import { Card, CardBody, Typography } from "@material-tailwind/react";
import { AuthContext } from "context/appContext";
import { ProfilClient } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { FC, useContext, useEffect } from "react";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import { NavLink, useLocation } from "react-router-dom";
import { getUserStats } from "redux/features/Admin/Stripe/stripeSlice";
import Badge from "shared/Badge/Badge";

export interface StripeMenuPageProps {
  children?: React.ReactNode;
  amount?: number;
  title?: string;
}

const StripeMenu: FC<StripeMenuPageProps> = ({ children, amount, title }) => {
  const authContext = useContext(AuthContext);

  const dispatch = useAppDispatch();
  let profile = authContext.user?.profil as ProfilClient;

  useEffect(() => {
    dispatch(
      getUserStats({
        userId: profile?.id,
        account_id: profile?.account_id || "",
        status: profile?.validation || "",
      })
    );
  }, []);
  const stats = useAppSelector((state) => state?.stripe?.ownerStats);

  // const [searchTerm, setSearchTerm] = useState('');

  // const handleSearch = () => {
  //     if (searchTerm.trim() !== '') {
  //     dispatch(filterAllReservation({ reference: searchTerm })).then(() => {
  //         navigate('/client/search');
  //         setSearchTerm('');
  //     });
  // }
  // };

  const location = useLocation();
  const path = location.pathname;

  const renderCard = () => {
    return (
      <Card className=" w-72 shadow-xl m-1  border border-gray-200 transition duration-1000 ease-in-out !rounded-lg flex flex-col group bg-gradient-to-r  from-indigo-500  to-blue-400 hover:bg-gradient-to-r hover:from-eventToBee hover:bg-eventToBee">
        <CardBody className="flex flex-col justify-between flex-1 p-4 !rounded-lg  ">
          <div className="flex items-center justify-between">
            <Typography className="!font-semibold text-base  text-white">
              {title === undefined ? "Solde disponible" : title}
            </Typography>
            <div className="flex items-center gap-1 text-white">
              <RiMoneyEuroCircleFill className="h-9 w-9" />
            </div>
          </div>
          <div className="mt-auto">
            <Typography color="blue-gray" className="font-semibold text-white">
              {amount !== undefined ? amount + " €" : stats?.balance + " €"}
            </Typography>
          </div>
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      <div className=" bg-neutral-50 dark:bg-neutral-900">
        <div className="flex pt-1 bg-white border-b border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800">
          <div className="container">
            <div className="flex justify-center space-x-8 overflow-x-auto text-center md:space-x-14 hiddenScrollbar">
              <NavLink
                to="/client/transfers"
                className={({ isActive }) =>
                  `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                    !isActive ? "border-transparent" : "border-primary-500"
                  }`
                }
              >
                <Badge
                  name="Tout"
                  color={path === "/client/transfers" ? "green-dark" : undefined}
                  className={`flex items-center justify-center w-20 h-8 text-center ${path === "/client/transfers" ? "font-bold text-3xl" : "font-normal text-base"}`}
                  href="/client/transfers"
                ></Badge>
              </NavLink>
              <NavLink
                to="/client/transfers-gain"
                className={({ isActive }) =>
                  `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                    !isActive ? "border-transparent" : "border-primary-500"
                  }`
                }
              >
                <Badge
                  name="Gain"
                  color={path === "/client/transfers-gain" ? "green-dark" : undefined}
                  className={`flex items-center justify-center w-20 h-8 text-center ${path === "/client/transfers-gain" ? "font-bold text-3xl" : "font-normal text-base"}`}
                  href="/client/transfers-gain"
                ></Badge>
              </NavLink>

              <NavLink
                to="/client/transactions"
                className={({ isActive }) =>
                  `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                    !isActive ? "border-transparent" : "border-primary-500"
                  }`
                }
              >
                <Badge
                  name="Historique"
                  color={path === "/client/transactions" ? "yellow-dark" : undefined}
                  className={`flex items-center justify-center w-20 h-8 text-center ${path === "/client/transactions" ? "font-bold text-3xl" : "font-normal text-base"}`}
                  href="/client/transactions"
                ></Badge>
              </NavLink>
            </div>
          </div>
          <div className="mr-5">{renderCard()}</div>
        </div>

        <div className="mt-5 ">{children}</div>
      </div>
    </>
  );
};

export default StripeMenu;
