import { FC, Fragment, useEffect, useRef } from "react";
import Modal from "./components/Modal";
import type { ListingGalleryImage } from "./utils/types";
import { useLastViewedPhoto } from "./utils/useLastViewedPhoto";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./styles/index.css";

export const addParam = ({ paramName = "", value }: { paramName?: string; value: string | number }) => {
  let params = new URLSearchParams(window.location.search);
  params.set(paramName, String(value));
  return params.toString();
};

export const deleteParam = ({ paramName = "" }: { paramName?: string }) => {
  let params = new URLSearchParams(window.location.search);
  params.delete(paramName);
  return params.toString();
};

interface Props {
  images?: ListingGalleryImage[];
  onClose: () => void;
  isShowModal: boolean;
}

const ListingImageGallery: FC<Props> = ({ images = [], onClose, isShowModal }) => {
  const navigate = useNavigate();
  const location = useLocation();

  let [searchParams] = useSearchParams();
  const photoId = searchParams?.get("");

  const handelShowImage = (image: any) => {
    const params = addParam({ value: image });
    navigate(`${location.pathname}?${params}`);
  };

  const handelCloseImage = () => {
    const params = deleteParam({});
    navigate(`${location.pathname}?${params}`);
  };

  return (
    <Transition appear show={isShowModal} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-white" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="sticky top-0 z-10 flex items-center justify-between p-4 bg-white xl:px-10">
            <button
              className="flex items-center justify-center w-10 h-10 rounded-full focus:outline-none focus:ring-0 hover:bg-neutral-100"
              onClick={onClose}
            >
              <ArrowSmallLeftIcon className="w-6 h-6" />
            </button>
          </div>

          <div className="flex items-center justify-center min-h-full pt-0 text-center sm:p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-5"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-5"
            >
              <Dialog.Panel className="w-full max-w-screen-lg p-4 pt-0 mx-auto text-left transition-all transform ">
                <div>
                  {photoId && <Modal images={images} onClose={handelCloseImage} />}

                  <div className="gap-4 columns-1 sm:columns-2 xl:columns-3">
                    {images.map(({ url }, index) => (
                      <div
                        key={index}
                        onClick={() => handelShowImage(index)}
                        className="relative block w-full mb-5 after:content group cursor-zoom-in after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:shadow-highlight focus:outline-none"
                      >
                        <img
                          alt="listing gallery "
                          className="transition transform rounded-lg brightness-90 will-change-auto group-hover:brightness-110 focus:outline-none"
                          style={{
                            transform: "translate3d(0, 0, 0)",
                          }}
                          src={process.env.REACT_APP_CLOUD_FRONT + url}
                          width={720}
                          height={480}
                          sizes="(max-width: 640px) 100vw, (max-width: 1280px) 50vw, 350px"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ListingImageGallery;
