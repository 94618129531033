import React from "react";

import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "../redux/store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const RIBDisplay = (rib: string) => {
  const firstFour = rib.slice(0, 4);
  const lastFour = rib.slice(-4);
  const maskedPart = "*".repeat(rib.length - 8);
  const newRIB = `${firstFour}${maskedPart}${lastFour}`;

  return newRIB;
};
