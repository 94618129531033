import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";

export interface CookiesProps {
  className?: string;
}

const Cookies: FC<CookiesProps> = ({ className = "pt-6 pb-6" }) => {
  return (
    <div className="flex items-center justify-center flex-col bg-[#E5E5E5] pt-6 pb-6 ">
      <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-black justify-center">
        Gestion des cookies
      </h2>

      <div className="rounded-xl">
        <div className="flex flex-col justify-center items-center pt-5 text-center">
          <a
            href="#"
            className="flex items-stretch p-6 mb-4 max-w-4xl bg-white rounded-lg border border-gray-200 shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
          >
            <div className="flex flex-col justify-between">
              <p className="mb-2 font-semibold text-gray-900 dark:text-white">
                Cupidatat sint dolor occaecat deserunt sunt.
              </p>
              <p className="h-full">
                Nostrud elit nisi ex sint. Mollit id adipisicing mollit in aute do esse minim eu occaecat magna non
                do. Reprehenderit consectetur fugiat laboris velit nisi laboris ipsum ea incididunt ullamco ea
                eiusmod ex consequat. Dolor sunt nulla eu incididunt irure officia tempor incididunt minim qui
                dolore pariatur exercitation. Occaecat est officia incididunt est et proident exercitation. Est
                quis mollit adipisicing eiusmod excepteur ad consequat deserunt elit. Aute do laboris et ad sint
                deserunt enim incididunt proident reprehenderit voluptate minim sunt. Do pariatur laborum quis
                adipisicing ad esse sunt sunt deserunt reprehenderit tempor ea ea. Laboris adipisicing anim dolore
                nulla voluptate dolor. Adipisicing cupidatat incididunt eu minim laborum labore in tempor
                reprehenderit labore nisi mollit enim. Laborum do fugiat nisi nulla dolor deserunt pariatur dolore
                laboris aliquip quis. Officia nisi est excepteur deserunt tempor ea eiusmod ex laboris. Id
                incididunt reprehenderit exercitation Lorem ad id nulla nostrud amet incididunt fugiat pariatur
                magna ullamco. Eu ad ullamco qui voluptate reprehenderit in est dolor nisi reprehenderit
                consectetur et est. Cillum incididunt amet reprehenderit sit nulla pariatur ad officia do ad eu
                deserunt in. Pariatur Lorem exercitation labore excepteur veniam. Incididunt est ea ea ad
                consequat. Duis minim veniam ipsum commodo adipisicing dolor sint commodo mollit amet. Dolore
                excepteur quis exercitation adipisicing aliqua elit eiusmod occaecat dolore. Consectetur commodo
                adipisicing exercitation proident nisi in tempor reprehenderit culpa. Veniam laboris irure ullamco
                ipsum nisi nostrud dolore dolore excepteur. Culpa irure sint excepteur anim est. Minim sint
                cupidatat commodo dolore officia. Qui ex quis enim dolor. Et sint laboris Lorem ad pariatur enim
                tempor consectetur veniam in dolor.
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
