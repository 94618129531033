import { Spinner } from "@material-tailwind/react";
import DeleteModal from "components/DeleteModal/DeleteModal";
import { AuthContext } from "context/appContext";
import { itemPerDashboradPrestation } from "data/constants";
import { Service } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deletePricingRequest, getPricingRequestByAgentId } from "redux/features/service/serviceSlice";
import Pagination from "shared/Pagination/Pagination";

export interface SectionGridPricingServiceAgentProps {
  className?: string;
}

const SectionGridPricingServiceAgent: FC<SectionGridPricingServiceAgentProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  let profile = authContext.user?.profil?.id;

  const dispatch = useAppDispatch();
  const serviceState = useAppSelector((state) => state.service.servicePring);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getPricingRequestByAgentId({ userId: profile })).then(() => setIsLoading(false));
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashboradPrestation;

  const paginatedPricingList = Array.isArray(serviceState)
    ? serviceState.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPricing, setSelectedService] = useState<Service | null>(null);

  const handleDeleteModalOpen = (service: Service) => {
    setSelectedService(service);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    if (selectedPricing && profile) {
      await dispatch(deletePricingRequest(selectedPricing.id));
      setDeleteModalOpen(false);
      await dispatch(getPricingRequestByAgentId({ userId: profile }));
    }
  };

  const length = Array.isArray(serviceState) ? serviceState.length : 0;
  const totalPages = Array.isArray(serviceState) ? Math.ceil(serviceState.length / itemsPerPage) : 0;
  return (
    <div className={`w-full  rounded-md ${className}`}>
      <div className="flex items-center justify-between pb-1">
        <div>
          <h2 className="font-semibold text-gray-600 dark:text-white">Mes Demandes de devis</h2>
        </div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center mt-16">
          <p>
            <Spinner color="blue" className="w-12 h-12" />
          </p>
        </div>
      ) : (
        <div>
          <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Nom et prénom
                    </th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Email
                    </th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Service
                    </th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Date
                    </th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 "></th>

                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Action
                    </th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white"></th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedPricingList.map((pricing) => (
                    <tr key={pricing.id}>
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800 ">
                        <p className="text-gray-900 whitespace-no-wrap dark:text-white">
                          {pricing?.firstName} {pricing?.lastName}
                        </p>
                      </td>
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800">
                        <p className="text-gray-900 whitespace-no-wrap dark:text-white">{pricing?.email}</p>
                      </td>
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800">
                        <p className="text-gray-900 whitespace-no-wrap dark:text-white">{pricing?.service}</p>
                      </td>
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800">
                        <p className="text-gray-900 whitespace-no-wrap dark:text-white">{pricing?.date}</p>
                      </td>
                      {/* GetbyID */}
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800">
                        <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                          <span
                            aria-hidden
                            className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                          ></span>
                          <span className="relative">
                            {" "}
                            <Link to={`/pricing-details/${pricing?.id}`}>
                              <i className="text-xl las la-eye"></i>
                            </Link>
                          </span>
                        </span>
                      </td>
                      {/* Update */}
                      <td className="text-sm border-b dark:bg-gray-800"></td>

                      {/* Delete */}
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800">
                        <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tigh">
                          <span aria-hidden className="absolute inset-0 bg-red-200 rounded-full opacity-50"></span>
                          <span className="relative">
                            <i
                              className="text-xl cursor-pointer las la-trash-alt disabled"
                              onClick={() => handleDeleteModalOpen(pricing)}
                            ></i>
                          </span>
                        </span>
                      </td>
                    </tr>
                  ))}
                  <DeleteModal
                    open={deleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    handleDelete={handleDeleteConfirmed}
                    category={{
                      id: selectedPricing?.id || 0,
                      designation: selectedPricing?.title || "",
                    }}
                  />
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex items-center justify-center mt-16">
            {typeof totalPages === "number" && (
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            )}
          </div>
          {Array.isArray(serviceState) && serviceState.length === 0 && (
            <div className="flex items-center justify-center mt-16">
              <p>Aucune demande de devis</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionGridPricingServiceAgent;
