import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import GallerySliderServices from "components/GallerySlider/GallerySliderServices";
import { AuthContext } from "context/appContext";
import { Service } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  addServiceToFavoris,
  checkServiceFavoriByUserId,
  deleteServiceFromFavoris,
  fetchAllServices,
  getServiceFavorisByClientId,
} from "redux/features/service/serviceSlice";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface ServiceCardProps {
  className?: any;
  data?: Service;
  size?: "default" | "small";
  galleryImgs?: string | string[];
  showLoginModal?: boolean;
  setShowLoginModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Favoris {
  id: number;
  clientId: number;
  locationId: number;
}

const ServiceCard: FC<ServiceCardProps> = ({
  size = "default",
  className = "",
  data,
  showLoginModal,
  setShowLoginModal,
}) => {
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  const dispatch = useAppDispatch();
  const [showLogin, setShowLogin] = useState(false);
  const serviceState = useAppSelector((state) => state.service);

  const isLiked = useAppSelector((state) => {
    const likedService = state?.service?.likedService || [];
    const serviceId = data?.id;

    if (Array.isArray(likedService) && likedService.length > 0 && serviceId) {
      const foundItem = likedService.find((item: any) => item.serviceId === serviceId);
      return !!foundItem;
    }
    return false;
  });

  const handleClickLike = async () => {
    if (!profilId) {
      if (setShowLoginModal) {
        setShowLoginModal(true);
      }
    }
    if (id && profilId && serviceState.likedService) {
      if (!isLiked) {
        await dispatch(addServiceToFavoris({ serviceId: id, userId: profilId }));
        await dispatch(getServiceFavorisByClientId({ clientId: profilId }));
      } else {
        dispatch(deleteServiceFromFavoris({ serviceId: id, userId: profilId }));
      }
    }
  };

  useEffect(() => {
    if (profilId) {
      dispatch(getServiceFavorisByClientId({ clientId: profilId }));
    }
  }, [profilId]);

  useEffect(() => {
    if (id && profilId && serviceState.likedService) {
      dispatch(checkServiceFavoriByUserId({ serviceId: id, userId: profilId }));
    }
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    switch (serviceState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [serviceState.loading]);

  if (!data) {
    return null;
  }
  const {
    title,
    images,
    events,
    id,
    ServiceAgent: { fields },
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySliderServices
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={images}
          href={`/prestations/${id}`}
        />
        <BtnLikeIcon
          loading={isLoading}
          isLiked={isLiked}
          onClick={handleClickLike}
          className="absolute right-3 top-3 z-[1]"
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="flex items-center justify-between">
          <div className="space-y-2">
            <span className="">{title}</span>
          </div>
          <Badge name={fields} color="blue" />
        </div>
        <div className="border-b w-14 border-neutral-100 dark:border-neutral-800"></div>
      </div>
    );
  };
  <ButtonSecondary href="/owners">Plus de propriétaire </ButtonSecondary>;

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={`/prestations/${id}`}>{renderContent()}</Link>
    </div>
  );
};

export default ServiceCard;
