import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { FC } from "react";
import OwnerGridFilterCard from "./OwnerGridFilterCard";
import { Helmet } from "react-helmet";

export interface OwnersProps {
  className?: string;
}

const OwnersPage: FC<OwnersProps> = ({ className = "" }) => {
  return (
    <div className={`nc-ListingStayPage relative overflow-hidden ${className}`} data-nc-id="ListingStayPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <br />

        {/* SECTION */}

        <OwnerGridFilterCard className="pb-24 lg:pb-28" />

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Découvrez d'autres prestations "
            subHeading="Explore les autres prestations "
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
          />
        </div>

        <br />
      </div>
    </div>
  );
};

export default OwnersPage;
