import React from "react";

interface LikeSaveBtnsServiceProps {
  onClick?: () => void;
  color?: string;
  isFavorited?: boolean;
}

const LikeSaveBtnsService: React.FC<LikeSaveBtnsServiceProps> = ({ onClick, isFavorited }) => {
  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm ">
        <span className="py-1.5 px-3 flex rounded-lg cursor-pointer  " onClick={onClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 mt-1.5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              fill={`${isFavorited ? "red" : "white"}`}
              stroke={`${isFavorited && "red"}`}
            />
          </svg>
          <span className="hidden sm:block ml-2.5 mt-1.5">Favoris</span>
        </span>
      </div>
    </div>
  );
};

export default LikeSaveBtnsService;
