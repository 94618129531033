import { Alert } from "@material-tailwind/react";
import { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "context/appContext";
import { currentUser } from "redux/features/auth/authSlice";
import { initStates, login } from "redux/features/signIn/signInSlice";
import { resetValues } from "redux/features/signUp/signUpSlice";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const HIW2img = `static-files/prestataire_inscrit.png`;
const HIW1img = `static-files/client_inscrit.png`;

export interface PageLoginProps {
  className?: string;
}

export interface accountTest {
  phone: number;
}

const formSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .regex(
      /((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).{8,26}$/,
      "Veuillez entrer un mot de passe valide."
    ),
});

const PageLogin: FC<PageLoginProps> = () => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  let redirect = "";
  if (location && location?.state?.form) {
    redirect = location?.state?.form?.pathname;
  }

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const signInState = useAppSelector((state) => state.signIn);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    setErrorAlert(false);
    dispatch(login(data));
  };

  useEffect(() => {
    load();

    if (authContext.isAuthenticated) {
      if (location.state && location.state.from) {
        navigate(redirect);
      }
      navigate("/");
    }
  }, []);

  useEffect(() => {
    switch (signInState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        dispatch(currentUser());
        break;
      }
      case "failed": {
        setIsLoading(false);
        setErrorAlert(true);
        break;
      }
    }
  }, [signInState.loading]);

  useEffect(() => {
    if (authContext.user && authContext.isAuthenticated) {
      switch (authContext.user.role) {
        case "owner": {
          navigate("/owner");
          break;
        }
        case "client": {
          if (redirect) {
            navigate(redirect);
          } else {
            navigate("/client/dashboard");
          }
          break;
        }
        case "admin": {
          if (redirect) {
            navigate(redirect);
          } else {
            navigate("/dashboard");
          }
          break;
        }
        case "service": {
          if (redirect) {
            navigate(redirect);
          } else {
            navigate("/prestataire");
          }
          break;
        }
        case "creator": {
          if (redirect) {
            navigate(redirect);
          } else {
            navigate("/dashboard-creator");
          }
          break;
        }
      }
    }
  }, [authContext.user]);

  const load = async () => {
    await dispatch(initStates());
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    dispatch(resetValues());
  }, []);

  return (
    <div>
      <Helmet>
        <title>Event To Bee | Login</title>
      </Helmet>

      <div className="flex items-center justify-center min-h-screen dark:bg-gray-800 ">
        <div className="container mx-auto">
          <div className="container flex flex-col items-center mx-auto mb-10 space-y-8 md:flex-row md:mb-40 md:space-y-0 md:space-x-8">
            <div className="w-full max-w-md p-6 rounded-lg shadow-md dark:bg-gray-800 mt-14" id="form1">
              <h2 className=" mt-12 text-center leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
                S'authentifier
              </h2>

              <div className="max-w-md space-y-6">
                {errorAlert && (
                  <Alert color="red" className="text-xs">
                    {signInState.signInError}
                  </Alert>
                )}

                <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                  <label className="block mt-14">
                    <span className="flex items-center justify-between text-neutral-800 dark:text-neutral-200">
                      Adresse email
                    </span>
                    <Input
                      type="email"
                      placeholder="example@example.com"
                      className={`mt-1 ${errors.email?.message ? "border-red-500" : ""}`}
                      disabled={isLoading}
                      {...register("email")}
                    />
                    {errors.email?.message && <div className="text-xs text-red-500">{errors.email?.message}</div>}
                  </label>

                  <label className="block">
                    <span className="flex items-center justify-between text-neutral-800 dark:text-neutral-200">
                      Mot de passe
                      <Link to="/forgot-password" className="text-sm text-blue-500">
                        Mot de passe oublié ?
                      </Link>
                    </span>
                    <div className="relative ">
                      <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="mot de passe"
                        className={`mt-1 ${errors.password?.message ? "border-red-500" : ""}`}
                        disabled={isLoading}
                        {...register("password")}
                      />
                      <span
                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    {errors.password?.message && (
                      <div className="text-xs text-red-500">{errors.password?.message}</div>
                    )}
                  </label>

                  <ButtonPrimary type="submit" disabled={isLoading} loading={isLoading}>
                    Se connecter
                  </ButtonPrimary>
                </form>
              </div>
            </div>

            <div className="flex flex-col justify-between space-y-5 md:flex-col" id="form2">
              <Link
                to={"/signup/client"}
                className="flex items-center max-w-sm p-6 mt-8 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                style={{ height: "400px" }}
              >
                <img
                  src={process.env.REACT_APP_CLOUD_FRONT + HIW1img}
                  alt="description."
                  className="w-20 h-20 mb-4 ml-2 mr-5"
                />
                <h5 className="mb-2 text-xl tracking-tight text-gray-900 dark:text-white">
                  <span className="font-semibold text-center"> Compte Utilisateur </span> <br />{" "}
                  <span className="text-sm text-center"> Créer compte utilisateur</span>
                </h5>
              </Link>

              {/* <Link
                style={{ height: "220px" }}
                to={"/signup/service"}
                className="flex items-center max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
              >
                <img
                  src={process.env.REACT_APP_CLOUD_FRONT + HIW2img}
                  alt="description"
                  className="w-20 h-20 mb-4 ml-2 mr-5"
                />
                <h5 className="mb-2 text-xl tracking-tight text-gray-900 dark:text-white">
                  <span className="font-semibold">
                    {" "}
                    Compte Prestataire <br />
                  </span>{" "}
                  <span className="text-sm">Créer compte prestataire</span>
                </h5>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
