import { FC, useEffect, useMemo, useState } from "react";
import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import NcImage from "shared/NcImage/NcImage";

type Timer = ReturnType<typeof setInterval>;

export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
}

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  uniqueID = "uniqueID",
  href = "/listing-stay-detail",
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();

  const [autoplayInterval, setAutoplayInterval] = useState<Timer | null>(null);

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
      startAutoplay();
    }, 10);

    return () => {
      stopAutoplay();
    };
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const renderSliderGallery = () => {
    return (
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {galleryImgs.map((item, index) => (
              <li key={index} className="glide__slide">
                <NcImage src={item ? process.env.REACT_APP_CLOUD_FRONT + item : ""} style={{ borderRadius: 0 }} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const startAutoplay = () => {
    const interval = setInterval(() => {
      MY_GLIDEJS.go(">");
    }, 10000);
    setAutoplayInterval(interval);
  };

  const stopAutoplay = () => {
    if (autoplayInterval) {
      clearInterval(autoplayInterval);
      setAutoplayInterval(null);
    }
  };

  return (
    <div className={`nc-GallerySlider ${className} `} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySlider;
