import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import { Spinner } from "@material-tailwind/react";
import AlertModalMessage from "components/AlertModal/AlertModalMessage";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import FormItem from "components/FormItem/FormItem";
import LikeSaveBtns from "components/LikeSaveBtns";
import LoginModal from "components/LoginModal/LoginModal";
import Message from "components/Message/Message";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import NcInputNumberOne from "components/NcInputNumber/NcInputNumberOne";
import SocialMediaShare from "components/SocialMediaShare/SocialMediaShare";
import StartRating from "components/StartRating/StartRating";
import { AuthContext } from "context/appContext";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  addComment,
  addToFavoris,
  checkFavoriByClientId,
  deleteLocationFromFavoris,
  fetchCommentById,
  fetchLocationById,
  fetchOwnerLocations,
  fetchPriceById,
  getFavorisByClient,
  initStates,
} from "redux/features/locations/locationSlice";
import { fetchOwnerRating } from "redux/features/owners/ownersSlice";
import {
  checkReservation,
  fetchAvailableHours,
  fetchReservationByLocation,
  fetchfullyDays,
  payReservation,
} from "redux/features/reservations/reservationSlice";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CheckboxInput from "shared/Checkbox/CheckboxInput";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import DetailPagetLayout from "../Layout";
import StayDatesRangeInput from "./StayDatesRangeInput";
import Cookies from "js-cookie";
import { useCookies } from "react-cookie";
import { fetchUnavailableByLocation } from "redux/features/unavailable/unavailableSlice";
import { socket } from "utils/socket";
import { getMinPriceByIdLocation } from "redux/features/location/locationSlice";
import MobileFooterSticky from "../(components)/MobileFooterSticky";
import { Helmet } from "react-helmet";
import TextDisplay from "utils/textAreaDisplay";
import { getOwnerById } from "redux/features/auth/authSlice";

interface LocationMinPrice {
  locationId: string;
  minPrice?: number;
}

const ListingStayDetailPage: FC<{}> = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const profilId = authContext?.user?.profil?.id;

  let locationState = useAppSelector((state) => state.featureLocation.locationDetail);
  const data = useAppSelector((state) => state.location?.locationMinPrice) as LocationMinPrice | undefined;

  const userState: any = useAppSelector((state) => state?.auth?.user);
  let availableHoursState: number[] = useAppSelector((state) => state.featureReservation.availableHours);
  let fullyDaysState = useAppSelector((state) => state.featureReservation.fullyDays);
  let ratingOwnerState = useAppSelector((state) => state.featureOwner.rating);
  let reservationsState = useAppSelector((state) => state.featureReservation);
  let unavailableState = useAppSelector((state) => state.featureUnvailable.unavailables);
  let ownerState = useAppSelector((state) => state.auth.owner);
  let commentStates = useAppSelector((state) => state.featureLocation.locationDetail?.comments);
  const states = useAppSelector((state) => state.featureLocation);

  const isLiked = useAppSelector((state) => {
    const likedLocation = state?.featureLocation.likedLocation || [];
    const locationId = id;
    if (Array.isArray(likedLocation) && likedLocation.length > 0 && locationId) {
      const foundItem = likedLocation.find((item: any) => item.locationId === locationId);
      return !!foundItem;
    }
    return true;
  });

  const acceptedCookies = Cookies.getJSON("eventtobee");
  const [cookies, setCookie] = useCookies(["visitedLocations"]);

  useEffect(() => {
    const parsedValue = parseInt(locationState?.nbreHour?.toString(), 10);
    if (!isNaN(parsedValue)) {
      setPeriode(parsedValue);
    }
  }, [locationState?.nbreHour]);

  const [star, setStar] = useState<number>(0);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [comment, setComment] = useState<string>("");
  const [showAllComments, setShowAllComments] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [hours, setHours] = useState<number[]>([]);
  const [price, setPrice] = useState<any>(null);
  const [priceMin, setPriceMin] = useState<any>(null);
  const [priceMax, setPriceMax] = useState<any>(null);
  const [priceId, setPriceId] = useState<string>("");
  const [periode, setPeriode] = useState<number>(parseInt(locationState?.nbreHour?.toString(), 10) ?? 0);
  const [halfTotal, setHalfTotal] = useState<number>(0);
  const [tva, setTva] = useState<number>(0);
  const [extraTotal, setExtraTotal] = useState<number>(0);

  const [total, setTotal] = useState<number>(0);
  const [reservation, setReservation] = useState<any>(null);
  const [services, setServices] = useState<number>(0);
  const [selectedStartHour, setSelectedStartHour] = useState<number | null>(null);
  const [maxDuration, setMaxDuration] = useState<number>(0);
  const [Submit, setSubmit] = useState<boolean>(false);
  const [allDescs, setAllDesc] = useState(true);
  const [openConversaton, setOpenConversation] = useState<any>(null);
  const [isCommentReqLoading, setIsCommentReqLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalExistReservation, setShowModalExistReservation] = useState(false);
  const [showSocialMediaShare, setShowSocialMediaShare] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [visitorRange, setVisitorRange] = useState<string | undefined>(undefined);
  const [formData, setFormData] = useState({
    selectedDate: selectedDay,
    startHour: selectedStartHour ? String(selectedStartHour + ":00") : "",
    endHour: selectedStartHour ? String(Number(selectedStartHour + periode) + ":00") : "",
    validation: false,
    visitor: locationState.visitor,
    locationId: locationState?.id,
    ownerId: locationState?.Client?.id,
    clientId: authContext?.user?.profil?.id,
    price: total,
    tva: tva,
    halfTotal: halfTotal,
    extra: extraTotal,
    service: services,
    menagementPrice: locationState.cleaning_costs,
  });

  const [checkedExtra, setCheckedExtra] = useState<any[]>([]);

  const lat = 48.8584;
  const lng = 2.2945;
  const googleMapsURL = `https://www.google.com/maps/embed/v1/place?key=${
    process.env.REACT_APP_MAPS_API_KEY
  }&q=${Number(lat)},${Number(lng)}`;

  useEffect(() => {
    dispatch(initStates());
    if (id && profilId && states.likedLocation) {
      dispatch(checkFavoriByClientId({ locationId: id, clientId: profilId }));
    }
  }, []);

  useEffect(() => {
    if (profilId) {
      dispatch(getFavorisByClient({ clientId: profilId }));
    }
  }, [profilId]);

  useEffect(() => {
    if (acceptedCookies === "accepted") {
      const visitedLocations = cookies.visitedLocations || [];

      const isVisited = visitedLocations.includes(id);

      if (!isVisited) {
        const updatedVisitedLocations = [...visitedLocations, id];
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);
        setCookie("visitedLocations", updatedVisitedLocations, { path: "/", expires: expirationDate });
      }
    }
  }, [id, cookies.visitedLocations, setCookie]);

  useEffect(() => {
    if (reservationsState.reservations.length > 0) {
      let reservationId = searchParams.get("reservationId");
      let openConv = searchParams.get("openconverstaion");

      if (openConv) {
        setOpenConversation(openConv === "true" ? true : false);
      }

      const found = reservationsState.reservations.find((element) => {
        return element["id"] === Number(reservationId);
      });

      setReservation(found);
    }
  }, [reservationsState?.reservations]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const res = await dispatch(fetchLocationById(id));

          if (res.payload.validation !== "approuvé" && profilId !== res.payload.userId) {
            navigate("/locations");
            return;
          } else {
            await Promise.all([
              dispatch(fetchCommentById(id)),
              dispatch(getMinPriceByIdLocation(id)),
              dispatch(fetchUnavailableByLocation(id)),
              dispatch(fetchfullyDays(id)),
              dispatch(fetchReservationByLocation(id)),
            ]);

            if (locationState?.userId) {
              await Promise.all([dispatch(getOwnerById(locationState?.userId))]);
            }

            if (locationState?.startHour && locationState?.endHour) {
              const startHour = parseInt(locationState.startHour.split(":")[0], 10);
              const endHour = parseInt(locationState.endHour.split(":")[0], 10);
              const updatedHour = generateHoursArray(startHour, endHour);
              setHours(updatedHour);
            }
          }
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();

    return () => {
      setReservation(null);
    };
  }, [id, locationState?.Client?.id, locationState?.startHour, locationState?.endHour, profilId]);

  useEffect(() => {
    const total = checkedExtra.reduce(
      (acc, checkedItem) => (checkedItem.isChecked ? acc + checkedItem.price * checkedItem.qte : acc),
      0
    );
    setExtraTotal(total);
  }, [checkedExtra]);

  useEffect(() => {
    if (periode) {
      handleTotalChange(periode);
    }
  }, [extraTotal, periode, price]);

  useEffect(() => {
    if (periode) {
      handleTotalChange(periode);
    }
  }, [periode, selectedDay]);

  useEffect(() => {
    setFormData({
      selectedDate: selectedDay,
      startHour: String(selectedStartHour + ":00"),
      endHour: selectedStartHour ? String(Number(selectedStartHour + periode) + ":00") : "",
      validation: false,
      visitor: locationState.visitor,
      locationId: locationState?.id,
      ownerId: locationState?.userId,
      clientId: authContext?.user?.profil?.id,
      price: total,
      halfTotal: halfTotal,
      tva: tva,
      extra: extraTotal,
      service: services,
      menagementPrice: locationState.cleaning_costs,
    });
  }, [total, selectedDay, selectedStartHour]);

  const handleClickLike = async () => {
    if (!profilId) {
      if (setShowLoginModal) {
        setShowLoginModal(true);
      }
    }
    if (id && profilId && states.likedLocation) {
      if (isLiked) {
        await dispatch(addToFavoris({ locationId: id, clientId: profilId })).then(() => {});
      } else {
        await dispatch(deleteLocationFromFavoris({ locationId: id, clientId: profilId }));
      }
      await dispatch(getFavorisByClient({ clientId: profilId }));
    }
  };

  const generateHoursArray = (startHour: any, endHour: any) => {
    const updatedHour = [];
    for (let i = startHour; i <= endHour; i++) {
      updatedHour.push(i);
    }
    return updatedHour;
  };

  const handleShareButtonClick = () => {
    setShowSocialMediaShare(!showSocialMediaShare);
  };

  const handleChangeData = (index: number, value: any) => {
    const updatedCheckedExtra = [...checkedExtra];
    updatedCheckedExtra[index] = { ...updatedCheckedExtra[index], qte: value };
    setCheckedExtra(updatedCheckedExtra);
  };

  const handleChangePrice = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPriceId = event.target.value;
    setPriceId(selectedPriceId);

    const res = (await dispatch(fetchPriceById(event.target.value))) as {
      payload: { price: number; priceId: string; min: string; max: string };
    };

    if (res.payload) {
      setPrice(res.payload.price);
      setPriceId(res.payload.priceId);
      setVisitorRange(`${res.payload.min} - ${res.payload.max}`);
    }
  };

  const handleExtraCheckboxChange = (item: any, isChecked: boolean) => {
    if (item) {
      setCheckedExtra((prevCheckedExtra) => {
        const itemExists = prevCheckedExtra.some(
          (x) => x.designation === item.designation && x.price === item.price && x.priceId === item.priceId
        );

        if (!itemExists) {
          return [
            ...prevCheckedExtra,
            { designation: item.designation, price: item.price, qte: 1, isChecked, priceId: item.priceId },
          ];
        } else {
          return prevCheckedExtra.filter((x) => {
            if (x.designation === item.designation) {
              x.isChecked = isChecked;
            }
            return x;
          });
        }
      });
    }
  };

  const handleSubmit = async () => {
    setIsLoadingSubmit(true);
    if (!profilId || authContext.user?.role !== "client") {
      if (setShowLoginModal) {
        setShowLoginModal(true);
        setIsLoadingSubmit(false);
        return;
      }
    } else {
      const check = await dispatch(checkReservation(formData));
      if (check.payload.error) {
        setShowModalExistReservation(true);
        setIsLoadingSubmit(false);
      } else {
        const res = await dispatch(
          payReservation({
            quantity: periode,
            priceId: priceId,
            location: id,
            extra: checkedExtra,
            reservation: formData,
            visitor: visitorRange,
          })
        );
        window.location.href = res.payload;
      }
    }
  };

  const handleSelectedDayChange = async (day: Date) => {
    setSelectedDay(day);
    setSelectedStartHour(null);
    setMaxDuration(0);
    setPeriode(parseInt(locationState?.nbreHour));
    if (day) {
      setSubmit(true);
      await dispatch(fetchAvailableHours({ id, selectedDay: day }));
      setSubmit(false);
    }
  };

  const handleTotalChange = (hour: number) => {
    const cleaning_costs = locationState.cleaning_costs || 0;
    if (hour !== null && locationState !== undefined) {
      const firstPrice = hour * Number(price);
      setHalfTotal(firstPrice);
      const calculatedServices = Number((0.14 * (firstPrice + extraTotal + cleaning_costs)).toFixed(2));
      const calculatedTva = Number((0.2 * calculatedServices).toFixed(2));
      const calculatedTotal = Number(
        (calculatedServices + firstPrice + extraTotal + (locationState.cleaning_costs ?? 0)).toFixed(2)
      );
      setServices(calculatedServices);
      setTva(calculatedTva);
      setTotal(calculatedTotal);
    } else {
      setServices(0);
      setTotal(0);
    }
  };

  const handleStartHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedHour = parseInt(event.target.value);
    setSelectedStartHour(selectedHour);
    const { maxConsecutiveHours } = findMaxConsecutiveHours(availableHoursState, selectedHour);
    setMaxDuration(maxConsecutiveHours);
    if (maxConsecutiveHours + 1 < parseInt(locationState?.nbreHour)) {
      setShowModal(true);
    }

    handleTotalChange(parseInt(locationState?.nbreHour));
  };

  const findMaxConsecutiveHours = (availableHours: number[], selectedHour: number) => {
    let maxConsecutiveHours = 0;
    let consecutiveHours = 1;

    for (let i = 0; i < availableHours.length; i++) {
      const currentHour = availableHours[i];

      if (currentHour >= selectedHour && currentHour <= selectedHour + consecutiveHours) {
        consecutiveHours++;
      } else {
        consecutiveHours = 1;
      }

      maxConsecutiveHours = Math.max(maxConsecutiveHours, consecutiveHours);
    }

    // Ensure the minimum value is 2
    maxConsecutiveHours = Math.max(maxConsecutiveHours, parseInt(locationState?.nbreHour));

    return { maxConsecutiveHours };
  };

  const handleChangeDuration = (duration: number) => {
    setPeriode(duration);
  };

  const handlePostRequest = async () => {
    if (authContext.user?.role !== "client") {
      if (setShowLoginModal) {
        setShowLoginModal(true);
        return;
      }
    }
    setIsCommentReqLoading(true);
    await dispatch(
      addComment(
        JSON.stringify({
          locationId: id,
          text: comment,
          like: star,
          clientId: authContext?.user?.profil?.id,
        })
      )
    ).then(() => {
      setIsCommentReqLoading(false);
    });

    await dispatch(fetchCommentById(id));

    if (locationState?.Client?.userId !== userState.id) {
      socket.emit("send-notification", {
        senderId: userState?.id,
        receiverId: locationState?.Client?.userId,
        action: "send_avis",
        locationId: locationState?.id,
        location: locationState,
      });
    }

    setStar(0);
    setComment("");
  };

  const handleOpenModalImageGallery = () => {
    router(`${location.pathname}?gallery=images`);
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex items-center justify-between">
          <Badge
            name={
              <div className="flex items-center">
                <i className="text-sm las la-share-alt"></i>
                <span className="ml-1">{locationState && locationState?.CategoryLocation?.designation}</span>
              </div>
            }
          />
          <Badge
            name={
              <div className="flex items-center">
                <i className="las la-map-marker"></i>
                <span className="ml-1">{locationState?.city}</span>
              </div>
            }
            color="yellow"
          />
          <LikeSaveBtns onClick={handleClickLike} isFavorited={!isLiked} share={handleShareButtonClick} />
        </div>

        <AlertModalMessage
          open={showModal}
          handleClose={() => setShowModal(false)}
          message={`Veuillez noter que la réservation nécessite au moins 2 heures.`}
          alertText="Important"
        />
        <AlertModalMessage
          open={showModalExistReservation}
          handleClose={() => setShowModalExistReservation(false)}
          message={`Il semble qu'il y ait déjà une réservation pour cette période.`}
          alertText="Oups !"
        />
        {/* 2 */}
        <h2 className="text-2xl font-semibold sm:text-3xl lg:text-4xl">{locationState?.title}</h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating point={locationState?.averageLikes} reviewCount={locationState?.commentCount} />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">
              {" "}
              {/* {locationState?.address}, {locationState?.address2} */}
              {locationState?.city}, {locationState?.codePostale}
              {location.search !== "" && (
                <>
                  , {locationState?.address} {locationState?.address2}
                </>
              )}
            </span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <a href={`/owners/${locationState?.Client?.id}`}>
              <Avatar
                hasChecked
                sizeClass="h-10 w-10"
                radius="rounded-full"
                imgUrl={
                  locationState?.Client?.client_url
                    ? process.env.REACT_APP_CLOUD_FRONT + locationState?.Client?.client_url
                    : ""
                }
              />
            </a>
            <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
              Publié par :{" "}
              <span className="font-medium text-neutral-900 dark:text-neutral-200">
                <span>{locationState?.Client?.name}</span>
              </span>
            </span>
          </div>
          {/*      <div className="flex items-center justify-between">
            <Badge
              name={
                <div className="flex items-center">
                  <span className="ml-1">
                    {locationState?.category === "particular"
                      ? "Particulier"
                      : "Entreprise"}
                  </span>
                </div>
              }
              color="green"
            />
          </div> */}
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex flex-col space-y-4 text-sm text-neutral-700 dark:text-neutral-300 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 sm:space-x-8 xl:flex-row xl:items-center xl:justify-start xl:space-x-12">
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-expand"></i>
            <span className="">
              {locationState?.surface} <span className="">Superficie</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-user"></i>
            <span className="">
              {locationState?.visitor} <span className="">Capacité d'accueil</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-clock"></i>
            <span className="">
              {locationState?.startHour}-{locationState?.endHour}
            </span>
          </div>
          {locationState?.handicap && (
            <div className="flex items-center space-x-3">
              <i className="text-2xl lab la-accessible-icon"></i>
              <span className="">Accessible </span>
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-4 text-sm text-neutral-700 dark:text-neutral-300 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 sm:space-x-8 xl:flex-row xl:items-center xl:justify-start xl:space-x-12">
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-toilet"></i>
            <span className="">WC {locationState?.wc}</span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-parking"></i>
            <span className="">
              Parking {locationState?.parking} <span className="hidden sm:inline-block"></span>
            </span>
          </div>
          {locationState?.water && (
            <div className="flex items-center space-x-3">
              <i className="text-2xl las la-shower"></i>
              <span className="">Point d'eau </span>
            </div>
          )}
          {locationState?.light && (
            <div className="flex items-center space-x-3">
              <i className="text-2xl las la-lightbulb"></i>
              <span className="">Eclairage nocturne </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    const desc = locationState?.description.slice(0, 675);
    const allDesc = locationState?.description;
    const handleClickVoirPlus = () => {
      setAllDesc(false);
    };
    const handleClickVoirMoins = () => {
      setAllDesc(true);
    };

    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Description</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        <div className="flex">
          {locationState.atmosphere.length > 0 &&
            locationState?.atmosphere.map((item: any, index: number) => (
              <div key={index} className="flex items-center space-x-1">
                <div className=""></div>
                <Badge href="##" color="purple" name={item} />
              </div>
            ))}
        </div>

        <div className="text-neutral-6000 dark:text-neutral-300">
          <TextDisplay text={allDescs ? desc : locationState?.description} />
          {/* <span>{allDescs ? desc : locationState?.description}</span> */}
          {locationState?.description.length >= 675 && allDescs && (
            <span className="text-sm text-blue-500 cursor-pointer hover:underline" onClick={handleClickVoirPlus}>
              {" "}
              Voir plus
            </span>
          )}

          {allDesc && allDescs === false && (
            <span className="text-sm text-blue-500 cursor-pointer hover:underline" onClick={handleClickVoirMoins}>
              {" "}
              Voir moins
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Équipements Disponibles</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` Vous pouvez profiter de ces équipements gratuits pour rendre votre expérience encore plus agréable`}
          </span>
        </div>
        <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
          {locationState?.equipments &&
            locationState?.equipments?.map((item: any) => (
              <div key={item?.id} className="flex items-center space-x-3">
                <i className="text-2xl las la-check"></i>
                <span className="">{item?.equipment}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderEventsSection = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Événements</h2>
        </div>
        <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
          {locationState?.events.map((item: any, index: number) => (
            <div key={index} className="flex items-center space-x-3">
              <i className="text-2xl las la-handshake"></i>
              <span>{item}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPlayGroundsSection = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Aire de jeux</h2>
        </div>
        <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
          {locationState?.playground.map((item: any, index: number) => (
            <div key={index} className="flex items-center space-x-3">
              <i className="text-2xl las la-dice"></i>
              <span>{item}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderDaysSection = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Les jours de disponibilité</h2>
        </div>
        <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
          {locationState &&
            locationState.availableDays.map((item: any, index: number) => (
              <div key={index} className="flex items-center space-x-3">
                <i className="text-2xl las la-calendar-day"></i>
                <span>{item}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection10 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Extra équipements</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <div className="">
            {locationState?.extras &&
              !reservation &&
              locationState?.extras?.map((item: any, index: number) => (
                <div key={index} className="flex items-center justify-between mt-3">
                  {String(locationState.Client.id) !== String(authContext.user?.profil?.id) ? (
                    <CheckboxInput
                      name={item.designation}
                      key={item.designation}
                      label={`${
                        item.price !== 0 ? `${item.designation}: ${item.price} €` : `${item.designation}: Gratuit`
                      }`}
                      onChange={(e) => handleExtraCheckboxChange(item, e.checked)}
                      checked={
                        item &&
                        checkedExtra.some(
                          (checkedItem) =>
                            checkedItem.designation === item.designation && checkedItem.price === item.price
                        )
                      }
                    />
                  ) : (
                    <div key={item.designation} className="flex items-center space-x-3">
                      <i className="text-2xl las la-check"></i>
                      <span className="">
                        {item.price !== 0
                          ? `${item.designation}: ${item.price} €`
                          : `${item.designation}: Gratuit`}
                      </span>
                    </div>
                  )}

                  {String(locationState.Client.id) !== String(authContext.user?.profil?.id) && (
                    <div className="">
                      <NcInputNumberOne
                        onChange={(value) => handleChangeData(index, value)}
                        defaultValue={1}
                        min={1}
                        max={item.quantity}
                        disabled={
                          !checkedExtra.some(
                            (checkedItem) =>
                              checkedItem.designation === item.designation &&
                              checkedItem.price === item.price &&
                              checkedItem.isChecked === true
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              ))}
            <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
              {locationState?.extras &&
                reservation &&
                locationState?.extras?.map((item: any, index: number) => (
                  <div key={index} className="flex items-center space-x-3">
                    <i className="text-2xl las la-plus"></i>
                    <span>{item.designation}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection9 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Les règles de cet espace</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {locationState.conditions &&
            locationState.conditions?.map((item: any) => (
              <div key={item.designation} className="flex items-center space-x-3">
                <i className="text-2xl las la-exclamation-triangle"></i>
                <span>{item.designation}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Proposé par</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <a href={`/owners/${ownerState?.id}`}>
            <Avatar
              hasChecked
              hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
              sizeClass="h-14 w-14"
              radius="rounded-full"
              imgUrl={ownerState?.client_url ? process.env.REACT_APP_CLOUD_FRONT + ownerState?.client_url : ""}
            />
          </a>
          <div>
            <span>{ownerState?.name}</span>

            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating point={ownerState?.overallAverageLikes} />
              <span className="mx-2">·</span>
              {ownerState?.totalLocations} Espaces
            </div>
          </div>
        </div>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Inscrit le {new Date(ownerState?.createdAt ?? "").toLocaleDateString()}</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Temps de réponse - 100%</span>
          </div>
        </div>

        {/* == */}
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        {String(locationState?.Owner?.id) !== String(authContext.user?.profil?.id) && (
          <div>
            <ButtonSecondary href={`/owners/${locationState?.Client?.id}`}>Voir le profile</ButtonSecondary>
          </div>
        )}
      </div>
    );
  };

  const renderSection4 = () => {
    const prices = locationState.prices;
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Tarif</h2>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="mb-4 text-sm sm:text-base text-neutral-6000 dark:text-neutral-300">
            {locationState &&
              locationState.prices &&
              prices.map((price: any, index) => (
                <div
                  key={index}
                  className={`p-4 ${
                    index % 2 === 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""
                  } flex justify-between items-center space-x-4 rounded-lg`}
                >
                  <span>
                    {price.min} - {price.max} visiteurs
                  </span>
                  <span>{price.price} € /heure</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div id="comment" className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Avis ({commentStates && commentStates?.totalComments})</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate
            iconClass="w-6 h-6"
            className="space-x-0.5"
            onRatingChange={(rating) => setStar(rating)}
          />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Partage tes pensées ..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <ButtonCircle
              loading={isCommentReqLoading}
              disabled={isCommentReqLoading}
              className="absolute transform -translate-y-1/2 right-2 top-1/2"
              size=" w-12 h-12 "
              onClick={handlePostRequest}
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}

        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {commentStates &&
            commentStates.comments
              .slice(0, showAllComments ? commentStates.comments.length : 5)
              .map((comment: any) => <CommentListing key={comment.id} data={comment} className="py-8" />)}

          <div className="pt-8">
            {commentStates && commentStates?.totalComments > 5 && (
              <ButtonSecondary onClick={() => setShowAllComments(!showAllComments)}>
                {showAllComments ? "Cacher les avis" : "Voir plus d'avis"}
              </ButtonSecondary>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Localisation</h2>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="z-0 rounded-xl ring-1 aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-black/10">
          <div className="z-0 overflow-hidden rounded-xl">
            {lat && lng && (
              <iframe
                title="x"
                width="100%"
                height="100%"
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={googleMapsURL}
              ></iframe>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold">Informations suplémentaires</h2>
        </div>
        {/* CONTENT */}

        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
          - Dêpot de garantie : {locationState?.guarantee} € <br />
        </span>
      </div>
    );
  };

  const renderSection11 = () => {
    return (
      <div className=" listingSection__wrap">
        {/* HEADING */}
        <div className="flex justify-between ">
          <h2 className="text-2xl font-semibold">Politiques d'annulation</h2>
        </div>
        {/* CONTENT */}
        {locationState?.reservation_type === "Stricte" ? (
          <>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              <p>
                {" "}
                Stricte : Les clients peuvent annuler dans les 48 heures suivant la réservation pour un
                remboursement complet. Passé ce délai, aucune annulation n'est possible et aucun remboursement ne
                sera effectué.
              </p>

              <p>- Remboursement de 70 % de la valeur de la réservation en cas d'annulation </p>
            </span>
          </>
        ) : (
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            <p>
              {" "}
              Flexible : Les clients peuvent annuler jusqu'à 5 jours avant la date d'arrivée prévue pour un
              remboursement de 70 % du montant total. Les annulations effectuées moins de 5 jours avant la date
              d'arrivée ne sont pas remboursables.
            </p>
          </span>
        )}
        <div></div>
      </div>
    );
  };

  const renderSidebar = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    return (
      <div id="renderSidebar" className="shadow-xl listingSectionSidebar__wrap">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {price ? <>{price} €</> : <>{data && data.minPrice} €</>}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">/heure</span>
          </span>
        </div>

        <div className="flex justify-center font-semibold text-center">
          <span>Quand souhaitez-vous reserver ?</span>
        </div>
        <form className="flex flex-col">
          <StayDatesRangeInput
            className="flex-1 z-[11] border-2 rounded-lg "
            availableDays={locationState.availableDays}
            onTotalDayChange={handleSelectedDayChange}
            fullyDays={fullyDaysState}
            unavailableDays={unavailableState}
          />
          <br />
          {Submit && (
            <div className="flex items-center justify-center">
              <Spinner color="blue" className="w-6 h-6" />
            </div>
          )}
          {!Submit && (
            <FormItem label="Heure d'entrée" desc="">
              <Select
                onChange={handleStartHourChange}
                title={selectedDay ? "Sélectionnez une heure d'entrée" : "Veuillez ajouter le jour souhaité."}
                disabled={!selectedDay}
                value={selectedStartHour || ""}
              >
                {!selectedDay && (
                  <option value="" disabled>
                    Veuillez d'abord sélectionner une date
                  </option>
                )}
                <option value="" disabled>
                  {" "}
                </option>
                {hours.map((hour: any, index: number) => {
                  const isDisabled =
                    !availableHoursState.includes(hour as never) ||
                    index >= hours.length - parseInt(locationState?.nbreHour) ||
                    (selectedDay &&
                      new Date(selectedDay).toDateString() === new Date().toDateString() &&
                      hour < new Date().getHours());

                  return (
                    <option key={hour} value={hour} disabled={Boolean(isDisabled)}>
                      {`${hour}:00`}
                    </option>
                  );
                })}
              </Select>
            </FormItem>
          )}
          <FormItem label="Visiteurs">
            <Select onChange={handleChangePrice}>
              <option value="Nombre de visiteurs" disabled selected>
                Nombre de visiteurs
              </option>
              {locationState &&
                locationState.prices &&
                Array.isArray(locationState.prices) &&
                locationState.prices.map((price: any) => (
                  <option key={price.id} value={price.id}>
                    {`${price.min}-${price.max}`}
                  </option>
                ))}
            </Select>
          </FormItem>
          <NcInputNumber
            className="w-full mt-6"
            defaultValue={parseInt(locationState?.nbreHour)}
            onChange={(value) => handleChangeDuration(value)}
            max={maxDuration}
            min={parseInt(locationState?.nbreHour)}
            label="Durée"
          />

          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <br />

          <div className="flex justify-center font-semibold text-center">
            <span>Détails</span>
          </div>

          {/* SUM */}
          <div className="flex flex-col space-y-4">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Sous-total </span>
              <span> {halfTotal} €</span>
            </div>

            {extraTotal && extraTotal !== 0 ? (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Extra équipements</span>
                <span>{extraTotal} €</span>
              </div>
            ) : null}

            {locationState.cleaning_costs && locationState.cleaning_costs > 0 ? (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Frais de ménage </span>
                <span>{locationState.cleaning_costs} €</span>
              </div>
            ) : null}

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Frais de service </span>
              <span> {services} €</span>
            </div>

            <div className="flex justify-between font-semibold">
              <span>Frais total</span>
              <span>{total} €</span>
            </div>
          </div>

          {/* SUBMIT */}
          <ButtonPrimary
            className="mt-5"
            type="button"
            loading={isLoadingSubmit}
            onClick={handleSubmit}
            disabled={
              !selectedDay || !selectedStartHour || maxDuration < parseInt(locationState?.nbreHour) || !price
            }
          >
            Demande de réservation
          </ButtonPrimary>
        </form>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`Event To Bee - ${locationState?.title}`}</title>
        <meta
          name="description"
          content="Découvrez les détails du l'espace à louer pour votre évènement sur Event To Bee ."
        />
      </Helmet>
      <DetailPagetLayout>
        <div className="mb-5 nc-ListingStayDetailPage">
          {/*  HEADER */}
          <header className="rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-3 gap-1 sm:grid-cols-4 sm:gap-2">
              <div
                className="relative col-span-2 row-span-3 overflow-hidden rounded-md cursor-pointer sm:row-span-2 sm:rounded-xl"
                onClick={handleOpenModalImageGallery}
              >
                <img
                  className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={process.env.REACT_APP_CLOUD_FRONT + locationState?.images[0]}
                  alt=""
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                />
                <div className="absolute inset-0 transition-opacity opacity-0 bg-opacity-20 bg-neutral-900 hover:opacity-100"></div>
              </div>

              {locationState?.images.slice(0, 4).map((item: string, index: number) => (
                <div key={index} className={`relative rounded-md sm:rounded-xl overflow-hidden`}>
                  <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                    <img
                      className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl"
                      src={process.env.REACT_APP_CLOUD_FRONT + item}
                      alt=""
                      sizes="400px"
                    />
                  </div>

                  <div
                    className="absolute inset-0 transition-opacity opacity-0 cursor-pointer bg-opacity-20 bg-neutral-900 hover:opacity-100"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}

              <button
                className="absolute z-10 hidden px-4 py-2 bottom-3 left-3 rounded-xl md:flex md:items-center md:justify-center bg-neutral-100 text-neutral-500 hover:bg-neutral-200"
                onClick={handleOpenModalImageGallery}
              >
                <Squares2X2Icon className="w-5 h-5" />
                <span className="ml-2 text-sm font-medium text-neutral-800">Afficher toutes les photos</span>
              </button>
            </div>
          </header>

          {/* MAIN */}
          <main className="relative z-10 flex flex-col mt-11 lg:flex-row">
            {/* CONTENT */}
            <div
              className={`space-y-8 w-full ${
                String(locationState.Client.id) != String(authContext.user?.profil?.id)
                  ? "lg:w-3/5 xl:w-2/3 lg:space-y-10 lg:pr-10"
                  : ""
              }`}
            >
              {renderSection1()}
              {renderSection2()}
              {renderSection4()}
              {renderEventsSection()}
              {locationState.playground?.length > 0 && renderPlayGroundsSection()}
              {renderDaysSection()}
              {locationState?.equipments?.length > 0 && renderSection3()}
              {locationState?.extras?.length > 0 && renderSection10()}

              {locationState?.conditions?.length > 0 && renderSection9()}
              {renderSection5()}
              {renderSection6()}
              {renderSection7()}
              {renderSection8()}
              {renderSection11()}

              {showLoginModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                  <div className="fixed inset-0 bg-black opacity-50"></div>

                  <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="flex flex-col items-center p-6 bg-white rounded-lg dark:bg-neutral-900">
                      <div className="self-end mb-4">
                        <ButtonSecondary onClick={() => setShowLoginModal(false)}>X</ButtonSecondary>
                      </div>
                      <LoginModal LoginModal={(e) => setShowLoginModal(e)} />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div style={{ position: "absolute", zIndex: 1 }}>
              {reservation && reservation?.validation && (
                <Message
                  style={{ position: "fixed", right: "10px", marginRight: "0%", bottom: "50px" }}
                  reservation={reservation}
                  open_conversation={openConversaton}
                ></Message>
              )}
            </div>

            {showSocialMediaShare && <SocialMediaShare url={window.location.href} />}
            {!reservation && (
              <div className="nc-ListingStayDetailPage">
                {String(locationState.Client.id) === String(authContext.user?.profil?.id) ? (
                  <div className="fixed-sidebar"></div>
                ) : (
                  <div className="fixed-sidebar">{renderSidebar()}</div>
                )}
                <br />
              </div>
            )}
          </main>
        </div>
        <MobileFooterSticky price={price} minPrice={data ? Number(data.minPrice) : 0} />
      </DetailPagetLayout>
    </>
  );
};

export default ListingStayDetailPage;
