/* import { Popover, Transition } from '@headlessui/react';
import { Radio } from '@material-tailwind/react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { Fragment, useEffect, useState } from 'react';
import { fetchFields } from 'redux/features/Admin/Fields/fieldSlice';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonThird from 'shared/Button/ButtonThird';
import __cities from '../../data/jsons/cities.json';

export interface regionData {
    map(arg0: (item: any) => import('react/jsx-runtime').JSX.Element): React.ReactNode;

    id: number;
    designation: string;
}

interface TabFiltersProps {
    onApplyRegion: (region: string) => void;
    onApply: (fields: string) => void;
}

const TabFilters: React.FC<TabFiltersProps> = ({ onApply, onApplyRegion }) => {
    const dispatch = useAppDispatch();
    const fields = useAppSelector((state) => state?.featureField?.fields);
    const [selectedFields, setSelectedFields] = useState<string>('');
    const [selectedRegion, setSelectedRegion] = useState<string>('');

    const allAvailability = useAppSelector((state) => {
        const allServices = state?.service?.allServices || [];

        if (Array.isArray(allServices)) {
            const availabilityArray = allServices.flatMap(
                (service: { ServiceAgent: { availability: any } }) => service?.ServiceAgent?.availability || []
            );
            const uniqueAvailability = availabilityArray.filter(
                (value, index, self) => self.indexOf(value) === index
            );
            return uniqueAvailability;
        }

        return [];
    });

    useEffect(() => {
        dispatch(fetchFields());
    }, []);

    const clearSelectedFields = () => {
        setSelectedFields('');
        onApply('');
    };

    const clearSelectedRegion = () => {
        setSelectedRegion('');
        onApplyRegion('');
    };

    const handleRegionClick = (region: any) => {
        setSelectedRegion(region);
    };

    const handleFieldClick = (field: any) => {
        setSelectedFields(field);
    };

    const renderTabsTypeOfPlace = () => {
        return (
            <Popover className="relative">
                {({ open, close }) => (
                    <>
                        <Popover.Button
                            className={`flex items-center hover:border-primary-500  justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700  dark:hover:border-neutral-500 focus:outline-none ${
                                open ? '!border-primary-500 ' : ''
                            }`}
                        >
                            <span>Type de prestation</span>
                            <i className="ml-2 las la-angle-down"></i>
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute left-0 z-10 max-w-xs sm:w-screen sm:max-w-sm md:max-w-md lg:max-w-lg">
                                <div className="overflow-hidden bg-white border shadow-xl rounded-2xl dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700">
                                    <div className="relative flex flex-col px-5 py-6 space-y-5 overflow-x-auto overflow-y-auto max-h-60 lg:max-h-80">
                                        {fields &&
                                            fields.map((item: any) => (
                                                <div key={item.designation} className="">
                                                    <Radio
                                                        name={item.designation}
                                                        label={item.designation}
                                                        onChange={() => handleFieldClick(item.designation)}
                                                        checked={item.designation === selectedFields}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                    <div className="flex items-center justify-between p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800">
                                        <ButtonThird
                                            onClick={() => {
                                                clearSelectedFields();
                                                close();
                                            }}
                                            sizeClass="px-4 py-2 sm:px-5"
                                        >
                                            Annuler
                                        </ButtonThird>
                                        <ButtonPrimary
                                            onClick={() => {
                                                onApply(selectedFields);
                                                close();
                                            }}
                                            sizeClass="px-4 py-2 sm:px-5"
                                        >
                                            Appliquer
                                        </ButtonPrimary>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        );
    };

    const renderTabsTypeOfRegion = () => {
        return (
            <Popover className="relative">
                {({ open, close }) => (
                    <>
                        <Popover.Button
                            className={`flex items-center hover:border-primary-500 justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700  dark:hover:border-neutral-500 focus:outline-none ${
                                open ? '!border-primary-500 ' : ''
                            }`}
                        >
                            <span>Région</span>
                            <i className="ml-2 las la-angle-down"></i>
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute left-0 z-10 max-w-xs sm:w-screen sm:max-w-sm md:max-w-md lg:max-w-lg">
                                <div className="overflow-hidden bg-white border shadow-xl rounded-2xl dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700">
                                    <div className="relative flex flex-col px-5 py-6 space-y-5 overflow-x-auto overflow-y-auto max-h-60 lg:max-h-80">
                                        {allAvailability &&
                                            allAvailability.map((item: any) => (
                                                <div key={item} className="">
                                                    <Radio
                                                        name={item}
                                                        label={item}
                                                        onChange={() => handleRegionClick(item)}
                                                        checked={item === selectedRegion}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                    <div className="flex items-center justify-between p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800">
                                        <ButtonThird
                                            sizeClass="px-4 py-2 sm:px-5"
                                            onClick={() => {
                                                clearSelectedRegion();
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </ButtonThird>
                                        <ButtonPrimary
                                            onClick={() => {
                                                onApplyRegion(selectedRegion);
                                                close();
                                            }}
                                            sizeClass="px-4 py-2 sm:px-5"
                                        >
                                            Appliquer
                                        </ButtonPrimary>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        );
    };

    return (
        <div className="flex flex-col lg:flex-row lg:space-x-4">
            <div className="flex lg:hidden">{renderTabsTypeOfPlace()}</div>
            <div className="hidden space-x-4 lg:flex">{renderTabsTypeOfPlace()}</div>
            <div className="flex mt-3 lg:hidden">{renderTabsTypeOfRegion()}</div>
            <div className="hidden space-x-4 lg:flex">{renderTabsTypeOfRegion()}</div>
        </div>
    );
};

export default TabFilters;
 */

import { FC, useState } from "react";
import EventInput from "components/HeroSearchForm/EventInput";
import ButtonCircle from "shared/Button/ButtonCircle";
import LocationInput from "containers/Locations/AllLocationsPage/LocationInput";
import TypeInput from "components/HeroSearchForm/TypeInput";
import { useLocation } from "react-router-dom";

interface RealEstateSearchFormProps {
  onSelectField?: () => void;
  onSelectFromRegionEvent?: (field: string, value: string) => void;
  searchClicked: boolean;
  handleSearchClick: () => void;
  handleDelete: () => void;
  propTypeValue?: string;
  propRegionValue?: string;
}
const RealEstateSearchFormService: FC<any> = ({
  onSelectFromRegionEvent,
  handleSearchClick,
  handleDelete,
  onSelectField,
  propTypeValue,
  propRegionValue,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const querytype = searchParams.get("field");
  const queryregion = searchParams.get("region");

  const [event, setEvent] = useState(false);
  const [region, setRegion] = useState(false);

  const handleEventSelection = (events: any) => {
    onSelectFromRegionEvent("events", events);
  };
  const handleRegionSelection = (region: string) => {
    onSelectFromRegionEvent(region);
    setRegion(false);
  };
  const handleField = (field: string) => {
    onSelectField(field);
    setEvent(false);
  };
  const clearEvent = () => {
    setEvent(true);
  };
  const clearRegion = () => {
    setRegion(true);
  };

  const renderForm = () => {
    return (
      <>
        <form className="relative flex flex-col w-full -mt-8 bg-white divide-y shadow-xl xl: lg:flex-row lg:items-center rounded-3xl lg:rounded-full dark:shadow-2xl dark:bg-neutral-800 divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 ">
          <LocationInput
            propRegionValue={queryregion}
            onSelectRegion={handleRegionSelection}
            clear={region}
            className="flex-[1]"
          />
          <div className="self-center h-4 border-r border-slate-200 dark:border-slate-700"></div>
          <TypeInput propTypeValue={querytype} onSelectEvent={handleField} clear={event} />
          <div className="self-center h-4 border-r border-slate-200 dark:border-slate-700"></div>
          <div className="flex [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none   ">
            <ButtonCircle
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleDelete();
                clearEvent();
                clearRegion();
              }}
            >
              <i className="las la-times"></i>
            </ButtonCircle>
            <ButtonCircle type="button" className="btn btn-primary" onClick={handleSearchClick}>
              <i className="las la-search"></i>
            </ButtonCircle>
          </div>
        </form>
      </>
    );
  };

  return renderForm();
};

export default RealEstateSearchFormService;
