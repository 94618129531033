import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/hooks";
import {
  nextStep,
  previousStep,
  setFormData,
  setProfileClientData,
  setProfileServiceData,
  signUp,
  updateProfileClient,
  updateProfileService,
  setTotalSteps,
  initState,
  setOtpError,
} from "redux/features/signUp/signUpSlice";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import UserForm from "./UserForm";
import ProfieClientForm from "./ProfieClientForm";
import { chekVerificationOTP, currentUser, sendVerificationEmail } from "redux/features/auth/authSlice";
import ProfileServiceForm from "./ProfileServiceForm";
import ConfirmEmail from "./ConfirmEmail";

const PageSignUp: React.FC = () => {
  const { role } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const signUpState = useAppSelector((state) => state.signUp);

  const progress = (signUpState.currentStep / signUpState.totalSteps) * 100;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNextStep, setIsNextStep] = useState<boolean>(false);

  const [event, setEvent] = useState<Event>(new Event(""));

  const handleNextClick = () => {
    setIsNextStep(true);

    switch (signUpState.currentStep) {
      case 1: {
        setEvent(new Event("chekFormEvent"));
        break;
      }
      case 2: {
        setEvent(new Event("chekConfirmEmailEvent"));
        break;
      }
      case 3: {
        if (role === "client") {
          setEvent(new Event("chekProfileClientEvent"));
        }
        if (role === "service") {
          setEvent(new Event("chekProfileServiceEvent"));
        }
        break;
      }
      default: {
        setEvent(new Event(""));
        break;
      }
    }
  };

  const verificationEmail = async (data: { email: any; otp: any }) => {
    const chek = await dispatch(chekVerificationOTP(data));
    setIsLoading(false);

    if (chek.payload?.status === "OK") {
      dispatch(nextStep());
    } else {
      dispatch(setOtpError("Le OTP est invalide ou expiré."));
    }
  };

  const sendMail = () => {
    dispatch(sendVerificationEmail(signUpState.formData?.email));
  };

  useEffect(() => {
    dispatch(initState());

    if (role && signUpState.roleType.includes(role)) {
      switch (role) {
        case "client":
        case "service": {
          dispatch(setTotalSteps(3));
          break;
        }
      }
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    switch (signUpState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        dispatch(currentUser());

        if (signUpState.totalSteps === signUpState.currentStep) {
          navigate("/");
        } else {
          if (isNextStep) {
            dispatch(nextStep());
          }
        }
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [signUpState.loading]);

  useEffect(() => {
    if (signUpState.currentStep === 2) {
      sendMail();
    }
  }, [signUpState.currentStep]);

  useEffect(() => {
    if (signUpState.formData && isNextStep) {
      dispatch(signUp(signUpState.formData));
    }
  }, [signUpState.formData]);

  useEffect(() => {
    if (signUpState.ProfileClientData && isNextStep) {
      dispatch(updateProfileClient(signUpState.ProfileClientData));
    }
  }, [signUpState.ProfileClientData]);

  useEffect(() => {
    if (signUpState.ProfileServiceData && isNextStep) {
      dispatch(updateProfileService(signUpState.ProfileServiceData));
    }
  }, [signUpState.ProfileServiceData]);

  const divClassName =
    signUpState.currentStep === 1 || role === "client" ? "max-w-md mx-auto mt-10 space-y-6" : " mt-10 space-y-6";

  return (
    <div className="nc-PageSignUp" data-nc-id="PageSignUp">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>

      <div className="container mb-24 lg:mb-32">
        <div className="mt-10 space-y-6">
          {signUpState.currentStep === 1 && (
            <UserForm
              className="max-w-md mx-auto space-y-6"
              isLoading={isLoading}
              role={role ?? ""}
              handleForm={event}
              FormData={(data) => dispatch(setFormData(data))}
              errorUserForm={signUpState.signUpError}
            />
          )}
          {signUpState.currentStep === 2 && (
            <ConfirmEmail
              className="max-w-md mx-auto space-y-6"
              email={signUpState.formData?.email ?? ""}
              handleForm={event}
              FormData={(data) => verificationEmail(data)}
              errorForm={signUpState.otpError}
              ResendMail={sendMail}
            />
          )}

          {signUpState.currentStep === 3 && role === "client" && (
            <ProfieClientForm
              className=""
              isLoading={isLoading}
              handleForm={event}
              FormData={(data) => dispatch(setProfileClientData(data))}
              errorProfileForm={signUpState.signUpError}
            />
          )}

          {signUpState.currentStep === 3 && role === "service" && (
            <ProfileServiceForm
              className=""
              isLoading={isLoading}
              handleForm={event}
              FormData={(data) => dispatch(setProfileServiceData(data))}
              errorServiceForm={signUpState.signUpError}
            />
          )}
        </div>

        <div className={divClassName}>
          <div className="flex flex-col items-center justify-center space-y-3">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div className="bg-blue-600 h-2.5 rounded-full w-45" style={{ width: `${progress}%` }}></div>
            </div>

            <div className="flex items-center justify-between w-full">
              <p className="text-lg font-semibold">
                {`Étape ${signUpState.currentStep}/${signUpState.totalSteps}`}
              </p>

              <ButtonPrimary onClick={handleNextClick} loading={isLoading} disabled={isLoading}>
                {signUpState.currentStep === 3 ? "Accepter et continuer" : "Continuer"}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
