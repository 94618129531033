import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import { faFacebookF, faInstagram, faLinkedinIn, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SocialsList1Props {
  className?: string;
}

const socials: SocialType[] = [
  { name: "Facebook", icon: faFacebookF, href: "https://www.facebook.com/profile.php?id=61555527601681" },
  { name: "Tiktok", icon: faTiktok, href: "https://www.tiktok.com/@eventtobee" },
  { name: "Youtube", icon: faYoutube, href: "https://www.youtube.com/channel/UCNaEePN03uQbmhZAEqmtDFg" },
  { name: "Instagram", icon: faInstagram, href: "https://www.instagram.com/eventtobee/" },
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center space-x-2 text-2xl leading-none text-white dark:text-neutral-300 dark:hover:text-white group"
        key={index}
      >
        <FontAwesomeIcon icon={item.icon} />
        {/* <span className="hidden text-sm lg:block">{item.name}</span> */}
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
