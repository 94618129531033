import { useEffect, useState } from 'react';
import { fetchReservationById } from 'redux/features/reservations/reservationSlice';
import { socket } from '../../utils/socket';
import { Spinner } from '@material-tailwind/react';

import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import './message.scss';

import { isValidURL } from 'utils/globalFunctions';
import { Alert } from '@material-tailwind/react';

const Message = (props) => {
    const user_profile = 'static-files/user_profile_2.png';

    const userState = useAppSelector((state) => state?.auth?.user);
    const dispatch = useAppDispatch();

    const [client, setClient] = useState(null);
    const [owner, setOwner] = useState(null);
    const [reservation, setReservation] = useState(null);
    const [message, setMessage] = useState('');
    const [conversation, setConversation] = useState([]);
    const [messageSeen, setMessageSeen] = useState(null);
    const [file, setFile] = useState(null);
    const [unsupportedFileType, setUnsupportedFileType] = useState(false);
    const [sending, setSending] = useState(false);
    const [openConversation, setOpenConverstaion] = useState(props?.open_conversation);

    let reservationId = props?.reservation?.id;

    function countMessageNotification() {
        var current = null;
        var cnt = 0;
        for (var i = 0; i < conversation.length; i++) {
            current = conversation[i];

            if (!current?.vue && current?.receiverId === userState?.id) {
                cnt += 1;
            } else {
                cnt = 0;
            }
        }

        return cnt;
    }

    const isDayAgo = (dateP) => {
        let date = new Date(dateP).getTime();
        let dayAgo = new Date().getTime() - 1000 * 60 * 60 * 24;
        return dayAgo > date;
    };

    useEffect(() => {
        if (reservationId) {
            dispatch(fetchReservationById(reservationId)).then((res) => {
                setClient(res.payload?.Client);
                setOwner(res.payload?.Owner);
                setReservation(res.payload);

                socket.emit('load-messages', {
                    senderId:
                        userState.id === res.payload.Client?.userId
                            ? res.payload.Client?.userId
                            : res.payload.Owner?.userId,
                    receiverId:
                        userState.id !== res.payload.Client?.userId
                            ? res.payload.Client?.userId
                            : res.payload.Owner?.userId,
                    reservationId: reservationId,
                });
                socket.on('fetched-messages', (data) => {
                    setConversation(data);
                    socket.off('fetched-messages');
                });
            });
        }

        if (openConversation) {
            onToggle();
            setOpenConverstaion(false);
        }
    }, []);

    const showUnsupportedFileTypeAlert = () => {
        setUnsupportedFileType(true);

        setTimeout(() => {
            setUnsupportedFileType(false);
        }, 5000);
    };

    useEffect(() => {
        setMessageSeen(countMessageNotification(conversation));

        conversation.forEach((element) => {
            loadConversation(element);
        });

        return () => {
            if (document.getElementById('message-box')) {
                document.getElementById('message-box').innerHTML = '';
            }
        };
    }, [conversation]);

    var running = false;
    function send() {
        if (running == true) return;
        if (file) {
            setMessage('');
            addMsg({ name: file.name, body: file });
            setFile(null);
        } else {
            var msg = document.getElementById('message').value;
            if (msg == '') return;
            running = true;
            setMessage('');
            addMsg(msg);
        }
        setSending(true);
        /*
         */
    }
    const breakDescription = (text, charactersPerLine) => {
        if (text && charactersPerLine) {
            const regex = new RegExp(`.{1,${charactersPerLine}}`, 'g');
            return text.match(regex)?.join('\n') || text;
        }
        return;
    };

    useEffect(() => {
        socket.on('message-response', (data) => {
            setConversation(data);
            if (document.getElementById('drop-zone')) {
                document.getElementById('drop-zone').style.display = 'none';
                document.getElementById('drop-zone-close').style.display = 'none';
            }
            setSending(false);
        });
    }, [socket]);

    function addMsg(msg) {
        let socketResponse = socket.emit('message', {
            senderId: userState.id === client?.userId ? client?.userId : owner?.userId,
            receiverId: userState.id !== client?.userId ? client?.userId : owner?.userId,
            reservationId: reservation?.id,
            message: msg,
        });

        socket.emit('send-notification', {
            senderId: userState.id === client?.userId ? client?.userId : owner?.userId,
            receiverId: userState.id !== client?.userId ? client?.userId : owner?.userId,
            action: 'send_message',
            reservationId: reservationId,
            reservation: reservation,
        });
    }

    function loadConversation(conversation) {
        let formattedDescription;
        if (isValidURL(conversation?.message)) {
            if (conversation?.message.endsWith('.pdf')) {
                formattedDescription = document.createElement('a');
                const fileName = conversation?.message.split('/').pop();

                formattedDescription.src = process.env.REACT_APP_CLOUD_FRONT + conversation?.message;
                formattedDescription.textContent = fileName;
                formattedDescription.style.cursor = 'pointer';
                formattedDescription.onclick = () => {
                    const link = document.createElement('a');
                    link.href = formattedDescription.src;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                };
            } else {
                formattedDescription = document.createElement('img');
                formattedDescription.src = process.env.REACT_APP_CLOUD_FRONT + conversation?.message;
                formattedDescription.style.height = '180px';
                formattedDescription.style.width = '220px';
                formattedDescription.style.cursor = 'pointer';
                formattedDescription.style.displayalignItems = 'flex';
                formattedDescription.style.alignItems = conversation?.senderId === userState?.id ? 'start' : 'end';
                formattedDescription.style.justifyContent =
                    conversation?.senderId === userState?.id ? 'start' : 'end';
                formattedDescription.style.float = conversation?.senderId === userState?.id ? 'right' : 'left';
                formattedDescription.onclick = () => {
                    window.open(process.env.REACT_APP_CLOUD_FRONT + conversation?.message);
                };
            }
        } else {
            formattedDescription = breakDescription(conversation?.message, 100);
        }

        if (conversation?.senderId === userState?.id) {
            var div = document.createElement('div');

            var messageContentDiv = document.createElement('div');
            messageContentDiv.className = '';

            var containsImage = formattedDescription.tagName === 'IMG';

            if (!isValidURL(conversation?.message)) {
                messageContentDiv.innerHTML = formattedDescription;
            } else {
                messageContentDiv.appendChild(formattedDescription);
            }

            div.className =
                'flex flex-col justify-end p-1 mb-2 text-black' +
                (containsImage ? '' : ' rounded-2xl border bg-msgbg');
            div.appendChild(messageContentDiv);

            var time = document.createElement('span');
            var date =
                isDayAgo(conversation?.createdAt) === false
                    ? formatDate(new Date(conversation.createdAt))
                    : new Date(conversation.createdAt).toLocaleDateString('fr-FR', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                      });

            time.className = 'p-1 text-xs text-black text-gray-500';

            time.innerHTML = "<span class='chat-message-sent-time'>" + date + '</span>';

            if (document.getElementById('message-box') && document.getElementById('message')) {
                document.getElementById('message-box').appendChild(div);
                document.getElementById('message-box').appendChild(time);

                //SEND MESSAGE TO API
                document.getElementById('message').value = '';
                document.getElementById('message-box').scrollTop =
                    document.getElementById('message-box').scrollHeight;
            }
        } else if (conversation?.receiverId === userState?.id) {
            var div = document.createElement('div');

            !isValidURL(conversation?.message)
                ? (div.innerHTML = "<div className='chat-message-received'>" + formattedDescription + '</div>')
                : div.appendChild(formattedDescription);

            div.className =
                'flex justify-start p-1 mb-2 text-black bg-gray-200 border rounded-2xl sm:justify-start md:justify-start';
            var time = document.createElement('span');

            var date =
                isDayAgo(conversation?.createdAt) === false
                    ? formatDate(new Date(conversation.createdAt))
                    : new Date(conversation.createdAt).toLocaleDateString('fr-FR', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                      });
            time.className = 'flex justify-end p-1 text-xs text-black text-gray-500 sm:justify-end md:justify-end';
            time.innerHTML = "<span className='chat-message-received-time'>" + date + '</span>';

            if (document.getElementById('message-box')) {
                document.getElementById('message-box').appendChild(div);
                document.getElementById('message-box').appendChild(time);
                document.getElementById('message-box').scrollTop =
                    document.getElementById('message-box').scrollHeight;
                running = false;
            }
        }
    }

    const onToggle = function () {
        if (document.getElementById('chatbot_toggle')) {
            const toggle = (document.getElementById('chatbot_toggle').onClick = () => {
                if (document.getElementById('chatbot').classList.contains('collapsed')) {
                    document.getElementById('chatbot').classList.remove('collapsed');
                    if (document.getElementById('badge-not')) {
                        document.getElementById('badge-not').style.display = 'none';
                    }
                    document.getElementById('chatbot_toggle').children[0].style.display = 'none';
                    document.getElementById('chatbot_toggle').children[1].style.display = '';
                } else {
                    document.getElementById('chatbot').classList.add('collapsed');
                    if (document.getElementById('badge-not').style.display) {
                        document.getElementById('badge-not').style.display = '';
                    }
                    document.getElementById('chatbot_toggle').children[0].style.display = '';
                    document.getElementById('chatbot_toggle').children[1].style.display = 'none';
                }
            });
            toggle();
        }

        if (messageSeen && messageSeen > 0) {
            socket.emit('message-seen', {
                senderId: userState.id === client?.userId ? client?.userId : owner?.userId,
                receiverId: userState.id !== client?.userId ? client?.userId : owner?.userId,
                reservationId: reservation?.id,
            });

            setMessageSeen(0);
        }
    };

    const handleMessage = (event) => {
        setMessage(event.target.value);
    };
    const handleMessageSeen = () => {};
    function formatDate(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        let formateDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear() + '  ' + strTime;

        var OneDay = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;

        if (date.getTime() < OneDay) {
            return strTime;
        } else if (new Date(date) >= OneDay) {
            return formateDate;
        }
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileType = file.type;

            if (fileType.startsWith('image/') || fileType === 'application/pdf') {
                setMessage('');
                setFile(file);

                if (fileType.startsWith('image/')) {
                    const img = document.createElement('img');
                    img.src = URL.createObjectURL(file);
                    img.style.height = '180px';
                    img.style.width = '100%';
                    img.style.displayalignItems = 'flex';
                    img.style.alignItems = 'start';
                    img.style.justifyContent = 'start';
                    img.style.float = 'right';
                    let child = document.getElementById('drop-zone');
                    if (child.firstChild) {
                        child.removeChild(child.lastChild);
                    }
                    child.style.display = 'flex';
                    child.appendChild(img);
                } else if (fileType === 'application/pdf') {
                    const fileName = document.createElement('a');
                    fileName.textContent = file.name;
                    fileName.style.cursor = 'pointer';
                    fileName.onclick = () => {
                        window.open(URL.createObjectURL(file), '_blank');
                    };

                    let child = document.getElementById('drop-zone');
                    if (child.firstChild) {
                        child.removeChild(child.lastChild);
                    }
                    child.style.display = 'flex';
                    child.appendChild(fileName);
                }

                document.getElementById('drop-zone-close').style.display = 'flex';
            } else {
                showUnsupportedFileTypeAlert();
            }
        }
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            send();
        }
    };

    const deleteFile = () => {
        let deleteZone = document.getElementById('drop-zone-close');
        let child = document.getElementById('drop-zone');
        if (child.firstChild) {
            child.removeChild(child.lastChild);
            child.style.display = 'none';
            deleteZone.style.display = 'none';
        }
    };

    return (
        <>
            {conversation && (
                <div {...props} id="chatbot" className="main-card collapsed position">
                    <span
                        className={` ${messageSeen > 0 === true ? 'badge red' : ''}`}
                        onClick={onToggle}
                        id="badge-not"
                    >
                        {messageSeen}
                    </span>

                    <button id="chatbot_toggle" onClick={onToggle}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M15 4v7H5.17l-.59.59-.58.58V4h11m1-2H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm5 4h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1z" />
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            style={{ display: 'none' }}
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                        </svg>
                    </button>
                    <div className="main-title">
                        <div>
                            {client && owner && (
                                <img
                                    className=""
                                    style={{ borderRadius: '50%' }}
                                    src={
                                        userState.id === client.userId && owner?.client_url
                                            ? process.env.REACT_APP_CLOUD_FRONT + owner?.client_url
                                            : userState.id === owner.userId && client?.client_url
                                            ? process.env.REACT_APP_CLOUD_FRONT + client?.client_url
                                            : process.env.REACT_APP_CLOUD_FRONT + user_profile
                                    }
                                    id="img-change"
                                />
                            )}
                        </div>

                        {client && owner && userState.id === client.userId && <span>{owner?.name}</span>}
                        {client && owner && userState.id === owner.userId && <span>{client?.name}</span>}
                    </div>

                    <div className="p-4">
                        {unsupportedFileType && (
                            <Alert color="red" className="text-xs">
                                {
                                    'Type de fichier non pris en charge. Veuillez sélectionner une image ou un fichier PDF.'
                                }
                            </Alert>
                        )}
                    </div>
                    <div className="chat-area" id="message-box">
                        <span className="chat-message-received-time"></span>
                        <span className="chat-message-sent-time"></span>
                    </div>

                    <div style={{ display: 'none' }} id="drop-zone-close" className="flow-root mt-1">
                        <p className="float-left ml-2 text-xs text-gray-500">
                            <i
                                onClick={deleteFile}
                                style={{ position: 'relative', left: '0', display: 'flex', fontSize: '20px' }}
                                className="text-red-500 las la-ban icon-nm bg-dark"
                            ></i>
                        </p>
                    </div>
                    <div style={{ display: 'none' }} id="drop-zone" className="drop-zone"></div>

                    <div className="line"></div>

                    <div className="input-div">
                        <label className="filelabel">
                            <i style={{ fontSize: '30px' }} className="fa fa-paperclip"></i>
                            <input
                                className="FileUpload1"
                                id="FileInput"
                                name="booking_attachment"
                                type="file"
                                onChange={onImageChange}
                                accept="application/pdf,image/png,image/jpeg,image/jpg,image/webp"
                            />
                        </label>
                        <input
                            id="message"
                            type="text"
                            name="message"
                            className="ml-5 input-message"
                            value={message}
                            onChange={handleMessage}
                            onKeyDown={_handleKeyDown}
                            onFocus={handleMessageSeen}
                            placeholder="Entrez votre message ..."
                            disabled={sending}
                        />

                        {sending ? (
                            <div className="spinner-border text-primary" role="status">
                                <Spinner className="w-4 h-4 mt-2 mr-2" style={{ width: '24px', height: '24px' }} />
                            </div>
                        ) : (
                            <button className="input-send" onClick={send}>
                                <svg style={{ width: '24px', height: '24px' }}>
                                    <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
                                </svg>
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Message;
