import { TrashIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "@material-tailwind/react";
import { LocationFormProps } from "data/reduxInterfaces";
import { ParticularJustificationData } from "data/types";
import { useAppSelector } from "hooks/hooks";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import NcImage from "shared/NcImage/NcImage";
import OnboardingTour, { stepStyle } from "utils/tours/OnboardingTour";
import { z } from "zod";

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const ACCEPTED_IMAGE_MIME_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/webp",
  "application/pdf",
];

const LocationParticularImagesData: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  errorLocationForm,
  FormData,
}) => {
  const updatelocationStates = useAppSelector((state) => state.location.locationData);

  const formSchema = z.object({
    iban_url:
      updatelocationStates?.iban_url === null
        ? z
            .instanceof(File, {
              message: " L'image du RIB est obligatoire.",
            })
            .refine((file) => file && file.size <= MAX_FILE_SIZE, `La taille maximale d'image est de 10MB.`)
            .refine(
              (file) => file && ACCEPTED_IMAGE_MIME_TYPES.includes(file.type),
              "Le fichier doit être une image au format JPEG, PNG , GIF ou PDF."
            )
        : z
            .instanceof(File, {
              message: "L'image du RIB est obligatoire. est optionel.",
            })
            .optional()
            .refine(
              (file) => !file || (file.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file.type)),
              "Le fichier doit être une image au format JPEG, PNG , GIF ou PDF.Le fichier doit être une image au format JPEG, PNG , GIF ou PDF."
            ),

    adresseImg_url:
      updatelocationStates?.adresseImg_url === null
        ? z
            .instanceof(File, {
              message: " Extrait du justification à domicile est obligatoire.",
            })
            .refine((file) => file && file.size <= MAX_FILE_SIZE, `La taille maximale d'image est de 10MB.`)
            .refine(
              (file) => file && ACCEPTED_IMAGE_MIME_TYPES.includes(file.type),
              "Le fichier doit être une image au format JPEG, , GIF ou PDF."
            )
        : z
            .instanceof(File, {
              message: "L'image du RIB est obligatoire. est optionel.",
            })
            .optional()
            .refine(
              (file) => !file || (file.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file.type)),
              "Le fichier doit être une image au format JPEG, PNG , GIF ou PDF..Le fichier doit être une image au format JPEG, PNG , GIF ou PDF.."
            ),

    identityFImg_url:
      updatelocationStates?.identityFImg_url === null
        ? z
            .instanceof(File, {
              message: " L'image de carte identité face avant est obligatoire.",
            })
            .refine((file) => file && file.size <= MAX_FILE_SIZE, `La taille maximale d'image est de 10MB.`)
            .refine(
              (file) => file && ACCEPTED_IMAGE_MIME_TYPES.includes(file.type),
              "Le fichier doit être une image au format JPEG, PNG , GIF ou PDF.."
            )
        : z
            .instanceof(File, {
              message: "L'image du RIB est obligatoire. est optionel.",
            })
            .optional()
            .refine(
              (file) => !file || (file.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file.type)),
              "Le fichier doit être une image au format JPEG, PNG , GIF ou PDF..Le fichier doit être une image au format JPEG, PNG , GIF ou PDF.."
            ),

    identityBImg_url:
      updatelocationStates?.identityBImg_url === null
        ? z
            .instanceof(File, {
              message: " L'image de carte identité face arriere obligatoire.",
            })
            .refine((file) => file && file.size <= MAX_FILE_SIZE, `La taille maximale d'image est de 10MB.`)
            .refine(
              (file) => file && ACCEPTED_IMAGE_MIME_TYPES.includes(file.type),
              "Le fichier doit être une image au format JPEG, PNG , GIF ou PDF.."
            )
        : z
            .instanceof(File, {
              message: "L'image du RIB est obligatoire. est optionel.",
            })
            .optional()
            .refine(
              (file) => !file || (file.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file.type)),
              "Le fichier doit être une image au format JPEG, PNG , GIF ou PDF..Le fichier doit être une image au format JPEG, PNG , GIF ou PDF.."
            ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  const steps = [
    {
      selector: ".face-avant",
      content:
        ":  La copie de votre pièce d'identité ne doit pas être floutée ni tronquée. Veuillez également vérifier sa date d'expiration.",
      stepStyle,
    },
    {
      selector: ".face-arriere",
      content:
        " La copie de votre pièce d'identité ne doit pas être floutée ni tronquée. Veuillez également vérifier sa date d'expiration.",
      stepStyle,
    },

    {
      selector: ".k-bis",
      content:
        "La copie de votre extrait du justification à domicile  ne doit pas être floutée ni tronquée. Veuillez également vérifier sa date d'expiration.",
      stepStyle,
    },

    {
      selector: ".iban ",
      content: "Copie de votre relevé bancaire IBAN.",
    },
  ];

  useEffect(() => {
    if (handleForm.type === "chekLocationFormFifteenParticular") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const [showFullText, setShowFullText] = useState(false);

  const handleToggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleDropImage = (e: React.DragEvent<HTMLDivElement>, fieldName: keyof ParticularJustificationData) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles) {
      const file = droppedFiles[0];
      setValue(fieldName, file);
      trigger(fieldName as "iban_url" | "identityFImg_url" | "adresseImg_url" | "identityBImg_url");
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div className={className}>
      <form
        className="flex flex-col justify-center"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div className="flex items-center justify-center">
          <div className="flex flex-col">
            <form className="flex-grow space-y-6" encType="multipart/form-data">
              <div className="face-avant">
                <h2 className="text-base font-semibold">Pièce d'identité (Face avant)* </h2>

                <p className="text-xs">
                  Information : La copie de votre pièce d'identité ne doit pas être floutée ni tronquée. Vérifiez
                  aussi sa date d'expiration.
                </p>

                <div>
                  <div className="mt-5">
                    <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                      <div
                        className="mb-8"
                        onDrop={(e) => handleDropImage(e, "identityFImg_url")}
                        onDragOver={handleDragOver}
                      >
                        <div className="space-y-1 text-center">
                          {!getValues("identityFImg_url") && updatelocationStates?.identityFImg_url != null && (
                            <div className="mt-3">
                              <p className="text-sm text-green-500">Votre image:</p>
                              <div className="flex flex-wrap gap-2 mt-2">
                                <div key={""} className="relative">
                                  <NcImage
                                    src={
                                      process.env.REACT_APP_CLOUD_FRONT + updatelocationStates?.identityFImg_url
                                    }
                                    className="h-auto max-w-xs max-h-40"
                                  />

                                  <TrashIcon className="absolute w-5 text-white bg-black rounded-full cursor-pointer top-2 right-2 h-7 las la-trash-alt" />
                                </div>
                              </div>
                            </div>
                          )}
                          {getValues("identityFImg_url") && (
                            <div className="relative">
                              <img
                                src={
                                  getValues("identityFImg_url")
                                    ? URL.createObjectURL(getValues("identityFImg_url") as File)
                                    : ""
                                }
                                alt="Uploaded File"
                                className="h-48 rounded-md w-72"
                              />
                              {/*     <TrashIcon
                            className="absolute w-5 text-sm text-white bg-black rounded-full top-2 right-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                                                         onClick={handleDeleteCompanyImg}
                             
                          ></TrashIcon> */}
                            </div>
                          )}
                          <svg
                            className="w-12 h-12 mx-auto text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          <div className="flex-col text-sm text-neutral-6000 dark:text-neutral-300 ">
                            <label
                              htmlFor="identityFImg_url"
                              className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <span className="mt-1 text-xs">Ajouter image</span>

                              <input
                                id="identityFImg_url"
                                name="identityFImg_url"
                                type="file"
                                className="sr-only"
                                onChange={(e) => {
                                  const file = e.target.files?.[0];
                                  if (file) {
                                    setValue("identityFImg_url", file);
                                    trigger("identityFImg_url");
                                  }
                                }}
                                accept="image/jpeg, image/jpg, image/png, image/webp , application/pdf"
                              />
                            </label>
                            <p className="pl-1 text-xs">
                              {" "}
                              Choisissez une photo de votre carte d'identité ou passeport ou permis de conduire (en
                              cours de validité)
                            </p>
                          </div>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.identityFImg_url?.message && (
                  <div className="text-xs text-red-500">{errors.identityFImg_url?.message.toString()}</div>
                )}
              </div>
              .{" "}
              <div className="face-arriere">
                <h2 className="text-base font-semibold">Pièce d'identité (Face arriére)* </h2>

                <p className="text-xs">
                  Information : La copie de votre pièce d'identité ne doit pas être floutée ni tronquée. Vérifiez
                  aussi sa date d'expiration.
                </p>

                <div>
                  <div className="mt-5">
                    <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                      <div
                        className="mb-8"
                        onDrop={(e) => handleDropImage(e, "identityBImg_url")}
                        onDragOver={handleDragOver}
                      >
                        <div className="space-y-1 text-center">
                          {!getValues("identityBImg_url") && updatelocationStates?.identityBImg_url != null && (
                            <div className="mt-3">
                              <p className="text-sm text-green-500">Votre image:</p>
                              <div className="flex flex-wrap gap-2 mt-2">
                                <div key={""} className="relative">
                                  <NcImage
                                    src={
                                      process.env.REACT_APP_CLOUD_FRONT + updatelocationStates?.identityBImg_url
                                    }
                                    className="h-auto max-w-xs max-h-40"
                                  />

                                  <TrashIcon className="absolute w-5 text-white bg-black rounded-full cursor-pointer top-2 right-2 h-7 las la-trash-alt" />
                                </div>
                              </div>
                            </div>
                          )}
                          {getValues("identityBImg_url") && (
                            <div className="relative">
                              <img
                                src={
                                  getValues("identityBImg_url")
                                    ? URL.createObjectURL(getValues("identityBImg_url") as File)
                                    : ""
                                }
                                alt="Uploaded File"
                                className="h-48 rounded-md w-72"
                              />
                              {/*      <TrashIcon
                            className="absolute w-5 text-sm text-white bg-black rounded-full top-2 right-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                                                         onClick={handleDeleteCompanyImg}
                             
                          ></TrashIcon> */}
                            </div>
                          )}
                          <svg
                            className="w-12 h-12 mx-auto text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          <div className="flex-col text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                              htmlFor="identityBImg_url"
                              className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <span className="mt-1 text-xs">Ajouter image</span>

                              <input
                                id="identityBImg_url"
                                name="identityBImg_url"
                                type="file"
                                className="sr-only"
                                onChange={(e) => {
                                  const file = e.target.files?.[0];
                                  if (file) {
                                    setValue("identityBImg_url", file);
                                    trigger("identityBImg_url");
                                  }
                                }}
                                accept="image/jpeg, image/jpg, image/png, image/webp , application/pdf"
                              />
                            </label>
                            <p className="pl-1 text-xs">
                              {" "}
                              Choisissez une photo de votre carte d'identité ou passeport ou permis de conduire (en
                              cours de validité)
                            </p>
                          </div>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {errors.identityBImg_url?.message && (
                  <div className="text-xs text-red-500">{errors.identityBImg_url?.message.toString()}</div>
                )}
              </div>
              <div className="k-bis">
                <h2 className="text-base font-semibold">Télécharger un justificatif de domicile à votre nom </h2>

                <p className="text-xs">
                  {showFullText ? (
                    <>
                      celui-ci peut être soit une facture d'électricité, de gaz ou d'eau, une facture de téléphone
                      pour une ligne fixe ou mobile, une quittance de loyer ou une attestation d'assurance logement
                      pour l'année en cours. Tous ces documents doivent dater de moins de trois mois
                    </>
                  ) : (
                    <>
                      celui-ci peut être soit une facture d'électricité, de gaz ou d'eau, une facture de téléphone
                      pour une ligne fixe ou mobile{" "}
                      <span
                        onClick={handleToggleText}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        voir plus
                      </span>
                    </>
                  )}
                </p>
                <br />

                <div>
                  <div className="mt-5">
                    <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                      <div
                        className="mb-8"
                        onDrop={(e) => handleDropImage(e, "adresseImg_url")}
                        onDragOver={handleDragOver}
                      >
                        <div className="space-y-1 text-center">
                          {!getValues("adresseImg_url") && updatelocationStates?.adresseImg_url != null && (
                            <div className="mt-3">
                              <p className="text-sm text-green-500">Votre image:</p>
                              <div className="flex flex-wrap gap-2 mt-2">
                                <div key={""} className="relative">
                                  <NcImage
                                    src={process.env.REACT_APP_CLOUD_FRONT + updatelocationStates?.adresseImg_url}
                                    className="h-auto max-w-xs max-h-40"
                                  />

                                  <TrashIcon className="absolute w-5 text-white bg-black rounded-full cursor-pointer top-2 right-2 h-7 las la-trash-alt" />
                                </div>
                              </div>
                            </div>
                          )}
                          {getValues("adresseImg_url") && (
                            <div className="relative">
                              <img
                                src={
                                  getValues("adresseImg_url")
                                    ? URL.createObjectURL(getValues("adresseImg_url") as File)
                                    : ""
                                }
                                alt="Uploaded File"
                                className="h-48 rounded-md w-72"
                              />
                              {/*     <TrashIcon
                            className="absolute w-5 text-sm text-white bg-black rounded-full top-2 right-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                                                         onClick={handleDeleteCompanyImg}
                             
                          ></TrashIcon> */}
                            </div>
                          )}
                          <svg
                            className="w-12 h-12 mx-auto text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          <div className="flex-col text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                              htmlFor="adresseImg_url"
                              className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <span className="mt-1 text-xs">Ajouter image</span>

                              <input
                                id="adresseImg_url"
                                name="adresseImg_url"
                                type="file"
                                className="sr-only"
                                onChange={(e) => {
                                  const file = e.target.files?.[0];
                                  if (file) {
                                    setValue("adresseImg_url", file);
                                    trigger("adresseImg_url");
                                  }
                                }}
                                accept="image/jpeg, image/jpg, image/png, image/webp , application/pdf"
                              />
                            </label>
                            <p className="pl-1 text-xs">
                              {" "}
                              Insérer votre copie de du justification à domicile ne doit pas être floutée ni
                              tronquée
                            </p>
                          </div>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.adresseImg_url?.message && (
                  <div className="text-xs text-red-500">{errors.adresseImg_url?.message.toString()}</div>
                )}
              </div>
              <div className="iban">
                <h2 className="text-base font-semibold">RIB *</h2>
                <p className="text-xs">Information : Copie de votre relevé d'identité bancaire</p>
                <p className="text-xs">
                  Ces justificatifs seront traités par notre équipe en toute confidentialité
                </p>

                <div>
                  <div className="mt-5">
                    <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                      <div
                        className="mb-8"
                        onDrop={(e) => handleDropImage(e, "iban_url")}
                        onDragOver={handleDragOver}
                      >
                        <div className="space-y-1 text-center">
                          {!getValues("iban_url") && updatelocationStates?.iban_url != null && (
                            <div className="mt-3">
                              <p className="text-sm text-green-500">Votre image:</p>
                              <div className="flex flex-wrap gap-2 mt-2">
                                <div key={""} className="relative">
                                  <NcImage
                                    src={process.env.REACT_APP_CLOUD_FRONT + updatelocationStates?.iban_url}
                                    className="h-auto max-w-xs max-h-40"
                                  />

                                  <TrashIcon className="absolute w-5 text-white bg-black rounded-full cursor-pointer top-2 right-2 h-7 las la-trash-alt" />
                                </div>
                              </div>
                            </div>
                          )}
                          {getValues("iban_url") && (
                            <div className="relative">
                              <img
                                src={
                                  getValues("iban_url") ? URL.createObjectURL(getValues("iban_url") as File) : ""
                                }
                                alt="Uploaded File"
                                className="h-48 rounded-md w-72"
                              />
                              {/*     <TrashIcon
                            className="absolute w-5 text-sm text-white bg-black rounded-full top-2 right-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                            onClick={() => setValue("iban_url", "")}
                          ></TrashIcon> */}
                            </div>
                          )}

                          <div>
                            <svg
                              className="w-12 h-12 mx-auto text-neutral-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <div className="flex-col text-sm text-neutral-6000 dark:text-neutral-300">
                              <label
                                htmlFor="Ibanimg_url"
                                className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                              >
                                <span className="pl-1 text-xs">Ajouter image</span>
                                <input
                                  id="Ibanimg_url"
                                  name="iban_url"
                                  type="file"
                                  className="sr-only"
                                  onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    if (file) {
                                      setValue("iban_url", file);
                                      trigger("iban_url");
                                    }
                                  }}
                                  accept="image/jpeg, image/jpg, image/png, image/webp , application/pdf"
                                />
                              </label>
                              <p className="pl-1 text-xs">
                                Insérer votre photo du compte bancaire IBAN (International Bank Account Number)
                                valide
                              </p>
                            </div>
                            <p className="text-xs text-neutral-500 dark:text-neutral-400">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {errors.iban_url?.message && (
                    <div className="text-xs text-red-500">{errors.iban_url?.message.toString()}</div>
                  )}
                </div>
              </div>
              <p className="text-xs">
                En enregistrant les informations, vous acceptez le{" "}
                <a href="https://stripe.com/legal/connect-account" className="font-semibold text-primary-500">
                  {" "}
                  Stripe Connected Account Agreement{" "}
                </a>
              </p>
            </form>
          </div>
        </div>
      </form>
      <OnboardingTour steps={steps} page_name={"ParticularImagesDataForm"}></OnboardingTour>
    </div>
  );
};

export default LocationParticularImagesData;
