import { Link, useLocation } from "react-router-dom";
import CallToActionData from "./calltoaction.json";
import NcImage from "shared/NcImage/NcImage";
import imageService from "../../images/BecomeAgent.png";
import imageOwner from "../../images/BecomeOwner.png";

import { FC, useContext } from "react";
import { AuthContext } from "context/appContext";

interface BecomeAuthor {
  btnClass: any;
}
const CallToAction: FC<BecomeAuthor> = ({ btnClass }) => {
  const authContext = useContext(AuthContext);
  const owner = authContext?.isAuthenticated && authContext?.user?.role === "client";
  const service = authContext?.isAuthenticated && authContext?.user?.role === "service";

  const location = useLocation();
  let dataCollection = [];
  if (location.pathname === "/") {
    dataCollection = CallToActionData.OwnerCollection;
  } else {
    dataCollection = CallToActionData.Agentcollection;
  }
  const getImage = (img: any) => {
    switch (img) {
      case "imageOwner":
        return imageOwner;
      case "imageService":
        return imageService;
      default:
        return img;
    }
  };
  return (
    <>
      <div className="container">
        {dataCollection &&
          dataCollection.map((data, index) => (
            <div
              className="row align-items-center content-wrapper row--30 mt_dec--30 position-relative"
              key={index}
            >
              <div className="ml-2 md:ml-48">
                <div className="col-lg-8 mt--30 offset-lg-3">
                  <div className="inner">
                    <div className="text-left content">
                      <h2 className="text-3xl font-bold  lg:ml-36 2xl:ml-4" data-sal="slide-up">
                        {data.title}
                      </h2>
                      <h2 className="text-xl lg:ml-36  2xl:ml-4" data-sal="slide-up">
                        {data.desc}
                      </h2>
                      <div className="call-to-btn text-start mt--30 lg:ml-36 md:ml-8 2xl:ml-4">
                        <Link
                          className={btnClass}
                          to={
                            owner && location.pathname === "/"
                              ? data.secondLink
                              : service && location.pathname === "/prestations"
                                ? data.secondLink
                                : data.href
                          }
                        >
                          <span className="icon-reverse-wrapper">
                            <span className="btn-text">
                              {owner && location.pathname === "/"
                                ? "Ajouter votre espace"
                                : service && location.pathname === "/prestations"
                                  ? "Ajouter votre prestation"
                                  : "Inscription"}
                            </span>
                            <span className="btn-icon">
                              <i className="feather-arrow-right"></i>
                            </span>
                            <span className="btn-icon">
                              <i className="feather-arrow-right"></i>
                            </span>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:-mt-8 md:-ml-36 lg:-mt-12 xl:-mt-4 2xl:-mt-12 ">
                <div className="thumbnail">
                  <NcImage
                    className="x md:w-auto md:ml-8 lg:ml-8 lg:w-64 2xl:ml-16 md:ml-16  "
                    src={getImage(data.img)}
                    width={700}
                    height={400}
                    alt="Shape Images"
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default CallToAction;
