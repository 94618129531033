import About from "./About";

const AboutUsPage = () => {
  return (
    <>
      <div className="mt-36 rbt-about-area about-style-1 bg-color-white ">
        <About />
      </div>
    </>
  );
};

export default AboutUsPage;
