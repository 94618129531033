const baseURL = process.env.REACT_APP_SERVER_URL;

const getCsrfToken = async () => {
  const result = await fetch(`${baseURL}/api/auth/csrf`, {
    method: "GET",
    credentials: "include",
  });
  return result.json();
};

export const signInService = {
  login: async (data: any) => {
    const csrfToken = await getCsrfToken();

    const result = await fetch(`${baseURL}/api/auth/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrfToken?.csrfToken ?? "",
      },
      body: JSON.stringify(data),
    });

    return result.json();
  },
};
