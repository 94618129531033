import { zodResolver } from "@hookform/resolvers/zod";
import { FC, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import { z } from "zod";
import { Alert } from "@material-tailwind/react";
import Avatar from "shared/Avatar/Avatar";
import Label from "components/Label/Label";
import PhoneInput from "react-phone-input-2";
import OnboardingTour, { stepStyle } from "utils/tours/OnboardingTour";
import "react-phone-input-2/lib/style.css";
import { resetValues } from "redux/features/signUp/signUpSlice";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useLocation, Link } from "react-router-dom";
const MAX_FILE_SIZE = 1024 * 1024 * 10;

const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];

const formSchema = z.object({
  name: z.string().min(1, { message: "Le nom est obligatoire." }),
  lastName: z.string().min(1, { message: "Le prénom est obligatoire." }),
  dateOfBirth: z.string().min(1, { message: "La date de naissance est obligatoire." }),
  phone: z
    .string()
    .min(1, { message: "Le numéro téléphone est obligatoire." })
    .refine((value) => value.startsWith("33"), {
      message: "Le numéro de téléphone doit commencer par +33.",
    })
    .refine((value) => !/^0{11}$/.test(value.replace("+33", "")), {
      message: "Le numéro de téléphone ne peut pas comporter 11 zéros consécutifs.",
    }),
  client_url: z
    .optional(z.instanceof(File))
    .refine(
      (file) => !file || (file?.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type)),
      "Le fichier doit être une image au format JPEG, PNG ou GIF et ne pas dépasser 10MB."
    ),
});

export interface ProfieClientFormProps {
  className?: string;
  isLoading: boolean;
  handleForm: Event;
  errorProfileForm?: string;
  FormData: (data: any) => void;
}

const ProfieClientForm: FC<ProfieClientFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  errorProfileForm,
  FormData,
}) => {
  const [phoneInputColor, setPhoneInputColor] = useState("");
  useEffect(() => {
    let theme = localStorage.getItem("theme");
    if (theme === "dark") {
      setPhoneInputColor("#000");
    } else {
      setPhoneInputColor("#000");
    }
  }, []);

  const phoneInputStyle = {
    color: phoneInputColor,
    borderRadius: "0.375rem",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  const steps = [
    {
      selector: ".image",
      content: "Une image est une représentation visuelle.",
      stepStyle,
    },
    {
      selector: ".nom-prenom",
      content: "Ajouter votre prénom en complément de votre nom de famille.",
      stepStyle,
    },
    {
      selector: ".num-identite ",
      content:
        "Toute personne est identifiée dès sa naissance par son numéro d'inscription au répertoire national d'identification des personnes physiques. ",
    },
    {
      selector: ".num-telephone ",
      content:
        "Un numéro de téléphone est une séquence unique et nominative de chiffres permettant de vous contacter. ",
    },
  ];

  useEffect(() => {
    if (handleForm.type === "chekProfileClientEvent") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);
  const signUpState = useAppSelector((state) => state.signUp);
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const cleanup = () => {
      if (signUpState.currentStep === 2) {
        dispatch(resetValues());
      }
    };

    if (location.pathname !== "/signup/client") {
      dispatch(resetValues());
    }

    return cleanup;
  }, [location.pathname, signUpState.currentStep]);

  return (
    <div className={className}>
      <h2 className="mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Votre profil
      </h2>

      <div className="mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        <div className="flex-col items-start flex-shrink-0 image">
          <div className="relative flex overflow-hidden rounded-full">
            <Avatar
              sizeClass="w-32 h-32"
              imgUrl={getValues("client_url") ? URL.createObjectURL(getValues("client_url") as File) : ""}
            />
            {getValues("client_url") ? null : (
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-black cursor-pointer bg-opacity-60 text-neutral-50">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                ></svg>
                <span className="mt-1 text-xs">Selectionner Image</span>
              </div>
            )}

            <input
              type="file"
              id="imageFile"
              name="imageFile"
              className="absolute inset-0 opacity-0 cursor-pointer"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  setValue("client_url", file);
                  trigger("client_url");
                }
              }}
              accept="image/jpeg, image/jpg, image/png, image/webp"
            />
          </div>
        </div>
      </div>

      {errorProfileForm && (
        <Alert color="red" className="text-xs">
          {errorProfileForm}
        </Alert>
      )}

      <form className="max-w-md mx-auto mt-10 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6 md:mt-0">
          <div className="nom-prenom">
            <div className="">
              <Label>Nom *</Label>

              <Input
                type="text"
                placeholder="Nom"
                className={`mt-1.5  ${errors.name?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                {...register("name")}
              />
              {errors.name?.message && <div className="text-xs text-red-500">{errors.name?.message}</div>}
            </div>

            <div className="">
              <Label>Prénom *</Label>
              <Input
                type="text"
                placeholder="Prénom"
                className={`mt-1.5 ${errors.lastName?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                {...register("lastName")}
              />
              {errors.lastName?.message && <div className="text-xs text-red-500">{errors.lastName?.message}</div>}
            </div>

            <div className="">
              <Label>Date de naissance *</Label>
              <Input
                type="date"
                placeholder="Date de naissance"
                className={`mt-1.5 ${errors.dateOfBirth?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                {...register("dateOfBirth")}
              />
              {errors.dateOfBirth?.message && (
                <div className="text-xs text-red-500">{errors.dateOfBirth?.message}</div>
              )}
            </div>

            <div className="num-telephone">
              <Label>Numéro Téléphone *</Label>
              <PhoneInput
                onlyCountries={["fr"]}
                country={"fr"}
                placeholder={getValues("phone")}
                inputStyle={phoneInputStyle}
                containerStyle={phoneInputStyle}
                buttonStyle={phoneInputStyle}
                searchStyle={phoneInputStyle}
                inputProps={{ required: true }}
                value={getValues("phone")}
                onChange={(value) => setValue("phone", value)}
                disabled={isLoading}
              />
              {errors.phone?.message && (
                <div className="text-xs text-red-500">
                  {errors.phone?.message === "Required" ? "Ce champ est obligatoire" : ` ${errors.phone?.message}`}
                </div>
              )}
            </div>
          </div>

          <p style={{ fontSize: "10px", fontWeight: 400, lineHeight: "2" }}>
            En cliquant sur <b style={{ fontWeight: "bolder" }}>Accepter et continuer</b>, j'accepte les{" "}
            <a href={"/terms"} target="_blank">
              &nbsp;{" "}
              <span style={{ color: "rgb(0, 88, 255)", textDecoration: "underline" }}>
                Conditions générales d'utilisation
              </span>
              &nbsp;
            </a>
            et je reconnais avoir pris connaissance de la
            <a href={"/privacy-policy"} target="_blank">
              &nbsp;{" "}
              <span style={{ color: "rgb(0, 88, 255)", textDecoration: "underline" }}>
                Politique de confidentialité
              </span>
              &nbsp;
            </a>
            de Event to BEE.
          </p>
        </div>
      </form>
      <OnboardingTour steps={steps} page_name={"ProfieClientForm"}></OnboardingTour>
    </div>
  );
};

export default ProfieClientForm;
