import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import DashboardMenu from "../DashboardPage/DashboardMenu";

import { zodResolver } from "@hookform/resolvers/zod";
import { Card, CardBody, CardFooter, CardHeader, Typography } from "@material-tailwind/react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getCategoryById, resetValues, updateCategory } from "redux/features/Admin/categorys/categorySlice";
import { Alert } from "shared/Alert/Alert";
import { z } from "zod";

export interface UpdateCategoryPageProps {
  className?: string;
}

export interface EventtData {
  designation: string;
  id: number;
}

const UpdateCategoryPage: FC<UpdateCategoryPageProps> = ({ className = "" }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  let categoryState = useAppSelector((state) => state.featureCategory.categorie);
  useEffect(() => {
    if (id) {
      dispatch(getCategoryById(id));
    }
  }, [id]);
  useEffect(() => {
    if (categoryState) {
      setValue("designation", categoryState.designation || "");
    }
  }, [categoryState]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showExistAlert, setShowExistAlert] = React.useState(false);
  const navigate = useNavigate();
  const categoryStates = useAppSelector((state) => state.featureCategory);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formSchema = z.object({
    designation: z.string().min(1, { message: "Catégorie est obligatoire." }),
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    await dispatch(updateCategory({ data: { ...data }, id: id })).then(() => {
      dispatch(resetValues());
      navigate("/dashboard/categories");
    });
  };

  useEffect(() => {
    switch (categoryStates.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [categoryStates.loading]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="mx-auto w-full max-w-[24rem] dark:bg-gray-800">
            <CardHeader className="grid mb-4 h-28 place-items-center dark:bg-gray-800">
              {!showExistAlert && !showAlert && <span className="dark:text-white">Catégorie</span>}
              {showAlert && (
                <Alert type="success" containerClassName="text-xs">
                  modifiée avec succès !
                </Alert>
              )}
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
              <Label className="dark:text-white">Designation</Label>
              <Input {...register("designation")} />
              {errors.designation && <div className="text-xs text-red-500 ">{errors.designation.message}</div>}
            </CardBody>
            <CardFooter className="pt-0">
              <Typography variant="small" className="flex justify-center mt-6">
                <ButtonPrimary type="submit" loading={isLoading}>
                  Mise à jour
                </ButtonPrimary>
              </Typography>
            </CardFooter>
          </Card>
        </form>
      </DashboardMenu>
    </div>
  );
};

export default UpdateCategoryPage;
