import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import LogoN from "shared/Logo/LogoN";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Accueil",
    menus: [
      { href: "/login", label: "" },
      { href: "/login", label: "Créer un compte" },
      { href: "/signup/service", label: "Inscription prestataire" },
    ],
  },

  {
    id: "1",
    title: "Réservation",
    menus: [
      { href: "/locations", label: "Espaces" },
      { href: "/locations#hotes", label: "Hôtes" },
      { href: "/prestations", label: "Prestations " },
    ],
  },
  {
    id: "2",
    title: "Blog",
    menus: [{ href: "/blogs", label: "Blog" }],
  },

  {
    id: "6",
    title: "FAQ",
    menus: [{ href: "/proprietaire/faq", label: "Forum aux questions" }],
  },

  {
    id: "4",
    title: "Eventtobee.com",
    menus: [
      { href: "/about", label: "Qui sommes-nous ?" },
      { href: "/howItWorks", label: "Comment ça marche?" },
      { href: "/contact", label: "Contactez-nous" },
      { href: "/cookies", label: "Gestion des cookies" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm ">
        <h2 className="font-semibold text-white dark:text-neutral-200">{menu.title}</h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a key={index} className="text-white hover:text-white dark:hover:text-white" href={item.href}>
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="relative pt-10 border-t min-[320px]:mb-14 md:mb-0  nc-Footer border-neutral-200 dark:border-neutral-700 bg-stone-900">
      <div className="container grid grid-cols-2 gap-x-5 gap-y-10 sm:gap-x-8 md:grid-cols-5 lg:grid-cols-6 lg:gap-x-10">
        {widgetMenus.map(renderWidgetMenuItem)}

        <div className="grid items-center grid-cols-4 col-span-2 gap-5 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="flex items-center col-span-2 md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-3 lg:flex-row lg:space-y-0 lg:items-center" />
          </div>
          <div className="flex justify-center col-span-2 md:col-span-1">
            <LogoN />
          </div>
        </div>
      </div>
      <div className="my-2"></div>
      <hr className="border-t-2 border-gray-400" />

      <div className="my-4"></div>
      <div className="flex items-center justify-center mt-2 text-xs text-center text-white gap-x-2 sm:gap-x-4 md:grid-cols-10 lg:grid-cols-8">
        <div className="mb-5 ">
          <p className="ml-2 sm:ml-0">
            &copy; 2024 Eventtobee.com <span>&nbsp;&nbsp;&nbsp;</span> All rights reserved
          </p>
        </div>
        <div className="mb-5 ">
          {" "}
          <a href="/terms">Conditions Générales</a>
        </div>
        <div className="mb-5 ">
          <a href="/privacy-policy">Politique de confidentialité</a>
        </div>
        <div className="mb-5 ">
          <a href="/legal-notice">Mentions légales</a>
        </div>
        &nbsp;
      </div>
    </div>
  );
};

export default Footer;
