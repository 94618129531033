import React from "react";
import { Link } from "react-router-dom";
export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = `static-files/Black-EVENT+TO+BEE-17.svg`,
  imgLight = `static-files/White-EVENT+TO+BEE-20.svg`,
  className = "w-24",
}) => {
  return (
    <Link
      to="/"
      className={`inline-block ttnc-logo text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {/* <LogoSvgLight />
      <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block max-h-50 rounded-none ${img ? "dark:hidden" : ""}`}
          src={process.env.REACT_APP_CLOUD_FRONT + img}
          alt="logoImg"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden rounded-none max-h-50 dark:block"
          src={process.env.REACT_APP_CLOUD_FRONT + imgLight}
          alt="LogoImgN"
        />
      )}
    </Link>
  );
};

export default Logo;
