import AgentCommonLayout from "containers/AgentService/AccountPage/AgentCommonLayout";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPricingById } from "redux/features/service/serviceSlice";

export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: {
    id: string;
    airlines: {
      logo: string;
      name: string;
    };
    price: string;
  };
}

const FlightCard: FC = ({}) => {
  //const [isOpen, setIsOpen] = useState(defaultOpen);

  const serviceState = useAppSelector((state) => state.service.servicePring);

  const dispatch = useAppDispatch();

  const { pricingId } = useParams();
  useEffect(() => {
    dispatch(getPricingById(pricingId));
  }, []);

  const renderDetailTop = () => {
    return (
      <div>
        <div className="flex flex-col md:flex-row ">
          <div className="flex my-5 md:my-0">
            <div className="flex flex-col items-center flex-shrink-0 py-2">
              <span className="block w-6 h-6 border rounded-full border-neutral-400"></span>
              <span className="flex-grow block my-1 border-l border-dashed border-neutral-400"></span>
              <span className="block w-6 h-6 border rounded-full border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">{"Date de l'événement"}</span>
                <span className="font-semibold ">{serviceState?.date}</span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">{"Flexibilité"}</span>
                <span className="font-semibold ">{serviceState?.flexible ? <p>OUI</p> : <p>NON</p>}</span>
              </div>
            </div>
          </div>

          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          <div>
            <ul className="space-y-1 text-sm text-neutral-500 dark:text-neutral-400 md:space-y-2">
              <li className="font-semibold text">Phone: {serviceState?.phone}</li>
            </ul>
            <ul className="space-y-1 text-sm text-neutral-500 dark:text-neutral-400 md:space-y-2">
              <li className="font-semibold text">Description</li>
              <li>{serviceState?.text}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    return (
      <div className="p-4 border md:p-8 border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {renderDetailTop()}
      </div>
    );
  };

  return (
    <AgentCommonLayout>
      <div
        className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6`}
        data-nc-id="FlightCard"
      >
        <div className={` sm:pr-20 relative  `} data-nc-id="FlightCard">
          <a href="##" className="absolute inset-0" />

          <div className="flex flex-col space-y-6 sm:flex-row sm:items-center sm:space-y-0">
            <div className="block space-y-1 lg:hidden">
              <div className="flex font-semibold">
                <div>
                  <span>{serviceState?.firstName}</span>
                </div>
                <span className="flex justify-center w-12"></span>
              </div>
            </div>

            <div className="hidden lg:block  min-w-[150px] flex-[4] ">
              <div className="text-lg font-medium">
                {serviceState?.firstName} {serviceState?.lastName}
              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5"></div>
            </div>

            <div className="flex-[4] whitespace-nowrap sm:text-right">
              <div>
                <span className="text-xl font-semibold text-secondary-6000"></span>
              </div>
              <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">{serviceState?.email}</div>
            </div>
          </div>
        </div>

        {renderDetail()}
      </div>
    </AgentCommonLayout>
  );
};

export default FlightCard;
