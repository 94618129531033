import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { useTimeoutFn } from "react-use";
import RealEstateSearchForm from "containers/Locations/AllLocationsPage/RealEstateSearchForm";
import { useNavigate } from "react-router";
import RealEstateSearchFormService from "containers/Services/TabFilters";
const HeroSearchForm2RealEstateMobile = () => {
  const [showModal, setShowModal] = useState(false);
  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        className="relative flex items-center w-full px-4 py-2 border rounded-full shadow-lg border-neutral-200 dark:border-white pr-11 "
      >
        <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5 text-white" />
        <div className="flex-1 ml-3 overflow-hidden text-left ">
          <span className="block text-sm font-medium text-white"> Découvrez </span>
          <div className="block mt-0.5 text-xs font-light  dark:text-white text-white">
            <span className="line-clamp-1">Réservez des espaces uniques</span>
          </div>
        </div>
      </button>
    );
  };
  const navigate = useNavigate();
  const [event, setEvent] = useState("");
  const [region, setRegion] = useState("");
  const [field, setField] = useState("");
  const handleSelectionFromChild = (field: string, value: string) => {
    if (field === "events") {
      setEvent(value);
    } else if (field === "region") {
      setRegion(value);
    }
  };

  const handleField = (data: string) => {
    setField(data);
  };
  const handleApplyFields = (selectedField: any) => {};
  return (
    <div className="HeroSearchForm2RealEstateMobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative HeroSearchFormMobile__Dialog z-max" onClose={closeModal}>
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative flex flex-col justify-between flex-1 h-full overflow-hidden ">
                  {showDialog && (
                    <Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>

                      <Tab.List className="flex justify-center w-full pt-12 space-x-6 text-sm font-semibold sm:text-base text-neutral-500 dark:text-neutral-400 sm:space-x-8">
                        {["Espace", "Prestataire"].map((item, index) => (
                          <Tab key={index} as={Fragment}>
                            {({ selected }) => (
                              <div className="relative outline-none select-none focus:outline-none focus-visible:ring-0">
                                <div className={`${selected ? "text-black dark:text-white" : ""}  `}>{item}</div>
                                {selected && (
                                  <span className="absolute inset-x-0 border-b-2 border-black top-full dark:border-white"></span>
                                )}
                              </div>
                            )}
                          </Tab>
                        ))}
                      </Tab.List>
                      <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                        <Tab.Panels className="flex-1 py-4 overflow-y-auto hiddenScrollbar">
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out] mt-4">
                              <RealEstateSearchForm
                                onSelectFromRegionEvent={handleSelectionFromChild}
                                searchClicked={() => {}}
                                handleSearchClick={() => {
                                  navigate(
                                    !!event && !!region
                                      ? `${
                                          "/locations" +
                                          "?event=" +
                                          event?.replace(/\s+/g, "-").toLowerCase() +
                                          "&region=" +
                                          "" +
                                          region.replace(/\s+/g, "-").toLowerCase()
                                        }`
                                      : !!event
                                        ? `${"/locations" + "?event=" + event.replace(/\s+/g, "-").toLowerCase()}`
                                        : !!region
                                          ? `${
                                              "/locations" + "?region=" + region.replace(/\s+/g, "-").toLowerCase()
                                            }`
                                          : ""
                                  );
                                }}
                                handleDelete={() => {}}
                              />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out] mt-4">
                              <RealEstateSearchFormService
                                onSelectField={handleField}
                                onApply={handleApplyFields}
                                handleEventSelection={(data: any) => {}}
                                handleSearchClick={() => {
                                  navigate(
                                    !!field && !!region
                                      ? `${
                                          "/prestations" +
                                          "?field=" +
                                          field.replace(/\s+/g, "-").toLowerCase() +
                                          "&region=" +
                                          "" +
                                          region.replace(/\s+/g, "-").toLowerCase()
                                        }`
                                      : !!field
                                        ? `${
                                            "/prestations" + "?field=" + field.replace(/\s+/g, "-").toLowerCase()
                                          }`
                                        : !!region
                                          ? `${
                                              "/prestations" +
                                              "?region=" +
                                              region.replace(/\s+/g, "-").toLowerCase()
                                            }`
                                          : ""
                                  );
                                }}
                                handleDelete={() => {}}
                                onSelectFromRegionEvent={(value: any) => setRegion(value)}
                              />
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </div>
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2RealEstateMobile;
