import {
  CheckBadgeIcon,
  KeyIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, Radio, Spinner, Typography } from "@material-tailwind/react";
import { LocationFormProps } from "data/reduxInterfaces";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { fetchRules } from "redux/features/Admin/rules/rulesSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

import { z } from "zod";

interface Rules {
  id: number;
  designation: string;
}

const formSchema = z.object({
  handicap: z.boolean({ required_error: "Ce champs est obligatoire." }),
  parking: z.string().min(1, { message: "Ce champs est obligatoire." }),
  wc: z.string().min(1, { message: "Ce champs est obligatoire." }),
  water: z.boolean({ required_error: "Ce champs est obligatoire." }),
  light: z.boolean({ required_error: "Ce champs est obligatoire." }),
  rules: z.array(z.string()).optional(),
  condition: z.array(z.string()).optional(),
});

const LocationAccessibility: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  isLoading,
  handleForm,
  data,
  FormData,
}) => {
  const dispatch = useAppDispatch();

  const rulesState = useAppSelector((state) => state.featureRules);

  const [dataLoading, setDataLoading] = useState(true);

  const [tag, setTag] = useState<string>("");

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      handicap: data?.handicap ?? undefined,
      parking: data?.parking ?? "",
      wc: data?.wc ?? "",
      water: data?.water ?? undefined,
      light: data?.light ?? undefined,
      rules: data?.rules ?? [],
      condition: data?.condition ?? [],
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    dispatch(fetchRules());
  }, []);

  useEffect(() => {
    if (data) {
      setValue("handicap", data.handicap);
      setValue("parking", data.parking);
      setValue("wc", data.wc);
      setValue("water", data.water);
      setValue("light", data.light);
      setValue("rules", data.rules);
      setValue("condition", data.condition);
    }
  }, [data]);

  useEffect(() => {
    if (rulesState.rules) {
      setDataLoading(false);
    }
  }, [rulesState.rules]);

  useEffect(() => {
    if (handleForm.type === "chekLocationFormFive") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const handleSelect = (ruleDesignation: string, isChecked: boolean) => {
    const updatedselectedRules = isChecked
      ? [...(getValues("rules") ?? []), ruleDesignation]
      : getValues("rules")?.filter((rule: string) => rule !== ruleDesignation);

    setValue("rules", updatedselectedRules as string[]);
    trigger("rules");
  };

  const handleAddTag = () => {
    if (tag.trim() !== "") {
      const dd = getValues("condition") || [];
      setValue("condition", [...dd, tag.trim()]);
      trigger("condition");
      setTag("");
    }
  };

  const handleRemoveTag = (index: number) => {
    const newTags = [...(getValues("condition") || [])];
    newTags.splice(index, 1);
    setValue("condition", newTags);
    trigger("condition");
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  return (
    <Fragment>
      <form className="flex flex-col md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full md:w-3/4 md:mr-4">
          <div className="space-y-8">
            <div>
              <h2 className="text-2xl font-semibold">Accessibilité et Règles de l'espace</h2>

              <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
            </div>

            <div>
              <label className="mb-3 font-semibold" htmlFor="">
                Accès aux personnes à mobilité réduite *
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">Oui</Typography>}
                  disabled={isLoading}
                  name={"handicap"}
                  checked={getValues("handicap") === true}
                  onChange={() => {
                    setValue("handicap", true);
                    trigger("handicap");
                  }}
                />
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">Non</Typography>}
                  disabled={isLoading}
                  name={"handicap"}
                  checked={getValues("handicap") === false}
                  onChange={() => {
                    setValue("handicap", false);
                    trigger("handicap");
                  }}
                />
              </div>
              {errors.handicap?.message && (
                <div className="text-xs text-red-500">{`${errors.handicap?.message}`}</div>
              )}
            </div>

            <div>
              <label className="mb-3 font-semibold" htmlFor="">
                Accès au stationnement *
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">libre</Typography>}
                  disabled={isLoading}
                  name={"parking"}
                  checked={getValues("parking") === "libre"}
                  onChange={() => {
                    setValue("parking", "libre");
                    trigger("parking");
                  }}
                />
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">privé</Typography>}
                  disabled={isLoading}
                  name={"parking"}
                  checked={getValues("parking") === "privé"}
                  onChange={() => {
                    setValue("parking", "privé");
                    trigger("parking");
                  }}
                />
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">éxterieur</Typography>}
                  disabled={isLoading}
                  name={"parking"}
                  checked={getValues("parking") === "éxterieur"}
                  onChange={() => {
                    setValue("parking", "éxterieur");
                    trigger("parking");
                  }}
                />
              </div>
              {errors.parking?.message && (
                <div className="text-xs text-red-500">{`${errors.parking?.message}`}</div>
              )}
            </div>

            <div>
              <label className="mb-3 font-semibold" htmlFor="">
                Accès aux toilettes *
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">intérieur</Typography>}
                  disabled={isLoading}
                  name={"wc"}
                  checked={getValues("wc") === "intérieur"}
                  onChange={() => {
                    setValue("wc", "intérieur");
                    trigger("wc");
                  }}
                />
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">éxterieur</Typography>}
                  disabled={isLoading}
                  name={"wc"}
                  checked={getValues("wc") === "éxterieur"}
                  onChange={() => {
                    setValue("wc", "éxterieur");
                    trigger("wc");
                  }}
                />
              </div>
              {errors.wc?.message && <div className="text-xs text-red-500">{`${errors.wc?.message}`}</div>}
            </div>

            <div>
              <label className="mb-3 font-semibold" htmlFor="">
                Accès au point d'eau *
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">Oui</Typography>}
                  disabled={isLoading}
                  name={"water"}
                  checked={getValues("water") === true}
                  onChange={() => {
                    setValue("water", true);
                    trigger("water");
                  }}
                />

                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">Non</Typography>}
                  disabled={isLoading}
                  name={"water"}
                  checked={getValues("water") === false}
                  onChange={() => {
                    setValue("water", false);
                    trigger("water");
                  }}
                />
              </div>
              {errors.water?.message && <div className="text-xs text-red-500">{`${errors.water?.message}`}</div>}
            </div>

            <div>
              <label className="mb-3 font-semibold" htmlFor="">
                Éclairage extérieur *
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">Oui</Typography>}
                  disabled={isLoading}
                  name={"light"}
                  checked={getValues("light") === true}
                  onChange={() => {
                    setValue("light", true);
                    trigger("light");
                  }}
                />

                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">Non</Typography>}
                  disabled={isLoading}
                  name={"light"}
                  checked={getValues("light") === false}
                  onChange={() => {
                    setValue("light", false);
                    trigger("light");
                  }}
                />
              </div>
              {errors.light?.message && <div className="text-xs text-red-500">{`${errors.light?.message}`}</div>}
            </div>

            <div className="w-full md:w-3/4 md:mr-4">
              <div className="my-5 space-y-8">
                <div>
                  <label className="font-semibold text " htmlFor="">
                    Liste des règles
                  </label>
                  <div
                    className={`grid grid-cols-1   mt-6 ${
                      rulesState.rules.length > 10 ? "sm:grid-cols-2 lg:grid-cols-3" : ""
                    }`}
                  >
                    {dataLoading ? (
                      <div className="flex items-center justify-center mt-16">
                        <p>
                          <Spinner color="blue" className="w-12 h-12" />
                        </p>
                      </div>
                    ) : (
                      rulesState.rules?.map((rule: Rules) => (
                        <Checkbox
                          label={<Typography className="text-dark dark:text-white">{rule.designation}</Typography>}
                          key={rule.designation}
                          name={rule.designation}
                          checked={!!getValues("rules")?.includes(rule.designation)}
                          onChange={(e) => handleSelect(rule.designation, e.target.checked)}
                        />
                      ))
                    )}
                  </div>
                </div>

                <div className="flow-root">
                  <label className="font-semibold text " htmlFor="">
                    Autre régles
                  </label>
                  <div className="ml-12 -my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
                    {getValues("condition")?.map((tag: any, index: any) => (
                      <div key={index} className="flex items-center justify-between py-3">
                        <span className="font-medium text-neutral-6000 dark:text-neutral-400">{tag}</span>
                        <i
                          className="text-2xl cursor-pointer text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100"
                          onClick={() => handleRemoveTag(index)}
                        ></i>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col space-y-3 sm:flex-row sm:justify-between sm:space-y-0 sm:space-x-5">
                  <Input
                    className="!h-full"
                    placeholder="Régle..."
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                  />
                  <ButtonPrimary className="flex-shrink-0" onClick={handleAddTag} type="button">
                    <i className="text-xl las la-plus"></i>
                    <span className="ml-3">Ajouter</span>
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-4 md:w-1/3 md:mt-0">
          <div className="p-4 border-2 border-indigo-800">
            <div className="flex items-center mt-5 ml-5">
              <CheckBadgeIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Accessibilité</h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mr-5 -ml-8 text-sm">
                Cette section concerne l'accessibilité et les installations disponibles dans l'espace. Veuillez
                renseigner les informations suivantes :
              </p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <UserCircleIcon className="inline-block w-5" />
              <h5 className="inline-block mt-0 ml-2 text-lg font-semibold">Personnes à mobilité réduite</h5>
            </div>
            <div className="mt-2 ml-16">
              <p className="mt-2 -ml-8 text-sm">Accessible ou non</p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <KeyIcon className="inline-block w-5" />
              <h5 className="inline-block mt-0 ml-2 text-lg font-semibold">Stationnement </h5>
            </div>
            <div className="mt-2 ml-16">
              <p className="mt-2 -ml-8 text-sm">Libre, privé ou extérieur</p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <KeyIcon className="inline-block w-5" />
              <h5 className="inline-block mt-0 ml-2 text-lg font-semibold">Installations </h5>
            </div>
            <div className="mt-2 ml-16">
              <p className="mt-2 -ml-8 text-sm">WC et point d'eau disponibles.</p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <LightBulbIcon className="inline-block w-5" />
              <h5 className="inline-block mt-0 ml-2 text-lg font-semibold">Éclairage extérieur </h5>
            </div>
            <div className="mt-2 ml-16">
              <p className="mt-2 mb-3 -ml-8 text-sm">Présent ou non. </p>
            </div>
            <div className="flex items-center mt-10 ml-5">
              <ShieldCheckIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Règles de l'espace </h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mb-3 mr-5 -ml-8 text-sm">Liste des règles et restrictions à respecter.</p>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default LocationAccessibility;
