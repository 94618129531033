import NcImage from "shared/NcImage/NcImage";
import AboutData from "./about.json";
const About = () => {
  const image = `static-files/how-it-work.png`;

  return (
    <div className="dark:bg-gray-800 ">
      <div className="container ">
        {AboutData.aboutOne.map((data, index) => (
          <div className="lg:flex row mb:flex row" key={index}>
            <div className="w-full lg:w-1/2 lg:mt-16 ">
              {" "}
              {/* Adjusted the width classes */}
              <div className="thumbnail-wrapper sm:justify-center">
                <div className={`thumbnail image`}>
                  <NcImage
                    // parallaxData={{ x: 0, y: -20 }}
                    src={process.env.REACT_APP_CLOUD_FRONT + image}
                    // width={img.width}
                    // height={img.height}
                    alt="Education NcImages"
                  />
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 mb-32">
              {" "}
              {/* Adjusted the width classes */}
              <div className="sm:mt-4 lg:pl-16 lg:pt-12 col-lg-6">
                <div className="">
                  <div className="section-title text-start">
                    <h2 className="text-4xl font-bold text-black dark:text-white">
                      {data.title} <br /> {data.subTitle}
                    </h2>
                  </div>
                  <p className="description mt--30">{data.desc}</p>
                  {data.children.map((item, innerIndex) => (
                    <div className="rbt-feature-wrapper mt--40" key={innerIndex}>
                      <div className="rbt-feature feature-style-1">
                        <div className={`icon ${item.isPrimary}`}>
                          <i className={item.icon}></i>
                        </div>
                        <div className="feature-content">
                          <h6 className="feature-title">{item.title}</h6>
                          <p className="feature-description">{item.info}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
