import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, Textarea } from "@material-tailwind/react";
import { AuthContext } from "context/appContext";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaRegWindowClose } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import { getService, requestPricing, setServicePring } from "redux/features/service/serviceSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { z } from "zod";

const formSchema = z.object({
  firstName: z.string().min(1, { message: "Le Prénom est obligatoire." }),
  lastName: z.string().min(1, { message: "Le nom  est obligatoire." }),
  email: z.string().email(),
  date: z.string().min(1, { message: "date est obligatoire." }),
  visitor: z.string().min(1, { message: "Le nombre de visiteur est obligatoire." }),
  phone: z.string().min(1, { message: "Numéro téléphone est obligatoire." }),
  text: z.string().min(1, { message: "La description est obligatoire." }),
  flexible: z.boolean(),
});

interface PricingModalProps {
  onClose: (value: boolean) => void;
  id: any;
}
const PricingModal: React.FC<PricingModalProps> = ({ onClose, id }) => {
  const authContext = useContext(AuthContext);
  const serviceState = useAppSelector((state) => state.service);

  const {
    register,
    handleSubmit: handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { flexible: false },
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    await dispatch(
      requestPricing({
        serviceAgentId: serviceState?.serviceData?.ServiceAgent?.id,
        serviceId: serviceState?.serviceData?.id,
        clientId: authContext.user?.profil?.id,
        service: serviceState?.serviceData?.title,
        ...data,
      })
    );
    setOpen(false);
    onClose(open);
    if (serviceState?.serviceData?.id) {
      dispatch(getService(serviceState?.serviceData?.id));
    }
  };
  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    onClose(open);
  };

  const handleChange = (checked: boolean) => {
    setValue("flexible", checked);
    dispatch(setServicePring({ isWebHelp: checked }));
  };

  const phoneInputStyle = {
    borderRadius: "0.375rem",
    backgroundColor: "#FFFFFF",
  };

  const visitorOptions = ["0-50", "51-100", "101-150", "151-200", "201-250", "251-300", "301 ou plus"];
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    switch (serviceState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [serviceState.loading]);

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col  max-w-[27rem] h-[44rem]">
        <div className="self-end mr-5">
          <FaRegWindowClose className="text-xl cursor-pointer" onClick={handleClose} />
        </div>
        <h2 className="sticky top-0 p-4 w-full text-2xl font-extrabold text-center border-b border-grey">
          Demander un devis
        </h2>
        <main className="overflow-y-scroll flex-1">
          <p className="mt-3 text-sm">Nom et Prénom *</p>
          <div className="flex space-x-4">
            <Input type="text" placeholder="Prénom" className="mt-1" {...register("firstName")} />

            <Input type="text" placeholder="Nom" className="mt-1" {...register("lastName")} />
          </div>

          <div className="flex justify-between">
            {errors.firstName?.message && (
              <div className="mt-2 text-xs text-red-500">{errors.firstName?.message}</div>
            )}

            {errors.lastName?.message && (
              <div className="mt-2 mr-16 text-xs text-red-500">{errors.lastName?.message}</div>
            )}
          </div>

          <p className="mt-3 text-sm">Email *</p>

          <Input type="input" placeholder="example@example.com" className="mt-1" {...register("email")} />
          {errors.email?.message && <div className="text-xs text-red-500">{errors.email?.message}</div>}

          <p className="mt-3 text-sm">Date de l'évènement *</p>

          <Input type="date" placeholder="Date de l'évènement" {...register("date")} min={currentDate} />

          {errors.date?.message && <div className="text-xs text-red-500">{errors.date?.message}</div>}
          <p className="mt-3 text-sm"> la date de l'événement est flexible</p>

          <div className="grid grid-cols-1 gap-5 mt-6 lg:grid-cols-3">
            <Checkbox
              key="true"
              label="Oui"
              value="true"
              checked={getValues("flexible")}
              onChange={(e) => handleChange(e.target.checked)}
            />
          </div>

          <p className="mt-3 text-sm">Nombre des visiteurs *</p>

          <select value={getValues("visitor")} onChange={(e) => setValue("visitor", e.target.value)}>
            <option>Selectionner une valeur</option>
            {visitorOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          {errors.visitor?.message && <div className="text-xs text-red-500">{errors.visitor?.message}</div>}
          <p className="mt-3 text-sm">Numéro téléphone *</p>

          <PhoneInput
            country={"fr"}
            inputStyle={phoneInputStyle}
            containerStyle={phoneInputStyle}
            buttonStyle={phoneInputStyle}
            dropdownStyle={phoneInputStyle}
            searchStyle={phoneInputStyle}
            inputProps={{ required: true }}
            value={getValues("phone")}
            onChange={(value) => setValue("phone", value)}
          />

          {errors.phone?.message && <div className="text-xs text-red-500">{errors.phone?.message}</div>}

          <p className="mt-3 text-sm">Renseignez-vous sur les prix *</p>

          <Textarea placeholder="Demander les prix ..." rows={3} className="rounded-none" {...register("text")} />

          {errors.text?.message && <div className="text-xs text-red-500">{errors.text?.message}</div>}

          <div className="">
            <p className="mt-3 font-semibold">
              En cliquant sur « Demander un devis », vous acceptez que vos informations soient partagées avec le
              prestataire et utilisées pour créer un compte sur Event To Bee.
            </p>
          </div>
        </main>
        <ButtonPrimary
          className="px-4 py-2 w-full text-white bg-indigo-600 rounded"
          type="submit"
          loading={isLoading}
        >
          Demander un devis{" "}
        </ButtonPrimary>
      </div>
    </form>
  );
};
export default PricingModal;
