import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "@material-tailwind/react";
import { FC, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Input from "shared/Input/Input";
import OnboardingTour, { stepStyle } from "utils/tours/OnboardingTour";
import { z } from "zod";

const formSchema = z
  .object({
    // login: z.string().min(1, { message: "Veuillez entrer un pseudo valide." }),
    email: z.string().email({ message: "Veuillez entrer une adresse email valide." }),
    emailConfirm: z.string().email(),
    password: z
      .string()
      .regex(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?À-ÿ]).{8,26}$/,
        "Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial."
      ),
    passwordConfirm: z.string(),
    role: z.string().min(1, { message: "Veuillez choisir une option." }),
  })
  .refine((data) => data.email === data.emailConfirm, {
    message: "L'dresses e-mail ne correspondent pas.",
    path: ["emailConfirm"],
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Le mot de passe semble erroné. Veuillez réessayer.",
    path: ["passwordConfirm"],
  });

export interface UserFormProps {
  className?: string;
  isLoading: boolean;
  role: string;
  handleForm: Event;
  errorUserForm?: string;
  FormData: (data: any) => void;
}

const UserForm: FC<UserFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  role,
  handleForm,
  errorUserForm,
  FormData,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassowrd, setShowConfirmPassword] = useState(false);

  const steps = [
    {
      selector: ".username",
      content: "Utilisé comme préfixe afin de caractériser la seconde partie d'un mot.",
      stepStyle,
    },
    {
      selector: ".email",
      content:
        "Une adresse électronique, également appelée adresse e-mail, est une chaîne de caractères (exemple@gmail.com) utilisé une adresse correcte.",
      stepStyle,
    },
    {
      selector: ".confirm-email",
      content: "Pour vérifier que vous avez saisi la bonne adresse e-mail.",
      stepStyle,
    },
    {
      selector: ".password ",
      content: "Un mot de passe utilisé pour accéder à votre compte.",
    },
    {
      selector: ".confirm-password ",
      content: "Pour vous assurer que vous avez saisi correctement le mot de passe.(Exemple-123).",
    },
  ];

  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      role: role,
    },
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    const { emailConfirm, passwordConfirm, ...formData } = data;

    FormData(formData);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassowrd);
  };

  useEffect(() => {
    const updatedRequirements = {
      minLength: getValues("password")?.length >= 8,
      hasLowercase: /[a-z]/.test(getValues("password")),
      hasUppercase: /[A-Z]/.test(getValues("password")),
      hasNumber: /[0-9]/.test(getValues("password")),
      hasSpecialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?À-ÿ]/.test(getValues("password")),
    };

    setPasswordRequirements(updatedRequirements);
  }, [watch("password")]);

  useEffect(() => {
    if (handleForm.type === "chekFormEvent") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  return (
    <>
      <div className={className}>
        <h2 className="  mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Inscription
        </h2>

        {errorUserForm && (
          <Alert color="red" className="text-xs">
            {errorUserForm}
          </Alert>
        )}

        <form className="grid grid-cols-1 gap-6">
          {/* <label className="block username">
            <span className="text-neutral-800 dark:text-neutral-200">
              Pseudo <span className="text-xs ">*</span>
            </span>
            <Input
              type="text"
              placeholder="username"
              className={` mt-1 ${errors.password?.message ? "border-red-500" : ""
                }`}
              disabled={isLoading}
              {...register("login")}
            />
            {errors.password?.message && (
              <div className="mt-1 text-xs text-red-500">
                {errors.password?.message}
              </div>
            )}
          </label> */}

          <label className="block email">
            <span className="text-neutral-800 dark:text-neutral-200">
              Adresse Email <span className="text-xs">*</span>
            </span>
            <Input
              type="email"
              placeholder="Saisir votre email"
              className={`mt-1 ${errors.email?.message ? "border-red-500" : ""}`}
              disabled={isLoading}
              {...register("email")}
              onCopy={(e) => e.preventDefault()}
            />
            {errors.email?.message && <div className="mt-1 text-xs text-red-500">{errors.email?.message}</div>}
          </label>

          <label className="block confirm-email">
            <span className="text-neutral-800 dark:text-neutral-200">
              Confirmation Adresse Email <span className="text-xs">*</span>
            </span>
            <Input
              type="email"
              placeholder="Saisir à nouveau votre email"
              className={`mt-1 ${errors.emailConfirm?.message ? "border-red-500" : ""}`}
              disabled={isLoading}
              {...register("emailConfirm")}
            />
            {errors.emailConfirm?.message && (
              <div className="mt-1 text-xs text-red-500">{errors.emailConfirm?.message}</div>
            )}
          </label>

          <label className="relative block password ">
            <span className="text-neutral-800 dark:text-neutral-200">
              Mot de passe <span className="text-xs ">*</span>
            </span>
            <div className="relative">
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Saisir votre mot de passe"
                className={`w-full pr-3 mt-1 ${errors.password?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                {...register("password")}
                onCopy={(e) => e.preventDefault()}
              />
              <span
                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <div className="flex items-center">
              {passwordRequirements.minLength ? (
                <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
              ) : (
                <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
              )}
              <span className={`mt-1 text-sm ${passwordRequirements.minLength ?? "text-green-700"}`}>
                8 caractères minimum
              </span>
            </div>

            <div className="flex items-center">
              {passwordRequirements.hasLowercase ? (
                <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
              ) : (
                <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
              )}
              <span className={`mt-1 text-sm ${passwordRequirements.hasLowercase ?? "text-green-700"}`}>
                1 minuscule
              </span>
            </div>

            <div className="flex items-center">
              {passwordRequirements.hasUppercase ? (
                <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
              ) : (
                <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
              )}
              <span className={`mt-1 text-sm ${passwordRequirements.hasUppercase ?? "text-green-700"}`}>
                1 majuscule
              </span>
            </div>

            <div className="flex items-center">
              {passwordRequirements.hasNumber ? (
                <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
              ) : (
                <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
              )}
              <span className={`mt-1 text-sm ${passwordRequirements.hasNumber ?? "text-green-700"}`}>
                1 chiffre
              </span>
            </div>

            <div className="flex items-center">
              {passwordRequirements.hasSpecialChar ? (
                <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
              ) : (
                <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
              )}
              <span className={`mt-1 text-sm ${passwordRequirements.hasSpecialChar ?? "text-green-700"}`}>
                1 caractère spécial
              </span>
            </div>

            {errors.password?.message && (
              <div className="mt-1 text-xs text-red-500">{errors.password?.message}</div>
            )}
          </label>

          <label className="relative block  confirm-password">
            <span className="text-neutral-800 dark:text-neutral-200">
              Confirmation Mot de passe <span className="text-xs ">*</span>
            </span>
            <div className="relative">
              <Input
                type={showConfirmPassowrd ? "text" : "password"}
                placeholder="Saisir à nouveau votre mot de passe"
                className={`w-full pr-3 mt-1 ${errors.password?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                {...register("passwordConfirm")}
                onCopy={(e) => e.preventDefault()}
              />
              <span
                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassowrd ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {errors.passwordConfirm?.message && (
              <div className="mt-1 text-xs text-red-500">{errors.passwordConfirm?.message}</div>
            )}
          </label>
        </form>
      </div>
      <OnboardingTour steps={steps} page_name={"userForm"}></OnboardingTour>
    </>
  );
};

export default UserForm;
