import React, { FC, useContext, useEffect, useState } from "react";
import Heading2 from "components/Heading/Heading2";
import FlightCard, { FlightCardProps } from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ReservationDetailedCard from "components/FlightCard/ReservationsDetailedCard";
import jwtDecode from "jwt-decode";
import Pagination from "shared/Pagination/Pagination";
import { AuthContext } from "context/appContext";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { fetchReservationByOwner } from "redux/features/reservations/reservationSlice";

export interface SectionGridFilterCardProps {
  className?: string;
}

interface Reservation {
  id: number;
  startDate: string;
  endDate: string;
  startHour: string;
  endHour: string;
  nbHour: string;
  validation: boolean;
  detail: string;
  clientId: number;
  locationId: number;
  ownerId: number;
  owner: any;
  client: any;
  createdAt: any;
  location: any;
  visitor: any;
  selectedDate: any;
  images: any;
  price: number;
}

const SectionGridDetailedCard: FC<SectionGridFilterCardProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  const [loading, setIsLoading] = useState(false);
  const profilId = authContext?.user?.profil?.id;
  const dispatch = useAppDispatch();
  let reservationState = useAppSelector((state) => state.featureReservation.reservations);
  useEffect(() => {
    if (profilId) {
      setIsLoading(true);
      dispatch(fetchReservationByOwner(profilId)).then(() => setIsLoading(false));
    }
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const paginatedReservation = reservationState?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(reservationState?.length / itemsPerPage);
  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
      <Heading2
        heading="Mes réservations"
        subHeading={
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {reservationState?.length}
            <span className="mx-2">·</span>
            réservations
          </span>
        }
      />

      <div className="grid grid-cols-1 gap-6 lg:bg-neutral-50 lg:dark:bg-black/20 rounded-3xl">
        {paginatedReservation.map((item, index) => (
          <ReservationDetailedCard defaultOpen={!index} key={index} data={item} />
        ))}

        <div className="flex items-center justify-center mt-16">
          {typeof totalPages === "number" && (
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionGridDetailedCard;
