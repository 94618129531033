import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "components/SectionHero/SectionHero";

import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionGridFilterCard2 from "containers/Services/ServiceGridFilterCard";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useEffect } from "react";
import { fetchEvents } from "redux/features/Admin/Events/eventSlice";
import { fetchFields } from "redux/features/Admin/Fields/fieldSlice";
import { fetchSliders } from "redux/features/Admin/Sliders/sliderSlice";
import SectionVideos from "./SectionVideos";
import CallToActionPage from "elements/call-to-action";
import "slick-carousel/slick/slick-theme.css";
import { getAllHomeVideos } from "redux/features/Admin/homeVideo/homeVideoSlice";
import { Helmet } from "react-helmet";
import { getHomeLocations } from "redux/features/location/locationSlice";
import LocationsGridFilterCard from "containers/Locations/AllLocationsPage/LocationsGridFilterCard";
import StayCard from "components/StayCard/StayCard";
import LoactionGridCard from "components/HomeLocationCards/LoactionGridCard";

function PageHome() {
  const dispatch = useAppDispatch();

  const eventState = useAppSelector((state) => state.featureEvent);
  const fieldState = useAppSelector((state) => state.featureField);
  const locationState = useAppSelector((state) => state.location?.allLocations);
  const homeVideoStates = useAppSelector((state) => state.videoHome.allVideos);

  useEffect(() => {
    dispatch(getHomeLocations());
    dispatch(fetchSliders());
    dispatch(fetchEvents());
    dispatch(fetchFields());
    dispatch(getAllHomeVideos());
  }, []);

  return (
    <>
      <Helmet>
        <title>Event To Bee </title>
        <meta
          name="description"
          content="Trouvez les meilleurs espaces et prestations à louer pour votre événement sur Event To Bee."
        />
      </Helmet>
      <div className="relative overflow-hidden nc-PageHome">
        <div className="home_class dark:bg-gray-800 ">
          <div className="absolute top-0 left-0 z-0 w-full overflow-hidden" style={{ height: 600 }}>
            <video
              src={homeVideoStates[0]?.url ? process.env.REACT_APP_CLOUD_FRONT + homeVideoStates[0]?.url : ""}
              width={1920}
              autoPlay
              loop
              muted
            ></video>
          </div>

          <div className="container space-y-16 lg:space-y-28">
            <SectionHero />
          </div>
        </div>

        <div className="container relative mb-24 space-y-24 lg:space-y-14 lg:mb-28">
          {/* HOW IT WORK SECTION 1 */}
          <div className="lg:mt-16 xl:mt-24">
            <SectionHowItWork />
          </div>

          {/* LOCATIONS SECTION */}
          <div className="relative py-10">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Liste des espaces récents"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName="ListingStayMapPage"
            />
            <br />
            <div className="pt-16 mt-5">
              <LoactionGridCard data={locationState} />
            </div>
          </div>

          {/* EVENTS SECTION */}
          <div className="relative py-10">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Un événement, un lieu, des moments partagés"
              subHeading=""
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName="ListingStayMapPage"
            />
            <br />

            <div className="relative py-10 pt-16 mt-5">
              <SectionGridFilterCard2 fields={eventState.events} feature={"/locations?event="} />
            </div>
          </div>

          {/* SERVICES SECTION */}

          <div className="relative py-10">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Une sélection de professionnels pour chaque événement"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName="ListingStayMapPage"
            />
            <br />
            <div className="pt-16 mt-5">
              <SectionGridFilterCard2 fields={fieldState.fields} feature={"/prestations?field="} />
            </div>
          </div>

          <CallToActionPage />

          {/* VIDEO SECTION */}
          <SectionVideos />
        </div>
      </div>
    </>
  );
}

export default PageHome;
