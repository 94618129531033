const server = process.env.REACT_APP_SERVER_URL || "";

export const blogApi = {
  addCommentBlog: async (formData: any) => {
    try {
      const { blogId, clientId, text } = formData;
      const response = await fetch(`${server}/api/blog-comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ blogId: blogId, clientId: clientId, text: text }),
      });
      if (response.ok) {
        const data = await response.json();

        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw error;
    }
  },
  addBlog: async (data: any) => {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "tags") {
        formData.append(key, data[key]);
      }
    }
    formData.append("tags", data.tags);
    const result = await fetch(`${server}/api/blog/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },
  editBlog: async (data: any, id: any) => {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "tags") {
        formData.append(key, data[key]);
      }
    }
    formData.append("tags", data.tags);
    const result = await fetch(`${server}/api/blog/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },
  getBlogs: async () => {
    const response = await fetch(`${server}/api/blog`);
    return response.json();
  },
  getSimilarBlogs: async (id: any) => {
    const response = await fetch(`${server}/api/blog/similar/${id}`);
    return response.json();
  },
  getBlogsByTag: async (tag: string) => {
    const response = await fetch(`${server}/api/blog/tag/${tag}`);
    return response.json();
  },
  getBlogsById: async (id: string) => {
    const response = await fetch(`${server}/api/blog/${id}`);
    return response.json();
  },
  getBlogsByCreator: async (id: number) => {
    const response = await fetch(`${server}/api/blog/user/${id}`);
    return response.json();
  },

  getAllTags: async () => {
    const response = await fetch(`${server}/api/blog/all-tags/`);
    return response.json();
  },
  deleteBlog: async (id: any) => {
    const response = await fetch(`${server}/api/blog/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json();
  },
  getCommentByBlog: async (id: string) => {
    const response = await fetch(`${server}/api/blog-comment/blog/${id}`);
    const comments = await response.json();
    return comments.reverse();
  },
};
