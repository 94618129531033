import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { Input } from "@material-tailwind/react";
import { useAppDispatch } from "hooks/hooks";
import React, { FC, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { filterAllReservation } from "redux/features/reservations/reservationSlice";

export interface AllLocationsPageProps {
  children?: React.ReactNode;
}

const AllLocationsPage: FC<AllLocationsPageProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const [searchTerm, setSearchTerm] = useState('');

  // const handleSearch = () => {
  //     if (searchTerm.trim() !== '') {
  //     dispatch(filterAllReservation({ reference: searchTerm })).then(() => {
  //         navigate('/client/search');
  //         setSearchTerm('');
  //     });
  // }
  // };

  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="">
      <div className="">
        <div className="">
          <div className="">
            <div className="bg-neutral-50 dark:bg-neutral-900">
              <div className="pt-1 bg-white border-b border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800">
                <div className="container">
                  <div className="flex overflow-x-auto justify-center space-x-8 text-center md:space-x-14 hiddenScrollbar">
                    <NavLink
                      to="/dashboard/locations/unApprouved"
                      className={({ isActive }) =>
                        `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                          !isActive ? "border-transparent" : "border-primary-500"
                        }`
                      }
                    >
                      Espaces en cours de traitement
                    </NavLink>
                    <NavLink
                      to="/dashboard/locations/approuved"
                      className={({ isActive }) =>
                        `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                          !isActive ? "border-transparent" : "border-primary-500"
                        }`
                      }
                    >
                      Espaces publié
                    </NavLink>

                    <NavLink
                      to="/dashboard/locations/incompleted"
                      className={({ isActive }) =>
                        `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                          !isActive ? "border-transparent" : "border-primary-500"
                        }`
                      }
                    >
                      Espaces non complétés
                    </NavLink>

                    {/* {path === '/clint/search' && (
                                            <NavLink
                                                to="/client/search"
                                                className={({ isActive }) =>
                                                    `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                                                        !isActive ? 'border-transparent' : 'border-primary-500'
                                                    }`
                                                }
                                            >
                                                Résultat
                                            </NavLink>
                                        )} */}

                    {/* <div className="flex justify-end items-center">
                                            <div className="flex relative">
                                                <Input
                                                    type="text"
                                                    placeholder="Rechercher..."
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                                <MagnifyingGlassCircleIcon
                                                    onClick={handleSearch}
                                                    className="absolute right-3 top-1/2 w-6 h-6 text-gray-500 transform -translate-y-1/2 hover:text-eventToBee"
                                                />
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className="mt-5">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllLocationsPage;
