import LoginModal from "components/LoginModal/LoginModal";
import { AuthContext } from "context/appContext";

import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";

export interface SectionBecomeAnOwnerProps {
  className?: string;
  ownerImg?: string;
  agentImg?: string;
  type?: string;
}

const customStyle = {
  fontSize: "1.15em",
};

const SectionBecomeAnOwner: FC<SectionBecomeAnOwnerProps> = ({
  className = "",
  ownerImg = `static-files/ajouter-votre-espace-07.png`,
  agentImg = `static-files/prestataire_espace.png`,
  type,
}) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const onSubmit = () => {
    if (!authContext?.user) {
      setShowLoginModal(true);
      return;
    } else {
      navigate(type === "location" ? "/add-location" : "/add-service");
    }
  };

  return (
    <div
      className={`  add-page-padding  flex relative flex-col items-center nc-SectionBecomeAnOwner lg:flex-row ${className}`}
      data-nc-id="SectionBecomeAnOwner"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:ml-10  lg:w-3/5 xl-pl-8 ">
        <h2 className="mt-6 text-3xl font-semibold sm:text-3xl sm:mt-11">
          {type === "location" ? "Création d'un espace" : "Création de prestation"}
        </h2>
        <span className="block mt-6 text-neutral-600 dark:text-neutral-500" style={customStyle}>
          {type === "location" ? "Commencer pour créer votre espace !" : "Commencer pour créer votre prestation !"}
        </span>

        <ButtonPrimary
          type="button"
          className=" mt-6 sm:mt-11"
          onClick={onSubmit}

          /* href={type === 'location' ? '/add-location' : '/add-service'}
           */
        >
          Commencer
        </ButtonPrimary>
      </div>
      <div className="flex-grow ">
        <NcImage
          src={
            type === "location"
              ? process.env.REACT_APP_CLOUD_FRONT + ownerImg
              : process.env.REACT_APP_CLOUD_FRONT + agentImg
          }
          className="w-12/12 "
        />{" "}
      </div>
      {showLoginModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="flex flex-col items-center p-6 bg-white rounded-lg dark:bg-neutral-900">
              <div className="self-end mb-4">
                <ButtonSecondary onClick={() => setShowLoginModal(false)}>X</ButtonSecondary>
              </div>
              <LoginModal LoginModal={(e) => setShowLoginModal(e)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionBecomeAnOwner;
