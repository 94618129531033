import MyRouter from "routers/index";

import * as Sentry from "@sentry/react";
import Cookies from "shared/Cookies/cookies";

function App() {
  return (
    <div className="text-base bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
      <Cookies />
    </div>
  );
}

export default Sentry.withProfiler(App);
