const server = process.env.REACT_APP_SERVER_URL || "";

export const createField = {
  add: async (formData: any) => {
    try {
      const form = new FormData();

      form.append("designation", formData.designation);

      if (formData.images) {
        for (let i = 0; i < formData.images.length; i++) {
          form.append("images", formData.images[i]);
        }
      }
      const response = await fetch(`${server}/api/field`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: form,
      });
      if (response.ok) {
        const data = await response.json();

        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw error;
    }
  },

  verify: async () => {
    try {
      const response = await fetch(`${server}/api/field`);
      if (!response.ok) {
        throw new Error("Failed to fetch fields");
      }
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  delete: async (id: number) => {
    try {
      const response = await fetch(`${server}/api/field/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete field");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },
  getById: async (id: any) => {
    try {
      const response = await fetch(`${server}/api/field/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch fields");
      }
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  update: async (data: any, id: any) => {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === "oldImages") {
          formData.append(key, JSON.stringify(data[key]));
        } else if (key === "newImages") {
          for (let i = 0; i < data[key].length; i++) {
            formData.append("newImages", data[key][i]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    const result = await fetch(`${server}/api/field/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },
};
