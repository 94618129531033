import React, { FC } from "react";

interface Props {
  dayOfMonth: number;
  date?: Date | undefined;
  excludedDays?: string[];
  unavailableDays?: string[];

}

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth, date, excludedDays, unavailableDays }) => {
  if (!date) return <span>{dayOfMonth}</span>;

  const formattedDate = formatDate(date);
  const isExcluded = excludedDays?.includes(formattedDate);
  const isUnavailable = unavailableDays?.includes(formattedDate);

  const dayClass = `react-datepicker__day_span ${isExcluded ? "react-datepicker__day_span_unavailable" : ""} ${isUnavailable ? "react-datepicker__day_span_blocked" : ""}`;

  return <span className={dayClass}>{dayOfMonth}</span>;
};

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
;
};

export default DatePickerCustomDay;
