import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaRegWindowClose } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getAllClients, resetLoading, validateClient } from "redux/features/auth/authSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Select from "shared/Select/Select";
import { z } from "zod";

const formSchema = z.object({
  validation: z.string(),
});

interface UpdateModalProps {
  onClose: (value: boolean) => void;
  id?: any;
  title?: any;
}
const ValidateClientModal: React.FC<UpdateModalProps> = ({ onClose, id, title }) => {
  const {
    register,
    handleSubmit: handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    if (id) {
      await dispatch(validateClient({ data, id: id })).then(() => {
        setOpen(false);
        onClose(open);
        dispatch(getAllClients());
        dispatch(resetLoading());
      });
    }
  };

  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    onClose(open);
  };

  const phoneInputStyle = {
    borderRadius: "0.375rem",
    backgroundColor: "#FFFFFF",
  };

  const [isLoading, setIsLoading] = useState(false);
  const userState = useAppSelector((state) => state.auth);
  useEffect(() => {
    switch (userState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [userState.loading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col   h-[15rem]">
        <div className="self-end mr-5">
          <FaRegWindowClose className="text-xl cursor-pointer" onClick={handleClose} />
        </div>
        <h2 className="sticky top-0 p-4 text-2xl font-extrabold text-center border-b border-grey">
          Validation du compte : {title}
        </h2>
        <main className="flex-1">
          <p className="mt-3 text-sm ">Validation *</p>

          <Select
            className={`mt-1.5 ${errors.validation?.message ? "border-red-500" : ""}`}
            placeholder="Choix de ville"
            value={getValues("validation")}
            onChange={(e) => setValue("validation", e.target.value)}
          >
            <option value={""}>Selectionner</option>
            <option value={"en cours"}>En Cours</option>
            <option value={"approuvé"}>Approuvé</option>
            <option value={"annuler"}>Annuler</option>
          </Select>

          <div className=""></div>
        </main>
        <ButtonPrimary
          className="w-full px-4 py-2 text-white bg-indigo-600 rounded"
          type="submit"
          loading={isLoading}
        >
          Mise à jour
        </ButtonPrimary>
      </div>
    </form>
  );
};
export default ValidateClientModal;
