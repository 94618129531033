import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC, useEffect } from "react";
import { useState } from "react";

export interface FiveStartIconForRateProps {
  className?: string;
  iconClass?: string;
  defaultPoint?: number;
  onRatingChange?: (rating: number) => void;
  onClick?: (selectedItem: number) => void;
}

const FiveStartIconForRate: FC<FiveStartIconForRateProps> = ({
  className = "",
  iconClass = "w-4 h-4",
  defaultPoint = 0,
  onRatingChange,
  onClick,
}) => {
  const [point, setPoint] = useState(defaultPoint);
  const [currentHover, setCurrentHover] = useState(0);

  useEffect(() => {
    setPoint(defaultPoint);
  }, [defaultPoint]);

  const handleStarClick = (item: number) => {
    const newPoint = point === item ? 0 : item;
    setPoint(newPoint);
    if (onRatingChange) {
      onRatingChange(newPoint);
    }
    if (onClick) {
      onClick(newPoint);
    }
  };
  return (
    <div
      className={`nc-FiveStartIconForRate flex items-center text-neutral-300 ${className}`}
      data-nc-id="FiveStartIconForRate"
    >
      {[1, 2, 3, 4, 5].map((item) => {
        return (
          <StarIcon
            key={item}
            className={`${point >= item || currentHover >= item ? "text-yellow-500" : ""} ${iconClass}`}
            onMouseEnter={() => setCurrentHover(item)}
            onClick={(e) => handleStarClick(item)}
          />
        );
      })}
    </div>
  );
};

export default FiveStartIconForRate;
