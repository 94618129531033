import { Dialog, DialogBody, DialogFooter, DialogHeader, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import React from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface DeleteModalProps {
  open: boolean;
  handleClose?: () => void;
  Data: any;
}
const ReservationDetailsPage: React.FC<DeleteModalProps> = ({ open, handleClose, Data }) => {
  const [openx, setOpen] = React.useState(false);
  const formatDate = (dateString: any) => {
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };
  const currentDate = new Date();
  function setshowDetails(arg0: boolean) {
    throw new Error("Function not implemented.");
  }
  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Dialog open={open} handler={handleOpen} className="w-11/12 dark:bg-gray-800">
        <DialogHeader>
          <Typography variant="h5" color="blue-gray" className="text-center dark:text-white">
            Information détaillée pour la réservation
          </Typography>
        </DialogHeader>
        <DialogBody divider className="grid gap-4 place-items-center">
          <Typography className="font-normal text-center">
            <div>
              <Label className="font-semibold">Code :</Label>
              <span className="dark:text-white"> {Data?.reference} </span>
            </div>

            <div>
              <Label className="font-semibold">Espace :</Label>
              <span className="dark:text-white">{Data?.Location.title}</span>
            </div>

            {Data?.lastName !== null && (
              <div>
                <Label className="font-semibold">Proprietaire :</Label>
                <span className="dark:text-white">
                  {Data?.Owner?.name} {Data?.Owner?.lastName}
                </span>
              </div>
            )}

            <div>
              <Label className="font-semibold">Client:</Label>
              <span className="dark:text-white">
                {" "}
                {Data?.Client?.name} {Data?.Client?.lastName}
              </span>
            </div>

            <div>
              <Label className="font-semibold">Jour :</Label>
              <span className="dark:text-white"> {formatDate(Data.selectedDate)} </span>
            </div>

            <div>
              <Label className="font-semibold">Temps :</Label>
              <span className="dark:text-white">
                {" "}
                {Data.startHour}-{Data.endHour}
              </span>
            </div>
            {Data.detail !== "annulée" && (
              <div>
                <Label className="font-semibold">Montant :</Label>
                <span className="dark:text-white"> {Data?.price} </span>
              </div>
            )}
            {Data.detail == "annulée" && (
              <div>
                <Label className="font-semibold">Montant à rembourser :</Label>
                <span className="dark:text-white"> {(0.7 * Data.price).toFixed(2)} </span>
              </div>
            )}
            {Data.detail !== "annulée" && (
              <div>
                <Label className="font-semibold"> A Payer :</Label>
                <span className="dark:text-white"> {Data?.halfTotal} </span>
              </div>
            )}
            {Data.detail !== "annulée" && (
              <div>
                <Label className="font-semibold">Status :</Label>
                <span className="dark:text-white">
                  {new Date(Data?.selectedDate) > currentDate ? "en cours" : "terminer"}{" "}
                </span>
              </div>
            )}
            {Data.detail !== "annulée" && (
              <div>
                <Label className="font-semibold">Paiement :</Label>
                {Data.payOwner !== true ? (
                  <span className="dark:text-white">non payé</span>
                ) : (
                  <span className="dark:text-white">Déjà payé</span>
                )}
              </div>
            )}
            {Data.detail == "annulée" && (
              <div>
                <Label className="font-semibold">Remboursement :</Label>
                {Data.refund !== true ? (
                  <span className="dark:text-white">non remboursé</span>
                ) : (
                  <span className="dark:text-white">remboursé</span>
                )}
              </div>
            )}

            <div>
              <Label className="font-semibold">Réservation crée le :</Label>
              <span className="dark:text-white">
                {" "}
                {Data?.createdAt &&
                  new Date(Data.createdAt).toLocaleDateString("fr-FR") +
                    " - " +
                    new Date(Data.createdAt).toUTCString().slice(-12, -4)}
              </span>
            </div>
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <ButtonSecondary onClick={handleClose} className="dark:bg-gray-800 dark:text-white">
            Quitter
          </ButtonSecondary>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ReservationDetailsPage;
