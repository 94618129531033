import NewsletterData from "./newsletter.json";

const NewsletterThree = () => {
  return (
    <div className="container">
      <div className="justify-between lg:flex row ">
        {NewsletterData &&
          NewsletterData.newsletterThree.map((data, index) => (
            <div className="col-lg-5 col-md-12 col-12" key={index}>
              <div className="inner">
                <div className="mt-2 text-left section-title">
                  <h4 className="text-3xl font-bold text-black dark:text-white">
                    <strong>{data.subTitle}</strong> <br />
                  </h4>
                </div>
                <span className="text-lg-start">{data.title}</span>
              </div>
            </div>
          ))}
        <div className="col-lg-7 col-md-12 col-12 text-start text-sm-end">
          <form action="#" className="newsletter-form-1 me-0">
            <input type="email" placeholder="Saisir Votre Email" className="p-2 border border-black rounded-md " />
            <button type="submit" className="rbt-btn btn-md btn-gradient hover-icon-reverse">
              <span className="icon-reverse-wrapper">
                <span className="btn-text">S'abonner</span>
                <span className="btn-icon">
                  <i className="feather-arrow-right"></i>
                </span>
                <span className="btn-icon">
                  <i className="feather-arrow-right"></i>
                </span>
              </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewsletterThree;
