import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AvisService, Fields, ListFavoris, Service, ServicePricing } from "data/types";
import { RootState } from "redux/store";
import { serviceApi } from "./serviceAPI";

interface Comment {
  d: string;
}

interface ServiceState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  serviceError: string;
  error: SerializedError | "";
  totalSteps: number;
  currentStep: number;
  updateStep: number;
  fields: Fields[];
  servicePring?: ServicePricing;
  serviceData?: Service;
  serviceAvis?: AvisService;
  serviceComments: Comment[];
  allServices?: Service[];
  isLiked: boolean;
  likedService?: ListFavoris;
  isUpdated?: Service;
  oldImages?: undefined;
}

const initialState: ServiceState = {
  loading: "idle",
  serviceError: "",
  error: "",
  totalSteps: 3,
  currentStep: 0,
  updateStep: 1,
  fields: [],
  serviceComments: [],
  isLiked: false,
  isUpdated: undefined,
  serviceData: undefined,
  allServices: undefined,
  servicePring: undefined,
  serviceAvis: undefined,
  likedService: undefined,
  oldImages: undefined,
};

export const requestPricing = createAsyncThunk("requestPricing", async (data: any) => {
  return await serviceApi.requestPricing(data);
});

export const getPricingById = createAsyncThunk("getPricingById", async (id: any) => {
  return await serviceApi.getPricingById(id);
});

export const addAvis = createAsyncThunk("addAvis", async (data: any) => {
  return await serviceApi.addAvis(data);
});

export const addService = createAsyncThunk("addService", async (data: any) => {
  return await serviceApi.newService(data);
});

export const updateService = createAsyncThunk("updateService", async ({ data, id }: { data: any; id: any }) => {
  return await serviceApi.updateService(data, id);
});

export const getFields = createAsyncThunk("getFields", async () => {
  return await serviceApi.getFields();
});

export const getService = createAsyncThunk("getService", async (id: string) => {
  return await serviceApi.getServiceById(id);
});
export const fetchSimilareService = createAsyncThunk("fetchSimilareService", async (id: any) => {
  return await serviceApi.getSimilareService(id);
});

export const getServiceByUserId = createAsyncThunk("getServiceByUserId", async (id: number) => {
  return await serviceApi.getServiceByUserId(id);
});

export const getCommentByServiceId = createAsyncThunk("getCommentByServiceId", async (id: string) => {
  return await serviceApi.getCommentByServiceId(id);
});

export const checkServiceFavoriByUserId = createAsyncThunk(
  "checkServiceFavoriByUserId",
  async (data: { serviceId: string; userId: number }) => {
    return await serviceApi.checkServiceFavoriByUserId(data.serviceId, data.userId);
  }
);

export const fetchAllServices = createAsyncThunk("fetchAllServices", async () => {
  return await serviceApi.getAllServices();
});

export const filterAllServices = createAsyncThunk("filterAllServices", async (data: any) => {
  return await serviceApi.filterAllServices(data);
});

export const addServiceToFavoris = createAsyncThunk(
  "addServiceToFavoris",
  async (data: { serviceId: string; userId: number }) => {
    return await serviceApi.addServiceToFavoris(data.serviceId, data.userId);
  }
);

export const fetchAllFavoris = createAsyncThunk("fetchAllFavoris", async () => {
  return await serviceApi.getAllFavoris();
});

export const deleteServiceFromFavoris = createAsyncThunk(
  "deleteServiceFromFavoris",
  async (data: { serviceId: any; userId: any }) => {
    return await serviceApi.deleteServiceFromFavoris(data.serviceId, data.userId);
  }
);

export const deleteService = createAsyncThunk("deleteService", async (id: any) => {
  return await serviceApi.deleteService(id);
});

export const getServiceFavorisByClientId = createAsyncThunk(
  "getServiceFavorisByClientId",
  async (data: { clientId: number }) => {
    return await serviceApi.getFavorisByClientId(data.clientId);
  }
);

export const getPricingRequestByAgentId = createAsyncThunk(
  "getPricingRequestByAgentId",
  async (data: { userId: any }) => {
    return await serviceApi.getPricingRequestByAgentId(data.userId);
  }
);

export const deletePricingRequest = createAsyncThunk("deletePricingRequest", async (id: any) => {
  return await serviceApi.getPricingRequest(id);
});

export const serviceSlice = createSlice({
  name: "Service",
  initialState,
  reducers: {
    initState(state) {
      return {
        ...state,
        loading: "idle",
        serviceError: "",
        error: "",
        totalSteps: 3,
        currentStep: 1,
        updateStep: 1,
        serviceData: undefined,
        fields: [],
        serviceComments: [],
        isLiked: false,
        allServices: undefined,
        servicePring: undefined,
        serviceAvis: undefined,
        likedService: undefined,
        isUpdated: undefined,
        oldImages: undefined,
      };
    },
    resetValues(state) {
      state.currentStep = 1;
      state.loading = "idle";
      state.serviceData = undefined;
    },
    nextStep(state) {
      state.currentStep++;
    },
    previousStep(state) {
      state.currentStep--;
    },
    setTotalSteps(state, action) {
      state.totalSteps = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setServiceData(state, action) {
      state.serviceData = action.payload;
      // state.updateStep++;
      state.isUpdated = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setServicePring(state, action) {
      state.servicePring = action.payload;
    },
    setServiceAvis(state, action) {
      state.serviceAvis = { ...state.serviceAvis, ...action.payload };
    },
    setServiceOldImages(state, action) {
      state.oldImages = action.payload;
    },
  },
  extraReducers: (builder) => {
    // service Pricing

    builder.addCase(deletePricingRequest.fulfilled, (state, action) => {
      state.fields = action.payload;
    });

    builder.addCase(requestPricing.pending, (state, action) => {
      state.loading = "pending";
      state.serviceError = "";
    });

    builder.addCase(requestPricing.fulfilled, (state, action) => {
      state.loading = "succeeded";
    });
    builder.addCase(requestPricing.rejected, (state, action) => {
      state.loading = "failed";
    });

    builder.addCase(getPricingRequestByAgentId.fulfilled, (state, action) => {
      state.servicePring = action.payload;
    });

    builder.addCase(getPricingById.fulfilled, (state, action) => {
      state.servicePring = action.payload;
    });

    // service Avis

    builder.addCase(addAvis.pending, (state, action) => {
      state.loading = "pending";
      state.serviceError = "";
    });

    builder.addCase(addAvis.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.serviceError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.serviceError = action.payload.error;
      }
    });
    builder.addCase(addAvis.rejected, (state, action) => {
      state.loading = "failed";
    });

    // addService
    builder.addCase(addService.pending, (state, action) => {
      state.loading = "pending";
      state.serviceError = "";
    });
    builder.addCase(addService.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.serviceError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.serviceError = action.payload.error;
      }
    });
    builder.addCase(addService.rejected, (state, action) => {
      state.loading = "failed";
    });

    // update Service
    builder.addCase(updateService.pending, (state, action) => {
      state.loading = "pending";
      state.serviceError = "";
    });
    builder.addCase(updateService.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.serviceError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.serviceError = action.payload.error;
      }
    });
    builder.addCase(updateService.rejected, (state, action) => {
      state.loading = "failed";
    });

    //
    builder.addCase(getFields.fulfilled, (state, action) => {
      state.fields = action.payload;
    });
    //
    builder.addCase(getService.fulfilled, (state, action) => {
      state.serviceData = action.payload;
    });
    // getServiceByUserId
    builder.addCase(getServiceByUserId.fulfilled, (state, action) => {
      state.serviceData = action.payload;
    });
    //
    builder.addCase(getCommentByServiceId.fulfilled, (state, action) => {
      state.serviceComments = action.payload;
    });
    //
    builder
      .addCase(checkServiceFavoriByUserId.pending, (state, action) => {
        state.loading = "pending";
        state.serviceError = "";
      })
      .addCase(checkServiceFavoriByUserId.fulfilled, (state, action) => {
        state.loading = "succeeded";

        if (action.payload) {
          state.isLiked = true;
        } else {
          state.isLiked = false;
        }
      })
      .addCase(checkServiceFavoriByUserId.rejected, (state, action) => {
        state.loading = "failed";
        state.serviceError = "";
      });

    builder.addCase(filterAllServices.fulfilled, (state: any, action: any) => {
      state.allServices = action.payload;
    });
    builder.addCase(fetchAllServices.fulfilled, (state, action) => {
      state.allServices = action.payload;
    });
    builder.addCase(fetchSimilareService.fulfilled, (state, action) => {
      state.allServices = action.payload;
    });

    // Favoris

    builder.addCase(addServiceToFavoris.pending, (state, action) => {
      state.loading = "pending";
      state.serviceError = "";
    });
    builder.addCase(addServiceToFavoris.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
        state.likedService = action.payload;
        state.isLiked = action.payload;
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.serviceError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.serviceError = action.payload.error;
      }
    });
    builder.addCase(addServiceToFavoris.rejected, (state, action) => {
      state.loading = "failed";
    });

    builder.addCase(deleteServiceFromFavoris.fulfilled, (state, action) => {
      state.isLiked = false;
      state.likedService = action.payload;
    });
    builder.addCase(getServiceFavorisByClientId.fulfilled, (state: any, action: any) => {
      state.likedService = action.payload || [];
    });
  },
});

export const {
  initState,
  nextStep,
  previousStep,
  setTotalSteps,
  setServiceData,
  setError,
  setServicePring,
  setServiceAvis,
  setCurrentStep,
  setServiceOldImages,
  resetValues,
} = serviceSlice.actions;

export const selectCount = (state: RootState) => state.service;
export default serviceSlice.reducer;
