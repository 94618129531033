import React, { ButtonHTMLAttributes } from "react";
import twFocusClass from "utils/twFocusClass";

export interface ButtonCircleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: string;
  loading?: boolean;
  disabled?: boolean;
}

const ButtonCircle: React.FC<ButtonCircleProps> = ({
  className = "",
  size = "w-9 h-9",
  loading = false,
  disabled = false,
  ...args
}) => {
  const _renderLoading = () => {
    return (
      <svg className="w-5 h-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="3"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  };

  return (
    <button
      disabled={disabled || loading}
      className={
        `ttnc-ButtonCircle flex items-center justify-center rounded-full !leading-none disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className} ${size} ` +
        twFocusClass(true)
      }
      {...args}
    >
      {loading ? _renderLoading() : args.children}
    </button>
  );
};

export default ButtonCircle;
