import React from "react";
import { Helmet } from "react-helmet";
import Header from "components/Header/Header";

const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const renderHeader = () => {
    let headerClassName = "shadow-sm dark:border-b dark:border-neutral-700";

    return <Header className={headerClassName} navType="MainNav1" />;
  };

  return (
    <>
      <Helmet>
        <title>Event To Bee </title>
      </Helmet>
      {renderHeader()}
      <div ref={anchorRef} className="absolute invisible h-1"></div>
    </>
  );
};

export default SiteHeader;
