import { Popover, Transition } from "@headlessui/react";
import { useAppSelector } from "hooks/hooks";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";

interface Props {
  className?: string;
  notifications?: [];
}

const NotifyDropdown: any = (props: any) => {
  const user_profile = "static-files/user_profile_2.png";

  const { notifications, notificationSeen } = props;
  const [open, setOpen] = useState<any>(true);

  const userState = useAppSelector((state) => state?.auth?.user);

  const handleOpen = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);

  useEffect(() => {}, [open]);

  return (
    <div className={props.className}>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              onClick={props.notificationSeen}
              className={` ${
                open ? "" : "text-opacity-90"
              } group p-3 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
            >
              {props.new_notification && (
                <span className="absolute w-2 h-2 bg-blue-500 rounded-full top-4 right-4"></span>
              )}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute z-10 w-screen max-w-xs px-4 mt-3 sm:max-w-sm -right-28 sm:right-0 sm:px-0"
                style={{
                  display: "block",
                  maxHeight: "440px",
                  overflowY: "scroll",
                }}
              >
                <div className="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7">
                    {props.notifications.length > 0 &&
                      props.notifications.map((item: any, index: any) => {
                        return (
                          item && (
                            <Link
                              key={index}
                              to={
                                !!item.reservation &&
                                item.operation === "confirm_reservation" &&
                                userState &&
                                userState.role !== "admin"
                                  ? `${
                                      "/location/" +
                                      item.locationId +
                                      "?reservationId=" +
                                      item.reservationId +
                                      "&openconverstaion=true"
                                    }`
                                  : !!item.reservation &&
                                      item.operation === "confirm_reservation" &&
                                      userState &&
                                      userState.role === "admin"
                                    ? `${"/dashboard/reservation-admin"}`
                                    : !!item.reservation && item.operation === "create_reservation"
                                      ? `${"/client/list-pending-reservations"}`
                                      : !!item.reservation &&
                                          userState &&
                                          userState.role === "admin" &&
                                          item.operation === "cancel_reservation"
                                        ? `${"/dashboard/canceled-admin"}`
                                        : !!item.reservation &&
                                            userState &&
                                            userState.role === "admin" &&
                                            item.operation === "negate_reservation"
                                          ? `${"/dashboard/pending-admin"}`
                                          : (!!item.reservation &&
                                                userState &&
                                                userState.role !== "admin" &&
                                                item.operation === "negate_reservation") ||
                                              item.operation === "cancel_reservation"
                                            ? `${"/locations"}`
                                            : !!item.reservation && item.operation === "send_message"
                                              ? `/location/${item?.locationId}?reservationId=${
                                                  item?.reservationId + "&openconverstaion=true"
                                                }`
                                              : !!item.location && item.operation === "confirm_owner_espace"
                                                ? `${"/location/" + item.locationId}`
                                                : !!item.location && item.operation === "negate_owner_espace"
                                                  ? `${"/client/locations"}`
                                                  : !!item.location && item.operation === "send_avis"
                                                    ? "/location/" + item.locationId + "/#comments"
                                                    : userState &&
                                                        userState.role === "admin" &&
                                                        item.operation === "send_contact_request"
                                                      ? "/dashboard/reclamation-admin"
                                                      : item.operation === "create_espace"
                                                        ? "/dashboard/locations/unApprouved"
                                                        : item.operation === "create_prestation"
                                                          ? "/dashboard/prestations"
                                                          : item.prestation &&
                                                              item.operation === "send_presta_avis"
                                                            ? "/services"
                                                            : ""
                              }
                              className={`relative flex p-2 pr-8 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 
                              }`}
                              onClick={() => close()}
                            >
                              <Avatar
                                sizeClass="w-8 h-8 sm:w-12 sm:h-12"
                                imgUrl={
                                  !!item?.senderId
                                    ? item?.sender === "ADMIN"
                                      ? process.env.REACT_APP_CLOUD_FRONT + "static-files/EVENT TO BEE-17.png"
                                      : item?.senderData?.Clients?.[0]?.client_url &&
                                          item?.senderData?.Clients?.[0]?.client_url.trim() !== ""
                                        ? process.env.REACT_APP_CLOUD_FRONT +
                                          item?.senderData?.Clients?.[0]?.client_url
                                        : process.env.REACT_APP_CLOUD_FRONT + user_profile
                                    : process.env.REACT_APP_CLOUD_FRONT + user_profile
                                }
                              />
                              <div className="ml-3 space-y-1 sm:ml-4">
                                <p className="text-sm font-medium text-gray-900 dark:text-gray-200">
                                  {!!item?.sender?.name
                                    ? item?.sender?.name
                                    : item?.sender?.role === "admin"
                                      ? "Eventtobee"
                                      : item?.sender?.email}
                                </p>
                                <p className="text-xs text-gray-500 sm:text-sm dark:text-gray-400">
                                  {item.message}
                                </p>

                                <div className="flow-root mt-1 ">
                                  <p className="float-left text-xs text-gray-500 ">
                                    {new Date(item.createdAt).toISOString().split("T")[0]}
                                  </p>

                                  <p className="float-right text-xs text-gray-500 ">
                                    {new Date(new Date(item.createdAt).getTime()).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}{" "}
                                  </p>
                                </div>
                              </div>
                              {props.new_notification && (
                                <span
                                  className={`absolute w-2 h-2 transform -translate-y-1/2 rounded-full right-1 top-1/2`}
                                ></span>
                              )}
                            </Link>
                          )
                        );
                      })}

                    {props.notifications.length === 0 && (
                      <h3 className="text-align-center">Actuellement, il n'y a aucune notification en cours.</h3>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default NotifyDropdown;
