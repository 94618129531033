import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
export interface ClientCanceledReservationPageProps {
  className?: string;
}

const ClientCanceledReservationPage: FC<ClientCanceledReservationPageProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  if (localStorage.getItem("reservation")) {
    localStorage.removeItem("reservation");
  }
  const handleExploreLocationsClick = () => {
    navigate("/locations");
  };

  const renderContent = () => {
    return (
      <div className="flex flex-col w-full px-0 space-y-8 sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 sm:p-6 xl:p-8">
        <h2 className="text-3xl font-semibold lg:text-4xl">Triste nouvelle 😞</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Votre réservation n'est pas terminée</h3>
        </div>
        <div className="text-center">
          <ButtonPrimary onClick={handleExploreLocationsClick}>Explorer d'autres espaces</ButtonPrimary>
        </div>
      </div>
    );
  };
  return (
    <div className={`nc-PaymentConfirmationPage ${className}`} data-nc-id="PaymentConfirmationPage">
      <main className="container mb-24 mt-11 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};
export default ClientCanceledReservationPage;
