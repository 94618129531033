import { UserCircleIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import { AuthContext } from "context/appContext";
import { useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { PathName } from "routers/types";
import MenuBar from "shared/MenuBar/MenuBar";
let WIN_PREV_POSITION = window.pageYOffset;

interface NavItem {
  name: string;
  link?: PathName;
  icon: any;
}

const FooterNav = () => {
  const authContext = useContext(AuthContext);
  let role = authContext?.user?.role;

  let profileLink;
  if (role === "client") {
    profileLink = "/client";
  } else if (role === "service") {
    profileLink = "/prestataire";
  } else if (role === "admin") {
    profileLink = "/admin";
  }
  const NAV = [
    {
      name: "Accueil",
      link: "/",
      icon: HomeIcon,
    },
    authContext.user?.profil
      ? {
          name: "Profile",
          link: profileLink,
          icon: UserCircleIcon,
        }
      : {
          name: "Connexion",
          link: "/login",
          icon: UserCircleIcon,
        },
    {
      name: "Menu",
      icon: MenuBar,
    },
  ];
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  return (
    <div
      ref={containerRef}
      className="fixed inset-x-0 bottom-0 top-auto z-30 p-2 transition-transform duration-300 ease-in-out bg-white border-t FooterNav dark:bg-neutral-800 border-neutral-300 dark:border-neutral-700"
    >
      <div className="flex justify-around w-full max-w-lg mx-auto text-sm text-center">
        {/* MENU */}
        {NAV.map((item, index) => {
          let active = false;
          if (location.pathname === item.link) {
            active = true;
          }
          return item.link ? (
            <Link
              key={index}
              to={item.link}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-sky-700 dark:text-blue-300" : ""
              }`}
            >
              <item.icon className={`w-6 h-6 }`} />
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </Link>
          ) : (
            <div
              key={index}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-blue-500 dark:text-blue-300" : ""
              }`}
            >
              <item.icon iconClassName="w-6 h-6" className={``} />
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FooterNav;
