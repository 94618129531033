import { registerCreator } from "./signUpSlice";

const baseURL = process.env.REACT_APP_SERVER_URL;

const getCsrfToken = async () => {
  const result = await fetch(`${baseURL}/api/auth/csrf`, {
    method: "GET",
    credentials: "include",
  });
  return result.json();
};

export const signUpService = {
  register: async (data: any) => {
    const csrfToken = await getCsrfToken();

    const result = await fetch(`${baseURL}/api/auth/register`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrfToken?.csrfToken ?? "",
      },
      body: JSON.stringify(data),
    });

    return result.json();
  },

  registerCreator: async (data: any) => {
    const csrfToken = await getCsrfToken();

    const result = await fetch(`${baseURL}/api/creator`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrfToken?.csrfToken ?? "",
      },
      body: JSON.stringify(data),
    });

    return result.json();
  },

  updateProfileClient: async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const result = await fetch(`${baseURL}/api/auth/update-profile-client`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });

    return result.json();
  },

  // add a new profile service
  updateProfileService: async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const result = await fetch(`${baseURL}/api/auth/update-profile-service`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },

  // update profile service

  ServiceProfileUpdate: async (data: any, id: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "availability") {
        formData.append(key, data[key]);
      }
    }
    formData.append("availability", JSON.stringify(data.availability));
    const result = await fetch(`${baseURL}/api/service-agent/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });

    return result.json();
  },

  ClientProfileUpdate: async (data: any, id: any) => {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    const result = await fetch(`${baseURL}/api/client/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });

    return result.json();
  },

  OwnerProfileUpdate: async (data: any, id: any) => {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    const result = await fetch(`${baseURL}/api/owner/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });

    return result.json();
  },

  addOwnerProfile: async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    const result = await fetch(`${baseURL}/api/owner/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });

    return result.json();
  },

  getFields: async () => {
    const result = await fetch(`${baseURL}/api/field`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },
};
