import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createField } from "./featureAPI";

const initialState = {
  designation: "",
  image: [],
  status: "idle",
  fields: [],
  field: {
    images: [],
    id: "",
    designation: "",
  },
};

export const addField = createAsyncThunk("featureField/addField", async (data: any) => {
  return await createField.add(data);
});

export const fetchFields = createAsyncThunk("featureField/fetchField", async () => {
  return await createField.verify();
});
export const fetchFieldByid = createAsyncThunk("featureField/fetchFieldByid", async (id: any) => {
  return await createField.getById(id);
});
export const deleteField = createAsyncThunk("featureField/deleteField", async (id: number) => {
  return await createField.delete(id);
});

export const updateField = createAsyncThunk(
  "featureField/editField",
  async ({ data, id }: { data: any; id: any }) => {
    return await createField.update(data, id);
  }
);

const createFieldSlice = createSlice({
  name: "featureField",
  initialState,
  reducers: {
    setDesignation(state, action) {
      state.designation = action.payload;
    },
    setImage(state, action) {
      state.image = action.payload;
    },
    resetDesignation(state) {
      state.designation = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addField.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addField.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.designation = action.payload;
        state.image = action.payload;
      })
      .addCase(addField.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchFields.fulfilled, (state, action) => {
        state.fields = action.payload;
      })
      .addCase(fetchFieldByid.fulfilled, (state, action) => {
        state.field = action.payload;
      })
      .addCase(deleteField.pending, (state) => {
        state.status = "pending";
      })
      .addCase(deleteField.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(deleteField.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateField.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateField.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.designation = action.payload;
      })
      .addCase(updateField.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setDesignation, resetDesignation, setImage } = createFieldSlice.actions;
export default createFieldSlice.reducer;
