import { Spinner } from "@material-tailwind/react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  cancelReservation,
  checkReservationByReference,
  repayReservationFee,
} from "redux/features/reservations/reservationSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { socket } from "../../utils/socket";
import { ProfilClient } from "data/types";
import AlertModalDelete from "components/AlertModal/AlertModalDelete";

export interface PaymentConfirmationPageProps {
  className?: string;
}

const PaymentConfirmationPage: FC<PaymentConfirmationPageProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reference = queryParams.get("reservation");
  const handleExploreLocationsClick = () => {
    navigate("/locations");
  };

  const userValidation = (useAppSelector((state) => state.auth.user?.profil as ProfilClient)?.validation ||
    "") as string;

  const handleLocationsBack = () => {
    if (userValidation === "approuvé") {
      navigate(`/client/my-reservations`);
    } else {
      navigate(`/client-reservations`);
    }
  };
  const dispatch = useAppDispatch();
  const [showSpinner, setShowSpinner] = useState(true);
  let detailState = useAppSelector((state) => state.featureReservation.reservationDetail);
  let messageState = useAppSelector((state) => state.featureReservation.message);
  const formattedDate: string = new Date(detailState?.selectedDate).toLocaleDateString("fr-FR");
  let res: any;
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (reference) {
          res = await dispatch(checkReservationByReference(reference));
          setShowSpinner(false);
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (detailState?.id && detailState?.payment_status === "requires_capture") {
      socket.emit("send-notification", {
        senderId: detailState.Client.userId,
        receiverId: detailState.Owner.userId,
        action: "create_reservation",
        reservationId: detailState.id,
        reservation: detailState,
      });
      /* 
            socket.emit('send-notification', {
                receiverId: detailState.Client.userId,
                senderId: detailState.Owner.userId,
                action: 'create_reservation',
                reservationId: detailState.id,
                reservation: detailState,
            });

            console.log({
                senderId: detailState.Client,
                receiverId: detailState.Owner,
                action: 'create_reservation',
                reservationId: id,
                reservation: detailState,
            }); */
    }
  }, [detailState]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [canceled, setCanceled] = useState(false);
  const handleCancel = async () => {
    setShowModal(false);
    setLoading(true);
    await dispatch(cancelReservation({ reservation: detailState.id }));
    setCanceled(true);
    setLoading(false);
    navigate("/client-reservations");
    if (detailState && detailState?.payment_status === "requires_capture") {
      socket.emit("send-notification", {
        senderId: detailState.Client.userId,
        receiverId: detailState.Owner.userId,
        action: "cancel_reservation",
        reservationId: detailState.id,
        reservation: detailState,
      });
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleClick = async () => {
    if (setShowModal) {
      setShowModal(true);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const data = await dispatch(repayReservationFee(detailState.id));

    setIsLoading(false);
    window.location.href = data.payload;
  };

  const renderContent = () => {
    return (
      <>
        <AlertModalDelete
          open={showModal}
          handleDelete={() => handleCancel()}
          handleClose={() => setShowModal(false)}
          message={`Êtes-vous sûr(e) de vouloir annuler votre réservation ?`}
          alertText="Annulation de Réservation"
        />
        <div className="flex flex-col w-full px-0 space-y-8 sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 sm:p-6 xl:p-8">
          {messageState && messageState === "Payment completed." && (
            <>
              <h2 className="text-3xl font-semibold lg:text-3xl">
                {" "}
                Votre demande de réservation a été envoyée 🎉
              </h2>
            </>
          )}
          {messageState && messageState === "Payment not yet completed." && (
            <>
              <div className="flex justify-between">
                <div>
                  <h2 className="text-3xl font-semibold lg:text-4xl">Confirmer votre réservation !</h2>
                </div>
                <div>
                  {" "}
                  <ButtonPrimary onClick={handleSubmit} loading={isLoading}>
                    Confirmer
                  </ButtonPrimary>
                </div>
              </div>
            </>
          )}
          {messageState && messageState === "no reservation with this reference" && (
            <>
              <h2 className="text-3xl font-semibold lg:text-4xl">Erreur survenue</h2>
            </>
          )}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          {/* ------------------------ */}
          <div className="space-y-6">
            {messageState && messageState === "Payment completed." && (
              <>
                <h3 className="font-semibold ">
                  Votre réservation ne sera pas confirmée tant que l'hôte n'aura pas accepté votre demande (dans un
                  délai de 48 heures).
                </h3>
                <p className="text-gray-900 whitespace-no-wrap dark:text-white">
                  Veuillez noter que le montant sera{" "}
                  <span className="font-semibold text-secondary-6000"> entièrement remboursé</span> si l'hôte ne
                  confirme pas la réservation
                </p>
              </>
            )}
            {messageState && messageState === "Payment not yet completed." && (
              <>
                <h3 className="text-2xl font-semibold">Veuillez vous confirmer votre réservation</h3>
              </>
            )}
            {messageState && messageState === "no reservation with this reference" && (
              <>
                <h3 className="text-2xl font-semibold">Erreur survenue ou pas de réservation avec ce code</h3>
              </>
            )}

            <div className="flex flex-col sm:flex-row sm:items-center">
              <div className="flex-shrink-0 w-full sm:w-40">
                <div className="overflow-hidden aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl">
                  <NcImage
                    src={
                      detailState?.Location?.images[0]
                        ? process.env.REACT_APP_CLOUD_FRONT + detailState?.Location?.images[0]
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="pt-5 space-y-3 sm:pb-5 sm:px-5">
                <div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                    {detailState?.Location?.city} , {detailState?.Location?.region}
                  </span>
                  <span className="block mt-1 text-base font-medium sm:text-lg">
                    {detailState?.Location?.title}
                  </span>
                </div>{" "}
                {detailState?.Location?.surface && (
                  <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                    {detailState?.Location?.surface} m²
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-col mt-6 border divide-y border-neutral-200 dark:border-neutral-700 rounded-3xl sm:flex-row sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
              <div className="flex flex-1 p-5 space-x-4">
                <svg
                  className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                    stroke="#D1D5DB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className="flex flex-col">
                  <span className="text-sm text-neutral-400">Date</span>
                  <span className="mt-1.5 text-lg font-semibold">{formattedDate}</span>
                </div>
              </div>
              <div className="flex flex-1 p-5 space-x-4">
                <svg
                  className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                    stroke="#D1D5DB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className="flex flex-col">
                  <span className="text-sm text-neutral-400">Nombre de personnes</span>
                  <span className="mt-1.5 text-lg font-semibold">{detailState?.visitor} Personnes</span>
                </div>
              </div>
            </div>
          </div>

          {/* ------------------------ */}
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold">Détails Réservation</h3>
            <div className="flex flex-col space-y-4">
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Date</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">{formattedDate}</span>
              </div>
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Temps</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {detailState?.startHour}-{detailState?.endHour}
                </span>
              </div>
              <div className="flex text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Total</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {detailState?.price}€
                </span>
              </div>
            </div>
          </div>
          <div>
            {messageState === "Payment completed." && (
              <ButtonPrimary onClick={handleExploreLocationsClick}>Explorer d'autres espaces</ButtonPrimary>
            )}
            {messageState === "Payment not yet completed." && (
              <ButtonPrimary loading={loading} className="bg-red-500" onClick={handleClick}>
                Annuler réservation
              </ButtonPrimary>
            )}
          </div>
        </div>
      </>
    );
  };
  const errorContent = () => {
    return (
      <div className="flex flex-col w-full px-0 space-y-8 sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 sm:p-6 xl:p-8">
        <>
          <h2 className="text-3xl font-semibold lg:text-4xl">Erreur survenue</h2>
        </>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* ------------------------ */}
        <div className="space-y-6">
          <>
            <h3 className="text-2xl font-semibold">Erreur survenue ou pas de réservation avec ce code</h3>
          </>
        </div>

        <div>
          <ButtonPrimary onClick={handleExploreLocationsClick}>Explorer d'autres espaces</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PaymentConfirmationPage ${className}`} data-nc-id="PaymentConfirmationPage">
      <main className="container mb-24 mt-11 lg:mb-32 ">
        {!showSpinner && (
          <div className="max-w-4xl mx-auto">
            {messageState === "no reservation with this reference" ? errorContent() : renderContent()}
          </div>
        )}
        {showSpinner && (
          <div className="flex items-center justify-center mb-80">
            <Spinner color="blue" className="w-36 h-36" />
          </div>
        )}
      </main>
    </div>
  );
};

export default PaymentConfirmationPage;
