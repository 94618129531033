import { parsePhoneNumberFromString, CountryCode } from "libphonenumber-js";
import { loadStripe } from "@stripe/stripe-js";
import toast from "react-hot-toast";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

export const formatPhoneNumber = (phoneNumber: string, countryCode: string) => {
  const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, countryCode as CountryCode);
  return phoneNumberObj ? phoneNumberObj.format("E.164") : null;
};

export const createParticularStripeToken = async (data: any) => {
  if (!stripePromise) return null;

  try {
    const stripe = await stripePromise;
    if (stripe) {
      const phone_number = formatPhoneNumber(data?.Client?.phone, "FR");
      if (!phone_number) {
        return null;
      }

      const date = new Date(data?.Client.dateOfBirth);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const { token, error } = await stripe.createToken("account", {
        individual: {
          first_name: data?.Client?.name,
          last_name: data?.Client?.lastName,
          email: data?.Client?.User?.email,
          phone: phone_number,
          dob: {
            day: day,
            month: month,
            year: year,
          },
          address: {
            city: data?.Client?.city,
            line1: data?.Client?.line1,
            line2: data?.Client?.rue,
            postal_code: data?.Client?.postal_code,
          },
        },
        tos_shown_and_accepted: true,
        business_type: "individual",
      });
      if (error) {
        toast.error(`Error creating Stripe token: ${error.message}`);
        return null;
      } else {
        return token?.id;
      }
    }
  } catch (error: any) {
    toast.error(`Error in createParticularStripeToken:  ${error.message}`);
    throw new Error("Token creation failed");
  }
};

export const createBankAccountToken = async (name: string, type: string, rib: string) => {
  if (!stripePromise) return null;

  try {
    const stripe = await stripePromise;
    if (stripe) {
      const { token, error } = await stripe.createToken("bank_account", {
        country: "FR",
        currency: "EUR",
        account_holder_name: name,
        account_holder_type: type,
        account_number: rib,
      });
      if (error) {
        toast.error(`Error creating Stripe Bank Account Token: ${error.message}`);
        return null;
      } else {
        return token?.id;
      }
    }
  } catch (error: any) {
    toast.error(`Error in createBankAccountToken:  ${error.message}`);
    throw new Error("Bank Account Token creation failed");
  }
};

export const createCompanyStripeToken = async (data: any) => {
  if (!stripePromise) return null;

  try {
    const stripe = await stripePromise;
    if (stripe) {
      const phone_number = formatPhoneNumber(data?.Client?.companyPhone, "FR");
      if (!phone_number) {
        return null;
      }

      const { token, error } = await stripe.createToken("account", {
        company: {
          directors_provided: true,
          owners_provided: true,
          executives_provided: true,
          tax_id: data?.Client?.companySiren,
          name: data?.Client?.companyName,
          phone: phone_number,
          address: {
            city: data?.Client?.city,
            line1: data?.Client?.line1,
            line2: data?.Client?.rue,
            postal_code: data?.Client?.postal_code,
            country: "FR",
          },
        },
        tos_shown_and_accepted: true,
        business_type: "company",
      });
      if (error) {
        toast.error(`Error creating Stripe token: ${error.message}`);
        return null;
      } else {
        return token?.id;
      }
    }
  } catch (error: any) {
    toast.error(`Error in createCompanyStripeToken:  ${error.message}`);
    throw new Error("Token creation failed");
  }
};

/* Connected account by ADMIN Dashboard  */
export const createParticularStripeTokenMethod = async (data: any) => {
  if (!stripePromise) return null;

  try {
    const stripe = await stripePromise;
    if (stripe) {
      const phone_number = formatPhoneNumber(data?.Clients?.[0]?.phone, "FR");
      if (!phone_number) {
        return null;
      }

      const date = new Date(data?.Clients[0]?.dateOfBirth);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const { token, error } = await stripe.createToken("account", {
        individual: {
          first_name: data?.Clients?.[0]?.name,
          last_name: data?.Clients?.[0]?.lastName,
          email: data?.email,
          phone: phone_number,
          dob: {
            day: day,
            month: month,
            year: year,
          },
          address: {
            city: data?.city,
            line1: data?.address,
            line2: data?.address2,
            postal_code: (data?.codePostale).toString(),
          },
        },
        tos_shown_and_accepted: true,
        business_type: "individual",
      });
      if (error) {
        toast.error(`Error creating Stripe token: ${error.message}`);
        return null;
      } else {
        return token?.id;
      }
    }
  } catch (error: any) {
    toast.error(`Error in createParticularStripeToken:  ${error.message}`);
    throw new Error("Token creation failed");
  }
};

export const createBankAccountTokenMethod = async (name: string, type: string, rib: string) => {
  if (!stripePromise) return null;

  try {
    const stripe = await stripePromise;
    if (stripe) {
      const { token, error } = await stripe.createToken("bank_account", {
        country: "FR",
        currency: "EUR",
        account_holder_name: name,
        account_holder_type: type,
        account_number: rib,
      });
      if (error) {
        toast.error(`Error creating Stripe Bank Account Token: ${error.message}`);
        return null;
      } else {
        return token?.id;
      }
    }
  } catch (error: any) {
    toast.error(`Error in createBankAccountToken:  ${error.message}`);
    throw new Error("Bank Account Token creation failed");
  }
};

export const createCompanyStripeTokenMethod = async (data: any) => {
  if (!stripePromise) return null;

  try {
    const stripe = await stripePromise;
    if (stripe) {
      const phone_number = formatPhoneNumber(data?.companyPhone, "FR");
      if (!phone_number) {
        return null;
      }
      const { token, error } = await stripe.createToken("account", {
        company: {
          directors_provided: true,
          owners_provided: true,
          executives_provided: true,
          tax_id: data?.Clients[0]?.companySiren,
          name: data?.Clients[0]?.companyName,
          phone: phone_number,
          address: {
            city: data?.city,
            line1: data?.address,
            line2: data?.address2,
            postal_code: (data?.codePostale).toString(),
            country: "FR",
          },
        },
        tos_shown_and_accepted: true,
        business_type: "company",
      });
      if (error) {
        toast.error(`Error creating Stripe token: ${error.message}`);
        return null;
      } else {
        return token?.id;
      }
    }
  } catch (error: any) {
    toast.error(`Error in createCompanyStripeToken:  ${error.message}`);
    throw new Error("Token creation failed");
  }
};
