import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DashboardMenu from "../DashboardPage/DashboardMenu";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/solid";
import { Alert } from "shared/Alert/Alert";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { addSlider, fetchSliders, updateSlider } from "redux/features/Admin/Sliders/sliderSlice";
import { optional, z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export interface SliderPageProps {
  className?: string;
}
const SliderPage: FC<SliderPageProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const sliderState = useAppSelector((state) => state.featureSlider);
  useEffect(() => {
    dispatch(fetchSliders());
  }, []);
  useEffect(() => {
    if (sliderState) {
      setOldImages(sliderState.images);
    }
  }, [sliderState]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [oldImages, setOldImages] = useState<any[]>([]);
  const navigate = useNavigate();
  const MAX_FILE_SIZE = 1024 * 1024 * 10;
  const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

  const formSchema = z.object({
    newImages: optional(z.array(z.instanceof(File))).refine((files) => {
      // Your validation logic for the array of images
      return (
        !files ||
        files.every(
          (file) => !file || (file?.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type))
        )
      );
    }, "Le fichier doit être une image au format JPEG, PNG ou GIF et ne pas dépasser 10MB."),
  });
  const {
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });
  const handleDeleteOldImage = (index: number) => {
    setOldImages((prevImages) => {
      // Create a new array without the element to delete
      const updatedImages = prevImages.filter((_, i) => i !== index);
      return updatedImages;
    });
  };

  const handleDeleteNewImage = (index: number) => {
    setNewImages((prevImages) => {
      // Create a new array without the element to delete
      const updatedNewImages = prevImages.filter((_, i) => i !== index);
      return updatedNewImages;
    });
  };
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    if (sliderState?.images?.length) {
      await dispatch(
        updateSlider({
          data: {
            ...data,
            oldImages: oldImages,
          },
          id: sliderState?.id,
        })
      );
    } else {
      await dispatch(
        addSlider({
          images: newImages,
        })
      );
    }
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      navigate("/");
    }, 2000);
  };
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        {showAlert && (
          <Alert type="success" containerClassName="text-xs">
            Sliders modifiée avec succès ! <br />
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-2xl font-semibold text-center ">Liste images Sliders</h2>
          <br />
          <div className="mt-10 ">
            <div className="mt-3">
              <p className="text-sm text-green-500">Vos images:</p>
              <div className="flex items-center justify-center">
                <div className="relative">
                  <div className="flex flex-wrap gap-2 mt-2">
                    {Array.isArray(oldImages) &&
                      oldImages.map((image, index) => (
                        <div key={index} className="relative">
                          <img
                            src={process.env.REACT_APP_CLOUD_FRONT + image}
                            alt={`Uploaded ${index}`}
                            className="h-auto max-w-xs max-h-40"
                          />
                          <TrashIcon
                            className="absolute w-5 text-white bg-black rounded-full cursor-pointer h-7 las la-trash-alt top-2 right-2"
                            onClick={() => handleDeleteOldImage(index)}
                          />
                        </div>
                      ))}
                    {newImages &&
                      newImages?.map((image, index) => (
                        <div key={index} className="relative">
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Newly uploaded ${index}`}
                            className="h-auto max-w-xs max-h-40"
                          />
                          <TrashIcon
                            className="absolute w-5 text-white bg-black rounded-full cursor-pointer h-7 las la-trash-alt top-2 right-2"
                            onClick={() => handleDeleteNewImage(index)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
              <div className="space-y-1 text-center">
                <svg
                  className="w-12 h-12 mx-auto text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                  <label
                    htmlFor="fileInput"
                    className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>Modifier</span>
                    <input
                      id="fileInput"
                      name="image"
                      type="file"
                      multiple
                      className="sr-only"
                      onChange={(e) => {
                        const files = e.target.files;
                        if (files) {
                          const fileArray = Array.from(files);
                          setNewImages(fileArray); // Update the state with the array of files
                          setValue("newImages", fileArray); // Set the form value with the array of files
                          trigger("newImages");
                        }
                      }}
                      accept="image/jpg,image/jpeg,image/png,image/webp"
                    />
                  </label>
                  <p className="pl-1">ou déposer</p>
                </div>
                <p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF jusqu'à 10MB</p>
              </div>
            </div>

            {errors.newImages?.message && (
              <div className="mt-1 text-xs text-red-500">{errors.newImages?.message}</div>
            )}
          </div>
          <br />
          <ButtonPrimary type="submit">Mise à jour</ButtonPrimary>
        </form>
      </DashboardMenu>
    </div>
  );
};

export default SliderPage;
