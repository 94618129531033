import { Dialog, DialogBody, DialogFooter, DialogHeader, Spinner, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BlockedDatesRangeInput, { BlockedDatesRangeInputProps } from "./blockedDatesRangeInput";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
  addUnavailable,
  fetchUnavailableByLocation,
  removeUnavailable,
} from "redux/features/unavailable/unavailableSlice";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useAppSelector } from "hooks/hooks";
import { fetchfullyDays } from "redux/features/reservations/reservationSlice";

interface DeleteModalProps {
  open: boolean;
  handleClose?: () => void;
  locationData: any;
}
const BlockedDetailsPage: React.FC<DeleteModalProps> = ({ open, handleClose, locationData }) => {
  const [openx, setOpen] = React.useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000));
  const handleDateChange: BlockedDatesRangeInputProps["onDateChange"] = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const [Error, setError] = useState("");
  let fullyDaysState = useAppSelector((state) => state.featureReservation.fullyDays);
  let unavailableState = useAppSelector((state) => state.featureUnvailable.unavailables);
  const [onSubmitloading, setOnSubmitLoading] = useState(false);
  const [onUnblockSubmitloading, setOnUnblockSubmitLoading] = useState(false);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const handleOpen = () => setOpen(!open);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (locationData) {
          await dispatch(fetchfullyDays(locationData.id));
          await dispatch(fetchUnavailableByLocation(locationData.id));
          setShowSpinner(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const onSubmit = async () => {
    setError("");
    setOnSubmitLoading(true);
    const unavailable = {
      startDay: startDate,
      endDay: endDate,
      locationId: locationData.id,
    };

    try {
      const res = await dispatch(addUnavailable(unavailable));
      setOnSubmitLoading(false);
      if (res.payload.error) {
        setError(res.payload.error.message);
        return;
      }
      handleClose && handleClose();
    } catch (error) {
      console.error("Error occurred:", error);
      setOnSubmitLoading(false);
      setError("Oups ! Il semble qu'il y ait déjà au moins une réservation pour cette période.");
    }
  };

  const onUnblockSubmit = async () => {
    setError("");
    setOnUnblockSubmitLoading(true);
    const unavailable = {
      startDay: startDate,
      endDay: endDate,
      locationId: locationData.id,
    };

    try {
      const res = await dispatch(removeUnavailable(unavailable));
      setOnUnblockSubmitLoading(false);
      if (res.payload.error) {
        setError(res.payload.error.message);
        return;
      }
      handleClose && handleClose();
    } catch (error) {
      console.error("Error occurred:", error);
      setOnUnblockSubmitLoading(false);
      setError("Oups ! Il semble qu'il y ait déjà au moins une réservation pour cette période.");
    }
  };
  return (
    <>
      <div>
        <Dialog open={open} handler={handleOpen} className="w-full">
          <DialogHeader>Les jours non disponibles</DialogHeader>

          <DialogBody divider className="grid gap-4 overflow-y-scroll place-items-center">
            <div className="flex items-center">
              <div className="relative flex items-center justify-center w-4 h-4 mr-1 bg-black rounded-lg shadow"></div>
              <span className="mr-1 text-xs font-medium text-black">Les dates encore disponibles.</span>

              <div className="relative flex items-center justify-center w-4 h-4 mr-1 bg-gray-500 rounded-lg shadow"></div>
  
              <span className="mr-1 text-xs font-medium text-black">Les dates passées ou indisponibles</span>

              <div className="relative flex items-center justify-center w-4 h-4 mr-1 bg-red-500 rounded-lg shadow"></div>
              <span className="mr-1 text-xs font-medium text-black">Les dates bloquées</span>
            </div>

            <Typography className="font-normal text-center">
              {showSpinner && (
                <div className="flex items-center justify-center">
                  <Spinner color="blue" className="w-24 h-24" />
                </div>
              )}
              {!showSpinner && (
                <div className="max-w-4xl mx-auto">
                  <BlockedDatesRangeInput
                    className="flex-1 z-[11]"
                    onDateChange={handleDateChange}
                    availableDays={locationData.availableDays}
                    fullyDays={fullyDaysState}
                    unavailableDays={unavailableState}
                  />
                  {Error && <div className="mt-4 text-red-500 text-md">{Error}</div>}
                </div>
              )}
            </Typography>
          </DialogBody>
          <DialogFooter className="space-x-2">
            <ButtonPrimary loading={onSubmitloading} onClick={onSubmit} className="dark:bg-white dark:text-black">
              Bloquer date
            </ButtonPrimary>

            <ButtonPrimary
              loading={onUnblockSubmitloading}
              onClick={onUnblockSubmit}
              className="dark:bg-white dark:text-black"
            >
              Libérer date
            </ButtonPrimary>
            <ButtonSecondary onClick={handleClose} className="dark:bg-white dark:text-black">
              Quitter
            </ButtonSecondary>
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
};

export default BlockedDetailsPage;
