import { ListBulletIcon, PlusCircleIcon, PuzzlePieceIcon } from "@heroicons/react/24/solid";
import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { LocationFormProps } from "data/reduxInterfaces";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { fetchPlaygrounds } from "redux/features/Admin/playground/playgroundSlice";
import { Checkbox, Spinner, Typography } from "@material-tailwind/react";
import { fetchEquipments } from "redux/features/Admin/equipments/equipmentSlice";
import Input from "shared/Input/Input";
import NcInputNumberOne from "components/NcInputNumber/NcInputNumberOne";

interface Playground {
  id: number;
  designation: string;
}

interface Equipment {
  id: number;
  designation: string;
}

const formSchema = z.object({
  equipment: z.array(z.string()).optional(),
  extraEquipment: z
    .array(
      z.object({
        designation: z.string(),
        price: z.number(),
        quantity: z.number(),
      })
    )
    .optional(),
  playground: z.array(z.string()).optional(),
});

const LocationPlayground: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  isLoading,
  handleForm,
  data,
  FormData,
}) => {
  const dispatch = useAppDispatch();

  const playgroundsStates = useAppSelector((state) => state.featurePlayground);
  const equipmentsStates = useAppSelector((state) => state.featureEquipment);

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      playground: data?.playground ?? [],
      equipment: data?.equipment ?? [],
      extraEquipment: data?.extraEquipment ?? [],
    },
    mode: "onSubmit",
  });

  const [dataLoading, setDataLoading] = useState(true);

  const [extraName, setExtraName] = useState<string>("");
  const [extraPrice, setExtraPrice] = useState<number | undefined>();
  const [extraQuantity, setExtraQuantity] = useState<number | undefined>(1);

  const [extraNameErrorMsg, setExtraNameErrorMsg] = useState<string | null>(null);
  const [extraPriceErrorMsg, setExtraPriceErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchEquipments());
    dispatch(fetchPlaygrounds());
  }, []);

  useEffect(() => {
    if (data) {
      setValue("playground", data.playground);
      setValue("equipment", data.equipment);
      setValue("extraEquipment", data.extraEquipment);
    }
  }, [data]);

  useEffect(() => {
    if (playgroundsStates.Playgrounds) {
      setDataLoading(false);
    }
  }, [playgroundsStates.Playgrounds]);

  useEffect(() => {
    if (equipmentsStates.equipments) {
      setDataLoading(false);
    }
  }, [equipmentsStates.equipments]);

  useEffect(() => {
    if (handleForm.type === "chekLocationFormFour") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const handleAddExtra = () => {
    if (!extraName) {
      setExtraNameErrorMsg("Ce champ et obligatoire");
    } else {
      setExtraNameErrorMsg(null);
    }

    if (extraPrice === undefined) {
      setExtraPriceErrorMsg("Ce champ et obligatoire");
    } else {
      setExtraPriceErrorMsg(null);
    }

    if (extraName && extraPrice !== undefined && extraQuantity !== undefined) {
      const extraValues = getValues("extraEquipment") || [];
      setValue("extraEquipment", [
        ...extraValues,
        {
          designation: extraName,
          price: extraPrice,
          quantity: extraQuantity,
        },
      ]);
      trigger("extraEquipment");

      setExtraName("");
      setExtraPrice(undefined);
      setExtraQuantity(1);
    }
  };

  const handleSelectEquipment = (equipmentId: string, isChecked: boolean) => {
    const updatedEquipment = isChecked
      ? [...(getValues("equipment") ?? []), equipmentId]
      : getValues("equipment")?.filter((equipment: string) => equipment !== equipmentId);

    setValue("equipment", updatedEquipment);
    trigger("equipment");
  };

  const handleRemoveExtra = (index: number) => {
    const extraEquipment = [...(getValues("extraEquipment") || [])];
    extraEquipment.splice(index, 1);
    setValue("extraEquipment", extraEquipment);
    trigger("extraEquipment");
  };

  const handleSelectPlayground = (playgroundDesignation: string, isChecked: boolean) => {
    const updatedPlaygrounds = isChecked
      ? [...(getValues("playground") ?? []), playgroundDesignation]
      : getValues("playground")?.filter((playground: string) => playground !== playgroundDesignation);

    setValue("playground", updatedPlaygrounds);
    trigger("playground");
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  return (
    <Fragment>
      <form className="flex flex-col md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full md:w-3/4 md:mr-4">
          <div>
            <h2 className="text-2xl font-semibold">Spécifications de votre espace </h2>
            <h3 className="mt-2 text-sm text-neutral-600">
              Ces équipements sont ceux que les visiteurs peuvent généralement trouver et utiliser gratuitement
              dans l'espace. Vous pouvez ajouter d'autres équipements supplémentaires en précisant leur prix
              (indiquez zéro si l'équipement est proposé gratuitement)
            </h3>

            <div className="mt-3 border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
          </div>
          <div className="mt-5">
            <div>
              <label className="font-semibold text" htmlFor="">
                Équipements événementiels
              </label>
              <div
                className={`grid grid-cols-2  mt-6 ${
                  equipmentsStates.equipments.length > 10 ? "sm:grid-cols-2 lg:grid-cols-3" : ""
                }`}
              >
                {dataLoading ? (
                  <div className="flex items-center justify-center mt-16">
                    <p>
                      <Spinner color="blue" className="w-12 h-12" />
                    </p>
                  </div>
                ) : (
                  equipmentsStates.equipments?.map((equipment: Equipment) => (
                    <Checkbox
                      key={equipment.id}
                      name={String(equipment.id)}
                      label={
                        <Typography className="text-dark dark:text-white">{equipment.designation}</Typography>
                      }
                      disabled={isLoading}
                      checked={!!getValues("equipment")?.includes(equipment.designation)}
                      onChange={(e) => handleSelectEquipment(equipment.designation, e.target.checked)}
                    />
                  ))
                )}
              </div>
            </div>

            <div>
              <div className="mt-2">
                <label className="font-semibold text" htmlFor="">
                  Équipements supplémentaires
                </label>
              </div>

              <div className="grid grid-cols-1 gap-8 mt-6 md:grid-cols-3 md:gap-5">
                <div className="md:col-span-3">
                  <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
                    {getValues("extraEquipment")?.map((extraValues: any, index: number) => (
                      <div key={index} className="flex items-center justify-between py-3">
                        {extraValues.price !== undefined && extraValues.price !== 0 ? (
                          <span className="font-medium text-neutral-6000 dark:text-neutral-400">
                            {extraValues.designation} : {extraValues.quantity} / {extraValues.price} €
                          </span>
                        ) : (
                          <span className="font-medium text-neutral-6000 dark:text-neutral-400">
                            {extraValues.designation} : {extraValues.quantity} / Gratuit
                          </span>
                        )}
                        <i
                          className="ml-4 text-2xl cursor-pointer text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100"
                          onClick={() => handleRemoveExtra(index)}
                        ></i>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex pt-2 md:col-span-4 md:gap-5">
                  <div className="block w-full">
                    <Input
                      placeholder="Nom"
                      name={"extraName"}
                      value={extraName}
                      type={"text"}
                      disabled={isLoading}
                      onChange={(e) => setExtraName(e.target.value)}
                    />

                    {extraNameErrorMsg && <div className="pl-2 text-xs text-red-500">{extraNameErrorMsg}</div>}
                  </div>

                  <div className="block w-full">
                    <Input
                      placeholder="Prix unitaire / € "
                      name={"extraPrice"}
                      value={extraPrice ?? ""}
                      type={"number"}
                      disabled={isLoading}
                      onChange={(e) => {
                        setExtraPrice(Number(e.target.value));
                      }}
                    />

                    {extraPriceErrorMsg && <div className="pl-2 text-xs text-red-500">{extraPriceErrorMsg}</div>}
                  </div>

                  <div className="block h-full">
                    <NcInputNumberOne
                      className="p-2"
                      defaultValue={extraQuantity}
                      min={1}
                      onChange={(value) => setExtraQuantity(value)}
                    />
                  </div>

                  <div className="block">
                    <ButtonPrimary
                      className="flex-shrink-0"
                      onClick={handleAddExtra}
                      type="button"
                      disabled={isLoading}
                    >
                      <i className="text-xl las la-plus"></i>
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div>
              <label className="mb-3 font-semibold" htmlFor="">
                Aire de jeux
              </label>
              <div
                className={`grid grid-cols-2 mt-6 ${
                  playgroundsStates.Playgrounds.length > 10 ? "sm:grid-cols-2 lg:grid-cols-3" : ""
                }`}
              >
                {dataLoading ? (
                  <div className="flex items-center justify-center mt-16">
                    <p>
                      <Spinner color="blue" className="w-12 h-12" />
                    </p>
                  </div>
                ) : (
                  playgroundsStates.Playgrounds?.map((playground: Playground) => (
                    <Checkbox
                      key={playground.id}
                      name={playground.designation}
                      label={
                        <Typography className="text-dark dark:text-white">{playground.designation}</Typography>
                      }
                      disabled={isLoading}
                      checked={!!getValues("playground")?.includes(playground.designation)}
                      onChange={(e) => handleSelectPlayground(playground.designation, e.target.checked)}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-4 md:w-1/3 md:mt-0">
          <div className="border-2 border-indigo-800">
            <div className="flex items-center mt-5 ml-5">
              <ListBulletIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Liste des équipements</h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mr-5 -ml-8 text-sm">
                Cochez les équipements disponibles dans cet espace pour votre événement ou votre utilisation.
                Sélectionnez parmi une liste d'options les équipements fournis, tels que tables et chaises, système
                audio, équipements de cuisine, écrans ou projecteurs, système d'éclairage spécifique, etc. Cette
                liste permettra aux utilisateurs de savoir quels équipements sont inclus dans la location de
                l'espace et de planifier en conséquence.
              </p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <PlusCircleIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Équipements supplémentaires</h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mt-2 mb-3 mr-5 -ml-8 text-sm">
                Vous avez la possibilité d'ajouter des équipements supplémentaires moyennant un coût additionnel.
                Pour chaque équipement supplémentaire requis, sélectionnez les options disponibles parmi une liste
                fournie et indiquez le coût spécifique de chaque équipement. Cela offre aux utilisateurs la
                flexibilité de choisir des équipements supplémentaires selon leurs besoins spécifiques pour leur
                événement ou leur utilisation, tout en précisant le coût supplémentaire associé à chaque équipement
                choisi.
              </p>
            </div>

            <div className="flex items-center mt-5 ml-5">
              <PuzzlePieceIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Aire de jeux</h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mb-3 mr-5 -ml-8 text-sm">
                Précisez si l'espace dispose ou non d'une aire de jeux. Indiquez si l'espace propose des
                installations destinées aux loisirs et activités ludiques, telles que des terrains de jeu pour
                enfants, des équipements sportifs spécifiques, ou toute autre zone dédiée aux divertissements.
                Cette information est utile pour les utilisateurs recherchant un endroit avec des installations
                spécifiques pour des activités récréatives.
              </p>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default LocationPlayground;
