import React, { FC } from "react";
import Faq from "./faq";
import { DefaultAccordion } from "../../components/Accordion/accordion";

export interface OwnerFaqProps {
  className?: string;
}
const prestataireFaq: FC<OwnerFaqProps> = ({ className = "" }) => {
  return (
    <>
      <Faq>
        <DefaultAccordion
          question={"Comment se connecter ?"}
          answer={
            "Bienvenue sur Event to Bee ! Pour profiter pleinement de nos services, suivez ces étapes simples pour créer votre compte ou bien se connecter "
          }
        >
          <ol className="ol">
            <li>
              <span>
                {" "}
                Création de compte Si vous êtes nouveau sur <b> Event to bee,</b> vous devrez créer un compte pour
                profiter pleinement de nos services. <b> Suivez ces étapes simples:</b>
              </span>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Accédez à la page de connexion:&nbsp; </b> Cliquez sur le bouton "Compte" depuis la page
                    d'accueil pour accéder à la page de connexion.
                  </li>

                  <li>
                    <b> Choisissez votre type de compte: &nbsp; </b> Sélectionnez le type de compte qui correspond
                    à votre rôle sur la plateforme : Client, Prestataire, Propriétaire.
                  </li>

                  <li>
                    {" "}
                    <b> Remplissez les champs obligatoires: &nbsp; </b> Suivez les étapes indiquées et complétez
                    les informations nécessaires.
                  </li>
                  <li>
                    {" "}
                    <b> Cliquez sur "S'inscrire":&nbsp; </b> Une fois que vous avez rempli les champs, cliquez sur
                    le bouton "S'inscrire" pour créer votre compte.{" "}
                  </li>

                  <li>
                    {" "}
                    <b>Vérifiez votre adresse e-mail: &nbsp; </b> Consultez votre boîte de réception, où vous
                    trouverez un e-mail de confirmation. Cliquez sur le lien fourni pour activer votre compte.{" "}
                  </li>
                </ul>
              </p>
            </li>

            <li>
              <span style={{ justifyContent: "start" }}>
                {" "}
                Connexion à votre compte existant Si vous avez déjà un compte,
                <b> suivez ces étapes pour vous connecter :</b>{" "}
              </span>
              <ul className="c" style={{ listStyleType: "square" }}>
                <p style={{ textAlign: "start", margin: "15px" }}>
                  <li>
                    <b> Accédez à la page de connexion : &nbsp; </b>Cliquez sur le bouton "Compte" depuis la page
                    d'accueil pour accéder à la page de connexion.
                  </li>
                  <li>
                    <b> Saisissez vos identifiants : &nbsp; </b> Entrez votre adresse e-mail et votre mot de passe
                    dans les champs appropriés.
                  </li>
                  <li>
                    <b> Cliquez sur "Se connecter" : &nbsp; </b> Une fois vos informations saisies, cliquez sur le
                    bouton "Se connecter" pour accéder à votre compte Event to Bee.
                  </li>
                  <li>
                    {" "}
                    Si vous avez oublié votre mot de passe, cliquez sur <b> "Mot de passe oublié" </b>
                    pour réinitialiser votre mot de passe.
                  </li>
                </p>
              </ul>
            </li>
          </ol>
        </DefaultAccordion>

        <DefaultAccordion question={"Comment ajouter une prestation en tant que prestataire ? "}>
          <ol>
            <li>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Connectez-vous à votre compte: &nbsp; </b>
                    Accédez à votre compte propriétaire sur Event to Bee en utilisant vos identifiants.
                  </li>
                  <li>
                    <b> Accédez à la section "Mes prestations" : &nbsp; </b>Une fois connecté, recherchez la
                    section dédiée à la gestion des prestations dans le tableau de bord.
                  </li>{" "}
                  <li>
                    <b> Cliquez sur "Ajouter une nouvelle prestation" : &nbsp; </b>
                    Sélectionnez l'option pour ajouter une nouvelle prestation.
                  </li>
                  <li>
                    <b> Remplissez les informations requises : &nbsp; </b>
                    Complétez les détails nécessaires pour décrire votre prestation, tels que le nom, la
                    description, le type d'événements, les équipements supplémentaires, les tarifs et toute
                    information pertinente.
                  </li>
                  <li>
                    <b> Téléchargez des photos : &nbsp; </b>
                    Ajoutez des détails visuels pour rendre votre prestation attrayante. Incluez des images de
                    qualité pour illustrer vos services.
                  </li>
                  <li>
                    <b> Paramètres de disponibilité : &nbsp; </b>
                    Spécifiez les horaires disponibles pour votre prestation. Cela garantira une coordination
                    fluide avec les clients.
                  </li>{" "}
                  <li>
                    <b> Fixez le tarif: &nbsp; </b>
                    Définissez clairement vos tarifs. Si vous proposez des options supplémentaires comme des
                    forfaits ou des services complémentaires, spécifiez-les ici.
                  </li>{" "}
                  <li>
                    <b> Vérification des informations: &nbsp; </b>Avant de publier, assurez-vous de vérifier toutes
                    les informations que vous avez fournies. Des détails précis attirent davantage les clients.
                  </li>
                  <li>
                    <b> Cliquez sur "Publier" : &nbsp; </b>Une fois toutes les informations correctement saisies,
                    cliquez sur le bouton approprié pour mettre en ligne votre prestation sur notre plateforme.
                  </li>
                </ul>
              </p>
            </li>
          </ol>
        </DefaultAccordion>

        <DefaultAccordion question={"Comment gérer les demandes client ? "}>
          <ol>
            <li>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Connectez-vous à votre compte : &nbsp; </b>
                    Accédez à votre compte propriétaire sur Event to Bee en utilisant vos identifiants.{" "}
                  </li>
                  <li>
                    <b> Accédez à la section "Mes demande de devis " : &nbsp; </b>
                    Une fois connecté, recherchez la section dédiée à la gestion des devis dans le tableau de bord.
                  </li>
                  <li>
                    <b> Consultez vos Demandes de Devis : &nbsp; </b>
                    Explorez les demandes en attente, en cours et complétées pour une gestion efficace.
                  </li>
                  <li>
                    <b> Réception des Demandes de Devis : &nbsp; </b>
                    Lorsqu'un client exprime le besoin d'un devis, veillez à ce qu'il fournisse toutes les
                    informations nécessaires pour établir une proposition précise.
                  </li>{" "}
                  <li>
                    <b> Accès aux coordonnées : &nbsp; </b>
                    Assurez-vous que toutes vos coordonnées sont à jour et disponibles. Les clients peuvent vous
                    contacter via <b> email, téléphone mobile et site web.</b>
                  </li>
                </ul>
              </p>
            </li>
          </ol>
        </DefaultAccordion>
      </Faq>
    </>
  );
};

export default prestataireFaq;
