const server = process.env.REACT_APP_SERVER_URL || "";

export const createEquipment = {
  add: async (formData: any) => {
    try {
      const { designation } = formData;

      const response = await fetch(`${server}/api/equipment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ designation }),
      });
      if (response.ok) {
        const data = await response.json();

        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw error;
    }
  },

  verify: async () => {
    try {
      const response = await fetch(`${server}/api/equipment`);
      if (!response.ok) {
        throw new Error("Failed to fetch categories");
      }
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  delete: async (id: number) => {
    try {
      const response = await fetch(`${server}/api/equipment/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete Equipment");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },

  update: async (data: any, id: any) => {
    try {
      const { designation } = data;

      const response = await fetch(`${server}/api/equipment/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({ designation }),
      });
      if (response.ok) {
        const data = await response.json();

        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw error;
    }
  },
  getById: async (id: number) => {
    try {
      const response = await fetch(`${server}/api/equipment/${id}`, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Failed to delete category");
      }

      const data = await response.json();
      return data;
    } catch (error) {}
  },
};
