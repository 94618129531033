import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { reservation } from "./featureAPI";
import { Reservation } from "data/types";

interface ReservationState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  reservationError: string;
  error: SerializedError | "";
  reservationData?: Reservation;
  count: number;
}

const initialState = {
  totalPages: [] as Reservation[],
  count: 0,
  reservationData: undefined,
  status: "",
  loading: "idle",
  reservations: [],
  reservationOwner: [],
  canceledByClient: [],
  tentative: [],
  enAttente: [],
  inProgress: [],
  totalAmount: 0,
  canceledByOwner: [],
  pending: [],
  availableHours: [],
  fullyDays: [],
  sessionUrl: "",
  message: "",
  reservationDetail: {
    payment_status: "",
    id: "",
    map: "",
    selectedDate: "",
    startHour: "",
    endHour: "",
    validation: true,
    visitor: 0,
    locationId: "",
    ownerId: "",
    clientId: "",
    price: 0,
    Location: {
      map: "",
      atmosphere: [],
      playground: [],
      availableDays: "[]",
      events: [],
      comments: { roundedMoyenne: 0, comments: [] },
      comment: { loading: "" },
      equipments: [],
      extras: [],
      conditions: [],
      ownerLocations: [],
      images: [],
      id: null,
      title: "",
      description: "",
      region: null,
      address: "",
      address2: "",
      city: "",
      visitor: null,
      surface: "",
      light: false,
      price: "",
      startHour: "",
      endHour: "",
      parking: "",
      water: false,
      wc: "",
      handicap: false,
      guarantee: null,
      validation: false,
      location_url: null,
      createdAt: null,
      updatedAt: null,
      ownerId: null,
      categoryLocationId: null,
      CategoryLocation: {
        id: null,
        designation: "",
        createdAt: null,
        updatedAt: null,
      },
    },
    Owner: {
      id: null,
      name: "",
      lastName: "",
      identityNumber: "",
      rib: "",
      bank: "",
      phone: "",
      birthday: null,
      identityFImg_url: "",
      identityBImg_url: "",
      adresseImg_url: "",
      pays: null,
      companyCertifImg_url: "",
      companyName: "",
      companySiren: "",
      adresse: "",
      codePostale: "",
      suppAdresse: "",
      ville: "",
      iban_url: "",
      owner_url: "",
      validation: false,
      category: "",
      createdAt: null,
      updatedAt: null,
      userId: null,
    },
    Client: { userId: "" },
  },
};

export const repayReservationFee = createAsyncThunk("featureReservation/repayReservationFee", async (id: any) => {
  return await reservation.repayment(id);
});

export const cancelReservation = createAsyncThunk("featureReservation/cancelReservation", async (data: any) => {
  return await reservation.sendCancelEmail(data);
});

export const fetchReservationByLocation = createAsyncThunk(
  "featureReservation/fetchReservationByLocation",
  async (id: any) => {
    return await reservation.getReservationByLocations(id);
  }
);
export const fetchReservation = createAsyncThunk(
  "featureReservation/fetchReservation",
  async (pageNumber: number) => {
    return await reservation.getReservations(pageNumber);
  }
);

export const fetchOwnerTransferts = createAsyncThunk(
  "featureReservation/fetchOwnerTransactions",
  async ({ pageNumber, owner_id }: { pageNumber: number; owner_id: number }) => {
    return await reservation.getOwnerAllTransferts(pageNumber, owner_id);
  }
);

export const fetchOwnerTransfertsGain = createAsyncThunk(
  "featureReservation/fetchOwnerTransfertsGain",
  async ({ pageNumber, owner_id }: { pageNumber: number; owner_id: number }) => {
    return await reservation.getOwnerAllTransfertsGain(pageNumber, owner_id);
  }
);

export const transfertAmountOwner = createAsyncThunk(
  "featureReservation/transfertAmountOwner",
  async ({ amount, owner_id }: { amount: number; owner_id: string }) => {
    return await reservation.transfertAmountOwner(amount, owner_id);
  }
);

export const fetchTentativeReservation = createAsyncThunk(
  "featureReservation/fetchTentativeReservation",
  async (pageNumber: number) => {
    return await reservation.getTentativeReservations(pageNumber);
  }
);

export const fetchAvailableHours = createAsyncThunk(
  "featureReservation/fetchAvailableHours",
  async ({ id, selectedDay }: { id: any; selectedDay: any }) => {
    return await reservation.getAvailableHours(id, selectedDay);
  }
);
export const fetchfullyDays = createAsyncThunk("featureReservation/fetchfullyDays", async (id: any) => {
  return await reservation.getFullyBookedDays(id);
});

export const fetchReservationById = createAsyncThunk(
  "featureReservation/fetchReservationById",
  async (id: any) => {
    return await reservation.getReservationById(id);
  }
);
export const checkReservationByReference = createAsyncThunk(
  "featureReservation/checkReservationByReference",
  async (ref: any) => {
    return await reservation.checkReservationByReference(ref);
  }
);
export const fetchAdminCanceledReservationByClient = createAsyncThunk(
  "featureReservation/getCancledReservation",
  async (pageNumber: number) => {
    return await reservation.fetchAdminCanceledReservationByClient(pageNumber);
  }
);
export const payRefundReservation = createAsyncThunk(
  "featureReservation/payRefundReservation",
  async (ref: any) => {
    return await reservation.payRefundReservation(ref);
  }
);
export const payPartialRefundReservation = createAsyncThunk(
  "featureReservation/payPartialRefundReservation",
  async (ref: any) => {
    return await reservation.payPartialRefundReservation(ref);
  }
);

export const updateReservation = createAsyncThunk(
  "featureReservation/editReservation",
  async ({ data, id }: { data: any; id: any }) => {
    return await reservation.update(data, id);
  }
);
export const confirmReservation = createAsyncThunk(
  "featureReservation/confirmReservation",
  async ({ data, id }: { data: any; id: any }) => {
    return await reservation.confirm(data, id);
  }
);

export const fetchReservationByClient = createAsyncThunk(
  "featureReservation/fetchReservationByClient",
  async (id: any) => {
    return await reservation.getReservationByClient(id);
  }
);

export const fetchReservationByOwner = createAsyncThunk(
  "featureReservation/fetchReservationByOwner",
  async (id: any) => {
    return await reservation.getReservationByOwner(id);
  }
);

export const fetchReservationByOwnerAsClient = createAsyncThunk(
  "featureReservation/fetchReservationByOwnerAsClient",
  async (id: any) => {
    return await reservation.getReservationByOwnerAsClient(id);
  }
);

export const fetchAdminCanceledReservationByOwner = createAsyncThunk(
  "featureReservation/fetchPendingReservation",
  async (pageNumber: number) => {
    return await reservation.fetchAdminCanceledReservationByOwner(pageNumber);
  }
);

export const fetchEnAttenteReservation = createAsyncThunk(
  "featureReservation/fetchEnAttenteReservation",
  async (pageNumber: number) => {
    return await reservation.getEnAttenteReservation(pageNumber);
  }
);

export const fetchPendingOwnerReservation = createAsyncThunk(
  "featureReservation/fetchPendingOwnerReservation",
  async (id: any) => {
    return await reservation.getPendingOwnerReservation(id);
  }
);
export const fetchBlockedReservationByOwner = createAsyncThunk(
  "featureReservation/fetchBlockedReservationByOwner",
  async (id: any) => {
    return await reservation.getBlockedReservationByOwner(id);
  }
);

export const saveReservation = createAsyncThunk("featureReservation/saveReservation", async (formData: any) => {
  return formData;
});

export const deleteReservation = createAsyncThunk("featureReservation/deleteReservation", async (id: any) => {
  return await reservation.deleteReservation(id);
});
export const payReservation = createAsyncThunk("featureReservation/payReservation", async (data: any) => {
  return await reservation.payReservation(data);
});
export const payReservationOwner = createAsyncThunk(
  "featureReservation/payReservationOwner",
  async (data: any) => {
    return await reservation.payReservationOwner(data);
  }
);
export const checkReservation = createAsyncThunk("featureReservation/checkReservation", async (data: any) => {
  return await reservation.checkReservation(data);
});

export const makeReservationOwner = createAsyncThunk(
  "featureReservation/makeReservationOwner",
  async (data: any) => {
    return await reservation.makeReservationOwner(data);
  }
);

export const filterAllReservation = createAsyncThunk("filterAllReservation", async (data: any) => {
  return await reservation.filterAllReservation(data);
});

const reservationSlice = createSlice({
  name: "featureReservation",
  initialState,
  reducers: {
    initialState(state) {
      state.status = "";
      state.loading = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(confirmReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(confirmReservation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload;
      })
      .addCase(confirmReservation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(payRefundReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(payRefundReservation.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(payRefundReservation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(payPartialRefundReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(payPartialRefundReservation.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(payPartialRefundReservation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(makeReservationOwner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(makeReservationOwner.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(makeReservationOwner.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(cancelReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(cancelReservation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reservationDetail = action.payload;
      })
      .addCase(cancelReservation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchReservationByLocation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReservationByLocation.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.reservations = action.payload;
      })
      .addCase(fetchReservationByLocation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchAdminCanceledReservationByClient.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAdminCanceledReservationByClient.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.count = action.payload.count;
        state.totalPages = action.payload.totalPages;
        state.canceledByClient = action.payload.reservations;
      })
      .addCase(fetchAdminCanceledReservationByClient.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchTentativeReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTentativeReservation.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.count = action.payload.count;
        state.totalPages = action.payload.totalPages;
        state.tentative = action.payload.reservations;
      })
      .addCase(fetchTentativeReservation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReservation.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.count = action.payload.count;
        state.totalPages = action.payload.totalPages;
        state.inProgress = action.payload.reservations;
      })
      .addCase(fetchReservation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchReservationById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReservationById.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.reservationDetail = action.payload;
      })
      .addCase(fetchReservationById.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(checkReservationByReference.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkReservationByReference.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.reservationDetail = action.payload.reservation;
        state.message = action.payload.message;
      })
      .addCase(checkReservationByReference.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchReservationByClient.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReservationByClient.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.reservations = action.payload;
      })
      .addCase(fetchReservationByClient.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchReservationByOwner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReservationByOwner.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.reservations = action.payload;
      })
      .addCase(fetchReservationByOwner.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchReservationByOwnerAsClient.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReservationByOwnerAsClient.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.reservationOwner = action.payload;
      })
      .addCase(fetchReservationByOwnerAsClient.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchAdminCanceledReservationByOwner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAdminCanceledReservationByOwner.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.count = action.payload.count;
        state.totalPages = action.payload.totalPages;
        state.canceledByOwner = action.payload.reservations;
      })
      .addCase(fetchAdminCanceledReservationByOwner.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchEnAttenteReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEnAttenteReservation.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.count = action.payload.count;
        state.totalPages = action.payload.totalPages;
        state.enAttente = action.payload.reservations;
      })
      .addCase(fetchEnAttenteReservation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchPendingOwnerReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPendingOwnerReservation.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.reservations = action.payload;
      })
      .addCase(fetchPendingOwnerReservation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchBlockedReservationByOwner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlockedReservationByOwner.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.reservations = action.payload;
      })
      .addCase(fetchBlockedReservationByOwner.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(saveReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveReservation.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.reservationDetail = action.payload;
      })
      .addCase(saveReservation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(payReservationOwner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(payReservationOwner.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.message = action.payload;
      })
      .addCase(payReservationOwner.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchAvailableHours.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchAvailableHours.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.availableHours = action.payload;
      })
      .addCase(fetchAvailableHours.rejected, (state) => {
        state.loading = "failed";
      });

    builder
      .addCase(fetchfullyDays.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchfullyDays.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.fullyDays = action.payload;
      })
      .addCase(fetchfullyDays.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(payReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(payReservation.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.sessionUrl = action.payload;
      })
      .addCase(payReservation.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(repayReservationFee.pending, (state) => {
        state.status = "loading";
      })
      .addCase(repayReservationFee.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.sessionUrl = action.payload;
      })
      .addCase(repayReservationFee.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(filterAllReservation.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(filterAllReservation.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.reservationData = action.payload;
      })
      .addCase(filterAllReservation.rejected, (state, action) => {
        state.reservationData = undefined;
        state.loading = "failed";
      });

    builder
      .addCase(fetchOwnerTransferts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOwnerTransferts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.count = action.payload.count;
        state.totalPages = action.payload.totalPages;
        state.inProgress = action.payload.reservations;
      })
      .addCase(fetchOwnerTransferts.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(transfertAmountOwner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(transfertAmountOwner.fulfilled, (state, action) => {
        state.loading = "succeeded";
      })
      .addCase(transfertAmountOwner.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(fetchOwnerTransfertsGain.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOwnerTransfertsGain.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.count = action.payload.count;
        state.totalPages = action.payload.totalPages;
        state.inProgress = action.payload.reservations;
        state.totalAmount = action.payload.totalAmount;
      })
      .addCase(fetchOwnerTransfertsGain.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = reservationSlice.actions;
export default reservationSlice.reducer;
