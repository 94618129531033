import { FC } from "react";
import { StarIcon } from "@heroicons/react/24/solid";
export interface StartRatingHomeProps {
  className?: string;
  reviewCount?: any;
  point?: any;
  locId?: any;
}
const StartRatingHome: FC<StartRatingHomeProps> = ({ className = "", point, reviewCount }) => {
  return (
    <div className={`nc-StartRating flex items-center space-x-1 text-sm ${className}`} data-nc-id="StartRating">
      <div className="pb-[2px]">
        <StarIcon className="w-[18px] h-[18px] text-orange-500" />
      </div>
      <span className="font-medium">{point}</span>
      <span className="text-neutral-500 dark:text-neutral-400">({reviewCount})</span>
    </div>
  );
};

export default StartRatingHome;
