import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import MobileFooterSticky from "./(components)/MobileFooterSticky";
import { useAppSelector } from "hooks/hooks";

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const modal = searchParams?.get("gallery");

  let locationState = useAppSelector((state) => state.featureLocation.locationDetail);
  let serviceState = useAppSelector((state) => state.service);

  const [ApiImages, setApiImages] = useState<any[]>([]);

  const extractedId = extractIdFromPathname(location.pathname);
  const extractedServiceId = extractIdServiceFromPathname(location.pathname);

  useEffect(() => {
    if (extractedId && locationState) {
      const locationImages = locationState.images?.map((url: any) => ({ url })) || [];
      setApiImages(locationImages);
    }
    if (extractedServiceId && serviceState) {
      const serviceImages = serviceState.serviceData?.images?.map((url: any) => ({ url })) || [];
      setApiImages(serviceImages);
    }
  }, [extractedId, locationState, serviceState]);

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(window.location.search);
    params.delete("gallery");
    navigate(`${extractLocation(location.pathname)}?${params.toString()}`);
  };

  function extractLocation(pathname: string) {
    const match = pathname.match(/(location|prestations)\/(\d+)\/?/);
    if (match && match[2]) {
      return `/${match[1]}/${match[2]}`;
    } else {
      return pathname;
    }
  }

  function extractIdFromPathname(pathname: string) {
    const parts = pathname.split("/");
    const idIndex = parts.indexOf("location") + 1;
    if (idIndex > 0 && idIndex < parts.length) {
      const id = parts[idIndex];
      const cleanedId = id.replace(/\//g, "");
      return cleanedId;
    } else {
      return null;
    }
  }

  function extractIdServiceFromPathname(pathname: string) {
    const parts = pathname.split("/");
    const idIndex = parts.indexOf("prestations") + 1;
    if (idIndex > 0 && idIndex < parts.length) {
      const id = parts[idIndex];
      const cleanedId = id.replace(/\//g, "");
      return cleanedId;
    } else {
      return null;
    }
  }

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "images"}
        onClose={handleCloseModalImageGallery}
        images={ApiImages}
      />

      <div className="container ListingDetailPage__content">{children}</div>
    </div>
  );
};

export default DetailPagetLayout;
