import { Card, CardBody, Typography } from "@material-tailwind/react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { FaCalendarTimes, FaPen, FaUsers } from "react-icons/fa";
import { FaCalendarCheck } from "react-icons/fa6";
import { HiHome } from "react-icons/hi2";
import { IoIosPeople } from "react-icons/io";
import { PiUsersThreeFill } from "react-icons/pi";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getStats } from "redux/features/auth/authSlice";
import CommonLayout from "./DashboardMenu";

export interface AdminProfileProps {
  className?: string;
}

const AdminProfile: FC<AdminProfileProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();

  const stats = useAppSelector((state) => state?.auth?.stats);

  useEffect(() => {
    dispatch(getStats());
  }, []);

  const statisticCards = [
    {
      title: "Total clients",
      value: stats?.clientsCount,
      color: "cyan",
      icon: <FaUsers className="w-8 h-8" />,
      href: "/dashboard/clients",
    },
    {
      title: "Total propriétaires",
      value: stats?.ownersCount,
      color: "cyan",
      icon: <IoIosPeople className="w-8 h-8" />,
      href: "/dashboard/owners",
    },
    {
      title: "Total prestataires ",
      value: stats?.agentsCount,
      color: "cyan",
      icon: <PiUsersThreeFill className="w-8 h-8" />,
      href: "/dashboard/agents",
    },
    {
      title: "Total rédacteur",
      value: stats?.creatorsCount,
      color: "cyan",
      icon: <FaPen className="w-8 h-8" />,
      href: "/dashboard/creator-list",
    },
    {
      title: "Espaces en attente",
      value: stats?.inProgressLocations,
      color: "cyan",
      icon: <HiHome className="w-8 h-8" />,
      href: "/dashboard/locations/unApprouved",
    },
    {
      title: "Espaces non complétés ",
      value: stats?.pendingLocations,
      color: "cyan",
      icon: <HiHome className="w-8 h-8" />,
      href: "/dashboard/locations/incompleted",
    },

    {
      title: "Réservations en cours ",
      value: stats?.inProgressReservations,
      color: "cyan",
      icon: <FaCalendarCheck className="w-8 h-8" />,
      href: "/dashboard/reservation-admin",
    },

    {
      title: "Réservations annulées ",
      value: stats?.canceledReservationByClient,
      color: "cyan",
      icon: <FaCalendarTimes className="w-8 h-8" />,
      href: "/dashboard/canceled-admin",
    },

    {
      title: "Solde disponible",
      value: stats?.availableAmount + " €",
      color: "cyan",
      icon: <RiMoneyEuroCircleFill className="h-9 w-9" />,
      href: "#",
    },

    {
      title: "Solde en attente ",
      value: stats?.pengindAmount + " €",
      color: "cyan",
      icon: <RiMoneyEuroCircleFill className="h-9 w-9" />,
      href: "#",
    },

    {
      title: "Réclamations ",
      value: stats?.claimsCount,
      color: "cyan",
      icon: <BiSolidMessageRoundedDetail className="w-8 h-8" />,
      href: "/dashboard/reclamation-admin",
    },
  ];

  return (
    <div className={`nc-AccountPage`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>

      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div className="container max-w-6xl px-5 mx-auto my-28">
            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
              {statisticCards.map((card) => (
                <Link to={card.href}>
                  <Card className="shadow-xl   border border-gray-200 transition duration-1000 ease-in-out !rounded-lg flex flex-col group bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-r hover:from-eventToBee hover:bg-eventToBee">
                    <CardBody className="flex flex-col justify-between flex-1 p-4 !rounded-lg  ">
                      <div className="flex items-center justify-between">
                        <Typography className="!font-semibold text-base  text-white whitespace-nowrap ">
                          {card.title}
                        </Typography>
                        <div className="flex items-center gap-1 text-white">{card.icon}</div>
                      </div>
                      <div className="mt-auto">
                        <Typography color="blue-gray" className="font-semibold text-white">
                          {card.value}
                        </Typography>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AdminProfile;
