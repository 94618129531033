import { FC, Fragment, HTMLAttributes, useEffect } from "react";
import Input from "shared/Input/Input";
import FormItem from "./FormItem";

import { Square3Stack3DIcon, TagIcon, UsersIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { LocationFormProps } from "data/reduxInterfaces";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { fetchCategorys } from "redux/features/Admin/categorys/categorySlice";
import Select from "shared/Select/Select";
import { z } from "zod";

interface Category {
  id: number;
  designation: any;
}

const formSchema = z.object({
  categoryLocationId: z.number({
    required_error: "Le category est obligatoire.",
    invalid_type_error: "Le category doit étre un nombre.",
  }),
  surface: z.number({
    required_error: "La surface est obligatoire.",
    invalid_type_error: "La surface doit étre un nombre.",
  }),
  visitor: z.number({
    required_error: 'Le capacité d"accueil est obligatoire.',
    invalid_type_error: 'Le capacité d"accueil doit étre un nombre.',
  }),
  nbreHour: z.number({
    required_error: 'Le nombre d"heures est obligatoire.',
    invalid_type_error: 'Le nombre d"heures doit étre un nombre.',
  }),
});

const LocationProfile: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  isLoading,
  handleForm,
  data,
  FormData,
}) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      categoryLocationId: data?.categoryLocationId ?? undefined,
      surface: data?.surface ?? undefined,
      visitor: data?.visitor ?? undefined,
      nbreHour: data?.nbreHour ?? undefined,
    },
    mode: "onSubmit",
  });

  const categorys: Category[] = useAppSelector((state) => state.featureCategory.categories);
  const nbreHours = Array.from({ length: 9 }, (_, index) => ({ value: index + 2 }));

  useEffect(() => {
    dispatch(fetchCategorys());
  }, []);

  useEffect(() => {
    if (data) {
      setValue("categoryLocationId", data.categoryLocationId);
      setValue("surface", data.surface);
      setValue("visitor", data.visitor);
      setValue("nbreHour", data.nbreHour);
    }
  }, [data]);

  useEffect(() => {
    if (handleForm.type === "chekLocationFormOne") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    if (data.nbreHour === undefined) {
      data.nbreHour = 2;
    }
    FormData(data);
  };

  return (
    <Fragment>
      <form className="flex flex-col md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full md:w-3/4 md:mr-4">
          <h2 className="mt-5 text-2xl font-semibold">Ajouter vos Informations</h2>

          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

          <div className="my-5 space-y-8">
            <FormItem label="Choisir une catégorie *">
              <div className="md:w-1/2">
                <Select
                  className={`mt-1.5 ${errors.categoryLocationId?.message ? "border-red-500" : ""}`}
                  disabled={isLoading}
                  value={getValues("categoryLocationId")}
                  onChange={(e) => setValue("categoryLocationId", Number(e.target.value))}
                >
                  <option value={""}>Sélectionner une categorie...</option>
                  {categorys.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.designation}
                    </option>
                  ))}
                </Select>
                {errors.categoryLocationId?.message && (
                  <div className="text-xs text-red-500">{`${errors.categoryLocationId?.message}`}</div>
                )}
              </div>
            </FormItem>

            <FormItem label="Superficie (m²) *" className="mt-1.5">
              <div className="md:w-1/2">
                <Input
                  className={`mt-1.5 ${errors.surface?.message ? "border-red-500" : ""}`}
                  placeholder="Unité en m²"
                  type="number"
                  disabled={isLoading}
                  {...register("surface", { valueAsNumber: true })}
                />
                {errors.surface?.message && (
                  <div className="text-xs text-red-500">{`${errors.surface?.message}`}</div>
                )}
              </div>
            </FormItem>

            <FormItem label="Capacité d'accueil *" className="mt-1.5">
              <div className="md:w-1/2">
                <Input
                  className={`mt-1.5 ${errors.visitor?.message ? "border-red-500" : ""}`}
                  placeholder="..."
                  type="number"
                  disabled={isLoading}
                  {...register("visitor", { valueAsNumber: true })}
                />
                {errors.visitor?.message && (
                  <div className="text-xs text-red-500">{`${errors.visitor?.message}`}</div>
                )}
              </div>
            </FormItem>

            <FormItem label="Durée minimale en heures *" className="mt-1.5">
              <div className="md:w-1/2">
                <Select
                  className={`mt-1.5 ${errors.categoryLocationId?.message ? "border-red-500" : ""}`}
                  disabled={isLoading}
                  value={getValues("nbreHour")}
                  onChange={(e) => setValue("nbreHour", parseInt(e.target.value))}
                >
                  <option value={""}>Sélectionner</option>
                  {nbreHours.map((hour, index) => (
                    <option key={index} value={hour.value}>
                      {hour.value}
                    </option>
                  ))}
                </Select>
                {errors.nbreHour?.message && (
                  <div className="text-xs text-red-500">{`${errors.nbreHour?.message}`}</div>
                )}
              </div>
            </FormItem>
          </div>
        </div>

        <div className="w-full mt-4 md:w-1/3 md:mt-0">
          <div className="p-4 border-2 border-indigo-800">
            <div className="flex items-center mt-5 ml-5">
              <TagIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">
                Catégorie <span className="text-xs">(Type d'espace)</span>
              </h2>
            </div>

            <div className="mt-2 ml-16">
              <p className="mr-5 -ml-8 text-sm">
                Sélectionnez la catégorie ou le type de votre espace parmi une liste prédéfinie afin de décrire au
                mieux sa nature
              </p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <Square3Stack3DIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Superficie de l'espace</h2>
            </div>

            <div className="mt-2 ml-16">
              <p className="mt-2 -ml-8 text-sm">
                Indiquez la surface totale de votre espace en mètres carrés. Cette information est importante pour
                que les utilisateurs puissent se faire une idée de la taille de l'espace disponible pour leur
                événement.
              </p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <UsersIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Capacité d'accueil</h2>
            </div>

            <div className="mt-2 ml-16">
              <p className="mb-5 -ml-8 text-sm">
                Indiquez la capacité maximale d'accueil de l'espace en nombre de personnes afin d'aider les
                utilisateurs à déterminer si celui-ci convient à leur événement.
              </p>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default LocationProfile;
