import { FC, useContext, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import AvatarDropdown from "./AvatarDropdown";
import { AuthContext } from "context/appContext";
import { Link, useLocation } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import NotifyDropdown from "./NotifyDropdown";
import { useAppSelector } from "hooks/hooks";
import { socket } from "utils/socket";
import NotificationModal from "../NotificationModal";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const userState = useAppSelector((state) => state?.auth?.user);

  const authContext = useContext(AuthContext);
  const location = useLocation();

  const [notificationSeen, setNotificationSeen] = useState<any>(null);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [notification, setnotification] = useState<any>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    if (userState) {
      socket.emit("load-notifications", { userId: userState?.id });
      socket.on("fetched-notifications", (data) => {
        if (data[0]) {
          setNotificationSeen(!data[0]?.vue);
        }

        setNotifications(data);
      });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userState]);
  useEffect(() => {
    socket.on("get-notification", (data: any) => {
      if (data) {
        setnotification(data);
        setNotifications((prevData) => [data, ...prevData]);

        setNotificationSeen(!data?.vue);
        setTimeout(() => {
          setnotification(null);
        }, 3000);
      }
    });
  }, [socket]);

  const isHomePage = () => {
    return location.pathname === "/";
  };

  const handleNotificationSeen = () => {
    if (userState) {
      socket.emit("notification-seen", {
        userId: userState.id,
      });

      setNotificationSeen(false);
    }
  };

  return (
    <>
      <div className={`relative z-10 nc-MainNav1 ${className}`}>
        <div
          className={
            isSmallScreen ? "" : "relative flex items-center justify-between px-4 py-4 lg:container lg:py-5"
          }
        >
          {isSmallScreen && !isHomePage() && (
            <div className="relative flex items-center justify-between px-4 py-4 lg:container lg:py-5">
              <ButtonCircle
                onClick={() => {
                  if (window.location.pathname.includes("/location/")) {
                    window.location.href = "/locations";
                  }

                  if (window.location.pathname.includes("/prestations/")) {
                    window.location.href = "/prestations";
                  } else {
                    window.history.back();
                  }
                }}
                className="absolute transform -translate-y-1/2 top-1/2"
                size="w-6 h-6 "
              >
                <ArrowLeftIcon className="w-5 h-5" />
              </ButtonCircle>
            </div>
          )}

          <div className="items-center justify-start flex-1 hidden space-x-4 md:flex sm:space-x-10">
            <Logo className="w-40" />
          </div>

          <div className="flex items-center justify-center flex-grow">
            <Navigation />
          </div>

          <div className="items-center justify-end flex-1 flex-shrink-0 hidden md:flex lg:flex-none text-neutral-700 dark:text-neutral-100">
            <div className="hidden xl:flex items-center space-x-0.5">
              <div className="px-1" />

              {authContext?.user?.role !== "admin" &&
                authContext?.user?.role !== "creator" &&
                authContext?.user?.role !== "service" && (
                  <div className="px-3">
                    <Link
                      to="/add/location"
                      className="items-center hidden px-4 py-2 text-sm font-medium text-gray-700 border rounded-full text-opacity-90 xl:inline-flex group border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 dark:text-neutral-300 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Ajouter votre espace
                    </Link>
                  </div>
                )}
              {authContext?.user?.role === "service" && (
                <div className="px-3">
                  <Link
                    to="/add/service"
                    className="items-center hidden px-4 py-2 text-sm font-medium text-gray-700 border rounded-full text-opacity-90 xl:inline-flex group border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 dark:text-neutral-300 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                  >
                    Ajouter votre prestation
                  </Link>
                </div>
              )}
              <SwitchDarkMode />

              {authContext.isAuthenticated && (
                <div className="pr-1.5">
                  <NotifyDropdown
                    open={false}
                    className="-ml-2 xl:-ml-1"
                    new_notification={notificationSeen}
                    notifications={notifications}
                    notificationSeen={handleNotificationSeen}
                  />
                </div>
              )}
              {authContext.isAuthenticated ? (
                <AvatarDropdown />
              ) : (
                <ButtonPrimary href="/login">Compte</ButtonPrimary>
              )}
            </div>

            <div className="flex items-center xl:hidden">
              <SwitchDarkMode />
              <div className="px-0.5" />
              <MenuBar />
            </div>
          </div>
        </div>
      </div>
      {notification && (
        <>
          <NotificationModal
            onHideModal={() => {
              setnotification(null);
            }}
            state={notification?.sender}
            message={notification?.message}
          ></NotificationModal>
        </>
      )}
    </>
  );
};

export default MainNav1;
