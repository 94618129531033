import { Helmet } from "react-helmet";
import { FC } from "react";

import CommonLayout from "../../AccountPage/CommonLayout";
import ClientReservation from "../ClientReservation";

export interface AccountPageProps {
  className?: string;
}

const OwnerReservationPage: FC<AccountPageProps> = ({ className = "" }) => {
  return (
    <CommonLayout>
      <div
        className={`overflow-hidden relative nc-ListingFlightsPage ${className}`}
        data-nc-id="ListingFlightsPage"
      >
        <Helmet>
          <title>Event To Bee</title>
        </Helmet>

        <div className=""></div>
      </div>
    </CommonLayout>
  );
};

export default OwnerReservationPage;
