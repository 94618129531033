import { Helmet } from "react-helmet";
import OwnerMenu from "../AccountPage/OwnerMenu";
import { FC } from "react";
import SectionGridCanceledCardOwner from "containers/ListingLocationsDashboardPage/SectionGridCanceledCardOwner";
export interface OwnerCanceledReservationPageProps {
  className?: string;
}

const OwnerCanceledReservationPage: FC<OwnerCanceledReservationPageProps> = ({ className = "" }) => {
  return (
    <OwnerMenu>
      <div
        className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
        data-nc-id="ListingFlightsPage"
      >
        <Helmet>
          <title>Event To Bee</title>
        </Helmet>

        <div className="container relative">
          <SectionGridCanceledCardOwner className="pb-24 lg:pb-28" />
        </div>
      </div>
    </OwnerMenu>
  );
};

export default OwnerCanceledReservationPage;
