const baseURL = process.env.REACT_APP_SERVER_URL;

export const ContactApi = {
  createContactRequest: async (data: any) => {
    const result = await fetch(`${baseURL}/api/contact/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify({ ...data }),
    });

    let response = await result.json();
    if (result.ok) {
      return response;
    } else {
      throw new Error(response.message);
    }
  },

  getAllContact: async () => {
    const result = await fetch(`${baseURL}/api/contact/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    let response = await result.json();
    if (result.ok) {
      return response;
    } else {
      throw new Error(response.message);
    }
  },

  deleteContact: async (id: any) => {
    const result = await fetch(`${baseURL}/api/contact/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    let response = await result.json();
    if (result.ok) {
      return response;
    } else {
      throw new Error(response.message);
    }
  },

  getContactById: async (id: any) => {
    try {
    } catch (error) {}
  },
};
