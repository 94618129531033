import { AuthContext } from "context/appContext";
import { Location } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getLocationByUserId, resetUpdateValues } from "redux/features/location/locationSlice";
import Pagination from "shared/Pagination/Pagination";
import BlockedDetailsPage from "./BlockedDetailsPage";
import homeIcon from "../../images/home-icon.jpg";
export interface SectionGridFilterCardOwnerProps {
  className?: string;
}

const SectionGridFilterCardOwner: FC<SectionGridFilterCardOwnerProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  const owner_status = authContext?.user?.profil?.validation;
  const profilId = authContext?.user?.profil?.id;

  const dispatch = useAppDispatch();
  const locationStates = useAppSelector((state) => state.location?.locationData || []) as Location[];

  useEffect(() => {
    if (profilId) {
      dispatch(getLocationByUserId(String(profilId)));
    }
  }, [profilId]);

  useEffect(() => {
    dispatch(resetUpdateValues());
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [showBlockedDetails, setshowBlockedDetails] = useState(false);
  const [selectedLocation, setselectedLocation] = useState(null);
  const paginatedLocations = Array.isArray(locationStates)
    ? locationStates.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];
  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };
  const handleEyeIconClick = (location: any) => {
    setselectedLocation(location);
    setshowBlockedDetails(!showBlockedDetails);
  };
  const length = locationStates?.length;
  const totalPages = Math.ceil(locationStates?.length / itemsPerPage);

  return (
    <div className={`w-full rounded-md ${className}`}>
      <div className="flex items-center justify-between pb-1">
        <div>
          <h2 className="font-semibold text-gray-600 dark:text-white">Mes espaces</h2>
        </div>
      </div>

      <div>
        <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
          <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                    Images
                  </th>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                    Titre
                  </th>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                    Status
                  </th>

                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                    À compléter
                  </th>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                    Mes disponibilités
                  </th>

                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white"></th>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                    Action
                  </th>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white"></th>
                </tr>
              </thead>
              <tbody>
                {paginatedLocations.map((location) => (
                  <tr key={location.id}>
                    <td className="px-5 py-5 text-sm dark:bg-gray-800">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10">
                          <img
                            className="w-full h-full rounded-full"
                            src={
                              location?.images && location?.images.length > 0
                                ? `${process.env.REACT_APP_CLOUD_FRONT}${location?.images[0]}`
                                : homeIcon
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </td>
                    <td className="pr-4 text-sm dark:bg-gray-800">
                      <p className="text-gray-900 whitespace-no-wrap dark:text-white">{location?.title}</p>
                    </td>
                    <td className="pl-6 text-sm dark:bg-gray-800">
                      <p className="text-gray-900 whitespace-no-wrap dark:text-white">
                        {location?.validation === "brouillon" && "brouillon"}
                        {location?.validation === "approuvé" && "publié"}
                        {location?.validation === "bloqué" && "bloqué"}
                        {location?.validation === "en attente" && "en attente"}
                        {location?.validation === "en cours" && "en cours de traitement"}
                      </p>
                    </td>

                    <td className="px-5 py-5 text-sm dark:bg-gray-800">
                      <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                        <span
                          aria-hidden
                          className={`absolute inset-0 ml-4 bg-green-200 rounded-full opacity-50 ${
                            location?.validation === "approuvé" ||
                            location?.validation === "en cours" ||
                            owner_status === "en cours" ||
                            owner_status === "approuvé" ||
                            location?.validation === "brouillon"
                              ? "bg-red-900"
                              : ""
                          }`}
                        ></span>

                        <span className="relative ml-4">
                          {location?.validation === "approuvé" ||
                          location?.validation === "en cours" ||
                          owner_status === "en cours" ||
                          owner_status === "approuvé" ||
                          location?.validation === "brouillon" ? (
                            <button className="text-xl las la-check-circle" disabled></button>
                          ) : (
                            <>
                              {(location?.validation === "bloqué" ||
                                location?.validation === "en attente" ||
                                owner_status === "en cours") &&
                                owner_status !== "approuvé" && (
                                  <Link to={`/client/location/completed/${location.id}`}>
                                    <button className="text-xl las la-pen"></button>
                                  </Link>
                                )}
                            </>
                          )}
                        </span>
                      </span>
                    </td>

                    {/* Avaibility */}

                    <td className="px-5 py-5 text-sm dark:bg-gray-800">
                      <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                        <span
                          aria-hidden
                          className={`absolute inset-0 ml-4 bg-green-200 rounded-full opacity-50 ${
                            location?.validation != "approuvé" ? "bg-red-900" : ""
                          }`}
                        ></span>

                        <span className="relative ml-4">
                          {location?.validation != "approuvé" ? (
                            <button className="text-xl las la-calendar-day" disabled></button>
                          ) : (
                            <>
                              <button
                                className="text-xl las la-calendar-day"
                                onClick={() => handleEyeIconClick(location)}
                              ></button>
                            </>
                          )}
                        </span>
                      </span>
                    </td>

                    {/* GetbyID */}
                    <td className="px-5 py-5 text-sm dark:bg-gray-800">
                      <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                        <span aria-hidden className="absolute inset-0 bg-green-200 rounded-full opacity-50"></span>
                        <span className="relative">
                          <Link to={`/location/${location.id}`}>
                            {" "}
                            <i className="text-xl las la-eye"></i>
                          </Link>
                        </span>
                      </span>
                    </td>

                    {/* Update */}

                    <td className="px-5 py-5 text-sm dark:bg-gray-800">
                      <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                        <span aria-hidden className="absolute inset-0 bg-green-200 rounded-full opacity-50"></span>
                        <span className="relative">
                          <Link to={`/update-location/${location.id}`}>
                            {" "}
                            <i className="text-xl las la-edit"></i>
                          </Link>
                        </span>
                      </span>
                    </td>
                    {/* Delete */}
                    <td className="px-5 py-5 text-sm dark:bg-gray-800">
                      <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tigh">
                        <span aria-hidden className="absolute inset-0 bg-red-200 rounded-full opacity-50"></span>
                        <span className="relative">
                          <i className="text-xl cursor-not-allowed las la-trash-alt"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {showBlockedDetails && (
          <BlockedDetailsPage
            open={true}
            handleClose={() => setshowBlockedDetails(false)}
            locationData={selectedLocation}
          />
        )}
        <div className="flex items-center justify-center mt-64">
          {typeof totalPages === "number" && (
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          )}
        </div>
      </div>
      {paginatedLocations.length === 0 && (
        <div className="flex items-center justify-center mt-16">
          <p>Pas d'espaces , ajouter votre espace</p>
        </div>
      )}
    </div>
  );
};

export default SectionGridFilterCardOwner;
