import SocialMedia from "elements/social/SocialMedia";
import Social from "elements/social/SocialMedia";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center mt-28 mt-lg-28 inner">
              <h1 className="text-3xl font-bold text-center text-white md:text-5xl lg:text-7xl">
                Tout savoir sur <br />
                Event To Bee.
              </h1>
              <div className="mt-5 read-more-btn md:mt-10 lg:mt-8">
                <Link className="rbt-btn btn-gradient hover-icon-reverse" to={"/about"}>
                  <span className="icon-reverse-wrapper">
                    <span className="btn-text">À propos</span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                  </span>
                </Link>
                <SocialMedia classOne="rbt-social-area rbt-section-gap" classList="social-icon social-default" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
