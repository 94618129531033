import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPlayground } from "./featureAPI";

const initialState = {
  designation: "",
  loading: "idle",
  Playgrounds: [],
  Playground: { designation: "" },
};

export const addPlayground = createAsyncThunk("featurePlayground/addPlayground", async (data: any) => {
  return await createPlayground.add(data);
});

export const fetchPlaygrounds = createAsyncThunk("featurePlayground/fetchPlayground", async () => {
  return await createPlayground.verify();
});

export const deletePlayground = createAsyncThunk("featurePlayground/deletePlayground", async (id: number) => {
  return await createPlayground.delete(id);
});

export const updatePlayground = createAsyncThunk(
  "featurePlayground/updatePlayground",
  async ({ data, id }: { data: any; id: any }) => {
    return await createPlayground.update(data, id);
  }
);

export const getPlaygroundById = createAsyncThunk("featurePlayground/getPlaygroundById", async (id: any) => {
  return await createPlayground.getById(id);
});
const createPlaygroundSlice = createSlice({
  name: "featurePlayground",
  initialState,
  reducers: {
    setDesignation(state, action) {
      state.designation = action.payload;
    },
    resetDesignation(state) {
      state.designation = "";
    },
    resetValues(state) {
      state.designation = "";
      state.loading = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addPlayground.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addPlayground.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(addPlayground.rejected, (state) => {
        state.loading = "failed";
      });
    builder.addCase(fetchPlaygrounds.fulfilled, (state, action) => {
      state.Playgrounds = action.payload;
    });
    builder.addCase(getPlaygroundById.fulfilled, (state, action) => {
      state.Playground = action.payload;
    });
    builder
      .addCase(deletePlayground.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deletePlayground.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(deletePlayground.rejected, (state) => {
        state.loading = "failed";
      });
    builder
      .addCase(updatePlayground.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updatePlayground.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(updatePlayground.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const { setDesignation, resetDesignation, resetValues } = createPlaygroundSlice.actions;
export default createPlaygroundSlice.reducer;
