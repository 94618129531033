import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import { useLocation, useNavigate } from "react-router-dom";
interface CommentListingDataType {
  name: string;
  avatar?: string;
  date: string;
  comment: string;
  starPoint: number;
  text: string;
  client: any;
  locationId: number;
  like: number;
}

export interface CommentListingProps {
  className?: string;
  data?: CommentListingDataType;
  hasListingTitle?: boolean;
}
const server = process.env.REACT_APP_SERVER_URL;
const DEMO_DATA: any = {
  name: "Cody Fisher",
  date: "May 20, 2021",
  comment: "",
  starPoint: 5,
  text: "",
  client: undefined,
  locationId: 0,
  like: 0,
};

const CommentListingService: FC<CommentListingProps> = ({ className = "", data = DEMO_DATA, hasListingTitle }) => {
  const imagePng = "static-files/user.png";

  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const handleOpenModalImageGallery = () => {
    router(`comments/$/?modal=PHOTO_TOUR_SCROLLABLE`);
  };
  const [showImages, setShowImages] = useState(false);
  const [showAllText, setShowAllText] = useState(false);
  const handleVoirPlusClick = () => {
    setShowImages(!showImages);
    setShowAllText(!showAllText);
  };
  const shouldShowVoirPlus = data.text.length > 50 || (data.images && data.images.length > 0);
  const truncatedText = showAllText ? data.text : `${data.text.slice(0, 50)}...`;
  // Function to generate star icons based on the 'like' property
  const renderStars = (like: number) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(<StarIcon key={i} className={`w-4 h-4 ${i <= like ? "text-yellow-500" : "text-gray-300"}`} />);
    }
    return stars;
  };

  return (
    <div className={`flex space-x-4 nc-CommentListing ${className}`} data-nc-id="CommentListing">
      <div className="pt-0.5">
        {data.Client?.client_url ? (
          <Avatar
            sizeClass="h-10 w-10 text-lg"
            radius="rounded-full"
            userName={data.Client?.client_url}
            imgUrl={data.Client?.client_url ? process.env.REACT_APP_CLOUD_FRONT + data.Client?.client_url : ""}
          />
        ) : (
          <img
            className="w-10 h-10 text-lg rounded-full"
            src={process.env.REACT_APP_CLOUD_FRONT + imagePng}
            alt="Default User"
          />
        )}
      </div>
      <div className="flex-grow">
        <div className="flex justify-between space-x-3">
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              <span>{data?.firstName}</span>
            </div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
              {new Date(data?.createdAt).toLocaleDateString("fr-FR", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </span>
          </div>
          <div className="flex text-yellow-500">{renderStars(data.like)}</div>
        </div>
        <span
          className="block mt-3 text-neutral-6000 dark:text-neutral-300"
          style={{ whiteSpace: showAllText ? "normal" : "nowrap", overflow: "hidden" }}
        >
          {truncatedText}
        </span>
        {shouldShowVoirPlus && !showAllText && (
          <span
            className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5 cursor-pointer font-bold"
            style={{ display: "inline-block", textAlign: "center" }}
            onClick={handleVoirPlusClick}
          >
            Voir plus
          </span>
        )}

        {shouldShowVoirPlus && showAllText && (
          <span
            className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5 cursor-pointer font-bold"
            style={{ display: "inline-block", textAlign: "center" }}
            onClick={handleVoirPlusClick}
          >
            Voir moins
          </span>
        )}
        {showImages && data?.images.length > 0 && (
          <div className="mt-3">
            <p className="text-sm text-neutral-500">images:</p>
            <div className="flex flex-wrap gap-2 mt-2">
              {data?.images.map((image: any, index: any) => (
                <div key={index} className="relative">
                  <img
                    src={image ? process.env.REACT_APP_CLOUD_FRONT + image : ""}
                    alt={`Uploaded ${index}`}
                    className="h-auto max-h-32 max-w-32"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentListingService;
