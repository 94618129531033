import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

export interface FAQ {
  children?: React.ReactNode;
}

const Faq: FC<FAQ> = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Event To Bee - FAQ</title>
        <meta
          name="description"
          content="Trouvez toutes les réponses à vos questions sur Event To Bee. Découvrez les informations sur la location d'espaces pour vos événements spéciaux."
        />
      </Helmet>
      <div className="flex items-center justify-center flex-col bg-[#E5E5E5]  pb-6 ">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[100%] font-semibold text-neutral-900 dark:text-black justify-center display-4">
          Forum aux questions
        </h2>

        <div className="pl-4 pr-4 rounded-xl md:w-full">
          <div className="flex flex-col items-center justify-center text-center">
            <div className="pl-4 pr-4 md:w-4/5 md:mx-1/6">
              <div className="bg-neutral-50 dark:bg-neutral-900">
                <div className="pt-1 bg-white border-b border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800">
                  <div className="container">
                    <div className="flex justify-center space-x-8 overflow-x-auto text-center md:space-x-14 hiddenScrollbar">
                      <NavLink
                        to="/proprietaire/faq"
                        className={({ isActive }) =>
                          `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                            !isActive ? "border-transparent" : "border-primary-500"
                          }`
                        }
                      >
                        Hôte
                      </NavLink>
                      <NavLink
                        to="/prestataire/faq"
                        className={({ isActive }) =>
                          `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                            !isActive ? "border-transparent" : "border-primary-500"
                          }`
                        }
                      >
                        Prestataire
                      </NavLink>
                      <NavLink
                        to="/client/faq"
                        className={({ isActive }) =>
                          `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                            !isActive ? "border-transparent" : "border-primary-500"
                          }`
                        }
                      >
                        Client
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="container pt-10 pb-24 sm:pt-10 lg:pb-32">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
