import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import AlertModalConfirm from "components/AlertModal/AlertModalConfirm";
import { AuthContext } from "context/appContext";
import { ProfilClient, Reservation } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import {
  fetchOwnerTransferts,
  payReservationOwner,
  transfertAmountOwner,
} from "redux/features/reservations/reservationSlice";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";
import CommonLayout from "../AccountPage/CommonLayout";
import ReservationDetailsPage from "./reservationDetailsPage";
import StripeMenu from "./StripeMenu";

export interface ClientTransfertsPageProps {
  className?: string;
}
interface ClientTransfertsPageLoadingState {
  [key: string]: boolean;
}
const ClientTransfertsPage: FC<ClientTransfertsPageProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  let profile = authContext.user?.profil as ProfilClient;
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const reservations: Reservation[] = useAppSelector((state) => state.featureReservation.inProgress);
  const totalPages: Reservation[] = useAppSelector((state) => state.featureReservation.totalPages);
  const count: number = useAppSelector((state) => state.featureReservation.count);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState<ClientTransfertsPageLoadingState>({});
  const [showReservationDetails, setshowReservationDetails] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (profile) {
      dispatch(fetchOwnerTransferts({ pageNumber: page, owner_id: profile?.id }));
    }
  }, [page, dispatch]);

  const giveOwnerPayment = async (reservation: any) => {
    setModalOpen(false);

    setIsLoading((prevState) => ({
      ...prevState,
      [reservation.id]: true,
    }));
    try {
      const res = await dispatch(payReservationOwner(reservation.reference));
      toast.success(res.payload.msg);
      setIsLoading((prevState) => ({
        ...prevState,
        [reservation.id]: false,
      }));

      dispatch(fetchOwnerTransferts({ pageNumber: page, owner_id: profile?.id }));
      setIsLoading((prevState) => ({
        ...prevState,
        [reservation.id]: false,
      }));
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };

  const [selectedReservation, setselectedReservation] = useState<any | null>(null);

  const handleEyeIconClick = (client: any) => {
    setselectedReservation(client);
    setshowReservationDetails(true);
  };

  const isReservationOngoing = (selectedDate: any, endHour: any) => {
    const currentDate = new Date();
    const selectedDateTime = new Date(selectedDate);

    const [endHourInt, endMinuteInt] = endHour.split(":").map((part: any) => parseInt(part));

    selectedDateTime.setHours(endHourInt, endMinuteInt, 0, 0);
    selectedDateTime.setDate(selectedDateTime.getDate() + 1);
    const currentUtcTime = currentDate.getTime() - currentDate.getTimezoneOffset() * 60000;
    const selectedUtcTime = selectedDateTime.getTime() - selectedDateTime.getTimezoneOffset() * 60000;

    return currentUtcTime <= selectedUtcTime;
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const [amount, setAmount] = useState("");
  const transferPayment = async () => {
    if (!amount) return;

    try {
      if (profile) {
        const res = await dispatch(
          transfertAmountOwner({ amount: Number(amount), owner_id: profile.account_id || "" })
        );
        toast.success(res.payload.msg);

        dispatch(fetchOwnerTransferts({ pageNumber: page, owner_id: profile?.id }));
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  const dateAvailable = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
  return (
    <div className={`nc-AccountPage bg-neutral-50 ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <CommonLayout>
        <StripeMenu>
          {/* <div className="text-center">
            <h2>Liste des transfers en cours</h2>
          </div> */}
          <div className="flex items-center justify-between mb-4">
            <div></div>
            <div className="relative flex">
              <Input
                type="text"
                placeholder="Rechercher par code"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-500 transform -translate-y-1/2 right-3 top-1/2" />
            </div>
          </div>
          <div className="flex flex-col">
            {/* 
            <div className="flex items-center justify-between mb-4">
              <div className="flex">
                <Input
                  type="text"
                  placeholder="Rechercher par code"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <MagnifyingGlassCircleIcon className="w-10 h-90 " onClick={transferPayment} />
              </div>
            </div> */}

            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-sm font-light text-center">
                    <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                      <tr>
                        <th scope="col" className="px-6 py-4 ">
                          Code
                        </th>

                        <th scope="col" className="px-6 py-4 ">
                          A Payer
                        </th>
                        <th scope="col" className="px-6 py-4 ">
                          Status
                        </th>
                        <th scope="col" className="px-6 py-4 ">
                          Paiement
                        </th>
                        <th scope="col" className="px-6 py-4 ">
                          consulter
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservations &&
                        reservations

                          .filter((item) => item.reference.toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((item: any, index) => (
                            <tr className="border-b dark:border-neutral-500" key={index}>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">{item.reference}</td>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">
                                {(
                                  parseFloat(item.halfTotal) +
                                  (parseFloat(item.cleaning_costs) || 0) +
                                  (parseFloat(item.extra) ?? 0)
                                ).toFixed(2)}{" "}
                                €
                              </td>
                              {isReservationOngoing(item?.selectedDate, item?.endHour) ? (
                                <td className="font-semibold text-secondary-6000">En cours</td>
                              ) : (
                                <td className="px-6 py-4 font-medium whitespace-nowrap">Terminer</td>
                              )}
                              <td className="px-6 py-4 whitespace-nowrap">
                                {item?.payOwner === true && item?.transaction_id != null ? (
                                  <span className="font-semibold text-secondary-6000">Virement réussi</span>
                                ) : (
                                  <>
                                    {isReservationOngoing(item?.selectedDate, item?.endHour) ? (
                                      <span>En attente</span>
                                    ) : (
                                      <>
                                        {item.payOwner !== true ? (
                                          <ButtonSecondary
                                            loading={isLoading[item.id]}
                                            onClick={() => {
                                              setselectedReservation(item);
                                              setModalOpen(true);
                                            }}
                                          >
                                            {" "}
                                            <span className="relative flex w-3 h-3">
                                              <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400"></span>
                                              <span className="relative inline-flex w-3 h-3 rounded-full bg-sky-500"></span>
                                            </span>
                                            <span className="ml-3">Transférer</span>
                                          </ButtonSecondary>
                                        ) : (
                                          <span className="font-semibold text-secondary-6000"> Payé</span>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </td>

                              <td className="px-6 py-4 whitespace-nowrap">
                                <i
                                  className="text-2xl cursor-pointer las la-eye"
                                  onClick={() => handleEyeIconClick(item)}
                                ></i>{" "}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>

                  <div className="flex justify-end ">
                    <span className="text-sm text-gray-700">{count} élément</span>
                  </div>
                  {showReservationDetails && (
                    <ReservationDetailsPage
                      open={true}
                      handleClose={() => setshowReservationDetails(false)}
                      Data={selectedReservation}
                    />
                  )}

                  <AlertModalConfirm
                    open={modalOpen}
                    handleConfirm={() => giveOwnerPayment(selectedReservation)}
                    fullName={profile?.companyName || profile?.name + " " + profile?.lastName}
                    rib={profile?.rib}
                    handleClose={() => setModalOpen(false)}
                    message={`Vous êtes sur le point de transférer votre solde de vers votre compte bancaire`}
                    alertText="Confirmation de transfert"
                    //alertMsg={`Ce montant va être disponible dans votre compte bancaire  après 7 jours, soit le ${formatDate(dateAvailable)}.`}

                    alertMsg={
                      <div>
                        Ce montant va être disponible dans votre compte bancaire après 7 jours, soit le{" "}
                        <span className="font-bold">{formatDate(dateAvailable)}</span>.
                      </div>
                    }
                  />

                  <div className="flex items-center justify-center mt-11">
                    {typeof totalPages === "number" && (
                      <Pagination currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StripeMenu>
      </CommonLayout>
    </div>
  );
};

export default ClientTransfertsPage;
