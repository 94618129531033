import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Slider from "rc-slider";
import convertNumbThousand from "utils/convertNumbThousand";
import { Radio } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "redux/store";
import { fetchCategorys } from "redux/features/Admin/categorys/categorySlice";

export interface categoryInputData {
  map(arg0: (item: any) => import("react/jsx-runtime").JSX.Element): React.ReactNode;

  id: number;
  designation: string;
}

interface TabFiltersProps {
  onApply: (categories: string) => void;
  onApplyVisitor: (visitor: string) => void;
  onApplyPrice: (price: string) => void;
}

interface Category {
  id: number;
  designation: string;
}
const TabFilters: React.FC<TabFiltersProps> = ({ onApply, onApplyVisitor, onApplyPrice }) => {
  const [rangePrices, setRangePrices] = useState([0, 10000]);

  const [selectedCategories, setSelectedCategories] = useState<string>("");

  const server = process.env.REACT_APP_SERVER_URL;
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const fetchedCategories: Category[] = useSelector((state: RootState) => state.featureCategory.categories);

  const clearSelectedCategories = () => {
    setSelectedCategories("");
    onApply("");
  };

  useEffect(() => {
    dispatch(fetchCategorys());
  }, [dispatch]);

  const handleCategoryClick = (category: any) => {
    setSelectedCategories(category);
  };
  const [selectedVisitors, setSelectedVisitors] = useState<string>("");

  const handleVisitorClick = (visitor: any) => {
    setSelectedVisitors(visitor);
  };

  const clearSelectedVisitors = () => {
    setSelectedVisitors("");
    onApplyVisitor("");
  };

  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(2000);

  const handlePriceClick = (values: [number, number]) => {
    const [newMinPrice, newMaxPrice] = values;
    setMinPrice(newMinPrice);
    setMaxPrice(newMaxPrice);
  };

  const handleMinInputChange = (e: any) => {
    const value = e.target.value;
    setMinPrice(value);
  };

  const handleMaxInputChange = (e: any) => {
    const value = e.target.value;
    setMaxPrice(value);
  };

  const clearSelectedPrice = () => {
    setMinPrice(0);
    setMaxPrice(2000);
    onApplyPrice("0 - 2000");
    onApplyPrice("");
  };

  const renderTabsTypeOfPlace = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center hover:border-primary-500 px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700  dark:hover:border-neutral-500 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Catégorie</span>
              <i className="ml-2 las la-angle-down"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 transform -translate-x-1/2 w-screen max-w-sm px-4 mt-3 sm:px-0 -ml-3">
                <div className="overflow-hidden bg-white border shadow-xl rounded-2xl dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5 overflow-y-auto max-h-60">
                    {fetchedCategories &&
                      fetchedCategories.map((item: any) => (
                        <div key={item.designation} className="">
                          <Radio
                            name={item.designation}
                            label={item.designation}
                            onChange={() => handleCategoryClick(item.id)}
                            checked={item.id === selectedCategories}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="flex items-center justify-between p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800">
                    <ButtonThird
                      onClick={() => {
                        clearSelectedCategories();
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Annuler
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        onApply(selectedCategories);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Appliquer
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const typeOfVisitors = [
    {
      name: "0 - 5 personnes",
      value: "5",
      checked: true,
    },
    {
      name: "5 - 10 ",

      value: "10",
      checked: true,
    },
    {
      name: "10 - 20 ",
      value: "19",
      checked: true,
    },
    {
      name: "20 < ",
      value: "20",
    },
  ];

  const renderTabsTypeOfVisitor = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 hover:border-primary-500 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 dark:hover:border-neutral-500 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Capacité d'accueil</span>
              <i className="ml-2 las la-angle-down"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 transform -translate-x-1/2 w-screen max-w-sm px-4 mt-3 sm:px-0 ">
                <div className="overflow-hidden bg-white border shadow-xl rounded-2xl dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfVisitors.map((item) => (
                      <div key={item.value} className="">
                        <Radio
                          name={item.name}
                          label={item.name}
                          onChange={() => handleVisitorClick(item.value)}
                          checked={item.value === selectedVisitors}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center justify-between p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800">
                    <ButtonThird
                      onClick={() => {
                        clearSelectedVisitors();
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Annuler
                    </ButtonThird>
                    <ButtonPrimary
                      sizeClass="px-4 py-2 sm:px-5"
                      onClick={() => {
                        onApplyVisitor(selectedVisitors);
                        close();
                      }}
                    >
                      Appliquer
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border hover:border-primary-500  focus:outline-none `}
            >
              <span>{`€ ${convertNumbThousand(rangePrices[0])} - € ${convertNumbThousand(rangePrices[1])}`} </span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 transform -translate-x-1/2 w-screen max-w-sm px-4 mt-3 sm:px-0 -ml-3">
                <div className="overflow-hidden bg-white border shadow-xl rounded-2xl dark:bg-neutral-900 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Prix par heure</span>
                      <Slider
                        range
                        min={0}
                        max={2000}
                        defaultValue={[minPrice, maxPrice]}
                        allowCross={false}
                        onChange={(values) => {
                          if (Array.isArray(values)) {
                            setMinPrice(values[0]);
                            setMaxPrice(values[1]);
                          }
                        }}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Prix minimum
                        </label>
                        <div className="relative mt-1 rounded-md">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">€</span>
                          </div>
                          <input
                            type="text"
                            name="minPrice"
                            id="minPrice"
                            className="block w-full pr-3 focus:ring-indigo-500 focus:border-indigo-500 pl-7 sm:text-sm"
                            value={minPrice}
                            onChange={handleMinInputChange}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Prix maximum
                        </label>
                        <div className="relative mt-1 rounded-md">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">€</span>
                          </div>
                          <input
                            type="text"
                            name="maxPrice"
                            id="maxPrice"
                            className="pr-3 pl-7 "
                            value={maxPrice}
                            onChange={handleMaxInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800">
                    <ButtonThird
                      onClick={() => {
                        clearSelectedPrice();
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Annuler
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        onApplyPrice(`${minPrice} - ${maxPrice}`);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Appliquer
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  return (
    <div className="flex flex-col lg:flex-row lg:space-x-4">
      <div className="flex flex-col justify-center space-y-4 lg:space-x-4 lg:flex-row lg:items-center">
        <div className="ml-6" style={{ marginTop: "14px" }}>
          {renderTabsTypeOfPlace()}
        </div>
        {renderTabsTypeOfVisitor()}
        <div className="ml-6"> {renderTabsPriceRage()}</div>
      </div>
    </div>
  );
};

export default TabFilters;
