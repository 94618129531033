import { Tab } from "@headlessui/react";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import { Helmet } from "react-helmet";
import Pagination from "shared/Pagination/Pagination";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { fetchOwnerLocations } from "redux/features/locations/locationSlice";
import { fetchOwnerById, fetchOwnerRating } from "redux/features/owners/ownersSlice";
import { getOwnerById } from "redux/features/auth/authSlice";
import { fetchLocationsPrices } from "redux/features/location/locationSlice";

export interface OwnerDetailPageProps {
  className?: string;
}

const OwnerDetailPage: FC<OwnerDetailPageProps> = ({ className = "" }) => {
  const { ownerId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const dispatch = useAppDispatch();
  let locationState = useAppSelector((state) => state.featureLocation.locationDetail.ownerLocations);
  let ownerState = useAppSelector((state) => state.auth.owner);
  useEffect(() => {
    const fetchData = async () => {
      if (ownerId) {
        await dispatch(fetchOwnerLocations(ownerId));
        await dispatch(getOwnerById(ownerId));
        await dispatch(fetchOwnerRating(ownerId));
      }
    };

    fetchData();
  }, [ownerId]);

  const paginatedStays = locationState?.locationsWithRoundedAverage?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    dispatch(fetchLocationsPrices());
  }, []);

  let locationMinPrices = useAppSelector((state) => state.location?.AllLocationMinPrices);

  const totalPages = Math.ceil(locationState?.locationsWithRoundedAverage?.length / itemsPerPage);

  const renderSidebar = () => {
    return (
      <div className="flex flex-col items-center w-full px-0 space-y-6 text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 sm:space-y-7 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={ownerState?.client_url ? process.env.REACT_APP_CLOUD_FRONT + ownerState?.client_url : ""}
        />
        {/* ---- */}
        <div className="flex flex-col items-center space-y-3 text-center">
          <h2 className="text-3xl font-semibold">
            {" "}
            <span>{ownerState?.name}</span>
          </h2>
          <StartRating point={ownerState?.overallAverageLikes} />
        </div>

        {/* ---- */}
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">{ownerState?.totalLocations} Espaces</span>
          </div>

          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Rejoindre le {ownerState?.createdAt && new Date(ownerState?.createdAt).toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Les espaces</h2>
        {/* <div>
          <h2 className="text-2xl font-semibold">Les espaces</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Bienvenue ...
          </span>
        </div> */}
        <div>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="grid grid-cols-1 gap-6 mt-8 md:gap-7 sm:grid-cols-2">
                  {paginatedStays.map((stay: any, index) => {
                    let minPrice;
                    let priceCount;
                    if (Array.isArray(locationMinPrices) && locationMinPrices.length > 0) {
                      const priceData = locationMinPrices.find((price) => price.locationId === stay.id);
                      if (priceData) {
                        minPrice = priceData.minPrice;
                        priceCount = priceData.priceCount;
                      }
                    }

                    return (
                      <div key={index}>
                        <StayCard
                          data={stay}
                          galleryImgs={stay.images}
                          minPrice={minPrice}
                          priceCount={priceCount}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="flex items-center justify-center mt-11">
                  {typeof totalPages === "number" && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Event To Bee - Les Espaces</title>
        <meta
          name="description"
          content="Trouvez tous les meilleurs espaces à louer pour votre évènement sur Event To Bee"
        />
      </Helmet>
      <main className="container flex flex-col mt-12 mb-24 lg:mb-32 lg:flex-row">
        <div className="flex-grow block mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="flex-shrink-0 w-full space-y-8 lg:w-3/5 xl:w-2/3 lg:space-y-10 lg:pl-10">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default OwnerDetailPage;
