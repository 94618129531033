import { zodResolver } from "@hookform/resolvers/zod";
import { LocationFormProps } from "data/reduxInterfaces";
import { useAppSelector } from "hooks/hooks";
import { FC, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import OnboardingTour from "utils/tours/OnboardingTour";
import { z } from "zod";
import HIW1img from "images/1.png";
import HIW2img from "images/3.png";

const formSchema = z.object({
  category: z.string({ required_error: "Le type  est obligatoire." }),
});

const LocationUserType: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  handleForm,
  FormData,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });
  const [ownerType, setOwnerType] = useState<string>("");

  const steps = [
    {
      selector: ".profile-owner-form ",
      content: "Choisissez votre type de profil . ",
    },
  ];

  const handleTypeClick = (type: string) => {
    setOwnerType(type);
    setValue("category", type);
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    const { ...result } = data;

    FormData(result);
  };

  useEffect(() => {
    if (handleForm.type === "chekLocationFormThirteenOwner") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const updatelocationStates = useAppSelector((state) => state.location.locationData);

  useEffect(() => {
    if (updatelocationStates) {
      setValue("category", updatelocationStates.category);
    }
  }, [updatelocationStates]);

  return (
    <div className={className}>
      <h2 className=" mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Vous êtes ?
      </h2>

      <div className="-ml-8 lg:flex md:items-center sm:items-center profile-owner-form sm:flex md:flex">
        <div className="container_conent">
          <div className="card">
            <button
              className=""
              onClick={() => {
                handleTypeClick("particular");
              }}
            >
              <div
                className={`card bg-gray-900 card_side card_side-front ${
                  ownerType === "particular" ? "border-2 border-indigo-800" : ""
                }`}
              >
                <div className="text-center bg-gray-900 card_picture card_picture-1">
                  <img src={HIW1img} alt="" />
                </div>
                <div className="flex justify-center mt-3">
                  <p className="card_back-title">PARTICULIER</p>
                </div>
              </div>
            </button>
          </div>
        </div>

        <div className="ml-2 container_conent">
          <div className="card">
            <button
              className=""
              onClick={() => {
                handleTypeClick("company");
              }}
            >
              <div
                className={`card bg-gray-900 card_side card_side-front ${
                  ownerType === "company" ? "border-2 border-indigo-800" : ""
                }`}
              >
                <div className="text-center bg-gray-900 card_picture card_picture-1">
                  <img src={HIW2img} alt="" />
                </div>
                <div className="flex justify-center mt-3">
                  <p className="card_back-title">ENTREPRISE</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <form action="" onSubmit={handleSubmit(onSubmit)}>
        {errors.category?.message && <div className="text-xs text-red-500">{errors.category?.message}</div>}
      </form>
      <OnboardingTour steps={steps} page_name={"ProfileOwnerForm"}></OnboardingTour>
    </div>
  );
};

export default LocationUserType;
