import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Service, TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";
import { useAppSelector } from "hooks/hooks";

export interface CardCategory5Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategory5: FC<CardCategory5Props> = ({ className = "", taxonomy }) => {
  const { count, name, href = "/", thumbnail, listingType } = taxonomy;

  return (
    <Link to={href} className={`nc-CardCategory5 flex flex-col ${className}`} data-nc-id="CardCategory5">
      <div className={`flex-shrink-0 relative w-full aspect-w-4 aspect-h-3 h-0 rounded-2xl overflow-hidden group`}>
        <NcImage
          src={thumbnail ? process.env.REACT_APP_CLOUD_FRONT + thumbnail : ""}
          className="object-cover w-full h-full rounded-2xl"
        />
        <span className="absolute inset-0 transition-opacity bg-black opacity-0 group-hover:opacity-100 bg-opacity-10"></span>
      </div>
      <div className="px-3 mt-4 truncate">
        <h2 className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}>
          {name}
        </h2>
        <span className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}>
          {convertNumbThousand(count)} properties
        </span>
      </div>
    </Link>
  );
};

export default CardCategory5;
