import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import React, { FC, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
setDefaultLocale("fr");
export interface StayDatesRangeInputProps {
  className?: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({ className = "" }) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate, setEndDate] = useState<Date | null>(new Date("2023/02/23"));

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">{` When's your trip?`}</span>
      </div>
      <div className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}>
        <DatePicker
          locale={"fr"}
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => <DatePickerCustomDay dayOfMonth={day} date={date} />}
        />
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
