import { AuthContext } from "context/appContext";
import { ProfilClient } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BiSolidBank } from "react-icons/bi";
import { FaCalendarCheck } from "react-icons/fa";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { HiHome } from "react-icons/hi2";
import { IoCalendarSharp } from "react-icons/io5";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import { getUserStats } from "redux/features/Admin/Stripe/stripeSlice";
import CommonLayout from "./AccountPage/CommonLayout";

import { Card, CardBody, Typography } from "@material-tailwind/react";
import { IoHeart } from "react-icons/io5";
import { Link } from "react-router-dom";

export interface ClientDashboardProps {
  className?: string;
}

const ClientDashboard: FC<ClientDashboardProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);

  const dispatch = useAppDispatch();
  let profile = authContext.user?.profil as ProfilClient;

  useEffect(() => {
    dispatch(
      getUserStats({
        userId: profile?.id,
        account_id: profile?.account_id || "",
        status: profile?.validation || "",
      })
    );
  }, []);
  const stats = useAppSelector((state) => state?.stripe?.ownerStats);
  const ownerStats = [
    // {
    //   title: "Stripe solde",
    //   value: stats?.balance + " €",
    //   color: "cyan",
    //   icon: <RiMoneyEuroCircleFill className="h-9 w-9" />,
    //   href: "#",
    // },
    {
      title: "Demandes  ",
      value: stats?.pendingReservationsCount,
      color: "cyan",
      icon: <IoCalendarSharp className="w-8 h-8" />,
      href: "/client/list-pending-reservations",
    },
    {
      title: "Transferts",
      value: stats?.transfertsCount,
      color: "cyan",
      icon: <BiSolidBank className="w-8 h-8" />,
      href: "/client/transfers",
    },
    {
      title: "Transactions",
      value: stats?.transactionsCount,
      color: "cyan",
      icon: <HiOutlineSwitchHorizontal className="w-8 h-8" />,
      href: "/client/transactions",
    },
    {
      title: "Mes Espaces",
      value: stats?.locationsCount,
      color: "cyan",
      icon: <HiHome className="w-8 h-8" />,
      href: "/client/locations",
    },
  ];
  const statisticCards = [
    {
      title: "Réservations",
      value: stats?.reservationsCount,
      color: "cyan",
      icon: <FaCalendarCheck className="w-8 h-8" />,
      href: profile?.validation === "approuvé" ? "/client/my-reservations" : "/client-reservations",
    },

    {
      title: "Espaces favoris",
      value: stats?.favoriLocationsCount,
      color: "cyan",
      icon: <IoHeart className={`w-8 h-8 ${stats && stats?.favoriLocationsCount > 0 ? "text-red-500" : ""}`} />,
      href: "/client-savelists",
    },
    {
      title: "Prestations favoris",
      value: stats?.favoriServicesCount,
      color: "cyan",
      icon: <IoHeart className={`w-8 h-8 ${stats && stats?.favoriServicesCount > 0 ? "text-red-500" : ""}`} />,
      href: "/client-serviceslist",
    },
  ];

  const combinedStats = profile?.validation === "approuvé" ? [...ownerStats, ...statisticCards] : statisticCards;

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>

      <CommonLayout>
        {/* <div className="font-bold text-center">Tableau de bord</div> */}

        <div className="space-y-6 sm:space-y-8">
          <div className="container max-w-6xl px-5 mx-auto my-28">
            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
              {combinedStats.map((card) => (
                <Link to={card.href}>
                  <Card className="shadow-xl   border border-gray-200 transition duration-1000 ease-in-out !rounded-lg flex flex-col group bg-gradient-to-r  from-indigo-500  to-blue-400 hover:bg-gradient-to-r hover:from-eventToBee hover:bg-eventToBee">
                    <CardBody className="flex flex-col justify-between flex-1 p-4 !rounded-lg  ">
                      <div className="flex items-center justify-between">
                        <Typography className="!font-semibold text-base  text-white  ">{card.title}</Typography>
                        <div className="flex items-center gap-1 text-white">{card.icon}</div>
                      </div>
                      <div className="mt-auto">
                        <Typography color="blue-gray" className="font-semibold text-white">
                          {card.value}
                        </Typography>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default ClientDashboard;
