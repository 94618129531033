import { TrashIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "context/appContext";
import { ServiceFormProps } from "data/reduxInterfaces";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, Fragment, HTMLAttributes, useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaFileDownload } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getService, setServiceData, setServiceOldImages } from "redux/features/service/serviceSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { array, optional, z } from "zod";

const MAX_FILE_SIZE = 1024 * 1024 * 50;
const ACCEPTED_TARIF_MIME_TYPES = ["application/pdf"];
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];

const formSchema = z.object({
  serviceAgentId: z.number(),
  tarif: z
    .instanceof(File, {
      message: "Document est obligatoire.",
    })
    .optional()
    .refine((file: File | undefined) => {
      return !file || (file.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file.type));
    }, "Le fichier doit être un fichier pdf ou une image au format JPEG, PNG ou GIF et ne pas dépasser 50MB."),
  newImages: optional(z.array(z.instanceof(File))).refine((files) => {
    return (
      !files ||
      files.every(
        (file) => !file || (file?.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type))
      )
    );
  }, "Le fichier doit être une image au format JPEG, PNG ou GIF et ne pas dépasser 10MB."),
  remove: z.boolean().optional(),
});
const UpdateServiceFilesUpload: FC<ServiceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  errorServiceForm,
  FormData,
}) => {
  const authContext = useContext(AuthContext);
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const serviceState = useAppSelector((state) => state.service?.isUpdated);

  useEffect(() => {
    if (id) {
      dispatch(getService(id));
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      serviceAgentId: authContext.user?.profil?.id ?? undefined,
      //tarif:  undefined,
      //images: serviceState?.images || undefined,
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    if (handleForm.type === "chekServiceUpdateFormThree") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  useEffect(() => {
    if (serviceState) {
      if (serviceState.tarif && typeof serviceState.tarif === "object" && "size" in serviceState.tarif) {
        setValue("tarif", serviceState.tarif as File);
      } else {
        setValue("tarif", undefined);
      }
    }
  }, [serviceState]);

  const handleDownload = (pdfLink: any) => {
    const link = document.createElement("a");
    link.href = pdfLink;
    link.target = "_blank";
    link.download = "file.pdf";
    link.click();
  };

  const [images, setImages] = useState<File[]>([]);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [oldImages, setOldImages] = useState<any[]>([]);
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files) {
      const fileArray = Array.from(files);
      setNewImages(fileArray);
      setValue("newImages", fileArray || undefined);
      trigger("newImages");
    }
  };

  useEffect(() => {
    if (serviceState) {
      setOldImages(serviceState?.images);
    }
  }, []);

  useEffect(() => {
    if (serviceState) {
      dispatch(setServiceOldImages(oldImages));
    }
  }, [serviceState]);

  const handleDeleteNewImage = (index: number) => {
    setNewImages((prevImages) => {
      const updatedNewImages = prevImages.filter((_, i) => i !== index);
      return updatedNewImages;
    });
  };

  const handleDeleteOldImage = (index: number) => {
    setOldImages((prevImages) => {
      const updatedNewImages = prevImages.filter((_, i) => i !== index);
      dispatch(setServiceOldImages(updatedNewImages));
      return updatedNewImages;
    });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    const formData = {
      ...data,
      oldImages: oldImages,
    };
    FormData(formData);
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <span className="text-2xl font-semibold">Images de prestation</span>
          <form encType="multipart/form-data">
            <div>
              <div className="mb-8" onDrop={handleDrop} onDragOver={handleDragOver}>
                <div className="mt-5">
                  <div className="mt-3">
                    <p className="text-sm text-green-500">Vos images:</p>
                    <div className="flex items-center justify-center">
                      <div className="relative">
                        <div className="flex flex-wrap gap-2 mt-2">
                          {Array.isArray(oldImages) &&
                            oldImages.map((image, index) => (
                              <div key={image} className="relative">
                                <img
                                  src={process.env.REACT_APP_CLOUD_FRONT + image}
                                  alt={`Uploaded ${index}`}
                                  className="h-auto max-w-xs max-h-40"
                                />

                                <TrashIcon
                                  className="absolute w-5 text-white bg-black rounded-full cursor-pointer top-2 right-2 h-7 las la-trash-alt"
                                  onClick={() => handleDeleteOldImage(index)}
                                />
                              </div>
                            ))}
                          {newImages &&
                            newImages?.map((image, index) => (
                              <div key={index} className="relative">
                                <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Newly uploaded ${index}`}
                                  className="h-auto max-w-xs max-h-40"
                                />
                                <TrashIcon
                                  className="absolute w-5 text-white bg-black rounded-full cursor-pointer top-2 right-2 h-7 las la-trash-alt"
                                  onClick={() => handleDeleteNewImage(index)}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                    <div className="space-y-1 text-center">
                      <svg
                        className="w-12 h-12 mx-auto text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                        <label
                          htmlFor="fileInput"
                          className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>Ajouter</span>
                          <input
                            id="fileInput"
                            name="newImages"
                            type="file"
                            multiple
                            className="sr-only"
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files) {
                                const fileArray = Array.from(files);
                                setNewImages((prevImages) => [...prevImages, ...fileArray]);
                                setValue("newImages", [...newImages, ...fileArray]);
                                trigger("newImages");
                              }
                            }}
                            accept="image/jpeg, image/jpg, image/png, image/webp"
                          />
                        </label>
                        <p className="pl-1">ou déposer</p>
                      </div>
                      <p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF jusqu'à 10MB</p>
                    </div>
                  </div>
                </div>
              </div>
              {errors.newImages?.message && (
                <div className="text-xs text-red-500">{errors.newImages?.message.toString()}</div>
              )}

              <br />
              <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
              <div className="my-8"></div>
            </div>
          </form>

          {errors.newImages?.message && (
            <div className="text-xs text-red-500">{errors.newImages?.message.toString()}</div>
          )}

          <span className="text-2xl font-semibold">Document du tarif</span>
          {serviceState?.tarif && getValues("remove") != true ? (
            <div className="flex mt-2 space-x-3 ">
              <button
                className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                onClick={() => handleDownload(serviceState?.tarif)}
              >
                Télécharger votre ancien document du tarif
              </button>
              <TrashIcon
                className="w-5 mt-1 text-white bg-black rounded-full cursor-pointer right-2 h-7 las la-trash-alt"
                type="button"
                onClick={() => setValue("remove", true)}
              />
            </div>
          ) : (
            <div className="mt-2">
              <p>Aucun fichier précédemment téléchargé</p>
            </div>
          )}
          <div className="mt-3">
            <input
              className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
              type="file"
              id="formFile"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  setValue("tarif", file);
                  trigger("tarif");
                }
              }}
            />
            {errors.tarif?.message && (
              <div className="text-xs text-red-500">{errors.tarif?.message.toString()}</div>
            )}
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default UpdateServiceFilesUpload;
