import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Location, LocationPrices } from "data/types";

import { RootState } from "redux/store";
import { locationApi } from "./featureAPI";

interface ErrorValidation {
  path: string;
  msg: string;
}
interface LocationState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  validationLoading: "idle" | "pending" | "succeeded" | "failed";
  locationError: string;
  locationErrorValidation: ErrorValidation[];
  error: SerializedError | "";
  totalSteps: number;
  currentStep: number;
  validationStep: number;
  validationTotalSteps: number;
  locationData?: Location;
  isUpdated?: Location;
  allLocations?: Location;
  locationConditions?: undefined;
  locationEquipments?: undefined;
  locationExtraEquipments?: undefined;
  locationPrices?: undefined;
  uncheckedRules?: [];
  uncheckedEquipments?: [];
  uncheckedExtraEquipments?: [];
  uncheckedPrices?: [];
  oldImages?: [];
  unApprouvedLocations?: Location;
  approuvedLocations?: Location;
  incompletedLocations?: Location;
  AllLocationMinPrices?: LocationPrices;
  locationMinPrice?: undefined;
  locationNotExist?: boolean | undefined;
}

const initialState: LocationState = {
  loading: "idle",
  validationLoading: "idle",
  locationError: "",
  locationErrorValidation: [],
  error: "",
  totalSteps: 8,
  currentStep: 1,
  validationStep: 0,
  validationTotalSteps: 4,
  locationData: undefined,
  isUpdated: undefined,
  locationConditions: undefined,
  locationEquipments: undefined,
  locationExtraEquipments: undefined,
  locationPrices: undefined,
  allLocations: undefined,
  uncheckedRules: [],
  AllLocationMinPrices: undefined,
  uncheckedEquipments: [],
  uncheckedExtraEquipments: [],
  uncheckedPrices: [],
  oldImages: undefined,
  locationMinPrice: undefined,
  locationNotExist: undefined,
};

export const addLocation = createAsyncThunk("addLocation", async (data: any) => {
  return await locationApi.createLocation(data);
});

export const updateLocation = createAsyncThunk("updateLocation", async ({ data, id }: { data: any; id: any }) => {
  return await locationApi.updateLocation(data, id);
});

export const getLocationById = createAsyncThunk("getLocationById", async (id: string) => {
  return await locationApi.getLocationById(id);
});

export const getLocationAllDataById = createAsyncThunk("getLocationAllDataById", async (id: string) => {
  return await locationApi.getLocationAllDataById(id);
});

export const getConditionsByIdLocation = createAsyncThunk("getConditionsByIdLocation", async (id: any) => {
  return await locationApi.getConditionsByIdLocation(id);
});

export const deleteConditionByLocationId = createAsyncThunk(
  "deleteConditionByLocationId",
  async (data: { designation: any; locationId: any }) => {
    return await locationApi.deleteConditionByLocationId(data.designation, data.locationId);
  }
);

export const getEquipmentsByIdLocation = createAsyncThunk("getEquipmentsByIdLocation", async (id: any) => {
  return await locationApi.getEquipmentsByIdLocation(id);
});

export const deleteEquipmentByLocationId = createAsyncThunk(
  "deleteEquipmentByLocationId",
  async (data: { equipmentId: any; locationId: any }) => {
    return await locationApi.deleteEquipmentByLocationId(data.equipmentId, data.locationId);
  }
);

export const getExtraEquipmentsByIdLocation = createAsyncThunk(
  "getExtraEquipmentsByIdLocation",
  async (id: any) => {
    return await locationApi.getExtraEquipmentsByIdLocation(id);
  }
);

export const getLocationPricesByIdLocation = createAsyncThunk("getLocationPricesByIdLocation", async (id: any) => {
  return await locationApi.getLocationPricesByIdLocation(id);
});

export const deleteExtraEquipmentByLocationId = createAsyncThunk(
  "deleteExtraEquipmentByLocationId",
  async (data: { designation: any; locationId: any }) => {
    return await locationApi.deleteExtraEquipmentByLocationId(data.designation, data.locationId);
  }
);
export const deletePricesByLocationId = createAsyncThunk(
  "deletePricesByLocationId",
  async (data: { price: any; locationId: any }) => {
    return await locationApi.deletePricesByLocationId(data.price, data.locationId);
  }
);

export const getAllLocations = createAsyncThunk("getAllLocations", async () => {
  return await locationApi.getAllLocations();
});

export const getAllLocationsByAdmin = createAsyncThunk("getAllLocationsByAdmin", async (pageNumber: number) => {
  return await locationApi.getAllLocationsByAdmin(pageNumber);
});

export const getAllApprouvedLocationsByAdmin = createAsyncThunk(
  "getAllApprouvedLocationsByAdmin",
  async (pageNumber: number) => {
    return await locationApi.getAllApprouvedLocationsByAdmin(pageNumber);
  }
);

export const getAllIncompletedLocationsByAdmin = createAsyncThunk(
  "getAllIncompletedLocationsByAdmin",
  async (pageNumber: number) => {
    return await locationApi.getAllIncompletedLocationsByAdmin(pageNumber);
  }
);

export const getHomeLocations = createAsyncThunk("getHomeLocations", async () => {
  return await locationApi.getHomeLocations();
});

export const validateLocation = createAsyncThunk(
  "validateLocation",
  async ({
    data,
    id,
    stripeToken,
    stripeBankToken,
  }: {
    data: any;
    id: any;
    stripeToken?: string;
    stripeBankToken?: string;
  }) => {
    return await locationApi.validateLocation(data, id, stripeToken, stripeBankToken);
  }
);

export const createConnectedAcount = createAsyncThunk(
  "createConnectedAcount",
  async ({
    data,
    stripeToken,
    stripeBankToken,
  }: {
    data: any;
    stripeToken?: string;
    stripeBankToken?: string;
  }) => {
    return await locationApi.createConnectedAcountApi(data, stripeToken, stripeBankToken);
  }
);

export const updateLocationJustification = createAsyncThunk(
  "updateLocationJustification",
  async ({ data, id }: { data: any; id: any }) => {
    return await locationApi.updateLocationJustification(data, id);
  }
);

export const getLocationByUserId = createAsyncThunk("getLocationByUserId", async (id: string) => {
  return await locationApi.getLocationByUserId(id);
});

export const fetchLocationsPrices = createAsyncThunk("fetchLocationsPrices", async () => {
  return await locationApi.fetchLocationsPrices();
});

export const getMinPriceByIdLocation = createAsyncThunk("getMinPriceByIdLocation", async (id: any) => {
  return await locationApi.getMinPriceByIdLocation(id);
});

export const locationSlice = createSlice({
  name: "Location",
  initialState,
  reducers: {
    initStates(state) {
      return {
        ...state,
        loading: "idle",
        serviceError: "",
        error: "",
        totalSteps: 8,
        currentStep: 1,
        locationData: undefined,
        isUpdated: undefined,
        locationConditions: undefined,
        locationEquipments: undefined,
        locationExtraEquipments: undefined,
        locationPrices: undefined,
        uncheckedRules: [],
        uncheckedEquipments: [],
        uncheckedExtraEquipments: [],
        uncheckedPrices: [],
        oldImages: undefined,
        AllLocationMinPrices: undefined,
        locationMinPrice: undefined,
        validationStep: 0,
        validationTotalSteps: 4,
        locationError: "",
        locationNotExist: undefined,
        locationErrorValidation: [],
      };
    },
    resetValues(state) {
      state.totalSteps = 8;
      state.currentStep = 1;
      state.validationStep = 0;
      state.validationLoading = "idle";
      state.loading = "idle";
      state.locationData = undefined;
      state.error = "";
    },
    resetUpdateValues(state) {
      state.loading = "idle";
      state.validationLoading = "idle";
      state.isUpdated = undefined;
      state.error = "";
      state.totalSteps = 8;
      state.currentStep = 0;
      state.validationStep = 0;
      state.validationTotalSteps = 4;
      state.uncheckedPrices = [];
    },
    nextStep(state) {
      state.currentStep++;
    },
    validationNextStep(state) {
      state.validationStep++;
    },
    previousValidationStep(state) {
      state.validationStep--;
    },
    previousStep(state) {
      state.currentStep--;
    },
    setTotalSteps(state, action) {
      state.totalSteps = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setLocationData(state, action) {
      state.locationData = action.payload;
      state.isUpdated = action.payload;
    },
    setUncheckedConditions(state, action) {
      state.uncheckedRules = action.payload;
    },
    setUncheckedEquipments(state, action) {
      state.uncheckedEquipments = action.payload;
    },
    setUncheckedExtraEquipments(state, action) {
      state.uncheckedExtraEquipments = action.payload;
    },
    setUncheckedPrices(state, action) {
      state.uncheckedPrices = action.payload;
    },
    setLocationOldImages(state, action) {
      state.oldImages = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    //  location min price
    builder.addCase(getMinPriceByIdLocation.fulfilled, (state, action) => {
      state.locationMinPrice = action.payload;
    });

    // all locations prices
    builder.addCase(fetchLocationsPrices.fulfilled, (state, action) => {
      state.AllLocationMinPrices = action.payload;
    });

    // add location
    builder.addCase(addLocation.pending, (state, action) => {
      state.loading = "pending";
      state.locationError = "";
    });
    builder.addCase(addLocation.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
        state.locationData = action.payload.data;
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.locationErrorValidation = action.payload.error;
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.locationError = action.payload.error;
      }
    });
    builder.addCase(addLocation.rejected, (state, action) => {
      state.loading = "failed";
    });

    // all locations
    builder.addCase(getAllLocations.fulfilled, (state, action) => {
      state.allLocations = action.payload;
    });

    // amdin all unprouved locations
    builder.addCase(getAllLocationsByAdmin.fulfilled, (state, action) => {
      state.unApprouvedLocations = action.payload;
    });
    // amdin all aprouved locations

    builder.addCase(getAllApprouvedLocationsByAdmin.fulfilled, (state, action) => {
      state.approuvedLocations = action.payload;
    });

    builder.addCase(getAllIncompletedLocationsByAdmin.fulfilled, (state, action) => {
      state.incompletedLocations = action.payload;
    });

    builder.addCase(getHomeLocations.fulfilled, (state, action) => {
      state.allLocations = action.payload;
    });

    // update location

    builder.addCase(updateLocation.pending, (state, action) => {
      state.loading = "pending";
      state.locationError = "";
    });
    builder.addCase(updateLocation.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
        state.locationData = action.payload.data;
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.locationErrorValidation = action.payload.error;
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.locationError = action.payload.error;
      }
    });
    builder.addCase(updateLocation.rejected, (state, action) => {
      state.loading = "failed";
    });

    // get location by id
    builder.addCase(getLocationById.fulfilled, (state, action) => {
      state.locationData = action.payload;
    });

    // get location by id
    builder.addCase(getLocationAllDataById.pending, (state, action) => {
      state.loading = "pending";
      state.locationError = "";
    });
    builder.addCase(getLocationAllDataById.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
        state.locationData = action.payload.data.location;
        if (state.locationData) {
          state.locationData.equipment = action.payload.data.equipment;
          state.locationData.condition = action.payload.data.condition;
          state.locationData.rules = action.payload.data.rules;
          state.locationData.prices = action.payload.data.prices;
          state.locationData.extraEquipment = action.payload.data.extraEquipment;
        }
      }
      if (action.payload.status === "NOT_FOUND") {
        state.locationError = action.payload.message;
        state.locationNotExist = true;
      }
      if (action.payload.status === "ERROR") {
        state.locationError = action.payload.error;
      }
    });
    builder.addCase(getLocationAllDataById.rejected, (state, action) => {
      state.loading = "failed";
    });

    builder.addCase(getLocationByUserId.fulfilled, (state, action) => {
      state.locationData = action.payload;
    });

    builder.addCase(getConditionsByIdLocation.fulfilled, (state, action) => {
      state.locationConditions = action.payload;
    });

    builder.addCase(getEquipmentsByIdLocation.fulfilled, (state, action) => {
      state.locationEquipments = action.payload;
    });

    builder.addCase(getExtraEquipmentsByIdLocation.fulfilled, (state, action) => {
      state.locationExtraEquipments = action.payload;
    });

    builder.addCase(getLocationPricesByIdLocation.fulfilled, (state, action) => {
      state.locationPrices = action.payload;
    });

    builder.addCase(updateLocationJustification.pending, (state, action) => {
      state.validationLoading = "pending";
      state.locationError = "";
    });
    builder.addCase(updateLocationJustification.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.validationLoading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.validationLoading = "failed";
        state.locationError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.validationLoading = "failed";
        state.locationError = action.payload.error;
      }
    });
    builder.addCase(updateLocationJustification.rejected, (state, action) => {
      state.validationLoading = "failed";
    });

    // validate location

    builder.addCase(validateLocation.pending, (state, action) => {
      state.loading = "pending";
      state.locationError = "";
    });
    builder.addCase(validateLocation.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.locationError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.locationError = action.payload.error;
      }
    });
    builder.addCase(validateLocation.rejected, (state, action) => {
      state.loading = "failed";
    });
  },
});

export const {
  initStates,
  nextStep,
  previousStep,
  setTotalSteps,
  setLocationData,
  setError,
  setCurrentStep,
  setUncheckedConditions,
  setUncheckedEquipments,
  setUncheckedExtraEquipments,
  setUncheckedPrices,
  setLocationOldImages,
  validationNextStep,
  resetUpdateValues,
  resetValues,
} = locationSlice.actions;

export const selectCount = (state: RootState) => state.location;
export default locationSlice.reducer;
