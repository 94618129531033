import { zodResolver } from "@hookform/resolvers/zod";
import { Radio, Textarea } from "@material-tailwind/react";
import Label from "components/Label/Label";
import { AuthContext } from "context/appContext";
import { Fields, ProfilService } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import Multiselect from "multiselect-react-dropdown";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { ServiceProfileUpdate, getFields, resetValues } from "redux/features/signUp/signUpSlice";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { z } from "zod";
import __cities from "../../../data/jsons/cities.json";
import CommonLayout from "./AgentCommonLayout";
import { currentUser } from "redux/features/auth/authSlice";

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

const formSchema = z.object({
  designation: z.string().min(1, { message: "Le designation est obligatoire." }),
  phone: z
    .string()
    .min(1, { message: "Le numéro téléphone est obligatoire." })
    .refine((value) => !/^0{11}$/.test(value), {
      message: "Le numéro de téléphone ne peut pas comporter zéros consécutifs.",
    }),
  link: z.string(),
  region: z.string({ required_error: "Le region est obligatoire." }),
  contact: z.string(),
  description: z.string(),
  instagram: z.string(),
  youtube: z.string(),
  facebook: z.string(),
  fields: z.string().min(1, { message: "Les domaines sont obligatoires." }),
  availability: z.array(z.string()).nonempty({ message: "Les régions disponibles est obligatoire." }),
  service_url: z
    .optional(z.instanceof(File, { message: "L'image est obligatoire." }))
    .refine(
      (file) => !file || (file?.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type)),
      "Le fichier doit être une image au format JPEG, PNG ou GIF et ne pas dépasser 10MB."
    ),
});

const UpdateAccountPage: React.FC = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.auth?.user?.profil as ProfilService);
  const signUpState = useAppSelector((state) => state.signUp);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fields: Fields[] = useAppSelector((state) => state.signUp.fields);
  let profilId: number | undefined;
  profilId = authContext.user?.profil?.id;

  let profile = authContext.user?.profil as ProfilService;
  useEffect(() => {
    if (userState) {
      setValue("region", userState.region || "");
      setValue("designation", userState.designation || "");
      setValue("phone", userState.phone || "");
      setValue("link", userState.link || "");
      setValue("contact", userState.contact || "");
      setValue("facebook", userState.facebook || "");
      setValue("instagram", userState.instagram || "");
      setValue("youtube", userState.youtube || "");
      setValue("description", userState.description || "");
      setValue("fields", userState.fields);
      const tagsArray = Array.isArray(userState.availability) ? userState.availability : [];
      const availabilityValue: [string, ...string[]] = [tagsArray[0] || "", ...tagsArray.slice(1)];
      setValue("availability", availabilityValue);
    }
  }, [userState]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      link: "",
      contact: "",
      description: "",
      facebook: "",
      youtube: "",
      instagram: "",
      fields: "",
      availability: [],
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    dispatch(getFields());
  }, []);
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    await dispatch(ServiceProfileUpdate({ data: data, id: profilId }));

    dispatch(currentUser()).then(() => {
      dispatch(resetValues());
      navigate("/prestataire");
    });
  };

  const [phoneBackground, setPhoneBackground] = useState("");

  useEffect(() => {
    let theme = localStorage.getItem("theme");
    if (theme === "dark") {
      setPhoneBackground("rgb(31 41 55)");
    } else {
      setPhoneBackground("#FFFFFF");
    }
  }, []);

  const phoneInputStyle = {
    border: "1px solid #CBD5E0",
    borderRadius: "0.375rem",
    backgroundColor: phoneBackground,
  };

  const selectedValues = getValues("availability");
  const selectedLabels = selectedValues.map((value) => {
    const selectedLabel = __cities.find((option) => option.label === value);
    return selectedLabel ? { label: selectedLabel.label } : { label: value };
  });

  useEffect(() => {
    switch (signUpState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [signUpState.loading]);

  useEffect(() => {
    getValues("fields");
  }, [watch("fields")]);

  return (
    <div>
      <CommonLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            <div className="flex-col items-start flex-shrink-0">
              <div className="relative flex overflow-hidden rounded-full">
                <Avatar
                  sizeClass="w-32 h-32"
                  imgUrl={
                    getValues("service_url")
                      ? URL.createObjectURL(getValues("service_url") as File)
                      : process.env.REACT_APP_CLOUD_FRONT + userState?.service_url
                  }
                />
                {getValues("service_url") ? null : (
                  <div className="absolute inset-0 flex flex-col items-center justify-center bg-black cursor-pointer bg-opacity-60 text-neutral-50">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    ></svg>
                    <span className="mt-1 text-xs">Selectionner Image</span>
                  </div>
                )}

                <input
                  type="file"
                  id="imageFile"
                  name="imageFile"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      setValue("service_url", file);
                      trigger("service_url");
                    }
                  }}
                  accept="image/jpeg, image/jpg, image/png, image/webp"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center md:flex-row">
            <div className="flex-grow mt-10 space-y-6 md:mt-0">
              <div className="flex flex-wrap gap-8 x">
                <div>
                  <Label>Nom et prénom *</Label>
                  <Input
                    type="text"
                    placeholder="Nom et prénom"
                    className={`mt-1.5 ${errors.designation?.message ? "border-red-500" : ""}`}
                    {...register("designation")}
                  />
                </div>

                <div>
                  <Label>Vous-étes *</Label>
                  <Input
                    type="text"
                    name="instagram"
                    value={userState?.category === "particular" ? "Particulier" : "Entreprise"}
                  />
                </div>
              </div>

              <div className="flex flex-wrap gap-8 x">
                <div>
                  <Label>Ville *</Label>
                  <Select
                    style={{ width: "315px" }}
                    className={`mt-1.5 ${errors.region?.message ? "border-red-500" : ""}`}
                    placeholder="Choix de ville"
                    onChange={(e) => setValue("region", e.target.value)}
                  >
                    <option value={""}>{getValues("region")}</option>

                    {__cities?.map((region: any) => (
                      <option key={region.label} value={region.label}>
                        {`${region.label}`}
                      </option>
                    ))}
                  </Select>
                </div>

                <div>
                  <Label>Contact</Label>
                  <Input type="text" placeholder="example@mail.com" className="mt-1.5" {...register("contact")} />
                </div>
              </div>

              <div className="flex flex-wrap gap-8 x">
                <div>
                  <Label>Numéro téléphone *</Label>

                  <PhoneInput
                    country={"fr"}
                    placeholder={getValues("phone")}
                    inputStyle={phoneInputStyle}
                    containerStyle={phoneInputStyle}
                    buttonStyle={phoneInputStyle}
                    dropdownStyle={phoneInputStyle}
                    searchStyle={phoneInputStyle}
                    inputProps={{ required: true }}
                    value={getValues("phone")}
                    onChange={(value) => setValue("phone", value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap gap-8 x">
                <div className="site lg:w-1/2">
                  <Label>Site web</Label>
                  <Input type="text" placeholder="https://example.com" {...register("link")} />
                </div>
              </div>
              <div className="flex flex-wrap gap-8 x">
                <div className="facebook lg:w-1/2">
                  <Label>Facebook </Label>
                  <Input
                    className={`mt-1.5 ${errors.facebook?.message ? "border-red-500" : ""}`}
                    type="text"
                    placeholder="https://www.facebook.com/"
                    {...register("facebook")}
                  />
                  {errors.facebook?.message && (
                    <div className="text-xs text-red-500">{errors.facebook?.message}</div>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap gap-8 x">
                <div className="instagram lg:w-1/2">
                  <Label>instagram</Label>
                  <Input
                    className={`mt-1.5 ${errors.instagram?.message ? "border-red-500" : ""}`}
                    type="text"
                    placeholder="https://instagram.com/"
                    {...register("instagram")}
                  />
                </div>
              </div>
              <div className="flex flex-wrap gap-8 x">
                <div className="youtube lg:w-1/2">
                  <Label>Youtube</Label>
                  <Input
                    className={`mt-1.5 ${errors.youtube?.message ? "border-red-500" : ""}`}
                    type="text"
                    placeholder="https://www.youtube.com/"
                    {...register("youtube")}
                  />
                  {errors.youtube?.message && (
                    <div className="text-xs text-red-500">{errors.youtube?.message}</div>
                  )}
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <h2 className="text-2xl font-semibold">A propos</h2>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    Mentionnez les meilleures caractéristiques de votre prestations , équipements spéciaux ...
                  </span>
                </div>
                <Textarea placeholder="..." rows={14} {...register("description")} />

                {/* <div>
                                    <label className="text-lg font-semibold" htmlFor="">
                                        Type de prestation *
                                    </label>
                                    <div className="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-3">
                                        {fields?.map((field) => (
                                            <Radio
                                                key={field.designation}
                                                label={field.designation}
                                                value={field.designation}
                                                defaultChecked={userState?.fields.includes(field.designation)}
                                                {...register('fields')}
                                            />
                                        ))}
                                    </div>
                                </div> */}

                <div className="type">
                  <Label>Type de prestation *</Label>
                  <Select
                    className={`mt-1.5 ${errors.fields?.message ? "border-red-500" : ""}`}
                    disabled={isLoading}
                    value={getValues("fields")}
                    onChange={(e) => setValue("fields", e.target.value)}
                  >
                    <option value={""}>{getValues("fields")}</option>

                    {signUpState.fields?.map((field) => (
                      <option key={field.designation} value={field.designation}>
                        {field.designation}
                      </option>
                    ))}
                  </Select>

                  {errors.fields?.message && <div className="text-xs text-red-500">Ce champs est obligatoire</div>}
                </div>
              </div>

              <div>
                <span className="block mb-3 text-lg font-semibold">
                  Les régions disponibles pour vos prestations *
                </span>
                <Multiselect
                  options={__cities}
                  selectedValues={selectedLabels}
                  onSelect={(selectedList) => {
                    const labels = selectedList.map((item: { label: any }) => item.label);
                    setValue("availability", labels);
                  }}
                  onRemove={(selectedList) => {
                    const labels = selectedList.map((item: { label: any }) => item.label);
                    setValue("availability", labels);
                  }}
                  displayValue="label"
                  className={errors.availability ? "border-red-500" : ""}
                  placeholder="Select regions"
                  closeOnSelect={false}
                />
                {errors.availability?.message && (
                  <div className="text-xs text-red-500">{errors.availability?.message.toString()}</div>
                )}
              </div>

              <div>
                {" "}
                <ButtonPrimary type="submit" className="w-full md:w-1/6" loading={isLoading}>
                  Modifier
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </form>
      </CommonLayout>
    </div>
  );
};

export default UpdateAccountPage;
