import CommonLayout from "containers/Client/AccountPage/CommonLayout";
import { AuthContext } from "context/appContext";
import { ProfilClient } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getLocationById,
  getLocationByUserId,
  resetValues,
  setLocationData,
  updateLocationJustification,
  validationNextStep,
} from "redux/features/location/locationSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LocationCompanyImagesData from "./components/LocationCompanyImagesData";
import LocationCompanyPersonalData from "./components/LocationCompanyPersonalData";
import LocationConfirmation from "./components/LocationConfirmation";
import LocationParticularDetailedData from "./components/LocationParticularDetailedData";
import LocationParticularImagesData from "./components/LocationParticularImagesData";
import LocationUserType from "./components/LocationUserType";
import { socket } from "utils/socket";

export interface PageAddListing10Props {}
const Congrats: FC<PageAddListing10Props> = () => {
  const authContext = useContext(AuthContext);
  let userId: number | undefined;
  let role = "";
  let profilId: number | undefined;

  userId = authContext.user?.id;
  role = authContext.user?.role ?? "";
  profilId = authContext.user?.profil?.id;

  let profile = authContext.user?.profil as ProfilClient;
  const user_validation = profile?.validation;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const locationState = useAppSelector((state) => state.location);
  const progress = (locationState.validationStep / locationState.validationTotalSteps) * 100;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [event, setEvent] = useState<Event>(new Event(""));

  useEffect(() => {
    dispatch(resetValues());
  }, []);

  const handleNextClick = () => {
    switch (locationState.validationStep) {
      case 1: {
        setEvent(new Event("chekLocationFormThirteenOwner"));
        break;
      }
      case 2: {
        if (locationState?.locationData?.category === "company") {
          setEvent(new Event("chekLocationFormFourteenCompany"));
        }
        if (locationState?.locationData?.category === "particular") {
          setEvent(new Event("chekLocationFormFourteenParticular"));
        }
        break;
      }
      case 3: {
        if (locationState?.locationData?.category === "company") {
          setEvent(new Event("chekLocationFormFifteenCompany"));
        }
        if (locationState?.locationData?.category === "particular") {
          setEvent(new Event("chekLocationFormFifteenParticular"));
        }
        break;
      }
      case 4: {
        setEvent(new Event("confirmLocation"));
        break;
      }
      default: {
        setEvent(new Event(""));
        break;
      }
    }
  };

  const handlePreviousClick = () => {
    dispatch(previousValidationStep());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [locationState.validationStep]);

  useEffect(() => {
    switch (locationState.validationLoading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        if (locationState.locationData && locationState.validationStep === 4) {
          dispatch(updateLocationJustification({ data: locationState.locationData, id: id }));
        } else {
          dispatch(validationNextStep());
        }
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [locationState.validationLoading]);

  const { id } = useParams();
  const initialRender = useRef(true);

  useEffect(() => {
    const fetchData = async () => {
      if (locationState.locationData && locationState.validationStep === 4) {
        const locationData = locationState.locationData;
        const res = await dispatch(updateLocationJustification({ data: locationState.locationData, id: id }));

        if (res) {
          socket.emit("send-notification", {
            action: "create_espace",
            senderId: locationState.locationData?.userId,
            location: locationState?.locationData,
            locationId: locationState?.locationData?.id,
          });
        }

        dispatch(getLocationByUserId(String(userId)));

        if (profile?.validation === "en cours") {
          navigate(`/client/demande`);
        } else {
          navigate(`/client/locations`);
        }
      }
    };

    fetchData();
  }, [locationState.locationData]);

  useEffect(() => {
    if (!initialRender.current && locationState.locationData && locationState.validationStep !== 4) {
      dispatch(validationNextStep());
      setIsLoading(false);
    }

    initialRender.current = false;
  }, [locationState.locationData]);

  useEffect(() => {
    if (id) {
      dispatch(getLocationById(id));
    }
  }, [id]);

  const [isCompleterClicked, setIsCompleterClicked] = useState<boolean>(false);

  const handleShowForm = () => {
    setIsCompleterClicked(true);
  };

  const handleNavigate = () => {
    navigate("/client/locations");
  };

  return (
    <Fragment>
      <CommonLayout>
        {user_validation === "approuvé" ? (
          <>
            <div>
              <h2 className="text-2xl font-semibold al">Dernière étape</h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Super ! Votre demande a bien été enregistrée et en cours de traitement
              </span>

              <div className="flex items-center justify-center h-full">
                <ButtonPrimary className="mt-5" onClick={handleNavigate}>
                  Mes espaces
                </ButtonPrimary>
              </div>
            </div>
          </>
        ) : (
          <>
            {user_validation !== "approuvé" && !isCompleterClicked && (
              <div>
                <h2 className="text-2xl font-semibold al">Dernière étape</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  Super ! Votre demande a bien été enregistrée. Veuillez fournir les documents nécessaires pour que
                  votre espace soit approuvé
                </span>

                <div className="flex items-center justify-center h-full">
                  <ButtonPrimary className="mt-5" onClick={handleShowForm}>
                    Completer
                  </ButtonPrimary>
                </div>
              </div>
            )}

            <div className={` pb-24  mx-auto nc-PageAddListing1 `} data-nc-id="PageAddListing1">
              {isCompleterClicked && (
                <div className="mt-10 space-y-6">
                  {locationState.validationStep === 1 && (
                    <LocationUserType
                      isLoading={isLoading}
                      role={role ?? ""}
                      handleForm={event}
                      FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
                      errorLocationForm={locationState.locationError}
                    />
                  )}
                  {/* Company profil */}

                  {locationState.validationStep === 2 && locationState.locationData?.category === "company" && (
                    <LocationCompanyPersonalData
                      isLoading={isLoading}
                      role={role ?? ""}
                      handleForm={event}
                      FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
                      errorLocationForm={locationState.locationError}
                    />
                  )}

                  {locationState.validationStep === 3 && locationState.locationData?.category === "company" && (
                    <LocationCompanyImagesData
                      isLoading={isLoading}
                      role={role ?? ""}
                      handleForm={event}
                      FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
                      errorLocationForm={locationState.locationError}
                    />
                  )}

                  {/* Particular profil */}

                  {locationState.validationStep === 2 && locationState.locationData?.category === "particular" && (
                    <LocationParticularDetailedData
                      isLoading={isLoading}
                      role={role ?? ""}
                      handleForm={event}
                      FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
                      errorLocationForm={locationState.locationError}
                    />
                  )}

                  {locationState.validationStep === 3 && locationState.locationData?.category === "particular" && (
                    <LocationParticularImagesData
                      isLoading={isLoading}
                      role={role ?? ""}
                      handleForm={event}
                      FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
                      errorLocationForm={locationState.locationError}
                    />
                  )}

                  {locationState.validationStep === 4 && (
                    <LocationConfirmation
                      isLoading={isLoading}
                      role={role ?? ""}
                      handleForm={event}
                      FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
                      errorLocationForm={locationState.locationError}
                    />
                  )}

                  <div className="flex flex-col items-center justify-center space-y-3">
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                      <div className="bg-blue-600 h-2.5 rounded-full w-45" style={{ width: `${progress}%` }}></div>
                    </div>

                    <div className="flex items-center justify-between w-full">
                      <p className="text-lg font-semibold">
                        {`Étape ${locationState.validationStep}/${locationState.validationTotalSteps}`}
                      </p>

                      <ButtonPrimary onClick={handleNextClick} loading={isLoading} disabled={isLoading}>
                        Continue
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {/*  */}
      </CommonLayout>
    </Fragment>
  );
};

export default Congrats;
function previousValidationStep(): any {
  throw new Error("Function not implemented.");
}
