import AdvanceTab from "./AdvanceTab";

const AdvanceTabPage = () => {
  return (
    <>
      <div className="rbt-advance-tab-area bg-color-white ">
        <AdvanceTab tag="YOU CAN CUSTOMIZE ALL" title="Et que l’aventure commence ! " />
      </div>
    </>
  );
};

export default AdvanceTabPage;
