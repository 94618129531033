import { FC } from "react";
import imagePng2 from "images/collage5.png";
import imagePng from "images/concept-table-manger-plein-air.jpg";

import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { margin } from "@mui/system";
import { HomeVideo } from "data/types";

export interface SectionHeroProps {
  className?: string;
  homeVideoStates?: HomeVideo[];
}
const SectionHero: FC<SectionHeroProps> = ({ className = "", homeVideoStates }) => {
  return (
    <div
      className={`flex relative flex-col-reverse pt-10 nc-SectionHero lg:flex-col ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex flex-col items-start flex-shrink-0 pt-16 space-y-8 pb-14 lg:w-1/2 sm:space-y-10 lg:pb-60 xl:pr-4 lg:mr-10 xl:mr-0">
          <h1
            className="font-medium sx:mr-10 sx:mt-20 lg:mt-14 sx:dark:text-white sx:text-black text-7xl md:text-7xl sm:text-4xl xl:text-7xl !leading-[114%] dark:text-dark !leading-[114%] md:text-white"
            style={{ fontSize: "3rem" }}
          >
            Votre événement commence ici!
          </h1>
          <span className="text-base md:text-white sx:text-black md:text-lg text-neutral-500 dark:text-white">
            Retrouvez en quelques clics tout ce qu'il vous faut pour des événements de rêve : un espace original,
            une sélection de prestataires et plein d’idées inspirantes, tout est là !
          </span>
        </div>
      </div>
      <div className="w-full max-w-lg mx-auto mb-2 lg:hidden">
        <HeroSearchForm2MobileFactory />
      </div>

      <div className="z-10 hidden w-full mb-12 ml-12 xl:-mt-28 lg:block lg:mb-5 lg:-mt-32">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
