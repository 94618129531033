import { Tab } from "@headlessui/react";
import { useContext, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import Pagination from "shared/Pagination/Pagination";
import CarCardLocation from "components/CarCard/CarCardlocation";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { getFavorisByClient } from "redux/features/locations/locationSlice";
import { AuthContext } from "context/appContext";
import { Spinner } from "@material-tailwind/react";
import { itemPerDashboradClient } from "data/constants";

const AccountSavelists = () => {
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (profilId) {
      setIsLoading(true);
      dispatch(getFavorisByClient({ clientId: profilId })).then(() => setIsLoading(false));
    }
  }, [profilId]);
  const locationState = useAppSelector((state) => state.featureLocation.likedLocation || []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashboradClient;

  const paginatedWishlists = locationState?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const length = locationState?.length;
  const totalPages = Math.ceil(locationState?.length / itemsPerPage);

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold dark:text-white">Espace favoris</h2>
        </div>
        <span className="text-base">{length} espaces</span>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {isLoading ? (
          <div className="flex items-center justify-center mt-16">
            <p>
              <Spinner color="blue" className="w-12 h-12" />
            </p>
          </div>
        ) : (
          <div>
            <Tab.Group>
              <Tab.Panels>
                <Tab.Panel className="mt-8">
                  <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {paginatedWishlists?.map((item: any) => (
                      <CarCardLocation
                        key={item.id}
                        data={item.Location}
                        featuredImage={item?.Location?.images}
                        favoriId={item.id}
                      />
                    ))}
                  </div>
                  <div className="flex items-center justify-center mt-11">
                    {typeof totalPages === "number" && (
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            {length === 0 && (
              <div className="flex items-center justify-center">
                <h2 className="text-xl ">Aucun espace</h2>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
