import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { Input } from "@material-tailwind/react";
import { AuthContext } from "context/appContext";
import { useAppDispatch } from "hooks/hooks";
import React, { FC, useContext, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { filterAllReservation } from "redux/features/reservations/reservationSlice";

export interface ClientReservation {
  children?: React.ReactNode;
}

const ClientReservation: FC<ClientReservation> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const authContext = useContext(AuthContext);

  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      dispatch(
        filterAllReservation({ reference: searchTerm, userId: authContext.user?.profil?.id ?? undefined })
      ).then(() => {
        navigate("/client/search");
        setSearchTerm("");
      });
    }
  };

  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="">
      <div className="">
        <div className="">
          <div className="">
            <div className="bg-neutral-50 dark:bg-neutral-900">
              <div className="pt-1 bg-white border-b border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800">
                <div className="container">
                  <div className="flex justify-center space-x-8 overflow-x-auto text-center md:space-x-14 hiddenScrollbar">
                    <NavLink
                      to="/client/my-reservations"
                      className={({ isActive }) =>
                        `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                          !isActive ? "border-transparent" : "border-primary-500"
                        }`
                      }
                    >
                      Mes Réservations
                    </NavLink>
                    <NavLink
                      to="/client/list-reservations"
                      className={({ isActive }) =>
                        `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                          !isActive ? "border-transparent" : "border-primary-500"
                        }`
                      }
                    ></NavLink>

                    {path === "/clint/search" && (
                      <NavLink
                        to="/client/search"
                        className={({ isActive }) =>
                          `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                            !isActive ? "border-transparent" : "border-primary-500"
                          }`
                        }
                      >
                        Résultat
                      </NavLink>
                    )}

                    <div className="flex items-center justify-end">
                      <div className="relative flex">
                        <Input
                          type="text"
                          placeholder="Rechercher..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <MagnifyingGlassCircleIcon
                          onClick={handleSearch}
                          className="absolute w-6 h-6 text-gray-500 transform -translate-y-1/2 right-3 top-1/2 hover:text-eventToBee"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientReservation;
