import { FC, HTMLAttributes, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import OtpInput from "react-otp-input";
import toast from "react-hot-toast";
import Button from "shared/Button/Button";

const formSchema = z.object({
  email: z.string().email({ message: "Veuillez entrer une adresse email valide." }),
  otp: z.string(),
});

export interface ConfirmEmailProps {
  className?: string;
  email: string;
  handleForm: Event;
  errorForm?: string;
  FormData: (data: any) => void;
  ResendMail: () => void;
}

const ConfirmEmail: FC<ConfirmEmailProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  email,
  handleForm,
  errorForm,
  FormData,
  ResendMail,
}) => {
  const [otp, setOtp] = useState("");

  const [isMailSend, setIsMailSend] = useState(true);

  const { handleSubmit, getValues, setValue } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: email,
      otp: "",
    },
    mode: "onSubmit",
  });

  const onResendMail = () => {
    setIsMailSend(true);
    ResendMail();
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  useEffect(() => {
    if (handleForm.type === "chekConfirmEmailEvent") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  useEffect(() => {
    setValue("otp", otp);
  }, [otp]);

  useEffect(() => {
    if (errorForm) {
      toast.error(errorForm);
    }
  }, [errorForm]);

  useEffect(() => {
    if (isMailSend) {
      setTimeout(() => {
        setIsMailSend(false);
      }, 30000);
    }
  }, [isMailSend]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>

      <h2 className="mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Confirmation
      </h2>

      <div className="flex flex-col text-center text-sm font-semibold text-zinc-600">
        <span>
          Nous avons envoyé un e-mail à<span className="text-blue-500 px-1">{getValues("email")}</span>
          pour confirmer la validité de votre adresse e-mail.
        </span>
        <span className="py-2">Après réception de l'e-mail, veuillez entrer le numéro PIN généré ci-dessous.</span>

        {!isMailSend && (
          <span className="py-2">
            Si vous n'avez pas reçu d'email,{" "}
            <Button type="button" className="p-0 m-0 text-blue-500" onClick={onResendMail}>
              Renvoyez l'email de confirmation.
            </Button>
          </span>
        )}
      </div>

      <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
        <label className="block">
          <OtpInput
            value={otp}
            onChange={setOtp}
            inputType="number"
            numInputs={4}
            containerStyle={"flex w-full items-center justify-between space-x-5"}
            inputStyle={
              "w-16 h-16 xs:w-16 xs:h-16 md:w-12 md:h-12 lg:w-20 lg:h-20 rounded-2xl border-2 border-primary bg-transparent text-center text-2xl " +
              "disabled:border-primary/80 disabled:text-gray-500 " +
              "text-back font-bold outline-none transition focus:border-primary/70 focus:text-gray-700 " +
              "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            }
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus={true}
            skipDefaultStyles={true}
          />
        </label>
      </form>
    </div>
  );
};

export default ConfirmEmail;
