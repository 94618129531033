import { FC } from "react";
import { Typography } from "@material-tailwind/react";
import { Helmet } from "react-helmet";

export interface PrivacyProps {
  className?: string;
}

const TABLE_ROWS = [
  {
    partenaire: "Twilio sendGrid",
    paysDesinataire: "Etats-Unis",
    traitement: "Service d'envoi de courrier électronique",
    garanties: `https://www.twilio.com/en-us/legal/privacy`,
  },
  {
    partenaire: "Amazon Web Services",
    paysDesinataire: "Etats-Unis",
    traitement: "Hébergement du site web",
    garanties: "https://aws.amazon.com/fr/privacy/?nc1=f_pr",
  },
  {
    partenaire: "Facebook",
    paysDesinataire: "Etats-Unis",
    traitement: "Gestion des avis des personnes sur des produits, services ou contenus.",
    garanties: "https://fr-fr.facebook.com/privacy/policy",
  },
  {
    partenaire: "Youtube",
    paysDesinataire: "Etats-Unis",
    traitement: "Gestion des avis des personnes sur des produits, services ou contenus.",
    garanties: "https://policies.google.com/privacy",
  },
  {
    partenaire: "Instagram",
    paysDesinataire: "Etats-Unis",
    traitement: "Gestion des avis des personnes sur des produits, services ou contenus.",
    garanties: "https://privacycenter.instagram.com/policy/",
  },
  {
    partenaire: "TikTok",
    paysDesinataire: "Etats-Unis, Malaisie, Singapour",
    traitement: "Gestion des avis des personnes sur des produits, services ou contenus.",
    garanties: "https://www.tiktok.com/legal/page/cea/privacy-policy/fr",
  },
];
const TABLE_HEAD = ["Partenaire", "Pays destinataire", "Traitement réalisé", "Garanties"];

const Privacy: FC<PrivacyProps> = ({ className = "pt-6 pb-6" }) => {
  return (
    <>
      <Helmet>
        <title>Event To Bee - Politique de confidentialité</title>
        <meta
          name="description"
          content="Consultez la politique de confidentialité du site Event To Bee pour comprendre comment vos données personnelles sont collectées, utilisées et protégées."
        />
      </Helmet>

      <div className="flex flex-col items-center justify-center pt-6 pb-6">
        <h2 className="dark:text-white my-2 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900  justify-center">
          POLITIQUE DE CONFIDENTIALITE
        </h2>
        <h2 className="dark:text-white flex items-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900  justify-center">
          <a href="https://eventtobee.com" className="text-blue-600" target="_blank">
            www.eventtobee.com
          </a>
        </h2>
        <p className="dark:text-white flex items-center  leading-[115%] md:leading-[115%] font-semibold text-neutral-900  justify-center">
          Dernière mise à jour effectuée le 19/03/2024.
        </p>

        <div className="rounded-xl">
          <div className="flex flex-col items-center justify-center pt-5 text-center">
            <div className="flex items-stretch max-w-4xl p-6 mb-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div className="flex flex-col justify-between">
                <div className="pt-10">
                  <p className="h-full text-sm text-left">
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">PREAMBULE</p>
                    La présente politique de confidentialité vous informe sur la manière dont Bigup-Conseils
                    utilise et protège les informations que vous nous transmettez, le cas échéant, lorsque vous
                    utilisez le présent site accessible à partir de l’URL suivante : <br />{" "}
                    <a href="https://eventtobee.com" className="text-blue-600" target="_blank">
                      www.eventtobee.com{" "}
                    </a>{" "}
                    (ci-après le « Site »). <br />
                    Veuillez noter que cette politique de confidentialité est susceptible d’être modifiée ou
                    complétée à tout moment par Bigup-Conseils, notamment en vue de se conformer à toute évolution
                    légale ou technologique. Dans un tel cas, la date de sa mise à jour sera clairement identifiée
                    en tête de la présente politique. Ces modifications engagent l’Internaute dès leur mise en
                    ligne. Il convient par conséquent que l’Internaute consulte régulièrement la présente politique
                    de confidentialité afin de prendre connaissance de ses éventuelles modifications.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 1. PARTIES
                    </p>
                    La présente politique de confidentialité est applicable entre l’éditeur du Site à savoir Bigup-
                    Conseils ci-après <span className="font-bold"> Bigup-Conseils </span>, et toute personne se
                    connectant au Site, ci-après <span className="font-bold">l’Internaute</span>.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 2. DEFINITIONS
                    </p>
                    <span className="font-bold">Contenus du Site :</span> éléments de toute nature publiés sur le
                    Site, protégés ou non par un droit de propriété intellectuelle, tel que textes, images,
                    designs, présentations, vidéos, schémas, structures, bases de données ou logiciels.{" "}
                    <span className="font-bold">Internaute :</span> toute personne se connectant au Site. <br />
                    <span className="font-bold">Site :</span>
                    site internet accessible à l’URL{" "}
                    <a href="https://eventtobee.com" className="text-blue-600" target="_blank">
                      www.eventtobee.com
                    </a>{" "}
                    , ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférant. <br />
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 3. CHAMP D’APPLICATION
                    </p>
                    La présente politique de confidentialité est applicable à tout Internaute. Votre simple
                    navigation sur le Site emporte votre acceptation pleine et entière de celle-ci. <br />{" "}
                    L’Internaute reconnaît la valeur de preuve des systèmes d&#39;enregistrement automatique de
                    Bigup-Conseils et, sauf pour lui d&#39;apporter preuve contraire, il renonce à les contester en
                    cas de litige. <br /> L&#39;acceptation de la présente politique de confidentialité suppose de
                    la part des Internautes qu&#39;ils jouissent de la capacité juridique nécessaire pour cela ou
                    qu’ils aient au moins 16 ans, ou à défaut qu&#39;ils en aient l&#39;autorisation d&#39;un
                    tuteur ou d&#39;un curateur s&#39;ils sont incapables, de leur représentant légal s&#39;ils ont
                    moins de 16 ans, ou encore qu&#39;ils soient titulaires d&#39;un mandat s&#39;ils agissent pour
                    le compte d&#39;une personne morale.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 4. DONNEES PERSONNELLES
                    </p>
                    Conformément au Règlement général sur la protection des données (RGPD) adopté par le Parlement
                    européen le 14 avril 2016 et à la législation nationale en vigueur, Bigup-Conseils vous fournit
                    les informations suivantes :
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.1 Identité du responsable du traitement
                    </p>
                    Le responsable de la collecte et des données traitées est Bigup-Conseils, SAS, dont le siège
                    social se situe au 7 rue de l’Industrie 92400 Courbevoie, inscrite au RCS de Nanterre sous le
                    numéro 884 060 617, contacts@bigup-conseils.com.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.2 Identité du Délégué à la protection des données
                    </p>
                    Le Délégué à la protection des données est Soukaina Chouari.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3 Collecte de données par Bigup-Conseils
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3.1 Données collectées
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3.1.1 Données collectées lors de la navigation
                    </p>
                    Lors de la navigation sur le Site, vous consentez à ce que Bigup-Conseils collecte les
                    informations relatives : <br />- à votre utilisation du Site . <br /> - à vos données de
                    localisation . <br /> - à vos données de connexion (horaires, pages consultées, adresse IP…).
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3.1.1 Données collectées lors de l’utilisation du formulaire de contact
                    </p>
                    L’utilisation du formulaire de contact par l’Internaute suppose la collecte par Bigup-Conseils
                    des données personnelles suivantes : <br />- Nom <br /> - Prénom <br /> - Adresse e-mail.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3.1.2 Données collectées lors de l’utilisation du formulaire de création d’un compte
                    </p>
                    L’utilisation du formulaire de création d’un compte par l’Internaute suppose la collecte par
                    Bigup-Conseils des données personnelles suivantes : <br />
                    - Nom <br />
                    - Prénom <br />
                    - Adresse e-mail <br />- Numéro de téléphone.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3.2 Finalités de la collecte de données personnelles
                    </p>
                    Les données collectées lors de la navigation font l&#39;objet d&#39;un traitement automatisé
                    ayant pour finalité de : <br /> - Assurer et améliorer la sécurité du Site <br />
                    - Développer, exploiter, améliorer, fournir et gérer le Site <br />
                    - Eviter toute activité illicite ou illégale <br />
                    - Faire respecter les conditions relatives à l’utilisation du Site. <br />
                    Les données collectées lors de l’utilisation du formulaire de contact font l’objet d’un
                    traitement automatisé ayant pour finalité de : <br />
                    - Vérifier l&#39;identité de l’Internaute <br />
                    - Communiquer avec l’Internaute <br />
                    - Adresser des informations et contacter les Internautes, y compris par e-mail, téléphone et
                    notification push. <br />
                    Les données collectées lors de l’utilisation du formulaire de création d’un compte font l’objet
                    d’un traitement automatisé ayant pour finalité de : <br />
                    - Communiquer avec l’Internaute <br />
                    - Procéder à l’inscription des Internautes <br />
                    - Développer, exploiter, améliorer, fournir et gérer le Site <br />- Adresser des informations
                    et contacter les Internautes, y compris par e-mail, téléphone et notification push.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3.3 Bases juridiques du traitement
                    </p>
                    Les données collectées lors de la navigation ont pour base juridique l’intérêt légitime de
                    Bigup-Conseils, à savoir réaliser une analyse des comportements sur le Site et obtenir une
                    sécurité et un fonctionnement améliorés du Site. S’agissant des données issues de
                    l’implantation de certains cookies, l’Internaute est invité à se référer à la politique cookies
                    de Bigup-Conseils
                    <a href="https://eventtobee.com/cookies" className="text-blue-600" target="_blank">
                      {" "}
                      d'ici
                    </a>
                    . <br />
                    Les données collectées lors de l’utilisation du formulaire de contact ont pour base juridique
                    le consentement des personnes concernées. <br />
                    Les données collectées lors de l’utilisation du formulaire de création d’un compte ont pour
                    base juridique le consentement des personnes concernées.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3.4 Destinataires internes des données
                    </p>
                    Les données collectées sont consultables uniquement par les dirigeants de Bigup-Conseils ainsi
                    que par le personnel chargé de la gestion du site internet et ne sont jamais rendues librement
                    visualisables par une personne physique tierce.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3.5 Durée de conservation des données personnelles
                    </p>
                    Les données personnelles collectées lors de la navigation sont conservées pendant une durée
                    raisonnable nécessaire à la bonne administration du Site et pour un maximum de 12 mois, ou
                    jusqu’au retrait du consentement des personnes concernées. <br />
                    Les données personnelles collectées lors de l’utilisation du formulaire contact, sont
                    conservées pendant une durée raisonnable nécessaire à la bonne gestion de la demande de
                    l’Internaute, et pour un maximum de 12 mois. <br />
                    Les données personnelles collectées lors de l’utilisation du formulaire de création d’un
                    compte, sont conservées pendant une durée raisonnable à la bonne gestion de la demande de
                    l’Internaute et tant que l’Internaute possédera un compte sur le Site. <br />A l’issue de
                    chacun de ces délais, Bigup-Conseils archivera ces données et les conservera le temps durant
                    lequel sa responsabilité pourra être mise en cause. Passés ce délai de conservation,
                    Bigup-Conseils s’engage à supprimer définitivement les données des personnes concernées.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3.6 Sécurité et confidentialité des données personnelles
                    </p>
                    Les données personnelles sont conservées dans des conditions sécurisées, selon les moyens
                    actuels de la technique, dans le respect des dispositions du Règlement général pour la
                    protection des données et de la législation nationale en vigueur.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.3.7 Minimisation des données personnelles
                    </p>
                    Bigup-Conseils peut également collecter et traiter toute donnée transmise volontairement par un
                    Internaute, notamment lors de l’envoi d’un email à l’adresse de contact. <br />
                    Bigup-Conseils oriente les Internautes autant que possible lorsqu’ils fournissent des données à
                    caractère personnel inutiles ou superflues. <br />
                    Bigup-Conseils s’engage à ne conserver et traiter que les données strictement nécessaires à ses
                    activités, et supprimera toute donnée reçue non utile dans les plus brefs délais.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.4 Respects des droits
                    </p>
                    Vous disposez des droits suivants concernant vos données personnelles, que vous pouvez exercer
                    en nous écrivant à notre adresse postale ou en adressant un email à l’adresse suivante :
                    contacts@bigup-conseils.com .
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.4.1 Droit d’information, d’accès et de communication des données
                    </p>
                    Vous avez la possibilité d’accéder aux données personnelles qui vous concernent. En raison de
                    l’obligation de sécurité et de confidentialité dans le traitement des données à caractère
                    personnel qui incombe à Bigup-Conseils votre demande sera uniquement traitée si vous rapportez
                    la preuve de votre identité, notamment par la production d’un scan de votre titre d’identité
                    valide (en cas de demande par email) ou d’une photocopie signée de votre titre d’identité
                    valide (en cas de demande adressée par écrit), tous deux accompagnés de la mention « j’atteste
                    sur l’honneur que la copie de cette pièce d’identité est conforme à l’original. Fait à … le …
                    », suivie de votre signature. Pour vous aider dans votre démarche, vous trouverez ici un modèle
                    de courrier élaboré par la Cnil.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.4.2 Droit de rectification, de suppression et droit à l’oubli des données
                    </p>
                    Vous avez la possibilité de demander la rectification, la mise à jour, le verrouillage ou
                    encore l’effacement de vos données personnelles qui peuvent s’avérer le cas échéant inexactes,
                    erronées, incomplètes ou obsolètes. <br />
                    Vous pouvez également définir des directives générales et particulières relatives au sort des
                    données à caractère personnel après votre décès. Le cas échéant, les héritiers d’une personne
                    décédée peuvent exiger de prendre en considération le décès de leur proche et/ou de procéder
                    aux mises à jour nécessaires. <br />
                    Pour vous aider dans votre démarche, vous trouverez ici un modèle de courrier élaboré par la
                    Cnil.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.4.3 Droit d’opposition au traitement de données
                    </p>
                    Vous avez la possibilité de vous opposer à un traitement de vos données personnelles. Pour se
                    faire, il vous conviendra d’adresser un email à l’adresse suivante :
                    contacts@bigup-conseils.com. Dans ce mail, vous faudra préciser les données que vous souhaitez
                    voir supprimer ainsi que les raisons justifiant cette demande, sauf cas de prospection
                    commerciale.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.4.4 Droit à la portabilité des données
                    </p>
                    Vous avez le droit de recevoir les données personnelles que vous nous avez fournies dans un
                    format transférable, ouvert et lisible.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.4.5 Droit à la limitation du traitement
                    </p>
                    Vous avez le droit de demander que le traitement de vos données personnelles par Bigup-
                    Conseils soit limité. Ainsi, vos données ne pourront qu’être conservées et non plus utilisées
                    par Bigup-Conseils.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.4.6 Retrait du consentement
                    </p>
                    Votre consentement est indispensable au traitement de vos données par Bigup-Conseils.
                    Toutefois, il vous est possible de retirer celui-ci à tout moment. Ce retrait conduira à la
                    suppression des données personnelles vous concernant. <br />
                    Les services nécessitant le traitement de vos données par Bigup-Conseils ne seront néanmoins
                    plus accessibles.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.4.7 Délais de réponse
                    </p>
                    Bigup-Conseils s’engage à répondre à votre demande d’accès, de rectification ou d’opposition ou
                    toute autre demande complémentaire d’informations dans un délai raisonnable qui ne saurait
                    dépasser 1 mois à compter de la réception de votre demande.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.4.8 Plainte auprès de l’autorité compétente
                    </p>
                    Si vous considérez que Bigup-Conseils ne respecte pas ses obligations au regard de vos
                    informations personnelles, vous pouvez adresser une plainte ou une demande auprès de l’autorité
                    compétente. En France, l’autorité compétente est la Cnil à laquelle vous pouvez adresser une
                    demande{" "}
                    <a href="https://www.cnil.fr/fr/plaintes/internet" className="text-blue-600" target="_blank">
                      {" "}
                      ici{" "}
                    </a>
                    .
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.5 Transfert des données collectées
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.5.1 Transfert à des partenaires
                    </p>
                    Bigup-Conseils vous informe que nous avons recours à des prestataires habilités pour faciliter
                    le recueil et le traitement des données que vous nous avez communiquées. Ces prestataires
                    peuvent être situés en dehors de l’Union Européenne et ont communication des données
                    recueillies sur le Site. <br />
                    Bigup-Conseils s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties
                    adéquates et du respect de conditions strictes en matière de confidentialité, d’usage et de
                    protection des données. <br /> L’Internaute consent à ce que les données collectées soient
                    transmises par Bigup-Conseils à ses partenaires et fassent l’objet d’un traitement par ces
                    partenaires dans le cadre des services tiers, à savoir :
                    <div className="pt-10">
                      <table className="text-left border table-auto">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head) => (
                              <th key={head} className="p-4 text-center bg-blue-400 border border-black border-y">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold leading-none opacity-70 dark:opacity-100"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {TABLE_ROWS.map(({ partenaire, paysDesinataire, traitement, garanties }, index) => {
                            const isLast = index === TABLE_ROWS.length;
                            const classes = isLast ? "p-4 border" : "p-4 border-b border-black";

                            return (
                              <tr key={partenaire}>
                                <td className={`border ${classes}`}>
                                  <div className="flex items-center gap-3">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                      {partenaire}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={`border ${classes}`}>
                                  <Typography variant="small" color="blue-gray" className="font-normal">
                                    {paysDesinataire}
                                  </Typography>
                                </td>
                                <td className={`border ${classes}`}>
                                  <Typography variant="small" color="blue-gray" className="font-normal">
                                    {traitement}
                                  </Typography>
                                </td>
                                <td className={`border ${classes}`}>
                                  <Typography variant="small" color="blue-gray" className="font-normal">
                                    <a href={garanties} target="_blank" className="text-blue-600">
                                      {" "}
                                      {garanties}{" "}
                                    </a>
                                  </Typography>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.5.2 Transfert sur réquisition ou décision judiciaire
                    </p>
                    L’Internaute consent également à ce que Bigup-Conseils communique les données collectées à
                    toute personne, sur réquisition d’une autorité étatique ou sur décision judiciaire.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      4.5.3 Transfert dans le cadre d&#39;une fusion ou d&#39;une acquisition
                    </p>
                    Si Bigup-Conseils est impliquée dans une fusion, une vente d&#39;actifs, une opération de
                    financement, une liquidation ou banqueroute ou dans une acquisition de tout ou partie de son
                    activité par une autre société, l’Internaute consent à ce que les données collectées soient
                    transmises par Bigup-Conseils à cette société et que cette société opère les traitements de
                    données personnelles visés dans la présente politique confidentialité au lieu et place de
                    Bigup- Conseils.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 5. PROPRIETE INTELLECTUELLE
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      5.1 Protection légale des Contenus du Site
                    </p>
                    Les Contenus du Site sont susceptibles d&#39;être protégés par le droit d&#39;auteur et le
                    droit des bases de données. Toute représentation, reproduction, traduction, adaptation ou
                    transformation, intégrale ou partielle, réalisée illégalement et sans le consentement de
                    Bigup-Conseils ou de ses ayants droit ou ayants cause constitue une violation des Livres I et
                    III du Code de la propriété intellectuelle et sera susceptible de donner lieu à des poursuites
                    judiciaires pour contrefaçon.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      5.2 Protection contractuelle des Contenus du Site
                    </p>
                    L&#39;Internaute s&#39;engage contractuellement à l&#39;égard de Bigup-Conseils à ne pas
                    utiliser, reproduire ou représenter, de quelque manière que ce soit, les Contenus du Site,
                    qu&#39;ils soient ou non protégés par un droit de propriété intellectuelle, à une autre fin que
                    celle de leur lecture par un robot ou un navigateur. Cette interdiction n&#39;est pas
                    applicable aux robots d&#39;indexation ayant pour seul objet de scanner le contenu du Site aux
                    fins d&#39;indexation.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 6. STIPULATIONS FINALES
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.1 Modifications
                    </p>
                    La présente Politique de confidentialité peut être modifiée à tout moment par Bigup-Conseils.
                    Les conditions applicables à l’Internaute sont celles en vigueur lors de sa connexion sur le
                    Site, toute nouvelle connexion au Site emportant acceptation le cas échéant des nouvelles
                    conditions.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.2 Entièreté
                    </p>
                    La nullité d&#39;une des clauses du présent contrat n&#39;entraînera pas la nullité des autres
                    clauses du contrat ou du contrat dans sa globalité, qui garderont leur plein effet et portée.
                    Dans une telle hypothèse, les parties devront dans la mesure du possible remplacer la
                    stipulation annulée par une stipulation valable correspondant à l&#39;esprit et à l&#39;objet
                    des présentes.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.3 Non-renonciation
                    </p>
                    L&#39;absence d&#39;exercice par Bigup-Conseils des droits qui lui sont reconnus par les
                    présentes ne pourra en aucun cas être interprétée comme une renonciation à faire valoir lesdits
                    droits.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">6.4 Langues</p>
                    Les présentes conditions sont proposées en français.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.5 Clauses abusives
                    </p>
                    Les stipulations des présentes conditions s&#39;appliquent sous réserve du respect des
                    dispositions impératives du Code de la consommation concernant les clauses abusives dans les
                    contrats conclus entre un professionnel et un consommateur.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 7. LITIGES
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      7.1 Droit applicable
                    </p>
                    La présente Politique de confidentialité est soumise à l&#39;application du droit français et
                    de la règlementation européenne, notamment le Règlement européen sur la protection des données.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">7.2 Litiges</p>
                    En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans
                    le cadre de l&#39;exécution des présentes conditions générales et dont la solution n’aura pu
                    être trouvée préalablement à l’amiable entre les parties devra être soumis à un médiateur
                    agréé.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      7.3 Arbitrage
                    </p>
                    Tout litige relatif au présent contrat ou en relation avec celui-ci sera tranché par voie
                    d’arbitrage conformément au règlement FastArbitre de l’Institut digital d’arbitrage et de
                    médiation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
