import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionTagsPosts from "./SectionTagsPosts";

const BlogByTagPage: React.FC = () => {
  return (
    <div className="relative overflow-hidden nc-BlogPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======= Section Tags ============= */}
      <div className="container relative">
        <SectionTagsPosts className="py-16 lg:py-28" />
      </div>
    </div>
  );
};

export default BlogByTagPage;
