import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { unavailable } from "./featureAPI";

const initialState = {
  status: "",
  loading: "",
  error: "",
  unavailables: [],
  unavailableDetail: {
    startDay: "",
    endDay: "",
    locationId: "",
  },
};

export const addUnavailable = createAsyncThunk("featureUnavailable/unavailable", async (data: any) => {
  return await unavailable.add(data);
});

export const removeUnavailable = createAsyncThunk("featureUnavailable/unavailable", async (data: any) => {
  return await unavailable.remove(data);
});

export const fetchUnavailableByLocation = createAsyncThunk(
  "featureUnavailable/fetchUnavailableByLocation",
  async (id: any) => {
    return await unavailable.getUnvailableByLocations(id);
  }
);

export const fetchUnvailables = createAsyncThunk("featureUnavailable/fetchUnvailables", async () => {
  return await unavailable.geAllUnvailables();
});

export const fetchUnvailablesById = createAsyncThunk(
  "featureUnavailable/fetchUnvailablesById",
  async (id: any) => {
    return await unavailable.getUnvailableById(id);
  }
);

export const updateUnvailable = createAsyncThunk(
  "featureUnavailable/editUnvailable",
  async ({ data, id }: { data: any; id: any }) => {
    return await unavailable.update(data, id);
  }
);

export const deleteUnvailable = createAsyncThunk("featureUnavailable/deleteUnvailable", async (id: any) => {
  return await unavailable.deleteUnvailable(id);
});

const unvailableSlice = createSlice({
  name: "featureUnavailable",
  initialState,
  reducers: {
    initialState(state) {
      state.status = "";
      state.loading = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUnavailable.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUnavailable.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addUnavailable.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchUnavailableByLocation.fulfilled, (state, action) => {
        state.unavailables = action.payload;
      })
      .addCase(fetchUnvailablesById.fulfilled, (state, action) => {
        state.unavailableDetail = action.payload;
      })
      .addCase(deleteUnvailable.fulfilled, (state, action) => {
        state.status = action.payload;
      })
      .addCase(updateUnvailable.pending, (state, action) => {
        state.loading = "pending";
        state.error = "";
      });
    builder.addCase(updateUnvailable.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.error = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.error = action.payload.error;
      }
    });
    builder.addCase(updateUnvailable.rejected, (state, action) => {
      state.loading = "failed";
    });
  },
});

export const {} = unvailableSlice.actions;
export default unvailableSlice.reducer;
