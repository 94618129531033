import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import MobileFooterSticky from "./(components)/MobileFooterSticky";
import { useAppSelector } from "hooks/hooks";
const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  let thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  const [ApiImages, setApiImages] = useState<any[]>([]);
  function extractIdFromPathname(pathname: any) {
    const parts = pathname.split("/");
    const idIndex = parts.indexOf("location") + 1;
    if (idIndex > 0 && idIndex < parts.length) {
      const id = parts[idIndex];
      const cleanedId = id.replace(/\//g, "");
      return cleanedId;
    } else {
      return null;
    }
  }
  function extractIdServiceFromPathname(pathname: any) {
    const parts = pathname.split("/");
    const idIndex = parts.indexOf("services") + 1;
    if (idIndex > 0 && idIndex < parts.length) {
      const id = parts[idIndex];
      const cleanedId = id.replace(/\//g, "");
      return cleanedId;
    } else {
      return null;
    }
  }
  const extractedId = extractIdFromPathname(thisPathname);
  const extractedServiceId = extractIdServiceFromPathname(thisPathname);
  let locationState = useAppSelector((state) => state.featureLocation.locationDetail);
  let serviceState = useAppSelector((state) => state.service);
  useEffect(() => {
    const fetchData = async () => {
      if (extractedId && locationState) {
        const locationImages = locationState.images?.map((url: any) => ({ url })) || [];
        setApiImages(locationImages);
      }
      if (extractedServiceId && serviceState) {
        const serviceImages = serviceState.serviceData?.images?.map((url: any) => ({ url })) || [];
        setApiImages(serviceImages);
      }
    };

    fetchData();
  }, [extractedId, extractedServiceId, locationState, serviceState]);

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${extractLocation(thisPathname)}?${params.toString()}`);
  };
  function extractLocation(pathname: string) {
    const match = pathname.match(/\/(location|services)\/(\d+)\/?/);
    if (match && match[2]) {
      return `/${match[1]}/${match[2]}`;
    } else {
      return pathname;
    }
  }
  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={ApiImages}
      />
      <div className="container ListingDetailPage__content">{children}</div>
    </div>
  );
};

export default DetailPagetLayout;
