import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import LocationHomeGallerySlider from "components/LocationHomeGallerySlider/LocationHomeGallerySlider";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import StartRatingHome from "components/StartRating/StartRatingHome";
import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import { AuthContext } from "context/appContext";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import {
  addToFavoris,
  checkFavoriByClientId,
  deleteLocationFromFavoris,
  getFavorisByClient,
} from "redux/features/locations/locationSlice";
export interface StayCardProps {
  className?: string;
  data?: any;
  reviews?: any;
  size?: "default" | "small";
  galleryImgs?: string | string[];
  showLoginModal?: boolean;
  setShowLoginModal?: React.Dispatch<React.SetStateAction<boolean>>;
  minPrice?: number;
  priceCount?: number;
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
  galleryImgs,
  setShowLoginModal,
  minPrice,
  priceCount,
}) => {
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const locationState = useAppSelector((state) => state.featureLocation);
  const isLiked = useAppSelector((state) => {
    const likedLocation = state?.featureLocation.likedLocation || [];
    const locationId = data?.id;
    if (Array.isArray(likedLocation) && likedLocation.length > 0 && locationId) {
      const foundItem = likedLocation.find((item: any) => item.locationId === locationId);
      return !!foundItem;
    }
    return false;
  });
  const handleClickLike = async () => {
    if (!profilId) {
      if (setShowLoginModal) {
        setShowLoginModal(true);
      }
    }
    if (id && profilId && locationState.likedLocation) {
      if (!isLiked) {
        await dispatch(addToFavoris({ locationId: id, clientId: profilId }));
      } else {
        await dispatch(deleteLocationFromFavoris({ locationId: id, clientId: profilId }));
      }
      await dispatch(getFavorisByClient({ clientId: profilId }));
    }
  };

  useEffect(() => {
    if (profilId) {
      dispatch(getFavorisByClient({ clientId: profilId }));
    }
  }, [profilId]);

  useEffect(() => {
    if (id && profilId && locationState.likedLocation) {
      dispatch(checkFavoriByClientId({ locationId: id, clientId: profilId }));
    }
  }, []);

  const {
    codePostale,
    title,
    saleOff,
    isAds,

    city,
    visitor,
    CategoryLocation,
    id,

    commentCount,
    likesAverage,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full text-gray-900">
        <LocationHomeGallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={galleryImgs}
          href={`/location/${id}`}
        />
        <BtnLikeIcon
          loading={isLoading}
          isLiked={isLiked}
          onClick={handleClickLike}
          className="absolute right-3 top-3 z-[1]"
        />
        {saleOff && <SaleOffBadge className="absolute top-3 left-3" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <Badge name={CategoryLocation?.designation} color="green" />

          <span className="text-sm text-neutral-500 dark:text-neutral-400"> · {visitor} Personnes</span>
          <div className="flex items-center space-x-2">
            <h2 className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"}`}>
              <span className="line-clamp-1"> {title} </span>
            </h2>
          </div>
          <div className="flex items-center space-x-2 text-sm text-neutral-500 dark:text-neutral-400">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">
              {" "}
              {city} , {codePostale}
            </span>
          </div>
        </div>
        <div className="border-b w-14 border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex items-center justify-between">
          <span className="text-base font-semibold">
            {priceCount && priceCount > 1 ? `À partir de ${minPrice} €` : `${minPrice} €`}

            {size === "default" && (
              <span className="text-sm font-normal text-neutral-500 dark:text-neutral-400">/Heure </span>
            )}
          </span>

          <StartRatingHome point={likesAverage} reviewCount={commentCount} locId={id} />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`overflow-hidden relative bg-white rounded-2xl border transition-shadow nc-StayCard group dark:bg-neutral-900 border-neutral-100 dark:border-neutral-800 will-change-transform hover:shadow-xl ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={`/location/${id}`}>{renderContent()}</Link>
    </div>
  );
};

export default StayCard;
