import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import DeleteModal from "components/DeleteModal/DeleteModal";
import { itemPerDashborad } from "data/constants";
import { Video } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { deleteVideo, getAllVideos, initStates } from "redux/features/Admin/videos/videoSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";
import DashboardMenu from "../../DashboardPage/DashboardMenu";

export interface ListCategorysPageProps {
  className?: string;
}

const ListVideosPage: FC<ListCategorysPageProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const videos = useAppSelector((state) => state?.video?.allVideos);
  const filteredVideos = Array.isArray(videos) && videos.filter((video) => video);
  useEffect(() => {
    dispatch(getAllVideos());
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashborad;

  const paginatedVideos = Array.isArray(filteredVideos)
    ? filteredVideos.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const length = Array.isArray(filteredVideos) ? filteredVideos.length : 0;
  const totalPages = Array.isArray(filteredVideos) ? Math.ceil(filteredVideos.length / itemsPerPage) : 0;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

  const handleDeleteModalOpen = (video: Video) => {
    setSelectedVideo(video);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedVideo && selectedVideo?.id !== undefined) {
      await dispatch(deleteVideo(selectedVideo?.id));
      setDeleteModalOpen(false);
      await dispatch(getAllVideos());
    }
  };

  const handleEyeIconClick = (video: any) => {
    setSelectedVideo(video);
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    dispatch(initStates());
  }, []);

  const paginatedSearchedVideos = Array.isArray(videos)
    ? videos
        .filter((item) => {
          const name = item.title || "";

          return name.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const displayedvideos = searchTerm === "" ? paginatedVideos : paginatedSearchedVideos;

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="flex flex-col">
          <div className="flex items-center justify-between mb-4">
            <ButtonPrimary href="/dashboard/add-videos">Ajouter un vidéo</ButtonPrimary>
            <div className="relative flex">
              <Input
                type="text"
                placeholder="Rechercher par nom"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-500 transform -translate-y-1/2 top-1/2 right-3" />
            </div>
          </div>
          {deleteModalOpen && (
            <DeleteModal
              open={deleteModalOpen}
              handleClose={() => setDeleteModalOpen(false)}
              handleDelete={handleDeleteConfirm}
              category={{
                id: selectedVideo?.id || 0,
                designation: selectedVideo?.title || "",
              }}
            />
          )}

          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-sm font-light text-center">
                  <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="px-6 py-4 ">
                        Image
                      </th>

                      <th scope="col" className="px-6 py-4 ">
                        Titre
                      </th>

                      <th scope="col" className="px-6 py-4 ">
                        Youtube Id
                      </th>

                      <th scope="col" className="px-6 py-4 ">
                        Modifier
                      </th>

                      <th scope="col" className="px-6 py-4 ">
                        Supprimer
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {searchTerm === ""
                      ? paginatedVideos.map((item, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="w-10 px-6 py-4 font-medium whitespace-nowrap">
                              <img src={`${process.env.REACT_APP_CLOUD_FRONT + item?.thumbnail}`} alt="" />
                            </td>

                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item.title}</td>

                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item.youtubeId}</td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <Link to={`/dashboard/edit-videos/${item.id}`}>
                                <i className="text-2xl las la-edit"></i>{" "}
                              </Link>{" "}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-trash-alt"
                                onClick={() => handleDeleteModalOpen(item)}
                              ></i>
                            </td>
                          </tr>
                        ))
                      : displayedvideos.map((item, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="w-10 px-6 py-4 font-medium whitespace-nowrap">
                              <img src={`${process.env.REACT_APP_CLOUD_FRONT + item?.thumbnail}`} alt="" />
                            </td>

                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item.title}</td>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item.youtubeId}</td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <Link to={`/dashboard/edit-videos/${item.id}`}>
                                <i className="text-2xl las la-edit"></i>{" "}
                              </Link>{" "}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-trash-alt"
                                onClick={() => handleDeleteModalOpen(item)}
                              ></i>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <div className="flex justify-end ">
                  <span className="text-sm text-gray-700">{length} élément</span>
                </div>
                <div className="flex items-center justify-center mt-11">
                  {typeof totalPages === "number" && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardMenu>
    </div>
  );
};

export default ListVideosPage;
