import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "@material-tailwind/react";
import Label from "components/Label/Label";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { resetLoading, resetPassword } from "redux/features/auth/authSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { z } from "zod";
import CommonLayout from "./AgentCommonLayout";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const formSchema = z
  .object({
    email: z.string().email({ message: "Veuillez entrer une adresse email valide." }),
    oldPassword: z.string().min(1, { message: "Le mot de passe actuel est obligatoire." }),
    newPassword: z
      .string()
      .regex(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?À-ÿ]).{8,26}$/,
        "Le mot de passe doit contenir au moins 1 lettre minuscule, 1 lettre majuscule, 1 chiffre et 1 caractère spécial."
      ),
    passwordConfirm: z.string(),
  })
  .refine((data) => data.newPassword === data.passwordConfirm, {
    message: "Le mode de passe ne correspondent pas.",
    path: ["passwordConfirm"],
  });

const AgentAccountPass = () => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state?.auth?.user);
  const navigate = useNavigate();
  const loadingState = useAppSelector((state) => state?.auth);
  const [errorForm, setErrorForm] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { email: userState?.email },
    mode: "onSubmit",
  });
  useEffect(() => {
    const updatedRequirements = {
      minLength: getValues("newPassword")?.length >= 8,
      hasLowercase: /[a-z]/.test(getValues("newPassword")),
      hasUppercase: /[A-Z]/.test(getValues("newPassword")),
      hasNumber: /[0-9]/.test(getValues("newPassword")),
      hasSpecialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?À-ÿ]/.test(getValues("newPassword")),
    };

    setPasswordRequirements(updatedRequirements);
  }, [watch("newPassword")]);

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data, event) => {
    if (data.newPassword !== data.passwordConfirm) {
      setValue("passwordConfirm", "", {
        shouldValidate: true,
      });
      return;
    }
    const result = await dispatch(
      resetPassword({
        ...data,
      })
    );

    if (result.payload && result.payload.status === "VALIDATION" && result.payload.error?.length > 0) {
      setErrorForm(true);
    } else {
      navigate("/prestataire");
      dispatch(resetLoading());
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    dispatch(resetLoading());
  }, []);
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {errorForm && (
            <Alert color="red" className="text-xs">
              {"Vérifier votre ancien mot de passe "}
            </Alert>
          )}
          <h2 className="text-3xl font-semibold">Mettez à jour votre mot de passe.</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
            <div className="max-w-xl space-y-6 ">
              <div className="relative">
                <Label>Mot de passe actuel</Label>
                <div className="relative">
                  <Input
                    placeholder="Mot de passe actuel"
                    type={showPassword ? "text" : "password"}
                    className="mt-1.5"
                    {...register("oldPassword")}
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
              {errors.oldPassword?.message && (
                <div className="mt-1 text-xs text-red-500">{errors.oldPassword?.message}</div>
              )}
              <div className="relative">
                <Label>Nouveau mot de passe</Label>
                <div className="relative">
                  <Input
                    placeholder="Nouveau mot de passe "
                    type={showNewPassword ? "text" : "password"}
                    className="mt-1.5"
                    {...register("newPassword")}
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={toggleNewPasswordVisibility}
                  >
                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>

              <div className="flex items-center">
                {passwordRequirements.minLength ? (
                  <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
                ) : (
                  <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
                )}
                <span className={`mt-1 text-sm ${passwordRequirements.minLength ?? "text-green-700"}`}>
                  8 caractères minimum
                </span>
              </div>

              <div className="flex items-center">
                {passwordRequirements.hasLowercase ? (
                  <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
                ) : (
                  <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
                )}
                <span className={`mt-1 text-sm ${passwordRequirements.hasLowercase ?? "text-green-700"}`}>
                  1 minuscule
                </span>
              </div>

              <div className="flex items-center">
                {passwordRequirements.hasUppercase ? (
                  <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
                ) : (
                  <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
                )}
                <span className={`mt-1 text-sm ${passwordRequirements.hasUppercase ?? "text-green-700"}`}>
                  1 majuscule
                </span>
              </div>

              <div className="flex items-center">
                {passwordRequirements.hasNumber ? (
                  <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
                ) : (
                  <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
                )}
                <span className={`mt-1 text-sm ${passwordRequirements.hasNumber ?? "text-green-700"}`}>
                  1 chiffre
                </span>
              </div>

              <div className="flex items-center">
                {passwordRequirements.hasSpecialChar ? (
                  <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
                ) : (
                  <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
                )}
                <span className={`mt-1 text-sm ${passwordRequirements.hasSpecialChar ?? "text-green-700"}`}>
                  1 caractère spécial
                </span>
              </div>
              {errors.newPassword?.message && (
                <div className="mt-1 text-xs text-red-500">{errors.newPassword?.message}</div>
              )}
              <div className="relative">
                <Label>Confirmer le mot de passe</Label>
                <div className="relative">
                  <Input
                    placeholder="Confirmé votre mot de passe "
                    type={showConfirmPassword ? "text" : "password"}
                    className="mt-1.5"
                    {...register("passwordConfirm")}
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
              {errors.passwordConfirm?.message && (
                <div className="mt-1 text-xs text-red-500">{errors.passwordConfirm?.message}</div>
              )}
            </div>

            <div className="pt-2">
              <ButtonPrimary type="submit" className="w-full md:w-1/6">
                Mise à jour
              </ButtonPrimary>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AgentAccountPass;
