import { FC, useState } from "react";
import Heading2 from "components/Heading/Heading2";
import FieldCard from "components/ServiceCard/fieldCard";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionGridFilterCardProps {
  className?: string;
  fields?: any;
  feature?: any;
}

export interface Field {
  designation: string;
  id: number;
  images: any;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({ className = "", fields, feature }) => {
  const [fieldsToShow, setFieldsToShow] = useState<number>(8);
  const handleShowMore = () => {
    setFieldsToShow((prevEventsToShow) => prevEventsToShow + 4);
  };

  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
      <Heading2 heading="" />
      <br />
      <div
        className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        style={{ marginTop: "-130px" }}
      >
        {fields &&
          fields.slice(0, fieldsToShow).map((event: any, index: any) => (
            <div key={index}>
              <FieldCard key={event.id} data={event} galleryImgs={event.images} feature={feature} />
            </div>
          ))}
      </div>

      {fields?.length === 0 && (
        <div className="flex items-center justify-center">
          <p className="font-semibold text">Aucun type de prestation disponible</p>
        </div>
      )}
      <br />
      <div className="flex items-center justify-center ">
        {fields?.length > 8 && <ButtonSecondary onClick={handleShowMore}>Voir Plus</ButtonSecondary>}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
