import { Card, CardBody, CardFooter, CardHeader, Typography } from "@material-tailwind/react";
import DashboardMenu from "containers/Admin/DashboardPage/DashboardMenu";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "context/appContext";
import { Alert } from "shared/Alert/Alert";
import { editUser, getUser } from "redux/features/auth/authSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const AccountEdit = () => {
  const authContext = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let creatorState = useAppSelector((state) => state.auth.creator);
  const [shownewPasswordConfirm, setShownewPasswordConfirm] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await dispatch(getUser(id));
      }
    };

    fetchData();
  }, [id]);
  const togglenewPasswordConfirmVisibility = () => {
    setShownewPasswordConfirm(!shownewPasswordConfirm);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  useEffect(() => {
    if (creatorState) {
      setValue("email", creatorState.email || "");
    }
  }, [creatorState]);
  const formSchema = z.object({
    email: z.string().min(1, { message: "email est obligatoire." }),
    newPasswordConfirm: z.string().min(8, { message: " mots de passes ne sont pas identiques. " }),
    newPassword: z.string().min(8, { message: "nouveau mot de passe est obligatoire. " }),
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    if (data.newPassword !== data.newPasswordConfirm) {
      setValue("newPasswordConfirm", "", {
        shouldValidate: true,
      });
      return;
    }
    await dispatch(
      editUser({
        ...data,
      })
    );
    setShowAlert(true);
    reset();
    setTimeout(() => {
      setShowAlert(false);
      navigate("/dashboard/creator-list");
    }, 2000);
  };

  return (
    <div>
      <DashboardMenu>
        <div className="space-y-6 sm:space-y-8">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
            <Card className="mx-auto w-full max-w-[24rem]">
              <CardHeader className="grid mb-4 h-28 place-items-center ">Modifier Rédacteur</CardHeader>
              {showAlert && (
                <Alert type="success" containerClassName="text-xs">
                  modifiée avec succès !
                </Alert>
              )}
              <CardBody className="flex flex-col gap-4">
                <span className="text-neutral-800 dark:text-neutral-200">Adresse Email *</span>
                <Input type="email" className="mt-1" {...register("email")} />
                {errors.email?.message && <div className="text-xs text-red-500 ">{errors.email?.message}</div>}
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">Nouveau mot de passe *</span>
                  <div className="relative ">
                    <Input
                      type={showNewPassword ? "text" : "password"}
                      placeholder="nouveau mot de passe"
                      className={`mt-1 ${errors.newPassword?.message ? "border-red-500" : ""}`}
                      {...register("newPassword")}
                    />
                    <span
                      className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                      onClick={toggleNewPasswordVisibility}
                    >
                      {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {errors.newPassword?.message && (
                    <div className="text-xs text-red-500">{errors.newPassword?.message}</div>
                  )}
                </label>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">Confirmation de mot de passe *</span>
                  <div className="relative ">
                    <Input
                      type={shownewPasswordConfirm ? "text" : "password"}
                      placeholder="confirmation de mot de passe"
                      className={`mt-1 ${errors.newPasswordConfirm?.message ? "border-red-500" : ""}`}
                      {...register("newPasswordConfirm")}
                    />
                    <span
                      className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                      onClick={togglenewPasswordConfirmVisibility}
                    >
                      {shownewPasswordConfirm ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {errors.newPasswordConfirm?.message && (
                    <div className="text-xs text-red-500">{errors.newPasswordConfirm?.message}</div>
                  )}
                </label>
              </CardBody>
              <CardFooter className="pt-0">
                <Typography variant="small" className="flex justify-center mt-6">
                  <ButtonPrimary type="submit">Mise à jour</ButtonPrimary>
                </Typography>
              </CardFooter>
            </Card>
          </form>
        </div>
      </DashboardMenu>
    </div>
  );
};
export default AccountEdit;
