import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { FC } from "react";
import { Helmet } from "react-helmet";
import SectionHero from "./SectionHero";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  const image = `static-files/qui_somme_nous.jpg`;
  return (
    <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id="PageAbout">
      <Helmet>
        <title>Event To Bee - À propos </title>
        <meta
          name="description"
          content="Découvrez l'histoire d'Event To Bee, votre plateforme de location événementielle, et comment nous vous aidons à trouver des espaces uniques pour vos événements spéciaux."
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 space-y-16 lg:py-28 lg:space-y-28">
        <SectionHero
          imgHeight="460px"
          imgWidth="1000px"
          rightImg={image}
          heading="👋 Event To Bee."
          btnText=""
          subHeading="Event To Bee est bien plus qu'une simple plateforme de location événementielle ; c'est une passerelle vers des expériences uniques et mémorables. Notre mission est de faciliter la recherche d'espaces atypiques et authentiques pour tous types d'événements, qu'il s'agisse d'un anniversaire, d'un cocktail élégant, d'un shooting photo  ou simplement d'une réunion entre amis.

          Que vous soyez à la recherche d'un loft urbain, d'une villa avec piscine, d'un jardin, d'une terrasse ou même d'un rooftop avec vue imprenable, Event To Bee vous connecte avec des hôtes particuliers ou professionnels désireux de partager leurs lieux uniques pour des occasions spéciales.
          
          Notre plateforme offre aux hôtes  la liberté de choisir les conditions de location qui correspondent parfaitement à leurs besoins, que ce soit en termes de capacité d'accueil, de type d'évènement ou de tarifs.
          
          Nous offrons également une sélection de prestataires, soigneusement choisis pour compléter votre événement avec des services professionnels et de qualité.
          
          En résumé, Event To Bee vous offre une solution complète pour faire de votre événement un succès inoubliable.
          
          Laissez-nous vous guider dans la recherche du lieu idéal et des prestataires de confiance, afin que vous puissiez vous concentrer sur la création de moments magiques et sur la réalisation de souvenirs précieux avec vos proches."
        />
      </div>
    </div>
  );
};

export default PageAbout;
