import React from "react";

import { Dialog, DialogBody, DialogFooter, DialogHeader, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface DeleteModalProps {
  open: boolean;
  reclamationData: any;
  handleClose?: () => void;
}
const TransactionDetailsPage: React.FC<DeleteModalProps> = ({ open, handleClose, reclamationData }) => {
  const [openx, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Dialog open={open} handler={handleOpen} className="w-11/12 dark:bg-gray-800">
        <DialogHeader>
          <Typography variant="h5" color="blue-gray" className="text-center dark:text-white">
            Information détaillée de reclamation
          </Typography>
        </DialogHeader>
        <DialogBody divider className="grid gap-4 place-items-center">
          <Typography className="font-normal text-center">
            <div>
              <Label className="font-semibold">Nom :</Label>
              <span className="dark:text-white"> {reclamationData?.nom} </span>
            </div>

            <div>
              <Label className="font-semibold">E-mail :</Label>
              <span className="dark:text-white"> {reclamationData?.email} </span>
            </div>

            <div>
              <Label className="font-semibold">Message :</Label>
              <span
                className="dark:text-white"
                style={{
                  display: reclamationData.message.length > 30 ? "block" : "",
                  overflowY: "scroll",
                  maxWidth: "600px",
                }}
              >
                {reclamationData?.message}{" "}
                {reclamationData.message.length > 30 && (
                  <div>
                    <br />
                  </div>
                )}{" "}
              </span>
            </div>
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <ButtonSecondary onClick={handleClose} className="dark:bg-gray-800 dark:text-white">
            Quitter
          </ButtonSecondary>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default TransactionDetailsPage;
