import { FC } from "react";
import { Link } from "react-router-dom";
import GallerySliderField from "components/GallerySlider/GallerySliderField";

export interface ServiceCardProps {
  className?: any;
  data?: any;
  size?: "default" | "small";
  galleryImgs?: string | string[];
  feature?: string;
}

const FieldCard: FC<ServiceCardProps> = ({ size = "default", className = "", data, feature }) => {
  if (!data) {
    return null;
  }
  const { designation, id } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySliderField
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3"
          galleryImgs={data?.images || []}
          href={`${feature + designation?.replace(/\s+/g, "-")?.toLowerCase()}`}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="">{designation}</span>
        </div>
        <div className="border-b w-14 border-neutral-100 dark:border-neutral-800"></div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={`${feature + designation?.replace(/\s+/g, "-")?.toLowerCase()}`}>{renderContent()}</Link>
    </div>
  );
};

export default FieldCard;
