import React, { FC, useContext, useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon, LockClosedIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { Accordion, Card, List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";

import { Menu, MenuHandler, MenuList, MenuItem, Button } from "@material-tailwind/react";
import { FaHandshake } from "react-icons/fa";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { AuthContext } from "context/appContext";
import { ProfilService } from "data/types";

export interface AgentCommonLayoutProps {
  children?: React.ReactNode;
  clientName?: string;
  id?: string;
}

const AgentCommonLayout: FC<AgentCommonLayoutProps> = ({ children }) => {
  const [open, setOpen] = React.useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const isActivePath = (path: string) => location.pathname === path;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1023);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  let profile = authContext.user?.profil as ProfilService;

  return (
    <div className="flex flex-col lg:flex-row dark:bg-neutral-900">
      {isMobile ? (
        <Menu
          animate={{
            mount: { y: 0 },
            unmount: { y: 25 },
          }}
        >
          <MenuHandler>
            <Button className="dark:text-dark">
              {" "}
              <div>
                <i className="mt-1 text-xl las la-bars"></i>
              </div>{" "}
              &nbsp; Dashboard
            </Button>
          </MenuHandler>
          <MenuList>
            <MenuItem className="flex items-center">
              <Link to="/prestataire" className="flex items-center">
                <UserCircleIcon className="h-5 mr-2 w-9" />
                {profile?.designation}
              </Link>
            </MenuItem>
            <MenuItem className="flex items-center">
              <Link to="/agent-password" className="flex items-center">
                <LockClosedIcon className="h-5 mr-2 w-9" />
                Mot de passe
              </Link>
            </MenuItem>

            <MenuItem className="flex items-center">
              <Link to="/prestataire/services-list" className="flex items-center">
                <HiOutlineSquare3Stack3D className="h-5 mr-2 w-9" />
                Mes prestations
              </Link>
            </MenuItem>

            <MenuItem className="flex items-center">
              <Link to="/prestataire/pricing" className="flex items-center">
                <EnvelopeIcon className="h-5 mr-2 w-9" />
                Demandes de devis
              </Link>
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <div className="p-6 border-r lg:w-1/4 border-neutral-200 dark:border-neutral-700">
          <Card className="h-full lg:h-[calc(100vh-2rem)] w-full max-w-[20rem] lg:max-w-none p-4 shadow-xl shadow-blue-gray-900/5 dark:bg-gray-800  dark:text-white">
            <div className="p-4 mb-2">
              <Typography variant="h5" color="blue-gray">
                Bienvenue
              </Typography>
            </div>
            <List>
              <Accordion
                open={open === 1}
                icon={
                  <ChevronDownIcon
                    strokeWidth={2.5}
                    className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                  />
                }
              >
                <ListItem className={isActivePath("/prestataire") ? "font-bold text-eventToBee" : ""}>
                  <ListItemPrefix>
                    <UserCircleIcon className="h-5 w-9" />
                  </ListItemPrefix>
                  <Link to="/prestataire">Profil</Link>
                </ListItem>

                <ListItem className={isActivePath("/agent-password") ? "font-bold text-eventToBee" : ""}>
                  <ListItemPrefix>
                    <LockClosedIcon className="h-5 w-9" />
                  </ListItemPrefix>
                  <Link to="/agent-password ">Mot de passe</Link>
                </ListItem>

                <ListItem
                  className={isActivePath("/prestataire/services-list") ? "font-bold text-eventToBee" : ""}
                >
                  <ListItemPrefix>
                    <HiOutlineSquare3Stack3D className="h-5 w-9" />
                  </ListItemPrefix>
                  <Link to="/prestataire/services-list">Mes prestations </Link>
                </ListItem>
                <ListItem className={isActivePath("/prestataire/pricing") ? "font-bold text-eventToBee" : ""}>
                  <ListItemPrefix>
                    <EnvelopeIcon className="h-5 w-9" />
                  </ListItemPrefix>
                  <Link to="/prestataire/pricing">Demandes de devis</Link>
                </ListItem>
              </Accordion>
            </List>
          </Card>
        </div>
      )}
      <div className="container pb-24 pt-14 sm:pt-20 lg:pb-32">{children}</div>
    </div>
  );
};

export default AgentCommonLayout;
