import { AuthContext } from "context/appContext";
import { Location } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getLocationByUserId, resetValues } from "redux/features/location/locationSlice";
import Pagination from "shared/Pagination/Pagination";
import CommonLayout from "../AccountPage/CommonLayout";
import "../../../styles/__card.scss";
import { itemPerDashboradClient } from "data/constants";
import homeIcon from "../../../images/home-icon.jpg";

export interface NonCompletedLocationsProps {
  className?: string;
}

const UncompletedLocations: FC<NonCompletedLocationsProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;

  const dispatch = useAppDispatch();
  const locationStates = useAppSelector((state) => state.location?.locationData || []) as Location[];
  const filteredLocations = Array.isArray(locationStates)
    ? locationStates.filter((location) => location?.validation !== "approuvé")
    : [];

  useEffect(() => {
    if (profilId) {
      dispatch(getLocationByUserId(String(profilId)));
    }
  }, [profilId]);

  useEffect(() => {
    dispatch(resetValues());
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashboradClient;

  const paginatedLocations = filteredLocations?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const length = filteredLocations?.length;
  const totalPages = Math.ceil(filteredLocations?.length / itemsPerPage);

  return (
    <CommonLayout>
      <div className={`w-full rounded-md ${className}`}>
        <div className="flex justify-between items-center pb-1">
          <div>
            <h2 className="font-semibold text-gray-600 dark:text-white">Mes espaces</h2>
          </div>
        </div>

        <div>
          <div className="overflow-x-auto px-4 py-4 -mx-4 sm:-mx-8 sm:px-8">
            <div className="inline-block overflow-hidden min-w-full rounded-lg shadow">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                      Images
                    </th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                      Titre
                    </th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                      Status
                    </th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                      À compléter
                    </th>

                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedLocations.map((location) => (
                    <tr key={location?.id}>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img
                              className="w-full h-full rounded-full"
                              src={
                                location?.images && location?.images.length > 0
                                  ? `${process.env.REACT_APP_CLOUD_FRONT}${location?.images[0]}`
                                  : homeIcon
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 pr-4 text-sm bg-white border-b border-gray-200">
                        <p className="text-gray-900 whitespace-no-wrap">{location?.title}</p>
                      </td>
                      <td className="px-5 py-5 pl-6 text-sm bg-white border-b border-gray-200">
                        <p className="text-gray-900 whitespace-no-wrap">{location?.validation}</p>
                      </td>

                      {/* GetbyID */}

                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        <span className="inline-block relative px-3 py-1 font-semibold leading-tight text-green-900">
                          <span
                            aria-hidden
                            className={`absolute inset-0 ml-4 bg-green-200 rounded-full opacity-50 ${
                              location?.validation === "en cours" ? "bg-red-900" : ""
                            }`}
                          ></span>

                          <span className="relative ml-4">
                            {location?.validation === "en cours" ? (
                              <button className="text-xl las la-check-circle" disabled></button>
                            ) : (
                              <>
                                {(location?.validation === "bloqué" || location?.validation === "en attente") && (
                                  <Link to={`/client/location/completed/${location.id}`}>
                                    <button className="text-xl las la-pen"></button>
                                  </Link>
                                )}

                                {location?.validation === "brouillon" && (
                                  <Link to={`/update-location/${location.id}`}>
                                    <button className="text-xl las la-pen"></button>
                                  </Link>
                                )}
                              </>
                            )}
                          </span>
                        </span>
                      </td>

                      {/* Delete */}
                      <td className="px-5 py-5 text-sm bg-white">
                        <span className="inline-block relative px-3 py-1 font-semibold text-red-900 leading-tigh">
                          <span aria-hidden className="absolute inset-0 bg-red-200 rounded-full opacity-50"></span>
                          <span className="relative">
                            <i className="text-xl cursor-pointer las la-trash-alt"></i>
                          </span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-center items-center mt-16">
            {typeof totalPages === "number" && (
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            )}
          </div>
        </div>
        {paginatedLocations.length === 0 && (
          <div className="flex justify-center items-center mt-16">
            <p>Pas d'espaces , ajouter votre espace</p>
          </div>
        )}
      </div>
    </CommonLayout>
  );
};

export default UncompletedLocations;
