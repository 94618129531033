import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { signUpService } from "./signUpAPI";

interface Fields {
  id: number;
  designation: string;
}

interface UserState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  roleType: string[];

  fields: Fields[];

  signUpError: string;
  otpError: string;

  error: SerializedError | "";

  totalSteps: number;
  currentStep: number;

  formData?: {
    login: string;
    email: string;
    password: string;
    role: string;
  };

  ProfileClientData?: {
    name: string;
    lastName: string;
    identityNumber: string;
    phone: string;
    client_url: File;
  };

  ProfileServiceData?: {
    designation: string;
    category: string;
    link: string;
    phone: string;
    region: string;
    description: string;
    contact: string;
    facebook: string;
    youtube: string;
    instagram: string;
    fields: string[];
    availability: string[];
    service_url: File;
  };
}

const initialState: UserState = {
  loading: "idle",
  roleType: ["client", "service", "owner"],
  fields: [],
  signUpError: "",
  otpError: "",
  error: "",
  totalSteps: 1,
  currentStep: 1,
  formData: undefined,
  ProfileClientData: undefined,
  ProfileServiceData: undefined,
};

export const signUp = createAsyncThunk("register", async (data: any) => {
  return await signUpService.register(data);
});

export const registerCreator = createAsyncThunk("registerCreator", async (data: any) => {
  return await signUpService.registerCreator(data);
});

export const updateProfileClient = createAsyncThunk("updateProfileClient", async (data: any) => {
  return await signUpService.updateProfileClient(data);
});

export const updateProfileService = createAsyncThunk("updateProfileService", async (data: any) => {
  return await signUpService.updateProfileService(data);
});

export const ServiceProfileUpdate = createAsyncThunk(
  "updateServiceProfile",
  async ({ data, id }: { data: any; id: any }) => {
    return await signUpService.ServiceProfileUpdate(data, id);
  }
);

export const ClientProfileUpdate = createAsyncThunk(
  "updateClientProfile",
  async ({ data, id }: { data: any; id: any }) => {
    return await signUpService.ClientProfileUpdate(data, id);
  }
);

export const getFields = createAsyncThunk("getFields", async () => {
  return await signUpService.getFields();
});

export const signUpSlice = createSlice({
  name: "SignUp",
  initialState,
  reducers: {
    initState(state) {
      state = {
        loading: "idle",
        roleType: ["client", "service"],
        fields: [],
        signUpError: "",
        otpError: "",
        error: "",
        totalSteps: 1,
        currentStep: 1,
        formData: undefined,
        ProfileClientData: undefined,
        ProfileServiceData: undefined,
      };
    },
    resetValues(state) {
      state.loading = "idle";
      state.roleType = ["client", "service"];
      state.fields = [];
      state.signUpError = "";
      state.error = "";
      state.totalSteps = 1;
      state.currentStep = 1;
      state.formData = undefined;
      state.ProfileClientData = undefined;
      state.ProfileServiceData = undefined;
    },
    setVerificationMailCurrentStep(state) {
      state.currentStep = 2;
    },
    setLoginCurrentStep(state) {
      state.currentStep = 3;
    },
    nextStep(state) {
      state.currentStep++;
    },
    resetFormData(state) {
      state.formData = undefined;
    },
    previousStep(state) {
      state.currentStep--;
    },
    setTotalSteps(state, action) {
      state.totalSteps = action.payload;
    },
    setCurrentStep(state) {
      state.currentStep = 1;
    },
    setFormData(state, action) {
      state.formData = action.payload;
    },
    setProfileClientData(state, action) {
      state.ProfileClientData = action.payload;
    },
    setProfileServiceData(state, action) {
      state.ProfileServiceData = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setOtpError(state, action) {
      state.otpError = action.payload;
    },
  },
  extraReducers: (builder) => {
    // signUp
    builder.addCase(signUp.pending, (state, action) => {
      state.loading = "pending";
      state.signUpError = "";
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";

        localStorage.setItem("token", action.payload.token);
      }

      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.signUpError = action.payload.error[0].msg ?? "failed";
      }

      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.signUpError = action.payload.error;
      }
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.loading = "failed";

      state.error = action.error;
    });

    builder.addCase(registerCreator.pending, (state, action) => {
      state.loading = "pending";
      state.signUpError = "";
    });
    builder.addCase(registerCreator.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }

      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.signUpError = action.payload.error[0].msg ?? "failed";
      }

      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.signUpError = action.payload.error;
      }
    });
    builder.addCase(registerCreator.rejected, (state, action) => {
      state.loading = "failed";

      state.error = action.error;
    });

    // Signup Profile Client
    builder.addCase(updateProfileClient.pending, (state, action) => {
      state.loading = "pending";
      state.signUpError = "";
    });
    builder.addCase(updateProfileClient.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }

      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.signUpError = action.payload.error[0].msg ?? "failed";
      }

      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.signUpError = action.payload.error;
      }
    });
    builder.addCase(updateProfileClient.rejected, (state, action) => {
      state.loading = "failed";

      state.error = action.error;
    });

    // Signup Profile Service
    builder.addCase(updateProfileService.pending, (state, action) => {
      state.loading = "pending";
      state.signUpError = "";
    });
    builder.addCase(updateProfileService.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }

      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.signUpError = action.payload.error[0].msg ?? "failed";
      }

      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.signUpError = action.payload.error;
      }
    });
    builder.addCase(updateProfileService.rejected, (state, action) => {
      state.loading = "failed";

      state.error = action.error;
    });

    // update profile service
    builder.addCase(ServiceProfileUpdate.pending, (state, action) => {
      state.loading = "pending";
      state.signUpError = "";
    });
    builder.addCase(ServiceProfileUpdate.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }

      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.signUpError = action.payload.error[0].msg ?? "failed";
      }

      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.signUpError = action.payload.error;
      }
    });
    builder.addCase(ServiceProfileUpdate.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.error;
    });

    // getFields
    builder.addCase(getFields.fulfilled, (state, action) => {
      state.fields = action.payload;
    });

    // update client profile
    builder.addCase(ClientProfileUpdate.pending, (state, action) => {
      state.loading = "pending";
      state.signUpError = "";
    });
    builder.addCase(ClientProfileUpdate.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }

      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.signUpError = action.payload.error[0].msg ?? "failed";
      }

      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.signUpError = action.payload.error;
      }
    });
    builder.addCase(ClientProfileUpdate.rejected, (state, action) => {
      state.loading = "failed";

      state.error = action.error;
    });
  },
});

export const {
  initState,
  resetFormData,
  resetValues,
  nextStep,
  previousStep,
  setTotalSteps,
  setFormData,
  setProfileClientData,
  setProfileServiceData,
  setCurrentStep,
  setError,
  setOtpError,
  setVerificationMailCurrentStep,
  setLoginCurrentStep,
} = signUpSlice.actions;

export const selectCount = (state: RootState) => state.signUp;

export default signUpSlice.reducer;
