import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { fetchBlogs } from "redux/features/blog/blogSlice";

const BlogPage: React.FC = () => {
  const dispatch = useAppDispatch();
  let locationState = useAppSelector((state) => state.featureBlog.blogs);
  useEffect(() => {
    dispatch(fetchBlogs());
  }, []);

  return (
    <div className="relative overflow-hidden nc-BlogPage">
      <Helmet>
        <title>Event To Bee - Blogs</title>
        <meta name="description" content="Découvrez les derniers articles de notre blog sur Event To Bee." />
        <link rel="canonical" href="https://eventtobee.com/" />
      </Helmet>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      <div className="container relative">
        {/* === SECTION Magazine === */}
        <div className="pt-12 pb-16 lg:pb-28">
          <SectionMagazine5 posts={locationState} />
        </div>
        {/* === SECTION Latest Posts === */}
        <SectionLatestPosts posts={locationState} className="py-16 lg:py-28" />
      </div>
    </div>
  );
};

export default BlogPage;
