import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, Spinner, Typography } from "@material-tailwind/react";
import { LocationFormProps } from "data/reduxInterfaces";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { fetchEvents } from "redux/features/Admin/Events/eventSlice";
import { z } from "zod";

interface Event {
  id: number;
  designation: string;
}

const formSchema = z.object({
  events: z.array(z.string()).refine((arg) => {
    return arg.length > 0;
  }, "Merci d'avoir sélectionné au moins un événement."),
});

const LocationEvent: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  isLoading,
  handleForm,
  data,
  FormData,
}) => {
  const dispatch = useAppDispatch();

  const evenstStates = useAppSelector((state) => state.featureEvent);

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      events: data?.events ?? [],
    },
    mode: "onSubmit",
  });

  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchEvents());
  }, []);

  useEffect(() => {
    if (data) {
      setValue("events", data.events);
    }
  }, [data]);

  useEffect(() => {
    if (evenstStates.events) {
      setDataLoading(false);
    }
  }, [evenstStates.events]);

  useEffect(() => {
    if (handleForm.type === "chekLocationFormThree") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const handleSelect = (eventDesignation: string, isChecked: boolean) => {
    const updatedEvents = isChecked
      ? [...(getValues("events") ?? []), eventDesignation]
      : getValues("events")?.filter((event: string) => event !== eventDesignation);

    setValue("events", updatedEvents as string[]);
    trigger("events");
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  return (
    <Fragment>
      <form className="flex flex-col md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full md:w-3/4 md:mr-4">
          <div>
            <h2 className="text-2xl font-semibold">Spécifications de votre espace </h2>
            <h3 className="mt-2 text-sm text-neutral-600">
              Sélectionnez le type d'événement pour lequel vous envisagez de louer cet espace : mariage, fête
              anniversaire, séminaire, atelier, shooting, etc.{" "}
            </h3>

            <div className="mt-3 border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
          </div>

          <div className="mt-5">
            <div>
              <label className="font-semibold text" htmlFor="">
                Type d'événements *
              </label>

              <div
                className={`grid grid-cols-2 mt-6 ${
                  evenstStates.events.length > 10 ? "sm:grid-cols-2 lg:grid-cols-3" : ""
                }`}
              >
                {dataLoading ? (
                  <div className="flex items-center justify-center mt-16">
                    <p>
                      <Spinner color="blue" className="w-12 h-12" />
                    </p>
                  </div>
                ) : (
                  evenstStates.events?.map((event: Event) => (
                    <Checkbox
                      key={event.id}
                      name={event.designation}
                      disabled={isLoading}
                      label={<Typography className="text-dark dark:text-white">{event.designation}</Typography>}
                      checked={!!getValues("events")?.includes(event.designation)}
                      onChange={(e) => handleSelect(event.designation, e.target.checked)}
                    />
                  ))
                )}
              </div>

              {errors.events?.message && <div className="text-xs text-red-500">{`${errors.events?.message}`}</div>}
            </div>
          </div>
        </div>

        <div className="w-full mt-4 md:w-1/3 md:mt-0">
          <div className="p-4 border-2 border-indigo-800">
            <div className="flex items-center mt-5 ml-5">
              <CalendarDaysIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Type d'événements</h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mb-3 mr-5 -ml-8 text-sm">
                Sélectionnez le type d'événement pour lequel vous envisagez de louer cet espace parmi une liste
                d'options telles que mariage, fête d'anniversaire, réunion d'affaires, séminaire, atelier, etc.
                Cette information aidera à mieux comprendre la nature de votre événement et à adapter l'espace en
                conséquence pour répondre à vos besoins spécifiques
              </p>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default LocationEvent;
