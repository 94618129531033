import { FC, useEffect, useState } from "react";
import WidgetHeading1 from "./WidgetHeading1";
import Tag from "shared/Tag/Tag";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { fetchAllTags } from "redux/features/blog/blogSlice";

export interface WidgetTagsProps {
  className?: string;
}

const WidgetTags: FC<WidgetTagsProps> = ({ className = "bg-neutral-100 dark:bg-neutral-800" }) => {
  const [displayedTags, setDisplayedTags] = useState<number>(10);
  const dispatch = useAppDispatch();
  let tagsState = useAppSelector((state) => state.featureBlog.allTags);
  useEffect(() => {
    dispatch(fetchAllTags());
  }, []);

  const handleViewAllClick = () => {
    if (tagsState) {
      setDisplayedTags(tagsState?.length);
    }
  };

  return (
    <div className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`} data-nc-id="WidgetTags">
      <WidgetHeading1
        title="🏷 Explorer plus d'étiquettes"
        viewAll={{ label: "Voir tout", onClick: handleViewAllClick }}
      />
      <div className="flex flex-wrap p-4 xl:p-5">
        {Array.isArray(tagsState) &&
          tagsState.map((tag: any) => <Tag className="mb-2 mr-2" key={tag} tag={tag} />)}
      </div>
    </div>
  );
};

export default WidgetTags;
