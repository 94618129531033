import React, { useEffect, useState } from "react";
import TabFilters from "./TabFilters";
import RealEstateSearchForm from "./RealEstateSearchForm";

interface FiltersPageProps {
  onApplyFilters: (filters: any) => void;
  onApplyFiltersRegionEvent: (filters: any) => void;
}

const FiltersPage: React.FC<FiltersPageProps> = ({ onApplyFilters, onApplyFiltersRegionEvent }) => {
  const [searchClicked, setSearchClicked] = useState(false);
  const [clearClicked, setClearClicked] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [visitor, setVisitor] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [event, setEvent] = useState("");
  const [region, setRegion] = useState("");
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  };

  const mobileContainerStyle = {
    flexDirection: "column",
  } as React.CSSProperties;

  const responsiveContainerStyle = {
    ...containerStyle,
    ...((window.innerWidth || document.documentElement.clientWidth) < 600 && mobileContainerStyle),
  };

  const handleApplyCategories = (selectedCategories: any) => {
    setCategory(selectedCategories);
  };

  const handleApplyVisitors = (selectedVisitors: any) => {
    setVisitor(selectedVisitors);
  };

  const handleApplyPrice = (selectedPrice: any) => {
    const [minPrice, maxPrice] = selectedPrice.split(" - ").map(Number);
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
  };

  const handleSelectionFromChild = (field: string, value: string) => {
    if (field === "events") {
      setEvent(value);
    } else if (field === "region") {
      setRegion(value);
    }
  };
  useEffect(() => {
    onApplyFilters({ minPrice, maxPrice, visitor, category, title });
  }, [minPrice, maxPrice, visitor, category, title]);

  useEffect(() => {
    if (searchClicked) {
      onApplyFiltersRegionEvent({ event, region });
      setSearchClicked(false);
    }
    if (clearClicked) {
      setEvent("");
      setRegion("");
      onApplyFiltersRegionEvent({});
      setClearClicked(false);
    }
  }, [searchClicked, clearClicked]);

  return (
    <div className="mt-2">
      <RealEstateSearchForm
        onSelectFromRegionEvent={handleSelectionFromChild}
        searchClicked={searchClicked}
        handleSearchClick={() => setSearchClicked(true)}
        handleDelete={() => setClearClicked(true)}
      />
      <div style={responsiveContainerStyle} className="mt-2">
        <TabFilters
          onApply={handleApplyCategories}
          onApplyVisitor={handleApplyVisitors}
          onApplyPrice={handleApplyPrice}
        />
      </div>
    </div>
  );
};

export default FiltersPage;
