import React, { useEffect } from "react";
import { FC } from "react";
import { useParams } from "react-router";
import Avatar from "shared/Avatar/Avatar";

export interface CommentProps {
  isSmall?: boolean;
  data?: any;
}

const Comment: FC<CommentProps> = ({ isSmall, data }) => {
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  };
  return (
    <div className="flex nc-CommentCard ">
      <div className="pt-1">
        <Avatar
          imgUrl={process.env.REACT_APP_CLOUD_FRONT + data?.Client?.client_url}
          sizeClass={`w-6 h-6 ${!isSmall ? "sm:h-8 sm:w-8 " : ""}`}
        />
      </div>
      <div className="flex flex-col flex-grow p-4 ml-2 text-sm border border-neutral-200 rounded-xl sm:ml-3 sm:text-base dark:border-neutral-700">
        <div className="relative flex items-center pr-6">
          <a className="flex-shrink-0 font-semibold text-neutral-800 dark:text-neutral-100" href="/">
            {data?.Client?.name} {data?.Client?.lastName}
          </a>
          <span className="mx-2">·</span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400 line-clamp-1 sm:text-sm">
            {formatDate(data?.createdAt)}
          </span>
        </div>
        <span className="block mt-2 mb-3 text-neutral-700 sm:mt-3 sm:mb-4 dark:text-neutral-300">
          {data?.text}
        </span>
      </div>
    </div>
  );
};

export default Comment;
