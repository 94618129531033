import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import { AuthContext } from "context/appContext";
import { useContext, useEffect, useState } from "react";
import Pagination from "shared/Pagination/Pagination";
import CommonLayout from "./CommonLayout";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { getServiceFavorisByClientId } from "redux/features/service/serviceSlice";
import { Spinner } from "@material-tailwind/react";
import { itemPerDashboradClient } from "data/constants";

const AccountServiceSavelists = () => {
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();
  const wishlist = useAppSelector((state) => state?.service?.likedService);

  useEffect(() => {
    if (profilId) {
      setIsLoading(true);
      dispatch(getServiceFavorisByClientId({ clientId: profilId })).then(() => setIsLoading(false));
    }
  }, [profilId]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashboradClient;

  const paginatedWishlists = Array.isArray(wishlist)
    ? wishlist.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const length = Array.isArray(wishlist) ? wishlist.length : 0;
  const totalPages = Array.isArray(wishlist) ? Math.ceil(wishlist.length / itemsPerPage) : 0;

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold dark:text-white">Mes favoris</h2>
        </div>
        <span className="text-base">{length} prestations </span>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {isLoading ? (
          <div className="flex items-center justify-center mt-16">
            <p>
              <Spinner color="blue" className="w-12 h-12" />
            </p>
          </div>
        ) : (
          <div>
            <Tab.Group>
              <Tab.Panels>
                <Tab.Panel className="mt-8">
                  <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {paginatedWishlists.map((item) => (
                      <CarCard
                        key={item.id}
                        data={item.Service}
                        featuredImage={item.Service?.images}
                        favoriId={item.id}
                        profilId={profilId}
                      />
                    ))}
                  </div>
                  <div className="flex items-center justify-center mt-11">
                    {typeof totalPages === "number" && (
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            {length === 0 && (
              <div className="flex items-center justify-center">
                <h2 className="text-xl ">Aucun prestation</h2>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountServiceSavelists;
