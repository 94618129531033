import { Card, CardBody, CardFooter, CardHeader, Dialog, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import DashboardMenu from "../DashboardPage/DashboardMenu";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import DeleteModal from "components/DeleteModal/DeleteModal";
import Pagination from "shared/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { deleteEvent, fetchEvents } from "redux/features/Admin/Events/eventSlice";
import { itemPerDashborad } from "data/constants";

export interface ListEventsPageProps {
  className?: string;
}

export interface Event {
  designation: string;
  id: number;
  images: any;
}

const ListEventsPage: FC<ListEventsPageProps> = ({ className = "" }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen((cur) => !cur);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const fetchedEvents: Event[] = useSelector((state: RootState) => state.featureEvent.events);

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  const handleDelete = async (id: number) => {
    await dispatch(deleteEvent(id));
    dispatch(fetchEvents());
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashborad;

  const paginatedFields = fetchedEvents.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const length = fetchedEvents.length;
  const totalPages = Math.ceil(fetchedEvents.length / itemsPerPage);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<number | null>(null);
  const [deleteCategoryDesignation, setDeleteCategoryDesignation] = useState<string | null>(null);

  const handleDeleteClick = (id: number | null, designation: string) => {
    setDeleteCategoryDesignation(designation);
    setDeleteModalOpen(true);
    setDeleteCategoryId(id);
  };

  const handleDeleteConfirm = () => {
    if (deleteCategoryId !== null) {
      handleDelete(deleteCategoryId);
      setDeleteCategoryId(null);
      setDeleteModalOpen(false);
    }
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="flex flex-col">
          <div className="flex items-center justify-between mb-4">
            <ButtonPrimary href="/dashboard/add-events">Ajouter un evénement</ButtonPrimary>
            <div className="flex">
              <Input
                type="text"
                placeholder="Rechercher par désignation"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassCircleIcon className="w-10 h-90 " />
            </div>
            <Dialog size="xs" open={open} handler={handleOpen} className="bg-transparent shadow-none">
              <Card className="mx-auto w-full max-w-[24rem]">
                <CardHeader className="grid mb-4 h-28 place-items-center ">Message</CardHeader>
                <CardBody className="flex flex-col gap-4">
                  <Label>Désignation</Label>
                  <Input />
                </CardBody>
                <CardFooter className="pt-0">
                  <Typography variant="small" className="flex justify-center mt-6">
                    <ButtonPrimary>Ajouter</ButtonPrimary>
                  </Typography>
                </CardFooter>
              </Card>
            </Dialog>
          </div>
          {deleteModalOpen && (
            <DeleteModal
              open={deleteModalOpen}
              handleClose={() => setDeleteModalOpen(false)}
              handleDelete={handleDeleteConfirm}
              category={{
                id: deleteCategoryId || -1,
                designation: deleteCategoryDesignation || "",
              }}
            />
          )}

          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-sm font-light text-center">
                  <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="px-6 py-4 ">
                        Image
                      </th>

                      <th scope="col" className="px-6 py-4 ">
                        Designation
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Modifier
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Supprimer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchTerm === ""
                      ? paginatedFields.map((item, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="w-10 px-6 py-4 font-medium whitespace-nowrap">
                              <img src={`${process.env.REACT_APP_CLOUD_FRONT + item?.images[0]}`} alt="" />
                            </td>

                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item.designation}</td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <Link to={`/dashboard/edit-events/${item.id}`}>
                                <i className="text-2xl las la-edit"></i>{" "}
                              </Link>{" "}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl las la-trash-alt"
                                onClick={() => handleDeleteClick(item.id, item.designation)}
                              ></i>
                            </td>
                          </tr>
                        ))
                      : fetchedEvents
                          .filter((item) => item.designation.toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((item, index) => (
                            <tr className="border-b dark:border-neutral-500" key={index}>
                              <td className="w-10 px-6 py-4 font-medium whitespace-nowrap">
                                <img src={`${process.env.REACT_APP_CLOUD_FRONT + item?.images[0]}`} alt="" />
                              </td>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">{item.designation}</td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <Link to={`/dashboard/edit-field/${item.id}`}>
                                  <i className="text-2xl las la-edit"></i>{" "}
                                </Link>{" "}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <i
                                  className="text-2xl las la-trash-alt"
                                  onClick={() => handleDeleteClick(item.id, item.designation)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                  </tbody>
                </table>
                <div className="flex justify-end ">
                  <span className="text-sm text-gray-700">{length} élément</span>
                </div>
                <div className="flex items-center justify-center mt-11">
                  {typeof totalPages === "number" && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardMenu>
    </div>
  );
};

export default ListEventsPage;
