import DeleteModal from "components/DeleteModal/DeleteModal";
import { AuthContext } from "context/appContext";
import { Service } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteService, getServiceByUserId, initState } from "redux/features/service/serviceSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Pagination from "shared/Pagination/Pagination";
import { Spinner } from "@material-tailwind/react";
import { itemPerDashborad, itemPerDashboradPrestation } from "data/constants";

export interface SectionGridFilterCardServiceAgentProps {
  className?: string;
}

const SectionGridFilterCardServiceAgent: FC<SectionGridFilterCardServiceAgentProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;

  const dispatch = useAppDispatch();
  const serviceList = useAppSelector((state) => state?.service?.serviceData) || [];

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (profilId) {
      setIsLoading(true);
      dispatch(getServiceByUserId(profilId)).then(() => setIsLoading(false));
    }
  }, [profilId]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashboradPrestation;

  const paginatedServiceList = Array.isArray(serviceList)
    ? serviceList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const length = Array.isArray(serviceList) ? serviceList.length : 0;
  const totalPages = Array.isArray(serviceList) ? Math.ceil(serviceList.length / itemsPerPage) : 0;

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | null>(null);

  const handleDeleteModalOpen = (service: Service) => {
    setSelectedService(service);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    if (selectedService && profilId) {
      await dispatch(deleteService(selectedService.id));
      setDeleteModalOpen(false);
      await dispatch(getServiceByUserId(profilId));
    }
  };

  return (
    <div className={`w-full  rounded-md ${className}`}>
      <div className="flex items-center justify-between pb-6">
        <div>
          {" "}
          <ButtonPrimary className="mb-5" href={"/add-service"}>
            Ajouter une nouvelle prestation
          </ButtonPrimary>
        </div>
      </div>
      <div className="flex items-center justify-between pb-1">
        <div>
          <h2 className="font-semibold text-gray-600 dark:text-white">Mes Prestations </h2>
        </div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center mt-16">
          <p>
            <Spinner color="blue" className="w-12 h-12" />
          </p>
        </div>
      ) : (
        <div>
          <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Images
                    </th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Titre
                    </th>

                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 "></th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Action
                    </th>
                    <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 "></th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedServiceList.map((service: any) => (
                    <tr key={service.id}>
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img
                              className="w-full h-full rounded-full"
                              src={`${process.env.REACT_APP_CLOUD_FRONT + service?.images[0]}`}
                              alt=""
                            />
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800 ">
                        <p className="text-gray-900 whitespace-no-wrap dark:text-white">{service?.title}</p>
                      </td>

                      {/* GetbyID */}
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800 ">
                        <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                          <span
                            aria-hidden
                            className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                          ></span>
                          <span className="relative">
                            <Link to={`/prestations/${service.id}`}>
                              {" "}
                              <i className="text-xl las la-eye"></i>
                            </Link>
                          </span>
                        </span>
                      </td>
                      {/* Update */}
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800 ">
                        <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                          <span
                            aria-hidden
                            className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                          ></span>
                          <span className="relative">
                            <Link to={`/update-service/${service.id}`}>
                              {" "}
                              <i className="text-xl las la-edit"></i>
                            </Link>
                          </span>
                        </span>
                      </td>
                      {/* Delete */}
                      <td className="px-5 py-5 text-sm border-b dark:bg-gray-800 ">
                        <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tigh">
                          <span aria-hidden className="absolute inset-0 bg-red-200 rounded-full opacity-50"></span>
                          <span className="relative">
                            <i
                              className="text-xl cursor-pointer las la-trash-alt"
                              onClick={() => handleDeleteModalOpen(service)}
                            ></i>
                          </span>
                        </span>
                      </td>
                    </tr>
                  ))}

                  <DeleteModal
                    open={deleteModalOpen}
                    handleClose={() => setDeleteModalOpen(false)}
                    handleDelete={handleDeleteConfirmed}
                    category={{
                      id: selectedService?.id || 0,
                      designation: selectedService?.title || "",
                    }}
                  />
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex items-center justify-center mt-16">
            {typeof totalPages === "number" && (
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            )}
          </div>
          {Array.isArray(serviceList) && serviceList.length === 0 && (
            <div className="flex items-center justify-center mt-16">
              <p>Pas de prestations , ajouter votre prestation</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionGridFilterCardServiceAgent;
