import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import { faFacebookF, faInstagram, faLinkedinIn, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  { name: "Facebook", icon: faFacebookF, href: "https://www.facebook.com/profile.php?id=61555527601681" },
  { name: "Tiktok", icon: faTiktok, href: "https://www.tiktok.com/@eventtobee" },
  { name: "Youtube", icon: faYoutube, href: "https://www.youtube.com/channel/UCNaEePN03uQbmhZAEqmtDFg" },
  { name: "Instagram", icon: faInstagram, href: "https://www.instagram.com/eventtobee/" },
];

const SocialsList: FC<SocialsListProps> = ({ className = "", itemClass = "block", socials = socialsDemo }) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-6 text-2xl text-neutral-6000 dark:text-neutral-300 mt-4 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials.map((item, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <FontAwesomeIcon icon={item.icon} />
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
