import { FC } from "react";
import Card12 from "./Card12";
import Card14 from "./card14";

export interface SectionMagazine5Props {
  posts: any[];
}

const SectionMagazine5: FC<SectionMagazine5Props> = ({ posts }) => {
  return (
    <div className="nc-SectionMagazine5">
      <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
        {posts[0] && <Card12 post={posts[0]} />}
        <div className="grid gap-6 md:gap-8">
          {Array.isArray(posts) &&
            posts.filter((_, i) => i < 4 && i > 0).map((item, index) => <Card14 key={index} post={item} />)}
        </div>
      </div>
    </div>
  );
};

export default SectionMagazine5;
