import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import DeleteModal from "components/DeleteModal/DeleteModal";

import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";

import DashboardMenu from "../DashboardPage/DashboardMenu";
import { deleteContact } from "redux/features/contact/contactSlice";
import TransactionDetailsPage from "./TransactionDetailsPage";
import { itemPerDashborad } from "data/constants";
import { getAllTransactions } from "redux/features/Admin/Stripe/stripeSlice";

export interface ListReclamationPageProps {
  className?: string;
}

const ListTransactionAdminPage = () => {
  const dispatch = useAppDispatch();
  const transactions = useAppSelector((state) => state?.stripe?.transactionsList);

  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);

  const [deleting, setDeleting] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const itemsPerPage = itemPerDashborad;

  useEffect(() => {
    dispatch(getAllTransactions());
  }, []);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const paginatedTransaction = Array.isArray(transactions)
    ? transactions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const length = Array.isArray(transactions) ? transactions.length : 0;
  const totalPages = Array.isArray(transactions) ? Math.ceil(transactions.length / itemsPerPage) : 0;

  const paginatedSearchedTransactions = Array.isArray(transactions)
    ? transactions
        .filter((item) => {
          const nom = item.amount;
          const email = item.currency || "";

          return nom || email.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const displayedTransaction = searchTerm === "" ? paginatedTransaction : paginatedSearchedTransactions;

  const handleDeleteModalOpen = (serviceAgents: any) => {
    setSelectedTransaction(serviceAgents);
    setShowTransactionDetails(false);
    setDeleteModalOpen(true);
  };
  const handleDeleteConfirm = async () => {
    if (selectedTransaction && selectedTransaction?.id !== undefined) {
      setDeleting(true);
      await dispatch(deleteContact(selectedTransaction.id));
      setDeleting(false);
      setDeleteModalOpen(false);
      await dispatch(getAllTransactions());
    }
  };

  const handleEyeIconClick = (reclamation: any) => {
    setSelectedTransaction(reclamation);
    setShowTransactionDetails(true);
    setDeleteModalOpen(false);
  };

  return (
    <div className={`nc-AccountPage `} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="flex flex-col">
          <div className="flex items-center justify-between mb-4">
            <div></div>
            <div className="relative flex">
              <Input
                type="text"
                placeholder="Rechercher"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-500 transform -translate-y-1/2 top-1/2 right-3" />
            </div>
          </div>
          {deleteModalOpen && (
            <DeleteModal
              open={deleteModalOpen}
              handleClose={() => setDeleteModalOpen(false)}
              handleDelete={handleDeleteConfirm}
              category={{
                id: selectedTransaction?.id || 0,
                designation: selectedTransaction?.email || "",
              }}
            />
          )}

          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-sm font-light text-center">
                  <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="px-6 py-4 ">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Reférence
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Destinateur
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Devise
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Amount
                      </th>

                      <th scope="col" className="px-6 py-4 ">
                        Supprimer
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {searchTerm === ""
                      ? paginatedTransaction.map((item, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {new Date(item?.created * 1000).toLocaleDateString("fr-FR", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              })}
                            </td>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item?.id}</td>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {item?.account?.business_profile?.name}
                            </td>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item?.currency}</td>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item?.amount}</td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-trash-alt"
                                //onClick={() => handleDeleteModalOpen(item)}
                              ></i>
                            </td>
                          </tr>
                        ))
                      : displayedTransaction.length > 0 &&
                        displayedTransaction.map((item, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {new Date(item?.created * 1000).toLocaleDateString("fr-FR", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              })}
                            </td>

                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item?.id}</td>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {item?.account?.business_profile?.name}
                            </td>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item?.currency}</td>

                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item?.amount}</td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-trash-alt"
                                //onClick={() => handleDeleteModalOpen(item)}
                              ></i>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <div className="flex justify-end ">
                  <span className="text-sm text-gray-700">{length} élément</span>
                </div>
                {showTransactionDetails && !deleting && (
                  <TransactionDetailsPage
                    open={true}
                    reclamationData={selectedTransaction}
                    handleClose={() => setShowTransactionDetails(false)}
                  />
                )}
                <div className="flex items-center justify-center mt-11">
                  {typeof totalPages === "number" && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={Math.ceil((transactions?.length ?? 0) / itemsPerPage)}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardMenu>
    </div>
  );
};

export default ListTransactionAdminPage;
