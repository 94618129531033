import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import { Link } from "react-router-dom";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";

export interface Card14Props {
  className?: string;
  post: any;
}

const Card14: FC<Card14Props> = ({ className = "", post }) => {
  const { title, id, description, blog_url, date, postType, publisher } = post;

  // Function to break the description into lines after every 50 characters
  const breakDescription = (text: string, charactersPerLine: number) => {
    const regex = new RegExp(`.{1,${charactersPerLine}}`, "g");
    return text.match(regex)?.join("\n") || text;
  };

  const formattedDescription = breakDescription(description, 50);

  return (
    <div className={`nc-Card13 relative flex ${className}`} data-nc-id="Card13">
      <div className="flex flex-col flex-grow py-2">
        <Link to={`/blog-single/${id}`} className="line-clamp-2" title={title}>
          <h2 className="nc-card-title block font-semibold text-base">{title}</h2>
        </Link>
        <span className="my-3 text-neutral-500 dark:text-neutral-400 line-clamp-2">
          {formattedDescription}
          <span className="mt-4 block sm:hidden text-sm text-neutral-500">{date}</span>
          <div className="mt-auto hidden sm:block">
            <PostCardMeta meta={{ ...post, publisher }} />
          </div>
        </span>
      </div>

      <div className="flex-shrink-0 w-2/5 sm:w-1/3 ml-3 sm:ml-5">
        <Link to={`/blog-single/${id}`} className="block relative h-full">
          <NcImage
            containerClassName="absolute inset-0 rounded-xl sm:rounded-3xl"
            className="object-cover w-full h-full rounded-xl sm:rounded-3xl"
            src={process.env.REACT_APP_CLOUD_FRONT + blog_url}
            alt={title}
          />
          <PostTypeFeaturedIcon
            className="absolute bottom-2 left-2"
            postType={postType}
            wrapSize="w-8 h-8"
            iconSize="w-4 h-4"
          />
        </Link>
      </div>
    </div>
  );
};

export default Card14;
