import AgentCommonLayout from "containers/AgentService/AccountPage/AgentCommonLayout";
import { AuthContext } from "context/appContext";
import { ProfilService } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addService,
  initState,
  nextStep,
  previousStep,
  resetValues,
  setServiceData,
} from "redux/features/service/serviceSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ServiceDetails from "./ServiceDetails";
import ServiceExtraDetails from "./ServiceExtraDetails";
import ServiceFilesUpload from "./ServiceFilesUpload";

import { socket } from "utils/socket";

const ServiceCommonLayout: React.FC = () => {
  const authContext = useContext(AuthContext);

  let role = authContext.user?.role ?? "";
  let profile = authContext.user?.profil as ProfilService;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const serviceState = useAppSelector((state) => state.service);
  const progress = (serviceState.currentStep / serviceState.totalSteps) * 100;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [autoNext, setAutoNext] = useState<boolean>(false);
  const [event, setEvent] = useState<Event>(new Event(""));

  const initialRender = useRef(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(initState());
  }, []);

  useEffect(() => {
    switch (serviceState.loading) {
      case "pending": {
        setIsLoading(true);

        break;
      }
      case "succeeded": {
        setIsLoading(false);
        if (serviceState.serviceData && serviceState.currentStep === 3) {
          dispatch(addService(serviceState.serviceData));
        } else {
          dispatch(nextStep());
        }
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [serviceState.loading]);

  useEffect(() => {
    const handleAddService = async () => {
      if (serviceState.serviceData && serviceState.currentStep === 3) {
        const result = await dispatch(addService(serviceState.serviceData));
        socket.emit("send-notification", {
          action: "create_prestation",
          prestation: serviceState.serviceData,
          prestationSenderId: serviceState.serviceData?.serviceAgentId,
          prestationId: result?.payload?.id,
        });
        dispatch(resetValues());
        navigate("/prestataire/services-list");
      }
    };
    handleAddService();
  }, [serviceState.serviceData]);

  useEffect(() => {
    if (
      !initialRender.current &&
      serviceState.serviceData &&
      serviceState.currentStep < serviceState.totalSteps &&
      autoNext
    ) {
      dispatch(nextStep());
      setIsLoading(false);
    }

    initialRender.current = false;
  }, [serviceState.serviceData, autoNext]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [serviceState.currentStep]);

  const handleNextClick = () => {
    switch (serviceState.currentStep) {
      case 1: {
        setEvent(new Event("chekServiceFormOne"));
        setAutoNext(true);
        break;
      }
      case 2: {
        setEvent(new Event("chekServiceFormTwo"));
        setAutoNext(true);
        break;
      }
      case 3: {
        setEvent(new Event("chekServiceFormThree"));
        setAutoNext(true);
        break;
      }

      default: {
        setEvent(new Event(""));
        break;
      }
    }
  };

  const handlePreviousClick = () => {
    setAutoNext(false);
    dispatch(previousStep());
  };

  return (
    <AgentCommonLayout>
      <div className={`px-4 pb-24 mx-auto max-w-3xl nc-PageAddListing1 lg:pb-32`} data-nc-id="PageAddListing1">
        <p className="mt-5">
          Bonjour Mr/Mme :<span className="font-bold text-black">{profile?.designation}</span>{" "}
        </p>

        <div className="mt-10 space-y-6">
          {serviceState.currentStep === 1 && (
            <ServiceDetails
              isLoading={isLoading}
              role={role ?? ""}
              handleForm={event}
              FormData={(data) => {
                dispatch(setServiceData({ ...serviceState.serviceData, ...data }));
              }}
              errorServiceForm={serviceState.serviceError}
            />
          )}

          {serviceState.currentStep === 2 && (
            <ServiceExtraDetails
              isLoading={isLoading}
              role={role ?? ""}
              handleForm={event}
              FormData={(data) => dispatch(setServiceData({ ...serviceState.serviceData, ...data }))}
              errorServiceForm={serviceState.serviceError}
            />
          )}

          {serviceState.currentStep === 3 && (
            <ServiceFilesUpload
              isLoading={isLoading}
              role={role ?? ""}
              handleForm={event}
              FormData={(data) => dispatch(setServiceData({ ...serviceState.serviceData, ...data }))}
              errorServiceForm={serviceState.serviceError}
            />
          )}
          <div className="flex flex-col items-center justify-center space-y-3">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div className="bg-blue-600 h-2.5 rounded-full w-45" style={{ width: `${progress}%` }}></div>
            </div>

            <div className="flex items-center justify-between w-full">
              {/* <ButtonSecondary
                                onClick={handlePreviousClick}
                                disabled={serviceState.currentStep <= 1}
                            >
                                Retour
                            </ButtonSecondary> */}

              <p className="text-lg font-semibold">
                {`Étape ${serviceState.currentStep}/${serviceState.totalSteps}`}
              </p>

              <ButtonPrimary onClick={handleNextClick} loading={isLoading} disabled={isLoading}>
                Continue
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </AgentCommonLayout>
  );
};

export default ServiceCommonLayout;
