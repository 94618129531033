import Banner from "./Banner";
import TestimonialSeven from "elements/testimonial/Testimonials/Testimonial-Seven";
import ServiceNine from "elements/services/Service-Nine";
import { FC } from "react";
import "../../styles/css/next-styles.css";
import AboutUsPage from "elements/Pages";
import AdvanceTabPage from "elements/advance-tab";
import Page from "elements/newsletter";
import { Helmet } from "react-helmet";

export interface SectionHowItWorkProps {
  className?: string;
}

const HowItWorks: FC<SectionHowItWorkProps> = ({ className = "pt-6 pb-6" }) => {
  return (
    <>
      <Helmet>
        <title>Event To Bee - Comment ça marche </title>
        <meta
          name="description"
          content="Découvrez comment fonctionne Event To Bee pour vous aider à organiser vos événements spéciaux."
        />
      </Helmet>

      <div className="c">
        <div className="slider-area rbt-banner-10 height-500 bg_image bg_image--11 " data-black-overlay="5">
          <Banner />
        </div>

        <div>
          <AboutUsPage />
        </div>
        <AdvanceTabPage />

        <div className="p-24-4 ">
          <Page />
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
