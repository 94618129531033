import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import DashboardMenu from "../DashboardPage/DashboardMenu";
import { Card, CardBody, CardFooter, CardHeader, Typography } from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "shared/Alert/Alert";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  fetchPlaygrounds,
  getPlaygroundById,
  resetValues,
  updatePlayground,
} from "redux/features/Admin/playground/playgroundSlice";

export interface UpdateEquipmentPageProps {
  className?: string;
}

export interface PlaygroundData {
  designation: string;
  id: number;
}
const UpdateAmbiencesPage: FC<UpdateEquipmentPageProps> = ({ className = "" }) => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen((cur) => !cur);
  const dispatch = useAppDispatch();
  let playgroundState = useAppSelector((state) => state.featurePlayground.Playground);
  let playgroundsState = useAppSelector((state) => state.featurePlayground.Playgrounds);
  const loadingStates = useAppSelector((state) => state.featurePlayground);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (id) {
      dispatch(getPlaygroundById(id));
    }
    dispatch(fetchPlaygrounds());
  }, [id]);

  useEffect(() => {
    if (playgroundState) {
      setValue("designation", playgroundState.designation || "");
    }
  }, [playgroundState]);

  const [showAlert, setShowAlert] = React.useState(false);
  const [showExistAlert, setShowExistAlert] = React.useState(false);
  const navigate = useNavigate();
  const formSchema = z.object({
    designation: z.string().min(1, { message: "Aire de jeux est obligatoire." }),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    const isCategoryExists = playgroundsState?.some(
      (playground: any) => playground.designation === getValues("designation")
    );

    if (isCategoryExists) {
      setShowExistAlert(true);
      setTimeout(() => {
        setShowExistAlert(false);
      }, 1000);
      return;
    }
    await dispatch(
      updatePlayground({
        data: {
          ...data,
        },
        id: id,
      })
    ).then(() => {
      dispatch(resetValues());
      navigate("/dashboard/playgrounds");
    });
  };

  useEffect(() => {
    switch (loadingStates.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [loadingStates.loading]);
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="mx-auto w-full max-w-[24rem] dark:bg-gray-800">
            <CardHeader className="grid mb-4 h-28 place-items-center dark:bg-gray-800">
              {!showExistAlert && !showAlert && <span className="dark:text-white">Aire de jeux</span>}
              {showAlert && (
                <Alert type="success" containerClassName="text-xs">
                  modifiée avec succès !
                </Alert>
              )}
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
              <Label className="dark:text-white">Designation</Label>
              <Input {...register("designation")} />
              {errors.designation && <div className="text-xs text-red-500 ">{errors.designation.message}</div>}
            </CardBody>
            <CardFooter className="pt-0">
              <Typography variant="small" className="flex justify-center mt-6">
                <ButtonPrimary type="submit" loading={isLoading}>
                  Mise à jour
                </ButtonPrimary>
              </Typography>
            </CardFooter>
          </Card>
        </form>
      </DashboardMenu>
    </div>
  );
};

export default UpdateAmbiencesPage;
