import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import Heading from "components/Heading/Heading";
import { fetchOwners } from "redux/features/owners/ownersSlice";
import { getOwners } from "redux/features/auth/authSlice";

export interface SectionGridAuthorBoxProps {
  className?: string;
  boxCard?: "box1" | "box2";
  gridClassName?: string;
}

const SectionGridAuthorBox: FC<SectionGridAuthorBoxProps> = ({
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ",
}) => {
  const dispatch = useAppDispatch();
  const owners = useAppSelector((state) => state.auth.allOwners);

  useEffect(() => {
    dispatch(getOwners());
  }, []);

  return (
    <div className={`nc-SectionGridAuthorBox relative ${className}`} data-nc-id="SectionGridAuthorBox">
      <Heading desc="Évaluation basée sur les avis des clients" isCenter>
        Top 10 hôtes
      </Heading>

      <div className={`grid gap-6 md:gap-8 ${gridClassName}`}>
        {Array.isArray(owners) &&
          owners.map((author: any, index: number) => (
            <CardAuthorBox index={index + 1} key={author.id} author={author} />
          ))}
      </div>
    </div>
  );
};

export default SectionGridAuthorBox;
