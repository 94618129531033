import FormItem from "components/FormItem/FormItem";
import Label from "components/Label/Label";
import { AuthContext } from "context/appContext";
import { ProfilOwner } from "data/types";
import { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-input-2";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import OwnerMenu from "./OwnerMenu";

import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { nextStep, previousStep, resetFormData, setCurrentStep } from "redux/features/signUp/signUpSlice";

export interface AccountPageProps {
  className?: string;
}

const OwnerAccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);

  let userId: number | undefined;
  let role = "";
  let profilId: number | undefined;

  userId = authContext.user?.id;
  role = authContext.user?.role ?? "";
  profilId = authContext.user?.profil?.id;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const signUpStep = useAppSelector((state) => state.signUp?.currentStep);
  useEffect(() => {
    if (authContext?.user?.profil === null) {
      if (signUpStep == 2) {
        dispatch(previousStep());
        dispatch(resetFormData());
        navigate("/signup/owner");
      } else {
        dispatch(previousStep());
        dispatch(resetFormData());
        navigate("/signup/owner");
      }
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.startsWith("/signup")) {
      dispatch(setCurrentStep());
    }
  }, [location.pathname, dispatch]);

  let profile = authContext.user?.profil as ProfilOwner;
  const phoneInputStyle = {
    border: "1px solid #CBD5E0",
    borderRadius: "0.375rem",
    backgroundColor: "#FFFFFF",
  };

  const [updateCompanyModal, setUpdateCompanyModal] = useState(false);
  const [updateParticularModal, setUpdateParticularModal] = useState(false);
  const handleUpdate = () => {
    if (profile?.category === "company") {
      setUpdateCompanyModal(true);
    }

    if (profile?.category === "particular") {
      setUpdateParticularModal(true);
    }
  };

  const handleCompanyUpdateClose = () => {
    setUpdateCompanyModal(false);
  };

  const handleParticularUpdateClose = () => {
    setUpdateParticularModal(false);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>

      <OwnerMenu>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}

          <div className="flex flex-row justify-between ">
            <h2 className="text-3xl font-semibold">Votre profil</h2>

            <Badge
              name={
                <div className="flex items-center ">
                  {profile?.category === "particular" ? "Particulier" : "Entreprise"}
                </div>
              }
              color="blue"
            />
          </div>

          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

          <div className="flex flex-col md:flex-row">
            {/*  company profil */}

            {profile?.category === "company" && profile.companyName && (
              <form className="flex-grow max-w-3xl mt-10 space-y-6 md:mt-0 md:pl-16" encType="multipart/form-data">
                <div>
                  <div className="flex items-start flex-shrink-0">
                    <div className="relative flex overflow-hidden rounded-full">
                      <Avatar sizeClass="w-32 h-32" imgUrl={`${profile?.owner_url}`} />
                    </div>
                  </div>
                  <br />
                  <h2 className="text-base font-semibold">Détail de votre compte entreprise</h2>
                  <p className="text-xs ">
                    Pour percevoir le montant de vos locations vous devez renseigner votre numéro de compte
                    bancaire (IBAN)
                  </p>
                  <Label>Nom de l'entreprise</Label>
                  <Input className="mt-1.5" name="companyName" defaultValue={profile?.companyName} disabled />
                  <Label>Numéro SIREN</Label>
                  <Input disabled className="mt-1.5" name="companySiren" defaultValue={profile?.companySiren} />
                  <br />

                  <div className="flex gap-8 md:gap-5">
                    <div>
                      <Label>Numéro d'identité </Label>
                      <Input disabled type="number" name="identityNumber" defaultValue={profile?.identityNumber} />
                    </div>
                  </div>
                  <br />
                  <div className="flex gap-8 md:gap-5">
                    <div>
                      <Label>Numéro téléphone *</Label>

                      <PhoneInput
                        country={"fr"}
                        value={profile?.phone}
                        inputStyle={phoneInputStyle}
                        containerStyle={phoneInputStyle}
                        buttonStyle={phoneInputStyle}
                        dropdownStyle={phoneInputStyle}
                        searchStyle={phoneInputStyle}
                        inputProps={{ required: true }}
                      />
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className="flex gap-8">
                      <div>
                        <Label>Adresse</Label>
                        <Input disabled type="text" defaultValue={profile.adresse} />
                      </div>
                      <div>
                        <Label>Supplément d'adresse</Label>
                        <Input type="text" name="suppAdresse" defaultValue={profile?.suppAdresse} disabled />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex gap-8">
                      <FormItem label="Ville">
                        <Input disabled type="text" defaultValue={profile.ville} />
                      </FormItem>

                      <div>
                        <Label>Code postale</Label>
                        <Input disabled type="text" name="codePostale" defaultValue={profile?.codePostale} />
                      </div>
                    </div>
                  </div>

                  <br />
                  <div className="flex gap-8 md:gap-5">
                    <div>
                      <Label>Bank </Label>
                      <Input disabled type="text" name="bank" defaultValue={profile?.bank} />
                    </div>
                    <div>
                      <Label>Numéro du compte bancaire (IBAN)</Label>
                      <Input disabled type="text" name="rib" defaultValue={profile?.rib} />
                    </div>
                  </div>
                  <br />
                </div>

                <h2 className="text-base font-semibold">Compte bancaire IBAN</h2>
                <div>
                  {profile?.iban_url ? (
                    <img
                      src={`${profile?.iban_url}`}
                      alt="Company Certificate"
                      className="mx-auto h-100 w-100 text-neutral-400 "
                    />
                  ) : (
                    <div className="mt-5 ">
                      <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                        <div className="space-y-1 text-center">
                          <svg
                            className="w-12 h-12 mx-auto text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                              htmlFor="adresseimg_url"
                              className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <span>Upload adresse</span>
                              <input id="adresseimg_url" name="adresseImg_url" type="file" className="sr-only" />
                            </label>
                            <p className="pl-1">Choisissez une facture de moins de 3 mois.</p>
                          </div>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <h2 className="text-base font-semibold">
                  Telecharger un extrait K-Bis ou un certificat d'inscription au registre des entreprises{" "}
                </h2>
                <p className="text-xs ">
                  Information: La copie de votre K-Bis ne doit pas être floutée ni tronquée. Vérifiez aussi sa date
                  d'expiration.
                </p>
                <br />

                <div>
                  {profile?.companyCertifImg_url ? (
                    <img
                      src={`${profile?.companyCertifImg_url}`}
                      alt="Company Certificate"
                      className="mx-auto h-100 w-100 text-neutral-400 "
                    />
                  ) : (
                    <div className="mt-5 ">
                      <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                        <div className="space-y-1 text-center">
                          <svg
                            className="w-12 h-12 mx-auto text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                              htmlFor="companyCertifimg_url"
                              className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <span className="mt-1 text-xs">Entreprise</span>

                              <input
                                id="companyCertifimg_url"
                                name="companyCertifImg_url"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                            <p className="pl-1">Choisissez votre certificat</p>
                          </div>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <p className="text-xs ">
                  En enregistrant les informations, vous acceptez le{" "}
                  <a href="https://stripe.com/legal/connect-account" className="font-semibold text-primary-500">
                    {" "}
                    Stripe Connected Account Agreement{" "}
                  </a>
                </p>
              </form>
            )}

            {/*  particular profil */}
            {profile?.category === "particular" && profile.lastName && (
              <form
                className="flex-grow max-w-3xl mt-10 space-y-6 md:mt-0 md:pl-16"
                encType="multipart/form-data"
                id="particulier"
              >
                <div className="flex items-start flex-shrink-0">
                  <div className="relative flex overflow-hidden rounded-full">
                    <Avatar sizeClass="w-32 h-32" imgUrl={`${profile?.owner_url}`} />
                  </div>
                </div>
                <div className="">
                  <div className="flex gap-8 ">
                    <div>
                      <Label>Nom </Label>
                      <Input disabled type="text" name="name" value={profile?.name} />
                    </div>
                    <div>
                      <Label>Prénom</Label>
                      <Input disabled type="text" name="lastName" value={profile?.lastName} />
                    </div>
                  </div>
                  <br />
                  <div className="flex gap-8 md:gap-5">
                    <div>
                      <Label>Date de naissance </Label>
                      <Input disabled type="date" name="birthday" defaultValue={profile?.birthday} />
                    </div>
                  </div>
                  <br />

                  <div className="flex gap-8 md:gap-5">
                    <div>
                      <Label>Numéro d'identité </Label>
                      <Input disabled type="number" name="identityNumber" defaultValue={profile?.identityNumber} />
                    </div>
                  </div>
                  <br />

                  <div className="flex gap-8 md:gap-5">
                    <div>
                      <Label>Numéro téléphone *</Label>

                      <PhoneInput
                        country={"fr"}
                        value={profile?.phone}
                        inputStyle={phoneInputStyle}
                        containerStyle={phoneInputStyle}
                        buttonStyle={phoneInputStyle}
                        dropdownStyle={phoneInputStyle}
                        searchStyle={phoneInputStyle}
                        inputProps={{ required: true }}
                      />
                    </div>
                  </div>
                  <br />

                  <div>
                    <div className="flex gap-8">
                      <div>
                        <Label>Adresse</Label>
                        <Input disabled type="text" name="adresse" defaultValue={profile?.adresse} />
                      </div>
                      <div>
                        <Label>Supplément d'adresse</Label>
                        <Input disabled type="text" name="suppAdresse" defaultValue={profile?.suppAdresse} />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex gap-8">
                      <FormItem label="Ville">
                        <Input disabled type="text" defaultValue={profile.ville} />
                      </FormItem>

                      <div>
                        <Label>Code postale</Label>
                        <Input disabled type="text" name="codePostale" defaultValue={profile?.codePostale} />
                      </div>
                    </div>
                  </div>

                  <br />
                  <div className="flex gap-8 md:gap-5">
                    <div>
                      <Label>Bank </Label>
                      <Input disabled type="text" name="bank" defaultValue={profile?.bank} />
                    </div>
                    <div>
                      <Label>Numéro du compte bancaire (IBAN)</Label>
                      <Input disabled type="text" name="rib" defaultValue={profile?.rib} />
                    </div>
                  </div>
                  <br />
                </div>
                <h2 className="text-base font-semibold">Joindre votre pièce d'identité</h2>
                <p className="text-xs ">
                  Information : La copie de votre pièce d'identité ne doit pas être floutée ni tronquée. Vérifiez
                  aussi sa date d'expiration.
                </p>
                {profile?.identityFImg_url ? (
                  <img
                    src={`${profile?.identityFImg_url}`}
                    alt="Company Certificate"
                    className="mx-auto h-100 w-100 text-neutral-400 "
                  />
                ) : (
                  <div>
                    <div className="mt-5 ">
                      <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                        <div className="space-y-1 text-center">
                          <svg
                            className="w-12 h-12 mx-auto text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                              htmlFor="IdentityFimg_url"
                              className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <span>Upload face avant</span>
                              <input
                                id="IdentityFimg_url"
                                name="identityFImg_url"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                            <p className="pl-1">
                              Choisissez une photo de votre carte d'identité ou passeport ou permis de conduire (en
                              cours de validité)
                            </p>
                          </div>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {profile?.identityBImg_url ? (
                    <img
                      src={`${profile?.identityBImg_url}`}
                      alt="Company Certificate"
                      className="mx-auto h-100 w-100 text-neutral-400 "
                    />
                  ) : (
                    <div className="mt-5 ">
                      <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                        <div className="space-y-1 text-center">
                          <svg
                            className="w-12 h-12 mx-auto text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                              htmlFor="IdentityBimg_url"
                              className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <span>Upload face arrière</span>
                              <input
                                id="IdentityBimg_url"
                                name="identityBImg_url"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                            <p className="pl-1">
                              Choisissez une photo de votre carte d'identité ou passeport ou permis de conduire (en
                              cours de validité)
                            </p>
                          </div>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <h2 className="text-base font-semibold">Compte bancaire IBAN</h2>
                <div>
                  {profile?.iban_url ? (
                    <img
                      src={`${profile?.iban_url}`}
                      alt="Company Certificate"
                      className="mx-auto h-100 w-100 text-neutral-400 "
                    />
                  ) : (
                    <div className="mt-5 ">
                      <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                        <div className="space-y-1 text-center">
                          <svg
                            className="w-12 h-12 mx-auto text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                              htmlFor="adresseimg_url"
                              className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <span>Upload adresse</span>
                              <input id="adresseimg_url" name="adresseImg_url" type="file" className="sr-only" />
                            </label>
                            <p className="pl-1">Choisissez une facture de moins de 3 mois.</p>
                          </div>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <h2 className="text-base font-semibold">Justificatif de domicile</h2>
                <p className="text-xs ">
                  Information : La copie de votre justificatif de domicile ne doit pas être floutée ni tronquée
                </p>
                <div>
                  {profile?.adresseImg_url ? (
                    <img
                      src={`${profile?.adresseImg_url}`}
                      alt="Company Certificate"
                      className="mx-auto h-100 w-100 text-neutral-400 "
                    />
                  ) : (
                    <div className="mt-5 ">
                      <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                        <div className="space-y-1 text-center">
                          <svg
                            className="w-12 h-12 mx-auto text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                              htmlFor="adresseimg_url"
                              className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                              <span>Upload adresse</span>
                              <input id="adresseimg_url" name="adresseImg_url" type="file" className="sr-only" />
                            </label>
                            <p className="pl-1">Choisissez une facture de moins de 3 mois.</p>
                          </div>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            )}
          </div>
          <div className="pt-2">
            <ButtonPrimary onClick={handleUpdate}>Mise à jour</ButtonPrimary>
          </div>
        </div>
      </OwnerMenu>
    </div>
  );
};

export default OwnerAccountPage;
