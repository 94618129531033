import { Spinner } from "@material-tailwind/react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Heading2 from "components/Heading/Heading2";
import LoginModal from "components/LoginModal/LoginModal";
import SectionBecomeAnAgent from "components/SectionBecomeAnAuthor/SectionBecomeAnAgent";
import ServiceCard from "components/ServiceCard/ServiceCard";
import { Service } from "data/types";
import { useAppDispatch } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { fetchAllServices, filterAllServices } from "redux/features/service/serviceSlice";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Pagination from "shared/Pagination/Pagination";
import ServiceFiltersPage from "./ServiceFiltersPage";
import CallToAction from "elements/call-to-action/CallToAction";
import CallToActionPage from "elements/call-to-action";
import { itemPerPrestationsPage } from "data/constants";
import { Helmet } from "react-helmet";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: Service[];
  title?: string;
  selectedEvent?: string;
  region?: string;
}

interface State {
  service: {
    allServices: Service[];
  };
}

const SectionGridFieldFilterCard: FC<SectionGridFilterCardProps> = ({ className = "" }) => {
  const { field } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerPrestationsPage;
  const dispatch = useAppDispatch();
  const serviceState: Service[] | null = useSelector((state: State) => state?.service?.allServices);
  const totalPages = Math.ceil(serviceState?.length / itemsPerPage);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const pathname = location.pathname;
  const decodedPathname = decodeURIComponent(pathname);
  const afterField = decodedPathname.split("/field/")[1];

  const fields = searchParams.get("field");
  const region = searchParams.get("region");

  useEffect(() => {
    if (fields || region || afterField) {
      let filter = {};

      if (fields) {
        filter = { fields };
      }
      if (afterField) {
        filter = { fields: afterField };
      }
      if (region) {
        filter = { ...filter, region };
      }
      dispatch(filterAllServices(filter)).then(() => setIsLoading(false));
    } else {
      dispatch(fetchAllServices()).then(() => setIsLoading(false));
    }
  }, []);

  const handleApplyFilters = (filters: any) => {
    let filter = {};

    if (filters.field) {
      filter = { fields: filters.field?.replace(/\s+/g, "-")?.toLowerCase() };
    }
    if (filters.region) {
      filter = { ...filter, region: filters.region?.replace(/\s+/g, "-")?.toLowerCase() };
    }

    if (Object.keys(filter).length > 0) {
      dispatch(filterAllServices(filter)).then();
    } else {
      dispatch(fetchAllServices()).then();
    }
  };
  const paginatedServices = Array.isArray(serviceState)
    ? serviceState.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const [showLoginModal, setShowLoginModal] = useState(false);

  return (
    <>
      <Helmet>
        <title>Event To Bee - Les Prestations</title>
        <meta name="description" content="Découvrez les détails des prestations sur Event To Bee." />
      </Helmet>
      <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
        {showLoginModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="flex flex-col items-center p-6 bg-white rounded-lg dark:bg-neutral-900">
                <div className="self-end mb-4">
                  <ButtonSecondary onClick={() => setShowLoginModal(false)}>X</ButtonSecondary>
                </div>
                <LoginModal LoginModal={(e) => setShowLoginModal(e)} />
              </div>
            </div>
          </div>
        )}
        <Heading2
          heading={
            <div className="flex items-center">
              <HiOutlineSquare3Stack3D className="w-5 h-5 mr-2 text-gray-500 lg:w-5 lg:h-5" />
              <span>Prestations</span>
            </div>
          }
        />
        <ServiceFiltersPage
          fields={fields}
          region={region}
          onApplyFilters={handleApplyFilters}
          handleDelete={() => {}}
        />

        <br />
        <br />

        {isLoading ? (
          paginatedServices.length === 0 ? (
            <div className="flex items-center justify-center" style={{ height: "300px" }}>
              <p className="font-semibold text">Aucun prestation disponible</p>
            </div>
          ) : (
            <div className="flex items-center justify-center mt-16">
              <p>
                <Spinner color="blue" className="w-12 h-12" />
              </p>
            </div>
          )
        ) : paginatedServices.length > 0 ? (
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {paginatedServices.map((service: any) => (
              <ServiceCard
                key={service.id}
                data={service}
                galleryImgs={service.images}
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
              />
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center" style={{ height: "300px" }}>
            <p className="font-semibold text">Aucun prestation disponible</p>
          </div>
        )}
        <div className="flex items-center justify-center mt-16 mb-10">
          {typeof totalPages === "number" && (
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          )}
        </div>
        {/* AUTHOR SECTION */}
        <div className="relative py-16">
          <CallToActionPage />
        </div>
      </div>
    </>
  );
};

export default SectionGridFieldFilterCard;
