import React, { useEffect, useState } from "react";
import { FC } from "react";
import { Link } from "react-router-dom";

import { Card, Typography, List, ListItem, ListItemPrefix, Accordion } from "@material-tailwind/react";
import { UserCircleIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import jwtDecode from "jwt-decode";

export interface CommonLayoutProps {
  children?: React.ReactNode;
  ownerName?: string;
  userId?: string;
}

const OwnerMenu: FC<CommonLayoutProps> = ({ children, ownerName, userId }) => {
  const [open, setOpen] = React.useState(0);
  const [profil, setProfil] = useState("");
  const server = process.env.REACT_APP_SERVER_URL;
  const token = localStorage.getItem("token") || "";
  let userIdd: any;
  if (token) {
    const decodedToken = jwtDecode(token) as {
      userId: string;
    };
    userIdd = decodedToken.userId;
  }
  /*   useEffect(() => {
    fetch(`${server}/api/owner/user/${userIdd}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        return response.json();
      })
      .then((data) => {
        setProfil(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userId]); */

  const handleOpen = (value: React.SetStateAction<number>) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="flex flex-col lg:flex-row bg-neutral-50 dark:bg-neutral-900">
      <div className="p-6 border-r lg:w-1/4 border-neutral-200 dark:border-neutral-700">
        <Card className="h-full lg:h-[calc(100vh-2rem)] w-full max-w-[20rem] lg:max-w-none p-4 shadow-xl shadow-blue-gray-900/5">
          <div className="p-4 mb-2">
            <Typography variant="h5" color="blue-gray">
              Bienvenue <span className="text-red-500"></span>
            </Typography>
          </div>
          <List>
            <Accordion
              open={open === 1}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                />
              }
            >
              <ListItem>
                <ListItemPrefix>
                  <UserCircleIcon className="w-5 h-5" />
                </ListItemPrefix>
                <Link to={`/owner`}>Profil</Link>
              </ListItem>

              <ListItem>
                <ListItemPrefix>
                  <LockClosedIcon className="w-5 h-5" />
                </ListItemPrefix>
                <Link to="/owner-password">Mot de passe</Link>
              </ListItem>
            </Accordion>
          </List>
        </Card>
      </div>
      <div className="container pt-14 pb-24 sm:pt-20 lg:pb-32">{children}</div>
    </div>
  );
};

export default OwnerMenu;
