import { zodResolver } from "@hookform/resolvers/zod";
import { Radio, Textarea } from "@material-tailwind/react";
import Label from "components/Label/Label";
import { AuthContext } from "context/appContext";
import { Fields, ProfilClient, ProfilService } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import {
  ClientProfileUpdate,
  ServiceProfileUpdate,
  getFields,
  resetValues,
} from "redux/features/signUp/signUpSlice";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { z } from "zod";
import __cities from "../../../data/jsons/cities.json";
import { useNavigate } from "react-router-dom";
import CommonLayout from "./CommonLayout";
import { currentUser } from "redux/features/auth/authSlice";

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

const formSchema = z.object({
  name: z.string().min(1, { message: "Le Nom est obligatoire." }),
  lastName: z.string().min(1, { message: "Le Prénom est obligatoire." }),
  phone: z
    .string()
    .min(1, { message: "Le numéro téléphone est obligatoire." })
    .refine((value) => !/^0{11}$/.test(value), {
      message: "Le numéro de téléphone ne peut pas comporter zéros consécutifs.",
    }),

  client_url: z
    .optional(z.instanceof(File, { message: "L'image est obligatoire." }))
    .refine(
      (file) => !file || (file?.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type)),
      "Le fichier doit être une image au format JPEG, PNG ou GIF et ne pas dépasser 10MB."
    ),
  dateOfBirth: z.string().min(1, { message: "La date de naissance est obligatoire." }),
});

const ClientUpdateAccountPage: React.FC = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.auth?.user?.profil as ProfilClient);
  const signUpState = useAppSelector((state) => state.signUp);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let profilId: number | undefined;
  profilId = authContext.user?.profil?.id;

  let profile = authContext.user?.profil as ProfilClient;
  useEffect(() => {
    if (userState) {
      setValue("name", userState.name || "");
      setValue("lastName", userState.lastName || "");
      setValue("phone", userState.phone || "");
      setValue("dateOfBirth", userState.dateOfBirth || "");
    }
  }, [userState]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    await dispatch(ClientProfileUpdate({ data: data, id: profilId }));
    dispatch(currentUser()).then(() => {
      dispatch(resetValues());
      navigate("/client");
    });
  };
  const [phoneBackground, setPhoneBackground] = useState("");

  useEffect(() => {
    let theme = localStorage.getItem("theme");
    if (theme === "dark") {
      setPhoneBackground("rgb(31 41 55)");
    } else {
      setPhoneBackground("#FFFFFF");
    }
  }, []);

  const phoneInputStyle = {
    border: "0.5px solid #CBD5E0",
    borderRadius: "0.7rem",
    backgroundColor: phoneBackground,
  };
  const dropdownStyle = {
    border: "0.5px solid #CBD5E0",
    borderRadius: "0.7rem",
    backgroundColor: phoneBackground,
    width: "calc(100% )",
  };

  useEffect(() => {
    switch (signUpState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [signUpState.loading]);

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}

          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col md:flex-row"
            encType="multipart/form-data"
          >
            <div className="flex items-start flex-shrink-0">
              <div className="flex-col items-start flex-shrink-0">
                <div className="relative flex overflow-hidden rounded-full">
                  <Avatar
                    sizeClass="w-32 h-32"
                    imgUrl={
                      getValues("client_url")
                        ? URL.createObjectURL(getValues("client_url") as File)
                        : process.env.REACT_APP_CLOUD_FRONT + userState?.client_url
                    }
                  />
                  {getValues("client_url") ? null : (
                    <div className="absolute inset-0 flex flex-col items-center justify-center bg-black cursor-pointer bg-opacity-60 text-neutral-50">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      ></svg>
                      <span className="mt-1 text-xs">Selectionner Image</span>
                    </div>
                  )}

                  <input
                    type="file"
                    id="imageFile"
                    name="imageFile"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        setValue("client_url", file);
                        trigger("client_url");
                      }
                    }}
                    accept="image/jpeg, image/jpg, image/png, image/webp"
                  />
                </div>
              </div>
            </div>
            <div className="flex-grow max-w-3xl mt-10 space-y-6 md:mt-0 md:pl-16">
              <div>
                <Label>Nom</Label>
                <Input
                  type="text"
                  className={`mt-1.5 md:w-80 lg:w-80 ${errors.name?.message ? "border-red-500" : ""}`}
                  {...register("name")}
                />
              </div>

              <div>
                <Label>Prénom</Label>
                <Input
                  type="text"
                  className={`mt-1.5 md:w-80 lg:w-80 ${errors.lastName?.message ? "border-red-500" : ""}`}
                  {...register("lastName")}
                />
              </div>

              <div className="">
                <Label>Date de naissance *</Label>
                <Input
                  type="date"
                  placeholder="Date de naissance"
                  className={`mt-1.5 md:w-80 lg:w-80 ${errors.dateOfBirth?.message ? "border-red-500" : ""}`}
                  disabled={isLoading}
                  {...register("dateOfBirth")}
                />
                {errors.dateOfBirth?.message && (
                  <div className="text-xs text-red-500">{errors.dateOfBirth?.message}</div>
                )}
              </div>
              <div className="flex gap-8 mt-2 md:gap-5">
                <div>
                  <Label>Numéro téléphone *</Label>
                  <div className="w-full   ">
                    <PhoneInput
                      country={"fr"}
                      inputStyle={phoneInputStyle}
                      containerStyle={phoneInputStyle}
                      buttonStyle={phoneInputStyle}
                      dropdownStyle={dropdownStyle}
                      searchStyle={phoneInputStyle}
                      inputProps={{ required: true }}
                      value={getValues("phone")}
                      onChange={(value) => setValue("phone", value)}
                    />
                  </div>
                </div>
              </div>

              <div className="pt-2">
                <ButtonPrimary type="submit" className="w-full md:w-1/4" loading={isLoading}>
                  Modifier
                </ButtonPrimary>
              </div>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default ClientUpdateAccountPage;
