import { FC, useState } from "react";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WidgetTags from "./WidgetTags";
import Card3 from "./Card3";
import HeadingTags from "components/Heading/HeadingTags";

export interface SectionLatestPostsProps {
  posts?: any[];
  className?: string;
  postCardName?: "card3";
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  posts = [],
  postCardName = "card3",
  className = "",
}) => {
  const renderCard = (post: any) => {
    switch (postCardName) {
      case "card3":
        return <Card3 key={post.id} className="" post={post} />;

      default:
        return null;
    }
  };
  const [visibleBlogs, setVisibleBlogs] = useState<number>(6);
  const loadMoreBlogs = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 6);
  };
  const loadLessBlogs = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs - 6);
  };

  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
          <HeadingTags>Derniers articles 🎈</HeadingTags>
          <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
            {Array.isArray(posts) && posts.slice(0, visibleBlogs).map((post) => renderCard(post))}
          </div>
          {visibleBlogs < posts.length && posts.length > 6 && (
            <div className="flex flex-col mt-12 space-y-5 md:mt-20 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <Pagination />
              <ButtonPrimary onClick={loadMoreBlogs}>Voir plus</ButtonPrimary>
            </div>
          )}
          {visibleBlogs >= posts.length && posts.length > 6 && (
            <div className="flex flex-col mt-12 space-y-5 md:mt-20 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <Pagination />
              <ButtonPrimary onClick={loadLessBlogs}>Voir moins</ButtonPrimary>
            </div>
          )}
        </div>
        <div className="w-full mt-24 space-y-7 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">
          <WidgetTags />
        </div>
      </div>
    </div>
  );
};

export default SectionLatestPosts;
