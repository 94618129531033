import SectionBecomeAnOwner from "components/SectionBecomeAnAuthor/SectionBecomeAnOwner";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { resetValues } from "redux/features/location/locationSlice";

export interface AccountPageProps {
  className?: string;
}

const AddingMessage: FC<AccountPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetValues());
  });

  const { addUrl } = useParams();

  return (
    <div className={`pr-32 pb-24 pl-10 mx-auto nc-AccountPage lg:pb-32 ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>{`Event To Bee - ${addUrl === "location" ? "Création d'espace" : "Création du prestation"} `}</title>
        <meta
          name="description"
          content={`${addUrl === "location" ? "Création d'espace à louer pour votre évènement sur Event To Bee" : "Création du prestation sur Event To Bee"}`}
        />
      </Helmet>

      <div className="space-y-6 sm:space-y-8">
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        <div className="flex flex-col md:flex-row">
          <SectionBecomeAnOwner type={addUrl} />
        </div>
      </div>
    </div>
  );
};

export default AddingMessage;
