import { TrashIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "context/appContext";
import { ServiceFormProps } from "data/reduxInterfaces";
import { FC, Fragment, HTMLAttributes, useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const MAX_FILE_SIZE = 1024 * 1024 * 50;
const ACCEPTED_TARIF_MIME_TYPES = [
  "application/pdf",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/webp",
];
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];

const formSchema = z.object({
  serviceAgentId: z.number(),
  tarif: z
    .instanceof(File, {
      message: "Document est obligatoire.",
    })
    .optional()
    .refine((file: File | undefined) => {
      return !file || ACCEPTED_TARIF_MIME_TYPES.includes(file.type);
    }, "Le fichier doit être un fichier pdf ou une image au format JPEG, PNG ou GIF et ne pas dépasser 50MB."),
  images: z
    .array(
      z.instanceof(File, {
        message: "L'image est obligatoire.",
      })
    )
    .refine((files) => {
      return (
        !files ||
        files.every(
          (file) => !file || (file?.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type))
        )
      );
    }, "Le fichier doit être une image au format JPEG, PNG, WEBP ou GIF et ne pas dépasser 50MB."),
});
const ServiceFilesUpload: FC<ServiceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  errorServiceForm,
  FormData,
}) => {
  const authContext = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      serviceAgentId: authContext.user?.profil?.id ?? undefined,
      tarif: undefined,
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    if (handleForm.type === "chekServiceFormThree") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm.type]);
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    FormData(data);
  };

  const [images, setImages] = useState<File[]>([]);
  const handleDeleteNewImage = (index: number) => {
    setImages((prevImages) => {
      const updatedNewImages = prevImages.filter((_, i) => i !== index);
      return updatedNewImages;
    });
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files) {
      const fileArray = Array.from(files);
      setImages((prevImages) => [...prevImages, ...fileArray]);
      setValue("images", [...images, ...fileArray]);
      trigger("images");
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <span className="text-2xl font-semibold">Images de prestation</span>

          <div className="mb-8" onDrop={handleDrop} onDragOver={handleDragOver}>
            <div className="mb-8">
              <div className="flex flex-wrap gap-2 mt-2">
                {images.map((image, index) => (
                  <div key={index} className="relative">
                    <div className="relative">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Newly uploaded ${index}`}
                        className="h-auto max-w-xs max-h-40"
                      />
                      <TrashIcon
                        className="absolute w-5 text-white bg-black rounded-full cursor-pointer top-2 right-2 h-7 las la-trash-alt"
                        onClick={() => handleDeleteNewImage(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <br />
              <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                <div className="space-y-1 text-center">
                  <svg
                    className="w-12 h-12 mx-auto text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <label
                      htmlFor="fileInput"
                      className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Ajouter</span>
                      <input
                        id="fileInput"
                        name="images"
                        type="file"
                        multiple
                        className="sr-only"
                        onChange={(e) => {
                          const files = e.target.files;
                          if (files) {
                            const fileArray = Array.from(files);
                            setImages((prevImages) => [...prevImages, ...fileArray]);
                            setValue("images", [...images, ...fileArray]);
                            trigger("images");
                          }
                        }}
                        accept="image/jpeg, image/jpg, image/png, image/webp"
                      />
                    </label>
                    <p className="pl-1">ou déposer</p>
                  </div>
                  <p className="text-xs text-neutral-500 dark:text-neutral-400">
                    PNG, JPG, GIF, WEBP jusqu'à 50MB
                  </p>
                </div>
              </div>
            </div>
          </div>
          {errors.images?.message && (
            <div className="text-xs text-red-500">{errors.images?.message.toString()}</div>
          )}

          <span className="text-2xl font-semibold">Document du tarif</span>

          <div className="mt-3">
            <input
              className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
              type="file"
              id="formFile"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  setValue("tarif", file);
                  trigger("tarif");
                }
              }}
            />
            {errors.tarif?.message && (
              <div className="text-xs text-red-500">{errors.tarif?.message.toString()}</div>
            )}
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default ServiceFilesUpload;
