import CallToAction from "./CallToAction";

const CallToActionPage = () => {
  return (
    <>
      <div
        className={`flex relative flex-col items-center nc-SectionBecomeAnAuthor lg:flex-row`}
        data-nc-id="SectionBecomeAnAuthor"
      >
        <div className="container rbt-callto-action rbt-cta-default style-4 bg-gradient-6 mt--75 ">
          <CallToAction btnClass="rbt-btn btn-gradient hover-icon-reverse " />
        </div>
      </div>
    </>
  );
};

export default CallToActionPage;
