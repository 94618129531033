import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ownerApi } from "./ownerAPI";

interface OwnerState {
  id?: number;
  name?: string;
  lastName?: string;
  identityNumber?: string;
  rib?: string;
  bank?: string;
  phone?: string;
  birthday?: string;
  identityFImg_url?: string;
  identityBImg_url?: string;
  adresseImg_url?: string;
  pays?: string | null;
  companyCertifImg_url?: string;
  companyName?: string | null;
  companySiren?: string | null;
  adresse?: string;
  codePostale?: string;
  suppAdresse?: string;
  ville?: string;
  iban_url?: string;
  owner_url?: string;
  validation?: boolean;
  category?: string;
  createdAt?: string;
  updatedAt?: string;
  userId?: number;
  owners?: [];
  rating: { comments: 0; roundedMoyenne: 0 };
  ownerDetail: {
    id: 0;
    name: "";
    lastName: "";
    identityNumber: "";
    rib: "";
    bank: "";
    phone: "";
    birthday: "";
    identityFImg_url: "";
    identityBImg_url: "";
    adresseImg_url: "";
    pays: null;
    companyCertifImg_url: "";
    companyName: null;
    companySiren: null;
    adresse: "";
    codePostale: "";
    suppAdresse: "";
    ville: "";
    iban_url: "";
    owner_url: "";
    validation: false;
    category: "";
    createdAt: "";
    updatedAt: "";
    userId: 0;
  };
}

const initialState: OwnerState = {
  id: 0,
  name: "",
  lastName: "",
  identityNumber: "",
  rib: "",
  bank: "",
  phone: "",
  birthday: "",
  identityFImg_url: "",
  identityBImg_url: "",
  adresseImg_url: "",
  pays: null,
  companyCertifImg_url: "",
  companyName: null,
  companySiren: null,
  adresse: "",
  codePostale: "",
  suppAdresse: "",
  ville: "",
  iban_url: "",
  owner_url: "",
  validation: false,
  category: "",
  createdAt: "",
  updatedAt: "",
  userId: 0,
  rating: { comments: 0, roundedMoyenne: 0 },
  ownerDetail: {
    id: 0,
    name: "",
    lastName: "",
    identityNumber: "",
    rib: "",
    bank: "",
    phone: "",
    birthday: "",
    identityFImg_url: "",
    identityBImg_url: "",
    adresseImg_url: "",
    pays: null,
    companyCertifImg_url: "",
    companyName: null,
    companySiren: null,
    adresse: "",
    codePostale: "",
    suppAdresse: "",
    ville: "",
    iban_url: "",
    owner_url: "",
    validation: false,
    category: "",
    createdAt: "",
    updatedAt: "",
    userId: 0,
  },
};

export const fetchOwners = createAsyncThunk("featureOwner/fetchOwner", async () => {
  return await ownerApi.getOwners();
});
export const fetchOwnerById = createAsyncThunk("featureOwner/fetchOwnerById", async (id: any) => {
  return await ownerApi.getOwnerById(id);
});
export const fetchOwnerRating = createAsyncThunk("featureOwner/fetchOwnerRating", async (id: any) => {
  return await ownerApi.getOwnerRating(id);
});

const createOwnerSlice = createSlice({
  name: "featureOwner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOwners.fulfilled, (state, action) => {
        state.owners = action.payload;
      })
      .addCase(fetchOwnerById.fulfilled, (state, action) => {
        state.ownerDetail = action.payload;
      })
      .addCase(fetchOwnerRating.fulfilled, (state, action) => {
        state.rating = action.payload;
      });
  },
});

export const {} = createOwnerSlice.actions;
export default createOwnerSlice.reducer;
