import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import DeleteModal from "components/DeleteModal/DeleteModal";
import { User } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { deleteUser } from "redux/features/auth/authSlice";
import { getAllIncompletedLocationsByAdmin } from "redux/features/location/locationSlice";
import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";
import DashboardMenu from "../DashboardPage/DashboardMenu";
import ValidateLocationsModal from "./ValidateLocationsModal";
import AllLocationsPage from "./AllLocationsPage";

export interface ListIncompletedLocationsPageProps {
  className?: string;
}

const ListIncompletedLocationsPage: FC<ListIncompletedLocationsPageProps> = ({ className = "" }) => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const locations = useAppSelector((state) => state?.location?.incompletedLocations);
  useEffect(() => {
    dispatch(getAllIncompletedLocationsByAdmin(page));
  }, [page, dispatch]);

  const [searchTerm, setSearchTerm] = useState("");

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<User | null>(null);
  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDeleteConfirm = async () => {
    if (selectedLocation && selectedLocation.User?.id !== undefined) {
      setDeleting(true);
      await dispatch(deleteUser(selectedLocation.User?.id));
      setDeleting(false);
      setDeleteModalOpen(false);
      await dispatch(getAllIncompletedLocationsByAdmin(page));
    }
  };

  const handleEyeIconClick = (location: any) => {
    setSelectedLocation(location);
    setShowLocationDetails(true);
    setDeleteModalOpen(false);
  };

  const [updateModal, setUpdateModal] = useState(false);

  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [selectedItemtTitle, setSelectedItemTitle] = useState<string | null>(null);
  const [selectedItemt, setSelectedItem] = useState<string | null>(null);
  const handleUpdate = (itemId: number, itemTitle: string, item: any) => {
    setSelectedItemId(itemId);
    setSelectedItemTitle(itemTitle);
    setSelectedItem(item);
    setUpdateModal(true);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <AllLocationsPage>
          <div className="flex flex-col">
            {updateModal && (
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="fixed inset-0 bg-black opacity-50"></div>

                <div className="fixed inset-0 z-50 flex items-center justify-center">
                  <div className="flex flex-col items-center p-6 overflow-auto bg-white rounded-lg dark:bg-neutral-900">
                    <ValidateLocationsModal
                      onClose={(value) => {
                        setUpdateModal(value);
                        setSelectedItemId(null);
                      }}
                      id={selectedItemId}
                      title={selectedItemtTitle}
                      locationData={selectedItemt}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center justify-between mb-4">
              <div></div>
              <div className="relative flex">
                <Input
                  type="text"
                  placeholder="Rechercher par nom"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-500 transform -translate-y-1/2 right-3 top-1/2" />
              </div>
            </div>
            {deleteModalOpen && (
              <DeleteModal
                open={deleteModalOpen}
                handleClose={() => setDeleteModalOpen(false)}
                handleDelete={handleDeleteConfirm}
                category={{
                  id: selectedLocation?.User?.id || 0,
                  designation: selectedLocation?.User?.login || "",
                }}
              />
            )}

            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-sm font-light text-center">
                    <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                      <tr>
                        <th scope="col" className="px-6 py-4">
                          Titre
                        </th>
                        <th scope="col" className="px-6 py-4">
                          Status
                        </th>

                        <th scope="col" className="px-6 py-4">
                          Consulter
                        </th>
                        <th scope="col" className="px-6 py-4">
                          Modifier
                        </th>
                        <th scope="col" className="px-6 py-4">
                          Supprimer
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {locations?.locations &&
                        locations?.locations
                          .filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((item, index) => (
                            <tr className="border-b dark:border-neutral-500" key={index}>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">{item.title}</td>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">{item.validation}</td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <Link to={`/dashboard/location/${item.id}`}>
                                  <i className="text-2xl cursor-pointer las la-eye"></i>{" "}
                                </Link>{" "}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <i
                                  className="text-2xl las la-edit"
                                  onClick={() => handleUpdate(item.id, item.title, item)}
                                ></i>{" "}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <i
                                  className="text-2xl cursor-pointer las la-trash-alt"
                                  //onClick={() => handleDeleteModalOpen(item)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  <div className="flex justify-end">
                    <span className="text-sm text-gray-700">{locations?.count} élément</span>
                  </div>

                  <div className="flex items-center justify-center mt-11">
                    {typeof locations?.totalPages === "number" && (
                      <Pagination
                        currentPage={page}
                        totalPages={locations?.totalPages}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AllLocationsPage>
      </DashboardMenu>
    </div>
  );
};

export default ListIncompletedLocationsPage;
