import React from "react";
import { Link } from "react-router-dom";
import logoLightImg from "images/logo-light.png";
import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";
import LogoImg from "../../images/logos/Black-EVENT TO BEE-17.svg";
import LogoImgN from "../../images/logos/White-EVENT TO BEE-20.svg";
export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const LogoN: React.FC<LogoProps> = ({ img = LogoImg, imgLight = logoLightImg, className = "w-24" }) => {
  return (
    <Link
      to="/"
      className={`inline-block ttnc-logo text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {/* <LogoSvgLight />
      <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block max-h-50 rounded-none ${LogoImgN ? "dark:hidden" : ""}`}
          src={LogoImgN}
          alt="logoImg"
        />
      ) : (
        "Logo Here"
      )}
      {LogoImgN && <img className="hidden rounded-none max-h-50 dark:block" src={LogoImgN} alt="LogoImgN" />}
    </Link>
  );
};

export default LogoN;
