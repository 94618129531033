import { FC, useContext, useEffect, useState } from "react";
import Pagination from "shared/Pagination/Pagination";
import { useAppSelector } from "hooks/hooks";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AuthContext } from "context/appContext";

export interface SectionGridCanceledCardOwnerProps {
  className?: string;
}

const SectionGridCanceledCardOwner: FC<SectionGridCanceledCardOwnerProps> = ({ className = "" }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  let canceledReservationState = useAppSelector((state) => state.featureReservation.reservations);
  useEffect(() => {}, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const paginatedStays = canceledReservationState.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const formatDate = (dateString: any) => {
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(canceledReservationState.length / itemsPerPage);

  return (
    <div className={`w-full  rounded-md ${className}`}>
      <div className="flex items-center justify-between pb-1">
        <div>
          <h2 className="font-semibold text-gray-600">Liste Réservations annulés</h2>
        </div>
      </div>
      <div>
        <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
          <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                    Images
                  </th>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                    Titre
                  </th>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                    Client
                  </th>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                    Jour
                  </th>
                  <th className="px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
                    Temps
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedStays.map((stay: any) => (
                  <tr key={stay.id}>
                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10">
                          <img
                            className="w-full h-full rounded-full"
                            src={`${stay?.Location?.images[0]}`}
                            alt=""
                          />
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                      <p className="text-gray-900 whitespace-no-wrap">{stay?.Location?.title}</p>
                    </td>

                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {stay?.Client?.name} {stay?.Client?.lastName}
                      </p>
                    </td>
                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                      <p className="text-gray-900 whitespace-no-wrap">{formatDate(stay.selectedDate)}</p>
                    </td>
                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {stay.startHour}-{stay.endHour}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex items-center justify-center mt-16">
          {typeof totalPages === "number" && (
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          )}
        </div>
      </div>
      {canceledReservationState?.length === 0 && (
        <div className="flex items-center justify-center mt-16">
          <p>Pas d'espaces , ajouter votre espace</p>
        </div>
      )}
    </div>
  );
};

export default SectionGridCanceledCardOwner;
