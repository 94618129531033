import React, { FC, useContext, useEffect, useState } from "react";
import Faq from "./faq";
import { DefaultAccordion } from "../../components/Accordion/accordion";

export interface PropretaireFaq {
  className?: string;
}
const propretaireFaq: FC<PropretaireFaq> = ({ className = "" }) => {
  return (
    <>
      <Faq>
        <DefaultAccordion
          question={"Comment se connecter ?"}
          answer={
            "Bienvenue sur Event to Bee ! Pour profiter pleinement de nos services, suivez ces étapes simples pour créer votre compte ou bien se connecter"
          }
        >
          <ol className="ol">
            <li>
              <span>
                {" "}
                Création de compte Si vous êtes nouveau sur Event to bee, vous devrez créer un compte pour profiter
                pleinement de nos services. <b> Suivez ces étapes simples:</b>
              </span>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Accédez à la page de connexion:&nbsp; </b> Cliquez sur le bouton "Compte" depuis la page
                    d'accueil pour accéder à la page de connexion.
                  </li>

                  <li>
                    <b> Choisissez votre type de compte: &nbsp; </b> Sélectionnez le type de compte qui correspond
                    à votre rôle sur la plateforme : Client, Prestataire, Propriétaire.
                  </li>

                  <li>
                    {" "}
                    <b> Remplissez les champs obligatoires: &nbsp; </b> Suivez les étapes indiquées et complétez
                    les informations nécessaires.
                  </li>
                  <li>
                    {" "}
                    <b> Cliquez sur "S'inscrire":&nbsp; </b> Une fois que vous avez rempli les champs, cliquez sur
                    le bouton "S'inscrire" pour créer votre compte.{" "}
                  </li>

                  <li>
                    {" "}
                    <b>Vérifiez votre adresse e-mail: &nbsp; </b> Consultez votre boîte de réception, où vous
                    trouverez un e-mail de confirmation. Cliquez sur le lien fourni pour activer votre compte.{" "}
                  </li>
                </ul>
              </p>
            </li>

            <li>
              <span style={{ justifyContent: "start" }}>
                {" "}
                Connexion à votre compte existant Si vous avez déjà un compte,
                <b> suivez ces étapes pour vous connecter :</b>{" "}
              </span>
              <ul className="c" style={{ listStyleType: "square" }}>
                <p style={{ textAlign: "start", margin: "15px" }}>
                  <li>
                    <b> Accédez à la page de connexion : &nbsp; </b>Cliquez sur le bouton "Compte" depuis la page
                    d'accueil pour accéder à la page de connexion.
                  </li>
                  <li>
                    <b> Saisissez vos identifiants : &nbsp; </b> Entrez votre adresse e-mail et votre mot de passe
                    dans les champs appropriés.
                  </li>
                  <li>
                    <b> Cliquez sur "Se connecter" : &nbsp; </b> Une fois vos informations saisies, cliquez sur le
                    bouton "Se connecter" pour accéder à votre compte Event to Bee.
                  </li>
                  <li>
                    {" "}
                    Si vous avez oublié votre mot de passe, cliquez sur "Mot de passe oublié" pour réinitialiser
                    votre mot de passe.
                  </li>
                </p>
              </ul>
            </li>
          </ol>
        </DefaultAccordion>

        <DefaultAccordion question={"Comment créer un espace en tant que propriétaire ?"}>
          <ol>
            <li>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Connectez-vous à votre compte: &nbsp; </b>Accédez à votre compte propriétaire sur Event to
                    Bee en utilisant vos identifiants.
                  </li>{" "}
                  <li>
                    <b> Accédez à la section "Mes espaces": &nbsp; </b>Une fois connecté, recherchez la section
                    dédiée à la gestion des espaces dans le tableau de bord.
                  </li>
                  <li>
                    <b> Cliquez sur "Ajouter un nouvel espace" : &nbsp; </b> Sélectionnez l'option pour ajouter un
                    nouvel espace à votre liste.
                  </li>
                  <li>
                    <b> Remplissez les informations requises : &nbsp; </b> Complétez les détails nécessaires pour
                    décrire votre espace, y compris son nom, sa localisation, sa capacité, et tout autre
                    renseignement pertinent.
                  </li>
                  <li>
                    <b> Téléchargez des photos :&nbsp; </b> Ajoutez des images de qualité pour présenter votre
                    espace de manière attrayante. Les photos peuvent avoir un impact significatif sur la décision
                    des clients potentiels.
                  </li>
                  <li>
                    <b> Configurez les disponibilités : &nbsp; </b> Définissez les horaires de disponibilité de
                    votre espace pour que les clients puissent planifier leurs événements en fonction de vos
                    créneaux.
                  </li>
                  <li>
                    {" "}
                    <b>Fixez le tarif : &nbsp; </b> Indiquez le coût de la location de votre espace et précisez les
                    modalités de paiement.
                  </li>
                  <li>
                    {" "}
                    <b>Révisez les conditions d'utilisation : &nbsp; </b> Examinez et acceptez les conditions
                    d'utilisation de la plateforme pour la mise en location de votre espace.
                  </li>
                  <li>
                    {" "}
                    <b>Cliquez sur "Publier" : &nbsp; </b> Une fois toutes les informations correctement saisies,
                    cliquez sur le bouton approprié pour publier votre espace sur la plateforme.
                  </li>
                </ul>
              </p>
            </li>
          </ol>
        </DefaultAccordion>

        <DefaultAccordion question={"Comment gérer mes reservation ?"}>
          <ol>
            <li>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Connectez-vous à votre compte : &nbsp; </b>Accédez à votre compte propriétaire sur Event to
                    Bee en utilisant vos identifiants.
                  </li>{" "}
                  <li>
                    <b> Accédez à la section "Ma reservation" : &nbsp; </b>Une fois connecté, recherchez la section
                    dédiée à la gestion des réservations dans le tableau de bord.
                  </li>
                  <li>
                    <b> Vue d'Ensemble : &nbsp; </b> Consultez une vue d'ensemble de toutes vos réservations
                    actuelles et passées Détails de la Réservation : Cliquez sur chaque réservation pour accéder
                    aux détails spécifiques tels que la date, l'heure, les clients et les spécifications de
                    l'événement.
                    <br />
                    En tant que <b> propriétaire</b> sur notre plateforme, vous avez la flexibilité de réserver
                    votre propre espace pour des dates spécifiques. Si vous souhaitez utiliser votre propre espace
                    à une date donnée
                  </li>
                </ul>
              </p>
            </li>
          </ol>
        </DefaultAccordion>

        <DefaultAccordion question={"Comment gérer le paiement ?"}>
          <ol>
            <li>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Vérification des Détails : &nbsp; </b>Notre équipe examine attentivement les détails de
                    l'événement pour garantir un déroulement sans problème, vérifiant qu'aucune réclamation ou
                    problème n'a été signalé par le client.
                  </li>{" "}
                  <li>
                    <b> Paiement au Propriétaire : &nbsp; </b>Lorsque tous les détails sont confirmés et que tout
                    est conforme, notre application effectue automatiquement le paiement au propriétaire de
                    l'espace. Ce processus de paiement sera initié dans un délai de 48 heures à compter de la date
                    de l'événement.
                  </li>
                </ul>
              </p>
            </li>
          </ol>
        </DefaultAccordion>
      </Faq>{" "}
    </>
  );
};

export default propretaireFaq;
