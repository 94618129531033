import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createRule } from "./featureAPI";

const initialState = {
  designation: "",
  loading: "idle",
  rules: [],
  rule: { designation: "" },
};

export const addRule = createAsyncThunk("featureRule/addRule", async (data: any) => {
  return await createRule.add(data);
});

export const fetchRules = createAsyncThunk("featureRule/fetchRule", async () => {
  return await createRule.verify();
});

export const deleteRule = createAsyncThunk("featureRule/deleteRule", async (id: number) => {
  return await createRule.delete(id);
});

export const updateRule = createAsyncThunk(
  "featureRule/updateRule",
  async ({ data, id }: { data: any; id: any }) => {
    return await createRule.update(data, id);
  }
);

export const getRuleById = createAsyncThunk("featureRule/getRuleById", async (id: any) => {
  return await createRule.getById(id);
});
const createRuleSlice = createSlice({
  name: "featureRule",
  initialState,
  reducers: {
    setDesignation(state, action) {
      state.designation = action.payload;
    },
    resetDesignation(state) {
      state.designation = "";
    },
    resetValues(state) {
      state.designation = "";
      state.loading = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addRule.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addRule.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(addRule.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchRules.fulfilled, (state, action) => {
        state.rules = action.payload;
      })
      .addCase(getRuleById.fulfilled, (state, action) => {
        state.rule = action.payload;
      })
      .addCase(deleteRule.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteRule.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(deleteRule.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(updateRule.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateRule.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(updateRule.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const { setDesignation, resetDesignation, resetValues } = createRuleSlice.actions;
export default createRuleSlice.reducer;
