import { zodResolver } from "@hookform/resolvers/zod";
import { CardFooter, Typography } from "@material-tailwind/react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { Jodit } from "jodit-react";
import Multiselect from "multiselect-react-dropdown";
import { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { getAllUsers } from "redux/features/auth/authSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { z } from "zod";
import DashboardMenu from "../../DashboardPage/DashboardMenu";
const baseURL = process.env.REACT_APP_SERVER_URL;

export interface AdminSendEmailsProps {
  className?: string;
}

interface SelectedValue {
  label: string;
}

const formSchema = z.object({
  subject: z.string().min(1, { message: "Le Sujet est obligatoire." }),
  receivers: z.array(z.string()).nonempty({ message: "Utilisateur est obligatoire." }),
  content: z.string().min(1, { message: "La description est obligatoire." }),
});

const AdminSendEmails: FC<AdminSendEmailsProps> = ({ className = "" }) => {
  const [defaultEmail, setDefaultEmail] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<SelectedValue[]>([]);
  const {
    register,
    handleSubmit: handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const editorRef = useRef<HTMLTextAreaElement | null>(null);
  const [content, setContent] = useState<string>("");
  useEffect(() => {
    if (editorRef.current) {
      const editor = new Jodit(editorRef.current, {
        buttons: [
          "font",
          "bold",
          "italic",
          "underline",
          "|",
          "justifyleft",
          "justifycenter",
          "justifyright",
          "|",
          "fontsize",
          "brush",
          "paragraph",
          "link",
          "unlink",
          "|",
          "source",
          "image",
          "video",
          {
            name: "réinitialiser",
            icon: "eraser",
            tooltip: "Reset",
            exec: () => {
              editor.value = "";
              setContent("");
              setValue("content", "");
            },
          },
        ],
        uploader: {
          insertImageAsBase64URI: false,
          imagesExtensions: ["jpg", "png", "jpeg", "gif", "webp"],
          withCredentials: false,
          format: "json",
          method: "POST",
          url: `${process.env.REACT_APP_SERVER_URL}/api/blog/image`,
          prepareData: function (Data: any) {
            var file = Data.getAll("files[0]")[0];
            const formData = new FormData();
            formData.append("image", file);
            formData.delete("files[0]");
            return formData;
          },
          isSuccess: function (resp: any) {
            return !resp.error;
          },
          getMsg: function (resp: any) {
            return resp.msg.join !== undefined ? resp.msg.join(" ") : resp.msg;
          },
          process: function (resp: any) {
            editor.selection.insertImage(resp.imageUrl);
            return resp.imageUrl;
          },
        },
      });

      editor.events.on("change", (newContent: any) => {
        setContent(newContent);
        setValue("content", newContent);
      });
    }
  }, []);

  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.auth?.users || []);
  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    try {
      setIsLoading(true);
      const formattedData = {
        ...data,
        description: content,
      };
      const response = await fetch(`${baseURL}/api/auth/send-users-emails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify(formattedData),
      });

      if (response.ok) {
        setIsLoading(false);
        toast.success("Email envoyé avec succès.");
        setSelectedValues([]);
        setIsAllSelected(false);
        reset();
        setContent("");
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    if (Array.isArray(users) && users.length > 0) {
      const emails = users.map((user) => user.email || "");
      setDefaultEmail(emails);
    } else {
      setDefaultEmail([]);
    }
  }, [users]);

  const toggleSelectAll = () => {
    if (isAllSelected) {
      setSelectedValues([]);
      setValue("receivers", [""]);
    } else {
      const allEmails = users.map((user: any) => ({ label: user.email || "", value: user.email || "" }));
      setSelectedValues(allEmails);
      setValue(
        "receivers",
        allEmails.length > 0
          ? (allEmails.map((item: { label: string; value: string }) => item.value) as [string, ...string[]])
          : [""]
      );
    }
    setIsAllSelected(!isAllSelected);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="flex justify-center text-xl font-bold">Envoie des emails</span>
          <br />

          <span className="block mb-3 font-semibold">Sujet *</span>
          <Input {...register("subject")} name="subject" placeholder="Sujet d'email" className="mt-3" />

          {errors.subject?.message && <div className="text-xs text-red-500">{errors.subject?.message}</div>}

          <div className="mt-3 region">
            <span className="block mb-3 font-semibold">Liste des utilisateurs *</span>

            <div className="flex space-x-3">
              <div className="w-full">
                {" "}
                <Multiselect
                  options={
                    users && Array.isArray(users)
                      ? users.map((item) => ({ label: item.email, value: item.email }))
                      : []
                  }
                  selectedValues={selectedValues}
                  onSelect={(selectedList) => {
                    const labels = selectedList.map((item: { label: any }) => item.label);
                    setValue("receivers", labels);
                    setSelectedValues(selectedList);
                  }}
                  onRemove={(selectedList) => {
                    const labels = selectedList.map((item: { label: any }) => item.label);
                    setValue("receivers", labels);
                    setSelectedValues(selectedList);
                  }}
                  displayValue="label"
                  className={errors.receivers?.message ? "border-red-500" : ""}
                  placeholder="Sélectionner utilisateur"
                  closeOnSelect={false}
                  disable={isLoading}
                  showCheckbox
                  isObject={true}
                />
              </div>

              <div className="flex mb-2 ">
                <button
                  type="button"
                  className={`px-3  text-white bg-blue-500 rounded ${isAllSelected ? "bg-red-500" : "bg-blue-500"}`}
                  onClick={toggleSelectAll}
                  disabled={isLoading || users.length === 0}
                >
                  <span className="text-sm">{isAllSelected ? "Désélectionner tout" : " Sélectionner tout"}</span>
                </button>
              </div>
            </div>

            {errors.receivers?.message && (
              <div className="text-xs text-red-500">{errors.receivers?.message.toString()}</div>
            )}
          </div>

          <div className="mt-3">
            <div className="flex items-center">
              <h2 className="font-semibold ">Contenu</h2>
              <span className="ml-2 text-xs ">*</span>
            </div>
          </div>

          {errors.content?.message && (
            <div className="text-xs text-red-500">{"La description est obligatoire."}</div>
          )}

          <textarea ref={editorRef} value={content} onChange={(e) => setContent(e.target.value)} />

          <CardFooter className="pt-0">
            <Typography variant="small" className="flex justify-center mt-6">
              <ButtonPrimary type="submit" loading={isLoading}>
                Envoyer emails
              </ButtonPrimary>
            </Typography>
          </CardFooter>
        </form>
      </DashboardMenu>
    </div>
  );
};

export default AdminSendEmails;
