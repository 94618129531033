import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import Label from "components/Label/Label";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { createContactRequest, initState, resetValues } from "redux/features/contact/contactSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";

import { socket } from "../../utils/socket";
import { searchUser } from "redux/features/auth/authSlice";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "💌 EMAIL",
    desc: "support@eventtobee.com",
  },
  {
    title: "☎ TELEPHONE",
    desc: "09 77 93 30 63",
  },
];

const formSchema = z.object({
  nom: z.string().min(1, { message: "Nom est obligatoire." }),
  email: z.string().email({ message: "email est obligatoire." }),
  message: z.string().min(15, { message: "Message est obligatoire." }),
});

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const contactState = useAppSelector((state) => state.featureContact);
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    try {
      let response = await dispatch(createContactRequest(data));
      if (response) {
        socket.emit("send-notification", {
          action: "send_contact_request",
          email: data.email,
        });
      }
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    switch (contactState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [contactState.loading]);

  return (
    <div className={`overflow-hidden nc-PageContact ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>Event To Bee - Contactez-nous</title>
        <meta
          name="description"
          content="Contactez Event To Bee pour toute question, demande ou suggestion. Découvrez nos coordonnées et restez en contact avec nous via les réseaux sociaux."
        />
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container mx-auto max-w-7xl">
          <div className="grid flex-shrink-0 grid-cols-1 gap-12 sm:grid-cols-2">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="text-sm font-semibold tracking-wider uppercase dark:text-neutral-200">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">{item.desc}</span>
                </div>
              ))}
              <div>
                <h3 className="text-sm font-semibold tracking-wider uppercase dark:text-neutral-200">
                  🌏 RESEAUX SOCIAUX
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                <label className="block">
                  <Label>Nom et Prénom</Label>
                  <Input type="text" placeholder="Nom et Prénom" className="mt-1" {...register("nom")} />
                  {errors.nom && <div className="text-xs text-red-500">{errors.nom.message}</div>}
                </label>
                <label className="block">
                  <Label>Adresse email</Label>
                  <Input type="email" placeholder="exemple@exemple.com" className="mt-1" {...register("email")} />
                  {errors.email && <div className="text-xs text-red-500">{errors.email.message}</div>}
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea className="mt-1" rows={6} {...register("message")} />

                  {errors.message && <div className="text-xs text-red-500">{errors.message.message}</div>}
                </label>
                <div>
                  <ButtonPrimary type="submit" loading={isLoading}>
                    Envoyer Message
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
    </div>
  );
};

export default PageContact;
