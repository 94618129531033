const server = process.env.REACT_APP_SERVER_URL || "";

export const ownerApi = {
  getOwners: async () => {
    const response = await fetch(`${server}/api/owner`);
    return response.json();
  },
  getOwnerById: async (id: any) => {
    const response = await fetch(`${server}/api/owner/${id}`);
    return response.json();
  },
  getOwnerRating: async (id: any) => {
    const response = await fetch(`${server}/api/comment/owner/${id}`);
    return response.json();
  },
};
