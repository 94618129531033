import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { Card, CardBody, CardFooter, CardHeader, Dialog, Typography } from "@material-tailwind/react";
import DeleteModal from "components/DeleteModal/DeleteModal";
import Label from "components/Label/Label";
import { AuthContext } from "context/appContext";
import { itemPerDashborad } from "data/constants";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { deleteBlog, fetchBlogsByCreator, resetValues } from "redux/features/blog/blogSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";
import DashboardMenuCreator from "./DashboardPage/DashboardMenu";

export interface ListArticlesPageProps {
  className?: string;
}

export interface blogData {
  title: string;
  id: number;
  blog_url: string;
  description: string;
  tags: any;
}

const ListArticles: FC<ListArticlesPageProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  const dispatch = useAppDispatch();
  let blogsState = useAppSelector((state) => state.featureBlog.blogs);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen((cur) => !cur);
  const [searchTerm, setSearchTerm] = useState("");

  let profilId: number | undefined;
  profilId = authContext?.user?.id;

  useEffect(() => {
    if (profilId) {
      dispatch(fetchBlogsByCreator(profilId));
    }
  }, []);

  const handleDelete = (id: number) => {
    dispatch(deleteBlog(id)).then(() => {
      if (profilId) {
        dispatch(fetchBlogsByCreator(profilId));
      }
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashborad;

  const paginatedFields = Array.isArray(blogsState)
    ? [...blogsState].splice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const length = blogsState?.length;
  const totalPages = Math.ceil(blogsState?.length / itemsPerPage);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<number | null>(null);
  const [deleteCategoryDesignation, setDeleteCategoryDesignation] = useState<string | null>(null);

  const handleDeleteClick = (id: number | null, designation: string) => {
    setDeleteCategoryDesignation(designation);
    setDeleteModalOpen(true);
    setDeleteCategoryId(id);
  };

  const handleDeleteConfirm = () => {
    if (deleteCategoryId !== null) {
      handleDelete(deleteCategoryId);
      setDeleteCategoryId(null);
      setDeleteModalOpen(false);
    }
  };

  useEffect(() => {
    dispatch(resetValues());
  }, []);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenuCreator>
        <div>
          {" "}
          <h2 className="font-semibold dark:text-white">Mes Articles</h2>
        </div>
        <div className="flex flex-col">
          <br />
          <div className="flex items-center justify-between mb-4">
            <ButtonPrimary href="/dashboard/add-article">Ajouter un article</ButtonPrimary>
            <div className="flex">
              <Input
                type="text"
                placeholder="Rechercher par désignation"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassCircleIcon className="w-10 h-90 " />
            </div>
            <Dialog size="xs" open={open} handler={handleOpen} className="bg-transparent shadow-none">
              <Card className="mx-auto w-full max-w-[24rem]">
                <CardHeader className="grid mb-4 h-28 place-items-center ">Message</CardHeader>
                <CardBody className="flex flex-col gap-4">
                  <Label>Désignation</Label>
                  <Input />
                </CardBody>
                <CardFooter className="pt-0">
                  <Typography variant="small" className="flex justify-center mt-6">
                    <ButtonPrimary>Ajouter</ButtonPrimary>
                  </Typography>
                </CardFooter>
              </Card>
            </Dialog>
          </div>
          {deleteModalOpen && (
            <DeleteModal
              open={deleteModalOpen}
              handleClose={() => setDeleteModalOpen(false)}
              handleDelete={handleDeleteConfirm}
              category={{
                id: deleteCategoryId || -1,
                designation: deleteCategoryDesignation || "",
              }}
            />
          )}

          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-sm font-light text-center">
                  <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="px-6 py-4 ">
                        Image
                      </th>

                      <th scope="col" className="px-6 py-4 ">
                        Titre
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Aperçu
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Modifier
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Supprimer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchTerm === ""
                      ? paginatedFields &&
                        paginatedFields.map((item, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="w-10 px-6 py-4 font-medium whitespace-nowrap">
                              <img
                                src={`
                                                          
                                                          ${process.env.REACT_APP_CLOUD_FRONT + item?.blog_url}`}
                                alt=""
                              />
                            </td>

                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item.title}</td>
                            {/* GetbyID */}
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                                ></span>
                                <span className="relative">
                                  <Link to={`/blog-single/${item.id}`}>
                                    {" "}
                                    <i className="text-xl las la-eye"></i>
                                  </Link>
                                </span>
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <Link to={`/dashboard/update-article/${item.id}`}>
                                <i className="text-2xl las la-edit"></i>{" "}
                              </Link>{" "}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-trash-alt"
                                onClick={() => handleDeleteClick(item.id, item.title)}
                              ></i>
                            </td>
                          </tr>
                        ))
                      : blogsState
                          ?.filter((item: any) => item.title.toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((item, index) => (
                            <tr className="border-b dark:border-neutral-500" key={index}>
                              <td className="w-10 px-6 py-4 font-medium whitespace-nowrap">
                                <img src={`${process.env.REACT_APP_CLOUD_FRONT + item?.blog_url}`} alt="" />
                              </td>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">{item.title}</td>
                              {/* GetbyID */}
                              <td className="px-6 py-4 whitespace-nowrap">
                                <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                  <span
                                    aria-hidden
                                    className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                                  ></span>
                                  <span className="relative">
                                    <Link to={`/blog-single/${item.id}`}>
                                      {" "}
                                      <i className="text-xl las la-eye"></i>
                                    </Link>
                                  </span>
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <Link to={`/dashboard/update-article/${item.id}`}>
                                  <i className="text-2xl las la-edit"></i>{" "}
                                </Link>{" "}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <i
                                  className="text-2xl cursor-pointer las la-trash-alt"
                                  onClick={() => handleDeleteClick(item.id, item.title)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                  </tbody>
                </table>
                <div className="flex justify-end ">
                  <span className="text-sm text-gray-700">{length} élément</span>
                </div>
                <div className="flex items-center justify-center mt-11">
                  {typeof totalPages === "number" && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardMenuCreator>
    </div>
  );
};

export default ListArticles;
