import { Link } from "react-router-dom";
import TabData from "./advanceTab.json";
import SectionHead from "./SectionHead";
import NcImage from "shared/NcImage/NcImage";
import "../../styles/css/next-styles.css";
import { useState } from "react";
const AdvanceTab = ({ tag, title, desc }) => {
  const image1 = "static-files/tab.png";

  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      <div className="dark:bg-gray-800 ">
        {TabData &&
          TabData.advanceOne.map((data, index) => (
            <div className="container " key={index}>
              <SectionHead tag={tag} title={title} desc={desc} />
              <div className="items-start justify-between lg:flex ">
                {/* Card Section */}
                <div className="how-it-work ">
                  <div className="advance-tab-button advance-tab-button-1">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {data.body.map((item, innerIndex) => (
                        <li className="p-4 nav-item" role="presentation" key={innerIndex}>
                          <Link
                            to="#"
                            className={`nav-link tab-button ${innerIndex === activeTab ? "active" : ""}`}
                            id={item.tab}
                            onClick={() => setActiveTab(innerIndex)}
                            aria-controls={item.target}
                            aria-selected={innerIndex === activeTab}
                          >
                            <div
                              className={` p-4 duration-700 shadow-lg rounded p-4'  ${
                                innerIndex === activeTab ? " shadow-lg rounded p-4" : ""
                              }`}
                            >
                              <div className="flex items-center">
                                <p className="mr-2 text-3xl font-bold text-gray-500">{item.id}</p>
                                <div className="flex items-center">
                                  <hr className="h-full mx-4 border border-black" />
                                  <div>
                                    <h4 className="mb-2 text-xl font-bold text-black dark:text-white">
                                      {item.text}
                                    </h4>
                                    <p className="text-gray-700 dark:text-white">{item.desc}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* Image Section */}
                <div className="flex items-end ml-8 ">
                  <div className="tab-content">
                    <div role="tabpanel">
                      <div className=" thumbnail">
                        <NcImage
                          src={process.env.REACT_APP_CLOUD_FRONT + image1}
                          alt="advance-tab-image"
                          style={{ height: "25rem", width: "24rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default AdvanceTab;
