import FlightCard from "components/FlightCard/FlightCard";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import AgentCommonLayout from "../../../AgentService/AccountPage/AgentCommonLayout";
import SectionGridFilterCardServiceAgent from "containers/AgentService/Services/listing/SectionGridFilterCardServiceAgent";

export interface ServiceAgentServicesPageProps {
  className?: string;
}

const ServiceAgentServicesPage: FC<ServiceAgentServicesPageProps> = ({ className = "" }) => {
  return (
    <AgentCommonLayout>
      <div
        className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
        data-nc-id="ListingFlightsPage"
      >
        <Helmet>
          <title>Event To Bee</title>
        </Helmet>

        <div className="container relative">
          <SectionGridFilterCardServiceAgent className="pb-24 lg:pb-28" />
        </div>
      </div>
    </AgentCommonLayout>
  );
};

export default ServiceAgentServicesPage;
