import FormItem from "components/FormItem/FormItem";
import Label from "components/Label/Label";
import { AuthContext } from "context/appContext";
import { ProfilService } from "data/types";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-input-2";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./AgentCommonLayout";

export interface AgentAccountPageProps {
  className?: string;
}

const AgentAccountPage: FC<AgentAccountPageProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);

  let profile = authContext.user?.profil as ProfilService;

  const [phoneBackground, setPhoneBackground] = useState("");

  useEffect(() => {
    let theme = localStorage.getItem("theme");
    if (theme === "dark") {
      setPhoneBackground("rgb(31 41 55)");
    } else {
      setPhoneBackground("#FFFFFF");
    }
  }, []);

  const phoneInputStyle = {
    border: "1px solid #CBD5E0",
    borderRadius: "0.375rem",
    backgroundColor: phoneBackground,
  };

  return (
    <div className={`nc-AgentAccountPage ${className}`} data-nc-id="AgentAccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>

      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <Fragment>
            <form className="flex flex-col md:flex-row" encType="multipart/form-data">
              <div className="flex items-start flex-shrink-0">
                <div className="relative flex overflow-hidden rounded-full">
                  <Avatar
                    sizeClass="w-32 h-32"
                    imgUrl={profile?.service_url ? process.env.REACT_APP_CLOUD_FRONT + profile?.service_url : ""}
                    userName={profile?.designation}
                  />
                </div>
              </div>

              <div className="flex-grow max-w-3xl mt-10 space-y-6 md:mt-0 md:pl-16">
                <div className="flex flex-col md:flex-row md:flex-wrap gap-8">
                  <div>
                    <Label>Désignation </Label>
                    <Input disabled name="designation" defaultValue={profile?.designation} />
                  </div>
                  <div>
                    <FormItem label=" Vous-étes">
                      <Input
                        disabled
                        type="text"
                        defaultValue={profile?.category === "particular" ? "Particulier" : "Entreprise"}
                      />
                    </FormItem>
                  </div>
                </div>

                <div className="flex flex-wrap gap-8 md:gap-9 mr-3">
                  <div className=" space-y-3 ">
                    <Label className="">Numéro téléphone *</Label>

                    <PhoneInput
                      disabled
                      country={"fr"}
                      value={profile?.phone}
                      inputStyle={phoneInputStyle}
                      containerStyle={phoneInputStyle}
                      buttonStyle={phoneInputStyle}
                      dropdownStyle={phoneInputStyle}
                      searchStyle={phoneInputStyle}
                      inputProps={{ required: true }}
                    />
                  </div>

                  <div className="">
                    <FormItem label=" Adresse E-mail">
                      <Input
                        disabled
                        className="mt-1.5 md:w-60 lg:w-80"
                        type="text"
                        defaultValue={authContext?.user?.email}
                      />
                    </FormItem>
                  </div>
                </div>

                <div className="flex flex-wrap gap-8 x ">
                  <FormItem label="Ville" className="">
                    <Input disabled type="text" name="instagram" defaultValue={profile?.region} />
                  </FormItem>
                  <div>
                    <Label>Contact</Label>
                    <Input disabled type="text" name="contact" defaultValue={profile?.contact} />
                  </div>
                </div>

                <div className="flex flex-wrap w-full">
                  <div className=" w-full ">
                    <Label>Site web</Label>
                    <Input type="text" name="link" defaultValue={profile?.link} disabled />
                  </div>
                </div>

                <div className="flex flex-wrap gap-8 x">
                  <div className="w-full">
                    <Label>Facebook </Label>
                    <Input disabled type="text" name="facebook" defaultValue={profile?.facebook} />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row md:flex-wrap gap-8">
                  <div className="w-full">
                    <Label>Instagram</Label>
                    <Input disabled type="text" name="instagram" defaultValue={profile?.instagram} />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row md:flex-wrap gap-8">
                  <div className="w-full">
                    <Label>Youtube</Label>
                    <Input disabled type="text" name="youtube" defaultValue={profile?.youtube} />
                  </div>
                </div>

                <div className="space-y-8">
                  <div>
                    <h2 className="text-2xl font-semibold">Description</h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                      Mentionnez les meilleures caractéristiques de votre prestation , équipements spéciaux ...
                    </span>
                  </div>
                  <Textarea rows={14} defaultValue={profile?.description} disabled />
                  <div>
                    <label className="text-lg font-semibold" htmlFor="">
                      Type de prestation
                    </label>
                    <div className="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-3">
                      {profile?.fields}
                    </div>
                  </div>
                  <span className="block text-lg font-semibold">
                    Les régions disponibles pour vos prestations{" "}
                  </span>
                  <div className="flow-root">
                    <div className="flex flex-wrap -mx-3 divide-y divide-neutral-100 dark:divide-neutral-800">
                      {profile?.availability &&
                        profile.availability.map((tag, index) => (
                          <div key={index} className="flex items-center w-full px-3 py-3 sm:w-1/2 md:w-1/4">
                            <i className="flex-shrink-0 mr-2 text-lg las la-map-marker-alt"></i>
                            <span className="font-medium text-neutral-6000 dark:text-neutral-400">{tag}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="pt-2">
                  <ButtonPrimary className="w-full md:w-1/2" type="submit" href={`/update-profile-service/`}>
                    Mise à jour
                  </ButtonPrimary>
                </div>
              </div>
            </form>
          </Fragment>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AgentAccountPage;
