import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { Card, CardBody, CardFooter, CardHeader, Dialog, Typography } from "@material-tailwind/react";
import AlertModalDelete from "components/AlertModal/AlertModalDelete";
import DeleteModal from "components/DeleteModal/DeleteModal";
import Label from "components/Label/Label";
import { Reservation } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import {
  deleteReservation,
  fetchReservation,
  payReservationOwner,
} from "redux/features/reservations/reservationSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";
import DashboardMenu from "../DashboardPage/DashboardMenu";
import ReservationDetailsPage from "./reservationDetailsPage";

export interface ListPlaygroundsPageProps {
  className?: string;
}
interface ReservationLoadingState {
  [key: string]: boolean;
}
const ListReservationAdminPage: FC<ListPlaygroundsPageProps> = ({ className = "" }) => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const reservations: Reservation[] = useAppSelector((state) => state.featureReservation.inProgress);
  const totalPages: Reservation[] = useAppSelector((state) => state.featureReservation.totalPages);
  const count: number = useAppSelector((state) => state.featureReservation.count);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen((cur) => !cur);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState<ReservationLoadingState>({});
  const [showReservationDetails, setshowReservationDetails] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    dispatch(fetchReservation(page));
  }, [page, dispatch]);

  const handleDelete = async (id: number) => {
    await dispatch(deleteReservation(id));
    await dispatch(fetchReservation(page));
  };

  const giveOwnerPayment = async (reservation: any) => {
    setModalOpen(false);

    setIsLoading((prevState) => ({
      ...prevState,
      [reservation.id]: true,
    }));
    try {
      const res = await dispatch(payReservationOwner(reservation.reference));
      toast.success(res.payload.msg);
      setIsLoading((prevState) => ({
        ...prevState,
        [reservation.id]: false,
      }));

      await dispatch(fetchReservation(page));
      setIsLoading((prevState) => ({
        ...prevState,
        [reservation.id]: false,
      }));
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<number | null>(null);
  const [deleteCategoryDesignation, setDeleteCategoryDesignation] = useState<string | null>(null);
  const [selectedReservation, setselectedReservation] = useState<any | null>(null);
  const handleDeleteClick = (id: number | null, designation: string) => {
    setDeleteCategoryDesignation(designation);
    setDeleteModalOpen(true);
    setDeleteCategoryId(id);
  };
  const handleEyeIconClick = (client: any) => {
    setselectedReservation(client);
    setshowReservationDetails(true);
    setDeleteModalOpen(false);
  };
  const handleDeleteConfirm = () => {
    if (deleteCategoryId !== null) {
      handleDelete(deleteCategoryId);
      setDeleteCategoryId(null);
      setDeleteModalOpen(false);
    }
  };
  const isReservationOngoing = (selectedDate: any, endHour: any) => {
    const currentDate = new Date();
    const selectedDateTime = new Date(selectedDate);

    // Extract end hour and minute from the endHour string
    const [endHourInt, endMinuteInt] = endHour.split(":").map((part: any) => parseInt(part));

    // Set the end time of the selected date
    selectedDateTime.setHours(endHourInt, endMinuteInt, 0, 0);

    // Ensure both dates are in the same time zone context
    const currentUtcTime = currentDate.getTime() - currentDate.getTimezoneOffset() * 60000;
    const selectedUtcTime = selectedDateTime.getTime() - selectedDateTime.getTimezoneOffset() * 60000;

    // Compare the UTC times to determine if the reservation is ongoing
    return currentUtcTime <= selectedUtcTime;
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="text-center">
          <h2>Liste des réservations en cours</h2>
        </div>
        <div className="flex flex-col">
          <div className="flex items-center justify-between mb-4">
            <div className="flex">
              <Input
                type="text"
                placeholder="Rechercher par code"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassCircleIcon className="w-10 h-90 " />
            </div>
            <Dialog size="xs" open={open} handler={handleOpen} className="bg-transparent shadow-none">
              <Card className="mx-auto w-full max-w-[24rem]">
                <CardHeader className="grid mb-4 h-28 place-items-center ">Message</CardHeader>
                <CardBody className="flex flex-col gap-4">
                  <Label>Designation</Label>
                  <Input />
                </CardBody>
                <CardFooter className="pt-0">
                  <Typography variant="small" className="flex justify-center mt-6">
                    <ButtonPrimary>Ajouter</ButtonPrimary>
                  </Typography>
                </CardFooter>
              </Card>
            </Dialog>
          </div>
          {deleteModalOpen && (
            <DeleteModal
              open={deleteModalOpen}
              handleClose={() => setDeleteModalOpen(false)}
              handleDelete={handleDeleteConfirm}
              category={{
                id: deleteCategoryId || -1,
                designation: deleteCategoryDesignation || "",
              }}
            />
          )}
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-sm font-light text-center">
                  <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="px-6 py-4 ">
                        Code
                      </th>

                      <th scope="col" className="px-6 py-4 ">
                        A Payer
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Paiement
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        consulter
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Supprimer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reservations &&
                      reservations

                        .filter((item) => item.reference.toLowerCase().includes(searchTerm.toLowerCase()))
                        .map((item: any, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">{item.reference}</td>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {(
                                parseFloat(item.halfTotal) +
                                (parseFloat(item.cleaning_costs) || 0) +
                                (parseFloat(item.extra) ?? 0)
                              ).toFixed(2)}
                            </td>
                            {isReservationOngoing(item?.selectedDate, item?.endHour) ? (
                              <td className="font-semibold text-secondary-6000">En cours</td>
                            ) : (
                              <td className="px-6 py-4 font-medium whitespace-nowrap">Terminer</td>
                            )}
                            <td className="px-6 py-4 whitespace-nowrap">
                              {item?.payOwner === true && item?.transaction_id != null ? (
                                <span className="font-semibold text-secondary-6000">Virement réussi</span>
                              ) : (
                                <>
                                  {isReservationOngoing(item?.selectedDate, item?.endHour) ? (
                                    <span>En attente</span>
                                  ) : (
                                    <>
                                      {item.payOwner !== true ? (
                                        <ButtonSecondary
                                          loading={isLoading[item.id]}
                                          onClick={() => {
                                            setselectedReservation(item);
                                            setModalOpen(true);
                                          }}
                                        >
                                          {" "}
                                          <span className="relative flex w-3 h-3">
                                            <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400"></span>
                                            <span className="relative inline-flex w-3 h-3 rounded-full bg-sky-500"></span>
                                          </span>
                                          <span className="ml-3">Payer</span>
                                        </ButtonSecondary>
                                      ) : (
                                        <span className="font-semibold text-secondary-6000"> Payé</span>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-eye"
                                onClick={() => handleEyeIconClick(item)}
                              ></i>{" "}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl las la-trash-alt"
                                onClick={() => handleDeleteClick(item.id, item.designation)}
                              ></i>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <div className="flex justify-end ">
                  <span className="text-sm text-gray-700">{count} élément</span>
                </div>
                {showReservationDetails && (
                  <ReservationDetailsPage
                    open={true}
                    handleClose={() => setshowReservationDetails(false)}
                    Data={selectedReservation}
                  />
                )}

                <AlertModalDelete
                  open={modalOpen}
                  handleDelete={() => giveOwnerPayment(selectedReservation)}
                  handleClose={() => setModalOpen(false)}
                  message={`Êtes-vous sûr(e) de confirmé ce virement de paiement  ?`}
                  alertText="Confirmation de transaction"
                />

                <div className="flex items-center justify-center mt-11">
                  {typeof totalPages === "number" && (
                    <Pagination currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardMenu>
    </div>
  );
};

export default ListReservationAdminPage;
