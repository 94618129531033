import { CustomLink } from "data/types";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
  className?: string;
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (pageNumber: number) => void;
}

const Pagination: FC<PaginationProps> = ({ className = "", currentPage = 1, totalPages, onPageChange }) => {
  const paginationItems = Array.from({ length: totalPages! }, (_, index) => ({
    label: (index + 1).toString(),
    href: "#",
  }));

  const renderItem = (pag: CustomLink, index: number) => {
    const selectedPage = index + 1;
    if (selectedPage === currentPage) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {pag.label}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <button
        key={index}
        onClick={() => onPageChange && onPageChange(selectedPage)} // Conditional check
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
      >
        {pag.label}
      </button>
    );
  };

  return (
    <nav className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}>
      {paginationItems.map(renderItem)}
    </nav>
  );
};

export default Pagination;
