import React from "react";

interface TextDisplayProps {
  text: string;
}

const TextDisplay: React.FC<TextDisplayProps> = ({ text }) => {
  const convertNewlinesToBreaks = (text: string): React.ReactNode => {
    return text.split("\n").map((line: string, index: number) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  return <div>{convertNewlinesToBreaks(text)}</div>;
};

export default TextDisplay;
