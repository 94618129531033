import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { blogApi } from "./blogAPI";

interface Blog {
  id: number;
  title: string;
  description: string;
  content: string;
  blog_url: string;
  tags: string[];
  userId: number;
  blogError: string;
  createdAt?: Date;
  updatedAt?: Date;
  blogs: any[];
  comments?: [];
  blog?: any;
  comment?: Comment;
  allTags?: any;
}

interface BlogState {
  id: number;
  title: string;
  description: string;
  content: string;
  blog_url: string;
  tags: string[];
  userId: number;
  blogError: string;
  createdAt?: Date;
  loading: string;
  updatedAt?: Date;
  blogs: any[];
  comments?: [];
  blog?: any;
  comment?: Comment;
  allTags?: any;
}

interface Comment {
  id?: number;
  text: string;
  clientId: string;
  blogId: string;
}

const initialState: BlogState = {
  id: 0,
  title: "",
  blogError: "",
  description: "",
  content: "",
  blog_url: "",
  loading: "",
  tags: [],
  userId: 0,
  blogs: [],
  blog: {},
  comment: { clientId: "", blogId: "", text: "" },
};

export const addBlog = createAsyncThunk("featureBlog/addBlog", async (data: any) => {
  return await blogApi.addBlog(data);
});
export const editBlog = createAsyncThunk("featureBlog/editBlog", async ({ data, id }: { data: any; id: any }) => {
  return await blogApi.editBlog(data, id);
});
export const fetchBlogs = createAsyncThunk("featureBlog/fetchBlog", async () => {
  return await blogApi.getBlogs();
});
export const fetchSimilarBlogs = createAsyncThunk("featureBlog/fetchSimilarBlogs", async (id: any) => {
  return await blogApi.getSimilarBlogs(id);
});
export const fetchAllTags = createAsyncThunk("featureBlog/fetchAllTags", async () => {
  return await blogApi.getAllTags();
});
export const fetchBlogsByCreator = createAsyncThunk("featureBlog/fetchBlogByCreator", async (id: number) => {
  return await blogApi.getBlogsByCreator(id);
});
export const addBlogComment = createAsyncThunk("featureBlog/addBlogComment", async (data: any) => {
  return await blogApi.addCommentBlog(data);
});

export const fetchBlogsByTag = createAsyncThunk("featureBlog/fetchBlogByTag", async (tag: string) => {
  return await blogApi.getBlogsByTag(tag);
});

export const deleteBlog = createAsyncThunk("featureBlog/deleteBlog", async (id: any) => {
  return await blogApi.deleteBlog(id);
});

export const fetchBlogsById = createAsyncThunk("featureBlog/fetchBlogById", async (id: string) => {
  return await blogApi.getBlogsById(id);
});

export const fetchCommentsByBlog = createAsyncThunk("featureBlog/fetchCommentsByBlog", async (id: string) => {
  return await blogApi.getCommentByBlog(id);
});

const createBlogSlice = createSlice({
  name: "featureBlog",
  initialState,
  reducers: {
    initializeState: (state) => {
      return initialState;
    },

    resetValues(state) {
      state.id = 0;
      state.title = "";
      state.blogError = "";
      state.description = "";
      state.content = "";
      state.blog_url = "";
      state.loading = "";
      state.tags = [];
      state.userId = 0;
      state.blogs = [];
      state.blog = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addBlog.pending, (state, action) => {
      state.loading = "pending";
      state.blogError = "";
    });
    builder.addCase(addBlog.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.blogError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.blogError = action.payload.error;
      }
    });
    builder
      .addCase(addBlog.rejected, (state, action) => {
        state.loading = "failed";
      })
      .addCase(editBlog.pending, (state, action) => {
        state.loading = "pending";
        state.blogError = "";
      });
    builder.addCase(editBlog.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.blogError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.blogError = action.payload.error;
      }
    });
    builder
      .addCase(editBlog.rejected, (state, action) => {
        state.loading = "failed";
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.blogs = action.payload;
      })
      .addCase(fetchSimilarBlogs.fulfilled, (state, action) => {
        state.blogs = action.payload;
      })
      .addCase(fetchBlogsByTag.fulfilled, (state, action) => {
        state.blogs = action.payload;
      })
      .addCase(fetchBlogsByCreator.fulfilled, (state, action) => {
        state.blogs = action.payload;
      })
      .addCase(fetchBlogsById.fulfilled, (state, action) => {
        state.blog = action.payload;
      })
      .addCase(fetchCommentsByBlog.fulfilled, (state, action) => {
        state.comments = action.payload;
      })
      .addCase(fetchAllTags.fulfilled, (state, action) => {
        state.allTags = action.payload;
      })
      .addCase(addBlogComment.fulfilled, (state, action) => {
        state.comment = action.payload;
      });
  },
});
export const { initializeState, resetValues } = createBlogSlice.actions;

export default createBlogSlice.reducer;
