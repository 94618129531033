import React, { Fragment } from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { FaRegWindowClose } from "react-icons/fa";

interface SocialMediaShareProps {
  url: string;
}

const SocialMediaShare: React.FC<SocialMediaShareProps> = ({ url }) => {
  const [open, setOpen] = React.useState(true);

  const handleOpen = () => setOpen(!open);
  const socialMediaShare = [
    { component: FacebookShareButton, icon: FacebookIcon },
    {
      component: FacebookMessengerShareButton,
      icon: FacebookMessengerIcon,
      appId: "",
    },
    { component: WhatsappShareButton, icon: WhatsappIcon },
    { component: TwitterShareButton, icon: TwitterIcon },
    { component: EmailShareButton, icon: EmailIcon },
  ];

  return (
    <Fragment>
      <Dialog
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        style={{ width: "100%", maxWidth: "400px" }}
      >
        {/*       <DialogHeader className="flex items-center justify-between ">
          <div className="ml-auto ">
            <FaRegWindowClose
              onClick={handleOpen}
              className="cursor-pointer "
            />
          </div>
        </DialogHeader> */}
        <DialogBody>
          <div className="flex justify-center mt-2 space-x-3">
            {socialMediaShare.map((button, index) => {
              const Component = button.component;
              return (
                <Component key={index} url={url} appId={button.appId || ""}>
                  <button.icon size={30} round />
                </Component>
              );
            })}
          </div>
        </DialogBody>
      </Dialog>
    </Fragment>
  );
};

export default SocialMediaShare;
