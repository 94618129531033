import React from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography } from "@material-tailwind/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";

interface DeleteModalProps {
  open: boolean;
  handleClose?: () => void;
  agentData: any;
}
const ServiceAgentDetailsPage: React.FC<DeleteModalProps> = ({ open, handleClose, agentData }) => {
  const [openx, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Dialog open={open} handler={handleOpen} className="w-11/12 dark:bg-gray-800">
        <DialogHeader>
          <Typography variant="h5" color="blue-gray" className="text-center dark:text-white">
            Information détaillée du prestataire
          </Typography>
        </DialogHeader>
        <DialogBody divider className="grid gap-4 place-items-center">
          <Typography className="font-normal text-center">
            <div>
              <Label className="font-semibold">Désignation :</Label>
              <span className="dark:text-white"> {agentData?.designation} </span>
            </div>

            <div>
              <Label className="font-semibold">Type de prestation :</Label>
              <span className="dark:text-white"> {agentData?.fields} </span>
            </div>

            <div>
              <Label className="font-semibold">Région :</Label>
              <span className="dark:text-white"> {agentData?.region} </span>
            </div>

            <div>
              <Label className="font-semibold">Numéro de téléphone :</Label>
              <span className="dark:text-white"> {agentData?.phone} </span>
            </div>

            <div>
              <Label className="font-semibold">Contact :</Label>
              <span className="dark:text-white"> {agentData?.contact} </span>
            </div>

            <div className="flex !space-x-3 justify-center items-center p-2">
              {agentData?.youtube.trim() !== "" && (
                <a href={agentData?.facebook} target="_blank" rel="noreferrer">
                  <div className="flex items-center justify-center text-xl rounded-full w-9 h-9 bg-neutral-100 dark:bg-white">
                    <i className="lab la-facebook-square"></i>
                  </div>
                </a>
              )}
              {agentData?.youtube.trim() !== "" && (
                <a href={agentData?.youtube} target="_blank" rel="noreferrer">
                  <div className="flex items-center justify-center text-xl rounded-full w-9 h-9 bg-neutral-100 dark:bg-white">
                    <i className="lab la-youtube"></i>
                  </div>
                </a>
              )}

              {agentData?.instagram.trim() !== "" && (
                <a href={agentData?.instagram} target="_blank" rel="noreferrer">
                  <div className="flex items-center justify-center text-xl rounded-full w-9 h-9 bg-neutral-100 dark:bg-white">
                    <i className="lab la-instagram"></i>
                  </div>
                </a>
              )}

              {agentData?.link.trim() !== "" && (
                <a href={agentData?.link} target="_blank" rel="noreferrer">
                  <div className="flex items-center justify-center text-xl rounded-full w-9 h-9 bg-neutral-100 dark:bg-white">
                    <i className="lab la-chrome"></i>
                  </div>
                </a>
              )}
            </div>

            <div>
              <Label className="font-semibold">Inscrit le :</Label>
              <span className="dark:text-white"> {new Date(agentData?.createdAt).toLocaleDateString()} </span>
            </div>

            <div>
              <Label className="font-semibold">Dernière connexion le:</Label>
              <span className="dark:text-white">
                {" "}
                {new Date(agentData?.User?.last_connection).toLocaleDateString("fr-FR", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                })}
              </span>
            </div>
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <ButtonSecondary onClick={handleClose} className="dark:bg-dark dark:text-white">
            Quitter
          </ButtonSecondary>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ServiceAgentDetailsPage;
