import { FC, useState } from "react";
import Heading2 from "components/Heading/Heading2";
import FieldCard from "components/ServiceCard/fieldCard";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import StayCard from "components/StayCard/StayCard";
import { useNavigate } from "react-router-dom";
import LoginModal from "components/LoginModal/LoginModal";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: any;
  feature?: any;
}

export interface Field {
  designation: string;
  id: number;
  images: any;
}

const LoactionGridCard: FC<SectionGridFilterCardProps> = ({ className = "", data }) => {
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleShowMore = () => {
    navigate("/locations");
  };

  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
      {showLoginModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="flex flex-col items-center p-6 bg-white rounded-lg dark:bg-neutral-900">
              <div className="self-end mb-4">
                <ButtonSecondary onClick={() => setShowLoginModal(false)}>X</ButtonSecondary>
              </div>
              <LoginModal LoginModal={(e) => setShowLoginModal(e)} />
            </div>
          </div>
        </div>
      )}
      <Heading2 heading="" />
      <br />
      <div
        className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        style={{ marginTop: "-130px" }}
      >
        {Array.isArray(data) &&
          data?.map((location: any, index: any) => (
            <div key={index}>
              <StayCard
                data={location}
                galleryImgs={location.images}
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
                minPrice={location?.prices?.[0]?.price}
                //priceCount={priceCount}
              />
            </div>
          ))}
      </div>

      {data?.length === 0 && (
        <div className="flex items-center justify-center">
          <p className="font-semibold text">Aucun type de prestation disponible</p>
        </div>
      )}
      <br />
      <div className="flex items-center justify-center ">
        <ButtonSecondary onClick={handleShowMore}>Voir Plus</ButtonSecondary>
      </div>
    </div>
  );
};

export default LoactionGridCard;
