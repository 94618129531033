import React, { Fragment } from "react";
import { Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PiBankFill } from "react-icons/pi";
import { BsBank2 } from "react-icons/bs";
import { RIBDisplay } from "hooks/hooks";

interface AlertModalConfirmProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  email?: string;
  message?: string;
  alertText?: string;
  navigateTo?: string;
  fullName?: string;
  rib?: string;
  alertMsg?: any;
}

const AlertModalConfirm: React.FC<AlertModalConfirmProps> = ({
  open,
  handleClose,
  handleConfirm,
  message,
  fullName,
  rib,
  alertText,
  alertMsg,
  navigateTo,
}) => {
  const handleCloseModal = () => {
    handleClose();
    if (navigateTo != undefined) {
      window.location.href = navigateTo;
    }
  };

  const handleConfirmAction = () => {
    handleConfirm();
  };

  return (
    <Fragment>
      <Dialog open={open} handler={handleCloseModal}>
        <DialogHeader className="text-center">{alertText}</DialogHeader>

        <DialogBody divider className="flex flex-col justify-center">
          <div className="font-semibold">{message}</div>
          <div className="mt-2">{alertMsg}</div>
          <div className="mt-2 ">Compte enregistré :</div>
          <div className="inline-flex p-4 mt-3 bg-white border-2 border-white rounded shadow-md">
            <div className="inline-flex items-center justify-center bg-gray-500 rounded">
              <BsBank2 className="w-10 h-8 text-2xl text-white" />
            </div>
            <div className="flex flex-col ml-3">
              <div className="font-semibold">{fullName}</div>
              <div>{rib && RIBDisplay(rib)} </div>
            </div>
          </div>
        </DialogBody>

        <DialogFooter>
          <div className="flex space-x-3">
            <ButtonPrimary onClick={handleConfirmAction}>
              <span>Confirmer le transfert</span>
            </ButtonPrimary>
            <ButtonSecondary onClick={handleCloseModal}>
              <span>Quitter</span>
            </ButtonSecondary>
          </div>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default AlertModalConfirm;
