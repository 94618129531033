import ReservationDetailsOwnerPage from "containers/Client/Reservation/reservationDetailsOwnerPage";
import { AuthContext } from "context/appContext";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect, useState } from "react";
import { confirmReservation, fetchPendingOwnerReservation } from "redux/features/reservations/reservationSlice";
import Pagination from "shared/Pagination/Pagination";
import { socket } from "../../utils/socket";
import AlertModalDelete from "./AlertModalDelete";
import AlertModalMessage from "components/AlertModal/AlertModalMessage";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export interface SectionGridFilterCardOwnerProps {
  className?: string;
}
interface ReservationLoadingState {
  [key: string]: boolean;
}
const SectionGridFilterCardOwner: FC<SectionGridFilterCardOwnerProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoadingApprove, setIsLoadingApprove] = useState<ReservationLoadingState>({});
  const [isLoadingReject, setIsLoadingReject] = useState<ReservationLoadingState>({});
  const [hide, setHide] = useState<ReservationLoadingState>({});
  const [modalOpen, setModalOpen] = useState(false);
  let reservationState: any = useAppSelector((state) => state.featureReservation.reservations);
  useEffect(() => {
    if (profilId) {
      dispatch(fetchPendingOwnerReservation(profilId));
    }
  }, [profilId]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [showReservationDetails, setShowReservationDetails] = useState(false);
  const [selectedReservation, setselectedReservation] = useState(null);
  const paginatedReservations =
    reservationState.length > 0
      ? reservationState.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      : [];
  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };
  const handleEyeIconClick = (reservation: any) => {
    setselectedReservation(reservation);
    setShowReservationDetails(!showReservationDetails);
  };
  const handleClick = async (reservation: any, response: any) => {
    if (setShowModal) {
      setShowModal(false);
    }
    if (setShowDeleteModal) {
      setShowDeleteModal(false);
    }
    if (response === true) {
      setIsLoadingApprove((prevState) => ({
        ...prevState,
        [reservation.id]: true,
      }));
    } else {
      setIsLoadingReject((prevState) => ({
        ...prevState,
        [reservation.id]: true,
      }));
    }
    const approuve = { approuved: response };

    const res = await dispatch(confirmReservation({ data: approuve, id: reservation.id }));
    toast.success("Vous avez confirmé l'acceptation de la réservation.");

    if (res.payload.msg === "Reservation updated") {
      if (response) {
        socket.emit("send-notification", {
          senderId: reservationState[0]?.Owner?.userId,
          receiverId: reservationState[0]?.Client?.userId,
          action: "confirm_reservation",
          reservationId: reservationState[0].id,
          reservation: reservationState[0],
        });
      } else {
        socket.emit("send-notification", {
          senderId: reservationState[0]?.Owner?.userId,
          receiverId: reservationState[0]?.Client?.userId,
          action: "negate_reservation",
          reservationId: reservationState[0].id,
          reservation: reservationState[0],
        });
      }
    }
    navigate("/client/my-reservations");
    dispatch(fetchPendingOwnerReservation(profilId));
    setHide((prevState) => ({
      ...prevState,
      [reservation.id]: true,
    }));
    if (response === true) {
      setIsLoadingApprove((prevState) => ({
        ...prevState,
        [reservation.id]: false,
      }));
    } else {
      setIsLoadingReject((prevState) => ({
        ...prevState,
        [reservation.id]: false,
      }));
    }
  };
  const isReservationOngoing = (item: any) => {
    const currentDate = new Date();
    const createdAtDate = new Date(item.createdAt);
    const twoDaysAfterCreatedAt = new Date(createdAtDate);
    twoDaysAfterCreatedAt.setDate(createdAtDate.getDate() + 2);
    return currentDate.getTime() >= twoDaysAfterCreatedAt.getTime();
  };
  const length = reservationState?.length;
  const totalPages = Math.ceil(reservationState?.length / itemsPerPage);

  const [showModal, setShowModal] = useState(false);
  const handleOpen = async (reservation: any) => {
    setselectedReservation(reservation);
    if (setShowModal) {
      setShowModal(true);
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleOpenDelete = async (reservation: any) => {
    setselectedReservation(reservation);
    if (setShowDeleteModal) {
      setShowDeleteModal(true);
    }
  };

  return (
    <>
      <div className={`w-full rounded-md ${className}`}>
        <div className="flex items-center justify-between pb-1">
          <div>
            <h2 className="font-semibold text-gray-600 dark:text-white">Mes demande de réservation</h2>
          </div>
        </div>

        <div>
          <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="justify-center">
                    <th className="py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Images
                    </th>
                    <th className="py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Code
                    </th>
                    <th className="py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Status
                    </th>
                    <th className="py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Consulter
                    </th>
                    <th className="py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white"></th>
                    <th className="py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white">
                      Action
                    </th>
                    <th className="py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase border-b-2 dark:bg-gray-800 dark:text-white"></th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedReservations.map((reservation: any) => (
                    <tr key={reservation.id}>
                      <td className="px-5 py-5 text-sm dark:bg-gray-800">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img
                              className="w-full h-full rounded-full"
                              src={`${process.env.REACT_APP_CLOUD_FRONT + reservation?.Location?.images[0]}
                                                              
                                                              `}
                              alt=""
                            />
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 text-sm dark:bg-gray-800">
                        <p className="text-gray-900 whitespace-no-wrap dark:text-white">
                          {reservation?.reference}
                        </p>
                      </td>
                      <td className="px-5 py-5 text-sm dark:bg-gray-800">
                        {isReservationOngoing(reservation) ? (
                          <p className="text-gray-900 whitespace-no-wrap dark:text-white">terminée</p>
                        ) : (
                          <>
                            {reservation?.approuved === false && (
                              <p className="text-red-900 whitespace-no-wrap dark:text-white">bloqué</p>
                            )}
                            {reservation?.approuved === null && (
                              <p className="text-gray-900 whitespace-no-wrap dark:text-white">en attente</p>
                            )}
                          </>
                        )}
                      </td>
                      {/* GetbyID */}

                      <td className="px-5 py-5 text-sm dark:bg-gray-800">
                        <i
                          className="text-2xl cursor-pointer las la-eye"
                          onClick={() => handleEyeIconClick(reservation)}
                        ></i>{" "}
                      </td>

                      <td className="px-5 py-5 text-sm dark:bg-gray-800">
                        {reservation?.approuved == null && hide && !isReservationOngoing(reservation) && (
                          <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                            <span
                              aria-hidden
                              className="absolute inset-0 bg-green-200 rounded-full opacity-50"
                            ></span>
                            <span className="relative" onClick={() => handleOpen(reservation)}>
                              {isLoadingApprove[reservation.id] ? (
                                <i className="text-xl las la-spinner animate-spin"></i>
                              ) : (
                                <i className="text-xl cursor-pointer las la-check-circle"></i>
                              )}
                            </span>
                          </span>
                        )}
                      </td>
                      {/* Delete */}
                      <td className="px-5 py-5 text-sm dark:bg-gray-800">
                        {reservation?.approuved == null && hide && !isReservationOngoing(reservation) && (
                          <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tigh">
                            <span
                              aria-hidden
                              className="absolute inset-0 bg-red-200 rounded-full opacity-50"
                            ></span>
                            <span className="relative" onClick={() => handleOpenDelete(reservation)}>
                              {isLoadingReject[reservation.id] ? (
                                <i className="text-xl las la-spinner animate-spin"></i>
                              ) : (
                                <i className="text-xl cursor-pointer las la-times-circle"></i>
                              )}
                            </span>
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <AlertModalDelete
            open={showModal}
            handleDelete={() => handleClick(selectedReservation, true)}
            handleClose={() => setShowModal(false)}
            message={`Êtes-vous sûr(e) de vouloir accepter cette réservation ?`}
            alertText="Acceptation de réservation"
          />
          <AlertModalDelete
            open={showDeleteModal}
            handleDelete={() => handleClick(selectedReservation, false)}
            handleClose={() => setShowDeleteModal(false)}
            message={`Êtes-vous sûr(e) de vouloir refuser cette réservation ?`}
            alertText="Refus de réservation"
          />
          {showReservationDetails && (
            <ReservationDetailsOwnerPage
              open={true}
              handleClose={() => setShowReservationDetails(false)}
              Data={selectedReservation}
            />
          )}

          <div className="flex items-center justify-center mt-64">
            {typeof totalPages === "number" && (
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            )}
          </div>
        </div>
        {paginatedReservations.length === 0 && (
          <div className="flex items-center justify-center mt-16">
            <p>Pas de réservations </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SectionGridFilterCardOwner;
