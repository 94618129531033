import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_SERVER_URL;

export const stripeApi = {
  getUserStats: async (userId: number, account_id: string, status: string) => {
    const result = await fetch(`${baseURL}/api/user-stats`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify({ userId, account_id, status }),
    });

    return result.json();
  },

  getAllTransactions: async () => {
    const response = await fetch(`${baseURL}/api/transfers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    const data = await response.json();
    return data;
  },

  getOwnerAllTransactions: async (account_id: string) => {
    const response = await fetch(`${baseURL}/api/owner-transfers?account_id=${account_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    const data = await response.json();
    return data;
  },

  getAllAccounts: async (pageNumber: number, startAfter: string) => {
    const result = await fetch(`${baseURL}/api/connected-accounts?page=${pageNumber}&startAfter=${startAfter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  deleteAccount: async (id: number, owner_id: any) => {
    const result = await fetch(`${baseURL}/api/connected-accounts/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify({ id, owner_id }),
    });
    if (result.ok) {
      toast.success("Le compte a été supprimé avec succès.");
    }

    return result.json();
  },
};
