import AgentCommonLayout from "containers/AgentService/AccountPage/AgentCommonLayout";
import { AuthContext } from "context/appContext";
import { ProfilService } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  initState,
  nextStep,
  previousStep,
  resetValues,
  setServiceData,
  updateService,
} from "redux/features/service/serviceSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import UpdateServiceDetails from "./UpdateServiceDetails";
import UpdateServiceExtraDetails from "./UpdateServiceExtraDetails";
import UpdateServiceFilesUpload from "./UpdateServiceFilesUpload";

const UpdateServiceCommonLayout: React.FC = () => {
  const authContext = useContext(AuthContext);
  let profile = authContext.user?.profil as ProfilService;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const serviceState = useAppSelector((state) => state.service);
  const progress = (serviceState.currentStep / serviceState.totalSteps) * 100;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();
  const [event, setEvent] = useState<Event>(new Event(""));

  const handleNextClick = () => {
    switch (serviceState.currentStep) {
      case 1: {
        setEvent(new Event("chekServiceUpdateFormOne"));
        break;
      }
      case 2: {
        setEvent(new Event("chekServiceUpdateFormTwo"));
        break;
      }
      case 3: {
        setEvent(new Event("chekServiceUpdateFormThree"));
        break;
      }

      default: {
        setEvent(new Event(""));
        break;
      }
    }
  };
  const handlePreviousClick = () => {
    dispatch(previousStep());
  };

  useEffect(() => {
    dispatch(initState());
  }, []);
  useEffect(() => {
    switch (serviceState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        if (serviceState.serviceData && serviceState.currentStep === 3) {
          dispatch(updateService({ data: serviceState.isUpdated, id: id }));
        } else {
          dispatch(nextStep());
        }
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [serviceState.loading]);

  const initialRender = useRef(true);

  useEffect(() => {
    if (serviceState.isUpdated && serviceState.currentStep === 3) {
      dispatch(updateService({ data: serviceState.isUpdated, id: id })).then(() => {
        dispatch(resetValues());
        navigate("/prestataire/services-list");
      });
    }
  }, [serviceState.isUpdated]);

  useEffect(() => {
    if (!initialRender.current && serviceState.isUpdated && serviceState.currentStep != 3) {
      dispatch(nextStep());
      setIsLoading(false);
    }

    initialRender.current = false;
  }, [serviceState.isUpdated]);

  useEffect(() => {
    dispatch(resetValues());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [serviceState.currentStep]);

  return (
    <AgentCommonLayout>
      <div className={`px-4 pb-24 mx-auto max-w-3xl nc-PageAddListing1 lg:pb-32`} data-nc-id="PageAddListing1">
        <p className="mt-5">
          Bonjour Mr/Mme :<span className="font-bold text-black">{profile?.designation}</span>{" "}
        </p>

        <div className="mt-10 space-y-6">
          {serviceState.currentStep === 1 && (
            <UpdateServiceDetails
              isLoading={isLoading}
              handleForm={event}
              FormData={(data: any) => {
                if (serviceState.serviceData && "images" in serviceState.serviceData) {
                  const { images, tarif } = serviceState.serviceData;

                  dispatch(
                    setServiceData({
                      ...serviceState.isUpdated,
                      ...data,
                      images,
                      tarif,
                    })
                  );
                } else {
                }
              }}
              errorServiceForm={serviceState.serviceError}
            />
          )}

          {serviceState.currentStep === 2 && (
            <UpdateServiceExtraDetails
              isLoading={isLoading}
              handleForm={event}
              FormData={(data) => {
                dispatch(setServiceData({ ...serviceState.isUpdated, ...data }));
              }}
              errorServiceForm={serviceState.serviceError}
            />
          )}

          {serviceState.currentStep === 3 && (
            <UpdateServiceFilesUpload
              isLoading={isLoading}
              handleForm={event}
              FormData={(data) => dispatch(setServiceData({ ...serviceState.isUpdated, ...data }))}
              errorServiceForm={serviceState.serviceError}
            />
          )}
          <div className="flex flex-col items-center justify-center space-y-3">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div className="bg-blue-600 h-2.5 rounded-full w-45" style={{ width: `${progress}%` }}></div>
            </div>

            <div className="flex items-center justify-between w-full">
              <ButtonSecondary onClick={handlePreviousClick} disabled={serviceState.currentStep <= 1}>
                Retour
              </ButtonSecondary>

              <p className="text-lg font-semibold">
                {`Étape ${serviceState.currentStep}/${serviceState.totalSteps}`}
              </p>

              <ButtonPrimary onClick={handleNextClick} loading={isLoading} disabled={isLoading}>
                Continue
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </AgentCommonLayout>
  );
};

export default UpdateServiceCommonLayout;
