const baseURL = process.env.REACT_APP_SERVER_URL;

export const videoApi = {
  addVideo: async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const result = await fetch(`${baseURL}/api/home-video`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });

    return result.json();
  },

  getAllVideos: async () => {
    const result = await fetch(`${baseURL}/api/home-video`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  deleteVideo: async (id: string) => {
    const response = await fetch(`${baseURL}/api/home-video/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    const data = await response.json();
    return data;
  },

  getVideoById: async (id: string) => {
    const result = await fetch(`${baseURL}/api/home-video/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  updateHomeVideo: async (data: any, id: any) => {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    const result = await fetch(`${baseURL}/api/home-video/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });

    return result.json();
  },
};
