import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Helmet } from "react-helmet";
import { getAllClientsStats, getAllOwnerStats, getAllServiceAgentStats } from "redux/features/auth/authSlice";
import CommonLayout from "./DashboardMenu";

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from "chart.js";
import { useParams } from "react-router-dom";

export interface AdminProfileProps {
  className?: string;
}

const AdminProfile: FC<AdminProfileProps> = ({ className = "" }) => {
  ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
  const dispatch = useAppDispatch();

  const stats = useAppSelector((state) => state?.auth?.userStats);
  const { user } = useParams();

  useEffect(() => {
    if (user === "clients") {
      dispatch(getAllClientsStats());
    } else if (user === "agents") {
      dispatch(getAllServiceAgentStats());
    } else if (user === "owner") {
      dispatch(getAllOwnerStats());
    }
  }, [dispatch, user]);

  const total = Object.values(stats || {}).reduce((acc: number, value: number) => acc + value, 0);
  const months = Object.keys(stats || {});
  const userData = {
    labels: months.map((month) => `${month}`),
    datasets: [
      {
        label: "Statistiques",
        data: Object.values(stats || {}),
        backgroundColor: "#36A2EB",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        stacked: true,
        precision: 0,
        ticks: {
          font: {
            weight: "bold" as "bold",
          },
        },
      },
      y: {
        stacked: true,
        precision: 0,
        ticks: {
          stepSize: 1,
          font: {
            weight: "bold" as "bold",
          },
          callback: function (value: any) {
            return value.toLocaleString("fr-FR", { maximumFractionDigits: 0 });
          },
        },
      },
    },
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>

      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div className="container mx-auto">
            <div>
              <h2 className="text-3xl font-semibold">Statistiques du {user}</h2>
            </div>
            <span className="text-base">Nombre total : {total}</span>
            <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
            <Bar data={userData} options={options}></Bar>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AdminProfile;
