import React, { FC } from "react";
import StayCard from "components/ServiceCard/ServiceCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import OwnerCard from "components/OwnerCard/OwnerCard";

export interface OwnerGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

const OwnerGridFilterCard: FC<OwnerGridFilterCardProps> = ({ className = "", data = DEMO_DATA }) => {
  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
      <Heading2 heading="Liste des proriétaires" subHeading="propriétaires" />
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.map((stay) => (
          <OwnerCard key={stay.id} data={stay} />
        ))}
      </div>
      <div className="flex items-center justify-center mt-16">
        <Pagination />
      </div>
    </div>
  );
};

export default OwnerGridFilterCard;
