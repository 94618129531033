import NewsletterThree from "./Newsletter-Three";

const Page = () => {
  return (
    <>
      <div className="w-full">
        <div className="rbt-newsletter-area bg-gradient-6 ptb--50">
          <NewsletterThree />
        </div>
      </div>
    </>
  );
};

export default Page;
