import ClientReservationDetailedCard from "components/ClientReservationCard/ClientReservationDetailedCard";
import { useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import ClientReservation from "../ClientReservation";
import { Spinner } from "@material-tailwind/react";
import ReservationsDetailedCard from "components/FlightCard/ReservationsDetailedCard";
import { Reservation } from "data/types";
export interface SectionGridFilterCardProps {
  className?: string;
}

const ReservationSearchDetail: FC<SectionGridFilterCardProps> = ({ className = "" }) => {
  let reservationState: Reservation | undefined = useAppSelector(
    (state) => state.featureReservation.reservationData
  );
  if (reservationState !== undefined) {
    reservationState = reservationState as Reservation;
  }
  const searchState = useAppSelector((state) => state?.featureReservation);
  const userId = useAppSelector((state) => state.auth?.user?.profil?.id);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    switch (searchState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);

        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [searchState.loading]);

  return (
    <ClientReservation>
      <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
        <div className="grid grid-cols-1 gap-6 rounded-3xl lg:bg-neutral-50 lg:dark:bg-black/20">
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <Spinner color="blue" className="w-12 h-12" />
            </div>
          ) : reservationState !== undefined ? (
            userId === reservationState?.ownerId ? (
              <ReservationsDetailedCard data={reservationState} defaultOpen={false} />
            ) : (
              <ClientReservationDetailedCard data={reservationState} defaultOpen={false} />
            )
          ) : (
            <div className="flex items-center justify-center mt-16">
              <p>Pas de reservations disponibles</p>
            </div>
          )}
        </div>
      </div>
    </ClientReservation>
  );
};

export default ReservationSearchDetail;
