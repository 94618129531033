import { useState, FC } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
setDefaultLocale("fr");
export interface BlockedDatesRangeInputProps {
  className?: string;
  onDateChange?: (start: Date | null, end: Date | null) => void;
  availableDays: any;
  fullyDays: any;
  unavailableDays: any;
}

const BlockedDatesRangeInput: FC<BlockedDatesRangeInputProps> = ({
  className = "flex-col overflow-y-scroll ",
  onDateChange,
  availableDays,
  fullyDays,
  unavailableDays,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (onDateChange) {
      onDateChange(start, end);
    }
  };
  function convertDaysToNumbers(daysArray: any) {
    const daysOfWeek = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    return daysArray.map((dayName: any) => {
      const index = daysOfWeek.indexOf(dayName);
      return index ;
    });
  }
  const getDaysBetween = (start: any, end: any) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const days = [];
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      days.push(date.toISOString().split("T")[0]);
    }
    days.push(endDate.toISOString().split("T")[0]);
    return days;
  };

  const getFullyDaysFromUnavailableDays = () => {
    const result: any[] = [];

    unavailableDays.forEach(({ startDay, endDay }: any) => {
      const daysBetween = getDaysBetween(startDay, endDay);
      result.push(...daysBetween);
    });

    const uniqueResult = Array.from(new Set(result));

    return uniqueResult;
  };

  const fullyDaysFromUnavailableDays = getFullyDaysFromUnavailableDays();
  const availableDayNumbers = convertDaysToNumbers(availableDays);
  const excludedays = (date: Date) => {
    const dayOfWeek = date.getDay();

    const isFullyDay = (fullyDay: string) => {
      const fullyDate = new Date(fullyDay);
      return (
        fullyDate.getFullYear() === date.getFullYear() &&
        fullyDate.getMonth() === date.getMonth() &&
        fullyDate.getDate() === date.getDate()
      );
    };

    const isUnavailableDay = (unavailableDay: string) => {
      const unavailableDate = new Date(unavailableDay);
      return (
        unavailableDate.getFullYear() === date.getFullYear() &&
        unavailableDate.getMonth() === date.getMonth() &&
        unavailableDate.getDate() === date.getDate()
      );
    };

     const isExcluded = fullyDays.includes(formatDate(date)) || unavailableDays.some(isUnavailableDay);

    return !isExcluded && availableDayNumbers.includes(dayOfWeek);
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

 

  return (
    <div className={`StayDatesRangeInput relative flex ${className}`}>
      <div className="p-8 overflow-hidden bg-white shadow-lg rounded-3xl ring-1 ring-black ring-opacity-5 dark:bg-neutral-800">
        <DatePicker
          locale={"fr"}
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          allowSameDay={true}
          selectsRange
          minDate={new Date()}
          monthsShown={2}
          filterDate={excludedays}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          // renderDayContents={(day, date) => <DatePickerCustomDay dayOfMonth={day} date={date} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay
              dayOfMonth={day}
              date={date}
              excludedDays={fullyDaysFromUnavailableDays}
              unavailableDays={fullyDaysFromUnavailableDays}
            />
          )}

        />
      </div>
    </div>
  );
};

export default BlockedDatesRangeInput;
