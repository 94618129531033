import { AuthContext } from "context/appContext";
import { ProfilClient } from "data/types";
import { useAppSelector } from "hooks/hooks";
import { useContext } from "react";

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  const isAuthenticated = () => {
    return authContext.isAuthenticated;
  };

  const isEmailVirefied = () => {
    return authContext.user?.emailVirefied === true;
  };

  const isProfil = () => {
    return authContext.user?.profil !== undefined;
  };

  const getUser = () => {
    return authContext.user;
  };

  const getUserRole = () => {
    return authContext.user?.role;
  };

  const profile = useAppSelector((state) => state.auth?.user?.profil as ProfilClient);
  let validation = profile?.validation;
  const getUserType = () => {
    let type = "";

    type = validation || "";

    return type;
  };

  return { isAuthenticated, isEmailVirefied, isProfil, getUser, getUserRole, getUserType };
};
