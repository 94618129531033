import { Checkbox, Typography } from "@material-tailwind/react";
import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

import { zodResolver } from "@hookform/resolvers/zod";
import { ServiceFormProps } from "data/reduxInterfaces";
import { Event } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { fetchEvents } from "redux/features/Admin/Events/eventSlice";
import { getFields } from "redux/features/service/serviceSlice";
import { z } from "zod";

const formSchema = z.object({
  equipments: z.array(z.string()).min(0),

  events: z.array(z.string()).min(0),
});

const ServiceExtraDetails: FC<ServiceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  errorServiceForm,
  FormData,
}) => {
  const dispatch = useAppDispatch();
  const serviceState = useAppSelector((state) => state.service);
  const events: Event[] = useAppSelector((state) => state.featureEvent.events);
  const [tag, setTag] = useState<string>("");

  const handleAddTag = () => {
    if (tag.trim() !== "") {
      const dd = getValues("equipments");
      setValue("equipments", [...dd, tag.trim()]);
      trigger("equipments");
      setTag("");
    }
  };

  const handleRemoveTag = (index: number) => {
    const newTags = [...getValues("equipments")];
    newTags.splice(index, 1);
    setValue("equipments", newTags);
    trigger("equipments");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { equipments: [], events: [] },
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  useEffect(() => {
    if (handleForm.type === "chekServiceFormTwo") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm.type]);

  useEffect(() => {
    dispatch(getFields());
  }, []);

  useEffect(() => {
    dispatch(fetchEvents());
  }, []);

  return (
    <Fragment>
      <div>
        <h2 className="text-2xl font-semibold">Spécification du prestation </h2>
      </div>
      {/* FORM */}
      <div className="space-y-8">
        {/* ITEM */}
        <div>
          <label className="text-lg font-semibold" htmlFor="">
            Type d'événements
          </label>
          <div className="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-3">
            {events?.map((event) => (
              <Checkbox
                label={<Typography className="text-dark dark:text-white">{event.designation}</Typography>}
                key={event.designation}
                value={event.designation}
                //defaultChecked={selectedEvents.includes(event.designation)}
                {...register("events")}
              />
            ))}
          </div>
          {errors.events?.message && <div className="text-xs text-red-500 ">{errors.events?.message}</div>}
        </div>
      </div>
    </Fragment>
  );
};

export default ServiceExtraDetails;
