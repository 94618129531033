"use client";
import { useAppSelector } from "hooks/hooks";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { fetchEvents } from "redux/features/Admin/Events/eventSlice";
import { fetchFields } from "redux/features/Admin/Fields/fieldSlice";
import { RootState } from "redux/store";
import { findValueInArray } from "utils/globalFunctions";
export interface TypeInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onSelectEvent?: (value: string) => void;
  clear: boolean;
  propTypeValue?: string | null;
}

export interface TypeInputData {
  id: number;
  type: string;
}

const TypeInput: FC<TypeInputProps> = ({
  autoFocus = false,
  placeHolder = "Type de prestation",
  desc = "Quels types  ?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  onSelectEvent,
  clear,
  propTypeValue,
}) => {
  const location = useLocation();

  const fields = useAppSelector((state) => state?.featureField?.fields);
  const pathname = location.pathname;
  const decodedPathname = decodeURIComponent(pathname);
  const afterEvent = decodedPathname.split("/field/")[1];
  const field = findValueInArray(propTypeValue, "designation", fields);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(!!field ? field?.designation : "");
  const [showPopover, setShowPopover] = useState(autoFocus);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const fetchedEvents: Event[] = useSelector((state: RootState) => state.featureEvent.events);

  const [selectedFields, setSelectedFields] = useState<string>("");
  const [selectedRegion, setSelectedRegion] = useState<string>("");

  useEffect(() => {
    dispatch(fetchFields());
  }, []);
  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (clear) {
      setValue("");
    }
  }, [clear]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const handleFieldClick = (field: any) => {
    setShowPopover(false);
    setValue(field);

    if (onSelectEvent) {
      if (onSelectEvent) {
        onSelectEvent(field);
      }
    }
  };

  const handleRegionClick = (region: any) => {
    setSelectedRegion(region);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value);
  };
  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: string) => {
    setValue(item);
    setShowPopover(false);

    if (onSelectEvent) {
      onSelectEvent(item);
    }
  };

  const renderSelectValue = () => {
    return (
      <>
        {fields &&
          fields.map((item: any) => (
            <span
              key={item.id}
              onClick={() => handleFieldClick(item.designation)}
              className="flex items-center px-4 py-4 space-x-3 cursor-pointer sm:px-8 sm:space-x-4 hover:bg-neutral-100 dark:hover:bg-neutral-700"
            >
              <span className="block text-neutral-400">
                <i className="text-3xl las la-user-tie"></i>
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">{item.designation}</span>
            </span>
          ))}
      </>
    );
  };

  return (
    <div className={`flex relative ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <i className="text-3xl las la-user-tie"></i>
        </div>
        <div className="flex-grow">
          <div
            className={`block p-0 w-full font-semibold truncate bg-transparent border-none focus:ring-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg placeholder-neutral-800 dark:placeholder-neutral-200`}
            onClick={() => setShowPopover(true)}
          >
            {value ? (
              <span>{value}</span>
            ) : (
              <span className="dark:text-white">
                {afterEvent !== "locations" ? afterEvent : placeHolder}
                {afterEvent == undefined && placeHolder}
              </span>
            )}
          </div>
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="">{value ? placeHolder : desc}</span>
          </span>
        </div>
      </div>

      {showPopover && (
        <div
          className={`absolute top-1/2 z-0 self-center h-8 bg-white -translate-y-1/2 dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderSelectValue()}
        </div>
      )}
    </div>
  );
};

export default TypeInput;
