import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import DeleteModal from "components/DeleteModal/DeleteModal";
import { User } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { deleteUser, getAllClients } from "redux/features/auth/authSlice";
import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";
import DashboardMenu from "../../DashboardPage/DashboardMenu";
import ClientDetailsPage from "./ClientDetailsPage";
import ValidateClientModal from "./ValidateClientModal";
import { itemPerDashborad } from "data/constants";

export interface ListCategorysPageProps {
  className?: string;
}

const ListClientsPage: FC<ListCategorysPageProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const clients = useAppSelector((state) => state?.auth?.allClients);
  const filteredClients = Array.isArray(clients) && clients.filter((client) => client);
  useEffect(() => {
    dispatch(getAllClients());
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashborad;

  const paginatedClients = Array.isArray(filteredClients)
    ? filteredClients.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const length = Array.isArray(filteredClients) ? filteredClients.length : 0;
  const totalPages = Array.isArray(filteredClients) ? Math.ceil(filteredClients.length / itemsPerPage) : 0;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<User | null>(null);
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDeleteModalOpen = (client: User) => {
    setSelectedClient(client);
    setShowClientDetails(false);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedClient && selectedClient.User?.id !== undefined) {
      setDeleting(true);
      await dispatch(deleteUser(selectedClient.User?.id));
      setDeleting(false);
      setDeleteModalOpen(false);
      await dispatch(getAllClients());
    }
  };

  const handleEyeIconClick = (client: any) => {
    setSelectedClient(client);
    setShowClientDetails(true);
    setDeleteModalOpen(false);
  };
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [selectedItemtTitle, setSelectedItemTitle] = useState<string | null>(null);
  const handleUpdate = (itemId: number, itemTitle: string) => {
    setSelectedItemId(itemId);
    setSelectedItemTitle(itemTitle);
    setUpdateModal(true);
  };

  const paginatedSearchedClients = Array.isArray(clients)
    ? clients
        .filter((item) => {
          const name = item.name || "";
          const lastName = item.lastName || "";

          return (
            name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            lastName.toLowerCase().includes(searchTerm.toLowerCase())
          );
        })
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : [];

  const displayedClients = searchTerm === "" ? paginatedClients : paginatedSearchedClients;

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="flex flex-col">
          {updateModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="fixed inset-0 bg-black opacity-50"></div>

              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="flex flex-col items-center p-6 overflow-auto bg-white rounded-lg dark:bg-neutral-900">
                  <ValidateClientModal
                    onClose={(value) => {
                      setUpdateModal(value);
                      setSelectedItemId(null);
                    }}
                    id={selectedItemId}
                    title={selectedItemtTitle}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex items-center justify-between mb-4">
            <div></div>
            <div className="relative flex">
              <Input
                type="text"
                placeholder="Rechercher par nom"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-500 transform -translate-y-1/2 top-1/2 right-3" />
            </div>
          </div>
          {deleteModalOpen && (
            <DeleteModal
              open={deleteModalOpen}
              handleClose={() => setDeleteModalOpen(false)}
              handleDelete={handleDeleteConfirm}
              category={{
                id: selectedClient?.User?.id || 0,
                designation: selectedClient?.User?.login || "",
              }}
            />
          )}

          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-sm font-light text-center">
                  <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="px-6 py-4 ">
                        Nom et prénom
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Type de compte
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Validation
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Consulter
                      </th>
                      <th scope="col" className="px-6 py-4 ">
                        Supprimer
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {searchTerm === ""
                      ? paginatedClients.map((item, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {item.lastName} {item.name}
                            </td>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {item.validation === null && <span>Client</span>}
                              {item.validation === "approuvé" && <span>Hôte</span>}
                              {item.validation === "en cours" && <span>Demande en cours</span>}
                              {item.validation === "bloqué" && <span>demande bloqué</span>}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl las la-edit"
                                onClick={() => handleUpdate(item.id, item.lastName + "" + item.name)}
                              ></i>{" "}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-eye"
                                onClick={() => handleEyeIconClick(item)}
                              ></i>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-trash-alt"
                                onClick={() => handleDeleteModalOpen(item)}
                              ></i>
                            </td>
                          </tr>
                        ))
                      : displayedClients.map((item, index) => (
                          <tr className="border-b dark:border-neutral-500" key={index}>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {item.lastName} {item.name}
                            </td>
                            <td className="px-6 py-4 font-medium whitespace-nowrap">
                              {item.validation === null && <span>Client</span>}
                              {item.validation === "approuvé" && <span>Hôte</span>}
                              {item.validation === "en cours" && <span>Demande en cours</span>}
                              {item.validation === "annuler" && <span>Demande rejecté</span>}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-eye"
                                onClick={() => handleEyeIconClick(item)}
                              ></i>{" "}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <i
                                className="text-2xl cursor-pointer las la-trash-alt"
                                onClick={() => handleDeleteModalOpen(item)}
                              ></i>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <div className="flex justify-end ">
                  <span className="text-sm text-gray-700">{length} élément</span>
                </div>
                {showClientDetails && !deleting && (
                  <ClientDetailsPage
                    open={true}
                    handleClose={() => setShowClientDetails(false)}
                    clientData={selectedClient}
                  />
                )}
                <div className="flex items-center justify-center mt-11">
                  {typeof totalPages === "number" && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardMenu>
    </div>
  );
};

export default ListClientsPage;
