import FlightCard from "components/FlightCard/FlightCard";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import OwnerMenu from "../AccountPage/OwnerMenu";
import SectionGridFilterCardOwner from "containers/ListingLocationsDashboardPage/SectionGridFilterCardOwner";
import CommonLayout from "containers/Client/AccountPage/CommonLayout";

export interface OwnerLocationsPageProps {
  className?: string;
}

const OwnerLocationsPage: FC<OwnerLocationsPageProps> = ({ className = "" }) => {
  return (
    <CommonLayout>
      <div
        className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
        data-nc-id="ListingFlightsPage"
      >
        <Helmet>
          <title>Event To Bee</title>
        </Helmet>

        <div className="container relative">
          <SectionGridFilterCardOwner className="pb-24 lg:pb-28" />
        </div>
      </div>
    </CommonLayout>
  );
};

export default OwnerLocationsPage;
