import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import FormItem from "./FormItem";

import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "@material-tailwind/react";
import { ServiceFormProps } from "data/reduxInterfaces";
import { ProfilService } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { getService } from "redux/features/service/serviceSlice";
import { z } from "zod";
import Multiselect from "multiselect-react-dropdown";

const formSchema = z.object({
  title: z.string().min(1, { message: "Le nom du service est obligatoire." }),
  description: z.string().min(1, { message: "La description est obligatoire." }),
  region: z.array(z.string()).nonempty({ message: "Les régions disponibles est obligatoire." }),
});

interface SelectedValue {
  label: string;
}

const UpdateServiceDetails: FC<ServiceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  errorServiceForm,
  FormData,
}) => {
  const dispatch = useAppDispatch();
  const availability = useAppSelector((state) => {
    const profile = state.auth.user?.profil;

    if (profile && "availability" in profile) {
      return profile.availability as ProfilService["availability"];
    }
    return;
  });
  const { id } = useParams();
  const serviceState = useAppSelector((state) => state.service?.serviceData);
  useEffect(() => {
    if (id) {
      dispatch(getService(id));
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { title: "", description: "" },
    mode: "onSubmit",
  });

  useEffect(() => {
    if (serviceState) {
      setValue("title", serviceState.title || "");
      setValue("description", serviceState.description || "");
      setValue("region", [serviceState.region]);
    }
  }, [serviceState]);
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  useEffect(() => {
    if (handleForm.type === "chekServiceUpdateFormOne") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const [isSelected, setIsSelected] = useState<string[]>([]);
  useEffect(() => {
    setIsSelected(getValues("region"));
  }, [serviceState]);

  const selectedValues: string[] = getValues("region") || [];
  const selectedLabels: SelectedValue[] = selectedValues?.flatMap((value: string) => {
    if (value) {
      const labels = value.split(",").map((label) => ({ label }));
      return labels;
    }

    return [];
  });

  return (
    <Fragment>
      <h2 className="text-2xl font-semibold">Mise à jour votre prestation</h2>
      <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
      {errorServiceForm && (
        <Alert color="red" className="text-xs">
          {errorServiceForm}
        </Alert>
      )}
      <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <FormItem label={<> Nom du votre prestation *</>}>
          <Input
            type="text"
            placeholder={"title"}
            disabled={isLoading}
            className={`mt-1.5 ${errors.title?.message ? "border-red-500" : ""}`}
            {...register("title")}
            onChange={(e) => {
              setValue("title", e.target.value);
            }}
          />
          {errors.title?.message && <div className="text-xs text-red-500 ">{errors.title?.message}</div>}
        </FormItem>
        {/* 
                <div className="">
                    <div>
                        <FormItem label={<> Région</>} />
                    </div>
                    {availability && (
                        <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
                            {availability.map((item: any) => (
                                <div key={item} className="flex items-center space-x-3">
                                    <i className="las la-map-marker-alt"></i>
                                    <span className="">{item}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div> */}

        <div>
          <span className="block mb-3 text-lg font-semibold">Les régions disponibles pour vos prestations *</span>
          <Multiselect
            options={
              Array.isArray(availability) ? availability?.map((item) => ({ label: item, value: item })) : []
            }
            selectedValues={selectedLabels}
            onSelect={(selectedList) => {
              const labels = selectedList.map((item: { label: any }) => item.label);
              setValue("region", labels);
            }}
            onRemove={(selectedList) => {
              const labels = selectedList.map((item: { label: any }) => item.label);
              setValue("region", labels);
            }}
            displayValue="label"
            className={errors.region ? "border-red-500" : ""}
            placeholder="Select regions"
            closeOnSelect={false}
          />
          {errors.region?.message && (
            <div className="text-xs text-red-500">{errors.region?.message.toString()}</div>
          )}
        </div>

        <div>
          <div className="flex items-center">
            <h2 className="text-2xl font-semibold">Description de prestation </h2>
            <span className="ml-2 text-xs ">*</span>
          </div>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Mentionnez les meilleures caractéristiques de votre prestation,
          </span>
        </div>

        <Textarea placeholder={"...."} rows={14} {...register("description")} />
        {errors.description?.message && <div className="text-xs text-red-500">{errors.description?.message}</div>}
      </form>
    </Fragment>
  );
};

export default UpdateServiceDetails;
