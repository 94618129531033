import { useEffect } from 'react';
import './cookies.scss';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Button from 'shared/Button/Button';

const Cookies = (props) => {
    const showAlert = () => {
        var cookieAlert = document.querySelector('.cookie-alert');

        if (!getCookie('enableCookies')) {
            cookieAlert.classList.add('show');
        }
    };

    // Cookie functions stolen from w3schools
    const setCookie = (cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    };

    const getCookie = (cname) => {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);

        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };

    useEffect(() => {
        showAlert();
    });

    const enableCookies = () => {
        var cookieAlert = document.querySelector('.cookie-alert');

        setCookie('enableCookies', true, 60);
        cookieAlert.classList.remove('show');
    };

    const disableCookies = () => {
        var cookieAlert = document.querySelector('.cookie-alert');

        setCookie('enableCookies', false, 60);
        cookieAlert.classList.remove('show');
    };

    return (
        <>
            <div className="block p-6 bg-white border border-gray-200 rounded-lg dark:bg-neutral-800 cookie-container cookie-alert">
                <div className="card-body">
                    <div className="flex flex-wrap justify-center mb-3 text-center ">
                        <span>&#x1F36A;</span>
                        <h5 className="text-gray-6000 px-3 uppercase">EVENTTOBEE COOKIES ?</h5>
                    </div>

                    <div className="flex flex-col lg:flex-row lg:items-center mb-2 pt-4">
                        <div className="col-span-4 ">
                            <p className="cookie-text ">
                                Nous employons des cookies et des technologies similaires pour adapter notre
                                contenu, évaluer l'efficacité de nos services et affiner leur pertinence, tout en
                                offrant une expérience optimisée. En appuyant sur <b> Accepter </b>, vous adhérez
                                aux dispositions énoncées dans notre Politique concernant les cookies.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <Button className="cursor-pointer underline !px-0 !py-0" onClick={disableCookies}>
                            Continuer sans accepter
                        </Button>

                        <ButtonPrimary onClick={enableCookies}>Accepter</ButtonPrimary>
                    </div>
                </div>
                <br />
            </div>
        </>
    );
};

export default Cookies;
