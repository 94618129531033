import Label from "components/Label/Label";
import { AuthContext } from "context/appContext";
import { ProfilClient } from "data/types";
import { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);

  const navigate = useNavigate();

  let profile = authContext.user?.profil as ProfilClient;
  const [phoneInputColor, setPhoneInputColor] = useState("");

  useEffect(() => {
    let theme = localStorage.getItem("theme");
    if (theme === "dark") {
      setPhoneInputColor("#000");
    } else {
      setPhoneInputColor("#000");
    }
  }, []);

  const phoneInputStyle = {
    color: phoneInputColor,
    border: "1px solid #CBD5E0",
    borderRadius: "0.375rem",
  };

  const Modify = () => {
    navigate("/update-profile-client/");
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}

          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

          <form className="flex flex-col md:flex-row" encType="multipart/form-data">
            <div className="flex items-start flex-shrink-0">
              <div className="relative flex overflow-hidden rounded-full">
                <Avatar
                  sizeClass="w-32 h-32"
                  imgUrl={profile?.client_url ? process.env.REACT_APP_CLOUD_FRONT + profile?.client_url : ""}
                  userName={profile?.name}
                />
              </div>
            </div>
            <div className="flex-grow max-w-3xl mt-10 space-y-6 md:mt-0 md:pl-16">
              <div>
                <Label>Nom</Label>
                <Input disabled className="mt-1.5 md:w-80 lg:w-80" name="name" defaultValue={profile?.name} />
              </div>

              <div>
                <Label>Prénom</Label>
                <Input
                  disabled
                  className="mt-1.5 md:w-80 lg:w-80"
                  name="lastName"
                  defaultValue={profile?.lastName}
                />
              </div>

              <div className="">
                <Label>Date de naissance </Label>
                <Input
                  type="date"
                  placeholder="Date de naissance"
                  className={`mt-1.5 md:w-80 lg:w-80 `}
                  disabled
                  defaultValue={profile?.dateOfBirth}
                />
              </div>

              <div className="flex gap-8 mt-2 md:gap-5">
                <div>
                  <Label>Numéro téléphone *</Label>
                  <div className="mt-1.5 md:w-120 lg:w-120 ">
                    <PhoneInput
                      disabled
                      country={"fr"}
                      value={profile?.phone}
                      inputStyle={phoneInputStyle}
                      containerStyle={phoneInputStyle}
                      buttonStyle={phoneInputStyle}
                      dropdownStyle={phoneInputStyle}
                      searchStyle={phoneInputStyle}
                      inputProps={{ required: true }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:gap-8">
                <div>
                  <Label>Adresse E-mail</Label>

                  <Input
                    disabled
                    className="mt-1.5 md:w-80 lg:w-80"
                    name="name"
                    defaultValue={authContext?.user?.email}
                  />
                </div>
              </div>

              <div className="pt-2">
                <ButtonPrimary className="w-full md:w-auto whitespace-nowrap" onClick={() => Modify()}>
                  Mise à jour
                </ButtonPrimary>
              </div>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
