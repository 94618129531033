import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createEvent } from "./featureAPI";

const initialState = {
  designation: "",
  image: [],
  loading: "idle",
  events: [],
  id: "",
  event: {
    images: [],
    id: "",
    designation: "",
  },
};

export const addEvent = createAsyncThunk("featureEvent/addEvent", async (data: any) => {
  return await createEvent.add(data);
});

export const fetchEvents = createAsyncThunk("featureEvent/fetchEvent", async () => {
  return await createEvent.verify();
});
export const fetchEventByid = createAsyncThunk("featureEvent/fetchEventByid", async (id: any) => {
  return await createEvent.getById(id);
});

export const deleteEvent = createAsyncThunk("featureEvent/deleteEvent", async (id: any) => {
  return await createEvent.delete(id);
});

export const updateEvent = createAsyncThunk(
  "featureEvent/editEvent",
  async ({ data, id }: { data: any; id: any }) => {
    return await createEvent.update(data, id);
  }
);

const createEventSlice = createSlice({
  name: "featureEvent",
  initialState,
  reducers: {
    setDesignation(state, action) {
      state.designation = action.payload;
    },
    setImage(state, action) {
      state.image = action.payload;
    },
    resetDesignation(state) {
      state.designation = "";
    },
    resetValues(state) {
      state.designation = "";
      state.loading = "idle";
      state.image = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addEvent.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
        state.image = action.payload;
      })
      .addCase(addEvent.rejected, (state) => {
        state.loading = "failed";
      });
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload;
      })
      .addCase(fetchEventByid.fulfilled, (state, action) => {
        state.event = action.payload;
      });
    builder
      .addCase(deleteEvent.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteEvent.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(deleteEvent.rejected, (state) => {
        state.loading = "failed";
      });
    builder
      .addCase(updateEvent.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(updateEvent.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const { setDesignation, resetDesignation, setImage, resetValues } = createEventSlice.actions;
export default createEventSlice.reducer;
