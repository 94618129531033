import { Card, CardBody, CardFooter, CardHeader, Dialog, Spinner, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import DashboardMenu from "../DashboardPage/DashboardMenu";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import DeleteModal from "components/DeleteModal/DeleteModal";
import Pagination from "shared/Pagination/Pagination";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import {
  deleteReservation,
  fetchAdminCanceledReservationByClient,
  payPartialRefundReservation,
} from "redux/features/reservations/reservationSlice";
import ReservationDetailsPage from "./reservationDetailsPage";
import AlertModalMessage from "components/AlertModal/AlertModalMessage";
import { itemPerDashborad } from "data/constants";
import { Reservation } from "data/types";
export interface ListPlaygroundsPageProps {
  className?: string;
}
interface ReservationLoadingState {
  [key: string]: boolean;
}
const ListCanceledReservationByClientAdminPage: FC<ListPlaygroundsPageProps> = ({ className = "" }) => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const reservations: Reservation[] = useAppSelector((state) => state.featureReservation.canceledByClient);
  const totalPages: Reservation[] = useAppSelector((state) => state.featureReservation.totalPages);
  const count: number = useAppSelector((state) => state.featureReservation.count);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showReservationDetails, setshowReservationDetails] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen((cur) => !cur);
  const [searchTerm, setSearchTerm] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState<ReservationLoadingState>({});
  useEffect(() => {
    dispatch(fetchAdminCanceledReservationByClient(page));
    setShowSpinner(false);
  }, [page, dispatch]);

  const handleDelete = async (id: number) => {
    await dispatch(deleteReservation(id));
    await dispatch(fetchAdminCanceledReservationByClient(page));
  };
  const ReturnClientPayment = async (reservation: any) => {
    setIsLoading((prevState) => ({
      ...prevState,
      [reservation.id]: true,
    }));
    const res = await dispatch(payPartialRefundReservation(reservation.reference));
    if (res.payload.msg == "Successfully Processed and Transferred") {
      setMessage("Traitement et transfert réussis.");
      setModalOpen(true);
      await dispatch(fetchAdminCanceledReservationByClient(page));
    }
    if (res.payload.msg == "Charge has already been refunded.") {
      setMessage("Le montant a déjà été remboursé.");
      setModalOpen(true);
      await dispatch(fetchAdminCanceledReservationByClient(page));
    }
    if (res.payload.msg == "Payment not yet completed.") {
      setMessage("Le paiement n'a pas encore été effectué.");
      setModalOpen(true);
      await dispatch(fetchAdminCanceledReservationByClient(page));
    }
    setIsLoading((prevState) => ({
      ...prevState,
      [reservation.id]: false,
    }));
    await dispatch(fetchAdminCanceledReservationByClient(page));
  };

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };

  const formatDate = (dateString: any) => {
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReservation, setselectedReservation] = useState<any | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<number | null>(null);
  const [deleteCategoryDesignation, setDeleteCategoryDesignation] = useState<string | null>(null);

  const handleEyeIconClick = (client: any) => {
    setselectedReservation(client);
    setshowReservationDetails(true);
    setDeleteModalOpen(false);
  };
  const handleDeleteClick = (id: number | null, designation: string) => {
    setDeleteCategoryDesignation(designation);
    setDeleteModalOpen(true);
    setDeleteCategoryId(id);
  };

  const handleDeleteConfirm = () => {
    if (deleteCategoryId !== null) {
      handleDelete(deleteCategoryId);
      setDeleteCategoryId(null);
      setDeleteModalOpen(false);
    }
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>

      <DashboardMenu>
        <div className="text-center">
          <h2>Liste des réservations annulée par les clients</h2>
        </div>

        {!showSpinner && (
          <div className="flex flex-col">
            <div className="flex items-center justify-between mb-4">
              <div className="flex">
                <Input
                  type="text"
                  placeholder="Rechercher par code"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <MagnifyingGlassCircleIcon className="w-10 h-90 " />
              </div>
              <Dialog size="xs" open={open} handler={handleOpen} className="bg-transparent shadow-none">
                <Card className="mx-auto w-full max-w-[24rem]">
                  <CardHeader className="grid mb-4 h-28 place-items-center ">Message</CardHeader>
                  <CardBody className="flex flex-col gap-4">
                    <Label>Designation</Label>
                    <Input />
                  </CardBody>
                  <CardFooter className="pt-0">
                    <Typography variant="small" className="flex justify-center mt-6">
                      <ButtonPrimary>Ajouter</ButtonPrimary>
                    </Typography>
                  </CardFooter>
                </Card>
              </Dialog>
            </div>
            {deleteModalOpen && (
              <DeleteModal
                open={deleteModalOpen}
                handleClose={() => setDeleteModalOpen(false)}
                handleDelete={handleDeleteConfirm}
                category={{
                  id: deleteCategoryId || -1,
                  designation: deleteCategoryDesignation || "",
                }}
              />
            )}
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-sm font-light text-center">
                    <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                      <tr>
                        <th scope="col" className="px-6 py-4 ">
                          Code
                        </th>
                        <th scope="col" className="px-6 py-4 ">
                          Rembourcement
                        </th>

                        <th scope="col" className="px-6 py-4 ">
                          A rembourser
                        </th>
                        <th scope="col" className="px-6 py-4 ">
                          Status
                        </th>
                        <th scope="col" className="px-6 py-4 ">
                          consulter
                        </th>
                        <th scope="col" className="px-6 py-4 ">
                          Supprimer
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservations &&
                        reservations

                          .filter((item) => item.reference.toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((item: any, index) => (
                            <tr className="border-b dark:border-neutral-500" key={index}>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">{item.reference}</td>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">
                                {item.refund_pourcentage === "total" ? "total" : "partial"}
                              </td>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">
                                {parseFloat(item.price).toFixed(2)} €
                              </td>

                              <td className="px-6 py-4 whitespace-nowrap">
                                {item.refund !== true ? (
                                  <ButtonSecondary
                                    onClick={() => ReturnClientPayment(item)}
                                    loading={isLoading[item.id]}
                                  >
                                    {" "}
                                    <span className="relative flex w-3 h-3">
                                      <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400"></span>
                                      <span className="relative inline-flex w-3 h-3 rounded-full bg-sky-500"></span>
                                    </span>
                                    <span className="ml-3">Rembourser</span>
                                  </ButtonSecondary>
                                ) : (
                                  <span className="font-semibold text-secondary-6000">remboursé</span>
                                )}
                              </td>

                              <td className="px-6 py-4 whitespace-nowrap">
                                <i
                                  className="text-2xl cursor-pointer las la-eye"
                                  onClick={() => handleEyeIconClick(item)}
                                ></i>{" "}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <i
                                  className="text-2xl las la-trash-alt"
                                  onClick={() => handleDeleteClick(item.id, item.designation)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  <div className="flex justify-end ">
                    <span className="text-sm text-gray-700">{count} élément</span>
                  </div>
                  {showReservationDetails && (
                    <ReservationDetailsPage
                      open={true}
                      handleClose={() => setshowReservationDetails(false)}
                      Data={selectedReservation}
                    />
                  )}
                  {modalOpen && (
                    <AlertModalMessage
                      open={modalOpen}
                      handleClose={() => setModalOpen(false)}
                      message={`${message}`}
                      alertText="Important"
                    />
                  )}
                  <div className="flex items-center justify-center mt-11">
                    {typeof totalPages === "number" && (
                      <Pagination currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showSpinner && (
          <div className="flex items-center justify-center mt-4 ">
            <Spinner color="blue" className="w-36 h-36" />
          </div>
        )}
      </DashboardMenu>
    </div>
  );
};

export default ListCanceledReservationByClientAdminPage;
