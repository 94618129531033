import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC, useState } from "react";

export interface CheckboxInputProps {
  label?: string | React.ReactNode;
  subLabel?: string;
  className?: string;
  checked?: any;
  name: string;
  labelClassName?: string;
  defaultChecked?: boolean;
  onChange?: (checked: boolean | any) => void;
}

const CheckboxInput: FC<CheckboxInputProps> = ({
  subLabel = "",
  label = "",
  name,
  className = "",
  defaultChecked,
  labelClassName = "",
  onChange,
}) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange({ checked: e.target.checked });
  };

  return (
    <div className={`flex text-sm sm:text-base ${className}`}>
      <input
        id={name}
        name={name}
        type="checkbox"
        className={`w-6 h-6 bg-white rounded focus:ring-action-primary text-primary-500 border-primary border-neutral-500 dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500 ${className}`}
        defaultChecked={defaultChecked}
        onChange={handleCheckboxChange}
      />
      {label && (
        <label htmlFor={name} className={`ml-3.5 flex flex-col flex-1 justify-center ${labelClassName}`}>
          <span className="text-neutral-900 dark:text-neutral-100">{label} </span>
          {subLabel && (
            <p className="mt-1 text-sm font-light text-neutral-500 dark:text-neutral-400">{subLabel}</p>
          )}
        </label>
      )}
    </div>
  );
};

export default CheckboxInput;
