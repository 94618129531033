import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { signInService } from "./signInAPI";

interface UserState {
  loading: "idle" | "pending" | "succeeded" | "failed";

  signInError?: string;

  error?: SerializedError;
}

const initialState: UserState = {
  loading: "idle",
};

export const login = createAsyncThunk("login", async (data: any) => {
  return await signInService.login(data);
});

export const signInSlice = createSlice({
  name: "SignIn",
  initialState,
  reducers: {
    initStates(state) {
      return {
        ...state,
        loading: "idle",
        signInError: undefined,
        error: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(login.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";

        localStorage.setItem("token", action.payload.token);
      }

      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.signInError = action.payload.error;
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = "failed";

      state.error = action.error;
    });
  },
});

export const { initStates } = signInSlice.actions;

export const selectCount = (state: RootState) => state.auth;

export default signInSlice.reducer;
