import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { Card, List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";

export interface DashboardMenuCreatorProps {
  children?: React.ReactNode;
}

const DashboardMenuCreator: FC<DashboardMenuCreatorProps> = ({ children }) => {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value: React.SetStateAction<number>) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="flex bg-neutral-50 dark:bg-neutral-900">
      <div className="w-1/4 p-6 border-r border-neutral-200 dark:border-neutral-700">
        <Card className="h-[calc(100vh-2rem)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 dark:bg-gray-800">
          <div className="p-4 mb-2">
            <Typography variant="h5" color="blue-gray" className="dark:text-white">
              Bienvenue
            </Typography>
          </div>
          <List>
            <ListItem>
              <ListItemPrefix>
                <Cog6ToothIcon className="w-5 h-5 dark:text-white" />
              </ListItemPrefix>
              <Link className="dark:text-white" to="/dashboard-creator">
                Mes Articles
              </Link>
            </ListItem>
            <ListItem>
              <ListItemPrefix>
                <Cog6ToothIcon className="w-5 h-5 dark:text-white" />
              </ListItemPrefix>
              <Link className="dark:text-white" to="/dashboard/add-article">
                Ajouter Article
              </Link>
            </ListItem>
          </List>
        </Card>
      </div>
      <div className="container pb-24 pt-14 sm:pt-20 lg:pb-32">{children}</div>
    </div>
  );
};

export default DashboardMenuCreator;
