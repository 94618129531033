import React from "react";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

import "./accordion.css";
function Icon({ id, open }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

export function DefaultAccordion(props: any) {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value: any) => setOpen(open === value ? 0 : value);

  return (
    <>
      <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
        <AccordionHeader onClick={() => handleOpen(1)}>{props?.question}</AccordionHeader>
        <AccordionBody>
          {props?.answer}
          <br />
          <br />
          {props.children}
        </AccordionBody>
      </Accordion>
    </>
  );
}
