import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sliderApi } from "./sliderAPI";

interface SliderState {
  images: string[];
  id: any;
  status: string;
}

const initialState: SliderState = {
  images: [],
  id: "",
  status: "",
};

export const addSlider = createAsyncThunk("featureSlider/addSlider", async (data: any) => {
  return await sliderApi.add(data);
});

export const fetchSliders = createAsyncThunk("featureSlider/fetchSlider", async () => {
  return await sliderApi.getSliders();
});
export const updateSlider = createAsyncThunk(
  "featureSlider/editSlider",
  async ({ data, id }: { data: any; id: any }) => {
    return await sliderApi.update(data, id);
  }
);
const createSliderSlice = createSlice({
  name: "featureSlider",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addSlider.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addSlider.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.images = action.payload;
      })
      .addCase(addSlider.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchSliders.fulfilled, (state, action) => {
        state.images = action.payload[0]?.images;
        state.id = action.payload[0]?.id;
      })
      .addCase(updateSlider.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSlider.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.images = action.payload;
      })
      .addCase(updateSlider.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = createSliderSlice.actions;
export default createSliderSlice.reducer;
