import React from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography } from "@material-tailwind/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";

interface DeleteModalProps {
  open: boolean;
  handleClose?: () => void;
  clientData: any;
}
const ClientDetailsPage: React.FC<DeleteModalProps> = ({ open, handleClose, clientData }) => {
  const [openx, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Dialog open={open} handler={handleOpen} className="w-11/12 dark:bg-gray-800">
        <DialogHeader>
          <Typography variant="h5" color="blue-gray" className="text-center dark:text-white">
            Information détaillée du client
          </Typography>
        </DialogHeader>
        <DialogBody divider className="grid gap-4 place-items-center">
          <Typography className="font-normal text-center">
            <div>
              <Label className="font-semibold">Nom :</Label>
              <span className="dark:text-white"> {clientData?.name} </span>
            </div>

            <div>
              <Label className="font-semibold">Prénom :</Label>
              <span className="dark:text-white"> {clientData?.lastName} </span>
            </div>

            <div>
              <Label className="font-semibold">Numéro d'identité :</Label>
              <span className="dark:text-white"> {clientData?.identityNumber} </span>
            </div>

            <div>
              <Label className="font-semibold">Numéro de téléphone :</Label>
              <span className="dark:text-white"> {clientData?.phone} </span>
            </div>

            <div>
              <Label className="font-semibold">Adresse Email :</Label>
              <span className="dark:text-white"> {clientData?.User?.email} </span>
            </div>

            <div>
              <Label className="font-semibold">Inscrit le :</Label>
              <span className="dark:text-white"> {new Date(clientData?.createdAt).toLocaleDateString()} </span>
            </div>

            <div>
              <Label className="font-semibold">Dernière connexion le:</Label>
              <span className="dark:text-white">
                {" "}
                {new Date(clientData?.User?.last_connection).toLocaleDateString("fr-FR", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                })}
              </span>
            </div>
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <ButtonSecondary onClick={handleClose} className="dark:bg-dark dark:text-white">
            Quitter
          </ButtonSecondary>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ClientDetailsPage;
