import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import jwtDecode from "jwt-decode";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { json } from "stream/consumers";
import DashboardMenu from "./DashboardMenu";

const AccountPassCreator = () => {
  const server = process.env.REACT_APP_SERVER_URL;
  const token = localStorage.getItem("token") || "";
  let userId: any;
  let profilId: any;

  const oldPasswordRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const newPasswordRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const confirmPasswordRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  if (token) {
    const decodedToken = jwtDecode(token) as {
      userId: string;
      profilId: string;
    };
    userId = decodedToken.userId;
    profilId = decodedToken.profilId;
  }

  useEffect(() => {
    fetch(`${server}/api/user/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setEmail(data.email);
      });
  }, []);

  const updatePassword = () => {
    const url = `${server}/api/user/password/`;
    const oldPassword = oldPasswordRef.current?.value;
    const newPassword = newPasswordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;

    if (newPassword !== confirmPassword) {
      setError("Les nouveaux mots de passe ne correspondent pas.");
      return;
    }

    setError("");
    const requestBody = {
      email,
      newPassword,
      oldPassword,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdateClick = () => {
    updatePassword();
  };

  return (
    <div>
      <DashboardMenu>
        <div className="space-y-6 sm:space-y-8">
          <h2 className="text-3xl font-semibold">Mettez à jour votre mot de passe.</h2>
          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-xl space-y-6 ">
            <div>
              <Label>Mot de passe actuel</Label>
              <Input type="password" className="mt-1.5" ref={oldPasswordRef} />
            </div>
            <div>
              <Label>Nouveau mot de passe</Label>
              <Input type="password" className="mt-1.5" ref={newPasswordRef} />
            </div>
            <div>
              <Label>Confirmer le mot de passe</Label>
              <Input type="password" className="mt-1.5" ref={confirmPasswordRef} />
            </div>
            {error && <div className="text-red-500">{error}</div>}
            <div className="pt-2">
              <ButtonPrimary onClick={handleUpdateClick}>Mise à jour</ButtonPrimary>
            </div>
          </div>
        </div>
      </DashboardMenu>
    </div>
  );
};

export default AccountPassCreator;
