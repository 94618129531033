import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import FormItem from "./FormItem";

import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "@material-tailwind/react";
import { ServiceFormProps } from "data/reduxInterfaces";
import { ProfilService } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import Multiselect from "multiselect-react-dropdown";

interface SelectedValue {
  label: string;
}

const ServiceDetails: FC<ServiceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  errorServiceForm,
  FormData,
}) => {
  const serviceState = useAppSelector((state) => state.service);

  const [defaultRegion, setDefaultRegion] = useState<string[]>([]);

  const formSchema = z.object({
    title: z.string().min(1, { message: "Le nom du service est obligatoire." }),
    //region: z.array(z.string()).nonempty({ message: 'Les régions disponibles est obligatoire.' }),
    region:
      serviceState.serviceData === undefined
        ? z.array(z.string()).nonempty({ message: "Les régions disponibles est obligatoire." })
        : z.array(z.string()).nonempty({ message: "Les régions disponibles est obligatoire." }).optional(),

    description: z.string().min(1, { message: "La description est obligatoire." }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const availability = useAppSelector((state) => {
    const profile = state.auth.user?.profil;

    if (profile && "availability" in profile) {
      return profile.availability;
    }
    return [];
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  useEffect(() => {
    if (handleForm.type === "chekServiceFormOne") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm.type]);

  useEffect(() => {
    if (serviceState && serviceState?.serviceData && typeof serviceState.serviceData.region === "string") {
      setDefaultRegion([serviceState.serviceData.region]);
    } else if (serviceState && serviceState?.serviceData && Array.isArray(serviceState.serviceData.region)) {
      setDefaultRegion(serviceState?.serviceData?.region);
    }
  }, [serviceState]);

  const selectedValues: string[] = defaultRegion;
  //const selectedLabels: SelectedValue[] = selectedValues?.map((value: string) => ({ label: value, value }));
  const selectedLabels: SelectedValue[] = selectedValues?.map((value: string) => ({ label: value }));

  return (
    <Fragment>
      <h2 className="text-2xl font-semibold">Ajouter votre prestation</h2>
      <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

      {errorServiceForm && (
        <Alert color="red" className="text-xs">
          {errorServiceForm}
        </Alert>
      )}

      <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <FormItem label={<> Nom du votre prestation *</>}>
          <Input
            type="text"
            defaultValue={serviceState?.serviceData?.title}
            placeholder="Nom du votre prestation"
            disabled={isLoading}
            className={`mt-1.5 ${errors.title?.message ? "border-red-500" : ""}`}
            {...register("title")}
          />
          {errors.title?.message && <div className="text-xs text-red-500 ">{errors.title?.message}</div>}
        </FormItem>

        <div className="region">
          <span className="block mb-3 text-lg font-semibold">Les régions disponibles pour vos prestations *</span>
          <Multiselect
            options={availability?.map((item) => ({ label: item, value: item }))}
            selectedValues={selectedLabels}
            onSelect={(selectedList) => {
              const labels = selectedList.map((item: { label: any }) => item.label);
              setValue("region", labels);
            }}
            onRemove={(selectedList) => {
              const labels = selectedList.map((item: { label: any }) => item.label);
              setValue("region", labels);
            }}
            displayValue="label"
            className={errors.region?.message ? "border-red-500" : ""}
            placeholder="Select regions"
            closeOnSelect={false}
            disable={isLoading}
            showCheckbox
            isObject={true}
          />

          {errors.region?.message && (
            <div className="text-xs text-red-500">{errors.region?.message.toString()}</div>
          )}
        </div>

        <div>
          <div className="flex items-center">
            <h2 className="text-2xl font-semibold">Description de prestation</h2>
            <span className="ml-2 text-xs ">*</span>
          </div>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Mentionnez les meilleures caractéristiques de votre prestations ,
          </span>
        </div>

        <Textarea
          placeholder="..."
          rows={14}
          {...register("description")}
          defaultValue={serviceState?.serviceData?.description}
        />

        {errors.description?.message && <div className="text-xs text-red-500">{errors.description?.message}</div>}
      </form>
    </Fragment>
  );
};

export default ServiceDetails;
