import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createLocationFeatureReducer from "./features/locations/locationSlice";
import createFeatureCategory from "./features/Admin/categorys/categorySlice";
import createFeatureAtmosphere from "./features/Admin/atmospheres/atmosphereSlice";
import createFeaturePlayground from "./features/Admin/playground/playgroundSlice";
import createFeatureRule from "./features/Admin/rules/rulesSlice";
import createFeatureEquipment from "./features/Admin/equipments/equipmentSlice";
import createFeatureField from "./features/Admin/Fields/fieldSlice";
import createFeatureOwner from "./features/owners/ownersSlice";
import blogReducer from "./features/blog/blogSlice";
import commentOwnerReducer from "./features/comment-location/comment-locationSlice";
import sliderReducer from "./features/Admin/Sliders/sliderSlice";
import createFeatureEvent from "./features/Admin/Events/eventSlice";
import authReducer from "./features/auth/authSlice";
import forgetPasswordReducer from "./features/forgetPassword/forgetPasswordSlice";
import unvailableReducer from "./features/unavailable/unavailableSlice";
import reservationReducer from "./features/reservations/reservationSlice";
import signInReducer from "./features/signIn/signInSlice";
import signUpReducer from "./features/signUp/signUpSlice";
import contactReducer from "./features/contact/contactSlice";
import serviceReducer from "./features/service/serviceSlice";
import locationReducer from "./features/location/locationSlice";
import videoReducer from "./features/Admin/videos/videoSlice";
import videoHomeReducer from "./features/Admin/homeVideo/homeVideoSlice";
import stripeReducer from "./features/Admin/Stripe/stripeSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import logger from "redux-logger";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "location", "featureService"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  forgetPassword: forgetPasswordReducer,
  signIn: signInReducer,
  signUp: signUpReducer,
  featureLocation: createLocationFeatureReducer,
  featureCategory: createFeatureCategory,
  featureAtmosphere: createFeatureAtmosphere,
  featurePlayground: createFeaturePlayground,
  featureRules: createFeatureRule,
  featureEquipment: createFeatureEquipment,
  featureField: createFeatureField,
  featureEvent: createFeatureEvent,
  featureOwner: createFeatureOwner,
  featureComment: commentOwnerReducer,
  featureBlog: blogReducer,
  featureReservation: reservationReducer,
  service: serviceReducer,
  location: locationReducer,
  featureSlider: sliderReducer,
  video: videoReducer,
  featureUnvailable: unvailableReducer,
  featureContact: contactReducer,
  videoHome: videoHomeReducer,
  stripe: stripeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false,
    });

    if (process.env.NODE_ENV !== "production") {
      // middlewares.push(logger);
    }

    return middlewares;
  },
  // devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
