import { CalendarDaysIcon, HomeIcon, PencilIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { LocationFormProps } from "data/reduxInterfaces";
import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Textarea from "shared/Textarea/Textarea";
import { z } from "zod";
import FormItem from "./FormItem";
import Input from "shared/Input/Input";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { fetchAtmospheres } from "redux/features/Admin/atmospheres/atmosphereSlice";
import { Checkbox, Spinner, Typography } from "@material-tailwind/react";

interface Atmosphere {
  id: number;
  designation: string;
}

const formSchema = z.object({
  title: z.string().min(1, { message: "Le nom du l'espace est obligatoire." }),
  description: z.string().min(10, {
    message: "La description est obligatoire et doit contenir au minimum 10 caractères.",
  }),
  atmosphere: z.array(z.string()).optional(),
});

const LocationDescription: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  isLoading,
  handleForm,
  data,
  FormData,
}) => {
  const dispatch = useAppDispatch();

  const atmospheresStates = useAppSelector((state) => state.featureAtmosphere);

  const [dataLoading, setDataLoading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: data?.title ?? "",
      description: data?.description ?? "",
      atmosphere: data?.atmosphere ?? [],
    },
    mode: "onSubmit",
  });

  const handleSelect = (atmosphereDesignation: string, isChecked: boolean) => {
    const updatedAtmospheres = isChecked
      ? [...(getValues("atmosphere") ?? []), atmosphereDesignation]
      : getValues("atmosphere")?.filter((atmosphere: string) => atmosphere !== atmosphereDesignation);

    setValue("atmosphere", updatedAtmospheres as string[]);
    trigger("atmosphere");
  };

  useEffect(() => {
    dispatch(fetchAtmospheres());
  }, []);

  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      setValue("description", data.description);
      setValue("atmosphere", data.atmosphere);
    }
  }, [data]);

  useEffect(() => {
    if (atmospheresStates.atmospheres) {
      setDataLoading(false);
    }
  }, [atmospheresStates.atmospheres]);

  useEffect(() => {
    if (handleForm.type === "chekLocationFormSeven") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  return (
    <Fragment>
      <form className="flex flex-col md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full md:w-3/4 md:mr-4">
          <div>
            <h2 className="text-2xl font-semibold">Titre de votre annonce </h2>
          </div>

          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

          <FormItem label="Nom du l'espace *" className="mt-5">
            <div className="mt-1 md:w-1/2">
              <Input
                className={`mt-1.5 ${errors.title?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                placeholder="Nom du l'espace"
                {...register("title")}
              />
            </div>
            {errors.title?.message && <div className="text-xs text-red-500">{`${errors.title?.message}`}</div>}
          </FormItem>

          <div>
            <h2 className="mt-5 text-2xl font-semibold">Description </h2>
          </div>

          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

          <div className="space-y-8">
            <div>
              <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">
                Décrivez votre espace aux visiteurs. Rédigez un bref résumé en mettant en avant les
                caractéristiques de votre espace.
              </span>
            </div>
            <Textarea disabled={isLoading} placeholder="..." rows={14} {...register("description")} />
            {errors.description?.message && (
              <div className="text-xs text-red-500">{`${errors.description?.message}`}</div>
            )}
          </div>

          <div className="space-y-8">
            <div className="my-5">
              <label className="text-lg font-semibold " htmlFor="">
                Ambiance de votre espace
              </label>
              <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
              <div
                className={`grid grid-cols-1 mt-6 ${
                  atmospheresStates.atmospheres.length > 10 ? "sm:grid-cols-2 lg:grid-cols-3" : ""
                }`}
              >
                {dataLoading ? (
                  <div className="flex items-center justify-center mt-16">
                    <p>
                      <Spinner color="blue" className="w-12 h-12" />
                    </p>
                  </div>
                ) : (
                  atmospheresStates.atmospheres?.map((atmosphere: Atmosphere) => (
                    <Checkbox
                      key={atmosphere.designation}
                      label={
                        <Typography className="text-dark dark:text-white">{atmosphere.designation}</Typography>
                      }
                      disabled={isLoading}
                      name={atmosphere.designation}
                      checked={!!getValues("atmosphere")?.includes(atmosphere.designation)}
                      onChange={(e) => handleSelect(atmosphere.designation, e.target.checked)}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-4 md:w-1/3 md:mt-0">
          <div className="p-4 border-2 border-indigo-800">
            <div className="flex items-center mt-5 ml-5">
              <HomeIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Nom de l'espace</h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mb-3 mr-5 -ml-8 text-sm">
                Entrez le nom ou le titre de l'espace que vous mettez en location. Le nom de l'espace est important
                car il donne une première impression et aide à identifier l'endroit de manière claire et
                distinctive. Choisissez un nom évocateur et pertinent qui reflète l'essence ou l'attrait
                particulier de votre espace pour attirer l'attention des utilisateurs potentiels.
              </p>
            </div>
            <div className="flex items-center mt-5 ml-5">
              <PencilIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Description de l'espace</h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mr-5 -ml-8 text-sm ">
                Dans ce champ, vous pouvez fournir une description détaillée de l'espace que vous mettez en
                location. Décrivez les caractéristiques principales de l'endroit, son ambiance, sa capacité
                d'accueil, son agencement, et tout autre détail pertinent. Présentez les atouts uniques de
                l'espace, son style, ses particularités architecturales, et les éventuelles fonctionnalités
                spéciales. Cette description aidera les utilisateurs à avoir une idée claire de l'espace et de son
                potentiel pour leur événement ou leur utilisation spécifique. N'hésitez pas à inclure des
                informations sur l'historique de l'endroit ou des anecdotes intéressantes pour susciter l'intérêt
                des utilisateurs.
              </p>
            </div>

            <div className="flex items-center mt-5 ml-5">
              <CalendarDaysIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Tags</h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mb-3 mr-5 -ml-8 text-sm">
                Précisez si cet espace définissent les objectifs et les aspirations visant à façonner un
                environnement qui répond aux besoins et aux préférences spécifiques de ses utilisateurs. Cela peut
                inclure la création d'un cadre propice au bien-être et à la détente, en intégrant des éléments de
                design inspirés de la nature et en favorisant une atmosphère zen.{" "}
              </p>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default LocationDescription;
