import React, { Fragment } from "react";
import { Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface AlertModalDeleteProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  email?: string;
  message?: string;
  alertText?: string;
  navigateTo?: string;
}

const AlertModalDelete: React.FC<AlertModalDeleteProps> = ({
  open,
  handleClose,
  handleDelete,
  message,
  alertText,
  navigateTo,
}) => {
  const handleCloseModal = () => {
    handleClose();
    if (navigateTo != undefined) {
      window.location.href = navigateTo;
    }
  };

  const handleDeleteReservation = () => {
    handleDelete();
  };

  return (
    <Fragment>
      <Dialog open={open} handler={handleCloseModal}>
        <DialogHeader className="text-center">{alertText}</DialogHeader>
        <DialogBody divider>{message}</DialogBody>
        <DialogFooter>
          <div className="flex space-x-3">
            <ButtonPrimary onClick={handleDeleteReservation}>
              <span>Confirmer</span>
            </ButtonPrimary>
            <ButtonSecondary onClick={handleCloseModal}>
              <span>Quitter</span>
            </ButtonSecondary>
          </div>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default AlertModalDelete;
