const server = process.env.REACT_APP_SERVER_URL || "";
export const unavailable = {
  getUnvailableByLocations: async (id: any) => {
    const response = await fetch(`${server}/api/unavailable/location/${id}`);
    return response.json();
  },

  geAllUnvailables: async () => {
    const response = await fetch(`${server}/api/unavailable`);
    return response.json();
  },

  add: async (formData: any) => {
    try {
      const response = await fetch(`${server}/api/unavailable/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  remove: async (formData: any) => {
    try {
      const response = await fetch(`${server}/api/unavailable/unblock`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  update: async (data: any, id: any) => {
    const result = await fetch(`${server}/api/unavailable/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify(data),
    });
    return result.json();
  },
  getUnvailableById: async (id: any) => {
    const response = await fetch(`${server}/api/unavailable/${id}`);
    return response.json();
  },
  deleteUnvailable: async (id: any) => {
    const response = await fetch(`${server}/api/unavailable/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json();
  },
};
