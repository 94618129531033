import { Checkbox, Radio, Typography } from "@material-tailwind/react";
import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

import { zodResolver } from "@hookform/resolvers/zod";
import { ServiceFormProps } from "data/reduxInterfaces";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getFields, getService, setServiceData } from "redux/features/service/serviceSlice";
import { z } from "zod";
import { fetchEvents } from "redux/features/Admin/Events/eventSlice";
import { Event } from "data/types";

const formSchema = z.object({
  events: z.array(z.string()).min(0),
});

const UpdateServiceExtraDetails: FC<ServiceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  errorServiceForm,
  FormData,
}) => {
  const dispatch = useAppDispatch();
  const events: Event[] = useAppSelector((state) => state.featureEvent.events);
  const { id } = useParams();
  const serviceState = useAppSelector((state) => state.service?.serviceData);
  useEffect(() => {
    if (id) {
      dispatch(getService(id));
    }
  }, []);

  useEffect(() => {
    if (serviceState) {
      setValue("events", serviceState.events || "");
    }
  }, [serviceState]);

  /*    useEffect(() => {
    if (serviceState && serviceState.images && serviceState.tarif) {
      dispatch(setServiceData({ images: serviceState.images, tarif: serviceState.tarif }));
    }
  }, [serviceState]); 
 */
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { events: serviceState?.events },
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  useEffect(() => {
    if (handleForm.type === "chekServiceUpdateFormTwo") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  useEffect(() => {
    dispatch(getFields());
  }, []);

  useEffect(() => {
    dispatch(fetchEvents());
  }, []);

  return (
    <Fragment>
      <div>
        <h2 className="text-2xl font-semibold">Spécification du prestation </h2>
      </div>
      {/* FORM */}
      <div className="space-y-8">
        {/* ITEM */}
        <div>
          <label className="text-lg font-semibold" htmlFor="">
            Type d'événements
          </label>
          <div className="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-3">
            {events?.map((event) => (
              <Checkbox
                key={event.designation}
                color="blue"
                label={<Typography className="text-dark dark:text-white">{event.designation}</Typography>}
                value={event.designation}
                {...register("events")}
              />
            ))}
          </div>
          {errors.events?.message && <div className="text-xs text-red-500 ">{errors.events?.message}</div>}
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateServiceExtraDetails;
