import Glide from "@glidejs/glide";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import { AuthContext } from "context/appContext";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { fetchFields } from "redux/features/Admin/Fields/fieldSlice";
import NcImage from "shared/NcImage/NcImage";
import LocationGridFilterCard from "./LocationsGridFilterCard";
import { initStates } from "redux/features/locations/locationSlice";
export interface LocationsPageProps {
  className?: string;
}

const renderPostRelated = (field: any) => {
  return (
    <div key={field.id} className="relative overflow-hidden aspect-w-16 aspect-h-1 rounded-3xl group">
      <Link to={`/prestations?field=${field.designation}`} />
      <NcImage
        className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-105"
        src={process.env.REACT_APP_CLOUD_FRONT + field.images[0]}
      />
      <div>
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-black"></div>
      </div>
      <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
        <h2 className="block max-w-full text-lg font-semibold text-white">
          <span className="line-clamp-2">{field?.designation}</span>
        </h2>
      </div>
      <Link to={`/prestations?field=${field.designation}`} />
    </div>
  );
};

const LocationsPage: FC<LocationsPageProps> = ({ className = "" }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const decodedPathname = decodeURIComponent(pathname);
  const afterEvent = decodedPathname.split("/event/")[1];
  const authContext = useContext(AuthContext);
  const isOwner = authContext && authContext.user?.role === "owner";
  const dispatch = useAppDispatch();
  const fieldState = useAppSelector((state) => state.featureField);

  useEffect(() => {
    dispatch(fetchFields());
  }, []);
  const glideRef = useRef(null);
  useEffect(() => {
    if (fieldState && fieldState?.fields.length > 3 && glideRef.current) {
      const glide = new Glide(glideRef.current, {
        type: "carousel",
        perView: 4,
        autoplay: 1500,
      });

      glide?.mount();
      return () => glide?.destroy();
    }
  }, [fieldState]);

  useEffect(() => {
    dispatch(initStates());
  }, []);

  return (
    <div className={`overflow-hidden relative nc-ListingStayPage ${className}`} data-nc-id="ListingStayPage">
      <Helmet>
        <title>Event To Bee - Les Espaces</title>
        <meta
          name="description"
          content="Trouvez tous les meilleurs espaces à louer pour votre évènement sur Event To Bee"
        />
      </Helmet>

      <br />
      <div className="container relative"></div>
      <br />
      <div className="container relative overflow-hidden">
        {/* SECTION Locations */}
        <br />
        <LocationGridFilterCard className="pb-24 lg:pb-28" />
        <div id="hotes"></div>
        <br />
        <br />
        <br />
        {/* SECTION Author */}
        <div className="relative py-16">
          {isOwner ? null : (
            <div>
              <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20" />
              <SectionGridAuthorBox />
            </div>
          )}
        </div>
        <br />
        {/* SECTION Services Types */}
      </div>
      {fieldState && fieldState.fields.length > 3 && (
        <div className="relative py-16 mt-16 bg-neutral-100 dark:bg-neutral-800 lg:py-28 lg:mt-24">
          <div className="container glide" ref={glideRef}>
            <h2 className="text-3xl font-semibold text-center">Découvrez Nos Prestataires</h2>
            <div
              className="grid gap-6 mt-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8 glide__track"
              data-glide-el="track"
            >
              <div className="glide__slides">{fieldState && fieldState?.fields?.map(renderPostRelated)}</div>
            </div>
          </div>
        </div>
      )}
      <br />
    </div>
  );
};

export default LocationsPage;
