import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { FC } from "react";
import { Helmet } from "react-helmet";
import SectionGridFieldFilterCard from "./ServiceFieldGridFilterCard";

export interface ServicesProps {
  className?: string;
}

const ServicesAll: FC<ServicesProps> = ({ className = "" }) => {
  return (
    <div className={`nc-ListingStayPage relative overflow-hidden ${className}`} data-nc-id="ListingStayPage">
      <Helmet>
        <title>Event To Bee - Les Prestations</title>
        <meta name="description" content="Trouvez tous les meilleurs prestations  pour votre évènement" />
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <br />

        {/* SECTION */}
        <SectionGridFieldFilterCard className="pb-24 lg:pb-28" />

        <br />
      </div>
    </div>
  );
};

export default ServicesAll;
