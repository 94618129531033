const SectionHead = ({ tag, title, desc }) => {
  return (
    <div className="row mb--60">
      <div className="col-lg-12">
        <div className="text-center section-title">
          {tag === "" ? "" : <span className=""></span>}
          <h2 className="text-4xl font-bold text-black dark:text-white ">{title}</h2>
          {desc === "" ? "" : <p className="description mt--30 ">{desc}</p>}
        </div>
      </div>
    </div>
  );
};

export default SectionHead;
