import { FC, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import { z } from "zod";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { forgetPassword, setForgetPasswordError } from "redux/features/forgetPassword/forgetPasswordSlice";

const formSchema = z.object({
  email: z.string().email(),
});

const ForgotPassword: FC<{}> = () => {
  const dispatch = useAppDispatch();

  const forgotPasswordState = useAppSelector((state) => state.forgetPassword);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSubmited, setIsSubmited] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    dispatch(setForgetPasswordError());
    dispatch(forgetPassword(data.email));
  };

  useEffect(() => {
    dispatch(setForgetPasswordError());
  }, []);

  useEffect(() => {
    switch (forgotPasswordState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsSubmited(true);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [forgotPasswordState.loading]);

  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Récuperer le mot de passe </title>
      </Helmet>

      <div className="container mb-24 ">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {isSubmited ? "Demande envoyée" : "Mot de passe oublié ?"}
        </h2>
        {!isSubmited && (
          <div className="w-full h-full p-4 mx-auto bg-white rounded-lg shadow-lg sm:w-11/12 md:w-10/12 lg:w-8/12 xl:w-7/12 sm:p-6 dark:bg-gray-800 ">
            <p className="p-5 text-sm text-center">
              Pas de problème ! Nous allons vous envoyer un lien pour le réinitialiser. Veuillez indiquer l'adresse
              e-mail que vous utilisez pour vous connecter à{" "}
              <Link to="/" className="text-blue-500 ">
                eventtobee.com
              </Link>
            </p>

            {!!forgotPasswordState.forgetPasswordError && (
              <p className="p-5 text-sm text-center text-red-500">
                Nous n'avons pas pu trouver cette adresse e-mail dans notre système. Veuillez vérifier si vous avez
                saisi correctement votre adresse e-mail.
              </p>
            )}

            <div className="max-w-md mx-auto">
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                <label className="block mt-5 ">
                  <span className="text-neutral-800 dark:text-neutral-200">Votre adresse e-mail</span>
                  <Input
                    type="text"
                    placeholder="example@example.com"
                    className={`mt-1 ${errors.email?.message ? "border-red-500" : ""}`}
                    disabled={isLoading}
                    {...register("email")}
                  />
                  {errors.email?.message && <div className="text-xs text-red-500">{errors.email?.message}</div>}
                </label>

                <ButtonPrimary type="submit">Envoyer</ButtonPrimary>
              </form>
            </div>
          </div>
        )}

        {isSubmited && (
          <div className="w-full h-full p-5 mx-auto bg-white rounded-lg shadow-lg sm:w-11/12 md:w-10/12 lg:w-8/12 xl:w-7/12 sm:p-6 dark:bg-gray-800">
            <div className="flex flex-col items-center justify-center">
              <p className="p-10 text-sm text-center">
                Nous venons de vous adresser un e-mail qui vous permettra de choisir un nouveau mot de passe. Vous
                pouvez consulter dès maintenant votre messagerie.
              </p>

              <ButtonPrimary href={"/login"}>Login</ButtonPrimary>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
