import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createCategory } from "./featureAPI";

const initialState = {
  designation: "",
  loading: "idle",
  categories: [],
  categorie: { designation: "" },
};

export const addCategory = createAsyncThunk("featureCategory/addCategory", async (data: any) => {
  return await createCategory.add(data);
});

export const fetchCategorys = createAsyncThunk("featureCategory/fetchCategory", async () => {
  return await createCategory.verify();
});

export const deleteCategory = createAsyncThunk("featureCategory/deleteCategory", async (id: number) => {
  return await createCategory.delete(id);
});

export const updateCategory = createAsyncThunk(
  "featureCategory/updateCategory",
  async ({ data, id }: { data: any; id: any }) => {
    return await createCategory.update(data, id);
  }
);
export const getCategoryById = createAsyncThunk("featureCategory/getCategoryById", async (id: any) => {
  return await createCategory.getById(id);
});
const createCategorySlice = createSlice({
  name: "featureCategory",
  initialState,
  reducers: {
    setDesignation(state, action) {
      state.designation = action.payload;
    },
    resetDesignation(state) {
      state.designation = "";
    },
    resetValues(state) {
      state.designation = "";
      state.loading = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addCategory.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(addCategory.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchCategorys.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteCategory.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(deleteCategory.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(updateCategory.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(updateCategory.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        state.categorie = action.payload;
      });
  },
});

export const { setDesignation, resetDesignation, resetValues } = createCategorySlice.actions;
export default createCategorySlice.reducer;
