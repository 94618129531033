import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HomeVideo, Video } from "data/types";

import { RootState } from "redux/store";
import { videoApi } from "./homeVideoAPI";

interface VideoState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  videoError: string;
  error: SerializedError | "";
  allVideos: HomeVideo[];
  videoData?: HomeVideo;
}

const initialState: VideoState = {
  loading: "idle",
  videoError: "",
  error: "",
  allVideos: [],
  videoData: undefined,
};

export const addVideo = createAsyncThunk("addVideo", async (data: any) => {
  return await videoApi.addVideo(data);
});

export const getAllHomeVideos = createAsyncThunk("getAllHomeVideos", async () => {
  return await videoApi.getAllVideos();
});

export const getHomeVideoById = createAsyncThunk("getVideoById", async (id: string) => {
  return await videoApi.getVideoById(id);
});

export const deleteVideo = createAsyncThunk("deleteVideo", async (id: string) => {
  return await videoApi.deleteVideo(id);
});

export const updateHomeVideo = createAsyncThunk(
  "updateHomeVideo",
  async ({ data, id }: { data: any; id: any }) => {
    return await videoApi.updateHomeVideo(data, id);
  }
);

export const homeVideoSlice = createSlice({
  name: "homeVideo",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    initStates(state) {
      state.loading = "idle";
      state.videoData = undefined;
      state.error = "";
      state.videoError = "";
      state.allVideos = [];
    },
  },
  extraReducers: (builder) => {
    // add video
    builder.addCase(addVideo.pending, (state, action) => {
      state.loading = "pending";
      state.videoError = "";
    });
    builder.addCase(addVideo.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.videoError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.videoError = action.payload.error;
      }
    });
    builder.addCase(addVideo.rejected, (state, action) => {
      state.loading = "failed";
    });

    // get all videos

    builder.addCase(getAllHomeVideos.fulfilled, (state, action) => {
      state.allVideos = action.payload;
    });

    builder.addCase(getHomeVideoById.fulfilled, (state, action) => {
      state.videoData = action.payload;
    });

    // update
    builder.addCase(updateHomeVideo.pending, (state, action) => {
      state.loading = "pending";
      state.videoError = "";
    });
    builder.addCase(updateHomeVideo.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.videoError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.videoError = action.payload.error;
      }
    });
    builder.addCase(updateHomeVideo.rejected, (state, action) => {
      state.loading = "failed";
    });
  },
});

export const { initStates, setError } = homeVideoSlice.actions;

export const selectCount = (state: RootState) => state.videoHome;
export default homeVideoSlice.reducer;
