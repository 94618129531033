import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO, NAVIGATION_CREATOR } from "data/navigation";
import jwtDecode from "jwt-decode";

function Navigation() {
  const token = localStorage.getItem("token") || "";
  let decodedToken: { profilId: string; role: string; userId: string } | null = null;
  let profilId = "";
  let role = "";
  let userId = "";
  if (token) {
    decodedToken = jwtDecode(token) as { profilId: string; role: string; userId: string };
    userId = decodedToken.userId;
    profilId = decodedToken.profilId;
    role = decodedToken.role;
  }
  // Choose the appropriate navigation based on the role
  const navigationData = role === "creator" ? NAVIGATION_CREATOR : NAVIGATION_DEMO;

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative ">
      {navigationData.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
