import { Dialog, DialogBody, DialogFooter, DialogHeader, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import React from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface DeleteModalProps {
  open: boolean;
  handleClose?: () => void;
  ownerData: any;
}
const OwnerDetailsPage: React.FC<DeleteModalProps> = ({ open, handleClose, ownerData }) => {
  const [openx, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Dialog open={open} handler={handleOpen} className="w-11/12 ">
        <DialogHeader>
          <Typography variant="h5" color="blue-gray" className="text-center">
            Information détaillée du propriétaires
          </Typography>
        </DialogHeader>
        <DialogBody divider className="grid gap-4 place-items-center">
          <Typography className="font-normal text-center">
            <div>
              <Label className="font-semibold">Type de compte :</Label>
              <span> {ownerData?.category} </span>
            </div>

            {ownerData?.name !== null && (
              <div>
                <Label className="font-semibold">Nom :</Label>
                <span>{ownerData?.name}</span>
              </div>
            )}

            {ownerData?.lastName !== null && (
              <div>
                <Label className="font-semibold">Prénom :</Label>
                <span>{ownerData?.lastName}</span>
              </div>
            )}

            {ownerData?.companyName !== null && (
              <div>
                <Label className="font-semibold">Nom du l'entreprise :</Label>
                <span>{ownerData?.companyName}</span>
              </div>
            )}

            {ownerData?.companySiren !== null && (
              <div>
                <Label className="font-semibold">SIREN de l'entreprise :</Label>
                <span>{ownerData?.companySiren}</span>
              </div>
            )}

            <div>
              <Label className="font-semibold">Numéro d'identité :</Label>
              <span> {ownerData?.identityNumber} </span>
            </div>

            <div>
              <Label className="font-semibold">Numéro de téléphone :</Label>
              <span> {ownerData?.phone} </span>
            </div>
            <div>
              <Label className="font-semibold">Bank :</Label>
              <span> {ownerData?.bank} </span>
            </div>

            <div>
              <Label className="font-semibold">Ville :</Label>
              <span> {ownerData?.ville} </span>
            </div>

            <div>
              <Label className="font-semibold">Supplément d'adresse :</Label>
              <span> {ownerData?.suppAdresse} </span>
            </div>

            <div>
              <Label className="font-semibold">Code postale :</Label>
              <span> {ownerData?.codePostale} </span>
            </div>

            <div>
              <Label className="font-semibold">RIB :</Label>
              <span> {ownerData?.rib} </span>
            </div>

            {ownerData?.lastName !== null && (
              <div>
                <Label className="font-semibold">Date de naissance :</Label>
                <span> {new Date(ownerData?.birthday).toLocaleDateString()} </span>
              </div>
            )}
            <div>
              <Label className="font-semibold">Inscrit le :</Label>
              <span> {new Date(ownerData?.createdAt).toLocaleDateString()} </span>
            </div>

            <div>
              <Label className="font-semibold">Dernière connexion le:</Label>
              <span className="dark:text-white">
                {" "}
                {new Date(ownerData?.User?.last_connection).toLocaleDateString("fr-FR", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                })}
              </span>
            </div>
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <ButtonSecondary onClick={handleClose} className="dark:bg-white dark:text-black">
            Quitter
          </ButtonSecondary>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default OwnerDetailsPage;
