import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./AgentCommonLayout";
import { useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import AlertModalMessage from "components/AlertModal/AlertModalMessage";

export interface Data {
  id: number;
}

const AgentDeleteAccountPage = () => {
  const server = process.env.REACT_APP_SERVER_URL;

  const token = localStorage.getItem("token") || "";
  const decodedToken = jwtDecode(token) as { profilId: string };

  const profilId = decodedToken.profilId;
  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    fetch(`${server}/api/client/${profilId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setShowModal(true);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Suppression de Votre Compte</h2>
          <AlertModalMessage
            open={showModal}
            handleClose={() => setShowModal(false)}
            message={`Votre compte a été supprimé .`}
            alertText="succès."
            navigateTo="/"
          />
          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-2xl">
            <span className="block text-xl font-semibold">Noté bien </span>
            <br />
            <span className="block text-neutral-700 dark:text-neutral-300">
              Veuillez noter que la suppression de votre compte est une action irréversible et toutes les données
              associées à votre profil seront définitivement supprimées.
              <br />
              <br />
              Assurez-vous de sauvegarder toutes les informations importantes avant de procéder à la suppression.
            </span>
            <div className="pt-10">
              <ButtonPrimary onClick={handleDelete}>Supprimer</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AgentDeleteAccountPage;
