import { FC } from "react";
import { DefaultAccordion } from "components/Accordion/accordion";
import Faq from "./faq";

export interface AgentFaqProps {
  className?: string;
}
const clientFaq: FC<AgentFaqProps> = ({ className = "" }) => {
  return (
    <>
      <Faq>
        <DefaultAccordion
          question={"Comment se connecter ?"}
          answer={
            "Bienvenue sur Event to Bee ! Pour profiter pleinement de nos services, suivez ces étapes simples pour créer votre compte ou bien se connecter "
          }
        >
          <ol className="ol">
            <li>
              <span>
                {" "}
                Création de compte Si vous êtes nouveau sur Event to bee, vous devrez créer un compte pour profiter
                pleinement de nos services. <b> Suivez ces étapes simples:</b>
              </span>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Accédez à la page de connexion:&nbsp; </b> Cliquez sur le bouton "Compte" depuis la page
                    d'accueil pour accéder à la page de connexion.
                  </li>

                  <li>
                    <b> Choisissez votre type de compte: &nbsp; </b> Sélectionnez le type de compte qui correspond
                    à votre rôle sur la plateforme : Client, Prestataire, Propriétaire.
                  </li>

                  <li>
                    {" "}
                    <b> Remplissez les champs obligatoires: &nbsp; </b> Suivez les étapes indiquées et complétez
                    les informations nécessaires.
                  </li>
                  <li>
                    {" "}
                    <b> Cliquez sur "S'inscrire":&nbsp; </b> Une fois que vous avez rempli les champs, cliquez sur
                    le bouton "S'inscrire" pour créer votre compte.{" "}
                  </li>

                  <li>
                    {" "}
                    <b>Vérifiez votre adresse e-mail: &nbsp; </b> Consultez votre boîte de réception, où vous
                    trouverez un e-mail de confirmation. Cliquez sur le lien fourni pour activer votre compte.{" "}
                  </li>
                </ul>
              </p>
            </li>

            <li>
              <span style={{ justifyContent: "start" }}>
                {" "}
                Connexion à votre compte existant Si vous avez déjà un compte,
                <b> suivez ces étapes pour vous connecter :</b>{" "}
              </span>
              <ul className="c" style={{ listStyleType: "square" }}>
                <p style={{ textAlign: "start", margin: "15px" }}>
                  <li>
                    <b> Accédez à la page de connexion : &nbsp; </b>Cliquez sur le bouton "Compte" depuis la page
                    d'accueil pour accéder à la page de connexion.
                  </li>
                  <li>
                    <b> Saisissez vos identifiants : &nbsp; </b> Entrez votre adresse e-mail et votre mot de passe
                    dans les champs appropriés.
                  </li>
                  <li>
                    <b> Cliquez sur "Se connecter" : &nbsp; </b> Une fois vos informations saisies, cliquez sur le
                    bouton "Se connecter" pour accéder à votre compte Event to Bee.
                  </li>
                  <li>
                    {" "}
                    Si vous avez oublié votre mot de passe, cliquez sur "Mot de passe oublié" pour réinitialiser
                    votre mot de passe.
                  </li>
                </p>
              </ul>
            </li>
          </ol>
        </DefaultAccordion>

        <DefaultAccordion
          question={"Comment reserver un espace ?"}
          answer={
            "Event to Bee rend la réservation d'espaces simple et intuitive. Suivez ces étapes pour organiser votre événement sans tracas :"
          }
        >
          <ol>
            <li>
              <span>
                <b> Connectez-vous à votre compte: &nbsp;</b> Avant de commencer, assurez-vous d'être connecté à
                votre compte Event to Bee. Si vous n'avez pas encore de compte, suivez les étapes pour créer un
                compte, comme indiqué dans notre guide "Création de compte et Connexion".
              </span>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Consultez notre page "Blog": &nbsp; </b>
                    Pour enrichir votre expérience et en apprendre davantage sur Event to Bee. Des astuces sur les
                    fonctionnalités avancées à l'inspiration pour la décoration de vos espaces et événements,
                    obtenez des conseils pour organiser des événements mémorables et découvrez les dernières
                    tendances et idées créatives. Le blog est conçu pour vous offrir une source complète
                    d'informations et d'inspiration afin de tirer le meilleur parti de votre expérience sur notre
                    plateforme.
                  </li>

                  <li>
                    <b> Recherchez l'espace idéal : &nbsp; </b> Explorez notre large gamme d'espaces disponibles en
                    utilisant les filtres tels que la région et l'evenement .
                  </li>

                  <li>
                    <b> Consultez les détails de l'espace : &nbsp; </b> Cliquez sur un espace qui correspond à vos
                    besoins pour obtenir des informations détaillées. Vérifiez la capacité, les équipements inclus,
                    les tarifs, et assurez-vous que l'espace répond à vos exigences.
                  </li>

                  <li>
                    <b> Partagez avec vos amis :&nbsp; </b> Partagez l'espace que vous avez sélectionné avec vos
                    amis en utilisant les options de partage disponibles sur la page de l'espace.
                  </li>

                  <li>
                    <b> Ajoutez à vos favoris : &nbsp; </b> Marquez l'espace en tant que favori pour le retrouver
                    facilement ultérieurement. Vous pouvez gérer vos favoris dans la section "favoris espaces"
                    dédiée à votre compte Event to Bee.
                  </li>

                  <li>
                    {" "}
                    <b>Vérifiez la disponibilité : &nbsp; </b> Sur la page de l'espace, consultez le calendrier de
                    disponibilité pour trouver une date et une heure qui vous conviennent. Certains espaces peuvent
                    avoir des créneaux horaires spécifiques disponibles.
                  </li>

                  <li>
                    {" "}
                    <b>Sélectionnez vos options : &nbsp; </b> Choisissez les options supplémentaires, le cas
                    échéant, comme la configuration de l'espace, les services supplémentaires, etc.
                  </li>
                  <li>
                    {" "}
                    <b>Demande de réservation : &nbsp; </b> Cliquez sur le bouton "Demande de réservation" . Vous
                    serez redirigé vers votre panier où vous pouvez passer en revue votre sélection.
                  </li>

                  <li>
                    {" "}
                    <b>Procédez au paiement : &nbsp; </b> Une fois que vous êtes prêt, cliquez sur "Payer" pour
                    passer à la page de paiement. Suivez les instructions pour finaliser la réservation en
                    fournissant les informations nécessaires.
                  </li>

                  <li>
                    {" "}
                    <b>Confirmation de réservation : &nbsp; </b>Une fois le paiement effectué, Vous pourrez
                    également vérifier vos réservations dans la section "Mes reservation" de votre compte Event to
                    Bee.
                  </li>
                </ul>
              </p>
            </li>
          </ol>
        </DefaultAccordion>

        <DefaultAccordion
          question={"Politique d'annulation et de remboursement"}
          answer={
            "Nous comprenons que parfois, des imprévus surviennent. Afin de vous offrir une politique d'annulation équitable, veuillez prendre connaissance des détails suivants :"
          }
        >
          <ol>
            <li>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Remboursement de 70 % de la valeur de la réservation : &nbsp; </b>
                    En cas d'annulation effectuée avant 5 jours de l'heure prévue d'arrivée, les clients peuvent
                    bénéficier d'un remboursement de 70 % de la valeur total de la réservation.
                  </li>

                  <li>
                    <b> Conditions d'annulation : &nbsp; </b>
                    Les clients doivent annuler leur réservation au minimum 5 jours avant l'heure prévue d'arrivée
                    pour être éligibles au remboursement. Après cette période, les annulations ne seront plus
                    acceptées, et aucun remboursement ne sera accordé.
                  </li>
                </ul>
              </p>
            </li>
          </ol>
        </DefaultAccordion>

        <DefaultAccordion question={"Dépôt de garantie (cautionnement) "}>
          <ol>
            <li>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b> Montant du dépôt : &nbsp; </b>
                    Un dépôt de garantie variable, spécifique à chaque espace, sera remis le jour de votre arrivée
                    pour assurer la protection de l'espace réservé.
                  </li>

                  <li>
                    <b> Remboursement du dépôt : &nbsp; </b>
                    Le remboursement du dépôt de garantie, s'il a été prélevé, sera effectué à la conclusion de
                    votre événement, sous réserve qu'aucun dommage ou violation des termes et conditions ne soit
                    constaté.
                  </li>
                </ul>
              </p>
            </li>
          </ol>
        </DefaultAccordion>

        <DefaultAccordion question={"Comment procéder à une annulation et au remboursement ? "}>
          <ol>
            <li>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b>Connectez-vous à votre compte : &nbsp; </b>
                    Accédez à votre compte sur Event to Bee en utilisant vos identifiants.
                  </li>

                  <li>
                    <b> Accédez à vos réservations : &nbsp; </b>
                    Trouvez la réservation que vous souhaitez annuler dans la section "Mes réservations".
                  </li>

                  <li>
                    <b> Sélectionnez "Annuler la réservation" : &nbsp; </b>
                    Choisissez l'option d'annulation et suivez les instructions pour confirmer l'annulation.
                  </li>

                  <li>
                    <b> Confirmation par email : &nbsp; </b>
                    Un email de confirmation sera envoyé au client pour valider l'annulation. Une fois que le
                    client aura confirmé l'annulation par email, la réservation sera officiellement annulée.{" "}
                  </li>

                  <li>
                    <b> Traitement du remboursement : &nbsp; </b>
                    Une fois l'annulation effectuée dans les délais requis, le remboursement de 70 % sera traité.
                  </li>
                </ul>
              </p>
            </li>
          </ol>
        </DefaultAccordion>

        <DefaultAccordion question={"Comment ajouter un avis ou un commentaire en tant que client ?"}>
          <ol>
            <li>
              <p style={{ textAlign: "justify", margin: "15px" }}>
                <ul className="c" style={{ listStyleType: "square" }}>
                  <li>
                    <b>Connectez-vous à votre compte : &nbsp; </b>
                    Accédez à votre compte sur Event to Bee en utilisant vos identifiants.
                  </li>

                  <li>
                    <b> Trouvez un espace ou une prestation : &nbsp; </b>
                    Accédez à la section "Espaces" ou "Prestations" pour rechercher et sélectionner l'espace ou la
                    prestation que vous souhaitez évaluer.
                  </li>

                  <li>
                    <b> Accédez à la section des avis : &nbsp; </b>
                    Une fois sur la page de l'espace ou de la prestation que vous souhaitez évaluer, recherchez la
                    section dédiée aux avis et commentaires.
                  </li>

                  <li>
                    <b> Écrivez votre avis : &nbsp; </b>
                    Partagez votre expérience en détaillant ce que vous avez apprécié et toute suggestion
                    d'amélioration. Soyez honnête et constructif.
                  </li>

                  <li>
                    <b> Attribuez une note : &nbsp; </b>
                    Selon les options disponibles, donnez une note ou une évaluation à l'espace ou à la prestation,
                    généralement sur une échelle de 1 à 5 étoiles.
                  </li>

                  <li>
                    <b> Soumettez votre avis : &nbsp; </b>
                    Une fois votre commentaire terminé et votre évaluation attribuée, cliquez sur "Soumettre" pour
                    partager votre avis avec la communauté.
                  </li>
                </ul>
              </p>
            </li>
          </ol>
        </DefaultAccordion>
      </Faq>
    </>
  );
};

export default clientFaq;
