import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { AuthContext } from "context/appContext";
import { ProfilClient, Reservation } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { fetchOwnerTransfertsGain } from "redux/features/reservations/reservationSlice";
import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";
import CommonLayout from "../AccountPage/CommonLayout";
import ReservationDetailsPage from "./reservationDetailsPage";
import StripeMenu from "./StripeMenu";

export interface ClientGainPageProps {
  className?: string;
}

const ClientGainPage: FC<ClientGainPageProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  let profile = authContext.user?.profil as ProfilClient;
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const reservations: Reservation[] = useAppSelector((state) => state.featureReservation.inProgress);
  const totalPages: Reservation[] = useAppSelector((state) => state.featureReservation.totalPages);
  const count: number = useAppSelector((state) => state.featureReservation.count);
  const totalAmount: number = useAppSelector((state) => state.featureReservation.totalAmount);
  const [searchTerm, setSearchTerm] = useState("");
  const [showReservationDetails, setshowReservationDetails] = useState(false);

  useEffect(() => {
    if (profile) {
      dispatch(fetchOwnerTransfertsGain({ pageNumber: page, owner_id: profile?.id }));
    }
  }, [page, dispatch]);

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };

  const [selectedReservation, setselectedReservation] = useState<any | null>(null);

  const handleEyeIconClick = (client: any) => {
    setselectedReservation(client);
    setshowReservationDetails(true);
  };

  return (
    <div className={`nc-AccountPage bg-neutral-50 ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <CommonLayout>
        <StripeMenu amount={totalAmount} title="Gain total">
          <div className="flex items-center justify-between mb-4">
            <div></div>
            <div className="relative flex">
              <Input
                type="text"
                placeholder="Rechercher par code"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-500 transform -translate-y-1/2 right-3 top-1/2" />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-sm font-light text-center">
                    <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                      <tr>
                        <th scope="col" className="px-6 py-4 ">
                          Code
                        </th>

                        <th scope="col" className="px-6 py-4 ">
                          A Payer
                        </th>
                        <th scope="col" className="px-6 py-4 ">
                          Status
                        </th>

                        <th scope="col" className="px-6 py-4 ">
                          consulter
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservations &&
                        reservations

                          .filter((item) => item.reference.toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((item: any, index) => (
                            <tr className="border-b dark:border-neutral-500" key={index}>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">{item.reference}</td>
                              <td className="px-6 py-4 font-medium whitespace-nowrap">
                                {(
                                  parseFloat(item.halfTotal) +
                                  (parseFloat(item.cleaning_costs) || 0) +
                                  (parseFloat(item.extra) ?? 0)
                                ).toFixed(2)}{" "}
                                €
                              </td>

                              <td className="font-semibold text-secondary-6000">Virement réussi</td>

                              <td className="px-6 py-4 whitespace-nowrap">
                                <i
                                  className="text-2xl cursor-pointer las la-eye"
                                  onClick={() => handleEyeIconClick(item)}
                                ></i>{" "}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>

                  <div className="flex justify-end ">
                    <span className="text-sm text-gray-700">{count} élément</span>
                  </div>

                  {showReservationDetails && (
                    <ReservationDetailsPage
                      open={true}
                      handleClose={() => setshowReservationDetails(false)}
                      Data={selectedReservation}
                    />
                  )}

                  <div className="flex items-center justify-center mt-11">
                    {typeof totalPages === "number" && (
                      <Pagination currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StripeMenu>
      </CommonLayout>
    </div>
  );
};

export default ClientGainPage;
