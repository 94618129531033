import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Video } from "data/types";

import { RootState } from "redux/store";
import { videoApi } from "./videoAPI";

interface VideoState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  videoError: string;
  error: SerializedError | "";
  allVideos: Video[];
  videoData?: Video;
}

const initialState: VideoState = {
  loading: "idle",
  videoError: "",
  error: "",
  allVideos: [],
  videoData: undefined,
};

export const addVideo = createAsyncThunk("addVideo", async (data: any) => {
  return await videoApi.addVideo(data);
});

export const getAllVideos = createAsyncThunk("getAllVideos", async () => {
  return await videoApi.getAllVideos();
});

export const getVideoById = createAsyncThunk("getVideoById", async (id: string) => {
  return await videoApi.getVideoById(id);
});

export const deleteVideo = createAsyncThunk("deleteVideo", async (id: string) => {
  return await videoApi.deleteVideo(id);
});

export const updateVideo = createAsyncThunk("updateVideo", async ({ data, id }: { data: any; id: any }) => {
  return await videoApi.updateVideo(data, id);
});

export const videoSlice = createSlice({
  name: "Location",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    initStates(state) {
      state.loading = "idle";
      state.videoData = undefined;
      state.error = "";
      state.videoError = "";
      state.allVideos = [];
    },
  },
  extraReducers: (builder) => {
    // add video
    builder.addCase(addVideo.pending, (state, action) => {
      state.loading = "pending";
      state.videoError = "";
    });
    builder.addCase(addVideo.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.videoError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.videoError = action.payload.error;
      }
    });
    builder.addCase(addVideo.rejected, (state, action) => {
      state.loading = "failed";
    });

    // get all videos

    builder.addCase(getAllVideos.fulfilled, (state, action) => {
      state.allVideos = action.payload;
    });

    builder.addCase(getVideoById.fulfilled, (state, action) => {
      state.videoData = action.payload;
    });

    // update
    builder.addCase(updateVideo.pending, (state, action) => {
      state.loading = "pending";
      state.videoError = "";
    });
    builder.addCase(updateVideo.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.videoError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.videoError = action.payload.error;
      }
    });
    builder.addCase(updateVideo.rejected, (state, action) => {
      state.loading = "failed";
    });
  },
});

export const { initStates, setError } = videoSlice.actions;

export const selectCount = (state: RootState) => state.video;
export default videoSlice.reducer;
