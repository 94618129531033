import { Spinner } from "@material-tailwind/react";
import Heading2 from "components/Heading/Heading2";
import LoginModal from "components/LoginModal/LoginModal";
import StayCard from "components/StayCard/StayCard";
import { itemPerLocationsPage } from "data/constants";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { HiOutlineHome } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { fetchLocationsPrices } from "redux/features/location/locationSlice";
import {
  fetchLocationsFiltered,
  getAllValidateLocations,
  initStates,
} from "redux/features/locations/locationSlice";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Pagination from "shared/Pagination/Pagination";
import FiltersPage from "./FiltersPage";

export interface LocationsGridFilterCardProps {
  className?: string;
  data?: Location[];
  title?: string;
}

const LocationsGridFilterCard: FC<LocationsGridFilterCardProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  let locationState = useAppSelector((state) => state.featureLocation?.locations);

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = itemPerLocationsPage;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const event = searchParams.get("event");
  const region = searchParams.get("region");

  const pathname = location.pathname;
  const decodedPathname = decodeURIComponent(pathname);
  const afterEvent = decodedPathname.split("/event/")[1];

  useEffect(() => {
    if (event || region) {
      setIsLoading(true);
      let filter = { ...filters, events: event, region: region };

      dispatch(fetchLocationsFiltered({ pageNumber: page, filters: filter })).then(() => setIsLoading(false));
    } else if (afterEvent) {
      setIsLoading(true);
      let filter = { events: afterEvent };

      dispatch(fetchLocationsFiltered({ pageNumber: page, filters: filter })).then(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      dispatch(getAllValidateLocations(page)).then(() => setIsLoading(false));
    }
  }, [page, dispatch]);

  const handleApplyFilters = (newFilters: any) => {
    const updatedFilters = { ...filters, ...newFilters };
    if (event || region) {
      updatedFilters.events = event;
      updatedFilters.region = region;
    }
    dispatch(fetchLocationsFiltered({ pageNumber: page, filters: updatedFilters }));
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handleApplyFiltersRegionEvent = (filters: any) => {
    let filter = {
      region: filters.region?.replace(/\s+/g, "-")?.toLowerCase(),
      events: filters.event?.replace(/\s+/g, "-")?.toLowerCase(),
    };

    dispatch(fetchLocationsFiltered({ pageNumber: page, filters: filter }));
  };

  useEffect(() => {
    dispatch(fetchLocationsPrices());
  }, []);

  let locationMinPrices = useAppSelector((state) => state.location?.AllLocationMinPrices);

  const totalPages = Math.ceil(locationState.length / itemsPerPage);
  const [showLoginModal, setShowLoginModal] = useState(false);

  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
      {showLoginModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="flex flex-col items-center p-6 bg-white rounded-lg dark:bg-neutral-900">
              <div className="self-end mb-4">
                <ButtonSecondary onClick={() => setShowLoginModal(false)}>X</ButtonSecondary>
              </div>
              <LoginModal LoginModal={(e) => setShowLoginModal(e)} />
            </div>
          </div>
        </div>
      )}
      <Heading2
        heading={
          <div className="flex items-center">
            <HiOutlineHome className="w-5 h-5 mr-2 text-gray-500 lg:w-5 lg:h-5" />
            <span>Espaces</span>
          </div>
        }
      />

      <FiltersPage onApplyFilters={handleApplyFilters} onApplyFiltersRegionEvent={handleApplyFiltersRegionEvent} />
      <br />

      {isLoading ? (
        locationState.length === 0 ? (
          <div className="flex items-center justify-center" style={{ height: "300px" }}>
            <p className="font-semibold text">Aucun espace disponible</p>
          </div>
        ) : (
          <div className="flex items-center justify-center mt-16">
            <p>
              <Spinner color="blue" className="w-12 h-12" />
            </p>
          </div>
        )
      ) : locationState.length === 0 ? (
        <p className="font-semibold text">Aucun espace disponible</p>
      ) : (
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {Array.isArray(locationState?.locations) &&
            locationState?.locations.map((stay: any, index: any) => {
              let minPrice;
              let priceCount;
              if (Array.isArray(locationMinPrices) && locationMinPrices.length > 0) {
                const priceData = locationMinPrices.find((price) => price.locationId === stay.id);
                if (priceData) {
                  minPrice = priceData.minPrice;
                  priceCount = priceData.priceCount;
                }
              }

              return (
                <div key={index}>
                  <StayCard
                    data={stay}
                    galleryImgs={stay.images}
                    showLoginModal={showLoginModal}
                    setShowLoginModal={setShowLoginModal}
                    minPrice={minPrice}
                    priceCount={priceCount}
                  />
                </div>
              );
            })}
        </div>
      )}
      <div className="flex items-center justify-center mt-16">
        {typeof totalPages === "number" && (
          <Pagination currentPage={page} totalPages={locationState?.totalPages} onPageChange={handlePageChange} />
        )}
      </div>
    </div>
  );
};

export default LocationsGridFilterCard;
