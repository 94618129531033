import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import convertDateToString from "utils/converDateToString";

interface CommentListingDataType {
  name: string;
  avatar?: string;
  date: string;
  comment: string;
  starPoint: number;
  text: string;
  Client: any;
  locationId: number;
  like: number;
  createdAt: string;
}

export interface CommentListingProps {
  className?: string;
  data?: CommentListingDataType;
  hasListingTitle?: boolean;
}

const DEMO_DATA: CommentListingDataType = {
  name: "Cody Fisher",
  date: "May 20, 2021",
  comment: "",
  starPoint: 5,
  text: "",
  Client: undefined,
  locationId: 0,
  like: 0,
  createdAt: "",
};

const CommentListing: FC<CommentListingProps> = ({ className = "", data = DEMO_DATA, hasListingTitle }) => {
  // Function to generate star icons based on the 'like' property
  const renderStars = (like: number) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(<StarIcon key={i} className={`w-4 h-4 ${i <= like ? "text-yellow-500" : "text-gray-300"}`} />);
    }
    return stars;
  };
  const createdAtDate = new Date(data.createdAt);

  return (
    <div className={`nc-CommentListing flex space-x-4 ${className}`} data-nc-id="CommentListing">
      <div className="pt-0.5">
        <Avatar
          userName={data.Client?.lastName}
          sizeClass="h-10 w-10 text-lg"
          radius="rounded-full"
          imgUrl={data.Client?.client_url ? process.env.REACT_APP_CLOUD_FRONT + data.Client?.client_url : ""}
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between space-x-3">
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              <span>{data.Client?.lastName}</span>
              {hasListingTitle && (
                <>
                  <span className="font-normal text-neutral-500 dark:text-neutral-400">{` review in `}</span>
                  <a href="/">The Lounge & Bar</a>
                </>
              )}
            </div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
              {convertDateToString(createdAtDate)}
            </span>
          </div>
          <div className="flex text-yellow-500">{renderStars(data.like)}</div>
        </div>
        <span className="block mt-3 text-neutral-6000 dark:text-neutral-300">{data.text}</span>
      </div>
    </div>
  );
};

export default CommentListing;
