"use client";

import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import RealEstateSearchForm from "containers/Locations/AllLocationsPage/RealEstateSearchForm";
import RealEstateSearchFormService from "containers/Services/TabFilters";

export type SearchTab = "Espaces" | "Prestataires";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Espaces" | "Prestataires";
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({ className = "", currentTab = "Espaces", currentPage }) => {
  const navigate = useNavigate();
  const [availability, setAvailability] = useState("");
  const [field, setField] = useState("");

  const [event, setEvent] = useState("");
  const [region, setRegion] = useState("");
  const [presRegion, setPresRegion] = useState("");

  const tabs: SearchTab[] = ["Espaces", "Prestataires"];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const handleField = (data: string) => {
    setField(data);
  };

  const renderTab = () => {
    return (
      <ul className="flex overflow-x-auto ml-2 space-x-5 text-white sm:ml-6 md:ml-12 sm:space-x-8 lg:space-x-11 hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => setTabActive(tab)}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium text-white ${
                active ? "" : "text-neutral-500 hover:text-neutral-700 "
              }`}
              key={tab}
            >
              {active && <span className="block w-2.5 h-2.5 rounded-full  dark:bg-neutral-100 mr-2 bg-white" />}
              <span>{tab}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    const handleSelectionFromChild = (field: string, value: string) => {
      if (field === "events") {
        setEvent(value);
      } else if (field === "region") {
        setRegion(value);
      }
    };

    const handleApplyFields = (selectedField: any) => {};

    const handleApplyRegion = (selectedRegion: any) => {
      setAvailability(selectedRegion);
    };

    switch (tabActive) {
      case "Espaces":
        return (
          <>
            <br />
            <br />
            <RealEstateSearchForm
              onSelectFromRegionEvent={handleSelectionFromChild}
              searchClicked={() => {}}
              handleSearchClick={() => {
                navigate(
                  !!event && !!region
                    ? `${
                        "/locations" +
                        "?event=" +
                        event?.replace(/\s+/g, "-").toLowerCase() +
                        "&region=" +
                        "" +
                        region.replace(/\s+/g, "-").toLowerCase()
                      }`
                    : !!event
                      ? `${"/locations" + "?event=" + event.replace(/\s+/g, "-").toLowerCase()}`
                      : !!region
                        ? `${"/locations" + "?region=" + region.replace(/\s+/g, "-").toLowerCase()}`
                        : ""
                );
              }}
              handleDelete={() => {}}
            />
          </>
        );
      case "Prestataires":
        return (
          <>
            <br />
            <br />
            <RealEstateSearchFormService
              onSelectField={handleField}
              onApply={handleApplyFields}
              handleEventSelection={(data: any) => {}}
              handleSearchClick={() => {
                navigate(
                  !!field && !!region
                    ? `${
                        "/prestations" +
                        "?field=" +
                        field.replace(/\s+/g, "-").toLowerCase() +
                        "&region=" +
                        "" +
                        region.replace(/\s+/g, "-").toLowerCase()
                      }`
                    : !!field
                      ? `${"/prestations" + "?field=" + field.replace(/\s+/g, "-").toLowerCase()}`
                      : !!region
                        ? `${"/prestations" + "?region=" + region.replace(/\s+/g, "-").toLowerCase()}`
                        : ""
                );
              }}
              handleDelete={() => {}}
              onSelectFromRegionEvent={(value: any) => setRegion(value)}
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={`py-5 w-full max-w-6xl nc-HeroSearchForm lg:py-0 ${className}`}>
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
