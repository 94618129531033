import { Dialog, DialogBody, DialogFooter, DialogHeader, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import React from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface DeleteModalProps {
  open: boolean;
  handleClose?: () => void;
  Data: any;
}
const ReservationDetailsOwnerPage: React.FC<DeleteModalProps> = ({ open, handleClose, Data }) => {
  const [openx, setOpen] = React.useState(false);
  const formatDate = (dateString: any) => {
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Dialog open={open} handler={handleOpen} className="w-11/12 ">
        <DialogHeader>
          <Typography variant="h5" color="blue-gray" className="text-center">
            Information détaillée pour la réservation
          </Typography>
        </DialogHeader>
        <DialogBody divider className="grid gap-4 place-items-center">
          <Typography className="font-normal text-center">
            <div>
              <Label className="font-semibold">Code :</Label>
              <span> {Data?.reference} </span>
            </div>

            <div>
              <Label className="font-semibold">Espace :</Label>
              <span>{Data?.Location.title}</span>
            </div>
            <div>
              <Label className="font-semibold">Client:</Label>
              <span>
                {" "}
                {Data?.Client?.name} {Data?.Client?.lastName}
              </span>
            </div>

            <div>
              <Label className="font-semibold">Jour :</Label>
              <span> {formatDate(Data.selectedDate)} </span>
            </div>

            <div>
              <Label className="font-semibold">Temps :</Label>
              <span>
                {" "}
                {Data.startHour}-{Data.endHour}
              </span>
            </div>

            <div>
              <Label className="font-semibold">Montant pour vous :</Label>
              <span> {parseFloat(Data.halfTotal) + parseFloat(Data.extra)} €</span>
            </div>
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <ButtonSecondary onClick={handleClose} className="dark:bg-white dark:text-black">
            Quitter
          </ButtonSecondary>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ReservationDetailsOwnerPage;
