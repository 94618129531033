import { FC, useContext, useEffect, useState } from "react";
import Heading2 from "components/Heading/Heading2";
import ClientReservationDetailedCard from "components/ClientReservationCard/ClientReservationDetailedCard";
import Pagination from "shared/Pagination/Pagination";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { fetchReservationByClient } from "redux/features/reservations/reservationSlice";
import { AuthContext } from "context/appContext";
import { Spinner } from "@material-tailwind/react";
import { itemPerDashboradClient } from "data/constants";
export interface SectionGridFilterCardProps {
  className?: string;
}

const ReservationGridDetailedCard: FC<SectionGridFilterCardProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  const [loading, setIsLoading] = useState(false);
  const profilId = authContext?.user?.profil?.id;
  const dispatch = useAppDispatch();
  let reservationState = useAppSelector((state) => state.featureReservation.reservations);
  useEffect(() => {
    if (profilId) {
      setIsLoading(true);
      dispatch(fetchReservationByClient(profilId)).then(() => setIsLoading(false));
    }
  }, [profilId]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashboradClient;

  const paginatedReservation = reservationState?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(reservationState?.length / itemsPerPage);

  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
      <Heading2
        heading="Mes réservations"
        subHeading={
          <span className="block mt-3 text-neutral-500 dark:text-white">
            {reservationState?.length} réservations
          </span>
        }
      />

      <div className="grid grid-cols-1 gap-6 lg:bg-neutral-50 lg:dark:bg-black/20 rounded-3xl">
        {paginatedReservation.map((item, index) => (
          <ClientReservationDetailedCard defaultOpen={!index} key={index} data={item} />
        ))}
        {loading && (
          <div className="flex items-center justify-center mt-16">
            <p>
              <Spinner color="blue" className="w-12 h-12" />
            </p>
          </div>
        )}

        <div className="flex items-center justify-center mt-16">
          {typeof totalPages === "number" && (
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          )}
        </div>

        {reservationState?.length === 0 && (
          <div className="flex items-center justify-center mt-16">
            <p>Pas de reservations disponibles</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReservationGridDetailedCard;
