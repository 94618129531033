import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";

import img1 from "../../images/_space.png";
import img2 from "../../images/animation-calendar.gif";
import img3 from "../../images/_celebration.png";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: img1,
    title: "Trouver votre espace",
    desc: "Parcourez la liste des espaces disponibles et adaptez votre recherche en fonction de vos besoins ",
  },
  {
    id: 2,
    img: img2,
    title: "Réserver",
    desc: "Réservez l'espace et payez en toute sécurité",
  },
  {
    id: 3,
    img: img3,
    title: "Célébrer",
    desc: "Créez une expérience exceptionnelle dans un espace exceptionnel, et profitez-en",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({ className = "", data = DEMO_DATA }) => {
  return (
    <div className={`nc-SectionHowItWork  ${className}`} data-nc-id="SectionHowItWork">
      <Heading isCenter>Comment ça marche ?</Heading>
      <div className="relative grid gap-20 mt-20 md:grid-cols-3 md:gap-x-12">
        <img className="absolute inset-x-0 hidden top-10 md:block" src={VectorImg} alt="" />
        {data.map((item) => (
          <div key={item.id} className="relative flex flex-col items-center max-w-xs mx-auto text-center">
            <div className="mb-4">
              <NcImage containerClassName="mb-4 max-w-[200px] mx-auto" src={item.img} />
            </div>
            <div>
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">{item.desc}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
