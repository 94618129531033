import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { authService } from "./authAPI";
import { AdminStats, ProfilClient, Statistique, User } from "data/types";

interface AuthState {
  user?: User;
  creator?: User;
  error?: SerializedError;
  status?: string;
  resetPasswordError?: string;
  allClients?: User;
  allOwners?: User;
  allServiceAgents?: User;
  allCreators?: User;
  userStats?: Statistique;
  loading?: "idle" | "pending" | "succeeded" | "failed";
  owner?: ProfilClient;
  searchUser?: User;
  users?: User[];
  stats?: AdminStats;
}

const initialState: AuthState = {};
export const currentUser = createAsyncThunk("current", async () => {
  return await authService.currentUser();
});

export const getUser = createAsyncThunk("getUser", async (id: any) => {
  return await authService.getUserById(id);
});

export const getStats = createAsyncThunk("getStats", async () => {
  return await authService.getStats();
});

export const getAllUsers = createAsyncThunk("getAllUsers", async () => {
  return await authService.getAllUsers();
});

export const searchUser = createAsyncThunk("searchUser", async (data: any) => {
  return await authService.searchUser(data);
});
export const editUser = createAsyncThunk("editUser", async (data: any) => {
  return await authService.updateCreator(data);
});

export const resetPassword = createAsyncThunk("resetPassword", async (data: any) => {
  return await authService.resetPassword(data);
});

export const getAllClients = createAsyncThunk("getAllClients", async () => {
  return await authService.getAllClients();
});

// Statistques
export const getAllClientsStats = createAsyncThunk("getAllClientsStats", async () => {
  return await authService.getAllClientsStats();
});

export const getAllServiceAgentStats = createAsyncThunk("getAllServiceAgentStats", async () => {
  return await authService.getAllServiceAgentStats();
});

export const getAllOwners = createAsyncThunk("getAllOwners", async () => {
  return await authService.getAllOwners();
});

export const getOwners = createAsyncThunk("getOwners", async () => {
  return await authService.getOwners();
});

export const getAllOwnerStats = createAsyncThunk("getAllOwnerStats", async () => {
  return await authService.getAllOwnerStats();
});

export const getAllServiceAgents = createAsyncThunk("getAllServiceAgents", async () => {
  return await authService.getAllServiceAgents();
});

export const getAllCreators = createAsyncThunk("getAllCreators", async () => {
  return await authService.getAllCreators();
});

export const deleteUser = createAsyncThunk("deleteUser", async (id: number) => {
  return await authService.deleteUser(id);
});

export const validateClient = createAsyncThunk("validateClient", async ({ data, id }: { data: any; id: any }) => {
  return await authService.validateClient(data, id);
});

export const clientValidation = createAsyncThunk(
  "clientValidation",
  async ({ data, id }: { data: any; id: any }) => {
    return await authService.clientValidation(data, id);
  }
);

export const getOwnerById = createAsyncThunk("getOwnerById", async (id: any) => {
  return await authService.getOwnerById(id);
});

export const sendVerificationEmail = createAsyncThunk("sendVerificationEmail", async (email: any) => {
  return await authService.sendVerificationEmail(email);
});

export const chekVerificationOTP = createAsyncThunk(
  "chekVerificationOTP",
  async ({ email, otp }: { email: any; otp: any }) => {
    return await authService.chekVerificationOTP(email, otp);
  }
);

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    logOut(state) {
      localStorage.removeItem("token");
      state.user = undefined;
    },
    resetLoading(state) {
      state.loading = "idle";
    },
  },
  extraReducers: (builder) => {
    // current
    builder
      .addCase(currentUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.user = action.payload;
        } else {
          state.user = undefined;
        }
      })
      .addCase(currentUser.rejected, (state, action) => {
        state.error = action.error;
      });

    // get user by id
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.creator = action.payload;
      })
      .addCase(getOwnerById.fulfilled, (state, action) => {
        state.owner = action.payload;
      });

    // get all users
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });

    // edit user
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.creator = action.payload;
    });

    //search user
    builder
      .addCase(searchUser.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(searchUser.rejected, (state, action) => {
        state.loading = "failed";
      })
      .addCase(searchUser.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.searchUser = action.payload;
      });

    builder
      .addCase(resetPassword.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        if (action.payload.status === "OK") {
          state.loading = "succeeded";
        }

        if (action.payload.status === "ERROR") {
          state.loading = "failed";
          state.error = action.payload.error;
        }
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = "failed";
      });

    // get all clients
    builder.addCase(getAllClients.fulfilled, (state, action) => {
      state.allClients = action.payload;
    });

    // client stats per month
    builder.addCase(getAllClientsStats.fulfilled, (state, action) => {
      state.userStats = action.payload;
    });

    // get all owners
    builder.addCase(getAllOwners.fulfilled, (state, action) => {
      state.allOwners = action.payload;
    });

    builder.addCase(getOwners.fulfilled, (state, action) => {
      state.allOwners = action.payload;
    });

    builder.addCase(getAllOwnerStats.fulfilled, (state, action) => {
      state.userStats = action.payload;
    });

    // get all service agents
    builder.addCase(getAllServiceAgents.fulfilled, (state, action) => {
      state.allServiceAgents = action.payload;
    });

    builder.addCase(getAllServiceAgentStats.fulfilled, (state, action) => {
      state.userStats = action.payload;
    });

    // get all creators
    builder.addCase(getAllCreators.fulfilled, (state, action) => {
      state.allCreators = action.payload;
    });

    // validate client
    builder
      .addCase(validateClient.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(validateClient.fulfilled, (state, action) => {
        if (action.payload.status === "OK") {
          state.loading = "succeeded";
        }
        if (action.payload.status === "VALIDATION") {
          state.loading = "failed";
          state.error = action.payload.error[0].msg ?? "failed";
        }
        if (action.payload.status === "ERROR") {
          state.loading = "failed";
          state.error = action.payload.error;
        }
      })
      .addCase(validateClient.rejected, (state, action) => {
        state.loading = "failed";
      });

    // send Verification Email
    builder
      .addCase(sendVerificationEmail.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(sendVerificationEmail.fulfilled, (state, action) => {
        if (action.payload.status === "OK") {
          state.loading = "succeeded";
        }
        if (action.payload.status === "VALIDATION") {
          state.loading = "failed";
          state.error = action.payload.error[0].msg ?? "failed";
        }
        if (action.payload.status === "ERROR") {
          state.loading = "failed";
          state.error = action.payload.error;
        }
      })
      .addCase(sendVerificationEmail.rejected, (state, action) => {
        state.loading = "failed";
      });

    // chek Verification OTP
    builder
      .addCase(chekVerificationOTP.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(chekVerificationOTP.fulfilled, (state, action) => {
        if (action.payload.status === "OK") {
          state.loading = "succeeded";
        }
        if (action.payload.status === "VALIDATION") {
          state.loading = "failed";
          state.error = action.payload.error[0].msg ?? "failed";
        }
        if (action.payload.status === "ERROR") {
          state.loading = "failed";
          state.error = action.payload.error;
        }
      })
      .addCase(chekVerificationOTP.rejected, (state, action) => {
        state.loading = "failed";
      });

    // get stats
    builder.addCase(getStats.fulfilled, (state, action) => {
      state.stats = action.payload;
    });
  },
});

export const { logOut, resetLoading } = authSlice.actions;

export const selectCount = (state: RootState) => state.auth;

export default authSlice.reducer;
