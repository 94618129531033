import { Helmet } from "react-helmet";
import OwnerMenu from "../AccountPage/OwnerMenu";
import { FC } from "react";
import SectionGridDetailedCard from "./SectionGridDetailedCard";
export interface AccountPageProps {
  className?: string;
}

const OwnerReservationsPage: FC<AccountPageProps> = ({ className = "" }) => {
  return (
    <OwnerMenu>
      <div
        className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
        data-nc-id="ListingFlightsPage"
      >
        <Helmet>
          <title>Event To Bee</title>
        </Helmet>

        <div className="container relative">
          <SectionGridDetailedCard className="pb-24 lg:pb-28" />
        </div>
      </div>
    </OwnerMenu>
  );
};

export default OwnerReservationsPage;
