import { Alert, Card, CardBody, CardFooter, Input, Typography } from "@material-tailwind/react";
import { FC, useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { currentUser } from "redux/features/auth/authSlice";
import { initStates, login } from "redux/features/signIn/signInSlice";
import { AuthContext } from "context/appContext";

export interface LoginModalProps {
  LoginModal: (data: boolean) => void;
}

const formSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .regex(
      /((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).{8,26}$/,
      "Veuillez entrer un mot de passe valide."
    ),
});

const LoginModal: FC<LoginModalProps> = ({ LoginModal }) => {
  const authContext = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const signInState = useAppSelector((state) => state.signIn);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    setErrorAlert(false);
    dispatch(login(data));
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    switch (signInState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        dispatch(currentUser());
        break;
      }
      case "failed": {
        setIsLoading(false);
        setErrorAlert(true);
        break;
      }
    }
  }, [signInState.loading]);

  useEffect(() => {
    if (authContext.user && authContext.isAuthenticated) {
      LoginModal(false);
    }
  }, [authContext.user]);

  const load = async () => {
    await dispatch(initStates());
  };

  return (
    <>
      <Card className="mx-auto w-full max-w-[24rem] dark:bg-neutral-900 dark:text-gray-200 shadow-none">
        <CardBody className="flex flex-col gap-4 pt-4">
          <div className="max-w-md space-y-6">
            <Typography variant="h4" color="blue-gray" className="flex justify-center">
              Connectez-vous
            </Typography>

            <Typography className="mb-3 text-sm dark:text-gray-400" variant="paragraph" color="gray">
              Veuillez vous connecter en tant que client.
            </Typography>

            {errorAlert && (
              <Alert color="red" className="text-xs">
                {signInState.signInError}
              </Alert>
            )}

            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
              <label className="block">
                <Typography className="mb-2" variant="h6">
                  Adresse email
                </Typography>

                <Input
                  type="email"
                  placeholder="example@example.com"
                  className={`mt-1 ${errors.email?.message ? "border-red-500" : ""}`}
                  disabled={isLoading}
                  {...register("email")}
                />
                {errors.email?.message && <div className="text-xs text-red-500">{errors.email?.message}</div>}
              </label>

              <label className="block">
                <span className="flex items-center justify-between text-neutral-800 dark:text-neutral-200">
                  <Typography className="mb-2" variant="h6">
                    Mot de passe
                  </Typography>

                  <Link to="/forgot-password" className="text-sm text-blue-500 mb-1.5">
                    Mot de passe oublié ?
                  </Link>
                </span>
                <div className="relative ">
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="mot de passe"
                    className={`mt-1 ${errors.password?.message ? "border-red-500" : ""}`}
                    disabled={isLoading}
                    {...register("password")}
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {errors.password?.message && (
                  <div className="text-xs text-red-500">{errors.password?.message}</div>
                )}
              </label>

              <ButtonPrimary type="submit" disabled={isLoading} loading={isLoading}>
                Se connecter
              </ButtonPrimary>
            </form>
          </div>
        </CardBody>

        <CardFooter className="pt-0">
          <Typography variant="small" className="flex justify-center mt-2 dark:text-gray-400">
            Nouveau compte?
            <Typography
              as="a"
              href="/signup/client"
              variant="small"
              color="blue-gray"
              className="ml-1 font-bold dark:text-gray-200"
            >
              Créer un compte
            </Typography>
          </Typography>
        </CardFooter>
      </Card>
    </>
  );
};

export default LoginModal;
