import Glide from "@glidejs/glide";
import { zodResolver } from "@hookform/resolvers/zod";
import LoginModal from "components/LoginModal/LoginModal";
import { AuthContext } from "context/appContext";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import HTMLReactParser from "html-react-parser";
import HIW2img from "images/EVENT TO BEE-17.png";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaRegWindowClose } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import {
  addBlogComment,
  fetchBlogsById,
  fetchCommentsByBlog,
  fetchSimilarBlogs,
} from "redux/features/blog/blogSlice";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import Textarea from "shared/Textarea/Textarea";
import { z } from "zod";

const BlogSingle = () => {
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const [showLogin, setShowLogin] = useState(false);
  let blogsState = useAppSelector((state) => state.featureBlog.blogs);
  let blogState = useAppSelector((state) => state.featureBlog.blog);
  let commentsState = useAppSelector((state) => state.featureBlog.comments);
  useEffect(() => {
    if (id) {
      dispatch(fetchBlogsById(id));
      dispatch(fetchCommentsByBlog(id));
      dispatch(fetchSimilarBlogs(id));
    }
  }, [id]);

  const formattedDate =
    blogState?.createdAt &&
    new Intl.DateTimeFormat("fr-FR", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(new Date(blogState?.createdAt));

  const handleClearPost = () => {
    reset();
  };

  const formSchema = z.object({
    text: z.string().min(1, { message: "Commentaire est obligatoire." }),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    if (authContext?.user?.role !== "client") {
      setShowLogin(true);
      return;
    } else {
      await dispatch(
        addBlogComment({
          blogId: id,
          clientId: authContext?.user?.profil?.id,
          ...data,
        })
      );
      reset();
      if (id) {
        await dispatch(fetchCommentsByBlog(id));
      }
    }
  };

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <div className="flex">
            {blogState?.tags &&
              blogState.tags.map((tag: string, index: any) => (
                <div key={index}>
                  <Badge href="##" color="purple" name={tag} />
                  {index < blogState.tags.length - 1 && <span className="mx-1"></span>}
                </div>
              ))}
          </div>
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {blogState?.title}
          </h1>
          <span className="block pb-1 text-base text-neutral-500 md:text-lg dark:text-neutral-400">
            {blogState?.description}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <div
            id="single-entry-content"
            style={{
              margin: "0 auto",
              maxWidth: "calc(100% - 2cm)",
              marginBottom: "2cm",
            }}
          >
            <span className="block pb-1 text-base text-neutral-500 md:text-lg dark:text-neutral-400">
              {blogState?.content ? <code>{HTMLReactParser(blogState?.content)}</code> : <p> </p>}
            </span>
          </div>
        </div>
      </header>
    );
  };
  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <div className="flex flex-wrap max-w-screen-md mx-auto">
          {blogState?.tags &&
            blogState.tags.map((tag: any) => (
              <a
                key={tag.trim()} // Add a unique key for each tag, trimming to remove extra spaces
                className="inline-block py-2 mb-2 mr-2 text-sm bg-white border rounded-lg nc-Tag text-neutral-600 dark:text-neutral-300 border-neutral-100 md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000"
                href="##"
              >
                {tag.trim()} {/* Trim to remove extra spaces */}
              </a>
            ))}
        </div>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="w-2/3 mx-auto ">
        <div className="flex nc-SingleAuthor ">
          {showLogin && (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="fixed inset-0 bg-black opacity-50"></div>

              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="flex flex-col items-center p-6 bg-white rounded-lg dark:bg-neutral-900">
                  <div className="self-end mb-4">
                    <FaRegWindowClose className="text-xl cursor-pointer" onClick={() => setShowLogin(false)} />
                  </div>
                  <LoginModal LoginModal={(e) => setShowLogin(e)} />
                </div>
              </div>
            </div>
          )}
          <Avatar imgUrl={HIW2img} sizeClass="w-11 h-11 md:w-24 md:h-24 " containerClassName="object-none" />
          <div className="flex flex-col max-w-lg ml-3 space-y-1 sm:ml-5">
            <span className="text-xs tracking-wider uppercase text-neutral-400">Publié Par</span>
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                <a href="#">{blogState?.publisher}</a>
              </h2>
              <span className="text-sm text-slate-500 ">{formattedDate}</span>
            </div>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              votre plateforme dédiée à la location d'espaces pour tous vos événements spéciaux tels que mariages,
              jeux, moments de détente, anniversaires et bien plus encore. Notre plateforme se distingue par sa
              simplicité d'utilisation et sa convivialité, garantissant une expérience fluide pour tous les
              utilisateurs.
              <a className="ml-1 font-medium text-primary-6000" href="/">
                En savoir plus
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="w-2/3 pt-5 mx-auto">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Commentaires ({commentsState?.length})
        </h3>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-5 nc-SingleCommentForm">
          <Textarea placeholder="Partage tes pensées ..." {...register("text")} />
          {errors.text?.message && <div className="text-xs text-red-500 ">{errors.text?.message}</div>}
          <div className="mt-2 space-x-3">
            <ButtonPrimary type="submit">Approuver</ButtonPrimary>
            <ButtonSecondary type="button" onClick={handleClearPost}>
              Annuler
            </ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <br />
        <ul className="space-y-5 nc-SingleCommentLists">
          {commentsState &&
            commentsState.map((comment: any, index: number) => <Comment key={comment.id} data={comment} />)}
        </ul>
      </div>
    );
  };
  const glideRef = useRef(null);
  useEffect(() => {
    if (blogsState && blogsState?.length > 3 && glideRef.current) {
      const glide = new Glide(glideRef.current, {
        type: "carousel",
        perView: 4,
        autoplay: 1500,
      });

      glide?.mount();
      return () => glide?.destroy();
    }
  }, [blogsState]);

  const renderPostRelated = (Relatedblogs: any) => {
    return (
      <div key={Relatedblogs.id} className="relative overflow-hidden aspect-w-16 aspect-h-2 rounded-3xl group">
        <Link to={`/blog-single/${Relatedblogs.id}`} />
        <NcImage
          className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-105"
          src={process.env.REACT_APP_CLOUD_FRONT + Relatedblogs.blog_url}
        />
        <div>
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name={Relatedblogs?.tags[0]} color="purple" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{Relatedblogs?.title}</span>
          </h2>
          <div className="flex">
            <span className="block font-medium truncate text-neutral-200 hover:text-white">
              {Relatedblogs?.publisher}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">
              {" "}
              {format(new Date(Relatedblogs.createdAt), "MMMM d, yyyy")}
            </span>
          </div>
        </div>
        <Link to={`/blog-single/${Relatedblogs.id}`} />
      </div>
    );
  };
  return (
    <>
      <div className="h-auto pt-8 nc-PageSingle lg:pt-16 lg:h-auto ">
        <Helmet>
          <title>{`Event To Bee - ${blogState?.title}`}</title>
          <meta name="description" content={`${blogState?.meta_description}`} />
          <meta name="author" content={`${blogState?.publisher}`} />
          <meta name="keywords" content={blogState && blogState.tags && blogState.tags.join(",")} />
          <meta property="og:title" content={`${blogState?.meta_title}`} />
          <meta property="og:description" content={`${blogState?.meta_description}`} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={process.env.REACT_APP_CLOUD_FRONT + blogState.blog_url} />
          <meta property="og:url" content={process.env.REACT_APP_CLOUD_FRONT + blogState.blog_url} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${blogState?.meta_title}`} />
          <meta name="twitter:description" content={`${blogState?.meta_description}`} />
          <meta name="twitter:image" content="URL_TO_YOUR_IMAGE" />
        </Helmet>
        {renderHeader()}
        <div className="flex justify-center mx-auto">
          <NcImage
            className="w-2/3 rounded-xl"
            containerClassName="container my-10 sm:my-12 flex justify-center	"
            src={process.env.REACT_APP_CLOUD_FRONT + blogState?.blog_url}
          />
        </div>

        {renderContent()}
        <div className="clear-both"></div>
        <br />
        <div className="max-w-screen-md mx-auto mb-3 border-t border-b border-neutral-100 dark:border-neutral-700"></div>
        {renderTags()}

        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}
        {blogsState && blogsState.length > 3 && (
          <div className="relative py-16 mt-16 bg-neutral-100 dark:bg-neutral-800 lg:py-28 lg:mt-24">
            <div className="container glide" ref={glideRef}>
              <h2 className="text-3xl font-semibold text-center">Blog similaire</h2>
              <div
                className="grid gap-6 mt-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8 glide__track"
                data-glide-el="track"
              >
                <div className="glide__slides">
                  {blogsState &&
                    blogsState.length > 0 &&
                    blogsState.filter((_, i) => i < 4).map((relatedBlog) => renderPostRelated(relatedBlog))}
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        )}
        <br />
      </div>
    </>
  );
};

export default BlogSingle;
