import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commentLocationApi } from "./comment-locationAPI";

interface Comment {
  id: number;
  text: string;
  like: number | null;
  createdAt?: string;
  updatedAt?: string;
  locationId: number;
  clientId: number | null;
  Client: any; // Define the Client interface if needed
  comments: [];
}

const initialState: Comment = {
  id: 0,
  text: "",
  like: 0,
  locationId: 0,
  clientId: 0,
  Client: "",
  comments: [],
};

export const fetchComments = createAsyncThunk("featureComment/fetchComment", async () => {
  return await commentLocationApi.getTopOwners();
});

const createCommentSlice = createSlice({
  name: "featureComment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    });
  },
});

export const {} = createCommentSlice.actions;
export default createCommentSlice.reducer;
