import ButtonPrimary from "shared/Button/ButtonPrimary";
import ModalReserveMobile from "./ModalReserveMobile";
import { Link } from "react-router-dom";
import ModalSelectDate from "components/ModalSelectDate";
import { useState } from "react";
import converSelectedDateToString from "utils/converSelectedDateToString";

const MobileFooterSticky = ({ price, minPrice }: { price: number; minPrice: number }) => {
  const handleReserveClick = () => {
    const sidebarSection = document.getElementById("renderSidebar");
    if (sidebarSection) {
      sidebarSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="fixed inset-x-0 bottom-0 z-40 block py-2 bg-white border-t lg:hidden sm:py-3 dark:bg-neutral-800 border-neutral-200 dark:border-neutral-6000">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
            {price ? null : minPrice} €
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">/heure</span>
          </span>
        </div>
        <ButtonPrimary sizeClass="px-5 sm:px-7 py-3 !rounded-2xl" onClick={handleReserveClick}>
          Réserver
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default MobileFooterSticky;
