import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import DashboardMenu from "../DashboardPage/DashboardMenu";
import { DataSchemaType, schema } from "data/validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardFooter, CardHeader, Typography } from "@material-tailwind/react";
import { Alert } from "shared/Alert/Alert";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import {
  addRule,
  fetchRules,
  resetDesignation,
  resetValues,
  setDesignation,
} from "redux/features/Admin/rules/rulesSlice";
import { useAppSelector } from "hooks/hooks";

export interface AddRulePage {
  className?: string;
}
export interface Rule {
  designation: string;
  id: number;
}

const AddRulePage: FC<AddRulePage> = ({ className = "" }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataSchemaType>({ resolver: zodResolver(schema) });

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const [showAlert, setShowAlert] = React.useState(false);
  const [showExistAlert, setShowExistAlert] = React.useState(false);
  const navigate = useNavigate();
  const rulesState = useAppSelector((state) => state.featureRules);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const designation = useSelector((state: RootState) => state.featureRules.designation);

  const fetchedRules: Rule[] = useSelector((state: RootState) => state.featureRules.rules);
  const handleSetCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDesignation = String(event.target.value);
    dispatch(setDesignation(newDesignation));
  };

  useEffect(() => {
    dispatch(fetchRules());
  }, [dispatch]);

  const onSubmit = async (data: { designation: string }) => {
    const { designation } = data;

    const isCategoryExists = fetchedRules?.some((rule) => rule.designation === designation);

    if (isCategoryExists) {
      setShowExistAlert(true);
      setTimeout(() => {
        setShowExistAlert(false);
      }, 1000);
      return;
    }

    await dispatch(addRule({ designation }));
    dispatch(resetValues());
    navigate("/dashboard/rules");
  };

  useEffect(() => {
    switch (rulesState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [rulesState.loading]);
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="mx-auto w-full max-w-[24rem] dark:bg-gray-800">
            <CardHeader className="grid mb-4 h-28 place-items-center dark:bg-gray-800">
              {!showExistAlert && !showAlert && <span className="dark:text-white">Régle</span>}

              {showExistAlert && (
                <Alert type="error" containerClassName="text-xs">
                  {designation} déjà existante !
                </Alert>
              )}

              {showAlert && (
                <Alert type="success" containerClassName="text-xs">
                  {designation} ajoutée avec succès !
                </Alert>
              )}
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
              <Label className="dark:text-white">Designation</Label>
              <Input
                {...register("designation")}
                name="designation"
                value={designation}
                onChange={handleSetCategory}
                placeholder="Régle"
              />
              {errors.designation && <div className="text-xs text-red-500 ">{errors.designation.message}</div>}
            </CardBody>
            <CardFooter className="pt-0">
              <Typography variant="small" className="flex justify-center mt-6">
                <ButtonPrimary type="submit" loading={isLoading}>
                  Ajouter
                </ButtonPrimary>
              </Typography>
            </CardFooter>
          </Card>
        </form>
      </DashboardMenu>
    </div>
  );
};

export default AddRulePage;
