export const isValidURL = (string: any) => {
  return string.includes("message/");
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator

  return !!urlPattern.test(string);
};

export const findValueInArray = (value: any, column: string, array: any) => {
  if (value && column && array) {
    return Array.isArray(array)
      ? array.find((option) => {
          return (
            option[column]?.replace(/\s+/g, "-")?.toLowerCase() === value?.replace(/\s+/g, "-")?.toLowerCase()
          );
        })
      : null;
  }
  return;
};

export const havePROTOCOL = (url: any) => {
  if (url) {
    return url.indexOf("https://") === 0;
  }
};

export const replaceDNS = (oldURL?: string, newDNS?: string) => {
  if (oldURL && newDNS) {
    if (!oldURL.startsWith("/") && !havePROTOCOL(oldURL) === true) {
      oldURL = "/" + oldURL;
    }

    if (haveDNS(oldURL)) {
      var url = new URL(oldURL);
      url.hostname = newDNS;
      oldURL = url.href;
    }

    if (!havePROTOCOL(oldURL)) {
      oldURL = "https://" + newDNS + oldURL;
    }
  }

  return oldURL;
};

export const haveDNS = (str: string) => {
  if (str) {
    var pat = /^(https?:\/\/)?(?:www\.)?([^\/]+)/;
    return pat.test(str);
  }

  return;
};

export const startWithSlash = (str: string) => {
  if (str) {
    var pat = /^[^\/]/;
    return pat.test(str);
  }
};
