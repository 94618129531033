import React, { Dispatch, Fragment, SetStateAction, createContext, useEffect, useState } from "react";

import { useAppSelector } from "hooks/hooks";
import { User } from "data/types";

interface AuthContextProps {
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
}

const initialAuthState: AuthContextProps = {
  user: {},
  setUser: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {},
};

export const AuthContext = createContext<AuthContextProps>(initialAuthState);

export default function AppProvider({ children }: { children: React.ReactNode }) {
  const authState = useAppSelector((state) => state.auth);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | undefined>({});

  useEffect(() => {
    if (authState.user) {
      setIsAuthenticated(true);
      setUser(authState.user);
    } else {
      setIsAuthenticated(false);
      setUser(undefined);
      localStorage.removeItem("token");
    }
  }, [authState.user]);

  return (
    <Fragment>
      <AuthContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated }}>
        {children}
      </AuthContext.Provider>
    </Fragment>
  );
}
