import React, { FC } from "react";
import { Link } from "react-router-dom";
import { faFacebookF, faInstagram, faLinkedinIn, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SocialProps {
  classOne?: any;
  classList?: any;
}

const SocialMedia: FC<SocialProps> = ({ classOne, classList }) => {
  return (
    <div className={classOne}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ul className={classList}>
              <li>
                <Link to="https://www.facebook.com/profile.php?id=61555527601681" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </Link>
              </li>
              <li>
                <Link to="https://www.tiktok.com/@eventtobee" target="_blank">
                  <FontAwesomeIcon icon={faTiktok} />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/eventtobee/" target="_blank">
                  <FontAwesomeIcon icon={faInstagram} />
                </Link>
              </li>
              <li>
                <Link to="https://www.youtube.com/channel/UCNaEePN03uQbmhZAEqmtDFg" target="_blank">
                  <FontAwesomeIcon icon={faYoutube} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
