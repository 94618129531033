import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import { PostDataType } from "data/types";
import { Link } from "react-router-dom";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";

export interface Card3Props {
  className?: string;
  post: any;
}

const Card3: FC<Card3Props> = ({ className = "h-full", post }) => {
  const { title, blog_url, description, tags, id, publisher } = post;

  // Add line breaks in the description after 50 characters
  const formattedDescription = description.match(/.{1,50}/g)?.join("\n");

  return (
    <div
      className={`nc-Card3 relative flex flex-col-reverse sm:flex-row sm:items-center rounded-[40px] group ${className}`}
      data-nc-id="Card3"
    >
      <div className="flex flex-col flex-grow">
        <div>
          <div style={{ display: "flex", gap: "8px" }}>
            {tags && tags.map((tag: any) => <CategoryBadgeList categories={tag} className="gray" />)}
          </div>

          <div>
            <br />
            <h2 className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}>
              <Link to={`/blog-single/${id}`} className="line-clamp-2" title={title}>
                {title}
              </Link>
            </h2>
            <div className="hidden sm:block sm:mt-2">
              <span className="text-neutral-500 dark:text-neutral-400 text-base line-clamp-1">
                {formattedDescription}
              </span>
            </div>
          </div>

          <PostCardMeta meta={{ ...post, publisher }} />
        </div>
      </div>

      <div className={`block flex-shrink-0 sm:w-56 sm:ml-6 rounded-3xl overflow-hidden mb-5 sm:mb-0`}>
        <Link to={`/blog-single/${id}`} className={`block w-full h-0 aspect-h-9 sm:aspect-h-16 aspect-w-16 `}>
          <NcImage
            containerClassName="absolute inset-0"
            src={process.env.REACT_APP_CLOUD_FRONT + blog_url}
            alt={title}
          />
        </Link>
      </div>
    </div>
  );
};

export default Card3;
