const baseURL = process.env.REACT_APP_SERVER_URL;

export const serviceApi = {
  newService: async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "equipments" && key !== "events" && key !== "images") {
        formData.append(key, data[key]);
      }
    }
    formData.append("equipments", JSON.stringify(data.equipments));
    formData.append("events", JSON.stringify(data.events));
    data.images.forEach((image: any, index: any) => {
      formData.append(`images`, image);
    });
    const result = await fetch(`${baseURL}/api/service/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },

  updateService: async (data: any, id: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (
        data.hasOwnProperty(key) &&
        key !== "equipments" &&
        key !== "events" &&
        key !== "oldImages" &&
        key !== "newImages"
      ) {
        formData.append(key, data[key]);
      }
    }
    formData.append("equipments", JSON.stringify(data.equipments));
    formData.append("events", JSON.stringify(data.events));
    formData.append("remove", JSON.stringify(data.remove));

    if (data.oldImages && data.oldImages.length > 0) {
      formData.append("oldImages", JSON.stringify(data.oldImages));
    }

    if (data.newImages && data.newImages.length > 0) {
      data.newImages.forEach((newImages: string | File) => {
        // Check if it's a file or a URL
        if (newImages instanceof File) {
          formData.append("newImages", newImages);
        } else {
          formData.append("newImages", newImages);
        }
      });
    }

    const result = await fetch(`${baseURL}/api/service/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });

    return result.json();
  },

  getFields: async () => {
    const result = await fetch(`${baseURL}/api/field`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  getServiceById: async (id: string) => {
    const result = await fetch(`${baseURL}/api/service/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  getPricingById: async (id: string) => {
    const result = await fetch(`${baseURL}/api/pricing/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  getServiceByUserId: async (id: number) => {
    const result = await fetch(`${baseURL}/api/service/user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  getCommentByServiceId: async (id: string) => {
    const result = await fetch(`${baseURL}/api/comment-agent/service/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  checkServiceFavoriByUserId: async (serviceId: string, userId: number) => {
    const result = await fetch(`${baseURL}/api/favori-agent/service/${serviceId}/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  getAllServices: async () => {
    const result = await fetch(`${baseURL}/api/service/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  getSimilareService: async (id: any) => {
    const result = await fetch(`${baseURL}/api/service/field/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return result.json();
  },

  filterAllServices: async (data: any) => {
    const result = await fetch(`${baseURL}/api/service/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return result.json();
  },

  addServiceToFavoris: async (serviceId: string, clientId: number) => {
    const result = await fetch(`${baseURL}/api/favori-agent/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify({
        serviceId: serviceId,
        clientId: clientId,
      }),
    });
    return result.json();
  },

  getFavorisByClientId: async (clientId: number) => {
    const result = await fetch(`${baseURL}/api/favori-agent/client/${clientId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return result.json();
  },

  deleteServiceFromFavoris: async (serviceId: string, userId: number) => {
    const result = await fetch(`${baseURL}/api/favori-agent/${serviceId}/${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify({
        serviceId: serviceId,
        userId: userId,
      }),
    });
    return result.json();
  },

  getAllFavoris: async () => {
    const result = await fetch(`${baseURL}/api/favori-agent/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  requestPricing: async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const result = await fetch(`${baseURL}/api/pricing/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },

  addAvis: async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const result = await fetch(`${baseURL}/api/comment-agent`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },

  deleteService: async (id: any) => {
    const response = await fetch(`${baseURL}/api/service/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },

  // Pricing
  getPricingRequestByAgentId: async (userId: any) => {
    const result = await fetch(`${baseURL}/api/pricing/agent/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return result.json();
  },

  getPricingRequest: async (id: any) => {
    const response = await fetch(`${baseURL}/api/pricing/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
};
