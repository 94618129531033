import { FC } from "react";

import "./LegalNotice.scss";
import { Helmet } from "react-helmet";

export interface LegalNoticeProps {
  className?: string;
}

const LegalNotice: FC<LegalNoticeProps> = ({ className = "pt-6 pb-6" }) => {
  return (
    <>
      <Helmet>
        <title>Event To Bee - Mentions légales</title>
        <meta
          name="description"
          content="Mentions légales du site Event To Bee. Informations sur la société Bigup-conseils, son siège social, son numéro RCS, son numéro de TVA intracommunautaire, son téléphone, son adresse e-mail, et son directeur de publication."
        />
      </Helmet>

      <div className="dark:bg-gray-800 ">
        <div className="container ">
          <div className="lg:flex row mb:flex ">
            <div className="w-full lg:w-1/2 lg:mt-52 ">
              <h4 className="my-8 flex items-center text-1xl leading-[40%] md:text-5xl md:leading-[80%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Mentions légales
              </h4>
              <p className="items-center justify-center pt-5 mb-2 font-thin text-center text-gray-900 dark:text-white">
                Ce site est édité par la société Bigup-conseils :
              </p>
            </div>
            <div className="w-full mb-32 lg:w-1/2 ">
              <div className="sm:mt-4 lg:pl-16 lg:pt-6 col-lg-6">
                <div className="">
                  <article>
                    <ul>
                      <li>Société par Actions Simplifiée au capital de 1000 €</li>
                      <li>Siège social : 7 rue de l'industrie 92400 courbevoie</li>
                      <li>RCS Nanterre B 984 189 571</li>
                      <li>N° TVA intracommunautaire : FR81984189571</li>
                      <li>Téléphone : 09 77 93 30 63 (Appel non surtaxé)</li>
                      <li>Contact : support@eventtobee.com</li>
                      <li>Directeur de la publication : Soukaina CHOUARI</li>
                      <li>Hébergement :</li>
                      <li>Amazon Web Services </li>
                      <li>Siège social : 2045 W. Grand Ave Ste B PMB 69109, Chicago IL 60612</li>
                      <li>
                        <a href="https://aws.amazon.com/fr/contact-us/" target="_blank" className="text-blue-500">
                          Amazon support
                        </a>
                      </li>
                    </ul>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalNotice;
