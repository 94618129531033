const baseURL = process.env.REACT_APP_SERVER_URL;

export const forgetPasswordService = {
  forgetPassword: async (email: string) => {
    const result = await fetch(`${baseURL}/api/auth/forget-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    return result.json();
  },

  checkTokenForgetPassword: async (token: any) => {
    const result = await fetch(`${baseURL}/api/auth/check-token-forget-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });

    return result.json();
  },

  resetPassword: async (token: any, newPassword: any) => {
    const result = await fetch(`${baseURL}/api/auth/new-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, newPassword }),
    });

    return result.json();
  },
};
