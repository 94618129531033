import React, { FC } from "react";

import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export interface TermsProps {
  className?: string;
}

const TABLE_ROWS = [
  {
    partenaire: "Twilio sendGrid",
    paysDesinataire: "Etats-Unis",
    traitement: "Service d'envoi de courrier électronique",
    garanties: `https://www.twilio.com/en-us/legal/privacy`,
  },
  {
    partenaire: "Amazon Web Services",
    paysDesinataire: "Etats-Unis",
    traitement: "Hébergement du site web",
    garanties: "https://aws.amazon.com/fr/privacy/?nc1=f_pr",
  },
  {
    partenaire: "Facebook",
    paysDesinataire: "Etats-Unis",
    traitement: "Gestion des avis des personnes sur des produits, services ou contenus.",
    garanties: "https://fr-fr.facebook.com/privacy/policy",
  },
  {
    partenaire: "Youtube",
    paysDesinataire: "Etats-Unis",
    traitement: "Gestion des avis des personnes sur des produits, services ou contenus.",
    garanties: "https://policies.google.com/privacy",
  },
  {
    partenaire: "Instagram",
    paysDesinataire: "Etats-Unis",
    traitement: "Gestion des avis des personnes sur des produits, services ou contenus.",
    garanties: "https://privacycenter.instagram.com/policy/",
  },
  {
    partenaire: "TikTok",
    paysDesinataire: "Etats-Unis, Malaisie, Singapour",
    traitement: "Gestion des avis des personnes sur des produits, services ou contenus.",
    garanties: "https://www.tiktok.com/legal/page/cea/privacy-policy/fr",
  },
];
const TABLE_HEAD = ["Partenaire", "Pays destinataire", "Traitement réalisé", "Garanties"];

const Terms: FC<TermsProps> = ({ className = "pt-6 pb-6" }) => {
  return (
    <>
      <Helmet>
        <title>Event To Bee - Conditions Générales</title>
        <meta
          name="description"
          content="Consultez les conditions générales d'utilisation du site Event To Bee pour connaître les règles et les droits liés à l'utilisation de nos services."
        />
      </Helmet>
      <div className="flex flex-col items-center justify-center pt-6 pb-6">
        <h2 className="dark:text-white my-2 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900  justify-center">
          Conditions Générales
        </h2>
        <h2 className="dark:text-white flex items-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900  justify-center">
          du site internet &nbsp;
          <a href="http://eventtobee.com" className="text-blue-600" target="_blank">
            www.eventtobee.com
          </a>
        </h2>
        <p className="dark:text-white flex items-center  leading-[115%] md:leading-[115%] font-semibold text-neutral-900  justify-center">
          applicables à compter du 19/03/2024
        </p>

        <div className="rounded-xl">
          <div className="flex flex-col items-center justify-center pt-5 text-center">
            <div className="flex items-stretch max-w-4xl p-6 mb-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div className="flex flex-col justify-between">
                <div>
                  <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">ARTICLE 1. PARTIES</p>
                  <p className="h-full text-sm text-left">
                    Les présentes conditions générales sont conclues entre{" "}
                    <span className="font-bold">BigUp Business</span> , SASU, capital social : 1000 €, Enregistré
                    au RCS de Nanterre sous le numéro : 984189571, siège social : 7 rue de l’Industrie, 92400
                    Courbevoie, France, téléphone : +33977933063, email : support@eventtobee.com , ci-après dénommé
                    <span className="font-bold"> BigUp Business</span> et toute personne, physique ou morale, de
                    droit privé ou de droit public, inscrite sur le Site pour profiter du Service, ci-après
                    dénommée
                    <span className="font-bold"> l’Utilisateur</span>
                  </p>
                </div>

                <div className="pt-10">
                  <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                    ARTICLE 1. DEFINITIONS
                  </p>
                  <p className="h-full text-sm text-left">
                    <span className="font-bold">Client :</span> toute personne, physique ou morale, de droit privé
                    ou de droit public, non Loueur, inscrite sur le Site pour louer un Espace. « Espace » : lieu de
                    toute nature proposé à la location sur le Site par un Loueur. <br />
                    <span className="font-bold">Loueur :</span> toute personne, physique ou morale, de droit privé
                    ou de droit public, se connectant au Site pour proposer un Espace à la location. <br />
                    <span className="font-bold">Offre :</span> pages web, ainsi que tout fichier numérique associé,
                    publiées sur le Site par le Loueur pour la location d’un Espace. <br />
                    <span className="font-bold">Prestataire :</span> toute personne, physique ou morale, de droit
                    privé ou de droit public, inscrite sur le Site pour proposer une prestation. <br />
                    <span className="font-bold">Service :</span> service proposé par BigUp Business sur le Site et
                    consistant en la mise à disposition d’une plateforme en ligne permettant la location d’Espaces
                    entre Loueurs et Clients. <br />
                    <span className="font-bold">Site :</span> site internet accessible à l’URL
                    <a href="http://eventtobee.com" className="text-blue-600" target="_blank">
                      {" "}
                      www.eventtobee.com{" "}
                    </a>
                    , ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférant.{" "}
                    <span className="font-bold">Utilisateur :</span> Client ou Loueur.
                  </p>
                </div>

                <div className="pt-10">
                  <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                    ARTICLE 3. CHAMP D’APPLICATION
                  </p>
                  <p className="h-full text-sm text-left">
                    Le Site est d&#39;accès libre et gratuit à tout Internaute. La navigation sur le Site suppose
                    l&#39;acceptation par tout Internaute des présentes conditions générales. La simple connexion
                    au Site, par quelque moyen que ce soit, notamment par l&#39;intermédiaire d&#39;un robot ou
                    d&#39;un navigateur, emportera acceptation pleine et entière des présentes conditions
                    générales. Lors de l’inscription sur le Site, cette acceptation sera confirmée par le fait de
                    cocher la case correspondante. <br />
                    L&#39;Internaute reconnaît du même fait en avoir pris pleinement connaissance et les accepter
                    sans restriction. <br />
                    Le fait de cocher la case susvisée sera réputé avoir la même valeur qu&#39;une signature
                    manuscrite de la part de l’Internaute. L&#39;Internaute reconnaît la valeur de preuve des
                    systèmes d&#39;enregistrement automatique de BigUp Business et, sauf pour lui d&#39;apporter
                    preuve contraire, il renonce à les contester en cas de litige. <br /> Les présentes conditions
                    générales sont applicables aux relations entre les parties à l’exclusion de toutes autres
                    conditions, et notamment celles de l’Internaute. <br />
                    L&#39;acceptation des présentes conditions générales suppose de la part des Internautes
                    qu&#39;ils jouissent de la capacité juridique nécessaire pour cela, ou à défaut qu&#39;ils en
                    aient l&#39;autorisation d&#39;un tuteur ou d&#39;un curateur s&#39;ils sont incapables, de
                    leur représentant légal s&#39;ils sont mineurs, ou encore qu&#39;ils soient titulaires d&#39;un
                    mandat s&#39;ils agissent pour le compte d&#39;une personne morale.
                  </p>
                </div>

                <div className="pt-10">
                  <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                    ARTICLE 4. OBJET DU SITE
                  </p>
                  <p className="h-full text-sm text-left">
                    Le Site a pour objet la mise à disposition d’une plateforme électronique en ligne permettant la
                    location d’Espaces entre Loueurs et Clients.
                  </p>
                </div>

                <div className="pt-10">
                  <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                    ARTICLE 5. OBLIGATIONS DE BIGUP BUSINESS
                  </p>
                  <p className="h-full text-sm text-left">
                    Le Site a pour objet la mise à disposition d’une plateforme électronique en ligne permettant la
                    location d’Espaces entre Loueurs et Clients.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      5.1. Mise à disposition de la place de marché
                    </p>
                    BigUp Business s’engage à mettre à disposition une place de marché en ligne permettant au
                    Loueur de loueur ses Espaces aux Clients. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      5.2. Maintenance du Site
                    </p>
                    BigUp Business s’engage à héberger et à assurer la maintenance du Site à tout moment, en dehors
                    des périodes de maintenance. À cet égard, BigUp Business se réserve la possibilité de suspendre
                    momentanément ou de modifier sans préavis l&#39;accès au Site afin de procéder notamment à des
                    améliorations, des adaptations ou d&#39;en assurer la maintenance. <br />
                    BigUp Business supportera à cet égard tous les frais exposés à l’occasion de l’exécution du
                    présent contrat. BigUp Business fera son affaire personnelle et supportera la charge de tous
                    impôts et taxes fiscaux et parafiscaux, ainsi que toutes les cotisations sociales lui incombant
                    à ce titre.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      5.3. Prestataires
                    </p>
                    BigUp Business propose également aux Utilisateurs de consulter une liste de Prestataires sur le
                    Site. Comme tout Utilisateur, un Prestataire a la possibilité de créer un compte personnel sur
                    le Site, d’éditer un profil public et d’y lister ses prestations. Les autres Utilisateurs n’ont
                    pas la possibilité de souscrire aux prestations proposées par le Prestataire par
                    l’intermédiaire du Site, ils devront contacter le Prestataire directement pour lui adresser une
                    demande de devis.
                  </p>
                </div>

                <div className="pt-10">
                  <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                    ARTICLE 6. OBLIGATIONS DU LOUEUR
                  </p>
                  <p className="h-full text-sm text-left">
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.1. Mise en location d’un Espace par un Loueur
                    </p>
                    Le Loueur met son Espace à la location en précisant le prix auquel il souhaite le louer.
                    L’Espace sera alors loué à un Client acceptant ce prix. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.1.1. Publication d’une Offre par le Loueur
                    </p>
                    La publication d’une Offre est gratuite. Elle est ouverte à tout Loueur titulaire des droits
                    requis sur l’Espace. Les Loueurs s&#39;interdisent de publier plusieurs Offres visant à la
                    location d&#39;un même Espace. Les annonces devront être rédigées dans un langage correct et
                    compréhensible. Elles devront se conformer aux dispositions légales en vigueur et devront être
                    dépourvues de tout critère discriminatoire quant à la qualité de l&#39;éventuel cocontractant.{" "}
                    <br />
                    Les Loueurs auront la possibilité de télécharger des photographies et vidéos relatives à
                    l’Espace. Les photographies et vidéos téléchargées devront être au format et à la taille
                    spécifiée sur le Site. <br />
                    Au cours de la publication de l&#39;Offre, le Loueur disposera, via son espace personnel, de la
                    faculté de modifier la fiche descriptive de l’Offre. Ces modifications seront possibles tout au
                    long de la publication de l&#39;Offre. <br />
                    Toutes les données publiées par le Loueur dans le cadre des Offres, qu’il s’agisse de textes,
                    de photographies, de liens embed ou autres, le sont sous sa pleine et entière responsabilité.
                    Il est expressément porté à la connaissance des Utilisateurs que BigUp Business ne garantit en
                    aucun cas l&#39;exactitude des données contenues dans les Offres. Il appartient à tout
                    Utilisateur intéressé par l’Offre de s&#39;assurer de l&#39;exactitude de toutes ces données
                    préalablement à toute transaction effective. De même, BigUp Business ne garantit en aucun cas
                    aux Loueurs la location effective des Espaces visés par l&#39;Offre.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.2. Obligations du Loueur
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.2.1. Conformité des Espaces mis en location
                    </p>
                    Le Loueur s’engage à ce que tout Espace proposé à la location sur la place de marché en ligne
                    soit conforme à l’Offre publiée, authentique et d’excellente qualité. Le Loueur garantit à
                    BigUp Business qu’il est seul titulaire de l’ensemble des droits relatifs aux Espaces proposé à
                    la location et/ou qu’il dispose des droits et autorisation nécessaires à cette fin. <br />
                    BigUp Business se réserve le droit de désactiver toute Offre ou tout compte Loueur qui
                    contreviendrait aux présentes.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.2.2. Droit de rétractation
                    </p>
                    Le Loueur s’engage à respecter les modalités relatives au droit de rétractation à l’égard des
                    Clients consommateurs, tels que prévus aux articles L221-18 et suivants du code de la
                    consommation.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.3. Modalités de paiement
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.3.1. Mandat d’encaissement
                    </p>
                    Le Loueur confère à BigUp Business un mandat aux fins de l’encaissement des sommes versées par
                    les Clients pour la location d’un Espace sur le Site. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.3.2. Reversement
                    </p>
                    Le prix de la location sera reversé au Loueur, déduction faite d’une commission de 14% du prix
                    HT de la location de l’Espace, sous réserve de l’émission de la facture correspondante par le
                    Loueur et sous réserve qu’aucune réclamation n’ait été portée à l’attention de BigUp Business
                    par le Client dans un délai de 24h ouvrées à compter de la date prévu pour la location de
                    l’Espace, par virement bancaire aux coordonnées mentionnées sur le RIB remis par le Client à
                    BigUp Business dans un délai de 45 jours à compter de la fin du mois de l’émission de la
                    facture.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.3.3. Dépôt de garantie
                    </p>
                    Le Loueur a la possibilité de demander au Client un dépôt de garantie. Dans ce cas, les
                    modalités de versement du dépôt de garantie sont convenues directement entre le Loueur et le 4
                    Client, sans passer par le Site et sans que la responsabilité BigUp Business ne puisse être
                    recherchée à ce titre.
                  </p>
                </div>

                <div className="pt-10">
                  <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                    ARTICLE 7. ETAPES DE LA LOCATION D’UN ESPACE
                  </p>
                  <p className="h-full text-sm text-left">
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      7.1. Commande
                    </p>
                    Les Clients ont la possibilité de louer un Espace afin de réaliser un évènement. Au moment de
                    la location, le Client devra préciser l’Espace loué, la date de location, l’objet de la
                    location et sa durée. <br /> Afin de passer commande, les Clients pourront sélectionner
                    effectuer la location depuis l’Offre sur le Site. La disponibilité des Espaces est indiquée sur
                    le Site, sur l’Offre correspondante.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      7.2. Validation de la commande par le Client
                    </p>
                    Si leur commande leur convient, les Clients pourront la valider. Ils accéderont alors à un
                    formulaire sur lequel ils pourront soit saisir leurs identifiants de connexion s&#39;ils en
                    possèdent déjà, soit s&#39;inscrire sur le Site en complétant le formulaire d’inscription au
                    moyen des informations personnelles les concernant. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      6.2. Obligations du Loueur
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      7.3. Paiement par le Client
                    </p>
                    Dès lors qu&#39;ils seront connectés ou après qu&#39;ils auront parfaitement complété le
                    formulaire d’inscription, les Clients seront invités à contrôler ou modifier leurs coordonnées
                    de facturation, puis seront invités à effectuer leur paiement en étant redirigés à cet effet
                    sur l&#39;interface de paiement sécurisée comportant la mention « commande avec obligation de
                    paiement » ou toute formule analogue. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      7.4. Confirmation de la commande par BigUp Business
                    </p>
                    Une fois le paiement effectivement reçu par BigUp Business, ce dernier s&#39;engage à en
                    accuser réception au Client par voie électronique, dans un délai maximal de 24 heures. Dans le
                    même délai, BigUp Business s&#39;engage à adresser au Client un courrier électronique
                    récapitulatif de la commande et lui en confirmant le traitement par le Loueur, reprenant toutes
                    les informations y afférant.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      7.5. Confirmation de la commande par le Loueur
                    </p>
                    Le Loueur confirme au Client la réservation de l’espace dans un délai de 48h à compter de la
                    commande. En l’absence de confirmation par le Loueur dans ce délai, la commande est
                    automatiquement annulée et le paiement versé par le Client intégralement remboursé. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      7.6. Annulation d’une commande
                    </p>
                    Le Client a la possibilité d’annuler une réservation jusqu’à cinq jours avant la date prévue
                    pour la location de l’Espace. Dans ce cas, le montant de la réservation sera remboursé au
                    Client, déduction faite de frais de traitement correspondant à 30 % du montant hors taxes de la
                    réservation. <br />
                    Au-delà de cinq jours avant la date prévue pour la location de l’Espace, aucun remboursement ne
                    pourra être exigé par le Client.
                  </p>
                </div>

                <div className="pt-10">
                  <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                    ARTICLE 8. DECLARATIONS ET OBLIGATIONS DES UTILISATEURS
                  </p>
                  <p className="h-full text-sm text-left">
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      8.1. Information de l’Utilisateur
                    </p>
                    L’Utilisateur déclare avoir reçu de BigUp Business toutes les explications et précisions utiles
                    pouvant lui permettre d’utiliser les Services objet du présent contrat. <br /> L’Utilisateur
                    reconnaît que ses besoins et les Services proposés par BigUp Business sont en adéquation et
                    qu’il a souscrit au contrat en connaissance de cause et en disposant de toutes les informations
                    nécessaires lui permettant de produire un consentement libre et éclairé. L’Utilisateur s’engage
                    à informer BigUp Business de toute circonstance susceptible d’affecter l’exécution du présent
                    contrat dès qu’il en a connaissance.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      8.2. Autorisations et déclarations
                    </p>
                    Le Loueur est seul responsable des autorisations et déclarations relatives à la mise en
                    location des Espaces. <br /> Le Loueur déclare qu’il dispose des droits et autorisations
                    nécessaires à cette fin. Le cas échéant, le Loueur déclare avoir effectué au préalable toute
                    démarche nécessaire, telle que demandes d’autorisations et déclarations administratives. <br />{" "}
                    Le défaut de telles déclarations et autorisations ne pourra en aucun cas remettre en cause la
                    validité du présent contrat. <br /> Le Loueur garantit BigUp Business contre tout recours qui
                    serait entrepris à son encontre en cas de défaut de telles déclarations et autorisations.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      8.3. Réactivité
                    </p>
                    L’Utilisateur s’engage à réagir dans les meilleurs délais à toute demande de BigUp Business
                    relative à la réalisation du Service. L’Utilisateur reconnaît que son implication et sa
                    collaboration sont nécessaires pour la bonne exécution du contrat. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      8.4. Charte de bonne conduite
                    </p>
                    Le Loueur s’engage à ne pas proposer de contenu rédactionnel au sein des Offres pouvant :{" "}
                    <br /> - porter atteinte ou avoir de propos contraires à l&#39;ordre public, aux bonnes mœurs
                    ou pouvant heurter la sensibilité des mineurs . <br /> - porter atteinte de quelque manière que
                    ce soit aux droits à la réputation, à la vie privée, au droit ou à l&#39;image d’un tiers .{" "}
                    <br /> - être dénigrant, diffamatoire, porter atteinte à l&#39;image, à la réputation d&#39;une
                    marque ou d&#39;une quelconque personne physique ou morale, de quelque manière que ce soit .{" "}
                    <br /> - avoir des propos à caractère pornographique ou pédophile . <br /> - porter atteinte à
                    la sécurité ou à l&#39;intégrité d&#39;un Etat ou d&#39;un territoire, quel qu&#39;il soit .{" "}
                    <br /> - porter atteinte aux droits de propriété intellectuelle de quelque personne que ce soit
                    . <br /> - inciter à la haine, à la violence, au suicide, au racisme, à l&#39;antisémitisme, à
                    la xénophobie, à l&#39;homophobie, faire l&#39;apologie des crimes de guerre ou des crimes
                    contre l&#39;humanité . <br /> - inciter à commettre un crime, un délit ou un acte de
                    terrorisme . <br /> - inciter à la discrimination d&#39;une personne ou d&#39;un groupe de
                    personnes en raison de son appartenance à une ethnie, à une religion, à une race, ou du fait de
                    son orientation sexuelle ou de son handicap . <br /> - conseiller une pratique douteuse ou
                    frauduleuse. De même, BigUp Business se réserve le droit de refuser de publier toute Offre ne
                    présentant pas un niveau d’orthographe suffisant. <br />
                    Le Loueur s&#39;engage à ne pas entraver ou perturber le Site et les serveurs de BigUp Business
                    et à se conformer aux conditions requises, aux procédures, aux règles générales qui lui sont
                    communiquées par BigUp Business pour la publication des Offres. <br />
                    Tout usage illégal ou de manière générale non autorisé du Site entrainera la suppression
                    immédiate du compte du Loueur, sans préjudice des éventuels dommages-intérêts auxquels BigUp
                    Business pourrait prétendre. <br /> Le Loueur garantit en conséquence BigUp Business contre
                    tout dommage susceptible de lui être causé du fait de son utilisation du Site, en ce compris
                    les éventuels frais d’avocat et de procédure, et s’engage à ce titre à intervenir à toute
                    instance judiciaire engagée à son encontre du fait de son utilisation du Site.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 9. PRIX - PAIEMENT
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">9.1. Prix</p>
                    Les prix applicables sont ceux affichés sur l’Offre au jour de la réservation. Ces prix peuvent
                    être modifiés à tout moment par le Loueur pour toute réservation ultérieure. Les prix affichés
                    ne sont valables qu&#39;au jour de la réservation et ne portent pas effet pour l&#39;avenir.{" "}
                    <br /> Les prix indiqués sur le Site sont entendus en euros, toutes taxes comprises. <br />
                    BigUp Business perçoit une commission sur la location de chaque Espace par un Client. La
                    commission est à la charge exclusive du Loueur.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      9.2. Modalité de paiement
                    </p>
                    Le Client peut effectuer son règlement par carte bancaire. Les paiements par carte bancaire se
                    font au moyen de transactions sécurisées fournies par Stripe. <br />
                    Dans le cadre des paiements par carte bancaire, BigUp Business n&#39;a accès à aucune donnée
                    relative aux moyens de paiement du Client. Le paiement est effectué directement entre les mains
                    de l&#39;établissement bancaire.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      9.3. Facturation
                    </p>
                    BigUp Business adressera ou mettra à disposition du Client une facture par voie électronique
                    après chaque paiement. Le Client accepte expressément de recevoir les factures par voie
                    électronique.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      9.4. Défaut de paiement
                    </p>
                    Les dates de paiement convenues ne peuvent être retardées sous quelque prétexte que ce soit, y
                    compris en cas de litige. <br /> Toute somme non payée à l’échéance donnera lieu, de plein
                    droit et sans mise en demeure, à l’application de pénalités de retard calculées sur la base
                    d’un taux égal à 3 fois le taux d’intérêt légal, sans que cette pénalité nuise à l’exigibilité
                    des sommes dues en principal. <br /> En outre, tout retard de paiement aura pour conséquence la
                    facturation au Client professionnel défaillant de frais de recouvrement d&#39;un montant de 40
                    euros par facture due et l’exigibilité immédiate de toutes les sommes restant dues quels que
                    soient les délais convenus, majorées d’une indemnité de 20 % du montant à titre de clause
                    pénale. <br />
                    En tout état de cause, tout retarde paiement aura pour conséquence la possibilité de résilier
                    le contrat unilatéralement au tort du Client. <br />
                    La présente clause s’inscrit dans le cadre des dispositions de l’article 1152 du code civil
                    permettant au juge de réduire l’indemnité si le juge estime qu’elle est excessive.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 10. RECLAMATION - RETRACTATION – GARANTIE
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      10.1. Service clientèle
                    </p>
                    Le service clientèle du Site est accessible du lundi au vendredi de 9h à 18h au numéro de
                    téléphone non surtaxé suivant : +33977933063, par l’intermédiaire du formulaire en ligne ou par
                    courrier postal à l’adresse indiquée à l’article 1 des présentes conditions générales. Dans ces
                    deux derniers cas, BigUp Business s&#39;engage à apporter une réponse sous 24h ouvrées. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      10.2. Droit de rétractation – Vente à distance
                    </p>
                    Le contrat de vente étant conclu entre le Client et le Loueur, le droit de rétractation relatif
                    au contrat de location doit, le cas échéant, s’exercer directement à l’égard du Loueur ayant la
                    qualité de professionnel par l’intermédiaire du service client de BigUp Business accessible à
                    l’adresse : support@eventtobee.com.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 11. ESPACE PERSONNEL
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      11.1. Création de l’espace personnel
                    </p>
                    La création d&#39;un espace personnel est un préalable indispensable à toute location d&#39;un
                    Espace sur le Site par un Utilisateur. A cette fin, l’Utilisateur sera invité à fournir un
                    certain nombre d&#39;informations personnelles. Certaines de ces informations sont réputées
                    indispensables à la création de l&#39;espace personnel. Le refus par un Utilisateur de fournir
                    lesdites informations aura pour effet d&#39;empêcher la création de l&#39;espace personnel
                    ainsi que, incidemment, la validation de la commande. <br />
                    Lors de la création de l&#39;espace personnel, l’Utilisateur est invité à choisir un mot de
                    passe. Ce mot de passe constitue la garantie de la confidentialité des informations contenues
                    dans l’espace personnel. L’Utilisateur s&#39;interdit donc de le transmettre ou de le
                    communiquer à un tiers. A défaut, BigUp Business ne pourra être tenu pour responsable des accès
                    non autorisés à l’espace personnel d&#39;un Internaute. <br />
                    L’Utilisateur s’engage à procéder à une vérification régulière des données qui le concernent et
                    à procéder en ligne, depuis son espace personnel, aux actualisations et modifications
                    nécessaires. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      11.2. Contenu de l’espace personnel
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      11.2.1. Généralité
                    </p>
                    L’espace personnel permet au Client de consulter et de suivre toutes ses locations effectuées
                    sur le Site. <br /> L’espace personnel permet au Loueur de gérer ses locations, ses Espaces,
                    ses demandes de réservations. <br /> L’espace personnel permet au Prestataire d’afficher ses
                    prestations. <br /> Les pages relatives aux espaces personnels sont librement imprimables par
                    le titulaire du compte en question, mais ne constituent nullement une preuve admissible par un
                    tribunal. Elles n&#39;ont qu&#39;un caractère informatif destiné à assurer une gestion efficace
                    de ses commandes par l’Utilisateur. <br />
                    BigUp Business s&#39;engage à conserver de façon sécurisée tous les éléments contractuels dont
                    la conservation est requise par la loi ou la réglementation en vigueur. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      11.2.2. Publication d&#39;un profil public
                    </p>
                    Les Utilisateurs se voient offrir la faculté de se constituer un profil public sur le Site.
                    Dans ce cadre, les Utilisateurs pourront renseigner un certain nombre d&#39;informations
                    personnelles les concernant, telles que adresse email, comptes Instagram, Facebook, YouTube, et
                    l&#39;URL de son site web. Tous les profils publics seront soumis à validation préalable de
                    BigUp Business ou de son équipe de modérateurs avant leur première mise en ligne. <br /> Il est
                    expressément entendu que le Utilisateur, en décidant de souscrire aux services offerts par
                    BigUp Business et en complétant la fiche relative à son profil public, reconnaît que ces
                    données, et notamment sa photographie, sont susceptibles d&#39;être publiées sur le Site et
                    diffusées à ce titre auprès des autres Utilisateurs et Internautes. Le simple fait de fournir
                    ces informations et de compléter les champs de la fiche de création du profil public emportera
                    manifestation expresse de la volonté du Utilisateur de publier lesdites informations sur le
                    Site. Les Utilisateurs disposeront de la faculté de faire cesser la diffusion de leur profil
                    public. Ils devront, pour cela, demander la suppression de leur compte Utilisateur et la
                    résiliation des services, en suivant la procédure prévue à cet effet. La suspension de la
                    diffusion du profil public sera effective dans un délai maximal de 3 jours ouvrés à compter de
                    la réception de la demande par BigUp Business. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      11.2.3. Messagerie interne
                    </p>
                    Il est mis à disposition des Utilisateurs un service de messagerie privée interne. Ce système
                    est réservé aux Utilisateurs et le secret des correspondances lui est applicable. <br />
                    Le contenu des boîtes de réception et d&#39;envoi ne fait l&#39;objet d&#39;aucune garantie de
                    conservation de la part de BigUp Business et il appartient aux Utilisateurs de sauvegarder
                    lesdits contenus. La perte de ces contenus, quelle qu&#39;en soit la cause, ne pourra
                    constituer un dommage pour l’Utilisateur qui ne pourra prétendre à aucune indemnité de ce fait.{" "}
                    <br />
                    Tout Utilisateur qui sera victime d&#39;abus (spams, publicités non souhaitées ou autre) pourra
                    en informer BigUp Business qui prendra toutes mesures utiles.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      11.2.4. Contributions des Utilisateurs
                    </p>
                    Les Utilisateurs se voient offrir la faculté de contribuer au contenu du Site par la
                    publication de commentaires sur leur utilisation des Services et leur relation avec BigUp
                    Business. Les commentaires devront être apportés en français. Ils seront soumis à validation de
                    BigUp Business ou de son équipe de modérateurs. <br /> En cliquant sur l&#39;onglet « Valider
                    », afin de publier son commentaire, l’Utilisateur accorde une licence de droit d&#39;auteur non
                    exclusive à titre gratuit à BigUp Business portant sur ledit commentaire. A ce titre,
                    l’Utilisateur autorise BigUp Business à communiquer au public en ligne, en tout ou partie, son
                    commentaire sur le Site, les newsletters de BigUp Business et les sites des partenaires de
                    BigUp Business. L’Utilisateur autorise BigUp Business à reproduire son commentaire aux fins de
                    le communiquer au public en ligne et de réaliser des supports de communication et de promotion
                    du Site. L’Utilisateur autorise BigUp Business à traduire dans toute langue son commentaire aux
                    fins de le communiquer au public en ligne et de le reproduire sur ses supports commerciaux et
                    promotionnels. Le droit d&#39;adaptation comprend également le droit d&#39;effectuer les
                    modifications techniquement nécessaires à l&#39;exploitation du commentaire dans d&#39;autres
                    formats. La présente licence est accordée pour une utilisation commerciale et publicitaire.
                    Elle est accordée pour toute la durée des droits, pour une exploitation en France et à
                    l&#39;étranger. <br />
                    L’Utilisateur déclare disposer de la propriété intellectuelle et des autorisations nécessaires
                    pour procéder à la publication de son commentaire. L’Utilisateur s&#39;engage à intervenir sur
                    toute demande de BigUp Business à toute instance engagée contre cette dernière du fait de son
                    commentaire et à le garantir des éventuels frais et condamnations prononcés à son encontre de
                    ce fait, en ce compris les éventuels frais d&#39;avocat.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      11.3. Suppression de l’espace personnel
                    </p>
                    BigUp Business se réserve le droit de supprimer le compte de tout Utilisateur qui contrevient
                    aux présentes conditions générales, notamment lorsque l’Utilisateur fournit des informations
                    inexactes, incomplètes, mensongères ou frauduleuses, ainsi que lorsque l’espace personnel d’un
                    Utilisateur sera resté inactif depuis au moins une année. Ladite suppression ne sera pas
                    susceptible de constituer une faute de BigUp Business ou un dommage pour l’Utilisateur exclu,
                    qui ne pourra prétendre à aucune indemnité de ce fait. <br /> Cette exclusion est sans
                    préjudice de la possibilité, pour BigUp Business, d&#39;entreprendre des poursuites d&#39;ordre
                    judiciaire à l&#39;encontre de l’Utilisateur, lorsque les faits l&#39;auront justifié. <br />
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 12. DONNEES PERSONNELLES
                    </p>
                    Dans le cadre de sa prestation, BigUp Business va être amené à traiter des données à caractère
                    personnel de ses Utilisateurs. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.1. Identité du responsable du traitement
                    </p>
                    Le responsable de la collecte et des données traitées sur le Site est BigUp Business.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.2. Identité du Délégué à la protection des données
                    </p>
                    Le responsable de la collecte et des données traitées est Bigup-Conseils, SAS, dont le siège
                    social se situe au 7 rue de l’Industrie 92400 Courbevoie, inscrite au RCS de Nanterre sous le
                    numéro 884 060 617, contacts@bigup-conseils.com.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.3. Données collectées
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.3.1. Données collectées auprès des Utilisateurs
                    </p>
                    Dans le cadre de ses relations contractuelles, BigUp Business peut être amené à collecter et
                    traiter des informations de ses Utilisateurs, à savoir : les noms, prénoms, numéro de
                    téléphone, adresses postales, adresses mails, historique des contrats, âge, sexe.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.3.2. Finalités de la collecte de données personnelles
                    </p>
                    Les données collectées lors de la relation contractuelle font l&#39;objet d&#39;un traitement
                    automatisé ayant pour finalité de : <br /> - exécuter les engagements contractuels . <br /> -
                    contacter les Utilisateurs . <br /> - éviter toute activité illicite ou illégale . <br /> -
                    faire respecter les conditions générales . <br /> - engager des procédures judiciaires . <br />{" "}
                    - vérifier l&#39;identité des Utilisateurs .
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.3.3. Bases juridiques du traitement
                    </p>
                    Les données collectées ont pour base juridique une relation contractuelle.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.3.4. Destinataires des données
                    </p>
                    Les données collectées sont consultables uniquement par BigUp Business dans les limites
                    strictement nécessaires à l’exécution des engagements contractuels. <br />
                    Ces données, que ce soit sous forme individuelle ou agrégée, ne sont jamais rendues librement
                    visualisables par une personne physique tierce.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.3.5. Durée de conservation des données personnelles
                    </p>
                    Les données personnelles collectées sont conservées pendant le temps de la relation
                    contractuelle, et pendant le temps durant lequel la responsabilité de BigUp Business peut être
                    engagée. <br />
                    Passé le délai de conservation, BigUp Business s’engage à supprimer définitivement les données
                    des personnes concernées sans en conserver une copie.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.3.6. Sécurité et confidentialité des données personnelles
                    </p>
                    Les données personnelles sont conservées dans des conditions sécurisées, selon les moyens
                    actuels de la technique, dans le respect des dispositions du Règlement général sur la
                    protection des données et de la législation nationale en vigueur. <br />
                    L’accès aux locaux de BigUp Business est également sécurisé.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.3.7. Minimisation des données
                    </p>
                    BigUp Business peut également collecter et traiter toute donnée transmise volontairement par
                    ses Utilisateurs. <br /> BigUp Business oriente ses Utilisateurs afin qu’il fournisse des
                    données à caractère personnel strictement nécessaires à l’exécution des engagements
                    contractuels. <br />
                    BigUp Business s’engage à ne conserver et traiter que les données strictement nécessaires à ses
                    activités professionnelles, et supprimera toute donnée reçue non utile à ses activités dans les
                    plus brefs délais.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.4. Respect des droits
                    </p>
                    Les Utilisateurs de BigUp Business disposent des droits suivants concernant leurs données
                    personnelles, qu’ils peuvent exercer en écrivant à l’adresse postale de BigUp Business ou en
                    remplissant le formulaire contact en ligne.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.4.1. Droit d’information, d’accès et de communication des données
                    </p>
                    Les Utilisateurs de BigUp Business ont la possibilité d’accéder aux données personnelles qui
                    les concernent. <br />
                    En raison de l’obligation de sécurité et de confidentialité dans le traitement des données à
                    caractère personnel qui incombe à BigUp Business, les demandes seront uniquement traitées si
                    les Utilisateurs rapportent la preuve de leur identité, notamment par la production d’un scan
                    de leur titre d’identité valide (en cas de demande par le formulaire électronique dédié) ou
                    d’une photocopie signée de leur titre d’identité valide (en cas de demande adressée par écrit),
                    tous deux accompagnés de la mention « j’atteste sur l’honneur que la copie de cette pièce
                    d’identité est conforme à l’original. Fait à … le … », suivie de leur signature. <br />
                    Pour les aider dans leur démarche, les Utilisateurs trouveront ici un modèle de courrier
                    élaboré par la Cnil.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.4.2. Droit de rectification, de suppression et droit à l’oubli des données
                    </p>
                    Les Utilisateurs de BigUp Business ont la possibilité de demander la rectification, la mise à
                    jour, le verrouillage ou encore l’effacement de leurs données personnelles qui peuvent s’avérer
                    le cas échéant inexactes, erronées, incomplètes ou obsolètes. <br /> Les Utilisateurs de BigUp
                    Business peuvent également définir des directives générales et particulières relatives au sort
                    des données à caractère personnel après leur décès. Le cas échéant, les héritiers d’une
                    personne décédée peuvent exiger de prendre en considération le décès de leur proche et/ou de
                    procéder aux mises à jour nécessaires. <br /> Pour les aider dans leur démarche, les
                    Utilisateurs trouveront ici un modèle de courrier élaboré par la Cnil.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.4.3. Droit d’opposition au traitement de données
                    </p>
                    Les Utilisateurs de BigUp Business ont la possibilité de s’opposer à un traitement de leurs
                    données personnelles. <br />
                    Pour les aider dans leur démarche, les Utilisateurs trouveront{" "}
                    <a
                      href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees"
                      className="text-blue-600"
                      target="_blank"
                    >
                      ici
                    </a>{" "}
                    un modèle de courrier élaboré par la Cnil.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.4.4. Droit à la portabilité des données
                    </p>
                    Les Utilisateurs de BigUp Business ont le droit de recevoir les données personnelles qu’ils ont
                    fournies à BigUp Business dans un format transférable, ouvert et lisible.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.4.5. Droit à la limitation du traitement
                    </p>
                    Les Utilisateurs de BigUp Business ont le droit de demander que le traitement de leurs données
                    personnelles par BigUp Business soit limité. Ainsi, leurs données ne pourront qu’être
                    conservées et non plus utilisées par BigUp Business.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.4.6. Délais de réponse
                    </p>
                    BigUp Business s’engage à répondre à toute demande d’accès, de rectification ou d’opposition ou
                    toute autre demande complémentaire d’informations dans un délai raisonnable qui ne saurait
                    dépasser 1 mois à compter de la réception de la demande.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.4.7. Plainte auprès de l’autorité compétente
                    </p>
                    Si les Utilisateurs de BigUp Business considèrent que BigUp Business ne respecte pas ses
                    obligations au regard de leurs données à caractère personnel, ils peuvent adresser une plainte
                    ou une demande auprès de l’autorité compétente. En France, l’autorité compétente est la Cnil à
                    laquelle ils peuvent adresser une demande{" "}
                    <a href="https://www.cnil.fr/fr/plaintes/internet" className="text-blue-600" target="_blank">
                      ici
                    </a>{" "}
                    .
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.5. Transfert des données collectées
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.5.1. Transfert à des partenaires
                    </p>
                    BigUp Business a recours à des prestataires habilités pour faciliter la collecte et le
                    traitement des données de ses Utilisateurs. Ces prestataires peuvent être situés en dehors de
                    l’Union Européenne. <br /> BigUp Business s’est préalablement assuré de la mise en œuvre par
                    ses prestataires de garanties adéquates et du respect de conditions strictes en matière de
                    confidentialité, d’usage et de protection des données, par exemple via le Privacy Shield
                    états-unien. BigUp Business fait appel aux sous-traitants suivants :
                    <div className="pt-10">
                      <table className="text-left border table-auto">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head) => (
                              <th key={head} className="p-4 text-center bg-blue-400 border border-black border-y">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold leading-none opacity-70 dark:opacity-100"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {TABLE_ROWS.map(({ partenaire, paysDesinataire, traitement, garanties }, index) => {
                            const isLast = index === TABLE_ROWS.length;
                            const classes = isLast ? "p-4 border" : "p-4 border-b border-black";

                            return (
                              <tr key={partenaire}>
                                <td className={`border ${classes}`}>
                                  <div className="flex items-center gap-3">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                      {partenaire}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={`border ${classes}`}>
                                  <Typography variant="small" color="blue-gray" className="font-normal">
                                    {paysDesinataire}
                                  </Typography>
                                </td>
                                <td className={`border ${classes}`}>
                                  <Typography variant="small" color="blue-gray" className="font-normal">
                                    {traitement}
                                  </Typography>
                                </td>
                                <td className={`border ${classes}`}>
                                  <Typography variant="small" color="blue-gray" className="font-normal">
                                    <a href={garanties} target="_blank" className="text-blue-600">
                                      {" "}
                                      {garanties}{" "}
                                    </a>
                                  </Typography>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.5.2. Transfert sur réquisition ou décision judiciaire
                    </p>
                    Les Utilisateurs consentent également à ce que BigUp Business communique les données collectées
                    à toute personne, sur réquisition d’une autorité étatique ou sur décision judiciaire. <br />
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      12.5.3. Transfert dans le cadre d&#39;une fusion ou d&#39;une acquisition
                    </p>
                    Si BigUp Business est impliqué dans une fusion, une vente d&#39;actifs, une opération de
                    financement, une liquidation ou banqueroute ou dans une acquisition de tout ou partie de son
                    activité par une autre société, les Utilisateurs consentent à ce que les données collectées
                    soient transmises par BigUp Business à cette société et que cette société opère les traitements
                    de données personnelles visés dans les présentes Conditions générales de service au lieu et
                    place de BigUp Business.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 13. RESPONSABILITE DE BIGUP BUSINESS
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      13.1. Nature des obligations de BigUp Business
                    </p>
                    BigUp Business s&#39;engage à apporter le soin et la diligence nécessaire à la fourniture de
                    Service de qualité conforme aux spécifications des présentes Conditions générales. BigUp
                    Business ne répond que d&#39;une obligation de moyens concernant les services objet des
                    présentes. <br />
                    BigUp Business n’agit qu’en qualité d’intermédiaire technique et commercial et n’est donc pas
                    partie aux contrats de locations passés entre le Loueur et le Client. BigUp Business n’est pas
                    tenue par les obligations du Loueur à l’égard du Client. BigUp Business ne garantit ni la
                    conclusion ni la bonne exécution des contrats entre le Client et le Loueur. BigUp Business ne
                    garantit en aucun cas la qualité ou la sécurité des Espaces.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      13.2. Force majeure - Faute de l’Utilisateur
                    </p>
                    BigUp Business n&#39;engagera pas sa responsabilité en cas de force majeure ou de faute de
                    l’Utilisateur, telles que définies au présent article :
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      13.2.1. Force majeure
                    </p>
                    Au sens des présentes conditions générales, sera considéré comme un cas de force majeure
                    opposable à l’Utilisateur tout empêchement, limitation ou dérangement du Service du fait
                    d&#39;incendie, d&#39;épidémie, d&#39;explosion, de tremblement de terre, de fluctuations de la
                    bande passante, de manquement imputable au fournisseur d&#39;accès, de défaillance des réseaux
                    de transmission, d&#39;effondrement des installations, d&#39;utilisation illicite ou
                    frauduleuse des mots de passe, codes ou références fournis à l’Utilisateur, de piratage
                    informatique, d&#39;une faille de sécurité imputable à l&#39;hébergeur du Site ou aux
                    développeurs, d&#39;inondation, de panne d&#39;électricité, de guerre, d&#39;embargo, de loi,
                    d&#39;injonction, de demande ou d&#39;exigence de tout gouvernement, de réquisition, de grève,
                    de boycott, ou autres circonstances hors du contrôle raisonnable de BigUp Business. Dans de
                    telles circonstances, BigUp Business sera dispensé de l&#39;exécution de ses obligations dans
                    la limite de cet empêchement, de cette limitation ou de ce dérangement.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      13.2.2. Faute de l’Utilisateur
                    </p>
                    Au sens des présentes Conditions générales, sera considéré comme une faute de l’Utilisateur
                    opposable à ce dernier toute mauvaise utilisation du Service, faute, négligence, omission ou
                    défaillance de sa part ou de celle de ses préposés, non-respect des conseils donnés par BigUp
                    Business sur son Site, toute divulgation ou utilisation illicite du mot de passe, des codes et
                    références de l’Utilisateur, ainsi que le renseignement d’informations erronées ou l’absence de
                    mise à jour de telles informations dans son espace personnel. Sera également considérée comme
                    une faute de l’Utilisateur la mise en œuvre de tout procédé technique, tels que des robots, ou
                    des requêtes automatiques, dont la mise en œuvre contreviendrait à la lettre ou à l’esprit des
                    présentes conditions générales de vente.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      13.3. Problèmes techniques - Liens hypertextes
                    </p>
                    En cas d&#39;impossibilité d&#39;accès au Site, en raison de problèmes techniques de toutes
                    natures, l’Utilisateur ne pourra se prévaloir d&#39;un dommage et ne pourra prétendre à aucune
                    indemnité. L&#39;indisponibilité, même prolongée et sans aucune durée limitative, d&#39;un ou
                    plusieurs services en ligne, ne peut être constitutive d&#39;un préjudice pour les Utilisateurs
                    et ne peut aucunement donner lieu à l&#39;octroi de dommages et intérêts de la part de BigUp
                    Business. <br /> Les liens hypertextes présents sur le Site peuvent renvoyer sur d&#39;autres
                    sites internet. La responsabilité de BigUp Business ne saurait être engagée si le contenu de
                    ces sites contrevient aux législations en vigueur. De même la responsabilité de BigUp Business
                    ne saurait être engagée si la visite, par l&#39;Internaute, de l&#39;un de ces sites, lui
                    causait un préjudice.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      13.4. Dommages-intérêts à la charge de BigUp Business
                    </p>
                    A défaut de dispositions légales ou réglementaires contraires, la responsabilité de BigUp
                    Business est limitée au préjudice direct, personnel et certain subi par l’Utilisateur et lié à
                    la défaillance en cause. BigUp Business ne pourra en aucun cas être tenu responsable des
                    dommages indirects tels que, notamment les pertes de données, les préjudices commerciaux, les
                    pertes de commandes, les atteintes à l&#39;image de marque, les troubles commerciaux et les
                    pertes de bénéfices ou de Utilisateurs. De même et dans les mêmes limites, le montant des
                    dommages et intérêts mis à la charge de BigUp Business ne pourra en tout état de cause excéder
                    le prix de la location de l’Espace.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      13.5. Liens hypertextes et contenus du Site
                    </p>
                    Les Contenus du Site sont publiés à titre indicatif, sans garantie d&#39;exactitude. BigUp
                    Business ne peut en aucun cas être tenu responsable du fait d&#39;une omission, d&#39;une
                    inexactitude ou de toute erreur contenue dans ces informations et qui serait à l&#39;origine
                    d&#39;un dommage direct ou indirect causé à l&#39;Internaute.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      13.6. Responsabilité en qualité d&#39;hébergeur
                    </p>
                    Les données, notamment Offres, publiées sur le Site par le Loueur le sont sous sa propre
                    responsabilité. <br />
                    Dans ce cadre, BigUp Business bénéficiera du statut d&#39;hébergeur des données au sens de
                    l&#39;article 6-I-2 de la Loi pour la confiance dans l&#39;économie numérique du 21 juin 2004.
                    Conformément à l&#39;alinéa 3 du même article, BigUp Business ne pourra voir sa responsabilité
                    civile ou pénale engagée à raison de ces commentaires, à moins que, dès le moment où il a eu
                    connaissance de l&#39;activité ou de l&#39;information illicite, il n&#39;ait agi promptement
                    pour retirer ces informations ou en rendre l&#39;accès impossible.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 14. PROPRIETE INTELLECTUELLE
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      14.1. Protection légale des Contenus du Site
                    </p>
                    Les Contenus du Site sont susceptibles d&#39;être protégés par le droit d&#39;auteur et le
                    droit des bases de données. Toute représentation, reproduction, traduction, adaptation ou
                    transformation, intégrale ou partielle, réalisée illégalement et sans le consentement de BigUp
                    Business ou de ses ayants droit ou ayants cause constitue une violation des Livres I et III du
                    Code de la propriété intellectuelle et sera susceptible de donner lieu à des poursuites
                    judiciaires pour contrefaçon.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      14.2. Protection contractuelle des Contenus du Site
                    </p>
                    L&#39;Internaute s&#39;engage contractuellement à l&#39;égard de BigUp Business à ne pas
                    utiliser, reproduire ou représenter, de quelque manière que ce soit, les Contenus du Site,
                    qu&#39;ils soient ou non protégés par un droit de propriété intellectuelle, à une autre fin que
                    celle de leur lecture par un robot ou un navigateur. Cette interdiction n&#39;est pas
                    applicable aux robots d&#39;indexation ayant pour seul objet de scanner le contenu du Site aux
                    fins d&#39;indexation.
                    <p className="mb-2 font-semibold text-left text-gray-900 dark:text-white">
                      ARTICLE 15. STIPULATIONS FINALES
                    </p>
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      15.1. Droit applicable
                    </p>
                    Les présentes conditions générales sont soumises à l&#39;application du droit français.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      15.2. Modifications des présentes conditions générales
                    </p>
                    Les présentes conditions générales peuvent être modifiées à tout moment par BigUp Business. Les
                    conditions générales applicables à l’Utilisateur sont celles en vigueur au jour de sa commande
                    ou de sa connexion sur le présent Site, toute nouvelle connexion à l&#39;espace personnel
                    emportant acceptation le cas échéant des nouvelles conditions générales.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      15.3. Litiges
                    </p>
                    En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans
                    le cadre de l&#39;exécution des présentes conditions générales et dont la solution n’aura pu
                    être trouvée préalablement à l’amiable entre les parties devra être soumis à
                    support@eventtobee.com : [URL de votre médiateur]. <br />
                    En outre, l’Utilisateur est informé de l’existence de la plateforme de règlement en ligne des
                    litiges, accessibles à l’adresse URL suivante :
                    <a
                      href="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show"
                      className="text-blue-600"
                      target="_blank"
                    >
                      https://ec.europa.eu/consumers/odr/main/?event=main.home2.show{" "}
                    </a>{" "}
                    <br />
                    Tout litige relatif au présent contrat ou en relation avec celui-ci sera tranché par voie
                    d’arbitrage conformément au règlement de l’Institut digital d’arbitrage et de médiation :{" "}
                    <a href="https://www.fast-arbitre.com/" className="text-blue-600" target="_blank">
                      www.fast-arbitre.com{" "}
                    </a>{" "}
                    .
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      15.4. Entièreté
                    </p>
                    La nullité d&#39;une des clauses du présent contrat n&#39;entraînera pas la nullité des autres
                    clauses du contrat ou du contrat dans sa globalité, qui garderont leur plein effet et portée.
                    Dans une telle hypothèse, les parties devront dans la mesure du possible remplacer la
                    stipulation annulée par une stipulation valable correspondant à l&#39;esprit et à l&#39;objet
                    des présentes.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      15.5. Non-renonciation
                    </p>
                    L&#39;absence d&#39;exercice par BigUp Business des droits qui lui sont reconnus par les
                    présentes ne pourra en aucun cas être interprétée comme une renonciation à faire valoir lesdits
                    droits.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      15.6. Démarchage téléphonique
                    </p>
                    L’Utilisateur est informé qu’il a la possibilité de s’inscrire sur la liste d&#39;opposition au
                    démarchage téléphonique à l’adresse{" "}
                    <a href="http://www.bloctel.gouv.fr/" className="text-blue-600" target="_blank">
                      http://www.bloctel.gouv.fr/{" "}
                    </a>
                    .
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      15.7. Langues des présentes conditions générales
                    </p>
                    Les présentes conditions générales sont proposées en français.
                    <p className="mb-2 text-sm font-medium text-left text-gray-900 dark:text-white">
                      15.8. Clauses abusives
                    </p>
                    Les stipulations des présentes conditions générales s&#39;appliquent sous réserve du respect
                    des dispositions impératives du Code de la consommation concernant les clauses abusives dans
                    les contrats conclus entre un professionnel et un consommateur.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
