import { zodResolver } from "@hookform/resolvers/zod";
import Label from "components/Label/Label";
import { LocationFormProps } from "data/reduxInterfaces";
import { useAppSelector } from "hooks/hooks";
import { FC, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import OnboardingTour, { stepStyle } from "utils/tours/OnboardingTour";
import { z } from "zod";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import toast from "react-hot-toast";
import FormItem from "./FormItem";
import { Card, List, ListItem } from "@material-tailwind/react";
import PhoneInput from "react-phone-input-2";

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];

const formSchema = z.object({
  category: z.string(),
  email: z.string().email({ message: "Veuillez entrer une adresse email valide." }),
  companyName: z.string({
    required_error: "Le nom de l'entrepise est obligatoire.",
    invalid_type_error: "Le nom de l'entrepise est obligatoire.",
  }),
  owner_title: z.string({
    required_error: "Le poste du propriétaire est obligatoire.",
    invalid_type_error: "Le poste du propriétaire est obligatoire.",
  }),
  companySiren: z.string({
    required_error: "Le SIREN de l'entrepise est obligatoire.",
    invalid_type_error: "Le SIREN de l'entrepise est obligatoire.",
  }),

  bank: z.string({
    required_error: "Le compte bancaire est obligatoire.",
    invalid_type_error: "Le compte bancaire est obligatoire.",
  }),
  rib: z
    .string()
    .min(27, { message: "Le RIB doit contenir exactement 27 caractères." })
    .max(27, { message: "Le RIB doit contenir exactement 27 caractères." })
    .refine((value) => value.startsWith("FR"), {
      message: "Le RIB doit commencer par 'FR'.",
    }),
  city: z.string().min(1, { message: "Le ville est obligatoire." }),
  address: z.string().min(1, { message: 'L"addresse est obligatoire.' }),
  address2: z.string().optional(),
  country: z.string().min(1, { message: "Le Pays est obligatoire." }),
  codePostale: z.number({
    required_error: "Le code postale est obligatoire.",
    invalid_type_error: "Le code postale doit étre un nombre.",
  }),
  stripe_url: z.string().optional(),

  companyPhone: z
    .string()
    .min(1, { message: "Le numéro téléphone est obligatoire." })
    .refine((value) => value.startsWith("33"), {
      message: "Le numéro de téléphone doit commencer par +33.",
    }),
  owner_city: z.string().min(1, { message: "Le ville est obligatoire." }),
  owner_address: z.string().min(1, { message: 'L"addresse est obligatoire.' }),
  owner_address2: z.string().optional(),
  owner_country: z.string().min(1, { message: "Le Pays est obligatoire." }),
  owner_postal_code: z.number({
    required_error: "Le code postale est obligatoire.",
    invalid_type_error: "Le code postale doit étre un nombre.",
  }),
});

const LocationCompanyPersonalData: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  data,
  errorLocationForm,
  FormData,
}) => {
  const phoneInputStyle = {
    borderRadius: "0.375rem",
    backgroundColor: "#FFFFFF",
  };

  const steps = [
    {
      selector: ".image",
      content: "Une image est une représentation visuelle.",
      stepStyle,
    },
    {
      selector: ".nom-entreprise",
      content:
        "Le nom commercial est le nom de l'entreprise perçu par le public. Il permet d'identifier le fonds de commerce ainsi que l'activité exercée par une entreprise",
    },
    {
      selector: ".num-siren ",
      content: "Le numéro SIREN (pour est le numéro unique d'identification de chaque entreprise. ",
    },
    {
      selector: ".num-identite ",
      content:
        "Toute personne est identifiée dès sa naissance par son numéro d'inscription au répertoire national d'identification des personnes physiques. ",
    },
    {
      selector: ".num-telephone ",
      content:
        "Un numéro de téléphone est une séquence unique et nominative de chiffres permettant de vous contacter. ",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      category: "company",
      city: data?.city ?? "",
      address: data?.address ?? "",
      address2: data?.address2 ?? "",
      country: data?.country ?? "",
      codePostale: data?.codePostale ?? undefined,
      owner_city: data?.owner_city ?? "",
      owner_address: data?.owner_address ?? "",
      owner_address2: data?.owner_address ?? "",
      owner_country: data?.owner_country ?? "",
      owner_postal_code: data?.owner_postal_code ?? undefined,
      stripe_url: "eventtobee.com",
    },
    mode: "onSubmit",
  });
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  useEffect(() => {
    if (handleForm.type === "chekLocationFormFourteenCompany") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const updatelocationStates = useAppSelector((state) => state.location.locationData);
  useEffect(() => {
    if (updatelocationStates) {
      setValue("companyName", updatelocationStates.companyName);
      setValue("companySiren", updatelocationStates.companySiren);
      setValue("bank", updatelocationStates.bank);
      setValue("rib", updatelocationStates.rib);
      setValue("stripe_url", updatelocationStates.stripe_url);
    }
  }, [updatelocationStates]);

  const { placesService, placePredictions, isPlacePredictionsLoading, getPlacePredictions } = usePlacesService({
    apiKey: process.env.REACT_APP_MAPS_API_KEY + "&loading=async",
    options: {
      input: "",
      componentRestrictions: {
        country: "fr",
      },
    },
  });

  useEffect(() => {
    if (data) {
      setValue("city", data.city);
      setValue("address", data.address);
      setValue("address2", data.address2);
      setValue("codePostale", data.codePostale);

      setValue("owner_city", data.city);
      setValue("owner_address", data.address);
      setValue("owner_address2", data.address2);
      setValue("owner_postal_code", data.codePostale);
    }
  }, [data]);

  const [isload, setIload] = useState(isLoading);
  const [isOwnerload, setOwnerload] = useState(isLoading);

  const holdeSelectAddress = async (place_id: string) => {
    setIload(true);
    placesService?.getDetails({ placeId: place_id }, (placeDetails: any, placeStatus: any) => {
      if (placeStatus !== "OK") {
        toast.error("Quelque chose ne fonctionne pas, veuillez réessayer!");
      }
      const parser = new DOMParser();
      const adr_address = parser.parseFromString(placeDetails?.adr_address ?? "", "text/html");
      const streetAddress = adr_address.querySelector(".street-address")?.textContent || "";
      const postalCode = adr_address.querySelector(".postal-code")?.textContent || "";
      const locality = adr_address.querySelector(".locality")?.textContent || "";
      const country_name = adr_address.querySelector(".country-name")?.textContent || "";

      console.log("adr_address", adr_address);
      console.log("streetAddress", streetAddress);
      console.log("postalCode", postalCode);
      console.log("locality", locality);
      console.log("country_name", country_name);
      if (streetAddress !== placeDetails?.name) {
        setValue("address", `${placeDetails?.name}, ${streetAddress}`);
      } else {
        setValue("address", streetAddress);
      }
      setValue("codePostale", Number(postalCode));
      setValue("city", locality);
      setValue("country", country_name);

      setIload(false);
    });

    getPlacePredictions({ input: "" });
  };

  const holdeSelectOwnerAddress = async (place_id: string) => {
    setOwnerload(true);
    placesService?.getDetails({ placeId: place_id }, (placeDetails: any, placeStatus: any) => {
      if (placeStatus !== "OK") {
        toast.error("Quelque chose ne fonctionne pas, veuillez réessayer!");
      }
      const parser = new DOMParser();
      const adr_address = parser.parseFromString(placeDetails?.adr_address ?? "", "text/html");
      const streetAddress = adr_address.querySelector(".street-address")?.textContent || "";
      const postalCode = adr_address.querySelector(".postal-code")?.textContent || "";
      const locality = adr_address.querySelector(".locality")?.textContent || "";
      const country_name = adr_address.querySelector(".country-name")?.textContent || "";

      if (streetAddress !== placeDetails?.name) {
        setValue("owner_address", `${placeDetails?.name}, ${streetAddress}`);
      } else {
        setValue("owner_address", streetAddress);
      }
      setValue("owner_postal_code", Number(postalCode));
      setValue("owner_city", locality);
      setValue("owner_country", country_name);

      setOwnerload(false);
    });

    getPlacePredictions({ input: "" });
  };

  return (
    <div className={className}>
      <form className="flex flex-col justify-center md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-grow mt-10 space-y-6 md:mt-0">
          <h2 className="p-1 flex items-center text-xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Informations de l'entreprise
          </h2>
          <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
            <div className="nom-entreprise">
              <Label>Nom de l'entreprise *</Label>

              <Input
                type="text"
                placeholder="Nom"
                className={`mt-1.5 ${errors.companyName?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                {...register("companyName")}
                style={{ minWidth: "350px" }}
              />
              {errors.companyName?.message && (
                <div className="text-xs text-red-500">{errors.companyName?.message}</div>
              )}
            </div>

            <div className="num-siren">
              <Label>Numéro SIREN *</Label>
              <Input
                style={{ minWidth: "350px" }}
                type="text"
                placeholder="SIREN"
                className={`mt-1.5 ${errors.companySiren?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                {...register("companySiren")}
              />
              {errors.companySiren?.message && (
                <div className="text-xs text-red-500">{errors.companySiren?.message}</div>
              )}
            </div>

            <div>
              <FormItem label="Adresse *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.address?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isload}
                    placeholder="..."
                    {...register("address")}
                    onChange={(e) => {
                      setValue("address", e.target.value);
                      getPlacePredictions({ input: e.target.value });
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Escape") {
                        getPlacePredictions({ input: "" });
                      }
                    }}
                    loading={isPlacePredictionsLoading || isload}
                  />

                  {placePredictions.length > 0 && (
                    <div className="flex flex-col flex-1 w-auto mt-0 mb-24 h-50">
                      <Card className="w-96">
                        <List>
                          {placePredictions.map((item, index) => (
                            <ListItem key={index} onClick={() => holdeSelectAddress(item.place_id)}>
                              {item.description}
                            </ListItem>
                          ))}
                        </List>
                      </Card>
                    </div>
                  )}

                  {errors.address?.message && (
                    <div className="text-xs text-red-500">{`${errors.address?.message}`}</div>
                  )}
                </div>
              </FormItem>
            </div>
          </div>

          <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
            <div className="nom-entreprise">
              <FormItem label="Complément d'adresse">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    disabled={isLoading || isload}
                    placeholder="..."
                    {...register("address2")}
                  />
                </div>
              </FormItem>
              {errors.address2?.message && <div className="text-xs text-red-500">{errors.address2?.message}</div>}
            </div>

            <div className="num-siren">
              <FormItem label="Ville *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.address?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isload}
                    placeholder="..."
                    {...register("city")}
                  />
                </div>
                {errors.city?.message && <div className="text-xs text-red-500">{`${errors.city?.message}`}</div>}
              </FormItem>
            </div>

            <div>
              <FormItem label="Code postale *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.codePostale?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isload}
                    placeholder="..."
                    type={"number"}
                    {...register("codePostale", { valueAsNumber: true })}
                  />
                </div>
                {errors.codePostale?.message && (
                  <div className="text-xs text-red-500">{`${errors.codePostale?.message}`}</div>
                )}
              </FormItem>
            </div>
          </div>

          <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
            <div className="num-siren">
              <FormItem label="Pays *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.address?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isload}
                    placeholder="..."
                    {...register("country")}
                  />
                </div>
                {errors.country?.message && (
                  <div className="text-xs text-red-500">{`${errors.country?.message}`}</div>
                )}
              </FormItem>
            </div>

            <div>
              <FormItem label="E-mail du l'entreprise ">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.email?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isload}
                    placeholder="..."
                    {...register("email")}
                  />
                </div>
                {errors.email?.message && <div className="text-xs text-red-500">{`${errors.email?.message}`}</div>}
              </FormItem>
            </div>
          </div>

          <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
            <div className="num-siren">
              <Label>Numéro Téléphone *</Label>
              <PhoneInput
                onlyCountries={["fr"]}
                country={"fr"}
                placeholder={getValues("companyPhone")}
                inputStyle={phoneInputStyle}
                containerStyle={phoneInputStyle}
                buttonStyle={phoneInputStyle}
                searchStyle={phoneInputStyle}
                inputProps={{ required: true }}
                value={getValues("companyPhone")}
                onChange={(value) => setValue("companyPhone", value)}
                disabled={isLoading}
              />
              {errors.companyPhone?.message && (
                <div className="text-xs text-red-500">
                  {errors.companyPhone?.message === "Required"
                    ? "Ce champ est obligatoire"
                    : ` ${errors.companyPhone?.message}`}
                </div>
              )}
            </div>
          </div>

          <h2 className="p-1 flex items-center text-xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Informations du propriétaire de l'entreprise
          </h2>
          <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
            <div className="num-siren">
              <Label>Poste du propriétaire de l'entreprise</Label>
              <Input
                style={{ minWidth: "350px" }}
                type="text"
                placeholder="Poste"
                className={`mt-1.5 ${errors.owner_title?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                {...register("owner_title")}
              />
              {errors.owner_title?.message && (
                <div className="text-xs text-red-500">{errors.owner_title?.message}</div>
              )}
            </div>

            <div>
              <FormItem label="Adresse *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.owner_address?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isOwnerload}
                    placeholder="..."
                    {...register("owner_address")}
                    onChange={(e) => {
                      setValue("owner_address", e.target.value);
                      getPlacePredictions({ input: e.target.value });
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Escape") {
                        getPlacePredictions({ input: "" });
                      }
                    }}
                    loading={isPlacePredictionsLoading || isOwnerload}
                  />

                  {placePredictions.length > 0 && (
                    <div className="flex flex-col flex-1 w-auto mt-0 mb-24 h-50">
                      <Card className="w-96">
                        <List>
                          {placePredictions.map((item, index) => (
                            <ListItem key={index} onClick={() => holdeSelectOwnerAddress(item.place_id)}>
                              {item.description}
                            </ListItem>
                          ))}
                        </List>
                      </Card>
                    </div>
                  )}

                  {errors.address?.message && (
                    <div className="text-xs text-red-500">{`${errors.address?.message}`}</div>
                  )}
                </div>
              </FormItem>
            </div>
          </div>

          <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
            <div className="nom-entreprise">
              <FormItem label="Complément d'adresse">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    disabled={isLoading || isOwnerload}
                    placeholder="..."
                    {...register("owner_address2")}
                  />
                </div>
              </FormItem>
              {errors.owner_address2?.message && (
                <div className="text-xs text-red-500">{errors.owner_address2?.message}</div>
              )}
            </div>

            <div className="num-siren">
              <FormItem label="Ville *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.owner_city?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isOwnerload}
                    placeholder="..."
                    {...register("owner_city")}
                  />
                </div>
                {errors.owner_city?.message && (
                  <div className="text-xs text-red-500">{`${errors.owner_city?.message}`}</div>
                )}
              </FormItem>
            </div>

            <div>
              <FormItem label="Code postale *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.owner_postal_code?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isOwnerload}
                    placeholder="..."
                    type={"number"}
                    {...register("owner_postal_code", { valueAsNumber: true })}
                  />
                </div>
                {errors.owner_postal_code?.message && (
                  <div className="text-xs text-red-500">{`${errors.owner_postal_code?.message}`}</div>
                )}
              </FormItem>
            </div>
          </div>

          <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
            <div>
              <div className="num-siren">
                <FormItem label="Pays *">
                  <div className="md:w-1/2">
                    <Input
                      style={{ minWidth: "350px" }}
                      className={`mt-1.5 ${errors.owner_country?.message ? "border-red-500" : ""}`}
                      disabled={isLoading || isOwnerload}
                      placeholder="..."
                      {...register("owner_country")}
                    />
                  </div>
                  {errors.owner_country?.message && (
                    <div className="text-xs text-red-500">{`${errors.owner_country?.message}`}</div>
                  )}
                </FormItem>
              </div>
            </div>
          </div>

          <div>
            <h2 className="p-5 flex items-center text-xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Informations de compte bancaire
            </h2>

            <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
              <div className="bank">
                <Label>BIC </Label>
                <Input
                  {...register("bank")}
                  disabled={isLoading}
                  type="text"
                  name="bank"
                  placeholder="bank"
                  style={{ minWidth: "350px" }}
                />
                {errors.bank?.message && <div className="text-xs text-red-500">{errors.bank?.message}</div>}
              </div>
              <div className="iban">
                <Label>RIB</Label>
                <Input
                  {...register("rib")}
                  disabled={isLoading}
                  type="text"
                  name="rib"
                  placeholder="FR11 XXXX XXXX XXXX XXXX XXXX XXXX"
                  style={{ minWidth: "350px" }}
                />
                {errors.rib?.message && <div className="text-xs text-red-500">{errors.rib?.message}</div>}
              </div>
            </div>
          </div>
        </div>
      </form>
      <OnboardingTour steps={steps} page_name={"CompanyPersonalDataForm"}></OnboardingTour>
    </div>
  );
};

export default LocationCompanyPersonalData;
