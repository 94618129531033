import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface AlertProps {
  containerClassName?: string;
  type?: "default" | "warning" | "info" | "success" | "error";
  children: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({
  children = "Alert Text",
  containerClassName = "",
  type = "default",
}) => {
  let classes = containerClassName;
  switch (type) {
    case "default":
      classes += " text-black bg-neutral-900";
      break;
    case "info":
      classes += " bg-status-infoBg text-status-info";
      break;
    case "success":
      classes += " bg-lime-200 px-6 py-5 text-base text-success-700";
      break;
    case "error":
      classes += " bg-red-200 px-6 py-5 text-base text-danger-700";
      break;
    case "warning":
      classes += " bg-status-warningBg text-status-warning";
      break;
    default:
      break;
  }

  return (
    <div
      className={`ttnc-alert relative flex items-center text-paragraph-base px-6 pt-4 pb-3 rounded-lg ${classes}`}
    >
      <i className="pe-7s-info text-2xl mr-2"></i>
      <span className="mr-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
          <path
            fill-rule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
      {children}
    </div>
  );
};
