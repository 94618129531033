import React, { Fragment } from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";

interface AlertModalMessageProps {
  open: boolean;
  handleClose: () => void;
  email?: string;
  message?: string;
  alertText?: string;
  navigateTo?: string;
}

const AlertModalMessage: React.FC<AlertModalMessageProps> = ({
  open,
  handleClose,
  email,
  message,
  alertText,
  navigateTo,
}) => {
  const handleCloseModal = () => {
    handleClose();
    if (navigateTo != undefined) {
      window.location.href = navigateTo;
    }
  };

  return (
    <Fragment>
      <Dialog open={open} handler={handleCloseModal}>
        <DialogHeader className="text-center">{alertText}</DialogHeader>
        <DialogBody divider>{message}</DialogBody>
        <DialogFooter>
          <ButtonPrimary onClick={handleCloseModal}>
            <span>Quitter</span>
          </ButtonPrimary>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default AlertModalMessage;
