import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  FavorisLocation,
  createLocation,
  createLocationEquipment,
  createLocationExtraEquipment,
  createLocationPrice,
  createLocationRule,
} from "./featureAPI";

interface ExtrasPayload {
  extra: string;
  price: number;
}
interface Price {
  id: number;
  min: number;
  max: number;
  price: number;
  stripeId: string;
  priceId: string;
  enable: boolean;
  createdAt: string;
  updatedAt: string;
  locationId: number;
}
const initialState = {
  categoryLocationId: "",
  surface: "",
  nbreVisitor: 5,
  city: "",
  address: "",
  fullAddress: "",
  map: "",
  selectedEvents: [],
  selectedPlayground: [],
  selectedAtmospheres: [],
  selectedEquipments: [],
  extraEquipments: [] as ExtrasPayload[],
  handicap: undefined,
  parking: "",
  wc: undefined,
  water: undefined,
  light: undefined,
  description: "",
  selectedRules: [],
  extraRules: [],
  price: "",
  guarantee: "",
  selectedAvailableDays: [],
  likedLocation: [],
  isLiked: false,
  locationError: "",
  startHour: "",
  loading: "",
  endHour: "",
  images: [],
  prices: [] as Price[],
  title: "",
  status: "",
  validation: false,
  locations: [] as any,
  locationDetail: {
    commentCount: 0,
    averageLikes: 0,
    reservation_type: "Flexible",
    codePostale: "",
    nbreHour: "",
    iban_url: "",
    companyCertifImg_url: "",
    identityFImg_url: "",
    identityBImg_url: "",
    adresseImg_url: "",
    companyName: "",
    companySiren: "",
    map: "",
    priceId: "",
    atmosphere: [],
    playground: [],
    availableDays: [],
    events: [],
    rib: "",
    bank: "",
    prices: [] as Price[],
    priceInfo: {
      enable: true,
      id: 9,
      locationId: 0,
      max: 0,
      min: 0,
      price: 0,
      priceId: "",
      stripeId: "",
    },
    comments: { roundedMoyenne: 0, totalComments: 0, comments: [] },
    comment: { loading: "" },
    equipments: [],
    extras: [],
    conditions: [],
    ownerLocations: { locationsWithRoundedAverage: [], overallRoundedAverageLikes: 0, totalComments: 0 },
    images: [],
    id: null,
    title: "",
    description: "",
    region: null,
    address: "",
    address2: "",
    city: "",
    visitor: null,
    surface: "",
    light: false,
    price: "",
    startHour: "",
    endHour: "",
    parking: "",
    water: false,
    wc: "",
    handicap: false,
    guarantee: null,
    cleaning_costs: null,
    validation: false,
    location_url: null,
    createdAt: null,
    updatedAt: null,
    ownerId: null,
    userId: "",
    category: "",
    categoryLocationId: null,
    CategoryLocation: {
      id: null,
      designation: "",
      createdAt: null,
      updatedAt: null,
    },
    Client: {
      id: "",
      name: "",
      lastName: "",
      identityNumber: "",
      phone: "",
      birthday: "",
      client_url: "",
      createdAt: "",
      userId: "",
      account_type: "",
      bank: "",
      rib: "",
      adresseImg_url: "",
      iban_url: "",
      identityBImg_url: "",
      identityFImg_url: "",
      companyName: "",
      companySiren: "",
      companyPhone: "",
      owner_city: "",
      onwer_address: "",
      onwer_address2: "",
      owner_country: "",
      owner_postal_code: "",
      email: "",
      owner_title: "",
      companyCertifImg_url: "",
      companyEmail: "",
    },
    Owner: {
      id: null,
      name: "",
      lastName: "",
      identityNumber: "",
      rib: "",
      bank: "",
      phone: "",
      birthday: null,
      identityFImg_url: "",
      identityBImg_url: "",
      adresseImg_url: "",
      pays: null,
      companyCertifImg_url: "",
      companyName: "",
      companySiren: "",
      adresse: "",
      codePostale: "",
      suppAdresse: "",
      ville: "",
      iban_url: "",
      owner_url: "",
      validation: false,
      category: "",
      createdAt: null,
      updatedAt: null,
      userId: null,
      account_type: "",
    },
  },
};

export const addLocation = createAsyncThunk(
  "featureLocation/addLocation",
  async ({ data, ownerId }: { data: any; ownerId: number }) => {
    return await createLocation.add({ ...data, ownerId });
  }
);
export const addComment = createAsyncThunk("featureComment/addComment", async (data: any) => {
  return await createLocation.addComment(data);
});

export const fetchLocations = createAsyncThunk("featureLocation/fetchLocation", async () => {
  return await createLocation.getLocations();
});

export const getAllValidateLocations = createAsyncThunk("getAllLocations", async (pageNumber: number) => {
  return await createLocation.getAllValidateLocations(pageNumber);
});

export const fetchExtraById = createAsyncThunk("featureLocation/fetchExtraById", async (id: any) => {
  return await createLocation.getExtraById(id);
});

export const fetchPricesByIdLocation = createAsyncThunk(
  "featureLocation/fetchPricesByIdLocation",
  async (id: any) => {
    return await createLocationPrice.getPricesByIdLocation(id);
  }
);
export const fetchPriceById = createAsyncThunk("featureLocation/fetchPriceById", async (id: any) => {
  return await createLocationPrice.getPricesById(id);
});

export const fetchOwnerLocations = createAsyncThunk("featureLocation/fetchOwnerLocations", async (id: any) => {
  return await createLocation.getOwnerLocations(id);
});
export const fetchEquipmentById = createAsyncThunk("featureLocation/fetchEquipmentById", async (id: any) => {
  return await createLocation.getEquipmentById(id);
});
export const fetchConditionById = createAsyncThunk("featureLocation/fetchConditionById", async (id: any) => {
  return await createLocation.getConditionById(id);
});
export const fetchCommentById = createAsyncThunk("featureLocation/fetchCommentById", async (id: any) => {
  return await createLocation.getCommentById(id);
});
export const fetchLocationById = createAsyncThunk("featureLocation/fetchLocationById", async (id: any) => {
  return await createLocation.getLocationById(id);
});

export const fetchLocationsFiltered = createAsyncThunk(
  "featureLocation/fetchLocationFiltered",
  async ({ pageNumber, filters }: { pageNumber: number; filters: any }) => {
    return await createLocation.getLocationsFiltered(pageNumber, filters);
  }
);

export const checkLocationFavoriByUserId = createAsyncThunk(
  "checkLocationFavoriByUserId",
  async (data: { locationId: any; userId: any }) => {
    return await createLocation.checkLocationFavoriByUserId(data.locationId, data.userId);
  }
);

export const addLocationToFavoris = createAsyncThunk(
  "addLocationToFavoris",
  async (data: { locationId: any; userId: any }) => {
    return await createLocation.addLocationToFavoris(data.locationId, data.userId);
  }
);

export const fetchAllFavoris = createAsyncThunk("fetchAllFavoris", async () => {
  return await createLocation.getAllFavoris();
});

export const getLocationFavorisByClientId = createAsyncThunk(
  "getLocationFavorisByClientId",
  async (data: { clientId: any }) => {
    return await createLocation.getFavorisLocationByClientId(data.clientId);
  }
);

export const addEquipments = createAsyncThunk(
  "featureLocation/addEquipments",
  async ({ data, locationId }: { data: any; locationId: any }) => {
    return await createLocationEquipment.add({ data, locationId });
  }
);

export const addRules = createAsyncThunk(
  "featureLocation/addRules",
  async ({ data, locationId }: { data: any; locationId: any }) => {
    return await createLocationRule.add({ data, locationId });
  }
);

export const addExtraEquipments = createAsyncThunk(
  "featureLocation/addExtraEquipments",
  async ({ data, locationId }: { data: any; locationId: any }) => {
    return await createLocationExtraEquipment.add({ data, locationId });
  }
);

export const addPrice = createAsyncThunk(
  "featureLocation/addLocationPrice",
  async ({ data, locationId }: { data: any; locationId: any }) => {
    return await createLocationPrice.add({ data, locationId });
  }
);

export const addToFavoris = createAsyncThunk(
  "featureFavori/addFavoris",
  async (data: { locationId: any; clientId: number }) => {
    return await FavorisLocation.add(data.locationId, data.clientId);
  }
);

export const getFavorisByClient = createAsyncThunk("featureFavori/getFavorisByClient", async (clientId: any) => {
  return await FavorisLocation.getByClient(clientId);
});

export const checkFavoriByClientId = createAsyncThunk(
  "checkLocationFavoriByClientId",
  async (data: { locationId: any; clientId: number }) => {
    return await FavorisLocation.checkFavoriByClientId(data.locationId, data.clientId);
  }
);

export const deleteFavori = createAsyncThunk("featureFavori/deleteFavori", async (id: any) => {
  return await FavorisLocation.delete(id);
});

export const deleteLocationFromFavoris = createAsyncThunk(
  "deleteLocationFromFavoris",
  async (data: { locationId: any; clientId: any }) => {
    return await FavorisLocation.deleteLocationFromFavoris(data.locationId, data.clientId);
  }
);

const createLocationSlice = createSlice({
  name: "featureLocation",
  initialState,
  reducers: {
    initStates(state) {
      return {
        ...state,
        categoryLocationId: "",
        surface: "",
        nbreVisitor: 5,
        city: "",
        address: "",
        fullAddress: "",
        map: "",
        selectedEvents: [],
        selectedPlayground: [],
        selectedAtmospheres: [],
        selectedEquipments: [],
        extraEquipments: [] as ExtrasPayload[],
        handicap: undefined,
        parking: "",
        wc: undefined,
        water: undefined,
        light: undefined,
        description: "",
        selectedRules: [],
        extraRules: [],
        price: "",
        guarantee: "",
        selectedAvailableDays: [],
        likedLocation: [],
        isLiked: false,
        locationError: "",
        startHour: "",
        loading: "",
        endHour: "",
        images: [],
        prices: [] as Price[],
        title: "",
        status: "",
        validation: false,
        locations: [],
        locationDetail: {
          commentCount: 0,
          averageLikes: 0,
          reservation_type: "Flexible",
          rib: "",
          bank: "",
          codePostale: "",
          nbreHour: "",
          iban_url: "",
          companyCertifImg_url: "",
          identityFImg_url: "",
          identityBImg_url: "",
          adresseImg_url: "",
          companyName: "",
          companySiren: "",
          map: "",
          priceId: "",
          atmosphere: [],
          playground: [],
          availableDays: [],
          events: [],
          prices: [] as Price[],
          priceInfo: {
            enable: true,
            id: 9,
            locationId: 0,
            max: 0,
            min: 0,
            price: 0,
            priceId: "",
            stripeId: "",
          },
          comments: { roundedMoyenne: 0, totalComments: 0, comments: [] },
          comment: { loading: "" },
          equipments: [],
          extras: [],
          conditions: [],
          ownerLocations: {
            locationsWithRoundedAverage: [],
            overallRoundedAverageLikes: 0,
            totalComments: 0,
          },
          images: [],
          id: null,
          title: "",
          description: "",
          region: null,
          address: "",
          address2: "",
          city: "",
          visitor: null,
          surface: "",
          light: false,
          price: "",
          startHour: "",
          endHour: "",
          parking: "",
          water: false,
          wc: "",
          handicap: false,
          guarantee: null,
          cleaning_costs: null,
          validation: false,
          location_url: null,
          createdAt: null,
          updatedAt: null,
          ownerId: null,
          userId: "",
          category: "",
          categoryLocationId: null,
          CategoryLocation: {
            id: null,
            designation: "",
            createdAt: null,
            updatedAt: null,
          },
          Client: {
            id: "",
            name: "",
            lastName: "",
            identityNumber: "",
            phone: "",
            birthday: "",
            client_url: "",
            createdAt: "",
            userId: "",
            account_type: "",
            bank: "",
            rib: "",
            adresseImg_url: "",
            iban_url: "",
            identityBImg_url: "",
            identityFImg_url: "",
            companyName: "",
            companySiren: "",
            companyPhone: "",
            owner_city: "",
            onwer_address: "",
            onwer_address2: "",
            owner_country: "",
            owner_postal_code: "",
            email: "",
            owner_title: "",
            companyCertifImg_url: "",
            companyEmail: "",
          },
          Owner: {
            id: null,
            name: "",
            lastName: "",
            identityNumber: "",
            rib: "",
            bank: "",
            phone: "",
            birthday: null,
            identityFImg_url: "",
            identityBImg_url: "",
            adresseImg_url: "",
            pays: null,
            companyCertifImg_url: "",
            companyName: "",
            companySiren: "",
            adresse: "",
            codePostale: "",
            suppAdresse: "",
            ville: "",
            iban_url: "",
            owner_url: "",
            validation: false,
            category: "",
            createdAt: null,
            updatedAt: null,
            userId: null,
            account_type: "",
          },
        },
      };
    },

    setCategoryLocationId(state, action) {
      state.categoryLocationId = action.payload;
    },
    setSurface(state, action) {
      state.surface = action.payload;
    },
    setNbreVisitor(state, action) {
      state.nbreVisitor = action.payload;
    },
    setCity(state, action) {
      state.city = action.payload;
    },
    setAddress(state, action) {
      state.address = action.payload;
    },
    setFullAddress(state, action) {
      state.fullAddress = action.payload;
    },
    setMap(state, action) {
      state.map = action.payload;
    },
    setSelectedEvents(state, action) {
      state.selectedEvents = action.payload;
    },
    setSelectedPlayground(state, action) {
      state.selectedPlayground = action.payload;
    },
    setSelectedAtmosphere(state, action) {
      state.selectedAtmospheres = action.payload;
    },
    setSelectedEquipments(state, action) {
      state.selectedEquipments = action.payload;
    },
    setExtraEquipments(state, action) {
      state.extraEquipments.push(action.payload);
    },
    setHandicap(state, action) {
      state.handicap = action.payload;
    },
    setParking(state, action) {
      state.parking = action.payload;
    },
    setWc(state, action) {
      state.wc = action.payload;
    },
    setWater(state, action) {
      state.water = action.payload;
    },
    setLight(state, action) {
      state.light = action.payload;
    },
    setDescription(state, action) {
      state.description = action.payload;
    },
    setSelectedRules(state, action) {
      state.selectedRules = action.payload;
    },
    setExtraRules(state, action) {
      state.extraRules = action.payload;
    },
    setPrice(state, action) {
      state.price = action.payload;
    },
    setGuarantee(state, action) {
      state.guarantee = action.payload;
    },
    setSelectedAvailableDays(state, action) {
      state.selectedAvailableDays = action.payload;
    },
    setStartHour(state, action) {
      state.startHour = action.payload;
    },
    setEndHour(state, action) {
      state.endHour = action.payload;
    },
    setImages(state, action) {
      state.images = action.payload;
    },
    setTitle(state, action) {
      state.title = action.payload;
    },
    setValidation(state, action) {
      state.validation = action.payload;
    },
    setLocations(state, action) {
      state.locations = action.payload;
    },
    resetLocationState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addLocation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addLocation.fulfilled, (state, action) => {
        state.status = "succeeded";
        const payload = action.payload as any;
        state.categoryLocationId = payload.categoryLocationId;
        state.surface = payload.surface;
        state.nbreVisitor = payload.nbreVisitor;
        state.city = payload.city;
        state.address = payload.address;
        state.fullAddress = payload.fullAddress;
        state.map = payload.map;
        state.selectedEvents = payload.selectedEvents;
        state.selectedPlayground = payload.selectedPlayground;
        state.selectedAtmospheres = payload.selectedAtmospheres;
        state.selectedEquipments = payload.selectedEquipments;
        state.extraEquipments = payload.extraEquipments;
        state.handicap = payload.handicap;
        state.parking = payload.parking;
        state.wc = payload.wc;
        state.water = payload.water;
        state.light = payload.light;
        state.description = payload.description;
        state.selectedRules = payload.selectedRules;
        state.extraRules = payload.extraRules;
        state.price = payload.price;
        state.guarantee = payload.guarantee;
        state.selectedAvailableDays = payload.selectedAvailableDays;
        state.startHour = payload.startHour;
        state.endHour = payload.endHour;
        state.images = payload.images;
        state.title = payload.title;
        state.validation = payload.validation;
      })
      .addCase(addLocation.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.locations = action.payload;
      })
      .addCase(getAllValidateLocations.fulfilled, (state, action) => {
        state.locations = action.payload;
      })
      .addCase(fetchLocationById.fulfilled, (state, action) => {
        state.locationDetail = action.payload;
      })
      .addCase(fetchCommentById.fulfilled, (state, action) => {
        state.locationDetail.comments = action.payload;
      })
      .addCase(fetchExtraById.fulfilled, (state, action) => {
        state.locationDetail.extras = action.payload;
      })
      .addCase(fetchPricesByIdLocation.fulfilled, (state, action) => {
        state.locationDetail.prices = action.payload;
      })
      .addCase(fetchPriceById.fulfilled, (state, action) => {
        state.locationDetail.priceInfo = action.payload;
      })
      .addCase(fetchConditionById.fulfilled, (state, action) => {
        state.locationDetail.conditions = action.payload;
      })
      .addCase(fetchEquipmentById.fulfilled, (state, action) => {
        state.locationDetail.equipments = action.payload;
      })
      .addCase(fetchOwnerLocations.fulfilled, (state, action) => {
        state.locationDetail.ownerLocations = action.payload;
      })
      .addCase(fetchLocationsFiltered.fulfilled, (state, action) => {
        state.locations = action.payload;
      })
      .addCase(getFavorisByClient.fulfilled, (state: any, action: any) => {
        state.likedLocation = action.payload || [];
      })
      .addCase(checkFavoriByClientId.fulfilled, (state, action) => {
        if (action.payload) {
          state.isLiked = true;
        } else {
          state.isLiked = false;
        }
      })
      .addCase(addToFavoris.pending, (state, action) => {
        state.loading = "pending";
        state.locationError = "";
      })
      .addCase(addToFavoris.fulfilled, (state, action) => {
        if (action.payload.status === "OK") {
          state.loading = "succeeded";
          state.likedLocation = action.payload;
        }
        if (action.payload.status === "VALIDATION") {
          state.loading = "failed";
          state.locationError = action.payload.error[0].msg ?? "failed";
        }
        if (action.payload.status === "ERROR") {
          state.loading = "failed";
          state.locationError = action.payload.error;
        }
      })
      .addCase(addToFavoris.rejected, (state, action) => {
        state.loading = "failed";
      });

    // Favoris

    builder.addCase(addLocationToFavoris.pending, (state, action) => {
      state.loading = "pending";
      state.locationError = "";
    });
    builder.addCase(addLocationToFavoris.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
        state.likedLocation = action.payload;
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.locationError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.locationError = action.payload.error;
      }
    });
    builder.addCase(addLocationToFavoris.rejected, (state, action) => {
      state.loading = "failed";
    });
    builder.addCase(addComment.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(addComment.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.locationDetail.comment.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.locationDetail.comment.loading = "failed";
      }
      if (action.payload.status === "ERROR") {
        state.locationDetail.comment.loading = "failed";
      }
    });
    builder.addCase(addComment.rejected, (state, action) => {
      state.locationDetail.comment.loading = "failed";
    });
    builder.addCase(deleteLocationFromFavoris.fulfilled, (state, action) => {
      state.isLiked = false;
      state.likedLocation = action.payload;
    });
    builder.addCase(getLocationFavorisByClientId.fulfilled, (state: any, action: any) => {
      state.likedLocation = action.payload || [];
    });
  },
});

export const {
  initStates,
  setCategoryLocationId,
  setSurface,
  setNbreVisitor,
  setCity,
  setAddress,
  setFullAddress,
  setMap,
  setSelectedEvents,
  setSelectedPlayground,
  setSelectedEquipments,
  setExtraEquipments,
  setHandicap,
  setParking,
  setWc,
  setWater,
  setLight,
  setDescription,
  setSelectedRules,
  setExtraRules,
  setPrice,
  setGuarantee,
  setSelectedAvailableDays,
  setStartHour,
  setEndHour,
  setImages,
  setTitle,
  setSelectedAtmosphere,
  resetLocationState,
} = createLocationSlice.actions;

export default createLocationSlice.reducer;
