import React, { useContext } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import LangDropdown from "components/Header/LangDropdown";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { logOut } from "redux/features/auth/authSlice";
import { AuthContext } from "context/appContext";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({ data = NAVIGATION_DEMO, onClickClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth?.user);
  const handleLogOut = () => {
    dispatch(logOut());
    navigate("/");
  };

  const authContext = useContext(AuthContext);

  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="pb-1 pl-6 text-base nav-mobile-sub-menu">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              end
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                `flex px-4 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 ${
                  isActive ? "text-secondary" : ""
                }`
              }
            >
              <span className={`py-2.5 pr-3 ${!i.children ? "block w-full" : ""}`}>{i.name}</span>
              {i.children && (
                <span className="flex flex-1" onClick={(e) => e.preventDefault()}>
                  <Disclosure.Button as="span" className="py-2.5 flex justify-end flex-1">
                    <ChevronDownIcon className="w-4 h-4 ml-2 text-neutral-500" aria-hidden="true" />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure key={item.id} as="li" className="text-neutral-900 dark:text-white">
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive ? "text-secondary" : ""
            }`
          }
          to={{
            pathname: item.href || undefined,
          }}
        >
          <span className={`py-2.5 pr-3 ${!item.children ? "block w-full" : ""}`}>{item.name}</span>
          {item.children && (
            <span className="flex flex-1" onClick={(e) => e.preventDefault()}>
              <Disclosure.Button as="span" className="py-2.5 flex items-center justify-end flex-1 ">
                <ChevronDownIcon className="w-4 h-4 ml-2 text-neutral-500" aria-hidden="true" />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>}
      </Disclosure>
    );
  };

  return (
    <div className="w-full h-screen py-2 overflow-y-auto transition transform bg-white divide-y-2 shadow-lg ring-1 dark:ring-neutral-700 dark:bg-neutral-900 divide-neutral-100 dark:divide-neutral-800">
      <div className="px-5 py-6">
        <Logo />
        <div className="flex flex-col mt-5 text-sm text-neutral-700 dark:text-neutral-300">
          <span>
            Bienvenue sur notre plateforme ! Nous sommes ravis de vous accueillir parmi nous. Profitez pleinement
            de votre expérience !
          </span>

          <div className="flex items-center justify-between mt-4">
            <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
            <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>
          </div>
        </div>
        <span className="absolute p-1 top-2 right-2">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col px-2 py-6 space-y-1">{data.map(_renderItem)}</ul>

      <ul className="flex flex-col px-2 py-6 space-y-1">
        {authContext &&
          authContext?.user?.role !== "admin" &&
          authContext?.user?.role !== "creator" &&
          authContext?.user?.role !== "service" && (
            <div className="px-3">
              <Link
                to="/add/location"
                className="xl:inline-flex group border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 dark:text-neutral-300 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              >
                Ajouter votre espace
              </Link>
            </div>
          )}
        {authContext?.user?.role == "service" && (
          <div className="px-3">
            <Link
              to="/add/service"
              className="xl:inline-flex group border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 dark:text-neutral-300 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              Ajouter votre prestation
            </Link>
          </div>
        )}
      </ul>

      {auth != undefined ? (
        <div className="flex items-center justify-between px-5 py-6">
          <ButtonPrimary className="text-sm font-medium" onClick={handleLogOut}>
            Se déconnecter
          </ButtonPrimary>
        </div>
      ) : (
        <div className="flex items-center justify-between px-5 py-6">
          <a className="inline-block" href="/login" rel="noopener noreferrer">
            <ButtonPrimary>
              {" "}
              <Link to="/login"> Compte</Link>
            </ButtonPrimary>
          </a>
        </div>
      )}
    </div>
  );
};

export default NavMobile;
