import { zodResolver } from "@hookform/resolvers/zod";
import { CardFooter, Typography } from "@material-tailwind/react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import {
  createBankAccountTokenMethod,
  createCompanyStripeTokenMethod,
  createParticularStripeTokenMethod,
} from "hooks/stripeHooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { getAllUsers } from "redux/features/auth/authSlice";
import { createConnectedAcount } from "redux/features/location/locationSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Select from "shared/Select/Select";
import { z } from "zod";
import DashboardMenu from "../../DashboardPage/DashboardMenu";
import Input from "shared/Input/Input";
import PhoneInput from "react-phone-input-2";
const baseURL = process.env.REACT_APP_SERVER_URL;

export interface AdminStripeProps {
  className?: string;
}

const formSchema = z.object({
  selected_User: z.string().min(1, { message: "Utilisateur est obligatoire." }),
  city: z.string().min(1, { message: "Le ville est obligatoire." }),
  address: z.string().min(1, { message: 'L"addresse est obligatoire.' }),
  address2: z.string().optional(),
  country: z.string().min(1, { message: "Le Pays est obligatoire." }),
  codePostale: z.number({
    required_error: "Le code postale est obligatoire.",
    invalid_type_error: "Le code postale doit étre un nombre.",
  }),

  // companyPhone: z
  //   .string()
  //   .optional()
  //   .refine((value) => !value || value.startsWith("+33"), {
  //     message: "Le numéro de téléphone doit commencer par +33.",
  //   }),

  companyPhone: z.string().optional(),

  owner_city: z.string().optional(),
  owner_address: z.string().optional(),
  owner_address2: z.string().optional(),
  owner_country: z.string().optional(),
  owner_postal_code: z
    .number({
      required_error: "Le code postale d'entreprise est obligatoire.",
      invalid_type_error: "Le code postale doit étre un nombre.",
    })
    .optional(),
  email: z.string().optional(),
  owner_title: z
    .string({
      required_error: "Le poste du propriétaire est obligatoire.",
      invalid_type_error: "Le poste du propriétaire est obligatoire.",
    })
    .optional(),
});

const AdminStripe: FC<AdminStripeProps> = ({ className = "" }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);
  const {
    handleSubmit: handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    register,
    watch,
    reset,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      companyPhone: "",
      owner_city: "",
      owner_address: "",
      owner_address2: "",
      owner_country: "",
      owner_postal_code: undefined,
      email: "",
      owner_title: "",
    },
    mode: "onSubmit",
  });

  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.auth?.users || []);
  const filtred_users = users.filter(
    (user) => user.role != "admin" && user.role != "service" && user.Clients?.[0]?.account_id === null
  );

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const userId = parseInt(getValues("selected_User"));
  const userValue = filtred_users.find((user) => user.id === userId);
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    let token;
    let bank_token;
    const selectedUserId = parseInt(getValues("selected_User"));
    const selectedUser = filtred_users.find((user) => user.id === selectedUserId);

    const city = getValues("city");
    const address = getValues("address");
    const address2 = getValues("address2");
    const country = getValues("country");
    const codePostale = getValues("codePostale");

    const owner_city = getValues("owner_city");
    const owner_address = getValues("owner_address");
    const owner_address2 = getValues("owner_address2");
    const owner_country = getValues("owner_country");
    const owner_codePostale = getValues("owner_postal_code");

    const owner_email = getValues("email");
    const owner_title = getValues("owner_title");
    const companyPhone = getValues("companyPhone");

    const new_data = {
      ...selectedUser,
      city,
      address,
      address2,
      country,
      codePostale,
      owner_city,
      owner_address,
      owner_address2,
      owner_country,
      owner_codePostale: owner_codePostale || undefined,
      owner_email,
      owner_title,
      companyPhone: companyPhone || undefined,
    };

    if (selectedUser?.Clients?.[0]?.account_type === "particular") {
      setIsLoading(true);
      token = await createParticularStripeTokenMethod(new_data);
    } else {
      setIsLoading(true);
      token = await createCompanyStripeTokenMethod(new_data);
    }
    const fullName = selectedUser?.Clients?.[0]?.name + " " + selectedUser?.Clients?.[0]?.lastName;
    if (token && selectedUser?.Clients?.[0]?.account_type === "particular") {
      setIsLoading(true);
      bank_token = await createBankAccountTokenMethod(fullName, "individual", selectedUser?.Clients?.[0]?.rib);
    } else {
      setIsLoading(true);
      bank_token = await createBankAccountTokenMethod(
        selectedUser?.Clients?.[0]?.companyName,
        "company",
        selectedUser?.Clients?.[0]?.rib
      );
    }

    const req: any = await dispatch(
      createConnectedAcount({
        data: new_data,
        stripeToken: token ?? undefined,
        stripeBankToken: bank_token ?? undefined,
      })
    );

    if (req?.payload?.ok) {
      setIsLoading(false);
      reset();
      dispatch(getAllUsers());
    }
    if (!req?.payload?.ok) {
      setIsLoading(false);
    }
  };

  const copyData = async () => {
    try {
      setIsLoadingCopy(true);
      const response = await fetch(`${baseURL}/api/client/copy-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      if (response.ok) {
        setIsLoadingCopy(false);
        toast.success("Data copieé");
      }

      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
  const phoneInputStyle = {
    borderRadius: "0.375rem",
    backgroundColor: "#FFFFFF",
  };

  useEffect(() => {
    getValues("selected_User");
    getValues("companyPhone");
  }, [watch("selected_User"), watch("companyPhone")]);
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <span className="flex justify-center text-xl font-bold">Stripe comptes connect</span>
        <ButtonPrimary type="submit" loading={isLoadingCopy} onClick={copyData} className="mt-5">
          Copie data
        </ButtonPrimary>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-10">
          <div className="mt-3 region">
            <span className="block mb-3 font-semibold">Liste des utilisateurs *</span>

            <div className="flex space-x-3">
              <div className="w-full">
                {" "}
                <Select
                  className={`form-select mt-1 block w-full ${errors.selected_User ? "border-red-500" : ""}`}
                  disabled={isLoading}
                  value={getValues("selected_User") || ""}
                  onChange={(e) => setValue("selected_User", e.target.value)}
                >
                  <option value="" disabled>
                    Sélectionner utilisateur
                  </option>
                  {filtred_users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.email}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="flex space-x-3">
              <div className="w-full">
                {" "}
                <Input
                  {...register("address")}
                  placeholder="Adresse"
                  className={` mt-1 block w-full ${errors.address ? "border-red-500" : ""}`}
                  disabled={isLoading}
                ></Input>
              </div>

              <div className="w-full">
                {" "}
                <Input
                  {...register("address2")}
                  placeholder="Complément d'adresse"
                  className={` mt-1 block w-full ${errors.city ? "border-red-500" : ""}`}
                  disabled={isLoading}
                ></Input>
              </div>
            </div>

            <div className="flex space-x-3">
              <div className="w-full">
                {" "}
                <Input
                  {...register("country")}
                  placeholder="Pays "
                  className={` mt-1 block w-full ${errors.country ? "border-red-500" : ""}`}
                  disabled={isLoading}
                ></Input>
              </div>

              <div className="w-full">
                {" "}
                <Input
                  {...register("city")}
                  placeholder="Ville "
                  className={` mt-1 block w-full ${errors.city ? "border-red-500" : ""}`}
                  disabled={isLoading}
                ></Input>
              </div>

              <div className="w-full">
                {" "}
                <Input
                  {...register("codePostale", { valueAsNumber: true })}
                  placeholder="Code postale"
                  className={` mt-1 block w-full ${errors.codePostale ? "border-red-500" : ""}`}
                  disabled={isLoading}
                ></Input>
              </div>
            </div>

            {userValue?.Clients?.[0]?.account_type === "company" && (
              <>
                <span className="block mt-5 font-semibold">Entreprise </span>

                <div className="flex space-x-3">
                  <div className="w-full">
                    {" "}
                    <Input
                      {...register("email")}
                      placeholder="Email d'entreprise"
                      className={` mt-1 block w-full ${errors.email ? "border-red-500" : ""}`}
                      disabled={isLoading}
                    ></Input>
                  </div>

                  <div className="w-full">
                    {" "}
                    <PhoneInput
                      onlyCountries={["fr"]}
                      country={"fr"}
                      placeholder={getValues("companyPhone")}
                      inputStyle={phoneInputStyle}
                      containerStyle={phoneInputStyle}
                      buttonStyle={phoneInputStyle}
                      searchStyle={phoneInputStyle}
                      inputProps={{ required: true }}
                      value={getValues("companyPhone")}
                      onChange={(value) => {
                        setValue("companyPhone", value);
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>

                <div className="flex space-x-3">
                  <div className="w-full">
                    {" "}
                    <Input
                      {...register("owner_title")}
                      placeholder="Poste du propriétaire de l'entreprise "
                      className={` mt-1 block w-full ${errors.owner_title ? "border-red-500" : ""}`}
                      disabled={isLoading}
                    ></Input>
                  </div>

                  <div className="w-full">
                    {" "}
                    <Input
                      {...register("owner_address")}
                      placeholder="adresse"
                      className={` mt-1 block w-full ${errors.owner_address ? "border-red-500" : ""}`}
                      disabled={isLoading}
                    ></Input>
                  </div>

                  <div className="w-full">
                    {" "}
                    <Input
                      {...register("owner_address2")}
                      placeholder="Complement d'adresse"
                      className={` mt-1 block w-full ${errors.owner_address2 ? "border-red-500" : ""}`}
                      disabled={isLoading}
                    ></Input>
                  </div>
                </div>

                <div className="flex space-x-3">
                  <div className="w-full">
                    {" "}
                    <Input
                      {...register("owner_city")}
                      placeholder="Ville "
                      className={` mt-1 block w-full ${errors.owner_city ? "border-red-500" : ""}`}
                      disabled={isLoading}
                    ></Input>
                  </div>

                  <div className="w-full">
                    {" "}
                    <Input
                      {...register("owner_postal_code", { valueAsNumber: true })}
                      placeholder="code postale"
                      className={` mt-1 block w-full ${errors.owner_postal_code ? "border-red-500" : ""}`}
                      disabled={isLoading}
                    ></Input>
                  </div>

                  <div className="w-full">
                    {" "}
                    <Input
                      {...register("owner_country")}
                      placeholder="Pays"
                      className={` mt-1 block w-full ${errors.owner_country ? "border-red-500" : ""}`}
                      disabled={isLoading}
                    ></Input>
                  </div>
                </div>
              </>
            )}
          </div>

          {errors.selected_User?.message && (
            <div className="text-xs text-red-500">{"Utilisateur est obligatoire"}</div>
          )}

          {errors.address?.message && (
            <div className="text-xs text-red-500">{errors.address?.message.toString()}</div>
          )}

          {errors.country?.message && (
            <div className="text-xs text-red-500">{errors.country?.message.toString()}</div>
          )}
          {errors.city?.message && <div className="text-xs text-red-500">{errors.city?.message.toString()}</div>}

          {errors.codePostale?.message && (
            <div className="text-xs text-red-500">{errors.codePostale?.message.toString()}</div>
          )}

          {errors.companyPhone?.message && (
            <div className="text-xs text-red-500">{errors.companyPhone?.message.toString()}</div>
          )}

          {errors.owner_title?.message && (
            <div className="text-xs text-red-500">{errors.owner_title?.message.toString()}</div>
          )}

          {errors.owner_address?.message && (
            <div className="text-xs text-red-500">{errors.owner_address?.message.toString()}</div>
          )}
          {errors.owner_address2?.message && (
            <div className="text-xs text-red-500">{errors.owner_address2?.message.toString()}</div>
          )}

          {errors.owner_city?.message && (
            <div className="text-xs text-red-500">{errors.owner_city?.message.toString()}</div>
          )}

          {errors.owner_country?.message && (
            <div className="text-xs text-red-500">{errors.owner_country?.message.toString()}</div>
          )}

          {errors.owner_postal_code?.message && (
            <div className="text-xs text-red-500">{errors.owner_postal_code?.message.toString()}</div>
          )}
          <CardFooter className="pt-0">
            <Typography variant="small" className="flex justify-center mt-6">
              <ButtonPrimary type="submit" loading={isLoading}>
                Creér un compte
              </ButtonPrimary>
            </Typography>
          </CardFooter>
        </form>
      </DashboardMenu>
    </div>
  );
};

export default AdminStripe;
