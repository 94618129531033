import FlightCard from "components/FlightCard/FlightCard";
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import AgentCommonLayout from "../../AccountPage/AgentCommonLayout";
import SectionGridFilterCardServiceAgent from "containers/AgentService/Services/listing/SectionGridFilterCardServiceAgent";
import SectionGridPricingServiceAgent from "./SectionGridPricingServiceAgent";

export interface PricingAgentServicesPageProps {
  className?: string;
}

const PricingAgentServicesPage: FC<PricingAgentServicesPageProps> = ({ className = "" }) => {
  return (
    <AgentCommonLayout>
      <div
        className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
        data-nc-id="ListingFlightsPage"
      >
        <Helmet>
          <title>Event To Bee</title>
        </Helmet>

        <div className="container relative">
          <SectionGridPricingServiceAgent className="pb-24 lg:pb-28" />
        </div>
      </div>
    </AgentCommonLayout>
  );
};

export default PricingAgentServicesPage;
