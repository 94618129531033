import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Textarea } from "@material-tailwind/react";
import Label from "components/Label/Label";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import Multiselect from "multiselect-react-dropdown";
import { FC, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { getFields, resetValues } from "redux/features/signUp/signUpSlice";
import Avatar from "shared/Avatar/Avatar";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { z } from "zod";
import __cities from "../../data/jsons/cities.json";
import OnboardingTour, { stepStyle } from "utils/tours/OnboardingTour";
import { useLocation } from "react-router-dom";

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];

const formSchema = z.object({
  designation: z.string().min(1, { message: "Le designation est obligatoire." }),
  category: z.string().min(1, { message: "Le category est obligatoire." }),
  phone: z
    .string()
    .min(1, { message: "Le numéro téléphone est obligatoire." })
    .refine((value) => !/^0{11}$/.test(value), {
      message: "Le numéro de téléphone ne peut pas comporter zéros consécutifs.",
    }),
  link: z.string(),
  region: z.string({ required_error: "Le region est obligatoire." }),
  contact: z.string(),
  description: z.string(),
  instagram: z.string(),
  youtube: z.string(),
  facebook: z.string(),
  fields: z.string().min(1, { message: "Les domaines sont obligatoires." }),
  availability: z.array(z.string()).nonempty({ message: "Les régions disponibles est obligatoire." }),
  service_url: z
    .optional(z.instanceof(File))
    .refine(
      (file) => !file || (file?.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type)),
      "Le fichier doit être une image au format JPEG, PNG ou GIF et ne pas dépasser 10MB."
    ),
});

export interface ProfieServiceFormProps {
  className?: string;
  isLoading: boolean;
  handleForm: Event;
  errorServiceForm?: string;
  FormData: (data: any) => void;
}

const ProfileServiceForm: FC<ProfieServiceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  errorServiceForm,
  FormData,
}) => {
  const [phoneInputColor, setPhoneInputColor] = useState("");
  useEffect(() => {
    let theme = localStorage.getItem("theme");
    if (theme === "dark") {
      setPhoneInputColor("#000");
    } else {
      setPhoneInputColor("#000");
    }
  }, []);

  const phoneInputStyle = {
    color: phoneInputColor,
    borderRadius: "0.375rem",
  };
  const dispatch = useAppDispatch();
  const signUpState = useAppSelector((state) => state.signUp);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      link: "",
      contact: "",
      description: "",
      facebook: "",
      youtube: "",
      instagram: "",
      fields: "",
      availability: [],
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    dispatch(getFields());
  }, []);

  useEffect(() => {
    if (handleForm.type === "chekProfileServiceEvent") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  const steps = [
    {
      selector: ".image",
      content: "Une image est une représentation visuelle.",
      stepStyle,
    },
    {
      selector: ".nom-prenom",
      content: "Ajouter votre prénom en complément de votre nom de famille.",
      stepStyle,
    },
    {
      selector: ".type",
      content: "Ajouter votre prénom en complément de votre nom de famille.",
      stepStyle,
    },
    {
      selector: ".num-telephone ",
      content:
        "Un numéro de téléphone est une séquence unique et nominative de chiffres permettant de vous contacter. ",
    },
    {
      selector: ".site ",
      content: "Votre site web. ",
    },
    {
      selector: ".ville",
      content: "Région de logements éparpillés dans la campagne..",
      stepStyle,
    },
    {
      selector: ".contact",
      content: "Votre contact.",
      stepStyle,
    },
    {
      selector: ".facebook",
      content: "Votre Facebook.",
      stepStyle,
    },
    {
      selector: ".instagram",
      content: "Votre Instagram.",
      stepStyle,
    },
    {
      selector: ".youtube",
      content: "Votre Youtube.",
      stepStyle,
    },
    {
      selector: ".propos",
      content: "Mentionnez les meilleures caractéristiques de votre services, équipements spéciaux.",
      stepStyle,
    },
    {
      selector: ".type-service",
      content: "Votre type de service.",
      stepStyle,
    },
    {
      selector: ".region",
      content: "Votre Region.",
      stepStyle,
    },
  ];

  const location = useLocation();
  useEffect(() => {
    const cleanup = () => {
      if (signUpState.currentStep === 2) {
        dispatch(resetValues());
      }
    };

    if (location.pathname !== "/signup/service") {
      dispatch(resetValues());
    }

    return cleanup;
  }, [location.pathname, signUpState.currentStep]);

  useEffect(() => {
    getValues("fields");
  }, [watch("fields")]);

  return (
    <div className={className}>
      <h2 className="mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Votre profil
      </h2>
      <div className="mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        <div className="flex-col items-start flex-shrink-0 image">
          <div className="relative flex overflow-hidden rounded-full">
            <Avatar
              sizeClass="w-32 h-32"
              imgUrl={getValues("service_url") ? URL.createObjectURL(getValues("service_url") as File) : ""}
            />
            {getValues("service_url") ? null : (
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-black cursor-pointer bg-opacity-60 text-neutral-50">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                ></svg>
                <span className="mt-1 text-xs">Selectionner Image</span>
              </div>
            )}

            <input
              type="file"
              id="imageFile"
              name="imageFile"
              className="absolute inset-0 opacity-0 cursor-pointer"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  setValue("service_url", file);
                  trigger("service_url");
                }
              }}
              accept="image/jpeg, image/jpg, image/png, image/webp"
            />
          </div>
        </div>
      </div>

      {errorServiceForm && (
        <Alert color="red" className="text-xs">
          {errorServiceForm}
        </Alert>
      )}

      <form className="flex flex-col justify-center md:flex-row">
        <div className="flex-grow mt-10 space-y-6 md:mt-0">
          <div className="flex flex-wrap gap-8 x">
            <div className="nom-prenom">
              <Label>Nom et prénom *</Label>
              <Input
                type="text"
                placeholder="Nom et prénom"
                className={`mt-1.5 ${errors.designation?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                {...register("designation")}
              />

              {errors.designation?.message && (
                <div className="text-xs text-red-500">{errors.designation?.message}</div>
              )}
            </div>

            <div className="type">
              <Label>Vous-étes *</Label>
              <Select
                className={`mt-1.5 ${errors.category?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                placeholder="Choisir votre type de compte"
                value={getValues("category")}
                onChange={(e) => setValue("category", e.target.value)}
              >
                <option value={""}>Choisir votre type de compte</option>

                <option value="company">Entreprise</option>
                <option value="particular">Particulier</option>
              </Select>

              {errors.category?.message && (
                <div className="text-xs text-red-500">{"Ce champ est obligatoire"}</div>
              )}
            </div>
          </div>

          <div className="flex flex-wrap gap-8">
            <div className="num-telephone">
              <Label>Numéro téléphone *</Label>

              <PhoneInput
                country={"fr"}
                placeholder={getValues("phone")}
                inputStyle={phoneInputStyle}
                containerStyle={phoneInputStyle}
                buttonStyle={phoneInputStyle}
                //dropdownStyle={phoneInputStyle}
                searchStyle={phoneInputStyle}
                inputProps={{ required: true }}
                value={getValues("phone")}
                onChange={(value) => setValue("phone", value)}
                disabled={isLoading}
              />

              {errors.phone?.message && (
                <div className="text-xs text-red-500">
                  {errors.phone?.message === "Required" ? "Ce champ est obligatoire" : ` ${errors.phone?.message}`}
                </div>
              )}
            </div>

            <div className="ville">
              <Label>Ville *</Label>
              <Select
                style={{ minWidth: "306px" }}
                className={` ${errors.region?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                placeholder="Choix de ville"
                value={getValues("region")}
                onChange={(e) => setValue("region", e.target.value)}
              >
                <option value={""}>Choix de ville</option>

                {__cities?.map((region: any) => (
                  <option key={region.label} value={region.label}>
                    {`${region.label}`}
                  </option>
                ))}
              </Select>

              {errors.region?.message && <div className="text-xs text-red-500">{errors.region?.message}</div>}
            </div>

            {/*       */}
          </div>

          <div className="flex flex-wrap gap-6 x">
            <div className="w-1/2 site">
              <Label>Site web</Label>
              <Input type="text" placeholder="https://example.com" disabled={isLoading} {...register("link")} />
            </div>
          </div>
          <div className="flex flex-wrap gap-6 x">
            <div className="w-1/2 contact">
              <Label>Contact</Label>
              <Input
                type="text"
                placeholder="example@mail.com"
                className="mt-1.5"
                disabled={isLoading}
                {...register("contact")}
              />
            </div>
          </div>

          <div className="flex flex-wrap gap-6 x">
            <div className="w-1/2 facebook">
              <Label>Facebook </Label>
              <Input
                className={`mt-1.5 ${errors.facebook?.message ? "border-red-500" : ""}`}
                type="text"
                placeholder="https://www.facebook.com/"
                disabled={isLoading}
                {...register("facebook")}
              />
              {errors.facebook?.message && <div className="text-xs text-red-500">{errors.facebook?.message}</div>}
            </div>
          </div>

          <div className="flex flex-wrap gap-6 x">
            <div className="w-1/2 instagram">
              <Label>instagram</Label>
              <Input
                className={`mt-1.5 ${errors.instagram?.message ? "border-red-500" : ""}`}
                type="text"
                placeholder="https://instagram.com/"
                disabled={isLoading}
                {...register("instagram")}
              />
              {errors.instagram?.message && (
                <div className="text-xs text-red-500">{errors.instagram?.message}</div>
              )}
            </div>
          </div>

          <div className="flex flex-wrap gap-6 x">
            <div className="w-1/2 youtube">
              <Label>Youtube</Label>
              <Input
                className={`mt-1.5 ${errors.youtube?.message ? "border-red-500" : ""}`}
                type="text"
                placeholder="https://www.youtube.com/"
                disabled={isLoading}
                {...register("youtube")}
              />
              {errors.youtube?.message && <div className="text-xs text-red-500">{errors.youtube?.message}</div>}
            </div>
          </div>
          <div className="space-y-8">
            <div className="propos">
              <div className="">
                <h2 className="text-2xl font-semibold">A propos</h2>
                <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">
                  Mentionnez vos spécialités, vos atouts et ce qui vous distingue en tant que prestataire.
                </span>
              </div>
              <Textarea placeholder="..." rows={14} disabled={isLoading} {...register("description")} />
            </div>

            <div className="type">
              <Label>Type de prestation *</Label>
              <Select
                className={`mt-1.5 ${errors.fields?.message ? "border-red-500" : ""}`}
                disabled={isLoading}
                value={getValues("fields")}
                onChange={(e) => setValue("fields", e.target.value)}
              >
                <option value={""}>Séléctionner votre type de prestation</option>

                {signUpState.fields?.map((field) => (
                  <option key={field.designation} value={field.designation}>
                    {field.designation}
                  </option>
                ))}
              </Select>

              {errors.fields?.message && <div className="text-xs text-red-500">Ce champs est obligatoire</div>}
            </div>
          </div>

          <div className="region">
            <span className="block mb-3 text-lg font-semibold">
              Les régions disponibles pour vos prestations *
            </span>
            <Multiselect
              options={__cities}
              //selectedValues={}
              onSelect={(selectedList) => {
                const labels = selectedList.map((item: { label: any }) => item.label);
                setValue("availability", labels);
              }}
              onRemove={(selectedList) => {
                const labels = selectedList.map((item: { label: any }) => item.label);
                setValue("availability", labels);
              }}
              displayValue="label"
              className={errors.availability?.message ? "border-red-500" : ""}
              placeholder="Select regions"
              closeOnSelect={false}
              disable={isLoading}
            />

            {errors.availability?.message && (
              <div className="text-xs text-red-500">{errors.availability?.message.toString()}</div>
            )}
          </div>

          <div>
            {errors.service_url?.message && (
              <div className="text-xs text-red-500">{errors.service_url?.message.toString()}</div>
            )}
          </div>

          <p style={{ fontSize: "10px", fontWeight: 400, lineHeight: "2" }}>
            En cliquant sur <b style={{ fontWeight: "bolder" }}>Accepter et continuer</b>, j'accepte les{" "}
            <a href={"/terms"} target="_blank">
              &nbsp;{" "}
              <span style={{ color: "rgb(0, 88, 255)", textDecoration: "underline" }}>
                Conditions générales d'utilisation
              </span>
              &nbsp;
            </a>
            et je reconnais avoir pris connaissance de la
            <a href={"/privacy-policy"} target="_blank">
              &nbsp;{" "}
              <span style={{ color: "rgb(0, 88, 255)", textDecoration: "underline" }}>
                Politique de confidentialité
              </span>
              &nbsp;
            </a>
            de Event to BEE.
          </p>
        </div>
      </form>
      <OnboardingTour steps={steps} page_name={"ProfileServiceForm"}></OnboardingTour>
    </div>
  );
};

export default ProfileServiceForm;
