import { useState } from 'react';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const stepStyle = {
    color: '#666',
    background: '#dff9fb',
    height: '100%',
    overflow: 'hidden',
};

function OnboardingTour(props) {
    const [isTourOpen, setIsTourOpen] = useState(false);
    const disableBody = (target) => disableBodyScroll(target);
    const enableBody = (target) => enableBodyScroll(target);

    return (
        <Tour
            steps={props?.steps}
            isOpen={isTourOpen}
            accentColor="#4F46E5"
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            onRequestClose={() => setIsTourOpen(false)}
        />
    );
}

export default OnboardingTour;
