import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import DeleteModal from "components/DeleteModal/DeleteModal";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { deleteConnectedAccount, getAllConnectedAccounts } from "redux/features/Admin/Stripe/stripeSlice";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";
import DashboardMenu from "../../DashboardPage/DashboardMenu";
import { Spinner } from "@material-tailwind/react";

export interface ListAcoountsPageProps {
  className?: string;
}

const ListConnectedAccounts: FC<ListAcoountsPageProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<any | null>(null);
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const accounts = useAppSelector((state) => state.stripe.accounts || { data: [], totalPages: 1, count: 0 });

  const getLastId = (accounts: any) => {
    if (!accounts || !accounts.data || accounts.data.length === 0) return null;
    return accounts.data[accounts.data.length - 1].id;
  };

  //const lastId = getLastId(accounts);

  useEffect(() => {
    if (accounts && accounts.data) {
      setLoading(true);
      const lastId = getLastId(accounts);
      dispatch(getAllConnectedAccounts({ pageNumber: page, startAfter: lastId || "" })).then(() =>
        setLoading(false)
      );
    }
  }, [page, dispatch]);

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };

  const handleDeleteModalOpen = (account: any) => {
    setSelectedAccount(account);
    setShowClientDetails(false);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedAccount && selectedAccount.id !== undefined) {
      setDeleting(true);
      setIsLoading(true);
      await dispatch(
        deleteConnectedAccount({ id: selectedAccount.id, owner_id: selectedAccount?.metadata?.owner_id })
      );
      setIsLoading(false);
      setDeleting(false);
      setDeleteModalOpen(false);
      setLoading(true);
      const updatedAccounts = await dispatch(getAllConnectedAccounts({ pageNumber: page, startAfter: "" }));
      const updatedLastId = getLastId(updatedAccounts.payload);
      setLoading(false);
    }
  };

  const handleEyeIconClick = (client: any) => {
    setSelectedAccount(client);
    setShowClientDetails(true);
    setDeleteModalOpen(false);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="flex flex-col">
          <div className="flex items-center justify-between mb-4">
            <div></div>
            <div className="relative flex">
              <Input
                type="text"
                placeholder="Rechercher par nom"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-500 transform -translate-y-1/2 top-1/2 right-3" />
            </div>
          </div>
          {deleteModalOpen && (
            <DeleteModal
              isLoading={isLoading}
              open={deleteModalOpen}
              handleClose={() => setDeleteModalOpen(false)}
              handleDelete={handleDeleteConfirm}
              category={{
                id: selectedAccount?.User?.id || 0,
                designation: selectedAccount?.User?.login || "",
              }}
            />
          )}

          <div className=" sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-sm font-light text-center">
                  <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        Id
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Nom du compte
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Type de compte
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Email
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Status
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Date de création
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Supprimer
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="px-6 py-4 text-center" colSpan={8}>
                          <div className="flex items-center justify-center">
                            <div className="spinner-border text-primary" role="status">
                              <Spinner color="blue" className="h-14 w-14" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {accounts?.data &&
                          accounts?.data
                            .filter((item: any) => item.id.toLowerCase().includes(searchTerm.toLowerCase()))
                            .map((item: any, index: any) => (
                              <tr className="border-b dark:border-neutral-500" key={index}>
                                <td className="px-6 py-4 font-medium first-letter:">{item.id}</td>
                                <td className="px-6 py-4 font-medium whitespace-nowrap">
                                  {item.business_profile?.name}
                                </td>
                                <td className="px-6 py-4 font-medium ">
                                  <Badge
                                    color={item.business_type === "company" ? "blue" : "green"}
                                    name={item.business_type}
                                  />
                                </td>
                                <td className="px-6 py-4 font-medium whitespace-nowrap">
                                  {item?.individual?.email || item?.email}
                                </td>

                                <td className="px-6 py-4 font-medium whitespace-nowrap">
                                  <Badge color={item.status === "Limité" ? "red" : "green"} name={item.status} />
                                </td>

                                <td className="px-6 py-4 font-medium whitespace-nowrap">
                                  {new Date(item?.created * 1000).toLocaleString("fr-FR", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}
                                </td>

                                <td className="px-6 py-4 ">
                                  <i
                                    className="text-2xl cursor-pointer las la-trash-alt"
                                    onClick={() => handleDeleteModalOpen(item)}
                                  ></i>
                                </td>
                              </tr>
                            ))}
                      </>
                    )}
                  </tbody>
                </table>
                <div className="flex justify-end">
                  <span className="text-sm text-gray-700">{accounts?.count} élément</span>
                </div>

                <div className="flex items-center justify-center mt-11">
                  {accounts?.data?.totalPages > 1 && typeof accounts?.data?.totalPages && (
                    <Pagination
                      currentPage={page}
                      totalPages={accounts?.totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardMenu>
    </div>
  );
};

export default ListConnectedAccounts;
