import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import DashboardMenu from "../DashboardPage/DashboardMenu";
import { TrashIcon } from "@heroicons/react/24/solid";
import { CardFooter, Spinner, Typography } from "@material-tailwind/react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addEvent,
  fetchEvents,
  resetDesignation,
  resetValues,
  setDesignation,
  setImage,
} from "redux/features/Admin/Events/eventSlice";
import { RootState } from "redux/store";
import { Alert } from "shared/Alert/Alert";
import { useAppSelector } from "hooks/hooks";
export interface AddEventPageProps {
  className?: string;
}

export interface Event {
  designation: string;
  id: number;
  images: string;
}

const AddEventPage: FC<AddEventPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [showExistAlert, setShowExistAlert] = React.useState(false);
  const [DesignationError, setDesignationError] = useState("");
  const [imageError, setImageError] = useState("");
  const navigate = useNavigate();

  const designation = useSelector((state: RootState) => state.featureEvent.designation);
  const images = useSelector((state: RootState) => state.featureEvent.image);

  const fetchedEvents: Event[] = useSelector((state: RootState) => state.featureEvent.events);
  const eventState = useAppSelector((state) => state.featureEvent);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSetCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDesignation = String(event.target.value);
    dispatch(setDesignation(newDesignation));
  };

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const updatedImages = [...uploadedImages, ...fileArray];
      setUploadedImages(updatedImages);

      dispatch(setImage(updatedImages));
    }
  };
  const handleDeleteImage = (index: number) => {
    const updatedImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(updatedImages);
    dispatch(setImage(updatedImages));
  };

  const [showAlert, setShowAlert] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setDesignationError("");
    setImageError("");

    let isValid = true;

    if (!designation) {
      setDesignationError("Ce champ est obligatoire.");
      isValid = false;
    }

    if (uploadedImages.length === 0) {
      isValid = false;
      setImageError("Ce champ est obligatoire.");
    } else {
      setImageError("");
    }

    const isCategoryExists = fetchedEvents?.some((event) => event.designation === designation);

    if (isCategoryExists) {
      setShowExistAlert(true);
      setTimeout(() => {
        setShowExistAlert(false);
      }, 1000);
      return;
    }

    if (isValid) {
      await dispatch(addEvent({ designation, images }));
      dispatch(resetValues());
      navigate("/dashboard/events");
    }
  };

  useEffect(() => {
    switch (eventState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [eventState.loading]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <form onSubmit={handleSubmit}>
          <span className="grid mb-4 place-items-center h-28">
            {!showExistAlert && !showAlert && "Evénement"}

            {showExistAlert && (
              <Alert type="error" containerClassName="text-xs">
                {designation} déjà existante !
              </Alert>
            )}

            {showAlert && (
              <Alert type="success" containerClassName="text-xs">
                {designation} ajoutée avec succès !
              </Alert>
            )}
          </span>
          <br />
          <Label>Designation</Label>
          <br />
          <Input name="designation" value={designation} onChange={handleSetCategory} placeholder="Evénement" />
          {DesignationError && <div className="text-xs text-red-500">{DesignationError}</div>}
          <div className="mt-10">
            {uploadedImages.length > 0 && (
              <div className="mt-3">
                <p className="text-sm text-green-500">Vos images:</p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {uploadedImages.map((image, index) => (
                    <div key={index} className="relative">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Uploaded ${index}`}
                        className="h-auto max-w-xs max-h-40"
                      />

                      <TrashIcon
                        className="absolute w-5 text-white bg-black rounded-full cursor-pointer top-2 right-2 h-7 las la-trash-alt"
                        onClick={() => handleDeleteImage(index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
              <div className="space-y-1 text-center">
                <svg
                  className="w-12 h-12 mx-auto text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                  <label
                    htmlFor="fileInput"
                    className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>Ajouter</span>
                    <input
                      id="fileInput"
                      name="images"
                      type="file"
                      multiple
                      className="sr-only"
                      onChange={handleFileUpload}
                    />
                  </label>
                </div>
                <p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF jusqu'à 10MB</p>
              </div>
            </div>
          </div>
          {imageError && <div className="text-xs text-red-500">{imageError}</div>}

          <CardFooter className="pt-0">
            <Typography variant="small" className="flex justify-center mt-6">
              <ButtonPrimary type="submit" loading={isLoading}>
                Ajouter
              </ButtonPrimary>
            </Typography>
          </CardFooter>
        </form>
      </DashboardMenu>
    </div>
  );
};

export default AddEventPage;
