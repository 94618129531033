import { PostDataType } from "data/types";
import React, { FC } from "react";
import Badge from "shared/Badge/Badge";

export interface CategoryBadgeListProps {
  className?: string;
  itemClass?: string;
  categories: string;
}

const CategoryBadgeList: FC<CategoryBadgeListProps> = ({
  className = "flex flex-wrap space-x-2",
  itemClass,
  categories,
}) => {
  return (
    <div className={`nc-CategoryBadgeList ${className}`} data-nc-id="CategoryBadgeList">
      <Badge className={"itemClass"} key={categories} name={categories} href={`/blogs/${categories}`} />
    </div>
  );
};

export default CategoryBadgeList;
