import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { forgetPasswordService } from "./forgetPasswordAPI";

interface UserState {
  loading: "idle" | "pending" | "succeeded" | "failed";

  isTokenValid: boolean;
  passwordHasChanged: boolean;

  forgetPasswordError?: string;
  checkTokenForgetPasswordError?: string;
  resetPasswordError?: string;

  error?: SerializedError;
}

const initialState: UserState = {
  loading: "idle",
  isTokenValid: false,
  passwordHasChanged: false,
};

export const forgetPassword = createAsyncThunk("forgetPassword", async (email: string) => {
  return await forgetPasswordService.forgetPassword(email);
});

export const checkTokenForgetPassword = createAsyncThunk("checkTokenForgetPassword", async (token: any) => {
  return await forgetPasswordService.checkTokenForgetPassword(token);
});

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async ({ token, newPassword }: { token: any; newPassword: any }) => {
    return await forgetPasswordService.resetPassword(token, newPassword);
  }
);

export const signInSlice = createSlice({
  name: "ForgetPassword",
  initialState,
  reducers: {
    initState(state) {
      state.loading = "idle";
      state.isTokenValid = false;
      state.passwordHasChanged = false;
    },
    setForgetPasswordError(state) {
      state.forgetPasswordError = undefined;
    },
    setCheckTokenForgetPasswordError(state) {
      state.checkTokenForgetPasswordError = undefined;
    },
    setResetPasswordError(state) {
      state.resetPasswordError = undefined;
    },
  },
  extraReducers: (builder) => {
    // forgetPassword
    builder.addCase(forgetPassword.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }

      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.forgetPasswordError = action.payload.error;
      }
    });
    builder.addCase(forgetPassword.rejected, (state, action) => {
      state.loading = "failed";

      state.error = action.error;
    });

    // checkTokenForgetPassword
    builder.addCase(checkTokenForgetPassword.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(checkTokenForgetPassword.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
        state.isTokenValid = true;
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.isTokenValid = false;
        state.checkTokenForgetPasswordError = action.payload.error;
      }
    });
    builder.addCase(checkTokenForgetPassword.rejected, (state, action) => {
      state.loading = "failed";

      state.error = action.error;
    });

    // newPassword
    builder.addCase(resetPassword.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
        state.passwordHasChanged = true;
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.resetPasswordError = action.payload.error;
      }
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = "failed";

      state.error = action.error;
    });
  },
});

export const { setForgetPasswordError, setCheckTokenForgetPasswordError, setResetPasswordError } =
  signInSlice.actions;

export const selectCount = (state: RootState) => state.auth;

export default signInSlice.reducer;
