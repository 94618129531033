import { FC, useEffect } from "react";
import ModalSelectDate from "components/ModalSelectDate";
import ModalSelectGuests from "components/ModalSelectGuests";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "hooks/hooks";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import { payReservation } from "redux/features/reservations/reservationSlice";
axios.defaults.baseURL = "/api";
export interface PaymentPageProps {
  className?: string;
}

const PaymentPage: FC<PaymentPageProps> = ({ className = "" }) => {
  let detailState = useAppSelector((state) => state.featureReservation.reservationDetail);
  let locationState = useAppSelector((state) => state.featureLocation.locationDetail);
  useEffect(() => {
    localStorage.setItem("reservation", JSON.stringify(detailState));
  }, [detailState]);
  const dispatch = useAppDispatch();
  const formattedDate = new Date(detailState?.selectedDate).toLocaleDateString("fr-FR");
  const handleSubmit = async () => {
    const res = await dispatch(payReservation({ product: locationState.title, price: detailState.price }));
    window.location.href = res.payload;
  };

  const renderSidebar = () => {
    return (
      <div className="flex flex-col w-full px-0 space-y-6 sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 sm:space-y-8 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className="overflow-hidden aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={`${locationState?.images[0]}`}
              />
            </div>
          </div>
          <div className="py-5 space-y-3 sm:px-5">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {locationState?.city} , {locationState?.address}
              </span>
              <span className="block mt-1 text-base font-medium">{locationState?.title}</span>
            </div>
            <span className="block text-sm text-neutral-500 dark:text-neutral-400">
              {locationState?.price} €/heure par personne
            </span>
            <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Frais</h3>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Frais total</span>
            <span>{detailState?.price}€</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="flex flex-col w-full px-0 space-y-8 sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 sm:p-6 xl:p-8">
        <h2 className="text-3xl font-semibold lg:text-4xl">Confirmer & Payer</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Votre réservation</h3>
          </div>
          <div className="z-10 flex flex-col mt-6 overflow-hidden border divide-y border-neutral-200 dark:border-neutral-700 rounded-3xl sm:flex-row sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <ModalSelectDate
              renderChildren={({ openModal }) => (
                <button
                  className="flex justify-between flex-1 p-5 space-x-5 text-left hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">{formattedDate}</span>
                  </div>
                </button>
              )}
            />

            <ModalSelectGuests
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  className="flex justify-between flex-1 p-5 space-x-5 text-left hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Personnes</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">{detailState?.visitor}</span>
                    </span>
                  </div>
                </button>
              )}
            />
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Accéder au paiement</h3>
          <div className="my-5 border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
          <ButtonPrimary
            className="w-full px-4 py-2 text-white bg-indigo-600 rounded"
            type="button"
            onClick={handleSubmit}
          >
            Payez
          </ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PaymentPage ${className}`}>
      <main className="container flex flex-col-reverse mb-24 mt-11 lg:mb-32 lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="flex-grow hidden lg:block">{renderSidebar()}</div>
      </main>
    </div>
  );
};
export default PaymentPage;
