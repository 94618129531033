import { Dialog, DialogBody, DialogFooter, DialogHeader, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import React from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface DeleteModalProps {
  open: boolean;
  handleClose?: () => void;
  data: any;
}
const ServiceDetailsPage: React.FC<DeleteModalProps> = ({ open, handleClose, data }) => {
  const [openx, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Dialog open={open} handler={handleOpen} className="w-11/12 dark:bg-gray-800">
        <DialogHeader>
          <Typography variant="h5" color="blue-gray" className="text-center dark:text-white">
            Information détaillée de Prestation
          </Typography>
        </DialogHeader>
        <DialogBody divider className="grid gap-4 place-items-center">
          <Typography className="font-normal text-center">
            <div>
              <Label className="font-semibold">Titre :</Label>
              <span className="dark:text-white"> {data?.title} </span>
            </div>

            <div>
              <Label className="font-semibold">Description :</Label>
              <span className="dark:text-white">{data?.description}</span>
            </div>
            <div>
              <Label className="font-semibold">Prestatire :</Label>
              <span className="dark:text-white"> {data?.ServiceAgent?.designation} </span>
            </div>
            <div>
              <Label className="font-semibold">Disponibilité :</Label>
              <span className="dark:text-white">{data?.ServiceAgent?.availability.join(", ")}</span>
            </div>

            <div>
              <Label className="font-semibold">Évènements :</Label>
              <span className="dark:text-white">{data?.events.join(", ")}</span>
            </div>

            <div>
              <Label className="font-semibold">Équipement :</Label>
              <span className="dark:text-white">{data?.equipments.join(", ")}</span>
            </div>
            <div>
              <Label className="font-semibold">Publiée en :</Label>
              <span className="dark:text-white"> {new Date(data?.createdAt).toLocaleDateString()} </span>
            </div>
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <ButtonSecondary onClick={handleClose} className="dark:bg-gray-800 dark:text-white">
            Quitter
          </ButtonSecondary>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ServiceDetailsPage;
