const convertDateToString = (startDate: Date | null | undefined) => {
  if (startDate instanceof Date && !isNaN(startDate.getTime())) {
    return startDate.toLocaleDateString("fr-FR", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
  }
  return "";
};

export default convertDateToString;
