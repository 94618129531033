import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Checkbox from "shared/Checkbox/Checkbox";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Input from "shared/Input/Input";
import { LocationFormProps } from "data/reduxInterfaces";

const formSchema = z.object({
  isChecked1: z.literal(true),

  isChecked2: z.literal(true),
});

const LocationConfirmation: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  errorLocationForm,
  FormData,
}) => {
  const [checkboxes, setCheckboxes] = useState({
    isChecked1: false,
    isChecked2: false,
  });

  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const handleCheckbox1Change = () => {
    setCheckboxes({ ...checkboxes, isChecked1: !checkboxes.isChecked1 });
  };

  const handleCheckbox2Change = () => {
    setCheckboxes({ ...checkboxes, isChecked2: !checkboxes.isChecked2 });
  };

  const canSubmit = isChecked1 && isChecked2;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  useEffect(() => {
    if (handleForm.type === "confirmLocation") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <div className="flex items-center justify-center mb-24 lg:mb-32">
        {/* HEADING */}

        <form className="flex flex-col items-center mt-11" onSubmit={handleSubmit(onSubmit)}>
          <h3>Génial votre compte est prét à être examiné.</h3>

          <div className="mt-10">
            <div className="flex items-center gap-4">
              <div className="flex-shrink-0">
                <Input
                  key=""
                  type="checkbox"
                  {...register("isChecked1")}
                  className="w-2 h-2 rounded-none"
                  checked={checkboxes.isChecked1}
                  onChange={handleCheckbox1Change}
                  required
                />
              </div>
              <p className="text-xs">
                J'accepte les{" "}
                <a href="#" className="text-blue-500">
                  conditions générales d'utilisation
                </a>
              </p>
            </div>
            {errors?.isChecked1?.message && (
              <div className="text-xs text-red-500">{"Ce champ est obligatoire"}</div>
            )}

            <div className="flex items-center gap-4">
              <div className="flex-shrink-0">
                <Input
                  key=""
                  type="checkbox"
                  {...register("isChecked2")}
                  className="w-4 h-4 text-xs rounded-none"
                  checked={checkboxes.isChecked2}
                  onChange={handleCheckbox2Change}
                  required
                />
              </div>
              <p className="text-xs">
                Je confirme que j'ai le droit, le pouvoir et l'autorité nécessaires pour proposer l'espace à louer
                sur le site, ainsi que pour collecter des paiements dans le cadre de la location de cet espace sur
                le site.
              </p>
            </div>

            {errors.isChecked2?.message && (
              <div className="text-xs text-red-500">{"Ce champ est obligatoire"}</div>
            )}
          </div>
          <p className="mt-5 text-xs">
            Une fois que notre équipe aura approuvé votre demande, elle sera publiée en ligne sur{" "}
            <a className="text-blue-500" href="http://eventtobee.com">
              eventtobee.com{" "}
            </a>{" "}
            Habituellement, ce processus d'approbation prend 72 heures ouvrables.{" "}
            <p>
              Cependant, en raison d'une forte demande, il se peut qu'il y ait actuellement une liste d'attente
              pour l'approbation
            </p>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LocationConfirmation;
