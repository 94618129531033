import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { AuthContext } from "context/appContext";
import { DEMO_CAR_LISTINGS } from "data/listings";
import { CarDataType } from "data/types";
import { useAppDispatch } from "hooks/hooks";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { deleteLocationFromFavoris, getFavorisByClient } from "redux/features/locations/locationSlice";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";

export interface CarCardProps {
  className?: string;
  data?: any;
  size?: "default" | "small";
  featuredImage?: string | string[];
  favoriId?: number;
}

const DEMO_DATA: CarDataType = DEMO_CAR_LISTINGS[0];

const CarCardLocation: FC<CarCardProps> = ({
  size = "default",
  className = "",
  featuredImage,
  data = DEMO_DATA,
}) => {
  const { id, title, isAds, city } = data;

  const dispatch = useAppDispatch();
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  const handleLikeClick = async () => {
    await dispatch(deleteLocationFromFavoris({ locationId: id, clientId: profilId }));
    await dispatch(getFavorisByClient({ clientId: profilId }));
  };

  let isFavorited = true;
  const renderSliderGallery = () => {
    const imageToShow = Array.isArray(featuredImage) ? featuredImage[0] : featuredImage;

    return (
      <div className="relative w-full overflow-hidden rounded-2xl">
        <div className="aspect-w-16 aspect-h-9 ">
          <Link to={`/location/${id}`}>
            <NcImage
              containerClassName="flex items-center justify-center"
              className="w-full"
              src={imageToShow ? process.env.REACT_APP_CLOUD_FRONT + imageToShow : ""}
            />
          </Link>
        </div>

        <BtnLikeIcon isLiked={isFavorited} className="absolute right-3 top-3 z-[1] " onClick={handleLikeClick} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2
              className={`  capitalize ${size === "default" ? "text-xl font-semibold" : "text-base font-medium"}`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center space-x-2 text-sm text-neutral-500 dark:text-neutral-400">
            <span className="">{city} </span>

            <span className=""> </span>
          </div>
        </div>
        <div className="border-b w-14 border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex items-center justify-between"></div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
      data-nc-id="CarCard"
    >
      {/*  <Link to={`/location/${id}`} className="flex flex-col"> */}
      {renderSliderGallery()}
      {renderContent()}
      {/*  </Link> */}
    </div>
  );
};

export default CarCardLocation;
