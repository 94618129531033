const server = process.env.REACT_APP_SERVER_URL || "";
export const reservation = {
  repayment: async (id: any) => {
    try {
      const response = await fetch(`${server}/api/reservation/stripe-repayment/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      });
      const data = await response.json();
      return data.sessionUrl;
    } catch (error) {
      console.error("Error in repayment:", error);
      throw error;
    }
  },
  getReservationByLocations: async (id: any) => {
    const response = await fetch(`${server}/api/reservation/location/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
  fetchAdminCanceledReservationByClient: async (pageNumber: number) => {
    const response = await fetch(`${server}/api/reservation/get-canceled?page=${pageNumber}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
  payRefundReservation: async (ref: any) => {
    const response = await fetch(`${server}/api/reservation/refund/${ref}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
  payPartialRefundReservation: async (ref: any) => {
    const response = await fetch(`${server}/api/reservation/partial-refund/${ref}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
  getReservations: async (pageNumber: number) => {
    const response = await fetch(`${server}/api/reservation?page=${pageNumber}`);
    return response.json();
  },

  getOwnerAllTransferts: async (pageNumber: number, owner_id: number) => {
    const response = await fetch(
      `${server}/api/reservation/owner-transferts?page=${pageNumber}&owner_id=${owner_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }
    );

    return response.json();
  },

  getOwnerAllTransfertsGain: async (pageNumber: number, owner_id: number) => {
    const response = await fetch(
      `${server}/api/reservation/owner-transferts-gain?page=${pageNumber}&owner_id=${owner_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      }
    );

    return response.json();
  },

  getTentativeReservations: async (pageNumber: number) => {
    const response = await fetch(`${server}/api/reservation/tentative?page=${pageNumber}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return response.json();
  },
  sendCancelEmail: async (formData: any) => {
    try {
      const response = await fetch(`${server}/api/reservation/canceled`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  update: async (data: any, id: any) => {
    const result = await fetch(`${server}/api/reservation/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify(data),
    });
    return result.json();
  },
  confirm: async (data: any, id: any) => {
    const result = await fetch(`${server}/api/reservation/confirmed/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify(data),
    });
    return result.json();
  },
  checkReservationByReference: async (ref: any) => {
    const response = await fetch(`${server}/api/reservation/testpay/${ref}`);
    return response.json();
  },
  payReservationOwner: async (ref: any) => {
    const response = await fetch(`${server}/api/reservation/pay-owner/${ref}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
  getAvailableHours: async (id: any, selectedDay: any) => {
    const response = await fetch(`${server}/api/reservation/free-hours/${id}/${selectedDay}`);
    return response.json();
  },

  getFullyBookedDays: async (id: any) => {
    const response = await fetch(`${server}/api/reservation/fully-days/${id}`);
    return response.json();
  },
  getReservationById: async (id: any) => {
    const response = await fetch(`${server}/api/reservation/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
  getReservationByClient: async (id: any) => {
    const response = await fetch(`${server}/api/reservation/client/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
  getReservationByOwner: async (id: any) => {
    const response = await fetch(`${server}/api/reservation/owner/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },

  getReservationByOwnerAsClient: async (id: any) => {
    const response = await fetch(`${server}/api/reservation/owner-as-client/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
  fetchAdminCanceledReservationByOwner: async (pageNumber: number) => {
    const response = await fetch(`${server}/api/reservation/pending?page=${pageNumber}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },

  getEnAttenteReservation: async (pageNumber: number) => {
    const response = await fetch(`${server}/api/reservation/list-en-attente?page=${pageNumber}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },

  getPendingOwnerReservation: async (id: any) => {
    const response = await fetch(`${server}/api/reservation/pending-owner/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
  getBlockedReservationByOwner: async (id: any) => {
    const response = await fetch(`${server}/api/reservation/owner-blocked/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },
  deleteReservation: async (id: any) => {
    const response = await fetch(`${server}/api/reservation/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    return response.json();
  },

  payReservation: async (formData: any) => {
    try {
      const response = await fetch(`${server}/api/reservation/stripe-payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        return data.sessionUrl;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  checkReservation: async (formData: any) => {
    try {
      const response = await fetch(`${server}/api/reservation/exist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  makeReservationOwner: async (formData: any) => {
    try {
      const response = await fetch(`${server}/api/reservation/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        return data.sessionUrl;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  filterAllReservation: async (data: any) => {
    const result = await fetch(`${server}/api/reservation/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify(data),
    });
    return result.json();
  },
  transfertAmountOwner: async (amount: number, owner_id: string) => {
    const data = { amount, owner_id };
    const result = await fetch(`${server}/api/iban-transfers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify(data),
    });
    return result.json();
  },
};
